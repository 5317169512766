import { Pipe, PipeTransform } from '@angular/core';
import { isObservable, Observable, of } from 'rxjs';
import { get } from 'lodash';
import { InputValue } from '../../ui-value-renderer';

@Pipe({
  name: 'valueByPath',
  standalone: true,
})
export class ValueByPathPipe implements PipeTransform {
  transform<T>(object: T, path?: string, fallback: string | null = null): Observable<InputValue> {
    if (!path) {
      console.warn('Runtime error: Unknown object path.');
      return of(null);
    }

    const value = get(object as Record<any, any>, path, fallback);

    if (isObservable(value)) {
      return value as Observable<InputValue>;
    }

    return of(value as InputValue);
  }
}
