import { Pipe, PipeTransform } from '@angular/core';
import { convertBytes, convertToBytes } from './bytes-calculator';
import { FilesizeUnit, getPrefixFromFileSizeUnit } from '../models/filesize-unit.type';

@Pipe({
  name: 'formatBytes',
})
export class FormatBytesPipe implements PipeTransform {
  static transformWithUnit = (bytes?: number | null, fallback = 'N/A') => {
    if (bytes === undefined || bytes === null) {
      return fallback;
    }

    const result = convertBytes(bytes, 2);

    return `${result.value}${result.prefix}B`;
  };

  static transform = (bitrateValue?: number | null, unit: FilesizeUnit = 'MB') => {
    const bytesUnit = getPrefixFromFileSizeUnit(unit);

    const result = convertBytes(bitrateValue, 2, bytesUnit);

    return result.value;
  };

  static transformToBytes = (value?: number | null, unit: FilesizeUnit = 'MB') => {
    const bytesUnit = getPrefixFromFileSizeUnit(unit);

    return convertToBytes(value, bytesUnit, 2);
  };

  transform(value: number | null): string {
    return FormatBytesPipe.transformWithUnit(value);
  }
}
