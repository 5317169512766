import { ActionReducerMap, createSelector } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import { getSelectors } from '@ngrx/router-store';

export interface AppState {
  router: fromRouter.RouterReducerState;
}

export const appReducers: ActionReducerMap<AppState> = {
  router: fromRouter.routerReducer,
};

export const appState = (state: AppState) => state;
export const routerState = (state: AppState) => state.router;

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = getSelectors(routerState);

export const getRouteParameter = (paramName?: string) =>
  createSelector(selectRouteParams, (params) => paramName && params && params[paramName]);
