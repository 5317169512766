import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DataColumn,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UILayoutModule,
  UILoaderModule,
} from '@vdms-hq/ui';
import { hotColdLogsTableColumns } from './hot-cold-asset-logs-table-config';
import { HotColdAssetLogsDs } from '../../logic/hot-cold-asset-logs-ds';
import { HotColdAssetLogsFetcher } from '../../logic/hot-cold-asset-logs.fetcher';
import { HotColdChangeLogData } from '@vdms-hq/api-contract';

@Component({
  selector: 'vdms-hq-hot-cold-asset-logs-tab',
  standalone: true,
  imports: [CommonModule, UILayoutModule, UILoaderModule, MultipleDataPresentationComponent],
  providers: [HotColdAssetLogsDs, HotColdAssetLogsFetcher, UILoaderModule],
  templateUrl: './hot-cold-asset-logs-tab.component.html',
  styles: [],
})
export class HotColdAssetLogsTabComponent {
  @Input() withHeader = true;
  @Input() set assetUuid(value: string) {
    this.dataSource.assetUuid$.next(value);
  }

  columns: DataColumn[] = hotColdLogsTableColumns;
  enabled = ['log_id', 'log_type', 'created_date', 'message'];
  isLoading$ = null;

  configuration: MultipleViewConfiguration<HotColdChangeLogData> = {
    multiView: {
      defaultView: 'tableAdvanced',
      fitToVisibleArea: false,
      emptyResults: {
        message: 'Could not find any entities',
      },
    },
    tableAdvanced: {
      actions: [],
      columns: [
        {
          id: 'log_id',
          label: 'Log ID',
          valuePath: 'id',
          sortable: false,
        },
        {
          id: 'created_date',
          label: 'Created Date',
          valuePath: 'createdAt',
          sortable: false,
          viewFormat: {
            modifiers: {
              dateFormat: 'date-time',
            },
          },
        },
        {
          id: 'log_type',
          label: 'Changed to',
          valuePath: 'storageChangeDirection',
          sortable: false,
        },
        {
          id: 'message',
          label: 'Message',
          valuePath: 'message',
          sortable: false,
        },
      ],
      columnsEnabled: ['log_id', 'log_type', 'created_date', 'message'],
    },
  };

  constructor(public dataSource: HotColdAssetLogsDs) {}
}
