import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fieldLabel',
  pure: true,
  standalone: true,
})
export class LabelPipe implements PipeTransform {
  transform(field: string): string {
    return `common.credentials.form.visible_data.${field}`;
  }
}
