<ng-container [ngSwitch]="config.input.type" *ngIf="config">
  <vdms-hq-ui-form-input-text *ngSwitchCase="'text'" [formControl]="innerFormControl" [label]="config.label | translate"></vdms-hq-ui-form-input-text>
  <vdms-hq-ui-form-input-number *ngSwitchCase="'number'" [formControl]="innerFormControl" [label]="config.label | translate"></vdms-hq-ui-form-input-number>
  <ng-container *ngIf="config.sourceListKey">
    <vdms-hq-selector *ngSwitchCase="'select'" [formControl]="innerFormControl" [label]="config.label | translate" [sourceType]="config.sourceListKey"></vdms-hq-selector>
  </ng-container>
  <ng-container *ngIf="config.sourceListKey">
    <vdms-hq-selector *ngSwitchCase="'language_selector'" [formControl]="innerFormControl" [label]="config.label | translate"[sourceType]="config.sourceListKey"></vdms-hq-selector>
  </ng-container>
</ng-container>
