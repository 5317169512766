import { Component, Input } from '@angular/core';
import { UILayoutModule } from '../../../ui-layout/ui-layout.module';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'vdms-hq-ui-runtime-error',
  imports: [UILayoutModule, MatIconModule],
  template: `<div class="runtime-error"><mat-icon>error</mat-icon>Runtime error: {{ message }}</div>`,
  standalone: true,
  styleUrls: ['./runtime-error.component.scss'],
})
export class RuntimeErrorComponent {
  @Input() message = 'Missing configuration';
}
