import { Route } from '@angular/router';
import { CartCheckoutGuard } from './logic/services/cart-checkout.guard';
import { CartComponent } from './pages/cart/cart.component';

export const CART_ROUTES: Route[] = [
  {
    path: '',
    component: CartComponent,
  },
  {
    path: 'checkout',
    loadComponent: () => import('./pages/checkout/checkout.component').then((m) => m.CheckoutComponent),
    pathMatch: 'full',
    canDeactivate: [CartCheckoutGuard],
  },
  {
    path: 'order-summary',
    loadComponent: () => import('./pages/order-summary/order-summary.component').then((m) => m.OrderSummaryComponent),
    pathMatch: 'full',
  },
];
