import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as miActions from '../actions/media-insights.action';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { RunMediaInsightsDialogComponent } from '../../view-components/run-media-insights-dialog/run-media-insights-dialog.component';
import { DialogResponse } from '../../../shared/logic/models/dialog';
import { runMediaInsightsWorkflow } from '../actions/media-insights.action';
import { AutoloadEffect } from '@vdms-hq/state';
import { MediaInsightsApiService } from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';

@Injectable()
export class MediaInsightsEffect {
  popToast = {
    START_WORKFLOW_PROCESSING: () =>
      this.toastService.processing({
        id: 'media_insights_workflow',
        message: 'notifications.media_insights.starter_workflow.processing',
      }),
    START_WORKFLOW_FAIL: () =>
      this.toastService.error({
        id: 'media_insights_workflow',
        message: 'notifications.media_insights.starter_workflow.fail',
      }),
    START_WORKFLOW_SUCCESS: () =>
      this.toastService.success({
        id: 'media_insights_workflow',
        message: 'notifications.media_insights.starter_workflow.success',
      }),
  };

  runMediaInsightsTrigger$ = createEffect(() =>
    this.actions$.pipe(
      ofType(miActions.runMediaInsightsTrigger),
      switchMap(({ assetUuid }) => {
        const payload = {};

        return this.dialog
          .open(RunMediaInsightsDialogComponent)
          .afterClosed()
          .pipe(
            take(1),
            mergeMap((dialogResponse) => {
              if (dialogResponse?.status === DialogResponse.OK) {
                const objectRecognition = !!dialogResponse?.triggerList.find(
                  (item) => item === 'mi_object_recognition',
                );
                const celebrityRecognition = !!dialogResponse?.triggerList.find(
                  (item) => item === 'mi_celebrity_recognition',
                );
                const transcribe = !!dialogResponse?.triggerList.find((item) => item === 'mi_transcribe');
                const subtitles = !!dialogResponse?.triggerList.find((item) => item === 'mi_subtitles');
                const technicalCueDetection = !!dialogResponse?.triggerList.find(
                  (item) => item === 'mi_technical_cue_detection',
                );
                const shotDetection = !!dialogResponse?.triggerList.find((item) => item === 'mi_shot_detection');

                if (objectRecognition) {
                  payload['objectRecognition'] = true;
                }
                if (celebrityRecognition) {
                  payload['celebrityRecognition'] = true;
                }
                if (technicalCueDetection) {
                  payload['technicalCueDetection'] = true;
                }
                if (shotDetection) {
                  payload['shotDetection'] = true;
                }

                if (transcribe) {
                  payload['transcribe'] = {
                    sourceLanguage: dialogResponse?.language?.source,
                  };
                }
                if (subtitles) {
                  payload['subtitles'] = {
                    targetLanguages: dialogResponse?.language?.target,
                  };
                }

                return [
                  runMediaInsightsWorkflow({
                    assetUuid,
                    payload,
                  }),
                ];
              }

              return [miActions.abortDialog()];
            }),
          );
      }),
    ),
  );

  runMediaInsightsWorkflow$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof miActions.runMediaInsightsWorkflow>>(miActions.runMediaInsightsWorkflow.type),
      tap(() => this.popToast.START_WORKFLOW_PROCESSING()),
      mergeMap(({ payload, assetUuid }) =>
        this.lambda.runWorkflow(payload, assetUuid).pipe(
          mergeMap((response) => [
            miActions.runMediaInsightsWorkflowSuccess({ response }),
            miActions.mediaInsightsWorkflowStartSuccess(),
          ]),
          catchError((reason) => of(miActions.runMediaInsightsWorkflowFail({ error: reason }))),
        ),
      ),
    ),
  );

  checkMediaInsightsStatusWorkflow$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof miActions.checkMediaInsightsWorkflowStatus>>(
        miActions.checkMediaInsightsWorkflowStatus.type,
      ),
      mergeMap((data) => {
        return this.lambda.getStatusWorkflow(data.workflowUuid, data.operator).pipe(
          map((response) => {
            return miActions.checkMediaInsightsWorkflowStatusSuccess({
              workflow: response,
              language: data.operator.split('_')[1],
            });
          }),
          catchError((reason) =>
            of(
              miActions.checkMediaInsightsWorkflowStatusFail({
                error: reason,
              }),
            ),
          ),
        );
      }),
    ),
  );

  onSuccessStartedWorkflow$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(miActions.mediaInsightsWorkflowStartSuccess),
        tap(() => this.popToast.START_WORKFLOW_SUCCESS()),
      ),
    { dispatch: false },
  );

  afterFailStartedWorkflow$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(miActions.mediaInsightsWorkflowStartFail),
        tap(() => this.popToast.START_WORKFLOW_FAIL()),
      ),
    { dispatch: false },
  );

  triggerReset$ = this.autoloadEffect.whenLogoutOrClientChange(miActions.clearMediaInsightsState);

  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
    private lambda: MediaInsightsApiService,
    private autoloadEffect: AutoloadEffect,
    private toastService: ToastService,
  ) {}
}
