import { Component } from '@angular/core';
import { SearchBarVisibilityService } from '../../logic/search-bar-visibility.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-browse-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent {
  isVisible$ = this.searchBarVisibility.isVisible$.pipe(debounceTime(0));

  constructor(private searchBarVisibility: SearchBarVisibilityService) {}
}
