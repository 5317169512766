import { Route } from '@angular/router';
import { NotificationSubscriptionComponent } from './pages/notification-subscription/notification-subscription.component';

export enum NOTIFICATION_SUBSCRIPTION_ROUTES {
  ROOT = 'notification-subscriptions',
  NOTIFICATIONS_PATH = 'account-settings/notification-center',
}

export const NOTIFICATION_SUBSCRIPTION_ROUTING: Route[] = [
  {
    path: '',
    component: NotificationSubscriptionComponent,
  },
];
