import { Injectable, OnDestroy } from '@angular/core';
import { filter, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { RightsContractsService } from '@vdms-hq/api-contract';
import { RightsContractsRefreshService } from './rights-contracts-refresh.service';
import { MatDialog } from '@angular/material/dialog';
import { UIConfirmationDialogService } from '@vdms-hq/ui';
import { ToastService } from '@vdms-hq/toast';
import { TranslateService } from '@ngx-translate/core';
import { RightsContractsResultsMultiDs } from './rights-contracts-results-multi-ds.service';
import { RightsContractsCreateEditDialogComponent } from '../components/rights-contracts-create-edit-dialog/rights-contracts-create-edit-dialog.component';
import { catchError } from 'rxjs/operators';
import { RightsContractsAddDsService } from './rights-contracts-add-ds.service';

@Injectable({ providedIn: 'root' })
export class RightsContractsActionsService implements OnDestroy {
  private destroyed$ = new Subject<void>();

  popToast = {
    CREATE_SUCCESS: () =>
      this.toastService.success({
        id: 'create-rights-contracts',
        message: 'common.notifications.rights_contracts.create.success',
      }),
    CREATE_FAILURE: () =>
      this.toastService.error({
        id: 'create-rights-contracts',
        message: 'common.notifications.rights_contracts.create.failure',
      }),
    UPDATE_SUCCESS: () =>
      this.toastService.success({
        id: 'update-rights-contracts',
        message: 'common.notifications.rights_contracts.update.success',
      }),
    UPDATE_FAILURE: () =>
      this.toastService.error({
        id: 'update-rights-contracts',
        message: 'common.notifications.rights_contracts.update.failure',
      }),
    DELETE_SUCCESS: () =>
      this.toastService.success({
        id: 'delete-rights-contracts',
        message: 'common.notifications.rights_contracts.delete.success',
      }),
    DELETE_FAILURE: () =>
      this.toastService.error({
        id: 'delete-rights-contracts',
        message: 'common.notifications.rights_contracts.delete.failure',
      }),
    GET_ONE_FAILURE: () =>
      this.toastService.error({
        id: 'get-one-rights-contracts',
        message: 'common.notifications.rights_contracts.get_one.failure',
      }),
  };

  constructor(
    private rightsContractsService: RightsContractsService,
    private rightsContractsResultsMultiDsService: RightsContractsResultsMultiDs,
    private rightsContractsRefreshService: RightsContractsRefreshService,
    private rightsContractsAddDsService: RightsContractsAddDsService,
    private matDialog: MatDialog,
    private confirmationDialog: UIConfirmationDialogService,
    private toastService: ToastService,
    private translate: TranslateService,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  createDialog = () => {
    this.matDialog.open(RightsContractsCreateEditDialogComponent, { data: { uuid: undefined } });
  };

  editDialog = (uuid: string) => {
    this.matDialog.open(RightsContractsCreateEditDialogComponent, { data: { uuid } });
  };

  editLicensedPackagesDialog = (uuid: string) => {
    this.matDialog.open(RightsContractsCreateEditDialogComponent, { data: { uuid, editPackages: true } });
  };

  deleteDialog = (uuid: string, contractName: string) => {
    this.confirmationDialog
      .openDelete({
        title: 'common.dialogs.rights_contracts.delete.title',
        message: this.translate.instant('common.dialogs.rights_contracts.delete.message', { contractName }),
        okAction: {
          label: 'common.dialogs.rights_contracts.delete.button_confirm',
          color: 'primary',
        },
        abortAction: {
          label: 'common.dialogs.rights_contracts.delete.button_cancel',
          color: 'warn',
        },
      })
      .pipe(
        takeUntil(this.destroyed$),
        take(1),
        filter(Boolean),
        tap(() => this.rightsContractsResultsMultiDsService.isLoading$.next(true)),
        switchMap(() =>
          this.rightsContractsService.remove(uuid).pipe(
            tap(() => {
              this.rightsContractsResultsMultiDsService.isLoading$.next(false);
              this.rightsContractsRefreshService.refreshRightsContractsList$.next(true);
              this.rightsContractsAddDsService.refresh$.next(true);
              this.popToast.DELETE_SUCCESS();
            }),
            catchError((error) => {
              this.rightsContractsResultsMultiDsService.isLoading$.next(false);
              this.popToast.DELETE_FAILURE();
              throw error;
            }),
          ),
        ),
      )
      .subscribe();
  };
}
