import { Routes } from '@angular/router';
import { DashboardsComponent } from './components/dashboards/dashboards.component';

export enum DASHBOARDS_ROUTER_BASE {
  DASHBOARDS = 'dashboards',
}

export const dashboardsRoutes: Routes = [
  {
    path: '',
    component: DashboardsComponent,
  },
  {
    path: '**',
    redirectTo: DASHBOARDS_ROUTER_BASE.DASHBOARDS,
    pathMatch: 'full',
  },
];
