import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByExceptNull',
})
export class SortByExceptNullPipe implements PipeTransform {
  exceptions = ['N/A', 'Please select', 'None', 'All', 'common.orders.download.all'];

  transform(array: Array<{ [key: string]: any }>, sortBy: string): Array<{ [key: string]: any }> {
    if (sortBy == '') {
      return array;
    }
    return this.sortArray(array, sortBy);
  }

  sortArray(array: Array<{ [key: string]: any }>, sortBy: string): Array<{ [key: string]: any }> {
    return array.sort((a, b) => {
      if (this.exceptions.includes(a[sortBy]) || this.exceptions.includes(b[sortBy])) {
        return 1;
      } else {
        return a?.[sortBy]?.localeCompare(b?.[sortBy]);
      }
    });
  }
}
