export interface GetDiscountCodeInterface {
  uuid: string;
  name: string;
  code: string;
  is_active: boolean;
  multiplier: MultiplierDiscountCodeInterface;
}
export type PostDiscountCodeInterface = Omit<GetDiscountCodeInterface, 'uuid'>;
export type PatchDiscountCodeInterface = Omit<GetDiscountCodeInterface, 'uuid'>;

export interface MultiplierDiscountCodeInterface {
  egress_price_per_gb?: number;
  restoration_price_per_gb?: number;
  asset_processing_fee_price_per_asset?: number;
  transcode: {
    transcode_sd_price_per_min?: number;
    transcode_hd_price_per_min?: number;
    transcode_uhd_price_per_min?: number;
    transcode_5k_price_per_min?: number;
    transcode_6k_price_per_min?: number;
    transcode_8k_price_per_min?: number;
  };
}

export type DiscountsFiltersView = {
  keyword?: string;
  active?: boolean;
  sort?: string;
  direction?: string;
};

export const DISCOUNTS_SORT_BY = [
  {
    key: 'createdAt_desc',
    label: 'common.sort.created_newest_first',
  },
  {
    key: 'createdAt_asc',
    label: 'common.sort.created_latest_first',
  },
  {
    key: 'name_asc',
    label: 'common.sort.name_az',
  },
  {
    key: 'name_desc',
    label: 'common.sort.name_za',
  },
];

export const DISCOUNTS_STATUS = [
  { key: true, label: 'Active' },
  { key: false, label: 'Inactive' },
];
