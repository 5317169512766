import { Component, Input } from '@angular/core';
import { UILoaderModule } from '../../../ui-loader/ui-loader.module';
import { Selection, SelectionState, SelectionVisibility } from '@vdms-hq/shared';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RuntimeErrorComponent } from '../runtime-error/runtime-error.component';

@Component({
  selector: 'vdms-hq-ui-select-all-on-page',
  imports: [UILoaderModule, CommonModule, MatCheckboxModule, RuntimeErrorComponent],
  template: `
    <ng-container *ngIf="selection; else missingSelection">
      <ng-container *ngIf="(selection.visibility$ | async) !== SelectionVisibility.HIDDEN">
        <mat-checkbox
          *ngIf="selection.state$ | async; let state"
          [indeterminate]="state === SelectionState.SOME"
          [checked]="state === SelectionState.ALL_ON_PAGE"
          (change)="selection.toggleAllVisible($event.checked)"
          [disabled]="(selection.visibility$ | async) === SelectionVisibility.DISABLED"
        >
        </mat-checkbox>
      </ng-container>
    </ng-container>

    <ng-template #missingSelection>
      <vdms-hq-ui-runtime-error message="Missing selection Input"></vdms-hq-ui-runtime-error>
    </ng-template>
  `,
  standalone: true,
})
export class SelectAllOnPageComponent<T> {
  @Input() selection?: Selection<T>;
  protected readonly SelectionVisibility = SelectionVisibility;

  protected readonly SelectionState = SelectionState;
}
