import { Progress, TransferableBatch, UploadStatus } from '../../model/transferable-file.interface';
import { BehaviorSubject } from 'rxjs';
import { AsperaFile } from '../model/aspera-select-event.model';
import * as uuid from 'uuid';
import { AsperaStatus, TransferEvent } from '../model/aspera-transfer-event.model';
import { AsperaTransferFile } from './aspera-batch-file';

export const transformAsperaStatus = (status: AsperaStatus): UploadStatus => {
  switch (status) {
    case 'failed':
      return 'failed';
    case 'cancelled':
      return 'cancelled';
    case 'initiating':
      return 'pending';
    case 'running':
      return 'pending';
    case 'completed':
      return 'done';
  }

  return 'not_started';
};

export class AsperaTransferBatch implements TransferableBatch {
  public transferUuid?: string;

  constructor(public batchId: string, public files: AsperaTransferFile[], public state$: BehaviorSubject<Progress>) {}

  public static fromAsperaFiles(files: AsperaFile[], fileIds: string[] = []) {
    const asperaFiles: AsperaTransferFile[] = [];

    const state$ = new BehaviorSubject<Progress>({ status: 'not_started', progress: 0 });

    files.forEach((file, key) => {
      const id = fileIds[key];
      asperaFiles.push(AsperaTransferFile.fromAsperaSelect(file, state$, id));
    });

    return new AsperaTransferBatch(uuid.v4(), asperaFiles, state$);
  }

  static fromAsperaTransferEvent(eventTransfer: TransferEvent) {
    const batchId = eventTransfer.transfer_spec.batch_id;
    const state$ = new BehaviorSubject<Progress>({
      status: transformAsperaStatus(eventTransfer.status),
      progress: Math.ceil(eventTransfer.percentage * 100),
    });

    return new AsperaTransferBatch(
      batchId,
      eventTransfer.transfer_spec.files_metadata?.map((file) => AsperaTransferFile.fromArray(file, state$)) ?? [],
      state$,
    );
  }

  setTransferUuid(uuid: string) {
    this.transferUuid = uuid;
  }

  update(progress: Progress) {
    this.state$.next(progress);
  }
}
