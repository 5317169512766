import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { MediaInsightsReducer, MediaInsightsState } from './media-insights.reducer';

export const featureKey = 'media-insights';

export interface MediaInsightsFeature {
  metadata: MediaInsightsState;
}

export const reducers: ActionReducerMap<MediaInsightsFeature> = {
  metadata: MediaInsightsReducer,
};

const featureSelector = createFeatureSelector<MediaInsightsFeature>(featureKey);
export const getMediaInsightsStatusLoading = createSelector(featureSelector, (state) => state.metadata.loading);
export const getMediaInsightsMetadata = createSelector(featureSelector, (state) => state.metadata.metadata);
export const getMediaInsightStatus = createSelector(featureSelector, (state) => state.metadata.status ?? '-');
export const getMediaInsightSubtitles = createSelector(featureSelector, (state) => {
  if (state.metadata.vtt) {
    return [
      {
        path: state.metadata.vtt,
        language: state.metadata.language,
      },
    ];
  }
});
