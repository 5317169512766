import { ChangeDetectionStrategy, Component, Input, QueryList, ViewChildren } from '@angular/core';
import {
  ExternalMenuItem,
  isExternal,
  isInternal,
  SidenavState,
  SidenavStatusEnum,
  UISidenavConfig,
  UISidenavMenuItem,
} from '../../logic/models/sidenav.model';
import { TourConfig } from '../sidenav.component';
import { UIConfirmationDialogService } from '../../../ui-dialog';
import { filter, take } from 'rxjs/operators';
import { DestroyComponent, PermissionAwareItem } from '@vdms-hq/shared';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'vdms-hq-ui-menu[config]',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UISidenavMenuComponent extends DestroyComponent {
  #state: SidenavState = 'expanded';
  showLabels = true;
  currentTab$ = new BehaviorSubject<(UISidenavMenuItem & PermissionAwareItem) | null>(null);
  @Input() branding?: boolean;
  @Input() isSalesForceEnabled = false;
  @Input() isExternalSupport = false;
  @Input() config: UISidenavConfig = [];

  @Input() set state(value: SidenavState) {
    this.#state = value;
    switch (value) {
      case SidenavStatusEnum.Collapsed:
        this.showLabels = false;
        break;
      case SidenavStatusEnum.Closed:
        this.showLabels = false;
        break;
      case SidenavStatusEnum.Hovered:
        this.showLabels = true;
        break;
      default:
        this.showLabels = true;
    }
  }

  get state() {
    return this.#state;
  }

  @Input() tourConfig: TourConfig = {};
  @Input() version = '';

  @ViewChildren('menuLink') elements!: QueryList<any>;

  isInternal = isInternal;
  isExternal = isExternal;

  constructor(private confirmationDialog: UIConfirmationDialogService) {
    super();
  }

  showConfirmationDialog(item: ExternalMenuItem) {
    this.confirmationDialog
      .open({
        title: item.label,
        message: item.confirmation,
        okAction: {
          label: 'common.global.confirm',
          color: 'primary',
        },
        abortAction: {
          label: 'common.global.cancel',
          color: 'secondary',
        },
      })
      .pipe(take(1), filter(Boolean), this.takeUntilDestroyed())
      .subscribe(() => {
        window.open(item.href, '_blank');
      });
  }

  setCurrentTab(route: (UISidenavMenuItem & PermissionAwareItem) | null) {
    route && this.currentTab$.next(route);
  }

  #initTab() {
    const currentTab = this.config
      ?.reduce((prev: Array<UISidenavMenuItem & PermissionAwareItem>, curr) => [...prev, ...curr.menuItems], [])
      ?.find((route) => {
        if ('route' in route) {
          return window.location.pathname.includes(route.route);
        }

        return window.location.pathname.includes(route.id);
      });
    currentTab && this.setCurrentTab(currentTab);
  }
}
