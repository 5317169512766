import { Pipe, PipeTransform } from '@angular/core';
import { FormatBitratePipe, FormatBytesPipe, Modifiers, RendererValue } from '@vdms-hq/shared';
import { camelCase, capitalize, isNumber, isString, snakeCase } from 'lodash';
import { DatePipe } from '@angular/common';
import moment, { isDate } from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { TzDatePipe } from '@vdms-hq/auth';

@Pipe({ name: 'valueModifier', standalone: true })
export class ValueModifierPipePipe implements PipeTransform {
  readonly #formatting: {
    dateFormat: string;
    dateTimeFormat: string;
    maxVisibleCharacters: number;
  } = {
    dateFormat: 'dd-MM-yyyy',
    dateTimeFormat: 'dd-MM-yyyy hh:mm a',
    maxVisibleCharacters: 50,
  };

  constructor(private translateService: TranslateService, private datePipe: DatePipe, private tzDaTe: TzDatePipe) {}

  transform(value: RendererValue, modifiers: Modifiers): { nextValue: RendererValue; showTooltip?: boolean } {
    let nextValue = value;
    let showTooltip = false;
    const format = this.#extractDateFormat(modifiers.dateFormat);

    if ('dateFormat' in modifiers && nextValue && (isDate(nextValue) || isString(nextValue) || isNumber(nextValue))) {
      const momentDate = moment(<Date | string | number>nextValue, undefined, undefined, true);
      if (momentDate.isValid()) {
        if (format == 'days_ago') {
          nextValue = momentDate.fromNow();
        } else if ('timeZoneSensitive' in modifiers) {
          nextValue = this.tzDaTe.transform(nextValue, format ?? this.#formatting.dateFormat);
        } else {
          nextValue = this.datePipe.transform(momentDate.toDate(), format);
        }
      }
    }

    if ('bitrateFormat' in modifiers && (isNumber(nextValue) || isString(nextValue))) {
      nextValue = FormatBitratePipe.transformWithUnit(Number(nextValue));
    }

    if ('discountValue' in modifiers && isNumber(nextValue)) {
      nextValue = ((1 - nextValue) * 100).toFixed(0) + '%';
    }

    if ('fileSizeFormat' in modifiers && (isNumber(nextValue) || isString(nextValue))) {
      nextValue = FormatBytesPipe.transformWithUnit(Number(nextValue));
    }

    if ('yesNo' in modifiers) {
      nextValue = nextValue == true ? 'Yes' : 'No';
    }

    if ('yesFallback' in modifiers) {
      nextValue = nextValue == true ? 'Yes' : null;
    }

    if ('translate' in modifiers) {
      nextValue = this.translateService.instant(String(nextValue));
    }

    if ('truncateCharacters' in modifiers && isString(nextValue) && nextValue) {
      const maxChars = modifiers?.truncateCharacters ?? this.#formatting.maxVisibleCharacters;
      if (nextValue.length > maxChars) {
        nextValue = nextValue.substring(0, maxChars) + '...';
        showTooltip = true;
      }
    }

    if ('asPercentage' in modifiers && isNumber(nextValue) && nextValue) {
      nextValue = nextValue + '%';
    }

    if ('removeDashes' in modifiers && isString(nextValue) && nextValue) {
      nextValue = nextValue.replace('-', ' ');
    }

    if ('removeUnderscores' in modifiers && isString(nextValue) && nextValue) {
      nextValue = nextValue.replace('_', ' ');
    }

    if ('textTransform' in modifiers && isString(nextValue) && nextValue) {
      if (modifiers.textTransform === 'uppercase') {
        nextValue = nextValue.toUpperCase();
      } else if (modifiers.textTransform === 'camelCase') {
        nextValue = camelCase(nextValue);
      } else if (modifiers.textTransform === 'snake_case') {
        nextValue = snakeCase(nextValue);
      } else if (modifiers.textTransform === 'capitalize') {
        nextValue = capitalize(nextValue);
      }
    }

    if ('asNumberWithZero' in modifiers && (typeof nextValue === 'number' || typeof nextValue === 'string')) {
      nextValue = Number(value) === 0 ? '0' : Number(value);
    }

    return {
      nextValue,
      showTooltip,
    };
  }

  #extractDateFormat(dateFormat: string | 'date' | 'date-time' | undefined) {
    if (dateFormat === 'date') {
      return this.#formatting.dateFormat;
    }

    if (dateFormat === 'date-time') {
      return this.#formatting.dateTimeFormat;
    }

    return dateFormat;
  }
}
