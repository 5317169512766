import { SelectionIdentifier, SelectOption } from '@vdms-hq/shared';

interface transformerUser {
  uuid: string;
  name: string;
  email?: string;
  emailAddress?: string;
}

export const transformUserToSelectOption = (user: transformerUser): SelectOption<SelectionIdentifier> => {
  return {
    key: user.uuid,
    label: `<span title="${user.email ?? user.emailAddress}">${user.name}</span>`,
  };
};

export const transformUserToSelectOptionMail = (user: transformerUser): SelectOption<SelectionIdentifier> => {
  return {
    key: user.uuid,
    label: user.email ?? user.emailAddress ?? '-',
  };
};
