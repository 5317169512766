import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormSectionComponent, UIButtonModule, UIDialogWrapperModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { OrderService } from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import { SelectOption } from '@vdms-hq/shared';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';

export type ReDeliverOrderDialogInput = {
  orderId: string;
};

@Component({
  selector: 'vdms-hq-re-deliver-order-dialog',
  templateUrl: './re-deliver-order-dialog.component.html',
  imports: [
    CommonModule,
    MatDialogModule,
    UIDialogWrapperModule,
    FormSectionComponent,
    UIFormModule,
    UIButtonModule,
    TranslateModule,
    UILayoutModule,
    MatCheckboxModule,
  ],
  standalone: true,
})
export class ReDeliverOrderDialogComponent implements OnInit {
  #popToast = {
    CREATE_SUCCESS: () =>
      this.toastService.success({
        id: 'load_emails_success',
        message: 'common.orders.redeliver_order.success',
      }),
    CREATE_FAILURE: (reason: string = 'Not specified') =>
      this.toastService.error({
        id: 'redeliver_emails_failure',
        message: 'common.orders.redeliver_order.failure',
        interpolatedParams: { reason },
      }),
    LOAD_FAILURE: (reason: string = 'Not specified') =>
      this.toastService.error({
        id: 'load_emails_failure',
        message: 'common.orders.redeliver_order.load_failure',
        interpolatedParams: { reason },
      }),
  };

  loading = false;
  saving = false;

  emails: SelectOption[] = [];

  constructor(
    public dialogRef: MatDialogRef<ReDeliverOrderDialogComponent>,
    private ordersApiService: OrderService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: ReDeliverOrderDialogInput,
  ) {}

  form = new FormGroup({
    emails: new FormControl<string[]>([], {
      validators: Validators.required,
      nonNullable: true,
    }),
  });

  ngOnInit(): void {
    this.loading = true;
    this.ordersApiService
      .reDeliveryEmails(this.data.orderId)
      .pipe(
        catchError(() => {
          this.#popToast.LOAD_FAILURE();

          return of([]);
        }),
      )
      .subscribe((emails) => {
        this.emails = emails.map((email) => ({ key: email, label: email }));
        this.loading = false;
      });
  }

  abort() {
    this.dialogRef.close();
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.saving = true;

    this.ordersApiService
      .reDeliverClips(this.data.orderId, this.form.controls.emails.value)
      .pipe(
        catchError(() => {
          this.#popToast.CREATE_FAILURE();

          return of(false);
        }),
      )
      .subscribe((response) => {
        this.saving = false;
        if (response !== false) {
          this.dialogRef.close();
          this.#popToast.CREATE_SUCCESS();
        }
      });
  }
}
