import { Routes } from '@angular/router';
import { OrderResultsComponent } from './results/pages/order-results/order-results.component';
import { SharedPacksResultsComponent } from './results/pages/shared-packs-results/shared-packs-results.component';
import { OrderDetailsComponent } from './details/pages/order-details/order-details.component';
import { OrderResolverService } from './details/logic/order-resolver.service';
import { SharedPacksDetailsComponent } from './details/pages/shared-packs-details/shared-packs-details.component';

export enum ORDERS_ROUTER_BASE {
  ORDERS = 'orders',
  ORDERS_ALL = 'orders/all',
  ORDERS_APPROVAL = 'orders/approval',
  SHARED_PACKS = 'shared-packs',
  ORDERS_MEDIA_PULSE = 'mediapulse-orders',
}

export const ordersRouting: Routes = [
  {
    path: 'all',
    component: OrderResultsComponent,
  },
  {
    path: 'approval',
    component: OrderResultsComponent,
  },
  {
    path: ':orderId',
    component: OrderDetailsComponent,
    resolve: {
      orderId: OrderResolverService,
    },
    providers: [OrderResolverService],
  },
  {
    path: 'approval/:orderId',
    component: OrderDetailsComponent,
    resolve: {
      orderId: OrderResolverService,
    },
    providers: [OrderResolverService],
  },
  {
    path: '**',
    redirectTo: `/${ORDERS_ROUTER_BASE.ORDERS_ALL}`,
    pathMatch: 'full',
  },
];

export const sharedPacksRouting: Routes = [
  {
    path: '',
    component: SharedPacksResultsComponent,
  },
  {
    path: ':orderId',
    component: SharedPacksDetailsComponent,
    resolve: {
      orderId: OrderResolverService,
    },
    providers: [OrderResolverService],
  },
  {
    path: '**',
    redirectTo: `/${ORDERS_ROUTER_BASE.SHARED_PACKS}`,
    pathMatch: 'full',
  },
];
