import { AfterViewInit, Component } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { read, utils, WorkSheet } from 'xlsx';
import { AnyFileComponent } from '../any-file.component';
import { PreviewErrorModel } from '../../logic/preview-error.model';

@Component({
  selector: 'vdms-hq-ui-sheet-support',
  templateUrl: './sheet-support.component.html',
  styleUrls: ['./sheet-support.component.scss', './../any-file.component.scss'],
})
export class SheetSupportComponent extends AnyFileComponent implements AfterViewInit {
  static supportsExtensions = ['xlsx', 'ods', 'csv'] as const;
  html: SafeHtml = '';
  dimension: { cols: number; rows: number } | null = null;
  error: PreviewErrorModel | null = null;

  #xlsxCols = 50;
  #xlsxRows = 500;

  async ngAfterViewInit(): Promise<void> {
    try {
      const buffer = await this.fetchBuffer();

      if (buffer) {
        const wb = read(buffer);
        const workSheet: WorkSheet = this.#overrideWorksheetDimension(wb.Sheets[wb.SheetNames[0]]);

        this.html = this.sanitizer.bypassSecurityTrustHtml(utils.sheet_to_html(workSheet));
      }
      this.loaded();
    } catch (e) {
      this.error = {
        message: String(e),
        header: 'Problem during render preview for this file',
      };
      this.loaded();
    }
    this.cdr.detectChanges();
  }

  #overrideWorksheetDimension(workSheet: WorkSheet): WorkSheet {
    const { s: start, e: end } = utils.decode_range(workSheet['!fullref'] ?? workSheet['!ref']);

    this.dimension = { rows: end.r, cols: end.c };

    end.c = end.c > this.#xlsxCols ? this.#xlsxCols : end.c;
    end.r = end.r > this.#xlsxRows ? this.#xlsxRows : end.r;

    workSheet['!ref'] = `${utils.encode_range(start, end)}`;
    return workSheet;
  }
}
