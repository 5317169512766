<div class="header--actions__wrapper" [class.header-actions]="headerClass">
  <ng-container *ngFor="let actionDef of actions ?? []">
    <vdms-hq-ui-button
      (click)="!actionDef.disabled && headerAction.emit({ event: $event, key: actionDef.key })"
      [color]="actionDef.color"
      [disabled]="actionDef.disabled ?? false"
      [iconOnly]="actionDef.iconOnly ?? false"
      [icon]="actionDef.icon"
      [matTooltip]="actionDef.iconOnly ? (actionDef.name | translate) : undefined"
    >
      {{ !actionDef.iconOnly ? (actionDef.name | translate) : undefined }}
    </vdms-hq-ui-button>
  </ng-container>
</div>
