import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DialogResponse, pairWithDefinition, SelectOption, SelectOptionKey } from '@vdms-hq/shared';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { FieldsConfigService } from '@vdms-hq/config';
import { combineLatest, Subject } from 'rxjs';
import { ColumnSettingsScope } from '@vdms-hq/firebase-contract';
import { FieldConfigId } from '@vdms-hq/shared';
import { FiltersFetcherService } from '../../filters-fetcher.service';

@Component({
  selector: 'vdms-hq-filters-config-dialog',
  templateUrl: './filters-config-dialog.component.html',
  styleUrls: ['./filters-config-dialog.component.scss'],
})
export class FiltersConfigDialogComponent implements OnInit, OnDestroy {
  formGroup = new UntypedFormGroup({
    listSortableCheckboxValue: new UntypedFormControl([], Validators.required),
  });
  availableListKey: SelectOptionKey[] = [];
  defaultListKey: SelectOptionKey[] = [];
  availableList: SelectOption[] = [];
  alwaysEnabledList = [];
  #destroyed = new Subject<void>();

  constructor(
    private activatedClient: ActivatedClientService,
    private activatedClientConfigService: FieldsConfigService,
    private filtersFetcherService: FiltersFetcherService,
    private columnsConfigDialogRef: MatDialogRef<FiltersConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { scopeName: ColumnSettingsScope },
  ) {}

  get listSortableCheckboxValues(): AbstractControl | null {
    return this.formGroup.get('listSortableCheckboxValue');
  }

  ngOnInit() {
    this.#initialize();
  }

  ngOnDestroy(): void {
    this.#destroyed.next();
    this.#destroyed.complete();
  }

  #initialize = () => {
    combineLatest([
      this.activatedClientConfigService.filterDefinitionsForAssetFiltersSettingsModal$,
      this.activatedClient.getFilters$(this.data.scopeName),
      this.filtersFetcherService.userConfig$(this.data.scopeName),
    ])
      .pipe(take(1))
      .subscribe(([all, clientConfig, userConfig]) => {
        this.defaultListKey = clientConfig ?? [];
        const available = clientConfig ?? [];

        this.availableListKey = [
          ...userConfig,
          ...available.filter((item: FieldConfigId) => !userConfig.includes(item)),
        ];

        this.availableList = pairWithDefinition(
          this.availableListKey,
          all.filter((item) => available.includes(item.key as string)),
        );

        this.listSortableCheckboxValues?.patchValue(userConfig.length > 0 ? userConfig : clientConfig);
      });
  };

  cancel(): void {
    this.columnsConfigDialogRef.close({ status: DialogResponse.ABORT });
  }

  onSubmit() {
    this.columnsConfigDialogRef.close({
      status: DialogResponse.OK,
      columns: this.listSortableCheckboxValues?.value,
    });
  }

  selectAll() {
    this.listSortableCheckboxValues?.patchValue(this.availableListKey);
  }

  deselectAll() {
    this.listSortableCheckboxValues?.patchValue([]);
  }

  revertDefaults() {
    this.listSortableCheckboxValues?.patchValue(this.defaultListKey);
  }
}
