import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UIButtonModule, UIDataSelectorComponent, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  BatchPostNotificationSubscription,
  EMAIL_NOTIFICATION_TYPES,
  EmailNotificationTypes,
  LicensedPackagesNotificationTypes,
  NotificationSubscriptionsService,
  PostNotificationSubscription,
  UserModel,
} from '@vdms-hq/api-contract';
import { UsersSelectorComponent, UsersShareDataSource } from '@vdms-hq/users';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, EMPTY, map, Observable, switchMap, take, tap } from 'rxjs';
import { DestroyComponent, SelectOption } from '@vdms-hq/shared';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from '@vdms-hq/auth';
import { ToastService } from '@vdms-hq/toast';

@Component({
  selector: 'vdms-hq-create-subscription-dialog',
  templateUrl: './create-subscription-dialog.component.html',
  styleUrls: ['./create-subscription-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    UIButtonModule,
    UIDialogWrapperModule,
    UIFormModule,
    UsersSelectorComponent,
    UIDataSelectorComponent,
    MatTooltipModule,
  ],
})
export class CreateSubscriptionDialogComponent extends DestroyComponent implements OnInit {
  #currentEditUser$ = new BehaviorSubject<SelectOption[]>([]);
  #scope: 'create' | 'edit' = this.data?.uuid ? 'edit' : 'create';
  #editSubscriptionUuid?: string;

  #licensedPackagesNotificationTypesArray = Array.from(Object.values(LicensedPackagesNotificationTypes));
  initialized$ = new BehaviorSubject<boolean>(false);
  loading$ = new BehaviorSubject<boolean>(false);

  notificationTypes$: Observable<SelectOption[]> = this.subscriptionService
    .getNotificationTypes()
    .pipe(map((types) => types.map((type) => ({ key: type, label: type }))));
  emailsNotificationTypes = [
    { key: EMAIL_NOTIFICATION_TYPES.PER_OCCURRENCE, label: 'Per Occurrence' },
    { key: EMAIL_NOTIFICATION_TYPES.DAILY_SUMMARY, label: 'Daily Summary' },
  ];
  selectedUsersControl = new FormControl<Array<UserModel['uuid']> | null>(null);
  form = new FormGroup({
    notification_event_type: new FormControl<string | null>(null, Validators.required),
    app_notifications_enabled: new FormControl<boolean>(false),
    email_notifications_enabled: new FormControl<boolean>(false),
    email_notification_type: new FormControl<EmailNotificationTypes>({
      value: EMAIL_NOTIFICATION_TYPES.PER_OCCURRENCE,
      disabled: true,
    }),
  });
  usersToSelect$!: Observable<SelectOption[]>;

  constructor(
    public usersDataSource: UsersShareDataSource,
    @Inject(MAT_DIALOG_DATA) private data: { uuid: string },
    private auth: AuthService,
    private ref: MatDialogRef<CreateSubscriptionDialogComponent>,
    private subscriptionService: NotificationSubscriptionsService,
    private toast: ToastService,
  ) {
    super();
    this.usersToSelect$ =
      this.#scope === 'edit'
        ? this.#currentEditUser$.asObservable()
        : this.usersDataSource.allData$.pipe(map((users) => [...users, { key: null, label: 'N/A', disabled: true }]));
  }

  get isEditing() {
    return this.#scope === 'edit';
  }

  ngOnInit() {
    this.#listenEmailNotificationChanges();
    this.#listenNotificationTypeChanges();
    this.isEditing ? this.#getSubscriptionDetails() : this.initialized$.next(true);
    this.usersDataSource.text$.next('');
  }

  close() {
    this.ref.close();
  }

  handleUsersInput(value: string) {
    this.usersDataSource.text$.next(value);
  }

  confirm() {
    if (this.form.invalid) {
      return;
    }

    this.loading$.next(true);
    this.auth.clientsInfo$
      .pipe(
        take(1),
        switchMap(({ selectedClientId }) => {
          const payload =
            this.selectedUsersControl.getRawValue() && !this.isEditing
              ? this.selectedUsersControl.value?.map(
                  (uuid) =>
                    ({
                      subscription_uuid: this.#editSubscriptionUuid,
                      group_uuid: selectedClientId,
                      user_uuid: uuid,
                      ...this.form.value,
                    } as BatchPostNotificationSubscription),
                )
              : ({
                  subscription_uuid: this.#editSubscriptionUuid,
                  group_uuid: selectedClientId,
                  ...this.form.value,
                } as PostNotificationSubscription);

          if (!payload) {
            return EMPTY;
          }

          if (!this.isEditing) {
            if (Array.isArray(payload)) {
              return this.subscriptionService.createMultipleSubscriptions(
                payload as BatchPostNotificationSubscription[],
              );
            }

            return this.subscriptionService.createSubscription(payload as PostNotificationSubscription);
          }

          if (Array.isArray(payload)) {
            return this.subscriptionService.updateMultipleSubscriptions(payload as BatchPostNotificationSubscription[]);
          }

          return this.subscriptionService.updateSubscription(this.data.uuid, payload);
        }),
        tap(() => this.loading$.next(true)),
      )
      .subscribe({
        next: () => {
          const message = this.isEditing
            ? 'common.notification_subscriptions.subscription_messages.update_success'
            : 'common.notification_subscriptions.subscription_messages.create_success';
          this.toast.success({ id: 'subscriptions', message });
          this.close();
        },
        error: () => {
          const message = this.isEditing
            ? 'common.notification_subscriptions.subscription_messages.update_failure'
            : 'common.notification_subscriptions.subscription_messages.create_failure';
          this.toast.error({ id: 'subscriptions', message });
          this.close();
        },
      });
  }

  #getSubscriptionDetails() {
    this.selectedUsersControl.disable();
    this.form.controls.notification_event_type.disable();
    this.subscriptionService.getSubscription(this.data.uuid as string).subscribe((data) => {
      this.selectedUsersControl.setValue([data.user.uuid]);
      this.form.patchValue(data);
      this.#editSubscriptionUuid = data.uuid;
      this.#currentEditUser$.next([{ key: data.user.uuid, label: `${data.user.name} (${data.user.email})` }]);
      this.initialized$.next(true);
    });
  }

  #listenEmailNotificationChanges() {
    this.form.controls.email_notifications_enabled.valueChanges
      .pipe(this.takeUntilDestroyed())
      .subscribe((isEnabled) => {
        const emailNotificationTypeControl = this.form.controls.email_notification_type;
        emailNotificationTypeControl[isEnabled ? 'enable' : 'disable']();
      });
  }

  #listenNotificationTypeChanges() {
    this.form.controls.notification_event_type.valueChanges.pipe(this.takeUntilDestroyed()).subscribe((eventType) => {
      const appNotificationTypeControl = this.form.controls.app_notifications_enabled;
      const onlyEmailNotification = this.#licensedPackagesNotificationTypesArray.includes(
        eventType as LicensedPackagesNotificationTypes,
      );
      appNotificationTypeControl[onlyEmailNotification ? 'disable' : 'enable']();
    });
  }
}
