<ng-container *ngIf="dataSource && configuration; else missingDataSource">
  <div class="grid-advanced" #gridAdvanced [class.grid-advanced--up-to-2]="configuration.layout?.columns === 'up-to-2'">
    <div
      class="grid-advanced__item"
      [class.selected-highlight]="isSelectable(dataSource) && (dataSource.selection.isSelected$(item) | async)"
      *ngFor="let item of dataSource.connection$ | async; trackBy: dataSource.trackBy ?? defaultTrackBy"
      (dblclick)="handleDoubleClick($event, item)"
      [matTooltip]="configuration.actions | isRowTooltip | translate"
      [matTooltipShowDelay]="500"
      (click)="isSelectable(dataSource) && !isRowDisabled(item) && selectItem(item)"
      (contextmenu)="showContextMenu($event, item)"
      [class.disabled-row]="(configuration.rows | disableIfRow : item).disabled"
    >
      <div class="grid-advanced__img" *ngIf="configuration.image">
        <vdms-hq-ui-tile-image
          [src]="$castToString(item | valueByPath : configuration.image.bgPath | async)"
          [hoverSrc]="$castToString(item | valueByPath : configuration.image.bgHoverPath | async)"
        >
          <vdms-hq-ui-select-one
            *ngIf="dataSource.selection"
            [selection]="dataSource.selection"
            [item]="item"
          ></vdms-hq-ui-select-one>
        </vdms-hq-ui-tile-image>
      </div>
      <div class="grid-advanced__content">
        <div class="grid-advanced__title">
          <ng-container *ngIf="configuration.content?.iconPath; let iconPath">
            <mat-icon *ngIf="item | valueByPath : iconPath | async as icon" inline="true">{{ icon }}</mat-icon>
          </ng-container>
          <span>{{ item | valueByPath : configuration.content?.titlePath | async }}</span>
          <div *ngIf="configuration.content?.additionalInfo" class="grid-advanced__title-additional">
            <vdms-hq-ui-value-renderer
              [value]="item | valueByPath : configuration.content?.additionalInfo?.valuePath | async"
              [format]="configuration.content?.additionalInfo?.viewFormat"
              [fallback]="configuration.content?.additionalInfo?.fallback ?? 'N/A'"
            ></vdms-hq-ui-value-renderer>
          </div>
        </div>
        <div class="grid-advanced__metadata">
          <ng-container *ngFor="let metadata of configuration.content?.metadata ?? []">
            <div *ngIf="!(metadata | metadataHidden : item)" [class.w-full]="metadata.fullLine">
              <span class="grid-advanced__metadata-label">{{ metadata.label | translate }}: </span>
              <vdms-hq-ui-value-renderer
                [value]="item | valueByPath : metadata.valuePath | async"
                [format]="metadata.viewFormat"
                [fallback]="metadata.fallback ?? 'N/A'"
              ></vdms-hq-ui-value-renderer>
            </div>
          </ng-container>
        </div>
      </div>

      <vdms-hq-ui-floating-controls-v2
        *ngIf="configuration.actions && configuration.actions.length > 0"
        class="grid-advanced__controls"
        [actions]="configuration.actions"
        (action)="action.emit($event)"
        [item]="item"
        [alternativePillConfig]="configuration.rows"
      >
      </vdms-hq-ui-floating-controls-v2>
    </div>
  </div>
</ng-container>

<vdms-hq-ui-context-menu
  [actions]="configuration?.contextMenu?.actions ?? []"
  (action)="action.emit($event)"
></vdms-hq-ui-context-menu>

<ng-template #missingDataSource>
  <vdms-hq-ui-runtime-error message="Missing datasource or configuration"></vdms-hq-ui-runtime-error>
</ng-template>
