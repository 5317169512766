import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vdms-hq-ui-load-more',
  templateUrl: './load-more.component.html',
  styleUrls: ['./load-more.component.scss'],
})
export class LoadMoreComponent {
  @Input() isLoading = false;
  @Input() total = 0;
  @Input() batchSize = 24;
  @Input() currentLoaded = 0;
  @Output() loadMore = new EventEmitter();
}
