import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { SelectorSourceType } from '@vdms-hq/selectors';
import { AudioTrack } from '@vdms-hq/api-contract';
import { FieldType, SelectOption } from '@vdms-hq/shared';

@Component({
  selector: 'vdms-hq-audio-tracks-legacy',
  templateUrl: './audio-tracks-legacy.component.html',
  styleUrls: ['./audio-tracks-legacy.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AudioTracksLegacyComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioTracksLegacyComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() label = '';
  @Input() readonly = false;
  forceReadOnly = true;
  @Input() placeholder = '';
  @Input() type: FieldType.AUDIO_TRACK | FieldType.AUDIO_TRACK_TEDIAL = FieldType.AUDIO_TRACK;
  initialValue: AudioTrack[] = [];
  disabled = false;
  audioForm = new UntypedFormGroup({
    audioTracksCounter: new UntypedFormControl(0, [Validators.required]),
  });
  selectorType = SelectorSourceType;
  fieldType = FieldType;
  readonly maxAudioTracks = 24;
  private subscription?: Subscription;
  possibleAudioTracks: SelectOption[] = new Array(this.maxAudioTracks).fill(null).map((value, index) => ({
    key: index,
    label: `${index} tracks`,
  }));

  constructor(private changeDetectorRefs: ChangeDetectorRef) {}

  get selectedLength(): number {
    return parseInt(this.audioForm.get('audioTracksCounter')?.value, 10);
  }

  get audioControls(): AbstractControl[] {
    return (this.audioForm.get('audioTracks') as UntypedFormArray).controls;
  }

  get channelsControls(): AbstractControl[] {
    return (this.audioControls[0].get('channels') as UntypedFormArray).controls;
  }

  propagateChange: (_: any) => void = () => undefined;

  propagateTouch: () => void = () => undefined;

  registerOnChange(fn: any): void {
    // this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  filterMethod(items: SelectOption[]) {
    return items;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    isDisabled ? this.audioForm.disable() : this.audioForm.enable();
    this.changeDetectorRefs.markForCheck();
  }

  writeValue(value: AudioTrack[] | null | undefined | ''): void {
    this.initialValue = <AudioTrack[]>(Array.isArray(value) ? [...value] : []);
    this.audioForm.get('audioTracksCounter')?.patchValue(this.initialValue.length);
    this.updateAudioTracksForm();
  }

  updateAudioTracksForm(): void {
    const emptyArray = Array(...Array(this.selectedLength));

    const audioArray = new UntypedFormArray(
      emptyArray.map((value, index) => {
        let audioTrackGroup;

        if (this.type === FieldType.AUDIO_TRACK) {
          audioTrackGroup = new UntypedFormGroup({
            layout: new UntypedFormControl(),
            language: new UntypedFormControl(),
            audio_description: new UntypedFormControl(),
            loudness_standard: new UntypedFormControl(),
          });
        } else {
          audioTrackGroup = new UntypedFormGroup({
            channels: new UntypedFormArray([
              new UntypedFormGroup({
                label: new UntypedFormControl(),
                number: new UntypedFormControl(),
              }),
            ]),
            class: new UntypedFormControl(),
            language: new UntypedFormControl(),
            layout: new UntypedFormControl(),
            number: new UntypedFormControl(),
            tech: new UntypedFormControl(),
          });
        }

        if (this.initialValue[index]) {
          audioTrackGroup.patchValue(this.initialValue[index]);
          audioTrackGroup.disable();
        }
        return audioTrackGroup;
      }),
    );

    this.audioForm.setControl('audioTracks', audioArray);
    this.changeDetectorRefs.detectChanges();
  }

  isValueChanged() {
    return parseInt(this.audioForm.get('audioTracksCounter')?.value, 10) !== this.audioControls.length;
  }

  ngOnInit(): void {
    this.subscription = this.audioForm.valueChanges.subscribe(() => {
      const values = this.audioForm.getRawValue();
      this.propagateChange && this.propagateChange(values['audioTracks']);
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
