import { camelCase } from 'lodash';

export const camelToSnakeCase = (str: string) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export const snakeCaseToCamel = (str: string) => {
  return camelCase(str);
};

export const snakeToString = (str: string) => {
  return str.replace(/_/g, ' ');
};

export const snakeToCapitalizedString = (str: string) => {
  const snakedString = snakeToString(str);
  return snakedString.charAt(0).toUpperCase() + snakedString.slice(1);
};

export const dashedToString = (str: string) => {
  return str.replace(/-/g, ' ');
};

export const dashedToCapitalizedString = (str: string) => {
  const dashedString = dashedToString(str);
  return dashedString.charAt(0).toUpperCase() + dashedString.slice(1);
};
