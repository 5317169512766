import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { SelectOption } from '@vdms-hq/shared';
import { BehaviorSubject } from 'rxjs';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';

type OutsideValue = string[] | null | undefined;
type InsideValue = string[];

@Component({
  selector: 'vdms-hq-ui-form-input-chips',
  templateUrl: './form-input-chips.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputChipsComponent),
    },
  ],
  styles: ['mat-icon { min-width: 18px;}'],
})
export class FormInputChipsComponent
  extends FormControlValueAccessorComponent<OutsideValue, InsideValue>
  implements OnInit
{
  innerFormControl = new FormControl<string[]>([], { nonNullable: true });

  @Input() public distinct = true;
  @Input() public autocompleteOptions: SelectOption[] = [];
  @Input() public chipsLimit = -1;
  @Input() public chipsPosition: 'left' | 'right' = 'left';
  @Input() public spaceSeparatorKeyCodeEnabled = false;

  @Input() addOnBlur = true;

  @Output() inputChange = new EventEmitter<string>();

  @ViewChild('input') inputRef!: ElementRef;

  public filteredOptions$: BehaviorSubject<SelectOption[]> = new BehaviorSubject(<SelectOption[]>[]);
  public separatorKeysCodes: number[] = [ENTER, COMMA, TAB];

  override ngOnInit() {
    super.ngOnInit();
  }

  onInput(value: string) {
    this.inputChange.emit(value);
    const filtered = this.autocompleteOptions.filter((option: SelectOption) => {
      return option.key?.toString().includes(value) && !this.innerFormControl.value?.includes(<string>option.key);
    });
    this.filteredOptions$.next(filtered);
  }

  onOptionSelected(e: string) {
    const value = this.innerFormControl.value ?? [];

    value.push(e);
    this.innerFormControl.setValue(value);
  }

  addChip(event: MatChipInputEvent): void {
    if (this.chipsLimit !== -1 && this.innerFormControl.value.length >= this.chipsLimit) {
      return;
    }
    const value = (event.value || '').trim();
    const currentValue = [...this.innerFormControl.value];

    if (this.distinct && currentValue.includes(value)) return;

    if (value) {
      currentValue.push(value);
    }

    event.input.value = '';
    this.innerFormControl.setValue(currentValue);
  }

  removeChip(value: string): void {
    const currentValue = [...this.innerFormControl.value];

    const index = currentValue.indexOf(value);

    if (index >= 0) {
      currentValue.splice(index, 1);
    }
    this.innerFormControl.setValue(currentValue);
  }

  deselectAll(): void {
    this.innerFormControl.reset();
  }

  override transformOuterToInner(value: OutsideValue): InsideValue {
    if (!Array.isArray(value)) {
      value = [];
    }

    return value;
  }

  onPaste($event: ClipboardEvent) {
    $event.preventDefault();

    $event.clipboardData
      ?.getData('Text')
      .split(/[;,\n]/)
      .forEach((value) => {
        if (value.trim()) {
          this.addValue(value.trim());
        }
      });
    this.removeChip('');
  }

  addValue(value: string) {
    const currentValue = [...this.innerFormControl.value];

    if (this.distinct && currentValue.includes(value)) return;
    currentValue.push(value);

    this.innerFormControl.setValue(currentValue);
  }
}
