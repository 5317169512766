<ng-container *ngIf="config; else missingData">
  <div
    class="grid-container"
    [class]="{
      'columns-dynamic': !config.columns || config.columns === 'dynamic',
      'columns-2': config.columns === '2-columns',
      'columns-3': config.columns === '3-columns'
    }"
    [scrollTop]="loading$ | async"
  >
    <ng-container *ngFor="let item of items">
      <ng-container *ngIf="config | disableIfRow : item; let disabledRowState">
        <div class="outer">
          <div
            class="grid-container__item"
            *ngIf="item | objectValueByPath : config.key; let itemValue"
            (click)="!disabledRowState.disabled && selectedChange.emit(item)"
            [class.selected-highlight]="selected.includes(itemValue)"
            [class.disabled]="disabledRowState.disabled"
            [matTooltip]="disabledRowState.disabled ? (config.disabledIf?.label ?? '' | translate) : ''"
          >
            <span
              *ngIf="item | objectValueByPath : config.label; let title"
              class="grid-container__title"
              #titleContainer
              [matTooltip]="title"
              [matTooltipDisabled]="titleContainer.offsetWidth >= titleContainer.scrollWidth"
              [matTooltipClass]="'selectable-title-tooltip'"
            >
              {{ item | objectValueByPath : config.label }}</span
            >
            <div class="grid-container__metadata">
              <ng-container *ngFor="let metadata of config.metadata">
                <div *ngIf="!(metadata | metadataHidden : item)" [class.w-full]="metadata.singleLine">
                  <span class="grid-container__label" *ngIf="metadata.label">{{ metadata.label | translate }}:</span>
                  <vdms-hq-ui-value-renderer
                    [value]="item | objectValueByPath : metadata.valuePath"
                    [format]="metadata.viewFormat"
                    [fallback]="metadata.fallback ?? 'N/A'"
                  ></vdms-hq-ui-value-renderer>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
<ng-template #missingData>
  <vdms-hq-ui-runtime-error message="Missing configuration"></vdms-hq-ui-runtime-error>
</ng-template>
