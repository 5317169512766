import { AssetFlatView2 } from '@vdms-hq/asset-results';
import { AssetFlat, AssetFlatCartItem } from '@vdms-hq/api-contract';
import { DataProviderService } from '@vdms-hq/selectors';
import { StorageUrlService } from '@vdms-hq/storage';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { Framerate, Timecode } from '@vdms-hq/timecode';

type Context = {
  uuid: string;
  timecode_in?: string | null;
  timecode_out?: string | null;
  note?: string;
};

export class CartAssetViewModel extends AssetFlatView2 {
  constructor(
    props: AssetFlat,
    deps: {
      dataProvider: DataProviderService;
      storageUrlService: StorageUrlService;
      activatedClientService: ActivatedClientService;
    },
    public context: Context,
  ) {
    super(props, deps);
  }

  override get timecodeIn() {
    if (this.context.timecode_in) {
      const timecode = Timecode.fromTimecode(this.context.timecode_in, this.framerate, false);

      return this.offset && timecode ? timecode.add(this.offset) : timecode;
    }

    return super.timecodeIn;
  }

  override get timecodeOut() {
    if (this.context.timecode_out) {
      const timecode = Timecode.fromTimecode(this.context.timecode_out, this.framerate, false);

      return this.offset && timecode ? timecode.add(this.offset) : timecode;
    }

    return super.timecodeOut;
  }

  static fromCartItem(
    originalAsset: AssetFlatCartItem,
    deps: {
      dataProvider: DataProviderService;
      storageUrlService: StorageUrlService;
      activatedClientService: ActivatedClientService;
    },
  ) {
    return new CartAssetViewModel(originalAsset.asset, deps, {
      uuid: originalAsset.uuid,
      timecode_in: originalAsset.timecode_in,
      timecode_out: originalAsset.timecode_out,
      note: originalAsset.note,
    });
  }
}
