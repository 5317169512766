import { SelectOption } from '../models/selector.model';

export enum GroupCurrencyISO {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  PLN = 'PLN',
}

export enum GroupCurrencySymbol {
  USD = '$',
  EUR = '€',
  GBP = '£',
  PLN = 'zł',
}

export const timezones: SelectOption[] = [
  { key: 'client', label: 'Default' },
  { key: 'local', label: 'Local' },
];
export const clientTimezones: SelectOption[] = [
  { key: 'local', label: 'User Local' },
  { key: '+0', label: 'UTC+0 (London)' },
  { key: '+1', label: 'UTC+1 (Paris/Berlin/Warsaw)' },
  { key: '+2', label: 'UTC+2 (Cairo)' },
  { key: '+3', label: 'UTC+3 (Jeddah)' },
  { key: '+4', label: 'UTC+4 (Dubai)' },
  { key: '+5', label: 'UTC+5 (Karachi)' },
  { key: '+6', label: 'UTC+6 (Dhaka)' },
  { key: '+7', label: 'UTC+7 (Bangkok)' },
  { key: '+8', label: 'UTC+8 (Beijing)' },
  { key: '+9', label: 'UTC+9 (Tokyo)' },
  { key: '+10', label: 'UTC+10 (Sydney)' },
  { key: '+11', label: 'UTC+11 (Noumea)' },
  { key: '+12', label: 'UTC+12 (Wellington)' },
  { key: '-11', label: 'UTC-11 (Midway Island)' },
  { key: '-10', label: 'UTC-10 (Honolulu)' },
  { key: '-9', label: 'UTC-9 (Anchorage)' },
  { key: '-8', label: 'UTC-8 (Los Angeles)' },
  { key: '-7', label: 'UTC-7 (Denver)' },
  { key: '-6', label: 'UTC-6 (Chicago)' },
  { key: '-5', label: 'UTC-5 (New York)' },
  { key: '-4', label: 'UTC-4 (Santo Domingo)' },
  { key: '-3', label: 'UTC-3 (Rio de Janeiro)' },
  { key: '-2', label: 'UTC-2 (Fernando de Noronha)' },
  { key: '-1', label: 'UTC-1 (Azores Islands)' },
];
export const dateFormats: SelectOption[] = [{ key: 'ISO (2020-02-22)', label: 'ISO (2020-02-22)' }];
export const timeFormats: SelectOption[] = [{ key: 'ISO (24H)', label: 'ISO (24H)' }];
export const tableViewOptions: SelectOption[] = [
  { key: 'table', label: 'Table View' },
  { key: 'advanced', label: 'List View' },
  { key: 'grid', label: 'Grid View' },
];
export const pageSizeOptions: SelectOption[] = [
  { key: 12, label: '12' },
  { key: 24, label: '24' },
  { key: 48, label: '48' },
  { key: 96, label: '96' },
  { key: 192, label: '192' },
];

export const groupCurrencyOptions: SelectOption<GroupCurrencyISO>[] = [
  {
    key: GroupCurrencyISO.USD,
    label: `${GroupCurrencyISO.USD} (${GroupCurrencySymbol.USD})`,
  },
  {
    key: GroupCurrencyISO.EUR,
    label: `${GroupCurrencyISO.EUR} (${GroupCurrencySymbol.EUR})`,
  },
  {
    key: GroupCurrencyISO.GBP,
    label: `${GroupCurrencyISO.GBP} (${GroupCurrencySymbol.GBP})`,
  },
  {
    key: GroupCurrencyISO.PLN,
    label: `${GroupCurrencyISO.PLN} (${GroupCurrencySymbol.PLN})`,
  },
];
