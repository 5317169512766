<div
  class="breadcrumb"
  [class.small]="size === 'small'"
  [class.single]="breadcrumbs.length === 1"
  [class.truncate-long]="breadcrumbs.length > 3"
>
  <ng-container *ngFor="let crumb of breadcrumbs | slice : 0 : MAX_BREADCRUMBS_SIZE; last as isLast; let index = index">
    <ng-container
      *ngTemplateOutlet="
        breadcrumb;
        context: { crumb: crumb, hasNext: !isLast || breadcrumbs.length > 3, index: index }
      "
    ></ng-container>
  </ng-container>

  <ng-container *ngIf="breadcrumbs.length > MAX_BREADCRUMBS_SIZE + 1">
    <ng-container *ngTemplateOutlet="more"></ng-container>
  </ng-container>

  <ng-container *ngIf="breadcrumbs.length > MAX_BREADCRUMBS_SIZE">
    <ng-container
      *ngFor="let crumb of breadcrumbs | slice : breadcrumbs.length - 1 : breadcrumbs.length; last as isLast"
    >
      <ng-container
        *ngTemplateOutlet="breadcrumb; context: { crumb: crumb, hasNext: !isLast, index: breadcrumbs.length }"
      ></ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="additionalInfo !== ''">
    <ng-container *ngTemplateOutlet="additionalInfoTemplate"></ng-container>
  </ng-container>

  <ng-container *ngIf="loading">
    <div class="crumb">
      <mat-spinner diameter="24" class="spinner"></mat-spinner>
    </div>
  </ng-container>
</div>

<ng-template #more>
  <div class="crumb">
    <span class="crumb-content">
      <vdms-hq-ui-button
        [iconOnly]="true"
        color="transparent"
        icon="more_horiz"
        [matMenuTriggerFor]="menu"
        tooltip="Click to see more"
      ></vdms-hq-ui-button>
      <mat-icon class="crumb-icon">chevron_right</mat-icon>
    </span>
  </div>
</ng-template>

<ng-template #breadcrumb let-crumb="crumb" let-index="index" let-hasNext="hasNext">
  <div class="crumb" [class.crumb-last]="!hasNext">
    <span
      class="crumb-content"
      [class.crumb-link]="!!crumb.path || !!crumb.clickable"
      [routerLink]="crumb.path ?? []"
      (click)="crumb.clickable && selectedBreadCrumb.emit({crumb, index})"
      [matTooltip]="crumb.name | translate"
      >{{ crumb.name | translate }}</span
    >
    <mat-icon *ngIf="hasNext" class="crumb-icon">chevron_right</mat-icon>
  </div>
</ng-template>

<ng-template #additionalInfoTemplate>
  <div class="crumb">
    <span class="crumb-additional-info">
      {{ additionalInfo }}
    </span>
  </div>
</ng-template>

<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    class="menu-item"
    [routerLink]="crumb.path ?? []"
    (click)="crumb.clickable && selectedBreadCrumb.emit({crumb, index: index + MAX_BREADCRUMBS_SIZE})"
    *ngFor="let crumb of breadcrumbs | slice : MAX_BREADCRUMBS_SIZE : breadcrumbs.length - 1; let index = index"
  >
    {{ crumb.name }}
  </button>
</mat-menu>
