import { Routes } from '@angular/router';
import { RatesCardComponent } from './pages/rate-cards/rates-card.component';

export enum RATE_CARDS_ROUTES {
  ROOT = 'billing',
}

export const RATE_CARDS_ROUTING: Routes = [
  {
    path: '',
    component: RatesCardComponent,
  },
  {
    path: '**',
    redirectTo: `/${RATE_CARDS_ROUTES.ROOT}`,
    pathMatch: 'full',
  },
];
