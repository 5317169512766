import { Routes } from '@angular/router';
import { RightsContractsResultsComponent } from './pages/rights-contracts-results/rights-contracts-results.component';

export enum RIGHTS_CONTRACTS_ROUTER_BASE {
  ROOT = 'rights-contracts',
}

export const rightsContractsRouting: Routes = [
  {
    path: '',
    component: RightsContractsResultsComponent,
  },
  {
    path: '**',
    redirectTo: `/${RIGHTS_CONTRACTS_ROUTER_BASE.ROOT}`,
    pathMatch: 'full',
  },
];
