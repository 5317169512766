import { Observable } from 'rxjs';
import { GettyAuth } from './models/getty-auth';
import { Extra } from '../cart/models/extra';
import { Endpoints } from './models/endpoints';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { CcSalesforceApiService } from './cc-salesforce.api.service';
import { Service } from './models/service';
import { BridgeService } from '../tools/bridge/bridge.service';
import { BridgedRequest } from '../tools/bridge/bridge';

@Injectable({ providedIn: 'root' })
export class CcSalesforceConnectionApiService {
  constructor(
    private readonly apiService: ApiService,
    private readonly ccSalesforceApiService: CcSalesforceApiService,
    private readonly bridgeService: BridgeService,
  ) {}

  public getGettyAuthCredentials$ = (): Observable<GettyAuth> =>
    this.apiService
      .get<Extra>(`${Endpoints.EXTRA}`)
      .pipe(GetResponseData)
      .pipe(map((responseData: Extra['data']) => responseData.getty_auth));

  /**
   * @remarks - For now it gets last service's url
   * @todo - to be reorganized
   * @param salesforceInstanceUrl
   */
  public getServiceUrl$ = (salesforceInstanceUrl: string): Observable<Service['url']> => {
    const getServiceUrlRequest: BridgedRequest = {
      url: `${salesforceInstanceUrl}/services/data`,
      method: 'GET',
    };

    return this.bridgeService
      .passThroughBridge<Service[]>(getServiceUrlRequest)
      .pipe(GetResponseData)
      .pipe(map((services: Service[]) => services[services.length - 1].url));
  };
}
