import { Injectable } from '@angular/core';
import { LoadableDataSource, LocalDataSource } from '@vdms-hq/shared';
import { DashboardModel } from '@vdms-hq/api-contract';
import { DashboardsFetcher } from './dashboards.fetcher';
import { ViewSettingsService } from '@vdms-hq/view-settings';

@Injectable({ providedIn: 'root' })
export class DashboardsDs extends LocalDataSource<DashboardModel> implements LoadableDataSource {
  isLoading$ = this.dashboardsFetcher.loading$;

  constructor(private readonly dashboardsFetcher: DashboardsFetcher, private viewSettingsService: ViewSettingsService) {
    super(dashboardsFetcher.dashboards$);
    this.viewSettingsService.perPageUserSettings$.subscribe((perPage) => {
      this.pageSize$.next(perPage);
    });
  }

  optionsChanged(model: DashboardModel | null = null) {
    this.dashboardsFetcher.refresh$.next(model);
  }
}
