import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { ApiResponse } from '../api.model';
import { LanguageModel, APIEndpoints } from './languages.model';
import { Observable } from 'rxjs';
import { GetResponseData } from '../../operators/get-response-data.operator';

@Injectable({
  providedIn: 'root',
})
export class LanguagesApiService {
  constructor(private apiService: ApiService) {}

  getList = (): Observable<LanguageModel[]> =>
    this.apiService.get<ApiResponse<LanguageModel[]>>(`${APIEndpoints.ROOT}`).pipe(GetResponseData);

  getCustomList = (): Observable<LanguageModel[]> =>
    this.apiService.get<ApiResponse<LanguageModel[]>>(`${APIEndpoints.ROOT_PLURAL}`).pipe(GetResponseData);
}
