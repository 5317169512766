import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SelectOption } from '@vdms-hq/shared';
import { SelectorSourceType } from '../../selector-source.type';
import { SourceProvider } from '../../source-provider';
import {
  AssetMasterType,
  AssetStatus,
  BillingReportTypes,
  EmbargoTypes,
  HotCold,
  HybrikContentClass,
  isDeleted,
  IsDirty,
  isPurged,
  MediaInsightsSourceLanguages,
  MediaInsightsTargetLanguages,
  Orphan,
  ProcessingStatus,
  Stage,
  YesNo,
} from './local.selector';

@Injectable({
  providedIn: 'root',
})
export class LocalSourceService implements SourceProvider {
  isLoading$ = of(false);

  supports(type: SelectorSourceType): boolean {
    return !![
      SelectorSourceType.IS_DIRTY,
      SelectorSourceType.YES_NO,
      SelectorSourceType.HOT_COLD,
      SelectorSourceType.ORPHAN,
      SelectorSourceType.IS_PURGED,
      SelectorSourceType.IS_DELETED,
      SelectorSourceType.STAGE,
      SelectorSourceType.MEDIA_INSIGHTS_SOURCE_LANGUAGE,
      SelectorSourceType.MEDIA_INSIGHTS_TARGET_LANGUAGE,
      SelectorSourceType.ASSET_MASTER_TYPE,
      SelectorSourceType.EMBARGO,
      SelectorSourceType.BILLING_REPORT,
      SelectorSourceType.HYBRIK_TRACK_CLASS,
      SelectorSourceType.ASSET_STATUS,
      SelectorSourceType.PROCESSING_STATUS,
    ].find((arrayType) => arrayType === type);
  }

  listByType(type: SelectorSourceType): Observable<SelectOption[]> {
    switch (type) {
      case SelectorSourceType.IS_DIRTY:
        return of(IsDirty);
      case SelectorSourceType.YES_NO:
        return of(YesNo);
      case SelectorSourceType.HOT_COLD:
        return of(HotCold);
      case SelectorSourceType.ORPHAN:
        return of(Orphan);
      case SelectorSourceType.IS_PURGED:
        return of(isPurged);
      case SelectorSourceType.IS_DELETED:
        return of(isDeleted);
      case SelectorSourceType.STAGE:
        return of(Stage);
      case SelectorSourceType.MEDIA_INSIGHTS_SOURCE_LANGUAGE:
        return of(MediaInsightsSourceLanguages);
      case SelectorSourceType.MEDIA_INSIGHTS_TARGET_LANGUAGE:
        return of(MediaInsightsTargetLanguages);
      case SelectorSourceType.ASSET_MASTER_TYPE:
        return of(AssetMasterType);
      case SelectorSourceType.EMBARGO:
        return of(EmbargoTypes);
      case SelectorSourceType.BILLING_REPORT:
        return of(BillingReportTypes);
      case SelectorSourceType.HYBRIK_TRACK_CLASS:
        return of(HybrikContentClass);
      case SelectorSourceType.ASSET_STATUS:
        return of(AssetStatus);
      case SelectorSourceType.PROCESSING_STATUS:
        return of(ProcessingStatus);
    }

    return of([]);
  }
}
