import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption, SelectOptionGroup } from '@vdms-hq/shared';

@Pipe({
  name: 'onePerGroup',
})
export class OnePerGroupPipe implements PipeTransform {
  transform(target: SelectOption, config: { group: SelectOptionGroup, chosenOptions: string[] }): boolean {
    const { group, chosenOptions } = config;
    return group.options.some((so: SelectOption) => chosenOptions?.includes(so.key as string)) && !chosenOptions?.includes(target.key as string);
  }
}
