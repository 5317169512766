import { Injectable } from '@angular/core';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { SettingsType } from './global-settings.model';

enum GLOBAL_SETTINGS_ENDPOINTS {
  ROOT = 'settings',
}

@Injectable({ providedIn: 'root' })
export class GlobalSettingsService {
  constructor(private readonly apiService: ApiService) {}

  get = (): Observable<SettingsType> => {
    return this.apiService.get<ApiResponse<SettingsType>>(`${GLOBAL_SETTINGS_ENDPOINTS.ROOT}`).pipe(GetResponseData);
  };

  patch = (settings: SettingsType) => {
    return this.apiService
      .patch<SettingsType, ApiResponse<SettingsType>>(`${GLOBAL_SETTINGS_ENDPOINTS.ROOT}`, settings)
      .pipe(GetResponseData);
  };
}
