<mat-divider *ngIf="isSeparator(item)" class="divider"></mat-divider>

<div
  [routerLink]="$sidebarItem(item).routerLink ?? []"
  class="link"
  routerLinkActive="link--active"
  *ngIf="item && isItem(item)"
  [class.link--has-children]="hasChildren"
  [class.link--smaller]="smaller"
  [routerLinkActiveOptions]="{ exact: false }"
>
  {{ $sidebarItem(item).name | translate
  }}<span *ngIf="$sidebarItem(item).additionalInfo">{{ ' | ' + $sidebarItem(item).additionalInfo }}</span>
</div>
<sup *ngIf="$sidebarItem(item)?.superscript as script" class="ml-3">{{ script }}</sup>
