import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientModel, ClientContractService } from '@vdms-hq/firebase-contract';
import { map, shareReplay, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ActivatedClientForHostnameService {
  client$: Observable<ClientModel | undefined> = this.clientService
    .findClients$((client) => client.hostnames !== undefined && client.hostnames.includes(location.hostname))
    .pipe(
      take(1),
      map((clients) => clients[0]),
      shareReplay(1),
    );

  constructor(private clientService: ClientContractService) {}
}
