<div class="content" *ngFor="let container of containers; let isLast = last">
  <div class="container__content-title">
    <h2>{{ container.label }}</h2>
  </div>
  <div class="container__content-fields">
    <vdms-hq-elements-renderer
      *ngIf="getFieldRefsFor(container.id); let refs"
      [elements]="refs"
      [withStateToggle]="withStateToggle"
      [columns]="columns"
      [content]="{ enabled: 'common.global.overwrite' }"
    ></vdms-hq-elements-renderer>
  </div>
  <mat-divider *ngIf="!isLast"></mat-divider>
</div>
