import { Routes } from '@angular/router';
import { GlobalConfigurationComponent } from './pages/global-configuration/global-configuration.component';

export enum GLOBAL_CONFIGURATION_ROUTES {
  ROOT = 'global',
}

export const GLOBAL_CONFIGURATION_ROUTING: Routes = [
  {
    path: '',
    component: GlobalConfigurationComponent,
  },
  {
    path: '**',
    redirectTo: `/${GLOBAL_CONFIGURATION_ROUTES.ROOT}`,
    pathMatch: 'full',
  },
];
