import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadMoreComponent } from './loader/load-more.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { UIButtonModule } from '../ui-button/ui-button.module';
import { UILoaderModule } from '../ui-loader';

@NgModule({
  declarations: [LoadMoreComponent],
  exports: [LoadMoreComponent],
  imports: [CommonModule, TranslateModule, MatButtonModule, UIButtonModule, UILoaderModule],
})
export class UILoadMoreModule {}
