import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule, UILayoutModule, UINativeUploadModule } from '@vdms-hq/ui';
import { ImportExportButtonComponent } from './smart/import-export-button/import-export-button.component';
import { ImportExportDialogComponent } from './smart/import-export-dialog/import-export-dialog.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ImportExportButtonComponent, ImportExportDialogComponent],
  imports: [
    CommonModule,
    UILayoutModule,
    UIFormModule,
    UINativeUploadModule,
    UIButtonModule,
    MatTabsModule,
    MatDialogModule,
    UIDialogWrapperModule,
    TranslateModule,
  ],
  exports: [ImportExportButtonComponent],
})
export class ImportExportModule {}
