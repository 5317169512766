import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PreviewUISettings } from '../../supports/any-file.component';
import { isCode, isDoc, isImage, isPdf, isSheet } from '../../logic/file.model';

@Component({
  selector: 'vdms-hq-ui-preview',
  templateUrl: './preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewComponent {
  @Input() settings!: PreviewUISettings;

  isPdf = isPdf;
  isSheet = isSheet;
  isDoc = isDoc;
  isImage = isImage;
  isCode = isCode;
}
