import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import {
  ReindexAutomatizationEndpoints,
  ReindexAutomatizationModel,
  ReindexAutomatizationRequestModel,
} from './reindex-automatization.model';
import { Observable } from 'rxjs';
import { ApiPaginatedResponse } from '../api.model';
import { GetResponseData } from '../../operators/get-response-data.operator';

@Injectable({ providedIn: 'root' })
export class ReindexAutomatizationService {
  constructor(private readonly api: ApiService) {}

  get(): Observable<ReindexAutomatizationModel[]> {
    return this.api
      .get<ApiPaginatedResponse<ReindexAutomatizationModel>>(`${ReindexAutomatizationEndpoints.ROOT}`)
      .pipe(GetResponseData);
  }

  post(payload: ReindexAutomatizationRequestModel) {
    return this.api.post(`${ReindexAutomatizationEndpoints.ROOT}`, payload);
  }
}
