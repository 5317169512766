<vdms-hq-ui-dialog-wrapper [size]="'default'">
  <div title>
    <span class="title">{{ 'pages.lists.select_list' | translate }}</span>
  </div>
  <div content class="relative">
    <vdms-hq-ui-loader *ngIf="dataSource.isLoading$ | async" [backdrop]="true" mode="over-parent"></vdms-hq-ui-loader>

    <form [formGroup]="form" class="form-content">
      <vdms-hq-dynamic-form-group-renderer
        [form]="dataSource.filters"
        [fieldsConfig]="filtersConfig"
        [hasLoader]="true"
        [fullWidth]="true"
      ></vdms-hq-dynamic-form-group-renderer>
      <mat-divider></mat-divider>
      <vdms-hq-ui-add-dialog-selectable-tiles
        [config]="collectionsTilesConfig"
        [items]="dataSource.connection$ | async"
        [selected]="selectedControl.value"
        (selectedChange)="selectCollection($event)"
      ></vdms-hq-ui-add-dialog-selectable-tiles>

      <ng-container *ngIf="dataSource.emptyResults$ | async">
        <vdms-hq-ui-empty-results
          class="data__results-empty"
          [message]="'Could not find any collections'"
          [type]="'compact'"
          [icon]="'insights'"
        ></vdms-hq-ui-empty-results>
        <vdms-hq-ui-button class="margin-inline-auto" (click)="createNewCollection()" color="primary"
          >{{ 'Create new collection' | translate }}
        </vdms-hq-ui-button>
      </ng-container>
      <mat-paginator
        [pageSizeOptions]="dataSource.pageSizeOptions"
        [length]="dataSource.total$ | async"
        [pageIndex]="dataSource.pageIndex$ | async"
        [pageSize]="dataSource.pageSize$ | async"
        (page)="dataSource.pageChange($event)"
        [showFirstLastButtons]="true"
      >
      </mat-paginator>
    </form>
  </div>

  <ng-container footer>
    <vdms-hq-ui-button (click)="close()" color="secondary">{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button>
    <vdms-hq-ui-button
      (click)="onSubmit()"
      [disabled]="selectedControl.invalid || selectedControl.value.length === 0"
      color="primary"
      >{{ 'common.global.save' | translate }}</vdms-hq-ui-button
    >
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
