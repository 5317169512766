import { NotificationSubscription } from '@vdms-hq/api-contract';
import { camelToSnakeCase } from '@vdms-hq/shared';

export class AdminNotificationSubscriptionViewModel {
  constructor(public props: NotificationSubscription) {}

  get notificationType() {
    if (!this.props.notification_event_type) {
      return 'N/A';
    }

    return `common.notification_subscriptions.types.type${camelToSnakeCase(
      this.props.notification_event_type,
    ).toLowerCase()}`;
  }
}
