<div class="layout">
  <div class="title">
    <h2>{{ 'common.notification_subscriptions.heading' | translate }}</h2>
  </div>
  <div class="notifications" *ngIf="datasource.unreadNotifications$ | async; let notifications">
    <ng-container *ngIf="notifications.total; else noUnreadNotifications">
      <vdms-hq-notification-message
        *ngFor="let notifications of notifications.data"
        [notification]="notifications"
        [detailedView]="false"
      ></vdms-hq-notification-message>
    </ng-container>
  </div>
  <div class="action">
    <vdms-hq-ui-button color="primary" (click)="goToNotificationsCenter()">{{
      'go to notification center'
    }}</vdms-hq-ui-button>
  </div>
</div>

<ng-template #noUnreadNotifications>
  <p>{{ 'common.notification_subscriptions.empty_notifications' | translate }}</p>
</ng-template>
