<mat-form-field
  appearance="outline"
  class="material-custom-field"
  [class.hide-footer]="!withFooter"
  [class.with-touched-indicator]="withTouchedIndicator"
>
  <mat-label>{{ label }}</mat-label>

  <mat-chip-list
    [required]="isRequired"
    class="material-custom-chip-input"
    #chipList
    [formControl]="innerFormControl"
    (ngModelChange)="propagateChange($event)"
  >
    <ng-template #ChipInput>
      <input
        type="search"
        autocomplete="off"
        class="mat-form-field"
        #input
        [matChipInputAddOnBlur]="addOnBlur"
        [matAutocomplete]="auto"
        [placeholder]="placeholder"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="addChip($event)"
        [disabled]="chipsLimit !== -1 && innerFormControl.value.length >= chipsLimit"
        (focus)="onInput(input.value)"
        (input)="onInput(input.value)"
        (paste)="onPaste($event)"
        [required]="isRequired"
      />
    </ng-template>
    <ng-template #Chips>
      <mat-chip
        *ngFor="let val of innerFormControl.value ?? []"
        [selectable]="false"
        [removable]="true"
        (removed)="removeChip(val)"
      >
        {{ val }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </ng-template>

    <ng-container *ngTemplateOutlet="chipsPosition === 'left' ? Chips : ChipInput"> </ng-container>
    <ng-container *ngTemplateOutlet="chipsPosition === 'left' ? ChipInput : Chips"> </ng-container>
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event.option.value)">
    <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option.key">
      {{ option.label }}
    </mat-option>
  </mat-autocomplete>

  <mat-hint *ngIf="withFooter && hint">{{ hint }}</mat-hint>
  <mat-error *ngIf="withFooter">
    <span *ngFor="let error of errors">{{ error.transLang | translate : error.contextParams }}</span>
  </mat-error>
  <vdms-hq-ui-loader matSuffix [size]="20" *ngIf="innerFormControl.pending"></vdms-hq-ui-loader>
</mat-form-field>
