import { Component, inject } from '@angular/core';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { UIDialogWrapperModule } from '../../../ui-dialog-wrapper';
import { UIButtonModule } from '../../../ui-button';
import { MatMenuModule } from '@angular/material/menu';
import { UIFormModule } from '../../../ui-form';
import { DrawerDialogService } from '../../logic/drawer-dialog.service';

@Component({
  selector: 'vdms-hq-ui-dialog-drawer-header',
  templateUrl: './dialog-drawer-header.component.html',
  styleUrls: ['./dialog-drawer-header.component.scss'],
  standalone: true,
  imports: [CommonModule, UIDialogWrapperModule, UIButtonModule, PortalModule, MatMenuModule, UIFormModule],
})
export class DialogDrawerHeaderComponent {
  drawerDialogService = inject(DrawerDialogService);
}
