import { SelectOption } from '@vdms-hq/shared';

export const QCFileExtensions: SelectOption[] = [
  { label: 'mov', key: 'mov' },
  { label: 'mxf', key: 'mxf' },
  { label: 'mp4', key: 'mp4' },
  { label: 'hevc', key: 'hevc' },
  { label: 'mpg', key: 'mpg' },
  { label: 'mpeg', key: 'mpeg' },
  { label: 'ts', key: 'ts' },
  { label: 'mkv', key: 'mkv' },
  { label: 'avi', key: 'avi' },
];

export const QCVideoBitDepths: SelectOption[] = [
  { label: '8', key: 8 },
  { label: '10', key: 10 },
  { label: '12', key: 12 },
  { label: '14', key: 14 },
  { label: '16', key: 16 },
];

export const QCChromaSampling: SelectOption[] = [
  { label: '4:1:1', key: '23.9' },
  { label: '4:2:0', key: '4:2:0' },
  { label: '4:2:2', key: '4:2:2' },
  { label: '4:4:4', key: '4:4:4' },
];

export const QCFramerates: SelectOption[] = [
  { label: '23.98', key: '23.98' },
  { label: '24', key: '24' },
  { label: '25', key: '25' },
  { label: '29.97', key: '29.97' },
  { label: '30', key: '30' },
  { label: '48', key: '48' },
  { label: '50', key: '50' },
  { label: '59.94', key: '59.94' },
  { label: '60', key: '60' },
];

export const QCVideoCodecs: SelectOption[] = [
  { label: 'AOMedia Video 1', key: 'AOMedia Video 1' },
  { label: 'Apple ProRes', key: 'Apple ProRes' },
  { label: 'Apple ProRes 422', key: 'Apple ProRes 422' },
  { label: 'Apple ProRes 422 HQ', key: 'Apple ProRes 422 HQ' },
  { label: 'Apple ProRes 422 LT', key: 'Apple ProRes 422 LT' },
  { label: 'Apple ProRes 422 Proxy', key: 'Apple ProRes 422 Proxy' },
  { label: 'Apple ProRes 4444', key: 'Apple ProRes 4444' },
  { label: 'Apple ProRes 4444 XQ', key: 'Apple ProRes 4444 XQ' },
  { label: 'Apple RLE (Animation)', key: 'Apple RLE (Animation)' },
  { label: 'AVCi', key: 'AVCi' },
  { label: 'Avid DNxHD', key: 'Avid DNxHD' },
  { label: 'Avid DNxHD 220x', key: 'Avid DNxHD 220x' },
  { label: 'Avid DNxHD 36', key: 'Avid DNxHD 36' },
  { label: 'Avid DNxHD 444', key: 'Avid DNxHD 444' },
  { label: 'DV', key: 'DV' },
  { label: 'DV 25', key: 'DV 25' },
  { label: 'DV 25 NTSC', key: 'DV 25 NTSC' },
  { label: 'DV 25 PAL', key: 'DV 25 PAL' },
  { label: 'DVCPRO', key: 'DVCPRO' },
  { label: 'DVCPRO 25 PAL', key: 'DVCPRO 25 PAL' },
  { label: 'DVCPRO 50 NTSC', key: 'DVCPRO 50 NTSC' },
  { label: 'DVCPRO HD, 1080i50', key: 'DVCPRO HD, 1080i50' },
  { label: 'DVCPRO HD, 1080i60', key: 'DVCPRO HD, 1080i60' },
  { label: 'DVCPRO HD, 1080p25', key: 'DVCPRO HD, 1080p25' },
  { label: 'DVCPRO HD, 1080p30', key: 'DVCPRO HD, 1080p30' },
  { label: 'DVCPRO HD, 720p24/30/60', key: 'DVCPRO HD, 720p24/30/60' },
  { label: 'Flash Video', key: 'Flash Video' },
  { label: 'GoPro CineForm HD', key: 'GoPro CineForm HD' },
  { label: 'h.261', key: 'h.261' },
  { label: 'h.263', key: 'h.263' },
  { label: 'H.264 (AVC) High Intra', key: 'H.264 (AVC) High Intra' },
  { label: 'h.264 (AVC)', key: 'h.264 (AVC)' },
  { label: 'h.264 (AVC) Baseline', key: 'h.264 (AVC) Baseline' },
  { label: 'h.264 (AVC) High', key: 'h.264 (AVC) High' },
  { label: 'h.264 (AVC) High 10 Bit', key: 'h.264 (AVC) High 10 Bit' },
  { label: 'h.264 (AVC) High 4:2:2', key: 'h.264 (AVC) High 4:2:2' },
  { label: 'h.264 (AVC) Main Profile', key: 'h.264 (AVC) Main Profile' },
  { label: 'h.264 (XAVC)', key: 'h.264 (XAVC)' },
  { label: 'h.265 (HEVC)', key: 'h.265 (HEVC)' },
  { label: 'h.265 (HEVC) 10 Bit', key: 'h.265 (HEVC) 10 Bit' },
  { label: 'h.265 (HEVC) Main Profile', key: 'h.265 (HEVC) Main Profile' },
  { label: 'h.265/HEVC', key: 'h.265/HEVC' },
  { label: 'Huff YUV', key: 'Huff YUV' },
  { label: 'JPEG 2000', key: 'JPEG 2000' },
  { label: 'Microsoft MPEG-4 version 3', key: 'Microsoft MPEG-4 version 3' },
  { label: 'Motion JPEG', key: 'Motion JPEG' },
  { label: 'Motion JPEG 2000', key: 'Motion JPEG 2000' },
  { label: 'Motion JPEG A', key: 'Motion JPEG A' },
  { label: 'Motion JPEG B', key: 'Motion JPEG B' },
  { label: 'MPEG 1', key: 'MPEG 1' },
  { label: 'MPEG 2', key: 'MPEG 2' },
  { label: 'MPEG 2 4:2:2 Profile', key: 'MPEG 2 4:2:2 Profile' },
  { label: 'MPEG 2 High Profile', key: 'MPEG 2 High Profile' },
  { label: 'MPEG 2 Main Profile', key: 'MPEG 2 Main Profile' },
  { label: 'MPEG 4', key: 'MPEG 4' },
  { label: 'MPEG IMX 30 NTSC', key: 'MPEG IMX 30 NTSC' },
  { label: 'MPEG IMX 30 PAL', key: 'MPEG IMX 30 PAL' },
  { label: 'MPEG IMX 40 NTSC', key: 'MPEG IMX 40 NTSC' },
  { label: 'MPEG IMX 40 PAL', key: 'MPEG IMX 40 PAL' },
  { label: 'MPEG IMX 50 NTSC', key: 'MPEG IMX 50 NTSC' },
  { label: 'MPEG IMX 50 PAL', key: 'MPEG IMX 50 PAL' },
  { label: 'MPEG-4 Part 2', key: 'MPEG-4 Part 2' },
  { label: 'r210', key: 'r210' },
  { label: 'RAW', key: 'RAW' },
  { label: 'TBC', key: 'TBC' },
  { label: 'v210', key: 'v210' },
  { label: 'VC-1', key: 'VC-1' },
  { label: 'Windows Media Video', key: 'Windows Media Video' },
  { label: 'XDCAM EX, 1080i60', key: 'XDCAM EX, 1080i60' },
  { label: 'XDCAM HD, 1080i50', key: 'XDCAM HD, 1080i50' },
  { label: 'XDCAM HD, 720p60', key: 'XDCAM HD, 720p60' },
  { label: 'XDCAM HD422, 1080i50', key: 'XDCAM HD422, 1080i50' },
  { label: 'XDCAM HD422, 1080i60', key: 'XDCAM HD422, 1080i60' },
  { label: 'XDCAM HD422, 1080p24', key: 'XDCAM HD422, 1080p24' },
  { label: 'XDCAM HD422, 1080p25', key: 'XDCAM HD422, 1080p25' },
];

export const QCInterlaceMode: SelectOption[] = [
  { key: 'TFF', label: 'TFF' },
  { key: 'BFF', label: 'BFF' },
  { key: 'Progressive', label: 'Progressive' },
];

export const QCAudioCodec: SelectOption[] = [{ key: 'PCM', label: 'PCM' }];

export const QCAudioSampleRate: SelectOption[] = [
  {
    key: 44100,
    label: '44100',
  },
  {
    key: 48000,
    label: '48000',
  },
  {
    key: 96000,
    label: '96000',
  },
];

export const QCAudioBitDepth: SelectOption[] = [
  {
    key: 16,
    label: '16',
  },
  {
    key: 20,
    label: '20',
  },
  {
    key: 24,
    label: '24',
  },
  {
    key: 32,
    label: '32',
  },
];
