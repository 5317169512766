import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, map, switchMap, take, tap } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { ClientsService } from '@vdms-hq/api-contract';
import { AsperaKeysDs } from '../../logic/aspera-keys.ds';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'vdms-hq-delete-dialog',
  standalone: true,
  imports: [CommonModule, UIDialogWrapperModule, TranslateModule, UILayoutModule, UIFormModule, UIButtonModule],
  providers: [AsperaKeysDs],
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent {
  suppliers$ = this.asperaKeysDs.suppliers$.pipe(
    map((resp) => resp?.data.map((supplier) => ({ key: supplier.uuid, label: supplier.name }))),
  );
  loading$ = new BehaviorSubject(false);

  form = new FormGroup({
    supplierUuid: new FormControl<string>('', [Validators.required]),
  });

  constructor(
    private ref: MatDialogRef<DeleteDialogComponent>,
    private asperaKeysDs: AsperaKeysDs,
    private clientsService: ClientsService,
    private activatedClientService: ActivatedClientService,
  ) {}

  get supplierUuid() {
    return this.form.controls.supplierUuid;
  }

  save() {
    if (this.form.invalid) {
      return;
    }

    this.loading$.next(true);

    this.activatedClientService.clientId$
      .pipe(
        switchMap((clientId) =>
          this.clientsService.deleteSupplierAsperaKey(clientId as string, this.supplierUuid.value ?? '').pipe(
            take(1),
            tap(() => this.loading$.next(false)),
          ),
        ),
      )
      .subscribe({
        next: () => {
          this.close(true);
        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          this.loading$.next(false);
        },
      });
  }

  close(success?: boolean) {
    this.ref.close(success);
  }
}
