import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AsyncPipe, DatePipe } from '@angular/common';
import { AuthService } from '../services/auth.service';
import moment from 'moment';

@Pipe({ name: 'tzDate', standalone: true, pure: true })
export class TzDatePipe implements PipeTransform {
  timezoneSource$: Observable<string> = this.authService.userAttributesDefinite$.pipe(
    map((attributes) => attributes.preferredTimezone),
  );

  public constructor(
    private readonly datePipe: DatePipe,
    private readonly asyncPipe: AsyncPipe,
    private readonly authService: AuthService,
  ) {}

  transform(value: Date | string | number, format: string): string | null {
    const dateWithoutTimezone = moment(value).utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
    return this.asyncPipe.transform(
      this.timezoneSource$.pipe(
        map((timezone: string) => this.datePipe.transform(dateWithoutTimezone, format, timezone)),
      ),
    );
  }
}
