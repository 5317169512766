import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PeakMeterService } from '../../logic/peak-meter.service';
import { InfoBarType, UIButtonModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { PlayerService } from '../../logic/player.service';

@Component({
  selector: 'vdms-hq-peak-meter',
  templateUrl: './peak-meter.component.html',
  styleUrls: ['peak-meter.component.scss'],
  standalone: true,
  imports: [NgIf, CommonModule, ReactiveFormsModule, UIFormModule, UIButtonModule, UILayoutModule],
  encapsulation: ViewEncapsulation.None,
})
export class PeakMeterComponent implements OnInit, OnDestroy {
  protected readonly InfoBarType = InfoBarType;

  @Input() withHeader = true;

  constructor(public peakMeterService: PeakMeterService, public playerService: PlayerService) {}

  ngOnInit(): void {
    this.peakMeterService.load();
  }

  ngOnDestroy() {
    this.peakMeterService.unload();
  }
}
