<ng-container *ngIf="state$ | async; let state">
  <vdms-hq-ui-preview-outer [loading]="state === 'loading'" [overflowHidden]="true">
    <audio
      id="audio-player"
      oncontextmenu="return false;"
      data-setup="{}"
      class="fit-to-parent video-js vjs-default-skin vjs-big-play-centered"
      controls
    ></audio>
  </vdms-hq-ui-preview-outer>
</ng-container>
