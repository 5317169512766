import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfinityLoaderComponent } from './components/infinity-loader/infinity-loader.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { UILoaderModule } from '../ui-loader';
import { UILoadMoreModule } from '../ui-load-more';

const components = [InfinityLoaderComponent];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, InfiniteScrollModule, UILoaderModule, UILoadMoreModule],
  exports: [...components],
})
export class UIInfinityLoaderModule {}
