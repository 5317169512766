import { Routes } from '@angular/router';
import { ReindexAutomatizationComponent } from './pages/reindex-automatization/reindex-automatization.component';

export enum REINDEX_ROUTES {
  ROOT = 'reindex-automatization',
}

export const REINDEX_ROUTING: Routes = [
  {
    path: '',
    component: ReindexAutomatizationComponent,
  },
];
