<vdms-hq-ui-actions-footer class="action-bar">
  <ng-container left>
    <ng-container *ngIf="selectionManager?.total$; let total">
      <vdms-hq-ui-button
        color="secondary"
        *ngIf="(total | async) > 0"
        (click)="actions.emit(ResultsActions.DESELECT_ALL)"
      >
        {{ 'common.global.deselect_all' | translate }}
      </vdms-hq-ui-button>
      <vdms-hq-ui-button
        color="warn"
        *ngIf="(total | async) > 0"
        (click)="actions.emit(ResultsActions.DELETE)"
        [disabled]="cartState.isUpdating$ | async"
      >
        {{ 'pages.cart.remove_selected' | translate }}
      </vdms-hq-ui-button>
    </ng-container>
  </ng-container>

  <ng-container center *ngIf="selectionManager?.total$; let total">
    <vdms-hq-ui-button [disabled]="true">
      {{ 'common.global.selected' | translate : { selected: total | async } }}
    </vdms-hq-ui-button>
  </ng-container>

  <!--  <ng-container right>-->
  <!--    <vdms-hq-ui-button-->
  <!--      color="primary"-->
  <!--      [disabled]="cartDataSource.total$.value === 0 || (cartState.isUpdating$ | async) || cartButtonDisabled"-->
  <!--      [loading]="cartState.isSubmitting$ | async"-->
  <!--      (click)="actions.emit(ResultsActions.SUBMIT_CART)"-->
  <!--    >-->
  <!--      {{ 'pages.cart.order_all' | translate }} ({{ cartDataSource.total$ | async }})-->
  <!--    </vdms-hq-ui-button>-->
  <!--  </ng-container>-->
</vdms-hq-ui-actions-footer>
