import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PdfSupportComponent } from './supports/pdf-support/pdf-support.component';
import { DocSupportComponent } from './supports/doc-support/doc-support.component';
import { UILoaderModule } from '../ui-loader/index';
import { ImgSupportComponent } from './supports/img-support/img-support.component';
import { SheetSupportComponent } from './supports/sheet-support/sheet-support.component';
import { PreviewOuterComponent } from './view/preview-outer/preview-outer.component';
import { UIFormModule } from '../ui-form';
import { FormInputTimecodeOverlayComponent } from './view/form-input-timecode-overlay/form-input-timecode-overlay.component';
import { UIButtonModule } from '../ui-button';
import { MathPipesModule } from 'ng-pipes';
import { CodeSupportComponent } from './supports/code-support/code-support.component';
import { PreviewComponent } from './view/preview/preview.component';
import { PreviewErrorComponent } from './view/preview-error/preview-error.component';
import { TranslateModule } from '@ngx-translate/core';

const exportedComponents = [
  PdfSupportComponent,
  DocSupportComponent,
  ImgSupportComponent,
  SheetSupportComponent,
  PreviewErrorComponent,
  FormInputTimecodeOverlayComponent,
  CodeSupportComponent,
  PreviewComponent,
  PreviewOuterComponent,
];

@NgModule({
  declarations: [exportedComponents, PreviewOuterComponent],
  imports: [
    CommonModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    UILoaderModule,
    UIFormModule,
    UIButtonModule,
    MathPipesModule,
    TranslateModule,
  ],
  exports: exportedComponents,
})
export class UIPreviewModule {}
