import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectOption } from '@vdms-hq/shared';
import { PhoneType, PhoneTypeSelectOptions, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { ExternalApiService } from '@vdms-hq/api-contract';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'vdms-hq-user-profile-details',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, UIFormModule, UILayoutModule],
  templateUrl: './user-profile-details.component.html',
  styleUrls: ['./user-profile-details.component.scss'],
})
export class UserProfileDetailsComponent implements OnInit, OnDestroy {
  public externalApiService = inject(ExternalApiService);

  @Input() personalControl!: FormGroup;
  @Input() companyControl!: FormGroup;

  readonly phoneTypes: SelectOption[] = PhoneTypeSelectOptions;
  countries: SelectOption[] = [];

  destroyed$ = new Subject<void>();

  get phoneTypeValue(): PhoneType | null {
    return this.personalControl?.get('phone.type')?.value || null;
  }
  get numberLabel(): string {
    return this.phoneTypeValue === PhoneType.MOBILE
      ? 'pages.settings.mobile_number'
      : 'pages.settings.office_phone_number';
  }
  get numberPlaceholder(): string {
    return this.phoneTypeValue === PhoneType.MOBILE
      ? 'pages.settings.mobile_number_placeholder'
      : 'pages.settings.office_phone_number_placeholder';
  }

  ngOnInit(): void {
    this.externalApiService
      .getCountries()
      .pipe(
        takeUntil(this.destroyed$),
        map((countries) => countries.map((c) => <SelectOption>{ label: c, key: c })),
      )
      .subscribe((c) => (this.countries = c));
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
