import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { GitRelease, GitReleasePayload } from './model';
import { Observable } from 'rxjs';
import { GithubEndpoints } from './endpoints';
import { ApiResponse } from '../api.model';
import { GetResponseData } from '../../operators/get-response-data.operator';

@Injectable({ providedIn: 'root' })
export class GithubService {
  constructor(private readonly apiService: ApiService) {}

  getReleases(payload: Partial<GitReleasePayload>): Observable<GitRelease[]> {
    return this.apiService
      .post<Partial<GitReleasePayload>, ApiResponse<ApiResponse<GitRelease[]>>>(`${GithubEndpoints.PROXY}`, payload)
      .pipe(GetResponseData, GetResponseData);
  }

  markdownToHTML(payload: Partial<GitReleasePayload>): Observable<string> {
    return this.apiService
      .post<Partial<GitReleasePayload>, ApiResponse<ApiResponse<string>>>(`${GithubEndpoints.PROXY}`, payload)
      .pipe(GetResponseData, GetResponseData);
  }
}
