import {
  Directive,
  Input,
  ViewContainerRef,
  Renderer2,
  SimpleChanges,
  OnChanges,
  ComponentRef,
  ComponentFactoryResolver,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Directive({
  selector: `button[mat-button][loading],
             button[mat-raised-button][loading],
             button[mat-icon-button][loading],
             button[mat-fab][loading],
             button[mat-mini-fab][loading],
             button[mat-stroked-button][loading],
             button[mat-flat-button][loading]`,
})
export class ButtonLoaderDirective implements OnChanges {
  @Input() loading = false;
  private spinner?: ComponentRef<MatProgressSpinner>;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private matButton: MatButton,
    private renderer: Renderer2,
  ) {}

  get nativeElement(): HTMLElement {
    return this.matButton._elementRef.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const loading = changes['loading'];
    if (!changes['loading']) {
      return;
    }

    if (loading.currentValue) {
      this.nativeElement.classList.add('mat-loading');
      this.createSpinner();
    } else if (!loading.firstChange) {
      this.nativeElement.classList.remove('mat-loading');
      this.destroySpinner();
    }
  }

  private createSpinner(): void {
    if (this.spinner) {
      return;
    }

    const factory = this.componentFactoryResolver.resolveComponentFactory(MatProgressSpinner);

    this.spinner = this.viewContainerRef.createComponent(factory);
    this.spinner.instance.mode = 'indeterminate';
    this.spinner.instance.color = 'primary';
    this.spinner.instance.strokeWidth = 2;
    this.spinner.instance.diameter = 21;

    this.renderer.appendChild(this.nativeElement, this.spinner.instance._elementRef.nativeElement);
  }

  private destroySpinner(): void {
    if (this.spinner) {
      this.spinner.destroy();
      this.spinner = undefined;
    }
  }
}
