import { Component, Input } from '@angular/core';
import { SimpleDetailsList } from '../logic/simple-details-list';

@Component({
  selector: 'vdms-hq-ui-simple-details-list[listConfig]',
  templateUrl: './simple-details-list.component.html',
  styleUrls: ['./simple-details-list.component.scss'],
})
export class UISimpleDetailsListComponent {
  @Input() listConfig: SimpleDetailsList[] = [];
}
