import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StatsStaticViewModel } from '../logic/models/stats-static.model';

@Component({
  selector: 'vdms-hq-ui-stats-static',
  templateUrl: './stats-static.component.html',
  styleUrls: ['./stats-static.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatsStaticComponent {
  @Input()
  statsStaticVM!: StatsStaticViewModel;
}
