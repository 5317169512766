import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StatsChartViewModel } from '../logic/models/stats-chart-view.model';
import { ChartDataset, ChartOptions, ChartType, DoughnutControllerChartOptions } from 'chart.js';
import { axisLabelFont, defaultPallete } from '../logic/models/default.config';

@Component({
  selector: 'vdms-hq-ui-stats-chart-pie',
  templateUrl: './stats-chart-pie.component.html',
  styleUrls: ['./stats-chart-pie.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatsChartPieComponent {
  readonly chartType: ChartType = 'doughnut';
  labels: string[] = [];
  additionalText!: string | undefined;
  dataset: ChartDataset[] = [];
  chartLegend = true;
  chartPlugins = [];
  chartOptions!: ChartOptions & Partial<DoughnutControllerChartOptions>;
  maxHeight?: string;

  @Input() set statsChartVM(value: StatsChartViewModel) {
    this.labels = value.labels;
    this.additionalText = value.additionalText;
    this.dataset = value.chartDataSets;
    this.maxHeight = value.maxHeight;
    this.chartOptions = {
      radius: value.pieSettings?.radius ?? 40,
      cutout: value.pieSettings?.cutout ?? 80,
      backgroundColor: defaultPallete as any,
      responsive: true,
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
      plugins: {
        legend: {
          display: true,
          position: 'right',
          align: value.legendAlign ?? 'center',
          labels: {
            color: value.labelsTextColor,
            boxHeight: 10,
            boxWidth: 10,
            ...axisLabelFont,
            ...value.labelSettings,
          },
        },
      },
    };
  }
}
