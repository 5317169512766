import { UserTrackingService } from './logic/user-tracking.service';

import { InjectionToken, Provider } from '@angular/core';
import {
  DEBUG_MODE,
  ScreenTrackingService,
  UserTrackingService as VendorUserTrackingService,
} from '@angular/fire/compat/analytics';

export interface AnalyticsConfig {
  appName: string;
  debug: boolean;
}
export const ANALYTICS_TOKEN: InjectionToken<AnalyticsConfig> = new InjectionToken<AnalyticsConfig>('ANALYTICS_TOKEN');

export const configureAnalytics = (params: AnalyticsConfig) => {
  const providers: Provider[] = [
    {
      provide: VendorUserTrackingService,
      useClass: UserTrackingService,
    },
    ScreenTrackingService,
    { provide: ANALYTICS_TOKEN, useValue: params },
  ];

  if (params.debug) {
    providers.push({
      provide: DEBUG_MODE,
      useValue: true,
    });
  }
  return providers;
};
