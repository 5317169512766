import { Inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { Subscription, withLatestFrom } from 'rxjs';
import { AuthService } from '@vdms-hq/auth';
import { registerVersion } from '@angular/fire/app';
import { VERSION } from '@angular/fire';
import { isSupported } from '@angular/fire/analytics';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { ANALYTICS_TOKEN, AnalyticsConfig } from '../configure';

@Injectable({
  providedIn: 'root',
})
export class UserTrackingService implements OnDestroy {
  public readonly initialized: Promise<void>;
  private disposables: Subscription[] = [];

  constructor(
    auth: AuthService,
    activatedClient: ActivatedClientService,
    zone: NgZone,
    analytics: AngularFireAnalytics,
    @Inject(ANALYTICS_TOKEN) env: AnalyticsConfig,
  ) {
    registerVersion('angularfire', VERSION.full, 'user-tracking');
    let resolveInitialized: () => void;
    this.initialized = zone.runOutsideAngular(
      () =>
        new Promise((resolve) => {
          resolveInitialized = resolve;
        }),
    );

    isSupported().then(() => {
      if (analytics) {
        this.disposables = [
          auth.id$.pipe(withLatestFrom(activatedClient.clientId$)).subscribe(([userId, clientId]) => {
            const propsToLog = {
              user_uuid: userId,
              client_uuid: clientId,
              app_name: env.appName,
            };

            userId && analytics.setUserId(userId);
            analytics.setUserProperties(propsToLog);

            resolveInitialized();
          }),
        ];
      } else {
        resolveInitialized();
      }
    });
  }

  ngOnDestroy() {
    this.disposables.forEach((it) => it.unsubscribe());
  }
}
