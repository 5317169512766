export class Pagination {
  constructor(private page = 0, private perPage = 24) {}

  static all() {
    return new Pagination(0, 100000);
  }

  static page(page: number, perPage?: number) {
    return new Pagination(page, perPage);
  }

  toHeaders = () => ({
    'per-page': this.perPage.toString(),
    page: this.page.toString(),
  });
}
