<div class="field-readonly">
  <div class="label">{{ definition.label }}</div>
  <div
    class="value value--fallback"
    *ngIf="value | controlValueFormat : definition | async; let value; else: fallbackValue"
  >
    {{ value }}
  </div>
</div>

<ng-template #fallbackValue>
  <div class="value value--fallback">N/A</div>
</ng-template>
