import { Injectable } from '@angular/core';

@Injectable()
export class TimecodeService {
  readonly fps: number;

  constructor() {
    this.fps = 25.0;
  }

  private isDropTimecode() {
    switch (this.fps) {
      case 29.97:
      case 59.94:
        return true;
      default:
        return false;
    }
  }

  public timecodeToSeconds(timeCode: string) {
    const isDrop = this.isDropTimecode();
    const time: string[] = timeCode.split(':');

    if (time.length !== 4) {
      return false;
    } else {
      const minutes = parseInt(time[0], 10) * 60 + Number(time[1]);
      const minutesCounted = minutes - Math.floor(minutes / 10);
      const isDivisibleByTen = Number(time[1]) % 10 === 0;
      const seconds = minutes * 60 + Number(time[2]);
      let frames = Number(time[3]);
      frames += Math.ceil(seconds * this.fps);

      if (isDrop) {
        let subtractFrames = 2;
        if (this.fps === 59.94) {
          subtractFrames = 4;
        }
        if (parseInt(time[3], 10) < subtractFrames && minutesCounted > 0 && time[2] === '00' && !isDivisibleByTen) {
          frames += subtractFrames - Number(time[3]);
        }
        frames = frames - minutesCounted * subtractFrames;
      }
      const result = frames / this.fps;
      if (this.fps === 23.976 || isDrop) {
        return result * 1.001;
      }

      return result;
    }
  }

  public secondsToTimecode(sec: number) {
    const parts = this.frameToTimecode(Math.floor(sec * this.fps));
    const hours = parts[0];
    const minutes = parts[1];
    const seconds = parts[2];
    const frames = parts[3];

    const h = hours < 10 ? '0' + hours : hours.toString();
    const m = minutes < 10 ? '0' + minutes : minutes.toString();
    const s = seconds < 10 ? '0' + seconds : seconds.toString();
    const f = frames < 10 ? '0' + frames : frames.toString();

    return h + ':' + m + ':' + s + ':' + f;
  }

  public frameToTimecode(frames) {
    const drop = this.isDropTimecode();

    let dropFrames = 0;
    if (drop) {
      dropFrames = parseInt(String(Math.round(this.fps * 0.066666)), 10);
    }

    const framesPerhour = parseInt(String(Math.round(this.fps * 60 * 60)), 10);
    const framesPer24hours = framesPerhour * 24;
    const framesPer10minutes = parseInt(String(Math.round(this.fps * 60 * 10)), 10);
    const framesPerMinute = parseInt(String(Math.round(this.fps) * 60), 10) - dropFrames;

    let frameNumber = frames;

    if (frameNumber < 0) {
      frameNumber += framesPer24hours;
    }

    frameNumber %= framesPer24hours;

    if (drop) {
      const d = parseInt(String(frameNumber / framesPer10minutes), 10);
      const m = frameNumber % framesPer10minutes;
      if (m > dropFrames) {
        frameNumber += dropFrames * 9 * d + dropFrames * parseInt(String((m - dropFrames) / framesPerMinute), 10);
      } else {
        frameNumber += dropFrames * 9 * d;
      }
    }

    const ifps = Math.ceil(this.fps);
    const frs = frameNumber % ifps;

    const secs = parseInt(String(frameNumber / ifps), 10) % 60;
    const mins = parseInt(String(parseInt(String(frameNumber / ifps), 10) / 60), 10) % 60;
    const hrs = parseInt(String(parseInt(String(parseInt(String(frameNumber / ifps), 10) / 60), 10) / 60), 10);

    return [hrs, mins, secs, frs];
  }

  public seek(nrOfFrame, videoCurrentTime) {
    const currentFrames = Math.round(videoCurrentTime * this.fps);
    return (currentFrames + nrOfFrame) / this.fps;
  }
}
