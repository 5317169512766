import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIFormModule, UIModule } from '@vdms-hq/ui';
import { ViewPlayerMetadataItem } from '../../logic/metadata-list.model';
import { StreamsType } from '../../logic/streams/streams-type';
import { PlayerService, TimelineRow, TimelineService } from '@vdms-hq/player';
import { ShowOnTimelineService } from '../../logic/show-on-timeline.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vdms-hq-show-on-timeline',
  templateUrl: './show-on-timeline.component.html',
  styleUrls: ['./show-on-timeline.component.scss'],
  standalone: true,
  imports: [CommonModule, UIModule, UIFormModule],
})
export class ShowOnTimelineComponent {
  @Input() type!: StreamsType;
  @Input() content!: string;
  @Input() languageKey!: string;
  @Input() vttPath!: string;
  @Input() selected = false;
  @Input() allData!: ViewPlayerMetadataItem[];

  constructor(
    private timelineService: TimelineService,
    private showOnTimelineService: ShowOnTimelineService,
    private translateService: TranslateService,
    private playerService: PlayerService,
  ) {}

  onShowOnTimeline() {
    this.showOnTimelineService.timelineSelected$.next({
      type: this.type,
      content: this.content,
      languageKey: this.languageKey,
    });

    if (this.selected) {
      const filteredItems = this.allData.filter((item) => item.type === this.type && item.content === this.content);
      if (this.type === StreamsType.MI_SUBTITLES) {
        this.#importSubtitlesToPlayer();
      } else {
        this.timelineService.addCustomRows(this.#prepareTimelineData(filteredItems));
      }
    } else {
      this.timelineService.removeCustomRow(this.timelineId);
    }
  }

  #prepareTimelineData(filteredItems: ViewPlayerMetadataItem[]): TimelineRow[] {
    return [
      {
        id: this.timelineId,
        label: this.generateLabel,
        values: filteredItems.map((item) => ({
          tcIn: item.timestamp.timecodeIn?.countSeconds() ?? 0,
          tcOut: item.timestamp.timecodeOut?.countSeconds(),
          tooltip: item.content,
        })),
      },
    ];
  }

  #importSubtitlesToPlayer() {
    const subtitles = [
      {
        path: this.vttPath,
        language: this.languageKey,
      },
    ];
    this.playerService.attachSubtitles(subtitles);
  }

  get generateLabel() {
    return this.type === StreamsType.MI_SUBTITLES
      ? this.translateService.instant(`common.player_metadata_list.stream_type_label.${this.type}`, {
          lang: this.languageKey,
        })
      : this.content;
  }

  get timelineId() {
    return `${this.type}_${this.content}`;
  }
}
