import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIDataHeaderActionsComponent } from '../ui-data-header-actions/component/data-header-actions.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ResultsWrapperComponent } from './results-wrapper/results-wrapper.component';
import { TranslateModule } from '@ngx-translate/core';
import { UILayoutModule } from '../ui-layout/ui-layout.module';
import { UIFormModule } from '../ui-form/ui-form.module';
import { UIButtonModule } from '../ui-button/ui-button.module';

@NgModule({
  declarations: [ResultsWrapperComponent],
  imports: [
    CommonModule,
    UILayoutModule,
    UIFormModule,
    UIButtonModule,
    MatTooltipModule,
    TranslateModule,
    UIDataHeaderActionsComponent,
  ],
  exports: [ResultsWrapperComponent],
})
export class UIResultsWrapperModule {}
