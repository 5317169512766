import { InjectionToken } from '@angular/core';

export interface TranscriptionConfig {
  voiceAIApiUrl: string;
  voiceAIApiKey: string;
}

export const TRANSCRIPTION_CONFIG: InjectionToken<TranscriptionConfig> = new InjectionToken<TranscriptionConfig>(
  'TRANSCRIPTION_CONFIG',
);
