<vdms-hq-ui-results-wrapper
  [dataSource]="discountsDsService"
  [breadcrumbs]="[{ name: 'pages.clients.discounts.title' | translate }]"
  [configuration]="headerConfig"
  (headerAction)="handleAction($event)"
>
  <vdms-hq-ui-loader
    *ngIf="discountsDsService.isLoading$ | async"
    [backdrop]="true"
    mode="over-parent"
  ></vdms-hq-ui-loader>
  <vdms-hq-ui-multiple-data-presentation
    [dataSource]="discountsDsService"
    [configuration]="viewConfig"
    (action)="handleAction($event)"
  >
    <vdms-hq-dynamic-form-group-renderer
      filters
      [form]="discountsDsService.filters"
      [fieldsConfig]="filtersConfig"
      [hasLoader]="true"
    ></vdms-hq-dynamic-form-group-renderer>
  </vdms-hq-ui-multiple-data-presentation>
</vdms-hq-ui-results-wrapper>
