import { Filters, FilterValue } from './filters.model';
import { clone } from 'lodash';
import { FieldConfigId } from '@vdms-hq/shared';

export class PersistenceSearchQuery {
  readonly keyValues: Record<FieldConfigId, FilterValue>;

  constructor(public text?: string, public readonly filters: Filters = {}) {
    if (filters['text']) {
      throw 'PersistenceSearchQuery error, filters should not contain `text` keyword';
    }

    const filterKeyValues: Record<FieldConfigId, FilterValue> = {};

    Object.entries(filters).forEach(([key, filter]) => (filterKeyValues[key] = filter.value));

    this.keyValues = filterKeyValues;
  }

  get allKeyValues() {
    return {
      text: this.text,
      ...this.keyValues,
    }
  }

  static empty() {
    return new PersistenceSearchQuery();
  }

  static freeText(text: string) {
    return new PersistenceSearchQuery(text);
  }

  static fromFilters(text?: string, filters?: Filters) {
    return new PersistenceSearchQuery(text, filters);
  }

  get(key: string) {
    return this.filters[key] ?? null;
  }

  isEqual = (next: PersistenceSearchQuery) => {
    return JSON.stringify(this.allKeyValues) === JSON.stringify(next.allKeyValues);
  }

  clone = () => clone(this);
}
