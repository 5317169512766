import { inject, Injectable } from '@angular/core';
import { Permission, PermissionService } from '@vdms-hq/activated-client';
import {
  AddAsset,
  AddAssetPayload,
  AddPayload,
  ApiResponse,
  AssetSearchFilters,
  CartApiService,
} from '@vdms-hq/api-contract';
import { AssetFlatView2, AssetFlatView2Model } from '@vdms-hq/asset-results';
import { ToastService } from '@vdms-hq/toast';
import { DataAction } from '@vdms-hq/ui';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { CartStateService } from './cart-state.service';

@Injectable({ providedIn: 'root' })
export class AddToCartActionsService {
  private permissionService = inject(PermissionService);
  private cartService = inject(CartApiService);
  private toastService = inject(ToastService);
  private cartStateService = inject(CartStateService);

  #popToast = {
    CART_PUT_FAILURE: () =>
      this.toastService.error({
        id: 'cart_put',
        message: 'pages.cart.notifications.add.try_again',
      }),
  };

  buildAddAssetAction$<T extends AssetFlatView2Model = AssetFlatView2Model>(
    additionalCondition = false,
  ): Observable<DataAction<T>> {
    return combineLatest([
      this.cartStateService.isEnabled$,
      this.cartStateService.isClipOnlyAndPermitted$,
      this.permissionService.verifyWithOwnedPermissions$([Permission.USE_QUARANTINED_ASSETS]),
    ]).pipe(
      take(1),
      map(([isDisabled, isClipOnlyAndPermitted, canUseQuarantined]) => {
        return {
          key: 'cart.add',
          label: 'common.global.add_to_cart',
          icon: 'add_shopping_cart',
          color: 'primary',
          onDoubleClick: false,
          disabled: isDisabled,
          hiddenIf: (item?) =>
            !isClipOnlyAndPermitted || additionalCondition || (!!item?.isQuarantined && !canUseQuarantined),
          disabledIf: () => isDisabled,
        };
      }),
    );
  }

  handleAddAssetAction<T extends AssetFlatView2 = AssetFlatView2>($event: { key: string; item?: T }): void {
    if ($event.key !== 'cart.add' || !$event.item) {
      return;
    }

    this.#addAssetToCart([$event.item]);
  }

  addOrders(orderUuids: string[]) {
    const payload: AddPayload[] = orderUuids.map((orderUuid) => ({
      type: 'order',
      entity_uuid: orderUuid,
    }));
    this.#cartPut(payload);
  }

  addCollections(collectionUuids: string[]) {
    const payload: AddPayload[] = collectionUuids.map((collectionUuid) => ({
      type: 'collection',
      entity_uuid: collectionUuid,
    }));
    this.#cartPut(payload);
  }

  addLibraryItems(libraryItemUuids: string[]) {
    const payload: AddPayload[] = libraryItemUuids.map((libraryItemUuid) => ({
      type: 'library_item',
      entity_uuid: libraryItemUuid,
    }));
    this.#cartPut(payload);
  }

  addFromSearchFilters(searchFilters: AssetSearchFilters) {
    this.cartStateService.isUpdating$.next(true);

    this.cartService.addFromSearchQuery(searchFilters).subscribe({
      error: () => {
        this.#popToast.CART_PUT_FAILURE();
        this.cartStateService.isUpdating$.next(false);
      },
    });
  }

  /**
   * @deprecated, use build action and handle action instead
   * @param items
   */
  addAssets(items: AddAsset[]) {
    const payload: AddAssetPayload[] = items.map((item) => {
      const hasTimecodes = (item.tcIn || item.tcOut) && item.tcIn !== '00:00:00:00' && item.tcOut !== '00:00:00:00';

      return {
        type: 'asset',
        entity_uuid: item.assetId,
        extra:
          hasTimecodes || item.offset
            ? {
                offset: item.offset ?? null,
                timecode_in: item.tcIn ?? undefined,
                timecode_out: item.tcOut ?? undefined,
                note: item.note,
                voiceover_required: item.voiceOverRequired,
              }
            : undefined,
      };
    });
    this.#cartPut(payload);
  }

  #addAssetToCart<T extends AssetFlatView2 = AssetFlatView2>(assets: T[]) {
    const assetIds = assets.map((item) => item.props.uuid);

    this.addAssets(assetIds.map((assetId) => ({ assetId })));
  }

  #cartPut(payload: AddPayload[] | AddAssetPayload[]) {
    this.cartStateService.isUpdating$.next(true);

    this.cartService
      .put<AddPayload[] | AddAssetPayload[], ApiResponse<unknown>>(payload)
      .pipe(take(1))
      .subscribe({
        error: () => {
          this.#popToast.CART_PUT_FAILURE();
          this.cartStateService.isUpdating$.next(false);
        },
      });
  }
}
