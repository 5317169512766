import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { PermissionEndpoints } from './endpoints';
import { Observable } from 'rxjs';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { ApiResponse } from '../api.model';
import { PermissionModel } from '../policy/permission.model';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  constructor(private readonly apiService: ApiService) {}

  public getPermissions(): Observable<PermissionModel[]> {
    return this.apiService.get<ApiResponse<PermissionModel[]>>(PermissionEndpoints.ROOT_PLURAL).pipe(GetResponseData);
  }

  public getPermission(id: Pick<PermissionModel, 'uuid'>): Observable<PermissionModel> {
    return this.apiService.get<ApiResponse<PermissionModel>>(`${PermissionEndpoints.ROOT}/${id}`).pipe(GetResponseData);
  }
}
