import { NgModule } from '@angular/core';
import { PreferredTzDatePipe } from './preferred-tz-date.pipe';
import { DatePipe } from '@angular/common';
import { TzDatePipe } from './tz-date.pipe';

@NgModule({
  declarations: [],
  exports: [PreferredTzDatePipe],
  imports: [TzDatePipe, PreferredTzDatePipe],
  providers: [DatePipe, TzDatePipe],
})
export class AuthPipesModule {}
