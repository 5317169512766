export interface VideoCodec {
  uuid: string;
  video_codec: string;
  profile: string;
  display_name: string;
}

export type PostVideoCodec = Omit<VideoCodec, 'uuid'>;

export enum VIDEO_CODECS_ENDPOINTS {
  ROOT_MULTIPLE = 'video-codec-mappings',
  ROOT_SINGLE = 'video-codec-mapping',
}

export interface ImportVideoCodecsCSV {
  filename: string;
}
