import { AdvancedPlayerService } from './advanced-player.service';
import { BehaviorSubject, Subject, take, takeUntil } from 'rxjs';
import { Injectable } from '@angular/core';
import { OmakasePlayer } from '@vdms-hq/omakase-player';
import { ADVANCED_PLAYER_REQUIRED_COOKIES, PEAK_METER_STYLES } from './advanced-player';
import { WebAudioPeakMeter } from 'web-audio-peak-meter';

import Hls from 'hls.js';

export interface AudioTrack {
  attrs: Attrs;
  bitrate: number;
  id: number;
  groupId: string;
  name: string;
  type: string;
  default: boolean;
  autoselect: boolean;
  forced: boolean;
  lang: string;
  url: string;
  audioCodec: string;
  details: Details;
}

export interface Attrs {
  TYPE: string;
  'GROUP-ID': string;
  NAME: string;
  LANGUAGE: string;
  AUTOSELECT: string;
  DEFAULT: string;
  CHANNELS: string;
  URI: string;
}

export interface Details {
  PTSKnown: boolean;
  alignedSliding: boolean;
  averagetargetduration: number;
  endCC: number;
  endSN: number;
  fragments: any[];
  partList: any;
  live: boolean;
  ageHeader: number;
  advancedDateTime: number;
  updated: boolean;
  advanced: boolean;
  misses: number;
  startCC: number;
  startSN: number;
  startTimeOffset: any;
  targetduration: number;
  totalduration: number;
  type: string;
  url: string;
  m3u8: string;
  version: number;
  canBlockReload: boolean;
  canSkipUntil: number;
  canSkipDateRanges: boolean;
  skippedSegments: number;
  partHoldBack: number;
  holdBack: number;
  partTarget: number;
  tuneInGoal: number;
  driftStartTime: number;
  driftEndTime: number;
  driftStart: number;
  driftEnd: number;
  encryptedFragments: any[];
}

type AudioMeterState = {
  context: AudioContext;
  element: HTMLAudioElement;
  meterElement: HTMLDivElement;
  audioTrack: AudioTrack;
};

@Injectable({ providedIn: 'root' })
export class PeakMeterService {
  private readonly PEAK_METER_DOM_ELEMENT = 'peak-meter-wrapper';

  #destroy$ = new Subject<void>();

  audioTracks$ = new BehaviorSubject<AudioMeterState[]>([]);
  #connected = new BehaviorSubject(false);
  isConnected$ = this.#connected.asObservable();
  #initialized = false;

  constructor(private playerService: AdvancedPlayerService) {}

  load() {
    this.playerService.onPlayPause$
      .pipe(takeUntil(this.#destroy$))
      .subscribe((state) => (state === 'playing' ? this.play() : this.pause()));
    this.playerService.currentPlaybackRate$.pipe(takeUntil(this.#destroy$)).subscribe((value) => {
      this.audioTracks$.value.forEach(({ element }) => (element.playbackRate = value));
    });
  }

  enabled(): boolean {
    return this.#connected.value;
  }

  isDisabled() {
    return !this.#initialized;
  }

  async toggleEnabled() {
    const isConnected = !this.#connected.value;
    this.#connected.next(isConnected);

    if (!this.playerService.player) {
      return;
    }

    await this.#initialize(this.playerService.player);

    if (isConnected) {
      this.playerService.handleAction('pause');
      this.playerService.setVideoOnlyMode(true);
      this.audioTracks$.value.forEach(({ element }) => (element.muted = false));
    } else {
      this.playerService.setVideoOnlyMode(false);
      this.audioTracks$.value.forEach(({ element }) => (element.muted = true));
    }
  }

  unload() {
    this.#destroy$?.next();
    this.#destroy$?.complete();
    this.audioTracks$.value.forEach((audioTrack) => {
      audioTrack.meterElement.remove();
      audioTrack.element.remove();
    });

    this.audioTracks$.next([]);
    this.#initialized = false;
  }

  async #initialize(player: OmakasePlayer) {
    if (this.#initialized) {
      return false;
    }
    this.#initialized = true;

    const audioTracks = player.audio.getAudioTracks() as AudioTrack[];

    const audioElements: AudioMeterState[] = [];
    for (const audioTrack of audioTracks) {
      const audioElement = document.createElement('audio') as HTMLAudioElement;

      const config = {
        enableWorker: false,
        debug: false,
        xhrSetup: this.playerService.config?.withCredentials
          ? function (xhr: { withCredentials: boolean; setRequestHeader: (arg0: string, arg1: string) => void }) {
              xhr.withCredentials = true;
              ADVANCED_PLAYER_REQUIRED_COOKIES.forEach((header) => xhr.setRequestHeader(header.name, header.value)); // do send cookie
            }
          : undefined,
      };

      const hlsInstance = new Hls(config);
      hlsInstance.loadSource(audioTrack.url);
      hlsInstance.attachMedia(audioElement);

      const layout51 = audioTrack.attrs.CHANNELS === '6';

      const audioContext = new AudioContext();
      const audioSource = audioContext.createMediaElementSource(audioElement);
      const meterWrapper = document.getElementById(this.PEAK_METER_DOM_ELEMENT) as HTMLElement;
      const meterElement = document.createElement('div') as HTMLDivElement;

      switch (true) {
        case layout51:
          audioSource.channelCountMode = 'max';
          audioSource.channelCount = 6;
          meterElement.style.width = '220px';
          break;

        default:
          meterElement.style.width = '100px';
          break;
      }

      audioSource.connect(audioContext.destination);
      meterWrapper.append(meterElement);

      const webAudioPeakMeter = new WebAudioPeakMeter(audioSource, meterElement, PEAK_METER_STYLES);
      await webAudioPeakMeter.initNode();

      audioElement.muted = true;

      audioElements.push({
        context: audioContext,
        element: audioElement,
        meterElement: meterElement,
        audioTrack,
      });
    }

    this.audioTracks$.next(audioElements);

    return true;
  }

  synchronise(callback?: () => void) {
    this.playerService.currentTimecode$.pipe(take(1)).subscribe((time) => {
      if (!time) {
        return;
      }

      this.audioTracks$.value.forEach((state) => {
        state.element.pause();
        state.element.currentTime = time?.countSeconds();
      });
      if (callback) {
        callback();
      }
    });
  }

  pause() {
    if (!this.#initialized) {
      return;
    }

    this.synchronise();
  }

  play() {
    if (!this.#initialized || !this.#connected.value) {
      return;
    }
    this.synchronise(() => this.audioTracks$.value.forEach(({ element }) => element.play()));
  }
}
