import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toIterable',
})
export class ToIterablePipe implements PipeTransform {
  transform = ToIterablePipe.transform;

  static transform<T>(value: undefined | null | T | T[]): T[] {
    if (!Array.isArray(value)) {
      if (value !== null && typeof value === 'object') {
        return Object.values(value).filter(Boolean) as T[];
      }
      return [value].filter(Boolean) as T[];
    }
    return value;
  }
}
