import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, map, tap, withLatestFrom } from 'rxjs';
import { MetadataListForm } from './metadata-list-form';
import { StreamsSourceService } from './streams/streams-source.service';
import { switchMap } from 'rxjs/operators';
import { ViewPlayerMetadataItem } from './metadata-list.model';
import { ShowOnTimelineService } from './show-on-timeline.service';
import { StreamsType } from './streams/streams-type';

@Injectable({ providedIn: 'root' })
export class MetadataListFetcher {
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadSelected$ = combineLatest([this.metadataListForm.values$, this.showOnTimelineService.timelineSelected$]).pipe(
    tap(() => this.isLoading$.next(true)),
    withLatestFrom(this.streamsSourceService.sources$),
    switchMap(([[values, onTimelineSelected], sources]) => {
      const selectedSources = sources.filter((source) => values.type.includes(source.key)).map((obj) => obj.source);
      return forkJoin(selectedSources).pipe(
        map((metadata) => metadata.reduce((prev, curr) => prev.concat(curr), [])),
        map((metadata) =>
          metadata
            .filter((item) => {
              if (Number(item.confidence) < Number(values.confidence ?? 100)) {
                return false;
              }

              if (
                values?.phrase?.length &&
                !values?.phrase.some((phrase) => {
                  const findPhrase = this.#findPhraseRegExp(this.#removeSpecialChars(phrase));
                  return item.content.toLowerCase().match(findPhrase);
                })
              ) {
                return false;
              }

              return true;
            })
            .map((item) => {
              if (!values?.phrase?.length) {
                return item;
              }

              const findPhrase = this.#findPhraseRegExp(values?.phrase?.map(this.#removeSpecialChars)?.join('|'));
              const content = item.content.replace(findPhrase, (match: string) => '<em>' + match + '</em>');
              return { ...item, content };
            }),
        ),
        map((metadata) =>
          metadata.map((item) => {
            if (onTimelineSelected) {
              if (
                (item.type === onTimelineSelected?.type && item.content === onTimelineSelected.content) ||
                (item.type === onTimelineSelected?.type &&
                  item.type === StreamsType.MI_SUBTITLES &&
                  item.languageKey === onTimelineSelected.languageKey)
              ) {
                item.onTimeline = !item.onTimeline;
              }
            }
            return item;
          }),
        ),
        map((metadata) => {
          return metadata.sort((a: ViewPlayerMetadataItem, b: ViewPlayerMetadataItem) => {
            if (!a.timestamp.timecodeIn || !b.timestamp.timecodeIn) {
              return -1;
            }
            return a.timestamp.timecodeIn.lessThan(b.timestamp.timecodeIn) ? -1 : 1;
          });
        }),
      );
    }),
    tap(() => this.isLoading$.next(false)),
  );

  #removeSpecialChars = (str: string) => str.replace(/[^\w\s]/gi, '');

  #findPhraseRegExp = (str: string) => new RegExp('(\\b' + str + '\\b[.,/\\\\()]*)', 'gi');

  constructor(
    private metadataListForm: MetadataListForm,
    private streamsSourceService: StreamsSourceService,
    private showOnTimelineService: ShowOnTimelineService,
  ) {}
}
