import { Injectable } from '@angular/core';

import { Pagination, PaginationProps } from '@vdms-hq/ui';
import { HttpHeaders } from '@angular/common/http';
import { GetResponseData, GetResponsePaginationData } from '../../operators/get-response-data.operator';
import { CreateDestinationParams, DestinationModel, UpdateDestinationParams } from './destination.model';
import { ApiService } from '../api.service';
import { ApiPaginatedResponse, ApiResponse } from '../api.model';
import { DESTINATION_ENDPOINTS } from './destination-enpoints.model';

@Injectable({ providedIn: 'root' })
export class DestinationApiService {
  constructor(private readonly apiService: ApiService) {}

  create = (body: CreateDestinationParams) =>
    this.apiService.post<CreateDestinationParams, ApiResponse<DestinationModel>>(`${DESTINATION_ENDPOINTS.ROOT}`, body);

  get = (id: string) =>
    this.apiService.get<ApiResponse<DestinationModel>>(`${DESTINATION_ENDPOINTS.ROOT}/${id}`).pipe(GetResponseData);

  getAll = () => {
    const pagination: PaginationProps = {
      page: '0',
      perPage: '1000',
      orderBy: '',
      orderDir: '',
    };
    const headers = Pagination.create(pagination).applyToHeaders(new HttpHeaders());
    return this.apiService
      .get<ApiPaginatedResponse<DestinationModel>>(DESTINATION_ENDPOINTS.ROOT_PLURAL, { headers })
      .pipe(GetResponsePaginationData);
  };

  getPaginated = (
    pagination?: { text?: string; perPage?: string; page?: string },
    orderBy?: string,
    orderDir?: string,
  ) => {
    const headers = new HttpHeaders({
      filter: pagination?.text ?? '',
      'per-page': pagination?.perPage ?? '24',
      page: pagination?.page ?? '0',
      'order-by': orderBy ?? 'name',
      'order-dir': orderDir ?? 'ASC',
    });
    return this.apiService
      .get<ApiPaginatedResponse<DestinationModel>>(DESTINATION_ENDPOINTS.ROOT_PLURAL, { headers })
      .pipe(GetResponsePaginationData);
  };

  update = (id: string, data: UpdateDestinationParams) =>
    this.apiService
      .patch<UpdateDestinationParams, ApiResponse<DestinationModel>>(`${DESTINATION_ENDPOINTS.ROOT}/${id}`, data)
      .pipe(GetResponseData);

  delete = (id: string) => this.apiService.delete(`${DESTINATION_ENDPOINTS.ROOT}/${id}`);
}
