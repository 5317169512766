import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pagination, PaginationProps } from '@vdms-hq/ui';
import { map } from 'rxjs/operators';
import { ApiPaginatedResponse, ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { SearchParams } from '../parameters/search-params';
import {
  DeliveryUploadJobAsperaPatchResponse,
  DeliveryUploadJobAsperaPost,
  DeliveryUploadJobAsperaPostResponse,
  DeliveryUploadJobFilterParams,
  DeliveryUploadJobGet,
  DeliveryUploadJobGetFlat,
  DeliveryUploadJobPost,
  DeliveryUploadJobResolveDetails,
  DeliveryUploadJobReUploadPatch,
} from './upload-job.model';

const UPLOAD_JOB_URL = 'upload-job';
const UPLOAD_JOB_APPROVE_URL = 'upload-jobs/approve';
const UPLOAD_JOB_REJECT_URL = 'upload-jobs/reject';
const UPLOAD_JOB_RESOLVE_URL = 'upload-jobs/resolve';
const DELIVERY_PACK_URL = 'delivery-pack';

interface RejectReasonDetails {
  rejected_reason?: string;
  rejected_details?: string;
  email_recipients?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class DeliveryUploadJobApiService {
  constructor(private apiService: ApiService) {}

  list = (deliveryUuid: string, filters?: DeliveryUploadJobFilterParams, pagination?: PaginationProps) => {
    const headers = Pagination.create(pagination).applyToHeaders(new HttpHeaders());

    let queryParams;

    if (filters) {
      const params = new SearchParams({
        status: filters.status,
      });

      queryParams = params.toQueryParams();
    }

    return this.apiService.get<ApiPaginatedResponse<DeliveryUploadJobGetFlat>>(
      `${DELIVERY_PACK_URL}/${deliveryUuid}/upload-jobs`,
      {
        headers,
        params: queryParams,
      },
    );
  };

  get = (id: string) => this.apiService.get<DeliveryUploadJobGet>(`${UPLOAD_JOB_URL}/${id}`);

  create = (data: DeliveryUploadJobPost) =>
    this.apiService
      .post<DeliveryUploadJobPost, ApiResponse<DeliveryUploadJobGet>>(UPLOAD_JOB_URL, data)
      .pipe(map(({ data }) => data));

  createAsperaBatchUpload = (data: DeliveryUploadJobAsperaPost) =>
    this.apiService
      .post<DeliveryUploadJobAsperaPost, ApiResponse<DeliveryUploadJobAsperaPostResponse>>(
        `${UPLOAD_JOB_URL}/aspera`,
        data,
      )
      .pipe(map(({ data }) => data as DeliveryUploadJobAsperaPostResponse));

  createAsperaReUpload = (jobUuid: string, data: DeliveryUploadJobReUploadPatch) => {
    return this.apiService
      .patch<DeliveryUploadJobReUploadPatch, ApiResponse<DeliveryUploadJobAsperaPatchResponse>>(
        `${UPLOAD_JOB_URL}/${jobUuid}/aspera/re-upload`,
        data,
      )
      .pipe(map(({ data }) => data as DeliveryUploadJobAsperaPatchResponse));
  };

  deleteMany = (ids: string[], deliveryUuid: string) => {
    return this.apiService.patch(`${DELIVERY_PACK_URL}/${deliveryUuid}`, { delete_jobs_uuids: ids });
  };

  approveMany = (ids: string[], deliveryUuid: string) => {
    return this.apiService.patch(`${DELIVERY_PACK_URL}/${deliveryUuid}/${UPLOAD_JOB_APPROVE_URL}`, {
      uuids: ids,
    });
  };

  rejectMany = (ids: string[], deliveryUuid: string, reason: RejectReasonDetails) => {
    return this.apiService.patch(`${DELIVERY_PACK_URL}/${deliveryUuid}/${UPLOAD_JOB_REJECT_URL}`, {
      uuids: ids,
      ...reason,
    });
  };

  resolveMany = (ids: string[], deliveryUuid: string, data: DeliveryUploadJobResolveDetails) => {
    const { resolved_reason, resolved_details } = data;
    const body = {
      uuids: ids,
      resolved_reason: resolved_reason,
      resolved_details: resolved_details,
    };

    return this.apiService.patch(`${DELIVERY_PACK_URL}/${deliveryUuid}/${UPLOAD_JOB_RESOLVE_URL}`, body);
  };
}
