<cdk-virtual-scroll-viewport
  #scrollViewport
  [itemSize]="88"
  minBufferPx="100"
  maxBufferPx="400"
  [style.height]="folders.length | calculateFoldersContainerHeight : perRow"
  class="virtual-scrolling-grid material-custom segments"
>
  <div class="row" *cdkVirtualFor="let row of folderRows">
    <div class="segment" *ngFor="let folder of row" (click)="clickSegment.emit(folder)">
      <div class="segment__body" #segmentBody>
        <img *ngIf="customIconPath; else icon" [src]="customIconPath" alt="Folder icon" />
        <ng-template #icon>
          <mat-icon class="icon">folder_open</mat-icon>
        </ng-template>
        <div
          class="segment__content"
          [matTooltip]="
            folder.title !== undefined && !['null', '-1', 0].includes(folder.title.toString().toLowerCase())
              ? folder.title
              : '[ empty ]'
          "
        >
          <span
            class="title"
            [class.title--empty]="
              folder.title === undefined || ['null', '-1'].includes(folder.title.toString().toLowerCase())
            "
            >{{
              folder.title !== undefined && !['null', '-1', 0].includes(folder.title.toString().toLowerCase())
                ? (folder.title.toString() | truncate : (segmentBody.offsetWidth - 50) * 0.1)
                : '[ empty ]'
            }}</span
          >
          <span class="subtitle" *ngIf="folder.subtitle">{{ folder.subtitle }}</span>
        </div>
        <vdms-hq-ui-floating-controls-v2
          class="segment__body__controls"
          *ngIf="actions?.length"
          [item]="folder"
          [actions]="actions ?? []"
          (action)="emitAction($event.key, folder)"
        ></vdms-hq-ui-floating-controls-v2>
      </div>
    </div>
  </div>
</cdk-virtual-scroll-viewport>
<vdms-hq-ui-loader mode="over-parent" [backdrop]="true" [centerPadding]="false" *ngIf="loading"></vdms-hq-ui-loader>
