import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { isArray } from 'lodash';
import { MatRippleModule } from '@angular/material/core';
import { RendererValue, ValueDisplayFormatConfig } from '@vdms-hq/shared';
import { ValueModifierPipePipe } from '../../pipes/value-modifier.pipe';
import { ValuePillApplierPipe } from '../../pipes/value-pill-applier.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';

export type InputValue = string | undefined | Date | null | string[] | Date[];

@Component({
  selector: 'vdms-hq-ui-value-renderer',
  imports: [CommonModule, MatRippleModule, ValueModifierPipePipe, ValuePillApplierPipe, MatTooltipModule],
  templateUrl: 'value-renderer.component.html',
  styleUrls: ['./value-renderer.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValueRendererComponent {
  readonly defaultVisibleValues = 2;
  unfolded = false;

  @Input() set value(value: InputValue) {
    if (isArray(value)) {
      this.arrayValue = value;
    } else {
      this.arrayValue = [value];
    }
  }

  @Input() format?: ValueDisplayFormatConfig;
  @Input() fallback = 'N/A';

  @Input() set forcedUnfolded(value: boolean) {
    this.unfolded = value;
  }

  arrayValue: RendererValue[] = [];

  get sliceTo(): number {
    if (this.unfolded) {
      return this.arrayValue.length;
    }

    return this.format?.maxVisibleValues ?? this.defaultVisibleValues;
  }

  makeString(value: RendererValue): string {
    return String(value);
  }
}
