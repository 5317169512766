import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  template: '',
})
/**
 * @description: extend this component to quickly unsubscribe from Observable on component destruction with takeUntilDestroyed() custom operator
 */
export class DestroyComponent implements OnDestroy {
  private destroyed$!: Subject<void>;

  protected takeUntilDestroyed = <T>() => {
    if (!this.destroyed$) this.destroyed$ = new Subject<void>();
    return takeUntil<T>(this.destroyed$);
  };

  ngOnDestroy(): void {
    this.destroyed$?.next();
    this.destroyed$?.complete();
  }
}
