<div class="lists columns-{{ columns }}" cdkDropListGroup [class.with-touched-indicator]="withTouchedIndicator">
  <mat-label class="material-custom-field-label" *ngIf="label">
    {{ label }}
    <span class="required-indicator" *ngIf="isRequired">*</span>
  </mat-label>
  <div
    class="column"
    cdkDropList
    (cdkDropListDropped)="drop($event, listIndex)"
    *ngFor="let list of listsArray; let listIndex = index"
  >
    <div *ngFor="let item of lists[listIndex]; trackBy: trackBy" cdkDrag [cdkDragData]="item">
      <vdms-hq-ui-list-item-placeholder *cdkDragPlaceholder></vdms-hq-ui-list-item-placeholder>
      <vdms-hq-ui-list-item [matTooltip]="item.label">
        <mat-checkbox
          prefix
          [checked]="value.includes(item.key) || alwaysEnabled.includes(item.key)"
          [disabled]="alwaysEnabled.includes(item.key)"
          (change)="change($event, item.key)"
        ></mat-checkbox>
        {{ item.label }}
      </vdms-hq-ui-list-item>
    </div>
  </div>
</div>
