import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { ApiResponse } from '../api.model';
import { Observable } from 'rxjs';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { Rating, RatingPost } from './ratings.model';
import { RatingEndpoints } from './endpoints';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RatingsService {
  constructor(private readonly apiService: ApiService) {}

  public getRatings(): Observable<Rating[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Cache-Control', 'max-age=0');
    return this.apiService.get<ApiResponse<Rating[]>>(RatingEndpoints.ROOT_PLURAL, { headers }).pipe(GetResponseData);
  }

  public getRating(id: Pick<Rating, 'uuid'>): Observable<Rating> {
    return this.apiService.get<ApiResponse<Rating>>(`${RatingEndpoints.ROOT}/${id}`).pipe(GetResponseData);
  }

  public postRating(data: RatingPost): Observable<Rating> {
    return this.apiService
      .post<{ name: string }, ApiResponse<Rating>>(`${RatingEndpoints.ROOT}`, data)
      .pipe(GetResponseData);
  }

  public patchRating(id: string, data: { name: string }): Observable<Rating> {
    return this.apiService
      .patch<{ name: string }, ApiResponse<Rating>>(`${RatingEndpoints.ROOT}/${id}`, data)
      .pipe(GetResponseData);
  }

  public deleteRating(id: string): Observable<void> {
    return this.apiService.delete(`${RatingEndpoints.ROOT}/${id}`);
  }
}
