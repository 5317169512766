import { HttpParams } from '@angular/common/http';
import { NOTIFICATIONS_CATEGORIES } from './notification-subscriptions.model';

export const generateNotificationParams = (params: {
  read?: boolean;
  types?: string;
  categories?: NOTIFICATIONS_CATEGORIES;
}): HttpParams => {
  return Object.entries(params).reduce(
    (previous, [key, value]) =>
      value === undefined || value === null
        ? previous
        : previous.set(key, typeof value === 'boolean' ? (value ? 'true' : false) : String(value)),
    new HttpParams(),
  );
};
