import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectorSourceType } from '../../logic/selector-source.type';
import { DataProviderService } from '../../logic/data-provider.service';
import { Observable, of } from 'rxjs';
import { SelectOptionGroup } from '@vdms-hq/shared';
import { FormControlValueAccessorComponent } from '@vdms-hq/ui';

type OuterValue = string[] | string | null | undefined;
type InnerValue = string[] | string | null;

@Component({
  selector: 'vdms-hq-grouped-selector',
  templateUrl: './grouped-selector.component.html',
  styleUrls: ['./grouped-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => GroupedSelectorComponent),
    },
  ],
})
export class GroupedSelectorComponent
  extends FormControlValueAccessorComponent<OuterValue, InnerValue>
  implements OnInit, AfterViewInit
{
  @Input() sourceType: SelectorSourceType = '';

  loading$: Observable<boolean> = of(true);
  list$: Observable<SelectOptionGroup[]> = of([]);
  innerFormControl = new FormControl([]);

  constructor(private provider: DataProviderService, injector: Injector, changeDetectorRef: ChangeDetectorRef) {
    super(injector, changeDetectorRef);
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.provider) {
      return;
    }

    this.loading$ = this.provider.isGroupLoading(this.sourceType);
    this.list$ = this.provider.listForGroupSelectors(this.sourceType);
  }

  transformOuterToInner(value: OuterValue): InnerValue {
    return value ?? [];
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
}
