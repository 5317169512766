import { ConfirmationInput } from '../confirmation-dialog/confirmation-dialog.interface';

export const LEAVING_CART_DIALOG_CONFIG: ConfirmationInput = {
  title: 'pages.cart.checkout.leaving_warning.title',
  message: 'pages.cart.checkout.leaving_warning.message',
  abortAction: {
    label: 'No',
    color: 'secondary',
  },
  okAction: {
    label: 'Yes',
    color: 'primary',
  },
};
