export const percentageToValue = (percentage: number, min: number, max: number, hundredPercent = 100) => {
  const diff = max - min;

  const calculatedValues = percentage ? (percentage / hundredPercent) * diff : 0;
  const calculatedValuesShift = min + calculatedValues;

  return between(calculatedValuesShift, min, max);
};

export const valueToPercentage = (value: number, min: number, max: number, hundredPercent = 100) => {
  const diff = value - min;

  return between(Math.round((diff / max) * hundredPercent), 0, hundredPercent);
};

export const between = (value: number, min: number, max: number) => {
  return Math.max(min, Math.min(value, max));
};
