import { DeepPartial, GroupCurrencyISO } from '@vdms-hq/shared';
import { Framerate, Timecode } from '@vdms-hq/timecode';
import { AttachmentStatusEnum } from '../../attachment/attachment.model';
import { DISCOUNT_STATUS } from '../../cart/models/cart.model';
import { ClientRef } from '../../client/client.model';
import { Workflow } from '../../media-insights/media-insights.model';
import { AwsV4Signature } from '../../upload/upload';
import { AssetType } from './asset-type.model';
import { AssetSearchFilters } from './search/filters-v2.model';

export type AssetProcessingStatus =
  | 'PLACEHOLDER'
  | 'CREATED'
  | 'ANALYSE'
  | 'CREATING_PROXY'
  | 'CREATING_STREAM'
  | 'DONE'
  | 'ERROR'
  | 'AWAITING_ANALYSE';

export enum ChangeLogDirection {
  HOT_TO_COLD = 'hotToCold',
  COLD_TO_HOT = 'coldToHot',
}

interface AssetHybrikFileMetadata extends AssetFileMetadata {
  source: 'hybrik';
}

interface AssetMediainfoFileMetadata extends AssetFileMetadata {
  source: 'mediainfo';
}

export interface AssetFileMetadata {
  player_offset: string;
  filesize: number;
  duration_sec: number;
  duration: string;

  video?: {
    duration_sec: number;
    duration: string;
    codec: string;
    profile: string;
    screen_size: {
      encoded_pixels: string;
      width: number;
      height: number;
      clean_aperture: string;
      clean_aperture_width: number | 'unknown';
      clean_aperture_height: number | 'unknown';
    };
  };

  bitrate: number;
  bitrate_mode: string;

  framerate: string;
  bit_depth: number | 'unknown';
  interlace_mode: string;
  pixel_aspect_ratio: number | 'unknown';
  //hybrik_aspect_ratio was of type number, check if it's correct
  aspect_ratio?: string;
  format: string;
  resolution: string;
  dropped_frames: boolean;
  frame_rate_mode: string;

  chroma_format: string;
  color_space: string;
  color_primaries: string;
  color_matrix_coefficients: string;
  color_transfer_characteristics: string;

  vida_resolution: string;
  video_codec_mapping: string;
}

export interface Asset {
  general: General;
  core: Core;
  logginginfo: LoggingInfo[];
  user_media_data: UserMediaData;
  audio: AudioTrack[];
  audioTracks: AudioTrack[];
  additional: Additional;
  ext_title_info: ExtTitleInfo;
  ext_asset_info: ExtAssetInfo;
  mediaset: Mediaset;
  attachments: Attachment[];
  vimeo?: VimeoUrl;
  transactions: TransactionDetailsType[];
  readonly file_metadata: AssetHybrikFileMetadata | AssetMediainfoFileMetadata;
}

export interface HotColdChangeLogData {
  id: number;
  message: string;
  created_at: Date;
  storageChangeDirection: ChangeLogDirection;
}

export interface AssetPatch extends DeepPartial<Asset> {
  general?: DeepPartial<General> & {
    thumbnail_timecode?: string;
  };
}

export enum AssetTransactionType {
  INGEST = 'ingest',
  PROXY_TRANSCODE = 'proxyTranscode',
  HLS_TRANSCODE = 'hlsTranscode',
  DOWNLOAD_PROXY = 'downloadProxy',
  TRANSCRIBE = 'transcribe',
  AI_SYNOPSIS = 'aiSynopsis',
  DOWNLOAD_SUBTITLES = 'downloadSubtitles',
  MASTER_TRANSCODE = 'masterTranscode',
  SEND_TO_YOUTUBE = 'sendToYouTube',
  SEND_TO_VIMEO = 'sendToVimeo',
  PURGE = 'purge',
  OTHER = 'other',
}

export interface GetMinifiedUserDetailsType {
  email: string;
  name: string;
  uuid: string;
}

export interface TransactionDetailsType {
  id: number;
  type: AssetTransactionType;
  ai_model_name?: string;
  order_id: number;
  created_at: Date;
  created_by: GetMinifiedUserDetailsType;
}

export interface GeneralMediainfo {
  id: number;
  width: number;
  height: number;
  resolution: string;
  timecodeStart?: string;
  timecodeStop?: string;
  duration: string;
  videoCodec: string;
  videoBitrate: number;
  framerate: string;
  aspectRatio: string;
  size: string;
  format: string;
  extension: string;
  totalTracks: number;
  audioTracks: AudioTrack[];
  droppedFrames: boolean;
}

export interface Attachment {
  uuid: string;
  upload_access: AwsV4Signature;
  status: AttachmentStatusEnum;
  file: string;
  type?: string;
  file_type: string;
  download_link?: string;
  view_link?: string;
}

export interface Thumbnail {
  contentType: string;
  details: {
    width?: number;
    height?: number;
    interval?: number;
  };
  type: 'external' | 'waveform-json' | 'main' | 'big' | 'gif' | 'sprite';
  url: string;
}

interface General {
  is_quarantined: boolean;
  embargoed_to: string;
  uuid: string;
  batch_id: string;
  s_object_id: string;
  s_object_id_bk: string;
  master_no: string;
  media_key: string;
  external_key: string;
  uploaded_at: Date;
  original_filename: string;
  thumbnail_processing: boolean;
  is_proxy_only: boolean;
  is_virtual: boolean;
  is_uploaded: boolean;
  parent?: any;
  timecode_in?: string;
  timecode_out?: string;
  thumbnails: Thumbnail[];
  waveform_url: string;
  gif_thumbnail_url: string;
  external_thumbnail: string;
  download_url: string;
  download_proxy_url: string;
  file_ext: string;
  file_url: string;
  subtitles: Subtitles[];
  /** @deprecated, use file_metadata or user_file_metadata instead */
  mediainfo?: GeneralMediainfo;
  groups: ClientRef[];
  collections: string[];
  collections_details: AssetCollection[];
  stage: number;
  approval_status: number;
  type: AssetType;
  tags: string[];
  logs: string[];
  catalog_item_uuid: string;
  catalog_item_parent_uuid: string;
  client_asset_id: string;
  workflow?: Workflow;
  processingStatus: AssetProcessingStatus;
  preview_url?: string;
}

export type AssetCollection = {
  uuid: string;
  item_uuid: string;
  name: string;
  description: string;
  custom_cover: boolean;
  custom_cover_path: string;
};

interface Core {
  episode_name: string;
  episode_number: number;
  series_name: string;
  series_number: number;
  client_ref: string;
  programme_number: string;
  facility_of_origin: string;
  original_filename: string;
}

interface UserMediaData {
  player_offset: string;
  language: string;
  aspect_ratio: string;
  // active-asset.service.ts: check if correct
  // hybrik_aspect_ratio: number;
  image_aspect: string;
  framing: string;
  elements: string;
  bars: boolean;
  clock_slate: boolean;
  seamless_segmented: string;
  retention: string;
  duration: string;
  size: string;
}

export interface AudioTrack {
  track: number;
  number: number;
  layout: string;
  channels?: string[];
  language: string;
  audio_description: string;
  loudness?: string;
  loudness_standard?: string;
  class?: string;
  codec: string;
  sample_rate?: string;
  bit_depth?: string;
  qc_status?: string;
}

interface Additional {
  description: string;
  synopsis: string;
  synopsis_short: string;
  genre: string;
  note: string;
  tape_no: string;
  alt_no: string;
  captions: boolean;
  subtitle: string;
  part_no: string;
  part_total: string;
  production_no: string;
  production_company?: string;
  shooting_start_date: string;
  shooting_end_date: string;
  star_rating: string;
  theme: string;
  variation: string;
  category: string;
  content_class: string;
  content_type?: string;
  /** @deprecated, use file_metadata.framerate instead */
  framerate: string;
  version: string;
  type: string;
  subtype: string;
  is_preview_app_enabled: boolean;
  vida_number: string;
  multiversion: string[];
}

interface ExtTitleInfo {
  season_title: string;
  original_title: string;
  other_title: string;
  programme_subtitle: string;
  eidr_number: string;
  release_year: string;
  tx_date: string;
  library_sales_rights: boolean;
}

interface ExtAssetInfo {
  order_number: string;
  barcode_field: string;
  dynamic_range: string;
  inventory_type: string;
  sequence_no: string;
  artist: string;
  origin: string;
}

interface Mediaset {
  physical_version_title: string;
  physical_version_description: string;
  parent_media_id: string;
  relationship: string;
}

interface LoggingInfo {
  uuid: string;
  duration?: string;
  logging_type: string;
  note: string;
  timecode_in: string;
  timecode_out: string;
  offset: string;
}

interface Subtitles {
  language: string;
  path: string;
}

export interface SubtitleResponse {
  subs: Subtitles[];
}

export interface VimeoUrl {
  link: string;
  uri: string;
}

export interface CreateAssetVirtualParams {
  timecodeIn: string;
  timecodeOut: string;
}

export interface AssetLink {
  url: string;
}

export interface CreateAssetVirtualParams {
  timecodeIn: string;
  timecodeOut: string;
}

export interface AssetLink {
  url: string;
  filename: string;
}

/**
 * todo invalid place for below (view model)
 */
export interface CreateAssetVirtualDialogData {
  id: string;
  asset: CreateAssetVirtualParams;
  framerate: Framerate;
  boundaries: Timecode[];
}

export interface DownloadProxyExtra {
  extra: {
    contact_uuid: string;
    contact_name: string;
    opportunity_uuid: string;
    opportunity_name: string;
  };
}

export interface SetAsColdOnlyType {
  assets: string[];
}

export interface HotColdEstimateSearch {
  filters: AssetSearchFilters;
  discount_code?: string;
}
export interface HotColdEstimateSimple {
  assets: string[];
  discount_code?: string;
}

export interface HotColdEstimateResponse {
  total_price: string;
  total_bytes: number;
  total_assets: number;
  price_per_gb: number;
  currency: GroupCurrencyISO;
  discount_status: DISCOUNT_STATUS;
  discount_prices?: {
    price_per_gb: number;
    total_price: string;
  };
}

export type AssetMapFilters = {
  polyenc: string;
};
