import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UIButtonModule,
  UIFormModule,
  UILayoutModule,
  UILoaderModule,
} from '@vdms-hq/ui';
import { startWith, switchMap, take, tap, combineLatest, takeUntil } from 'rxjs';
import { ReindexAutomatizationDatasource } from '../../logic/reindex-automatization-ds.service';
import { ActivatedClientModule } from '@vdms-hq/activated-client';
import { MatDialog } from '@angular/material/dialog';
import { UpdateReindexDialogComponent } from '../../components/update-reindex-dialog/update-reindex-dialog.component';
import { ReindexAutomatizationModel } from '@vdms-hq/api-contract';
import { FormControl } from '@angular/forms';
import { DestroyComponent } from '@vdms-hq/shared';

@Component({
  selector: 'vdms-hq-reindex-automatization',
  templateUrl: './reindex-automatization.component.html',
  styleUrls: ['./reindex-automatization.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    UILoaderModule,
    UILayoutModule,
    MultipleDataPresentationComponent,
    ActivatedClientModule,
    UIButtonModule,
    UIFormModule,
  ],
  providers: [ReindexAutomatizationDatasource],
})
export class ReindexAutomatizationComponent extends DestroyComponent implements OnDestroy, OnInit {
  #dialog = inject(MatDialog);
  dataSource = inject(ReindexAutomatizationDatasource);
  sortByControl = new FormControl<'ALL' | 'PROGRESS' | 'COMPLETED'>('ALL');
  sortByOptions = [
    { key: 'ALL', label: 'All' },
    { key: 'PROGRESS', label: 'Progress' },
    { key: 'COMPLETED', label: 'Completed' },
  ];

  sortDirControl = new FormControl<string>('label:ASC');
  sortDirOptions = [
    { key: 'label:ASC', label: 'Label - ASC' },
    { key: 'label:DESC', label: 'Label - DESC' },
    { key: 'total_assets:ASC', label: 'Total Assets - ASC' },
    { key: 'total_assets:DESC', label: 'Total Assets - DESC' },
    { key: 'index_size:ASC', label: 'Index Size - ASC' },
    { key: 'index_size:DESC', label: 'Index Size - DESC' },
    { key: 'requested_at:ASC', label: 'Requested At - ASC' },
    { key: 'requested_at:DESC', label: 'Requested At - DESC' },
  ];

  configuration: MultipleViewConfiguration<ReindexAutomatizationModel> = {
    multiView: {
      defaultView: 'tableAdvanced',
      fitToVisibleArea: false,
      emptyResults: {
        message: 'Could not find any entities',
      },
    },
    tableAdvanced: {
      layout: {
        headers: true,
      },
      columns: [
        {
          id: 'select',
          type: 'select',
        },
        {
          id: 'label',
          label: 'Label',
          valuePath: 'props.label',
        },
        {
          id: 'status',
          label: 'Status',
          valuePath: 'status',
          viewFormat: {
            pills: {
              default: 'orange-light',
              error: 'failed',
              regular: 'cold-blue',
              completed: 'done',
              'n/a': 'white-dashed',
            },
          },
        },
        {
          id: 'total_assets',
          label: 'Total Assets',
          valuePath: 'props.total_assets',
        },
        {
          id: 'index_size',
          label: 'Index Size',
          valuePath: 'props.index_size',
          viewFormat: {
            modifiers: {
              fileSizeFormat: true,
            },
          },
        },
        {
          id: 'last_reindex_name',
          label: 'Last Reindex Name',
          valuePath: 'props.index_name',
        },
        {
          id: 'last_reindex_date',
          label: 'Last Reindex Date',
          valuePath: 'props.done_at',
          viewFormat: {
            modifiers: {
              dateFormat: 'date-time',
            },
          },
        },
        {
          id: 'requested_at',
          label: 'Requested At',
          valuePath: 'props.requested_at',
          viewFormat: {
            modifiers: {
              dateFormat: 'date-time',
            },
          },
        },
        {
          id: 'requested_by',
          label: 'Requested By',
          valuePath: 'props.requested_by',
        },
      ],
      columnsEnabled: [
        'select',
        'label',
        'status',
        'total_assets',
        'index_size',
        'last_reindex_name',
        'last_reindex_date',
        'requested_at',
        'requested_by',
      ],
    },
  };

  ngOnInit() {
    this.#listenFiltersChanges();
  }

  override ngOnDestroy() {
    this.dataSource.destroy();
    super.ngOnDestroy();
  }

  reindex(sync: boolean) {
    this.dataSource.selection.entities$
      .pipe(
        take(1),
        tap(() => this.dataSource.pause$.next(true)),
        switchMap((entities) =>
          this.#dialog
            .open(UpdateReindexDialogComponent, {
              data: {
                sync,
                entities,
              },
            })
            .afterClosed(),
        ),
        tap(() => this.dataSource.pause$.next(false)),
      )
      .subscribe();
  }

  #listenFiltersChanges() {
    combineLatest([
      this.sortDirControl.valueChanges.pipe(startWith('label:ASC')),
      this.sortByControl.valueChanges.pipe(startWith('ALL')),
    ])
      .pipe(this.takeUntilDestroyed())
      .subscribe(([sortDir, sortBy]) => {
        this.dataSource.sortDir$.next(sortDir as string);
        this.dataSource.sortBy$.next(sortBy as string);
        this.dataSource.refresh(new Date().toString());
      });
  }
}
