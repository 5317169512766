import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { TransactionDetailsType } from '@vdms-hq/api-contract';
import { LocalDataSource } from '@vdms-hq/shared';

@Injectable()
export class TransactionsDataSource extends LocalDataSource<TransactionDetailsType> {
  emptyResults$ = this.total$.pipe(map((total) => total === 0));
  allData$!: BehaviorSubject<TransactionDetailsType[]>;

  constructor() {
    super(new BehaviorSubject<TransactionDetailsType[]>([]));
  }

  addTransactions(transactions: TransactionDetailsType[]) {
    this.allData$.next(transactions);
  }
}
