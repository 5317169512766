import {
  WebsocketCartNotificationMessage,
  WebsocketCollectionNotificationMessage,
  WebsocketStorageNotificationMessage,
} from '../models/websocket.model';

export const generateProgressToastMessage = (
  messageType: 'retry_dd_jobs' | 'collections' | 'cart' | 'licensed-package' | 'subscriptions' | 'move_to_storage',
  percent: number,
  imgSrc?: string,
  counter?: {
    processing: number;
    all: number;
    errors: WebsocketCollectionNotificationMessage &
      WebsocketCartNotificationMessage &
      WebsocketStorageNotificationMessage;
  },
  finished?: boolean,
) => {
  const message = messagePrefixes(messageType);
  return `
        ${message} ${finished ? 'finished' : percent + ' %'}  ${
    counter ? `(${counter?.processing}/${counter?.all} processed)` : ''
  }
        ${
          (counter?.errors?.already_in_collection ?? 0) > 0 ||
          (counter?.errors?.already_in_cart ?? 0) > 0 ||
          (counter?.errors?.has_embargo ?? 0) > 0 ||
          (counter?.errors?.error ?? 0) > 0 ||
          (counter?.errors?.asset_not_found ?? 0) > 0 ||
          (counter?.errors?.asset_in_active_order ?? 0) > 0 ||
          (counter?.errors?.is_virtual ?? 0) > 0 ||
          (counter?.errors?.is_not_uploaded ?? 0) > 0 ||
          (counter?.errors?.is_quarantined ?? 0) > 0 ||
          (counter?.errors?.dont_have_proxy_video_error ?? 0) > 0 ||
          (counter?.errors?.dont_have_stream_audio ?? 0) > 0 ||
          (counter?.errors?.dont_have_stream_audio_cold_to_hot ?? 0) > 0 ||
          (counter?.errors?.dont_have_proxy_video_cold_to_hot ?? 0) > 0 ||
          (counter?.errors?.dont_have_md5 ?? 0) > 0 ||
          (counter?.errors?.dont_have_waveform ?? 0) > 0 ||
          (counter?.errors?.already_in_cold ?? 0) > 0 ||
          (counter?.errors?.missing_in_aws ?? 0) > 0 ||
          (counter?.errors?.missing_in_wasabi ?? 0) > 0 ||
          (counter?.errors?.file_size_mismatch ?? 0) > 0 ||
          (counter?.errors?.already_in_hot ?? 0) > 0 ||
          (counter?.errors?.already_manipulating_to_hot ?? 0) > 0
            ? '<hr>'
            : ''
        }
        ${
          (counter?.errors?.asset_not_found ?? 0) > 0
            ? `<div class="toast--row-error__message">
          <img src="${imgSrc}" alt="loading"> ${counter?.errors?.asset_not_found}/${counter?.all} Asset not found
        </div>`
            : ''
        }
        ${
          (counter?.errors?.asset_in_active_order ?? 0) > 0
            ? `<div class="toast--row-error__message">
          <img src="${imgSrc}" alt="loading"> ${counter?.errors?.asset_in_active_order}/${counter?.all} Asset in active order
        </div>`
            : ''
        }
  ${
    (counter?.errors?.is_virtual ?? 0) > 0
      ? `<div class="toast--row-error__message">
          <img src="${imgSrc}" alt="loading"> ${counter?.errors?.is_virtual}/${counter?.all} Asset is virtual
        </div>`
      : ''
  }
  ${
    (counter?.errors?.is_not_uploaded ?? 0) > 0
      ? `<div class="toast--row-error__message">
          <img src="${imgSrc}" alt="loading"> ${counter?.errors?.is_not_uploaded}/${counter?.all} Asset is not uploaded
        </div>`
      : ''
  }
  ${
    (counter?.errors?.is_quarantined ?? 0) > 0
      ? `<div class="toast--row-error__message">
          <img src="${imgSrc}" alt="loading"> ${counter?.errors?.is_quarantined}/${counter?.all} Asset is quarantined
        </div>`
      : ''
  }
  ${
    (counter?.errors?.dont_have_proxy_video_error ?? 0) > 0
      ? `<div class="toast--row-error__message">
          <img src="${imgSrc}" alt="loading"> ${counter?.errors?.dont_have_proxy_video_error}/${counter?.all} Asset doesn't have proxy video
        </div>`
      : ''
  }
  ${
    (counter?.errors?.dont_have_stream_audio ?? 0) > 0
      ? `<div class="toast--row-error__message">
          <img src="${imgSrc}" alt="loading"> ${counter?.errors?.dont_have_stream_audio}/${counter?.all} Asset doesn't have stream audio
        </div>`
      : ''
  }
  ${
    (counter?.errors?.dont_have_stream_audio_cold_to_hot ?? 0) > 0
      ? `<div class="toast--row-error__message">
          <img src="${imgSrc}" alt="loading"> ${counter?.errors?.dont_have_stream_audio_cold_to_hot}/${counter?.all} Asset doesn't have stream audio cold to hot
        </div>`
      : ''
  }
  ${
    (counter?.errors?.dont_have_proxy_video_cold_to_hot ?? 0) > 0
      ? `<div class="toast--row-error__message">
          <img src="${imgSrc}" alt="loading"> ${counter?.errors?.dont_have_proxy_video_cold_to_hot}/${counter?.all} Asset doesn't have proxy video cold to hot
        </div>`
      : ''
  }
  ${
    (counter?.errors?.dont_have_md5 ?? 0) > 0
      ? `<div class="toast--row-error__message">
          <img src="${imgSrc}" alt="loading"> ${counter?.errors?.dont_have_md5}/${counter?.all} Asset doesn't have md5
        </div>`
      : ''
  }
  ${
    (counter?.errors?.dont_have_waveform ?? 0) > 0
      ? `<div class="toast--row-error__message">
          <img src="${imgSrc}" alt="loading"> ${counter?.errors?.dont_have_waveform}/${counter?.all} Asset doesn't have waveform
        </div>`
      : ''
  }
  ${
    (counter?.errors?.already_in_cold ?? 0) > 0
      ? `<div class="toast--row-error__message">
          <img src="${imgSrc}" alt="loading"> ${counter?.errors?.already_in_cold}/${counter?.all} Asset already in cold
        </div>`
      : ''
  }
  ${
    (counter?.errors?.missing_in_aws ?? 0) > 0
      ? `<div class="toast--row-error__message">
          <img src="${imgSrc}" alt="loading"> ${counter?.errors?.missing_in_aws}/${counter?.all} Asset missing in AWS
        </div>`
      : ''
  }
  ${
    (counter?.errors?.missing_in_wasabi ?? 0) > 0
      ? `<div class="toast--row-error__message">
          <img src="${imgSrc}" alt="loading"> ${counter?.errors?.missing_in_wasabi}/${counter?.all} Asset missing in WASABI
        </div>`
      : ''
  }
  ${
    (counter?.errors?.file_size_mismatch ?? 0) > 0
      ? `<div class="toast--row-error__message">
          <img src="${imgSrc}" alt="loading"> ${counter?.errors?.file_size_mismatch}/${counter?.all} Asset file size mismatch
        </div>`
      : ''
  }
  ${
    (counter?.errors?.already_in_hot ?? 0) > 0
      ? `<div class="toast--row-error__message">
          <img src="${imgSrc}" alt="loading"> ${counter?.errors?.already_in_hot}/${counter?.all} Asset already in hot
        </div>`
      : ''
  }
  ${
    (counter?.errors?.already_manipulating_to_hot ?? 0) > 0
      ? `<div class="toast--row-error__message">
          <img src="${imgSrc}" alt="loading"> ${counter?.errors?.already_manipulating_to_hot}/${counter?.all} Asset already manipulating to hot
        </div>`
      : ''
  }
        ${
          (counter?.errors?.already_in_collection ?? 0) > 0
            ? `<div class="toast--row-error__message">
          <img src="${imgSrc}" alt="loading"> ${counter?.errors?.already_in_collection}/${counter?.all} Already in collection
        </div>`
            : ''
        }
        ${
          (counter?.errors?.already_in_cart ?? 0) > 0
            ? `<div class="toast--row-error__message">
          <img src="${imgSrc}" alt="loading"> ${counter?.errors?.already_in_cart}/${counter?.all} Already in cart
        </div>`
            : ''
        }
        ${
          (counter?.errors?.has_embargo ?? 0) > 0
            ? `<div class="toast--row-error__message">
          <img src="${imgSrc}" alt="loading"> ${counter?.errors?.has_embargo}/${counter?.all} Has embargo
        </div>`
            : ''
        }
        ${
          (counter?.errors?.error ?? 0) > 0
            ? `
          <div class = "toast--row-error__message" >
          <img src="${imgSrc}" alt="loading"> ${counter?.errors?.error} /${counter?.all} Error
        </div>`
            : ''
        }
        `;
};

const messagePrefixes = (
  messageType: 'retry_dd_jobs' | 'collections' | 'cart' | 'licensed-package' | 'subscriptions' | 'move_to_storage',
): string => {
  switch (messageType) {
    case 'retry_dd_jobs':
      return 'Retrying delivery destinations';
    case 'collections':
      return 'Collection updating';
    case 'cart':
      return 'Cart updating';
    case 'licensed-package':
      return 'Licensed package updating';
    case 'subscriptions':
      return 'Notification subscriptions updating';
    case 'move_to_storage':
      return 'Moving to storage';
    default:
      return '';
  }
};
