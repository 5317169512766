import { ReindexAutomatizationModel } from '@vdms-hq/api-contract';
import moment from 'moment';

export class ReindexAutomatizationViewModel {
  constructor(public props: Partial<ReindexAutomatizationModel>) {}

  static fromReindexItem(item: Partial<ReindexAutomatizationModel>) {
    return new ReindexAutomatizationViewModel(item);
  }

  get timeLeft() {
    if (!this.props.seconds_left) {
      return '';
    }

    return moment.utc(this.props.seconds_left * 1000).format('HH:mm');
  }

  get status() {
    const { done_at, total_todo, processed } = this.props;
    if (!done_at && total_todo === 0) {
      return 'requested';
    }

    if (!done_at && total_todo) {
      return `in-progress (${processed}/${total_todo}) ${this.timeLeft && ' - (' + this.timeLeft + ')'}`;
    }

    if (done_at && total_todo && total_todo !== processed) {
      return 'error';
    }

    if (processed === total_todo && done_at) {
      return 'completed';
    }

    return 'N/A';
  }
}
