import { Routes } from '@angular/router';
import { AsperaKeysPageComponent } from './pages/aspera-keys-page/aspera-keys-page.component';

export enum ASPERA_KEYS_ROUTES {
  ROOT = 'aspera-keys',
}

export const ASPERA_KEYS_ROUTING: Routes = [
  {
    path: '',
    component: AsperaKeysPageComponent,
  },
  {
    path: '**',
    redirectTo: `/${ASPERA_KEYS_ROUTES}`,
    pathMatch: 'full',
  },
];
