import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filterEmpty } from '@vdms-hq/shared';

@Injectable({ providedIn: 'root' })
export class SingleCollectionResolverService implements Resolve<string | null> {
  currentId$ = new BehaviorSubject<string | null | undefined>(null);
  currentIdDefinite$ = this.currentId$.pipe(filterEmpty());

  resolve(route: ActivatedRouteSnapshot) {
    const collectionId = route.paramMap.get('uuid') as string;

    if (!collectionId) {
      return null;
    }

    this.currentId$.next(collectionId);

    return collectionId;
  }
}
