import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'vdms-hq-ui-progress-status',
  templateUrl: './progress-status.component.html',
  styleUrls: ['./progress-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressStatusComponent {
  @Input() labelVisible = true;
  @Input() value = 0;
  @Input() label = 'Uploading';
  @Input() mode: 'indeterminate' | 'determinate' = 'determinate';
}
