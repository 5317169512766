import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isEmptyInputValue } from './email-array.validator';

const BUCKET_NAME_REGEX_PATTERN = new RegExp('^[a-z0-9][a-z0-9.-]{1,61}[a-z0-9]$');
const BUCKET_NAME_REGEX_PATTERN_2 = new RegExp('\\b(?:\\d{1,3}\\.){3}\\d{1,3}\\b');
const BUCKET_NAME_FIRST_LAST_LETTER_CANNOT_BE_SPECIAL = new RegExp(/^[\\/]|[\\/]$/);

const FORBIDDEN_PREFIXES = ['xn--', 'sthree-', 'sthree-configurator'];
const FORBIDDEN_SUFFIXES = ['-s3alias', '--ol-s3'];

const ALLOWED_PREFIX_CHARS = new RegExp("^[a-zA-Z0-9!\\-_.*'()\\/ ]{0,50}$");

export function bucketNameValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (isEmptyInputValue(control.value) || control.value == '') return null;

    switch (true) {
      case BUCKET_NAME_FIRST_LAST_LETTER_CANNOT_BE_SPECIAL.test(control.value):
        return { bucketNameSuffix: true };
      case control.value.includes('..'):
        return { bucketNameDoubleDot: true };
      case BUCKET_NAME_REGEX_PATTERN_2.test(control.value):
        return { bucketNameIp: true };
      case FORBIDDEN_PREFIXES.some((prefix) => control.value.startsWith(prefix)):
        return { bucketNamePrefix: true };
      case FORBIDDEN_SUFFIXES.some((suffix) => control.value.endsWith(suffix)):
        return { bucketNameSuffix: true };
      case !BUCKET_NAME_REGEX_PATTERN.test(control.value):
        return { bucketNameLength: true };
      default:
        return null;
    }
  };
}

export function prefixValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (isEmptyInputValue(control.value) || control.value == '') return null;

    switch (true) {
      case !ALLOWED_PREFIX_CHARS.test(control.value):
        return { prefixChars: true };
      case control.value.startsWith('/'):
      case control.value.endsWith('/'):
        return { prefixSlash: true };
      default:
        return null;
    }
  };
}
