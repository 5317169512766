<div class="batch">
  <div class="batch--head">
    <vdms-hq-ui-button class="close" (click)="closeElement.emit()" [iconOnly]="true" icon="close"></vdms-hq-ui-button>
    <span class="batch--title">
      {{
        (batch.files.length === 1 ? 'common.aspera.uploading' : 'common.aspera.uploading.plural')
          | translate : { amount: batch.files.length }
      }}
      <ng-container *ngIf="isProgressBar">
        {{ 'common.aspera.upload_progress' | translate : { percent: (batch.state$ | async)?.progress ?? 0 } }}
      </ng-container>
    </span>
    <vdms-hq-ui-progress-status
      *ngIf="isProgressBar"
      [value]="(batch.state$ | async)?.progress ?? 0"
      [mode]="(batch.state$ | async)?.progress === 0 ? 'indeterminate' : 'determinate'"
      [labelVisible]="false"
    ></vdms-hq-ui-progress-status>
    <vdms-hq-ui-button *ngIf="isProgressBar" (click)="deleteElement.emit()" color="transparent">{{
      'common.global.cancel' | translate
    }}</vdms-hq-ui-button>
  </div>

  <div class="rows">
    <div class="row" *ngFor="let file of batch.files | slice : 0 : VISIBLE_FILES">
      {{ file.filename }}
    </div>
    <div class="row" *ngIf="batch.files.length > 3">
      {{ 'common.aspera.and_more' | translate : { amount: batch.files.length - VISIBLE_FILES } }}
    </div>
  </div>
</div>
