import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';
import { Framerate, Timecode, TimecodeValidators } from '@vdms-hq/timecode';
import { defaultErrors } from '../../models/default-error-messages.model';
import { ButtonColors } from '../../../ui-button';

type OutsideValue = Timecode | null | undefined;
type OutsideValuePair = [OutsideValue, OutsideValue];
type InsideValue = Timecode | null;
type InsideValuePair = {
  pair: [InsideValue, InsideValue];
};

@Component({
  selector: 'vdms-hq-form-input-timecode-pair',
  templateUrl: './form-input-timecode-pair.component.html',
  styleUrls: ['./form-input-timecode-pair.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputTimecodePairComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormInputTimecodePairComponent),
    },
  ],
})
export class FormInputTimecodePairComponent
  extends FormControlValueAccessorComponent<OutsideValuePair, InsideValuePair>
  implements OnInit, AfterViewInit, OnChanges
{
  innerFormControl = new UntypedFormGroup({
    pair: new UntypedFormArray([new UntypedFormControl(null), new UntypedFormControl(null)]),
  });

  @Input() framerate: Framerate = Framerate.default();
  @Input() mask = '00:00:00:00';
  @Input() hideFramerate = false;
  @Input() tcOutButtonConfig?: { visible: boolean; label: string; color: ButtonColors };
  @Input() tcInButtonConfig?: { visible: boolean; label: string; color: ButtonColors };

  private timecodeErrors: ValidationErrors = {
    timecodeFirstIsMissing: 'common.validators.tc_first_is_missing',
    timecodeSecondIsMissing: 'common.validators.tc_second_is_missing',
    timecodeFirstIsGreaterThanTcSecond: 'common.validators.tc_first_is_greater_than_tc_second',
    timecodeIsOutOfBoundaries: 'common.validators.tc_is_out_of_boundaries',
  };

  @Input() labels = ['pages.edl.tc_in', 'pages.edl.tc_out'];
  @Input() min: Timecode | null = null;
  @Input() max: Timecode | null = null;

  @Output() emitMarkIn = new EventEmitter();
  @Output() emitMarkOut = new EventEmitter();

  override set customErrorMessages(value: Record<string, string>) {
    this.errorMessages = {
      ...defaultErrors,
      ...this.timecodeErrors,
      ...value,
    };
  }

  #pair = this.innerFormControl.get('pair') as UntypedFormArray;

  get tcFirst() {
    return this.#pair.get('0');
  }

  get tcSecond() {
    return this.#pair.get('1');
  }

  constructor(injector: Injector, changeDetectorRef: ChangeDetectorRef) {
    super(injector, changeDetectorRef);

    this.customErrorMessages = this.timecodeErrors;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.min.currentValue || changes.max.currentValue) {
      this.innerFormControl.clearValidators();
      this.innerFormControl.setValidators(TimecodeValidators.isRangeValid(this.min, this.max));
      this.innerFormControl.updateValueAndValidity();
    }
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.innerFormControl.setValidators(TimecodeValidators.isRangeValid(this.min, this.max));
  }

  override transformInnerToOuter(value: InsideValuePair): OutsideValuePair {
    const left = value.pair[0];
    const right = value.pair[1];

    return [left ? left : null, right ? right : null];
  }

  override transformOuterToInner(value: OutsideValuePair): InsideValuePair {
    if (!Array.isArray(value)) {
      value = [null, null];
    }

    return {
      pair: [value[0] ?? null, value[1] ?? null],
    };
  }
}
