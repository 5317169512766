import { Injectable } from '@angular/core';
import { FilterableDataSource, LoadableDataSource, RefreshService } from '@vdms-hq/shared';
import { BehaviorSubject, combineLatest, debounceTime, map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { DeliveryPackApiService } from '@vdms-hq/api-contract';

@Injectable()
export class MandatoryFieldsDatasource implements Partial<LoadableDataSource>, Partial<FilterableDataSource> {
  isLoading$ = new BehaviorSubject(false);
  filterValue$ = new BehaviorSubject('');
  refresh$ = this.refreshService.refresh$;
  allFields$ = this.refresh$.pipe(
    switchMap(() => this.deliveryPackApiService.getMandatoryFields()),
    shareReplay(),
  );

  allData$: Observable<unknown[]> = combineLatest([this.allFields$, this.filterValue$]).pipe(
    tap(() => this.isLoading$.next(true)),
    debounceTime(250),
    map(([data, value]) => {
      const all = Object.entries(data).map((el) => ({ contentType: el[0], fields: el[1] }));

      if (value) {
        return all.filter(
          (item) =>
            item.contentType.toLowerCase().includes(value.toLowerCase()) ||
            item.fields.join(',').toLowerCase().includes(value.toLowerCase()),
        );
      }

      return all;
    }),
    tap(() => this.isLoading$.next(false)),
  );
  connection$ = this.allData$;

  constructor(private refreshService: RefreshService, private deliveryPackApiService: DeliveryPackApiService) {}

  applyFilter(value: string) {
    this.filterValue$.next(value);
  }

  refresh() {
    this.refreshService.refresh();
  }
}
