import { HttpParams } from '@angular/common/http';

export class SearchParams {
  constructor(
    private filters: {
      [key: string]: string;
    },
  ) {}

  toQueryParams = () => {
    let params = new HttpParams();

    Object.entries(this.filters).forEach(([key, value]) => {
      params = params.append(key, value.toString());
    });

    return params;
  };

  toPayload = () => this.filters;
}
