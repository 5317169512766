import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UIButtonModule } from '../ui-button';
import { UIFormModule } from '../ui-form';
import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Timecode } from '@vdms-hq/timecode';

type InputType = string | number | Timecode;

@Component({
  standalone: true,
  selector: 'vdms-hq-input-multiple',
  templateUrl: './input-multiple.component.html',
  styleUrls: ['./input-multiple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputMultipleComponent),
    },
  ],
  imports: [CommonModule, UIFormModule, UIButtonModule],
})
export class InputMultipleComponent implements ControlValueAccessor, OnInit, OnDestroy {
  propagateTouch?: () => void;

  private destroyed$ = new Subject<void>();
  arrayGroup = this.fb.group({
    array: this.fb.array([new FormControl('')]),
  });
  @Input() label?: string;
  @Input() inputType: 'number' | 'timecode' = 'number';
  @Input() numberMin: number | null = null;
  @Input() numberMax: number | null = null;

  propagateChange: (_: any) => void = () => undefined;

  get controlsArray() {
    return this.arrayGroup.get('array') as FormArray;
  }

  ngOnInit(): void {
    this.arrayGroup.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((v) => {
      this.propagateChange(v);
    });
  }

  writeValue(obj: undefined | null | InputType[]): void {
    if (!obj || !Array.isArray(obj)) {
      return;
    }
    obj.forEach((v, i) => {
      this.controlsArray.removeAt(i);
      this.controlsArray.push(new FormControl(v));
    });
  }

  registerOnChange(fn: any): void {
    this.propagateChange = (value) => {
      value = value.array;
      return fn(value);
    };
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  constructor(private fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  addControl() {
    (this.arrayGroup.controls.array as FormArray).push(new FormControl(''));
    this.changeDetectorRef.markForCheck();
  }

  removeControl(index: number) {
    this.controlsArray.removeAt(index);
  }
}
