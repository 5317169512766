import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ERROR_HANDLING_CONFIG } from './error-handling.types';
import { ErrorHandlingInterceptor } from './error-handling.interceptor';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true,
    },
    {
      provide: ERROR_HANDLING_CONFIG,
      useValue: {
        prefix: '/error',
      },
    },
  ],
})
export class ErrorHandlingCoreModule {}
