<vdms-hq-ui-layout-section
  [margin]="false"
  [breadcrumbs]="withHeader ? [{ name: 'common.admin.components.player_metadata_list' }] : undefined"
>
  <div class="player-metadata-list-wrapper">
    <vdms-hq-dynamic-form-group-renderer
      [form]="metadataListForm.filters"
      [fieldsConfig]="filtersConfig"
      [hasLoader]="false"
    ></vdms-hq-dynamic-form-group-renderer>

    <vdms-hq-timecode-table
      [dataSource]="dataSource"
      [columns]="columns"
      [enabledColumns]="enabled"
      [enabledComponents]="config.enabledComponents"
    ></vdms-hq-timecode-table>
  </div>
</vdms-hq-ui-layout-section>
