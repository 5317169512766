export const defaultErrors = {
  emailExist: 'common.validators.emailExist',
  required: 'common.validators.required',
  email: 'common.validators.email',
  emailPattern: 'common.validators.emailPattern',
  hasOwnerUuid: 'common.validators.hasOwnerUuid',
  forbiddenValue: 'common.validators.forbiddenValue',
  forbiddenMail: 'common.validators.forbiddenMail',
  forbiddenOwnMail: 'common.validators.forbiddenOwnMail',
  alreadyUsedMail: 'common.validators.alreadyUsedMail',
  minlength: 'common.validators.minlength',
  maxlength: 'common.validators.maxlength',
  bucketNameDoubleDot: 'common.validators.bucketNameDoubleDot',
  bucketNameLength: 'common.validators.bucketNameLength',
  bucketNameIp: 'common.validators.bucketNameIp',
  bucketNamePrefix: 'common.validators.bucketNamePrefix',
  bucketNameSuffix: 'common.validators.bucketNameSuffix',
  prefixChars: 'common.validators.prefixChars',
  prefixSlash: 'common.validators.prefixSlash',
};
