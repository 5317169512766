import { Injectable } from '@angular/core';
import { FilterableDataSource, LoadableDataSource, RefreshService } from '@vdms-hq/shared';
import { BehaviorSubject, combineLatest, debounceTime, map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { CredentialInterface, CredentialsApiService, CredentialTypeEnum } from '@vdms-hq/api-contract';

@Injectable({ providedIn: 'root' })
export class CredentialsDatasourceService implements Partial<LoadableDataSource>, Partial<FilterableDataSource> {
  isLoading$ = new BehaviorSubject(true);
  filterValue$ = new BehaviorSubject('');
  refresh$ = this.refreshService.refresh$;
  allCredentials$ = this.refresh$.pipe(
    switchMap(() => this.credentialsApiService.getAll()),
    shareReplay(),
  );
  allData$: Observable<CredentialInterface<CredentialTypeEnum>[]> = combineLatest([
    this.allCredentials$,
    this.filterValue$,
  ]).pipe(
    tap(() => this.isLoading$.next(true)),
    debounceTime(250),
    map(([data, value]) => {
      if (value) {
        return data.filter(({ name, uuid, type }) => {
          return (
            name.toLowerCase().includes(value.toLowerCase()) ||
            type.toLowerCase().includes(value.toLowerCase()) ||
            uuid.toLowerCase().includes(value.toLowerCase())
          );
        });
      }

      return data;
    }),
    tap(() => this.isLoading$.next(false)),
  );
  connection$ = this.allData$;

  constructor(private credentialsApiService: CredentialsApiService, private refreshService: RefreshService) {}

  trackBy = (_: number, item: any) => item.uuid;

  applyFilter(value: string) {
    this.filterValue$.next(value);
  }

  refresh() {
    this.refreshService.refresh();
  }
}
