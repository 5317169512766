import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'vdms-hq-ui-form-input-text-area',
  templateUrl: './form-input-text-area.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputTextAreaComponent),
    },
  ],
  styleUrls: ['./form-input-text-area.component.scss'],
})
export class FormInputTextAreaComponent extends FormControlValueAccessorComponent {
  innerFormControl = new UntypedFormControl(null);
  @Input() minRows = 4;
  @Input() small = false;
}
