import { VideoCodec } from '@vdms-hq/api-contract';
import { BehaviorSubject, combineLatest, debounceTime, map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { VideoCodecsApiService } from '@vdms-hq/api-contract';
import { Injectable } from '@angular/core';
import { FilterableDataSource, LoadableDataSource, RefreshService } from '@vdms-hq/shared';

@Injectable({ providedIn: 'root' })
export class VideoCodecsDatasourceService implements Partial<LoadableDataSource>, Partial<FilterableDataSource> {
  isLoading$ = new BehaviorSubject(false);
  filterValue$ = new BehaviorSubject('');
  refresh$ = this.refreshService.refresh$;
  allCodecs$ = this.refresh$.pipe(
    switchMap(() => this.videoCodecsApiService.getAll()),
    shareReplay(),
  );
  allData$: Observable<VideoCodec[]> = combineLatest([this.allCodecs$, this.filterValue$]).pipe(
    tap(() => this.isLoading$.next(true)),
    debounceTime(250),
    map(([data, value]) => {
      if (value) {
        return data.filter(({ display_name, profile, video_codec, uuid }) => {
          return (
            display_name.toLowerCase().includes(value.toLowerCase()) ||
            profile?.toLowerCase().includes(value.toLowerCase()) ||
            video_codec?.toLowerCase().includes(value.toLowerCase()) ||
            uuid?.toLowerCase().includes(value.toLowerCase())
          );
        });
      }

      return data;
    }),
    tap(() => this.isLoading$.next(false)),
  );
  connection$ = this.allData$;

  constructor(public videoCodecsApiService: VideoCodecsApiService, private refreshService: RefreshService) {}

  trackBy = (_: number, item: VideoCodec) => item.uuid;

  applyFilter(value: string) {
    this.filterValue$.next(value);
  }

  refresh() {
    this.refreshService.refresh();
  }
}
