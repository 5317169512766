import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { ActivatedClientService, WithPermissions } from '@vdms-hq/activated-client';
import { Selection } from '@vdms-hq/shared';
import { Subject, takeUntil } from 'rxjs';
import { ResultsActions } from '../../logic/results-actions.model';

export interface BatchActionsButtonsConfig {
  addToLicensedPackages?: boolean;
  cart?: boolean;
  batchEdit?: boolean;
  addToCollection?: boolean;
  delete?: boolean;
  setAsColdOnly?: boolean;
  triggerNotifications?: boolean;
}

export interface BatchActionsButtonsLabels {
  addToLicensedPackages?: string;
  cart?: string;
  batchEdit?: string;
  addToCollection?: string;
  delete?: string;
  setAsColdOnly?: string;
  triggerNotifications?: string;
}

type ResultsActionsType = typeof ResultsActions;

@Component({
  selector: 'vdms-hq-asset-results-actions',
  templateUrl: './vdms-hq-asset-results-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetResultsActionsComponent<T> extends WithPermissions() implements AfterViewInit, OnDestroy {
  changeRef = inject(ChangeDetectorRef);
  activatedClient = inject(ActivatedClientService);

  #enabledActions: BatchActionsButtonsConfig = {
    cart: true,
    batchEdit: true,
    addToCollection: true,
    delete: true,
    setAsColdOnly: true,
    addToLicensedPackages: true,
  };

  #actionLabels: BatchActionsButtonsLabels = {
    cart: 'common.global.add_to_basket',
    batchEdit: 'pages.assets_list.batch_update',
    addToCollection: 'pages.lists.add_to_collection',
    delete: 'pages.assets_list.delete_assets',
    setAsColdOnly: 'pages.lists.set_as_cold',
    addToLicensedPackages: 'pages.lists.add_to_licensed_packages',
  };

  ResultsActions = ResultsActions;
  selectionTotal = 0;
  selectedVirtualAssets = false;
  selectedQuarantinedAssets = false;

  destroyed$ = new Subject<void>();
  @Input() selectionManager?: Selection<T>;
  @Input() cartButtonVisibility = true;
  @Input() cartButtonDisabled = false;
  @Input() batchEditButton = true;

  @Input() addToCollectionButton = true;

  @Output() actions: EventEmitter<ResultsActionsType[keyof ResultsActionsType]> = new EventEmitter();

  @Input() set enabledActions(actionsConfig: BatchActionsButtonsConfig) {
    this.#enabledActions = { ...this.#enabledActions, ...actionsConfig };
  }

  get enabledActions() {
    return this.#enabledActions;
  }

  @Input() set actionLabels(actionLabels: BatchActionsButtonsLabels) {
    this.#actionLabels = { ...this.#actionLabels, ...actionLabels };
  }

  get actionLabels() {
    return this.#actionLabels;
  }

  ngAfterViewInit() {
    this.activatedClient.coldStorageEnabled$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((canMoveToCold) => (this.enabledActions.setAsColdOnly = canMoveToCold));
    this.selectionManager?.total$.pipe(takeUntil(this.destroyed$)).subscribe((selection) => {
      this.selectionTotal = selection;
      this.changeRef.detectChanges();
    });
    this.selectionManager?.entities$.pipe(takeUntil(this.destroyed$)).subscribe((selection) => {
      if (selection.length > 0) {
        this.selectedVirtualAssets = selection.some((asset: any) => asset.is_virtual);
        this.selectedQuarantinedAssets = selection.some((asset: any) => asset.is_quarantined);
      }

      this.changeRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
