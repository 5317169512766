import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { AudioPlayerService } from '../../logic/audio-player.service';
import { CommonModule } from '@angular/common';
import { UIPreviewModule } from '@vdms-hq/ui';
import { map } from 'rxjs/operators';

@Component({
  selector: 'vdms-hq-ui-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
  standalone: true,
  imports: [CommonModule, UIPreviewModule],
})
export class AudioPlayerComponent implements AfterViewInit, OnDestroy {
  state$ = this.audioService.state$.pipe(map((item) => item.state));

  constructor(private cdr: ChangeDetectorRef, private audioService: AudioPlayerService) {}

  ngOnDestroy() {
    this.audioService.unload();
  }

  async ngAfterViewInit(): Promise<void> {
    this.audioService.load();
    this.cdr.detectChanges();
  }
}
