import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getAllMessages } from '../../logic/reducers/index.reducer';
import { MessageModel, MessageState } from '../../logic/models/message.model';
import { Observable } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';
import * as messagesActions from '../../logic/actions/message.action';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('flyIn', [
      transition(':enter', [
        style({
          transform: 'scale(0.8)',
          opacity: 0,
        }),
        animate(
          '150ms cubic-bezier(0, 0, 0.2, 1)',
          style({
            transform: 'scale(1)',
            opacity: 1,
          }),
        ),
      ]),
    ]),
  ],
})
export class MessagesComponent implements OnInit {
  messages$: Observable<MessageModel[]>;
  trackByFn = (item: MessageModel) => item.id;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.messages$ = this.store.select(getAllMessages);
  }

  close(id: string) {
    this.store.dispatch(messagesActions.messageClose({ id }));
  }

  isProcessing(message: MessageModel) {
    return message.state === MessageState.processing;
  }

  getIcon(message: MessageModel) {
    switch (message.state) {
      case MessageState.error:
        return 'error';
      case MessageState.success:
        return 'check_circle';
    }
    return 'info';
  }
}
