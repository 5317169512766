import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { SelectedFilesService } from './selected-files.service';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'vdms-hq-native-upload-component',
  templateUrl: './native-upload.component.html',
  styleUrls: ['./native-upload.component.scss'],
})
export class NativeUploadComponent implements AfterViewInit, OnDestroy {
  #destroy = new Subject<void>();
  @Input() multiple = false;
  @Input() loading = false;
  @Input() hint = 'common.global.drag_and_drop_asset_hint';
  @Input() selectFileButtonLabel = 'common.global.select_file';
  @Input() mimeTypes = ['*/*'];

  disabled = false;

  selectedFiles$ = this.selectedFilesService.selectedFiles$;

  constructor(private selectedFilesService: SelectedFilesService) {}

  @ViewChild('fileUpload') fileInput!: ElementRef<HTMLInputElement>;

  ngAfterViewInit() {
    this.selectedFiles$
      .pipe(
        takeUntil(this.#destroy),
        tap((files) => {
          this.disabled = !this.multiple && files.length === 1;
        }),
        filter((files) => files.length === 0),
      )
      .subscribe(() => {
        this.fileInput.nativeElement.value = '';
      });
  }

  ngOnDestroy() {
    this.#destroy.next();
    this.#destroy.complete();
  }

  onFileDropped($event: FileList) {
    const selected = this.#extractFiles($event).filter((file) => this.#filterMimeType(file));

    this.#setFiles(selected);
  }

  selectFile($eventTarget: EventTarget | null) {
    if (!$eventTarget) {
      return;
    }
    const files = (<{ files?: FileList }>$eventTarget)?.files;

    if (files instanceof FileList) {
      const selected = this.#extractFiles(files);
      this.#setFiles(selected);
    }
  }

  removeFromList(index: number) {
    this.selectedFilesService.remove(index);
  }

  #extractFiles(fileList: FileList): File[] {
    const files = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      if (!file) {
        continue;
      }
      files.push(file);
    }

    return files;
  }

  #filterMimeType(file: File): boolean {
    if (this.mimeTypes.includes('*/*')) {
      return true;
    }

    return this.mimeTypes.includes(file.type);
  }

  #setFiles(selected: File[]) {
    if (selected.length === 0) {
      return;
    }

    if (!this.multiple) {
      this.selectedFilesService.set([selected[0]]);
    } else {
      this.selectedFilesService.set(selected);
    }
  }
}
