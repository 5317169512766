import { Pipe, PipeTransform } from '@angular/core';
import { AsyncPipe, DatePipe } from '@angular/common';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const argOrUndefined = (arg?: string | undefined) => (arg ? arg : undefined);

@Pipe({ name: 'reactiveTzDate', pure: true })
export class ReactiveTzDatePipe implements PipeTransform {
  timezoneSource$: Observable<string> = EMPTY;

  public constructor(private readonly datePipe: DatePipe, private readonly asyncPipe: AsyncPipe) {}

  transform(value: Date | string | number, format?: string, locale?: string): string | null {
    return this.asyncPipe.transform(
      this.timezoneSource$.pipe(
        map((timezone: string) =>
          this.datePipe.transform(value, argOrUndefined(format), timezone, argOrUndefined(locale)),
        ),
      ),
    );
  }
}
