import { MetadataSnapshot, MetadataSnapshotEndpoints } from './metadata-snapshots.model';
import { Injectable } from '@angular/core';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { ApiService } from '../api.service';
import { ApiResponse } from '../api.model';

@Injectable({ providedIn: 'root' })
export class MetadataSnapshotsService {
  constructor(private readonly apiService: ApiService) {}
  getSnapshotConfig() {
    return this.apiService.get<ApiResponse<MetadataSnapshot>>(`${MetadataSnapshotEndpoints.ONE}`).pipe(GetResponseData);
  }

  postSnapshotConfig(body: Partial<MetadataSnapshot>) {
    return this.apiService.post<Partial<MetadataSnapshot>, unknown>(`${MetadataSnapshotEndpoints.ONE}`, body);
  }

  updateSnapshotConfig(body: Partial<MetadataSnapshot>) {
    return this.apiService.patch<Partial<MetadataSnapshot>, unknown>(`${MetadataSnapshotEndpoints.ONE}`, body);
  }

  deleteSnapshotConfig() {
    return this.apiService.delete<ApiResponse<MetadataSnapshot>>(`${MetadataSnapshotEndpoints.ONE}`);
  }

  runSnapshot() {
    return this.apiService.post(`${MetadataSnapshotEndpoints.RUN}`, {});
  }
}
