import { Pipe, PipeTransform, QueryList } from '@angular/core';
import { CustomElementDirective } from '../directives/custom-element.directive';

@Pipe({
  name: 'customElement',
  standalone: true,
})
export class CustomElementPipe implements PipeTransform {
  transform(
    id: string,
    registeredCustomElements: QueryList<CustomElementDirective>,
  ): CustomElementDirective | undefined {
    return registeredCustomElements.find((customElement) => customElement.customElementId === id);
  }
}
