<vdms-hq-ui-dialog-wrapper footerAlign="space-between" [size]="'wide'">
  <div content [style.position]="'relative'">
    <vdms-hq-ui-loader *ngIf="previewLoader" [backdrop]="true" [mode]="'over-parent'"></vdms-hq-ui-loader>
    <form [formGroup]="form">
      <vdms-hq-ui-form-input-text
        formControlName="name"
        [label]="'pages.dashboards.name' | translate"
      ></vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-input-checkbox
        [withFooter]="false"
        formControlName="show_latest"
        [label]="'pages.dashboards.show_latest' | translate"
      ></vdms-hq-ui-form-input-checkbox>
      <vdms-hq-ui-form-input-checkbox
        [withFooter]="false"
        formControlName="is_default"
        [label]="'pages.dashboards.is_default' | translate"
      ></vdms-hq-ui-form-input-checkbox>
      <vdms-hq-ui-form-input-checkbox
        formControlName="show_collections_in_carousel"
        [label]="'pages.dashboards.show_collections_in_carousel' | translate"
      ></vdms-hq-ui-form-input-checkbox>
    </form>
    <div class="collections-wrapper">
      <div class="collections-list">
        <h2>{{ 'pages.dashboards.collections_list' | translate }} ({{ collectionsService.total$.value }})</h2>
        <form [formGroup]="form">
          <vdms-hq-ui-form-section [divider]="false" [layout]="'single'">
            <vdms-hq-ui-form-input-text formControlName="filterControl" [label]="'pages.dashboards.filter' | translate">
            </vdms-hq-ui-form-input-text>
          </vdms-hq-ui-form-section>
        </form>
        <div class="relative">
          <ng-container *ngIf="!previewLoader">
            <vdms-hq-ui-loader
              *ngIf="collectionsService.isLoading$ | async"
              [backdrop]="true"
              [mode]="'over-parent'"
            ></vdms-hq-ui-loader>
          </ng-container>
          <vdms-hq-ui-form-section
            [divider]="true"
            [dividerMarginBlock]="true"
            [layout]="'grid-3-columns'"
            [columnSizes]="['minmax(250px, 1fr)', '80px', '80px']"
          >
            <h3>{{ 'pages.dashboards.collection_name' | translate }}</h3>
            <h3 class="text-center">{{ 'pages.dashboards.main_page' | translate }}</h3>
            <h3 class="text-center">{{ 'pages.dashboards.carousel' | translate }}</h3>
          </vdms-hq-ui-form-section>
          <div class="list-container" #collectionsList>
            <div *ngFor="let collection of collections">
              <vdms-hq-ui-form-section
                [divider]="false"
                [layout]="'grid-3-columns'"
                [columnSizes]="['1fr', '80px', '80px']"
                [matTooltip]="collection.name"
                [matTooltipDisabled]="!collection.name.length || label.offsetWidth === label.scrollWidth"
              >
                <div #label class="label">{{ collection.name }}</div>
                <vdms-hq-ui-button class="text-center" (click)="toggleFor(collection, 'thumb')"
                  >{{ isEnabledFor(collection, 'thumb') ? 'Remove' : 'Add' }}
                </vdms-hq-ui-button>
                <vdms-hq-ui-button class="text-center" (click)="toggleFor(collection, 'carousel')"
                  >{{ isEnabledFor(collection, 'carousel') ? 'Remove' : 'Add' }}
                </vdms-hq-ui-button>
              </vdms-hq-ui-form-section>
            </div>
          </div>
        </div>
        <mat-paginator
          [length]="collectionsService.total$ | async"
          [pageIndex]="collectionsService.pageIndex$ | async"
          [pageSize]="collectionsService.pageSize$ | async"
          (page)="collectionsService.pageChange($event)"
          [hidePageSize]="true"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
      <div class="collections-list">
        <h2 *ngIf="collectionsSelected.length > 0; else noCollectionsEnabledHeader">
          {{ 'pages.dashboards.collections_selected' | translate }} ({{ collectionsSelected.length }})
        </h2>
        <ng-template #noCollectionsEnabledHeader>
          <h2>{{ 'pages.dashboards.no_collections_selected' | translate }}</h2>
        </ng-template>
        <vdms-hq-ui-form-section [divider]="false" [layout]="'single'">
          <vdms-hq-ui-form-input-text
            [(ngModel)]="selectedFilterValue"
            [label]="'pages.dashboards.filter_enabled' | translate"
            [disabled]="collectionsSelected.length === 0"
          >
          </vdms-hq-ui-form-input-text>
        </vdms-hq-ui-form-section>
        <vdms-hq-ui-form-section
          [divider]="true"
          [dividerMarginBlock]="true"
          [layout]="'grid-3-columns'"
          [columnSizes]="['minmax(250px, 1fr)', '80px', '80px']"
        >
          <h3>{{ 'pages.dashboards.collection_name' | translate }}</h3>
          <h3 class="text-center">{{ 'pages.dashboards.main_page' | translate }}</h3>
          <h3 class="text-center">{{ 'pages.dashboards.carousel' | translate }}</h3>
        </vdms-hq-ui-form-section>
        <ng-container *ngIf="collectionsSelected.length > 0; else noCollectionsEnabled">
          <div cdkDropList (cdkDropListDropped)="drop($event)" class="list-container">
            <div *ngFor="let collection of collectionsSelected | filterByProp : selectedFilterValue : 'name'" cdkDrag>
              <vdms-hq-ui-form-section
                [divider]="false"
                [layout]="'grid-3-columns'"
                [columnSizes]="['1fr', '80px', '80px']"
              >
                <div class="collection-selected-row">
                  <span
                    [matTooltip]="'Drag and drop to set order of dashboard carousel'"
                    [matTooltipPosition]="'right'"
                    >{{ collection.name }}</span
                  >
                  <vdms-hq-ui-button
                    (click)="removeFromSelected(collection)"
                    [iconOnly]="true"
                    [icon]="'delete_outline'"
                    [size]="'small'"
                    [matTooltip]="'Remove from selected'"
                    [matTooltipPosition]="'left'"
                    class="collection-selected-remove"
                  ></vdms-hq-ui-button>
                </div>
                <vdms-hq-ui-form-input-checkbox
                  class="text-center"
                  [ngModel]="isEnabledFor(collection, 'thumb')"
                  (ngModelChange)="toggleFor(collection, 'thumb')"
                  [withFooter]="false"
                >
                </vdms-hq-ui-form-input-checkbox>
                <vdms-hq-ui-form-input-checkbox
                  class="text-center"
                  [ngModel]="isEnabledFor(collection, 'carousel')"
                  (ngModelChange)="toggleFor(collection, 'carousel')"
                  [withFooter]="false"
                >
                </vdms-hq-ui-form-input-checkbox>
              </vdms-hq-ui-form-section>
            </div>
          </div>
        </ng-container>
        <ng-template #noCollectionsEnabled>
          <vdms-hq-ui-empty-results></vdms-hq-ui-empty-results>
        </ng-template>
      </div>
    </div>
  </div>

  <ng-container footer>
    <vdms-hq-ui-button (click)="close()" color="secondary">{{ 'common.global.close' | translate }}</vdms-hq-ui-button>
    <vdms-hq-ui-button
      [ngStyle]="{ margin: '0 0 0 auto' }"
      (click)="removeAllFromSelected()"
      color="warn"
      *ngIf="collectionsSelected.length > 0"
      >{{ 'pages.dashboards.collections_reset' | translate }}</vdms-hq-ui-button
    >
    <vdms-hq-ui-button (click)="save()" [disabled]="form.invalid" color="primary">{{
      'common.global.save' | translate
    }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
