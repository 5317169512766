import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vdms-hq-grid-status',
  templateUrl: './grid-status.component.html',
  styleUrls: ['./grid-status.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatIconModule, MatTooltipModule, TranslateModule],
})
export class GridStatusComponent {
  @Input() color: 'success' | 'default' | 'alert' = 'default';
  @Input() dotHidden = false;
  @Input() tooltip?: string;
  @Input() icon?: string;
  @Input() iconHidden? = true;
  @Input() iconOnly? = false;
  @Input() label?: string;

  get type() {
    if (this.icon?.length && this.iconOnly && !this.iconHidden) {
      return 'icon';
    }
    if (this.icon?.length && !this.iconOnly && !this.iconHidden) {
      return 'iconAndText';
    }

    return 'textOnly';
  }
}
