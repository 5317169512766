<form [formGroup]="form" class="auth-form" (submit)="login(); loading = !loading">
  <div class="auth-form__header">
    <span class="auth-form__header--text">{{ 'common.auth.headerLogInTo' | translate }}</span>
    <img [src]="fallbackLogoUrl" class="auth-form__header--img" />
    <span class="auth-form__header--text">{{ 'common.auth.headerContentOs' | translate }}</span>
  </div>

  <div class="auth-form__inputs">
    <vdms-hq-ui-form-input-text formControlName="token" [label]="'common.auth.tokenLabel' | translate">
    </vdms-hq-ui-form-input-text>
  </div>

  <div class="auth-form__footer">
    <div class="login-trouble-line">
      <span>{{ 'common.auth.loginTrouble' | translate }}</span>
      <a href="mailto:support@vida.studio">{{ 'common.auth.loginTroubleContact' | translate }}</a>
    </div>
    <vdms-hq-ui-button [isSubmit]="true" color="primary" size="full" [loading]="loading" [disabled]="form.invalid">{{
      loginButtonTranslateKey | translate
    }}</vdms-hq-ui-button>
  </div>
</form>
