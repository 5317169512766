import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UILoaderModule } from '../../../ui-loader';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vdms-hq-ui-tile-image',
  imports: [UILoaderModule, CommonModule],
  template: `
    <div class="tile-image">
      <img
        [src]="hover ? hoverSrc : src"
        [alt]="alt"
        [class]="'tile-image__img ' + (mock ? 'mock' : '')"
        (mouseenter)="hover = true"
        (mouseleave)="hover = false"
        (error)="mock = true"
      />
    </div>
  `,
  styleUrls: ['./tile-image.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileImageComponent {
  @Input() src?: string | null;
  @Input() hoverSrc?: string | null;
  @Input() alt = 'Preview';
  hover = false;
  mock = false;
}
