import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'vdmsHqIsEmpty',
})
export class IsEmptyPipe implements PipeTransform {
  transform = IsEmptyPipe.transform;

  static transform = (value?: unknown): boolean => {
    if (value === undefined || value === null) {
      return true;
    }

    if (Array.isArray(value)) {
      return value.every(IsEmptyPipe.transform);
    }

    if (value && typeof value === 'object') {
      return Object.keys(value).length === 0;
    }

    return false;
  };
}
