import { Pipe, PipeTransform } from '@angular/core';
import { GroupCurrencyISO, GroupCurrencySymbol } from './../misc/select-options';

@Pipe({
  name: 'clientCurrency',
})
export class ClientCurrencyPipe implements PipeTransform {
  transform = ClientCurrencyPipe.transform;
  static transform(value: string, currency: GroupCurrencyISO): string {
    switch (currency) {
      case GroupCurrencyISO.EUR:
        return `${GroupCurrencySymbol.EUR} ${value}`;
      case GroupCurrencyISO.USD:
        return `${GroupCurrencySymbol.USD} ${value}`;
      case GroupCurrencyISO.PLN:
        return `${value} ${GroupCurrencySymbol.PLN}`;
      default:
        return `${GroupCurrencySymbol.GBP} ${value}`;
    }
  }
}
