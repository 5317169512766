import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Timecode } from '@vdms-hq/timecode';

@Injectable({ providedIn: 'root' })
export class TimecodesService {
  #timecodes = new BehaviorSubject<[Timecode | null, Timecode | null]>([null, null]);
  timecodes$ = this.#timecodes.asObservable();

  setTcIn(tcIn: Timecode) {
    const [, tcOut] = this.#timecodes.value;
    this.#timecodes.next([tcIn, tcOut]);
  }

  setTcOut(tcOut: Timecode) {
    const [tcIn] = this.#timecodes.value;
    this.#timecodes.next([tcIn, tcOut]);
  }

  reset() {
    this.#timecodes.next([null, null]);
  }
}
