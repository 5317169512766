import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TilesComponent } from './view/tiles/tiles.component';
import { TileComponent } from './view/tile/tile.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatCheckboxModule} from "@angular/material/checkbox";

const exportedComponents = [TilesComponent, TileComponent];

@NgModule({
  declarations: exportedComponents,
  providers: [],
  imports: [CommonModule, TranslateModule, MatIconModule, MatProgressSpinnerModule, MatCheckboxModule],
  exports: exportedComponents,
})
export class UITilesModule {}
