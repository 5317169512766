import { InjectionToken, Provider } from '@angular/core';

export interface CartConfig {
  cdn: string;
}
export const CART_CONFIG: InjectionToken<CartConfig> = new InjectionToken<CartConfig>('CART_TOKEN');

export const configureCart = (config: CartConfig): Provider[] => {
  return [{ provide: CART_CONFIG, useValue: config }];
};
