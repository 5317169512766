<div class="material-custom-form" [class.with-touched-indicator]="withTouchedIndicator">
  <span class="field-label">{{ label }}</span>

  <div class="slider-content material-custom-slider">
    <mat-slider
      [disabled]="disabled"
      [max]="sliderMaxValue"
      [min]="outerValue ? sliderMinValue : undefined"
      [step]="step"
      [showTickMarks]="true"
      [discrete]="discrete"
      [displayWith]="displayWithFn"
    >
      <input matSliderThumb [formControl]="innerFormControl" (valueChange)="update($event)" />
    </mat-slider>
    <ng-content select="[action]"></ng-content>
  </div>

  <mat-hint *ngIf="withFooter && hint">{{ hint }}</mat-hint>
  <mat-error *ngIf="withFooter">
    <span *ngFor="let error of errors">{{ error.transLang | translate : error.contextParams }}</span>
  </mat-error>
  <vdms-hq-ui-loader matSuffix [size]="20" *ngIf="innerFormControl.pending"></vdms-hq-ui-loader>
</div>
