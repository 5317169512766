import { Injectable } from '@angular/core';
import { AIModelModel, SummaryModel, TranscriptionService } from '@vdms-hq/api-contract';
import { Observable, of } from 'rxjs';
import { TranscriptionDataSourceService } from './transcription.data-source.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { TranscriptionToasts } from './transcription-toasts';
import { filterEmpty, SelectOption } from '@vdms-hq/shared';

export enum DescriptionTone {
  HUMOROUS = 'Humorous',
  SERIOUS = 'Serious',
  NOSTALGIC = 'Nostalgic',
  SUSPENSEFUL = 'Suspenseful',
  ROMANTIC = 'Romantic',
  INSPIRING = 'Inspiring',
  MELANCHOLIC = 'Melancholic',
  SATIRICAL = 'Satirical',
  ENERGETIC = 'Energetic',
  CALMING = 'Calming',
}

@Injectable({ providedIn: 'root' })
export class SummaryGeneratorService {
  constructor(
    private readonly dataSourceService: TranscriptionDataSourceService,
    private readonly transcriptionService: TranscriptionService,
    private readonly toastService: TranscriptionToasts,
  ) {}

  modelsSelectOptions$ = this.transcriptionService.listModels().pipe(
    map((models) => models.filter((model) => model.context === 'summary')),
    map<AIModelModel[], SelectOption[]>((models) =>
      models.map((model) => ({
        key: model.uuid,
        label: model.name,
      })),
    ),
  );

  descriptionToneSelectOptions$ = of(DescriptionTone).pipe(
    map((enumType) => Object.values(enumType)),
    map<string[], SelectOption[]>((values) => values.map((value) => ({ key: value.toLowerCase(), label: value }))),
  );

  generateForDefaultTranscription$ = (modelUuid: string, tone?: string): Observable<SummaryModel | SummaryModel[]> => {
    return this.dataSourceService.defaultTranscriptionUuid$.pipe(
      filterEmpty(),
      switchMap((uuid) =>
        this.transcriptionService.generateSummary(uuid, modelUuid, tone).pipe(
          catchError(() => {
            this.toastService.popToast.TRIGGER_SUMMARY_ERROR();
            return of(null);
          }),
        ),
      ),
      filterEmpty(),
      tap(() => {
        this.toastService.popToast.TRIGGER_SUMMARY_SUCCESS();
      }),
    );
  };

  fetchLatest(number: number) {
    return this.dataSourceService.defaultTranscriptionUuid$.pipe(
      filterEmpty(),
      switchMap((uuid) => this.transcriptionService.getSummaries(uuid)),
      map((summaries) => summaries.slice(0, number)),
    );
  }
}
