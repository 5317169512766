import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { WithPermissions } from '@vdms-hq/activated-client';
import { Selection } from '@vdms-hq/shared';
import { ResultsActions } from '@vdms-hq/asset-results';
import { take } from 'rxjs/operators';
import { UIButtonModule, UILayoutModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vdms-hq-shared-pack-assets-footer',
  templateUrl: './shared-pack-assets-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UILayoutModule, UIButtonModule, TranslateModule],
  standalone: true,
})
export class SharedPackAssetsFooterComponent<T> extends WithPermissions() {
  @Input() selectionManager?: Selection<T>;
  @Input() downloadButtonsDisabled = false;
  @Output() actions: EventEmitter<{ action: ResultsActions; item: T[] }> = new EventEmitter();

  protected readonly ResultsActions = ResultsActions;

  onExport(action: ResultsActions = ResultsActions.DOWNLOAD_SELECTED_ASPERA) {
    this.selectionManager?.entities$.pipe(take(1)).subscribe((items) => {
      if (this.downloadButtonsDisabled) {
        return;
      }

      this.actions.emit({ action: action, item: items });
    });
  }

  onClear(): void {
    this.selectionManager?.clear();
  }
}
