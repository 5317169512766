<div class="range">
  <vdms-hq-ui-form-input-toggle
    [(ngModel)]="customValues"
    label="Set custom values"
    [withFooter]="false"
    class="custom-values-toggle"
  ></vdms-hq-ui-form-input-toggle>

  <vdms-hq-ui-form-radio-list
    *ngIf="!customValues"
    [selectOptions]="predefinedRangesOptions"
    [disabled]="innerFormControl.disabled"
    [ngModel]="innerFormControl.value"
    (ngModelChange)="updateFromPredefined($event)"
  >
  </vdms-hq-ui-form-radio-list>

  <div class="inputs" *ngIf="customValues" [formGroup]="innerFormControl">
    <vdms-hq-form-input-timecode
      label="From"
      formControlName="from"
      [withFooter]="false"
      [hideFramerate]="true"
    ></vdms-hq-form-input-timecode>
    <vdms-hq-form-input-timecode
      label="To"
      formControlName="to"
      [withFooter]="false"
      [hideFramerate]="true"
    ></vdms-hq-form-input-timecode>
  </div>

  <ngx-slider
    [value]="currentSliderFrom"
    (userChangeEnd)="updateFromSlider($event)"
    [highValue]="currentSliderTo"
    [options]="options"
    *ngIf="customValues"
    class="custom-slider"
  >
  </ngx-slider>
</div>
