import { inject, Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { QUARANTINE_ALL_ASSET_TYPES, QuarantineConfigRules } from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import { BehaviorSubject, finalize, map, take, tap } from 'rxjs';
import { QuarantineService } from './quarantine.service';

@Injectable({ providedIn: 'root' })
export class QuarantineFormService {
  private quarantineService = inject(QuarantineService);
  private toastService = inject(ToastService);

  private form = new FormGroup({
    quarantine: new FormControl<string[]>([], { nonNullable: true }),
  });

  private QuarantineRulesAssetTypes = QUARANTINE_ALL_ASSET_TYPES;
  #popToast = {
    SAVE_SUCCESS: () =>
      this.toastService.success({
        id: 'save_field_success',
        message: 'common.notifications.quarantine.save.success',
      }),
    SAVE_FAILURE: (reason: string = 'Not specified') =>
      this.toastService.error({
        id: 'save_field_failure',
        message: 'common.notifications.quarantine.save.failure',
        interpolatedParams: { reason },
      }),
  };

  isLoading$ = new BehaviorSubject(false);
  isSaving$ = new BehaviorSubject(false);

  getForm$() {
    return this.quarantineService.clientQuarantineConfig$.pipe(
      take(1),
      tap(() => {
        this.isLoading$.next(true);
      }),
      map((quarantineEnabled) => {
        const rules: string[] = quarantineEnabled
          ? Object.keys(quarantineEnabled.rules ?? {}).filter(
              (key) => (quarantineEnabled.rules as Record<string, boolean>)[key],
            )
          : [];

        this.form.patchValue({
          quarantine: rules,
        });
        return this.form;
      }),
      finalize(() => {
        this.isLoading$.next(false);
      }),
    );
  }

  save() {
    if (this.form.invalid) {
      return;
    }

    const quarantineValue = this.form.value.quarantine;

    this.isSaving$.next(true);

    this.quarantineService.saveQuarantineConfig(this.#toQuarantineConfigRules(quarantineValue)).subscribe({
      next: () => {
        this.isSaving$.next(false);
        this.#popToast.SAVE_SUCCESS();
      },
      error: (error) => {
        this.isSaving$.next(false);
        this.#popToast.SAVE_FAILURE(error.message);
      },
    });
  }

  #toQuarantineConfigRules(quarantine: string[] | undefined): QuarantineConfigRules {
    return this.QuarantineRulesAssetTypes.reduce((acc, type) => {
      acc[type] = quarantine?.includes(type) ?? false;
      return acc;
    }, {} as QuarantineConfigRules);
  }
}
