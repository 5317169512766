import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-shared-form-file',
  templateUrl: './form-file.component.html',
  styleUrls: ['./form-file.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormFileComponent),
    },
  ],
})
export class FormFileComponent implements ControlValueAccessor {
  @Input() multiple = false;
  @Input() type: 'images' | 'videos' | 'any' = 'any';

  private files: FileList = null;
  file: File = null;
  disabled: boolean;

  propagateChange?: (value: FileList | File) => void;

  propagateTouch?: () => void;

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  writeValue(obj: any): void {
    if (this.multiple) {
      this.files = obj;
    } else {
      this.file = obj;
    }
  }

  onFilesSelected(files: FileList) {
    if (!this.multiple && files.length > 1) {
      return;
    }

    if (this.multiple) {
      this.files = files;
      this.propagateChange(this.files);
    } else {
      this.file = files.item(0);
      this.propagateChange(this.file);
    }
  }

  getMimeTypes() {
    switch (this.type) {
      case 'images':
        return 'image/*';
      case 'videos':
        return 'video/*';
      case 'any':
        return false;
    }
  }
}
