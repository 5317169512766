import { ChangeDetectorRef, Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { castTo, FieldDefinitionModel, FieldType, SelectOption, SelectOptionKey } from '@vdms-hq/shared';
import { SelectorSourceType } from '@vdms-hq/selectors';
import { FormBuilderService } from '../../logic/form-builder.service';
import { ContentSettings } from '../../logic/content.model';
import { Framerate } from '@vdms-hq/timecode';
import { FieldRelationsService } from '../../logic/field-relations.service';
import { FormControlValueAccessorComponent } from '@vdms-hq/ui';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'vdms-hq-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ControlComponent),
    },
  ],
})
export class ControlComponent extends FormControlValueAccessorComponent implements OnInit {
  innerFormControl!: FormControl;
  @Input() definition!: FieldDefinitionModel;
  @Input() withFooter = true;
  @Input() framerate?: Framerate;
  @Input() withStateToggle = false;
  @Input() content?: Partial<ContentSettings>;
  @Input() nullValue = true;

  unknownControl = false;

  withTouchedIndicator = true;
  types = FieldType;

  constructor(
    public formBuilder: FormBuilderService,
    public fieldRelationsService: FieldRelationsService,
    injector: Injector,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(injector, changeDetectorRef);
  }

  $sourceListKeyToSourceType = castTo<SelectorSourceType>();

  // todo we have to restore below behavior - not sure what it does, and why business logic is in this lib ;)
  languageFilterMethod = (items: SelectOption<SelectOptionKey>[]): SelectOption<SelectOptionKey>[] => {
    let assetType: string = (this.formBuilder.value as never)?.['general']?.['type'];
    if (!assetType) {
      return items;
    }

    switch (assetType) {
      case 'subtitle':
        assetType = 'timed_text';
        break;
      case 'font':
        assetType = 'title_manager';
        break;
    }

    return items.filter((item) => assetType in item);
  };

  ngOnInit(): void {
    const id = this.definition?.id;
    if (!id || !this.formBuilder.formGroup?.get(id)) {
      this.unknownControl = true;
    }
    this.innerFormControl = this.formBuilder.getControl(id) ?? new FormControl();
    this.innerFormControl.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      const outer = this.transformInnerToOuter(value);
      this.propagateToOuter(outer);
    });
  }

  changeDisabledState(checked: boolean) {
    if (!this.innerFormControl) {
      return;
    }

    if (checked) {
      this.innerFormControl.markAsDirty();
      this.innerFormControl.markAsTouched();
      this.innerFormControl.enable();
    } else {
      this.innerFormControl.markAsPristine();
      this.innerFormControl.markAsUntouched();
      this.innerFormControl.disable();
    }
  }
}
