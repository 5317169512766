import { Inject, Injectable } from '@angular/core';
import { DestroyComponent } from '@vdms-hq/shared';
import { BehaviorSubject, combineLatest, map, of, switchMap, tap, shareReplay } from 'rxjs';
import { API_CONFIG, ApiConfig, GithubEndpoints, GithubService, GitRelease } from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import { catchError, startWith } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ReleaseNotesDsService extends DestroyComponent {
  #page = new BehaviorSubject<number>(1);
  #perPage = new BehaviorSubject<number>(10);
  public repo = 'vida-frontend';
  public owner = 'vdms-hq';

  isLoading$ = new BehaviorSubject<boolean>(true);
  refresh$ = new BehaviorSubject<Date>(new Date());
  currentReleaseNo$ = new BehaviorSubject<string>('');
  showLoadMore$ = new BehaviorSubject<boolean>(false);

  allData$ = combineLatest([this.#perPage, this.#page, this.refresh$]).pipe(
    this.takeUntilDestroyed(),
    tap(() => this.isLoading$.next(true)),
    switchMap(() =>
      this.fetcher
        .getReleases({
          path: GithubEndpoints.RELEASES,
          method: 'GET',
          owner: this.owner,
          repo: this.repo,
          params: {
            page: this.#page.value.toString(),
            per_page: this.#perPage.value.toString(),
          },
        })
        .pipe(
          map(this.#filterByDomain.bind(this)),
          catchError((e: HttpErrorResponse) => {
            this.toastService.error({ id: `githubReleasesError`, message: e.message });
            return of([]);
          }),
          startWith(this.#retrieveFromLocalStorage()),
          shareReplay(1),
        ),
    ),
    map((nextReleases: GitRelease[]) => {
      const map = new Map<number, GitRelease>();
      this.allReleases$.value.forEach((item) => map.set(item.id, item));
      nextReleases.forEach((item: GitRelease) => map.set(item.id, item));
      return Array.from(map.values());
    }),
    tap((releases) => {
      this.isLoading$.next(false);
      this.allReleases$.next(releases);
      this.#saveToLocalStorage(releases);
      this.showLoadMore();
    }),
    shareReplay(1),
  );

  allReleases$ = new BehaviorSubject([] as GitRelease[]);

  constructor(
    @Inject(API_CONFIG) private env: ApiConfig,
    private fetcher: GithubService,
    private toastService: ToastService,
  ) {
    super();
  }

  #filterByDomain(releases: GitRelease[]): GitRelease[] {
    return releases.filter((r) => (this.env.production ? !r.prerelease : true));
  }

  #retrieveFromLocalStorage(): GitRelease[] {
    const rawValue = localStorage.getItem('VidaOS_RELEASES');

    if (rawValue) {
      return JSON.parse(rawValue);
    }

    return [];
  }

  #saveToLocalStorage(rawValue: GitRelease[]) {
    if (rawValue) {
      localStorage.setItem('VidaOS_RELEASES', JSON.stringify(rawValue));
    }
  }

  loadMore() {
    /* 30 is the maximum number of releases that can be fetched from the GitHub API per single page */
    if (this.#perPage.value === 30) {
      this.#page.next(this.#page.value + 1);
    } else {
      this.#perPage.next(this.#perPage.value + 10);
    }
  }

  showLoadMore() {
    const releasesCount = this.allReleases$.value.length;
    let maxReleases = 0;
    if (this.#page.value === 1) {
      maxReleases = this.#perPage.value;
      this.showLoadMore$.next(releasesCount >= maxReleases);
    } else {
      maxReleases = this.#perPage.value * this.#page.value;
      this.showLoadMore$.next(releasesCount >= maxReleases);
    }
  }
}
