export const cachedPrefixes = [
  '/asset/',
  '/assets',
  '/user/',
  '/users',
  '/collection/',
  '/collections',
  '/dashboard/',
  '/dashboards',
  '/policy/',
  '/policies',
  '/supplier/',
  '/suppliers',
  '/group/',
  '/groups',
  '/delivery-destination',
  '/orders',
  '/preview-requests',
  '/field-types',
  '/field/',
  '/field-type/',
  '/delivery-pack',
  '/video-codec-mappings',
  '/video-codec-mapping/',
  '/delivery-destination/',
  '/qa-configs',
];

export const notAvailableSuffixes = ['/pdf', '/reindex'];
