import { Routes } from '@angular/router';
import { VideoCodecsComponent } from './pages/video-codecs/video-codecs.component';

export enum VIDEO_CODECS_ROUTES {
  ROOT = 'video-codecs',
}

export const VIDEO_CODECS_ROUTING: Routes = [
  {
    path: '',
    component: VideoCodecsComponent,
  },
  {
    path: '**',
    redirectTo: `/${VIDEO_CODECS_ROUTES.ROOT}`,
    pathMatch: 'full',
  },
];
