<canvas
  baseChart
  *ngIf="chartOptions"
  [ngStyle]="{ maxHeight: this.maxHeight ?? '180px' }"
  [datasets]="datasets"
  [labels]="labels"
  [type]="chartType"
  [options]="chartOptions"
  [plugins]="chartPlugins"
  [legend]="chartLegend"
>
</canvas>
