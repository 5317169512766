import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { ActivatedClientService, AuthorizedClient } from '@vdms-hq/activated-client';
import { AppState, selectRouteParams, selectUrl } from './reducer-app';
import { AuthenticatedUserModel, AuthService } from '@vdms-hq/auth';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { clientChange, login, logout } from './autoload.action';
import { Params } from '@angular/router';
import { filterEmpty } from '@vdms-hq/shared';
import { EMPTY } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AutoloadEffect {
  constructor(
    private actions$: Actions,
    private activatedClientService: ActivatedClientService,
    private authService: AuthService,
    private store: Store<AppState>,
  ) {}

  whenRouteNavigated = (
    filterBy:
      | string
      | {
          url: string;
          param: string;
        }
      | {
          url?: string;
          param: string;
        }
      | {
          url: string;
          param?: string;
        },
    callback: (params: {
      client: string;
      auth: AuthenticatedUserModel | null | undefined;
      url: string;
      urlParams: Params;
    }) => Action | Action[],
    config: {
      requireAuth: boolean;
      requireClient: boolean;
    } = {
      requireAuth: true,
      requireClient: true,
    },
  ) => {
    const filterByUrl = typeof filterBy !== 'string' ? filterBy.url : filterBy;
    const filterByParam = typeof filterBy !== 'string' ? filterBy.param : undefined;

    return createEffect(() =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        switchMap(() =>
          this.activatedClientService.selectedClientId$.pipe(
            filterEmpty(),
            filter((client) => !config.requireClient || !!client),
            withLatestFrom(this.authService.auth$),
            filter(([, auth]) => !config.requireAuth || !!auth),
            withLatestFrom(this.store.select(selectUrl), this.store.select(selectRouteParams)),
            filter(([, url]) => !filterByUrl || url.includes(filterByUrl)),
            filter(([, , urlParams]) => !filterByParam || !!urlParams[filterByParam]),
            mergeMap(([[client, auth], url, urlParams]) => {
              const actionOrActions = callback({
                client,
                auth,
                url,
                urlParams,
              });

              return Array.isArray(actionOrActions) ? actionOrActions : [actionOrActions];
            }),
          ),
        ),
      ),
    );
  };

  whenLogoutOrClientChange = (callback: () => Action | Action[]) =>
    createEffect(() =>
      this.actions$.pipe(
        ofType(logout, clientChange),
        mergeMap(() => {
          const actionOrActions = callback();
          return Array.isArray(actionOrActions) ? actionOrActions : [actionOrActions];
        }),
      ),
    );

  whenClientChange = (callback: (id: string) => Action | Action[]) =>
    createEffect(() =>
      this.actions$.pipe(
        ofType(clientChange),
        map(({ id }) => id),
        filterEmpty(),
        mergeMap((id: string) => {
          const actionOrActions = callback(id);
          return Array.isArray(actionOrActions) ? actionOrActions : [actionOrActions];
        }),
      ),
    );

  whenClientChangeDetails = (callback: (id: AuthorizedClient) => Action | Action[] | false) =>
    createEffect(() =>
      this.actions$.pipe(
        ofType(clientChange),
        map(({ id }) => id),
        filterEmpty(),
        withLatestFrom(this.activatedClientService.clientDefinite$),
        mergeMap(([id, client]: [string, AuthorizedClient]) => {
          const actionOrActions = callback(client);
          if (!actionOrActions) {
            return EMPTY;
          }
          return Array.isArray(actionOrActions) ? actionOrActions : [actionOrActions];
        }),
      ),
    );

  whenLoginWithClient = (callback: (clientId: string) => Action | Action[]) =>
    createEffect(() =>
      this.actions$.pipe(
        ofType(login),
        switchMap(() =>
          this.activatedClientService.clientId$.pipe(
            filter((id) => !!id),
            take(1),
          ),
        ),
        mergeMap((id) => {
          const actionOrActions = callback(<string>id);
          return Array.isArray(actionOrActions) ? actionOrActions : [actionOrActions];
        }),
      ),
    );
}
