import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';
import { Framerate, Timecode, TimecodeValidators } from '@vdms-hq/timecode';
import { ButtonColors } from '../../../ui-button';

type OuterValue = Timecode | null | undefined;
type InnerValue = string | null;

@Component({
  selector: 'vdms-hq-form-input-timecode',
  templateUrl: './form-input-timecode.component.html',
  styleUrls: ['./form-input-timecode.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => FormInputTimecodeComponent) },
    { provide: NG_VALIDATORS, multi: true, useExisting: forwardRef(() => FormInputTimecodeComponent) },
  ],
})
export class FormInputTimecodeComponent
  extends FormControlValueAccessorComponent<OuterValue, InnerValue>
  implements OnInit, AfterViewInit
{
  innerFormControl = new UntypedFormControl(null);
  defaultFramerate = Framerate.default();
  hintFallback = 'Use keys △▽ to change frames';
  specialCharacters = [';', ':'];

  @Input() mask = '00:00:00:00';
  @Input() autofocus = false;
  @Input() framerate?: Framerate;
  @Input() buttonConfig?: { visible: boolean; label: string; color: ButtonColors };

  @ViewChild('timecodeInput') timecodeInput!: ElementRef;
  @Input() hideFramerate = false;

  @Output() buttonClick = new EventEmitter();

  override ngOnInit() {
    if (!this.framerate) {
      console.warn(
        'Frame rate is not provided for the component, please specify otherwise we will use default value which might be wrong.',
      );
    }

    if (this.framerate?.isDrop) {
      this.mask = '00:00:00;00';
    }

    super.ngOnInit();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.innerFormControl.setValidators(TimecodeValidators.isValid(this.framerate ?? this.defaultFramerate));

    this.autofocus && this.focus();
  }

  focus() {
    this.timecodeInput?.nativeElement.focus();
    this.timecodeInput?.nativeElement.select();
  }

  override transformInnerToOuter(nextValue: InnerValue): OuterValue {
    if (nextValue) {
      return Timecode.fromTimecode(nextValue, this.framerate ?? this.defaultFramerate, false);
    }

    return null;
  }

  override transformOuterToInner(value: OuterValue): InnerValue {
    return value?.toString() ?? null;
  }

  keyUp($event: KeyboardEvent) {
    if (this.innerFormControl.invalid) {
      return;
    }

    switch ($event.code) {
      case 'ArrowUp':
        this.addFrame(1);
        break;
      case 'ArrowDown':
        this.addFrame(-1);
        break;
    }
  }

  private addFrame(number: number) {
    let currentValue = this.innerFormControl.value;

    if (!currentValue && number > 0) {
      currentValue = this.mask;
    }

    if (!currentValue) {
      return;
    }

    const timecode = Timecode.fromTimecode(currentValue, this.framerate ?? this.defaultFramerate, false)?.addFrames(
      number,
    );

    if (timecode) {
      this.innerFormControl.setValue(timecode.toString());
    }
  }
}
