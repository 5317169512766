import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationStart, Route, Router } from '@angular/router';
import { filterEmpty } from '@vdms-hq/shared';

import { shareReplay, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BreadcrumbsNavigationService implements OnDestroy {
  #destroy$ = new Subject<void>();
  #routes?: Route[];

  breadcrumbs$ = this.router.events.pipe(
    takeUntil(this.#destroy$),
    map((event) => {
      function trimQueryString(inputString: string) {
        const queryStringIndex = inputString.indexOf('?');
        if (queryStringIndex !== -1) {
          return inputString.substring(0, queryStringIndex);
        }
        return inputString;
      }

      if (event instanceof NavigationStart) {
        const url = trimQueryString(this.router.url);
        const [, urlPart] = url.split('/');

        return this.#routes?.find((route) => route.path === urlPart)?.data?.breadcrumb;
      }
    }),
    filterEmpty(),
    startWith({}),
    shareReplay(1),
  );
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.#routes = this.router.config.find((route) => route.data?.breadcrumb?.name?.toLowerCase() === 'home')?.children;
  }

  ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }
}
