import { Injectable, TrackByFunction } from '@angular/core';
import {
  SubtitlesRow,
  TIMELINE_STYLES,
  SUBTITLES_LINE_STYLES,
  TimelineRow,
  AUDIOTRACK_LINE_STYLES,
  AudioTrackRow,
  SCRUBBER_STYLES,
  THUMBNAIL_LINE_STYLES,
  IN_OUT_LINE_STYLES,
  IN_OUT_PERIOD_MARKER_STYLES,
  CUSTOM_LINE_STYLES,
  CUSTOM_MARKER_PERIOD_STYLES,
  CUSTOM_MARKER_MOMENT_STYLES,
} from './advanced-player';
import { AdvancedPlayerService, LoadedSubtitle } from './advanced-player.service';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
  Unsubscribable,
} from 'rxjs';
import {
  OmakasePlayer,
  SubtitlesLane,
  ThumbnailLane,
  MarkerLane,
  PeriodMarker,
  AudioTrackLane,
  Timeline,
  MomentMarker,
} from '@vdms-hq/omakase-player';
import { MediaFetcherService } from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import { TimecodesService } from './timecodes.service';
import { Timecode } from '@vdms-hq/timecode';
import { withLatestFrom } from 'rxjs/operators';
import { filterEmpty } from '@vdms-hq/shared';
import { GenericMarker } from '@vdms-hq/omakase-player/dist/timeline/marker/marker';

@Injectable({
  providedIn: 'root',
})
export class TimelineService {
  private readonly TIMELINE_DOM_ELEMENT = 'advanced-player-timeline';
  #timeline$ = new BehaviorSubject<{
    state: 'not_initialized' | 'initialized';
    timeline?: Timeline;
  }>({
    state: 'not_initialized',
  });
  customRowClickedEmitter$ = new Subject();
  timeline$ = this.#timeline$.asObservable();
  abortListener$ = new Subject<void>();
  #customRows: BehaviorSubject<TimelineRow[]> = new BehaviorSubject<TimelineRow[]>([]);
  #subtitleRows: BehaviorSubject<SubtitlesRow[]> = new BehaviorSubject<SubtitlesRow[]>([]);
  #audioTrackRows: BehaviorSubject<AudioTrackRow[]> = new BehaviorSubject<AudioTrackRow[]>([]);
  #timelineInitialized$: Observable<Timeline> = this.#timeline$.pipe(
    takeUntil(this.abortListener$),
    filter((timelineState) => timelineState.state === 'initialized'),
    take(1),
    filter((item) => !!item?.timeline),
    map((item) => item?.timeline),
    filterEmpty(),
  );
  allRows$ = combineLatest([this.#customRows, this.#subtitleRows, this.#audioTrackRows]).pipe(
    map(([row1, row2, row3]) => [...row1, ...row2, ...row3]),
  );

  subscriptions: Unsubscribable[] = [];

  constructor(
    private mediaFetcher: MediaFetcherService,
    private playerService: AdvancedPlayerService,
    private timecodesService: TimecodesService,
    private toast: ToastService,
  ) {}

  trackBy: TrackByFunction<TimelineRow> = (id: number, element: TimelineRow) => {
    return element.id;
  };

  load() {
    const listenToSubs = this.playerService.loadedSubtitles$.subscribe((subtitles) => {
      this.#addSubtitles(subtitles);
    });

    // const listenToSubs = this.playerService.loadedSubtitles$.subscribe((subtitles) => {
    //   const subRows = subtitles.map((subtitle, key) => ({
    //     id: 'advanced-player-line-' + key,
    //     label: subtitle.language,
    //     url: subtitle.path,
    //     default: subtitle.default,
    //   }));
    //
    //   this.#addSubtitles(subRows);
    // });
    this.subscriptions.push(listenToSubs);

    const listenToMarkers = this.timecodesService.timecodes$.subscribe((timecodes) => {
      if (!timecodes[0]) {
        return;
      }

      this.#updateMarker(timecodes[0], timecodes[1] ?? undefined);
    });
    this.subscriptions.push(listenToMarkers);

    const initSub = this.playerService.state$
      .pipe(
        filter((player) => player.state === 'ready'),
        take(1),
      )
      .subscribe(() => {
        if (!this.playerService.player) {
          this.#handleError('Missing video player');
          return;
        }

        this.#initTimeline(this.playerService.player);
      });
    this.subscriptions.push(initSub);
  }

  unload() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];
    this.#resetRows();
    this.#timeline$.value.timeline?.destroy();
  }

  #addSubtitles(rows: LoadedSubtitle[]) {
    const sub = this.#timelineInitialized$.subscribe({
      next: (timeline) => {
        const vendorRows: SubtitlesRow[] = [];

        rows.forEach((subtitle) => {
          const alphaOnly = subtitle.language.replace(/([^0-9a-z])/gi, '');
          const id = alphaOnly.slice(alphaOnly.length - 50);
          const subtitleLaneExist = timeline.getLane(id);
          if (subtitleLaneExist) {
            return;
          }

          const subtitlesLane = new SubtitlesLane({
            id: id,
            description: subtitle.language,
            subtitlesVttUrl: subtitle.blobDataSrc,
            style: SUBTITLES_LINE_STYLES,
          });

          timeline.addLane(subtitlesLane);

          vendorRows.push({
            id: id,
            label: subtitle.language,
            url: subtitle.path,
            default: subtitle.default,
          });
        });

        this.#subtitleRows.next(vendorRows);
      },
      error: () => {
        this.#handleError('Unable to add subtitle to timeline');
      },
    });

    this.subscriptions.push(sub);
  }
  #initTimeline(player: OmakasePlayer) {
    this.subscriptions.push(
      player
        .createTimeline({
          timelineHTMLElementId: this.TIMELINE_DOM_ELEMENT,
          style: TIMELINE_STYLES,
        })
        .subscribe((timeline: Timeline) => {
          timeline.getScrubberLane().style = SCRUBBER_STYLES;

          this.#timeline$.next({
            state: 'initialized',
            timeline,
          });

          this.#initInOutMarkerLine(timeline);
          this.#initThumbnails(timeline);
          this.#initWaveForm(timeline);
        }),
    );
  }

  #initThumbnails(timeline: Timeline) {
    const thumbnails = this.playerService.config?.thumbnailsVttUrl;

    if (thumbnails) {
      const defaultThumbnailLane = new ThumbnailLane({
        id: 'thumbnail_lane_default',
        axiosConfig: {},
        description: 'Thumbnails',
        thumbnailVttUrl: thumbnails,
        style: THUMBNAIL_LINE_STYLES,
      });
      timeline.addLane(defaultThumbnailLane);
    }
  }

  #initInOutMarkerLine(timeline: Timeline) {
    const timelineExist = timeline.getLane('marker_lane_inout_1');
    if (timelineExist) {
      timeline.removeLane('marker_lane_inout_1');
    }

    const inAndOutMarkersLane = new MarkerLane({
      id: 'marker_lane_inout_1',
      description: 'In and out markers',
      style: IN_OUT_LINE_STYLES,
    });

    timeline?.addLane(inAndOutMarkersLane);
  }

  #initWaveForm(timeline: Timeline) {
    const waveforms = this.playerService.config?.waveformsVtt;

    waveforms?.forEach((waveform, k) => {
      const defaultThumbnailLane = new AudioTrackLane({
        id: 'thumbnail_lane_default_' + k,
        description: waveform.name,
        audioVttFileUrl: waveform.url,
        style: AUDIOTRACK_LINE_STYLES,
      });
      timeline.addLane(defaultThumbnailLane);
    });
  }

  #updateMarker(tcIn: Timecode, tcOut?: Timecode) {
    this.#timelineInitialized$
      .pipe(take(1), withLatestFrom(this.playerService.offset$))
      .subscribe(([timeline, offset]) => {
        const inAndOutMarkersLane = timeline?.getLane('marker_lane_inout_1') as MarkerLane;

        if (!inAndOutMarkersLane) {
          return;
        }

        const inWithOffset = offset ? tcIn.subtract(offset, false) : tcIn;
        const outWithOffset = offset && tcOut ? tcOut.subtract(offset, false) : tcOut;

        inAndOutMarkersLane.clearContent();

        if (!inWithOffset) {
          return;
        }

        const newPeriodMarker = new PeriodMarker({
          id: 'periodMarker1',
          observation: {
            start: inWithOffset?.countSeconds(),
            end: outWithOffset?.countSeconds() ?? inWithOffset.countSeconds(),
          },
          editable: false,
          style: IN_OUT_PERIOD_MARKER_STYLES,
        });

        inAndOutMarkersLane.addMarker(newPeriodMarker as unknown as GenericMarker);
      });
  }

  addCustomRows(customRows: TimelineRow[]) {
    this.#customRows.next([...this.#customRows.value, ...customRows]);
    this.#timelineInitialized$.pipe(take(1)).subscribe((timeline) => {
      if (!timeline) {
        return;
      }

      customRows?.forEach((customRow, index) => {
        const customRowExist = timeline.getLane(customRow.id);
        if (customRowExist) {
          return;
        }

        const markerLine = new MarkerLane({
          id: customRow.id,
          description: customRow.label,
          style: CUSTOM_LINE_STYLES,
        });
        timeline.addLane(markerLine);

        customRow.values.forEach((markerValue, innerIndex) => {
          const periodMarker = markerValue.tcOut
            ? new PeriodMarker({
                id: customRow.id + `_period_marker${innerIndex}`,
                description: markerValue.tooltip,
                observation: {
                  start: markerValue.tcIn,
                  end: markerValue.tcOut,
                },
                editable: false,
                style: CUSTOM_MARKER_PERIOD_STYLES,
              })
            : new MomentMarker({
                id: customRow.id + `_period_marker${innerIndex}`,
                description: markerValue.tooltip,
                observation: {
                  time: markerValue.tcIn,
                },
                editable: false,
                style: CUSTOM_MARKER_MOMENT_STYLES,
              });

          periodMarker.onClick$.subscribe(() => this.customRowClickedEmitter$.next(markerValue));
          markerLine.addMarker(periodMarker as unknown as GenericMarker);
        });
      });
    });
  }

  removeCustomRow(id: string) {
    this.#timeline$.value.timeline?.removeLane(id);

    const withoutDeletedRow = this.#customRows.value.filter((row) => row?.id !== id);
    this.#customRows.next(withoutDeletedRow);
  }

  #resetRows() {
    this.allRows$.pipe(take(1)).subscribe((rows) => {
      rows.forEach(({ id }) => {
        const isNotExist = this.#timeline$.value.timeline?.getLane(id);
        if (isNotExist) {
          return;
        }
        this.#timeline$.value.timeline?.removeLane(id);
      });

      this.#subtitleRows.next([]);
      this.#customRows.next([]);
      this.#audioTrackRows.next([]);
    });
  }

  zoomTo(value: number) {
    this.#timeline$.value.timeline?.zoomTo(value).subscribe();
  }

  #handleError(message: string) {
    this.toast.error({ id: 'error', message });
  }
}
