import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleReleaseNoteDsService } from '../../logic/single-release-note.ds.service';
import { UILoaderModule } from '@vdms-hq/ui';
import { SANITIZE_TYPE, SanitizePipe } from '@vdms-hq/shared';

@Component({
  selector: 'vdms-hq-release-note',
  standalone: true,
  imports: [CommonModule, UILoaderModule, SanitizePipe],
  templateUrl: './release-note.component.html',
  styleUrls: ['./release-note.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReleaseNoteComponent {
  protected readonly SANITIZE_TYPE = SANITIZE_TYPE;
  public releaseNoteDS = inject(SingleReleaseNoteDsService);
  isFetching$ = this.releaseNoteDS.isLoading$;
}
