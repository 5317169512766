import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ThemeSwitcherService, Theme } from '@vdms-hq/theming';
import { Observable, of, switchMap, iif, BehaviorSubject } from 'rxjs';
import { ActionContextLess, DataPresentationHeaderComponent, UILoaderModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { API_CONFIG, ApiConfig } from '@vdms-hq/api-contract';
import { Router } from '@angular/router';

@Component({
  selector: 'vdms-hq-archived-release-notes',
  standalone: true,
  imports: [CommonModule, DataPresentationHeaderComponent, TranslateModule, UILoaderModule],
  templateUrl: './archived-release-notes.component.html',
  styleUrls: ['./archived-release-notes.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArchivedReleaseNotesComponent {
  isLoading$ = new BehaviorSubject(true);
  themeBasedUrl$: Observable<SafeResourceUrl> = this.themeSwitcherService.theme$.pipe(
    switchMap((theme) =>
      iif(
        () => theme === Theme.dark,
        of(this._sanitizer.bypassSecurityTrustResourceUrl(this.env.releaseNotes.dark)),
        of(this._sanitizer.bypassSecurityTrustResourceUrl(this.env.releaseNotes.light)),
      ),
    ),
  );
  actions: ActionContextLess[] = [
    {
      key: 'release-notes',
      label: 'Latest releases',
    },
  ];

  constructor(
    public readonly themeSwitcherService: ThemeSwitcherService,
    private readonly _sanitizer: DomSanitizer,
    private router: Router,
    @Inject(API_CONFIG) private env: ApiConfig,
  ) {}

  onLoad() {
    setTimeout(() => {
      this.isLoading$.next(false);
    }, 2000);
  }

  onAction($event: { key: string }) {
    this.router.navigate([$event.key]);
  }
}
