<vdms-hq-ui-dialog-wrapper>
  <ng-container title>
    <h1>Embargo</h1>
  </ng-container>
  <ng-container content>
    <ng-container [formGroup]="form">
      <ng-container formArrayName="files">
        <div class="row" *ngFor="let file of files.controls">
          <ng-container [formGroup]="file">
            <span>
              {{ file.get('originalFilename')?.value }}
            </span>
            <vdms-hq-ui-form-input-date-time-unix-timestamp-limited
              [placeholder]="'Custom date'"
              [sourceType]="SelectorSourceType.EMBARGO"
              [label]="'Embargo date'"
              formControlName="embargoed_to"
            ></vdms-hq-ui-form-input-date-time-unix-timestamp-limited>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="cancel()">{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button>
    <vdms-hq-ui-button [disabled]="form.invalid" (click)="save()" color="primary">{{
      'common.global.save' | translate
    }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
