import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { UIButtonModule, UIDialogWrapperModule } from '@vdms-hq/ui';

@Component({
  selector: 'vdms-hq-storage-install-aspera-dialog',
  templateUrl: './install-aspera-dialog.component.html',
  styleUrls: ['./install-aspera-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UIButtonModule, UIDialogWrapperModule],
})
export class InstallAsperaDialogComponent {
  constructor(private ref: MatDialogRef<InstallAsperaDialogComponent>) {}

  close() {
    this.ref.close();
  }
}
