import { AsyncPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Timecode } from '@vdms-hq/timecode';
import { filter } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { PlayerService } from '../logic/player.service';

@Pipe({
  name: 'offsetAdd',
  pure: true,
  standalone: true,
})
export class OffsetAddPipe implements PipeTransform {
  constructor(private previewService: PlayerService, private asyncPipe: AsyncPipe) {}

  transform(value: Timecode | string) {
    return this.asyncPipe.transform(
      this.previewService.offset$.pipe(
        take(1),
        filter((offset) => !!offset),
        map((offset) => {
          const timecode = Timecode.fromTimecode(value?.toString(), offset?.getFps());
          const offsetTimecode = Timecode.fromTimecode(offset?.toString(), offset?.getFps());

          if (!timecode || !offsetTimecode) {
            return;
          }

          return timecode.add(offsetTimecode);
        }),
      ),
    );
  }
}
