import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vdms-hq-mfa-reminder-dialog',
  templateUrl: './mfa-reminder-dialog.component.html',
  styleUrls: ['./mfa-reminder-dialog.component.scss'],
})
export class MfaReminderDialogComponent {
  readonly TWO_WEEKS = 1000 * 60 * 60 * 24 * 14;

  constructor(public dialogRef: MatDialogRef<MfaReminderDialogComponent>) {}

  public later(): void {
    this.dialogRef.close({
      mfaReminderDate: new Date(Date.now() + this.TWO_WEEKS),
    });
  }

  public onSubmit(): void {
    this.dialogRef.close({
      mfaReminderDate: null,
    });
  }
}
