import { Pipe, PipeTransform } from '@angular/core';
import { TimecodeService } from '../logic/calculators/timecode.service';

@Pipe({
  name: 'asTimeCode',
})
export class SecondsAsTimeCodePipe implements PipeTransform {
  constructor(private timecodeService: TimecodeService) {}

  transform(value: number, unit = 'ms', offset?: string): unknown {
    value = unit === 'ms' ? value / 1000 : value;

    if (offset) {
      const offsetInSeconds = this.timecodeService.timecodeToSeconds(offset);
      value += offsetInSeconds ? offsetInSeconds : 0;
    }

    return this.timecodeService.secondsToTimecode(value || null);
  }
}
