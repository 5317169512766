import { inject, Injectable } from '@angular/core';
import { AuthService } from '@vdms-hq/auth';
import { MultiViewKey, MultiViewType } from '@vdms-hq/firebase-contract';
import { distinctUntilChanged, Observable } from 'rxjs';
import { DefaultMultiViewType } from '@vdms-hq/ui';
import { map, switchMap, take } from 'rxjs/operators';
import { filterEmpty } from '@vdms-hq/shared';

export enum TABLE_TYPE {
  ASSET = 'assetView',
  COLLECTION = 'collectionsView',
  ORDER = 'ordersView',
  DOWNLOAD = 'downloadsView',
  LICENSED_PACKAGE = 'licensedPackagesView',
  LICENSEES_PARTNER = 'licenseesPartnersView',
  CONTRACT = 'contractsView',
}

@Injectable({ providedIn: 'root' })
export class ViewSettingsService {
  authService = inject(AuthService);

  perPageUserSettings$ = this.authService.userAttributes$.pipe(
    filterEmpty(),
    map((userAttributes) => userAttributes.vida?.preferredPageSize ?? AuthService.defaultPerPage),
    distinctUntilChanged(),
  );

  defaultViewFor$ = (viewKey: MultiViewKey): Observable<DefaultMultiViewType> =>
    this.authService.userAttributesDefinite$.pipe(
      take(1),
      map((userAttributes) => {
        return this.#transformDefaultViewToUI(userAttributes.vida.preferredTableView[viewKey]);
      }),
    );
  saveDefaultViewFor = (viewKey: MultiViewKey, type: DefaultMultiViewType) => {
    return this.authService.userAttributesDefinite$
      .pipe(
        take(1),
        switchMap((userAttributes) => {
          const persistence = this.#transformDefaultViewToPersistence(type);
          const vidaObject = {
            ...(userAttributes?.vida ?? {}),
          };
          vidaObject.preferredTableView[viewKey] = persistence;
          return this.authService.updateAttributes({
            vida: vidaObject,
          });
        }),
      )
      .subscribe();
  };
  #transformDefaultViewToUI(type: MultiViewType): DefaultMultiViewType {
    switch (type) {
      case 'table':
        return 'tableAdvanced';
      case 'grid':
        return 'gridTiles';
      case 'advanced':
        return 'gridAdvanced';
      default:
        return 'tableAdvanced';
    }
  }
  #transformDefaultViewToPersistence(type?: DefaultMultiViewType): MultiViewType {
    switch (type) {
      case 'tableAdvanced':
        return 'table';
      case 'gridTiles':
        return 'grid';
      case 'gridAdvanced':
        return 'advanced';
      default:
        return 'table';
    }
  }
}
