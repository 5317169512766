import { getCookieDomain } from './environment.shared';
import { Environment } from './environment.type';

export const environment = <Environment>{
  version: '3c5578ba6a2a6deaedd0ba56cb2ae8fe64dbc3be',
  production: true,
  type: 'dev',
  firebaseConfig: {
    apiKey: 'AIzaSyCPq-ZREMDMumX38ceVfVVo2Jk0Gc2jJw8',
    authDomain: 'vida-dev-01.firebaseapp.com',
    projectId: 'vida-dev-01',
    storageBucket: 'vida-dev-01.appspot.com',
    messagingSenderId: '598324657990',
    appId: '1:598324657990:web:3e8c28d608b5550b252f47',
    measurementId: 'G-HSZP10MFE4',
  },
  apiUrl: 'https://api.vida-dev-v2.vdms-remote.com',
  websocketUrl: 'wss://websockets.vida-dev-v2.vdms-remote.com/',
  launchpadUrl: 'https://launchpad.vida-dev-v2.vdms-remote.com',
  cookieDomain: getCookieDomain(),
  asperaRemoteHost: 'ams-1.vidatx.net',
  asperaRemoteUser: 'xfer',
  asperaRemotePassword: '$mallClub72',
  asperaDestinationRoot: 'VDMS/',
  asperaSDKLocation: '//d3gcli72yxqn2z.cloudfront.net/connect/v4',
  microsoftEnabled: false,
  microsoftTenantId: '53a24f1c-a975-4f47-8969-de492f993eb2',
  releaseNotes: {
    dark: 'https://docs.google.com/document/d/e/2PACX-1vSLhsgBBXW5gny1hwe4u7fYM-UBHvnUP5C6R5tWMyDqB-OPwpkuyEMEYAxxLupU16AUKzJFlcMaGoPQ/pub?embedded=true',
    light:
      'https://docs.google.com/document/d/e/2PACX-1vRsDi-X9alwvN6ARhll9dMT-Zae_SqLnupX3N4hm-bMMs7RhCaM2sBTTtKjMt3JD6MGptAZngcwCs3z/pub?embedded=true',
  },
  cdn: `https://player.${getCookieDomain()}`,
  voiceAIApiUrl: 'https://api.elevenlabs.io/v1',
  voiceAIApiKey: '8f8e416beaef338ce4ab77bfe286fe09',
  dolbyRaspApiUrl: 'https://api.vida-dev-02.dolbyrasp.com',
  sentryDSN: undefined,
};
