import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataProviderService } from './logic/data-provider.service';
import { SharedModule } from '../shared/shared.module';
import { LanguageFormSelectorComponent } from './components/language-selector/language-form-selector.component';
import { LanguageValueComponent } from './components/language-value/language-value.component';
import { LanguagesProviderService } from './logic/languages-provider.service';

@NgModule({
  declarations: [LanguageFormSelectorComponent, LanguageValueComponent],
  imports: [CommonModule, SharedModule],
  exports: [LanguageFormSelectorComponent, LanguageValueComponent],
  providers: [LanguagesProviderService],
})
export class LanguageModule {
  static forRoot(): ModuleWithProviders<LanguageModule> {
    return {
      ngModule: LanguageModule,
      providers: [DataProviderService],
    };
  }
}
