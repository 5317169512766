import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesComponent } from './components/messages/messages.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ToastService } from './logic/services/toast.service';
import { TOAST_CONFIG, ToastConfig } from './logic/models/message.model';

const exportedComponents = [
  MessagesComponent
];

const modules = [
  CommonModule,
  MatIconModule,
  MatProgressSpinnerModule,
]

const defaultConfig: ToastConfig = {
  durationInSeconds: 5,
  position: 'bottom-right',
  isCloseButton: true,
}

@NgModule({
  declarations: exportedComponents,
  imports: modules,
  exports: exportedComponents,
})
export class ToastModule {
  static forRoot(config: ToastConfig): ModuleWithProviders<ToastModule> {
    const providers: Provider[] = [
      ToastService,
      {
        provide: TOAST_CONFIG,
        useValue: config || defaultConfig,
      }
    ];
    return {
      ngModule: ToastModule,
      providers
    }
  }
}
