import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';
import { SelectOption, SelectOptionKey } from '@vdms-hq/shared';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { calculateColumn } from '../form-sortable-checkbox-list/column-calculator';

type OutsideValue = SelectOptionKey[] | null;
type InsideValue = SelectOptionKey[];

@Component({
  selector: 'vdms-hq-ui-form-checkbox-list',
  templateUrl: './form-checkbox-list.component.html',
  styleUrls: ['../../styles/columns.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormCheckboxListComponent),
    },
  ],
})
export class FormCheckboxListComponent extends FormControlValueAccessorComponent<OutsideValue, InsideValue> {
  innerFormControl = new UntypedFormControl([]);
  #availableOptions: SelectOption[] = [];
  total = 0;
  @Input() set available(available: SelectOption[]) {
    this.#availableOptions = available;
    this.total = available.length;
    setTimeout(() => this.#recalculateLists());
  }

  @Input() orientation: 'horizontal' | 'vertical' = 'vertical';
  @Input() checkboxVisible = true;
  @Input() disabled = false;
  @Input() highlightOnChecked = false;
  @Input() columns: 1 | 3 = 3;
  @Input() loading = false;
  lists: { [key: number]: SelectOption[] } = {};

  get listsArray() {
    return new Array(this.columns);
  }

  toggleOption(item: SelectOption) {
    let newValue;
    const prevValue = this.innerFormControl.value;
    if (prevValue.includes(item.key)) {
      newValue = prevValue.filter((value: SelectOptionKey) => value !== item.key);
    } else {
      newValue = [...prevValue, item.key];
    }

    this.innerFormControl.setValue(newValue);
  }

  #recalculateLists() {
    for (let listIndex = 0; listIndex < this.columns; listIndex++) {
      this.lists[listIndex] = this.#availableOptions.filter(
        (item) =>
          calculateColumn(
            item.key,
            this.#availableOptions.map((available) => available.key),
            this.columns,
          ) === listIndex,
      );
    }
    this.changeDetectorRef.markForCheck();
  }

  override transformOuterToInner(value: OutsideValue): InsideValue {
    if (!Array.isArray(value)) {
      value = [];
    }

    return value;
  }
}
