import { APP_INITIALIZER, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientSwitchComponent } from './components/client-switch/client-switch.component';
import { ClientSwitchViewComponent } from './components/client-switch/client-switch-view/client-switch-view.component';
import { RequiresPermissionsDirective } from './directives/requires-permissions.directive';
import { UIFormModule, UIModule } from '@vdms-hq/ui';
import { ACTIVATED_CLIENT_CONFIG_TOKEN, ActivatedClientConfig } from './logic/models/activated-client-config.model';
import { RequiresPermissionsGuard } from './logic/guards/requires-permissions.guard';
import { ActivatedClientService } from './logic/services/activated-client.service';
import { initialize } from './logic/activated-client-initializer';
import { ActivatedClientForHostnameService } from './logic/services/activated-client-for-hostname.service';

@NgModule({
  imports: [CommonModule, UIModule, UIFormModule],
  declarations: [ClientSwitchComponent, ClientSwitchViewComponent, RequiresPermissionsDirective],
  exports: [ClientSwitchComponent, RequiresPermissionsDirective],
  providers: [RequiresPermissionsGuard],
})
export class ActivatedClientModule {
  static forRoot(activatedClientConfig: ActivatedClientConfig): ModuleWithProviders<ActivatedClientModule> {
    const providers: Provider[] = [
      {
        provide: ACTIVATED_CLIENT_CONFIG_TOKEN,
        useValue: activatedClientConfig,
      },
      {
        provide: APP_INITIALIZER,
        useFactory: initialize,
        deps: [ActivatedClientService, ActivatedClientForHostnameService],
        multi: true,
      },
    ];

    return {
      ngModule: ActivatedClientModule,
      providers,
    };
  }
}
