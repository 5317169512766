import { AuthorizedClient, Permission } from '@vdms-hq/activated-client';

export function adminNavItemsConfig(client: AuthorizedClient) {
  const configs = Object.entries(client.assetView).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));
  const libraryConfigs = client.libraryConfigs ?? [
    {
      id: 'legacy-default',
      isClient: false,
      name: 'Client legacy default',
      structure: client.library.structure,
    },
  ];

  const navItems = [
    {
      name: 'View configuration',
      routerLink: '/admin/general',
    },
    {
      name: 'Storage and Database',
      routerLink: '/admin/storage',
    },
    {
      name: 'Asset Tables and Lists',
      routerLink: '/admin/asset-tables/default',
      children: [
        {
          name: 'Default',
          routerLink: '/admin/asset-tables/default',
        },
        {
          name: 'Deleted',
          routerLink: '/admin/asset-tables/deleted',
        },
        {
          name: 'Browse',
          routerLink: '/admin/asset-tables/browse',
        },
        {
          name: 'Cart',
          routerLink: '/admin/asset-tables/cart',
        },
        {
          name: 'Collections',
          routerLink: '/admin/asset-tables/collections',
        },
        {
          name: 'Downloads',
          routerLink: '/admin/asset-tables/shared-packs',
        },
        {
          name: 'Orders',
          routerLink: '/admin/asset-tables/orders',
        },
        {
          name: 'Upload jobs',
          routerLink: '/admin/asset-tables/upload-jobs',
        },
        {
          name: 'Content Corner',
          routerLink: '/admin/asset-tables/content-corner',
        },
        {
          name: 'Licensed Packages',
          routerLink: '/admin/asset-tables/license_package',
        },
      ],
    },
    {
      name: 'Asset filters',
      routerLink: '/admin/asset-filters/browse',
      children: [
        {
          name: 'Browse',
          routerLink: '/admin/asset-filters/browse',
        },
        {
          name: 'Deleted',
          routerLink: '/admin/asset-filters/deleted',
        },
        {
          name: 'Quarantined',
          routerLink: '/admin/asset-filters/quarantined',
        },
      ],
    },
    {
      name: 'Asset Details & Form',
      routerLink: '/admin/asset-details/',
      children: [
        ...configs.map(([key, config]) => ({
          name: config.name,
          routerLink: `/admin/asset-details/${key}`,
        })),
        'separator',
        {
          name: 'Add new',
          routerLink: '/admin/asset-details/add',
        },
      ],
    },
    {
      name: 'Field definitions',
      routerLink: '/admin/fields',
    },
    {
      name: 'Field sources',
      routerLink: '/admin/field-sources',
    },
    {
      name: 'Library browser',
      routerLink: '/admin/browse-library',
      children: [
        ...libraryConfigs.map((schema) => ({
          name: schema.name,
          routerLink: `/admin/browse-library/${schema.id}`,
        })),
        'separator',
        {
          name: 'Add new',
          routerLink: '/admin/browse-library/add',
        },
      ],
    },
    {
      name: 'Settings snapshots',
      routerLink: 'settings-snapshots',
    },
    {
      name: 'Client languages',
      routerLink: 'languages',
    },
    {
      name: 'Video Codecs',
      routerLink: 'video-codecs',
    },
    {
      name: 'Filename Conventions',
      routerLink: 'metadata-recognition',
    },
    {
      name: 'Content Corner',
      routerLink: 'content-corner-admin',
    },
    {
      name: 'Reindex Automatization',
      routerLink: 'reindex-automatization',
    },
    {
      name: 'Notification Subscriptions',
      routerLink: 'notification-subscriptions',
    },
    {
      name: 'Mandatory fields',
      routerLink: 'mandatory-fields',
    },
    {
      name: 'Aspera Keys',
      routerLink: 'aspera-keys',
    },
    {
      name: 'Assets metadata snapshots',
      routerLink: 'metadata-snapshots',
    },
  ];

  if (client.permissions.includes(Permission.PERMISSION_BROWSE_QA_CONFIGS)) {
    navItems.push({
      name: 'QA Configurations',
      routerLink: 'quality-control',
    });
  }

  if (client.permissions.includes(Permission.USER_ADMIN_BROWSE)) {
    navItems.push({
      name: 'Client User Admin',
      routerLink: 'client-user-admin',
    });
  }

  return navItems;
}
