import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';

@Pipe({
  name: 'objectValueByPath',
})
export class ObjectValueByPathPipe implements PipeTransform {
  transform<T, R extends null>(object: T, path?: string, fallback: string | null = null): R {
    if (!path) {
      return object as unknown as R;
    }

    return get(object as Record<any, any>, path, fallback) as R;
  }
}
