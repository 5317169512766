import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { isCompositeItem, isSeparator, Separator, SidebarItem, SidebarNestedItem } from '../../models/sidebar.model';
import { castTo } from '@vdms-hq/shared';

@Component({
  selector: 'vdms-hq-ui-inner-sidebar-menu',
  templateUrl: './inner-sidebar-menu.component.html',
  styleUrls: ['./inner-sidebar-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InnerSidebarMenuComponent {
  @Input() navItems: (SidebarItem | SidebarNestedItem)[] = [];
  @Input() loading = false;
  @Input() actionLink: { label: string; key: string } | null = null;
  @Output() action = new EventEmitter<{ key: string }>();
  $composite = castTo<SidebarNestedItem>();
  isNestedItem = isCompositeItem;
  trackBy = (index: number, item: SidebarItem | Separator) => (isSeparator(item) ? item : item.name + item.routerLink);
}
