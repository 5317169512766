export const sortByKey = (sortBy: string, array: any, direction: string) => {
  return array.sort((a: any, b: any) => {
    if (!a[sortBy] || a[sortBy] === null) {
      return 1;
    }
    if (!b[sortBy] || b[sortBy] === null) {
      return -1;
    }
    if (a[sortBy] < b[sortBy]) {
      return direction === 'asc' ? -1 : 1;
    }
    if (a[sortBy] > b[sortBy]) {
      return direction === 'asc' ? 1 : -1;
    }
    return 0;
  });
};
