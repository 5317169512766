<div class="timecodes">
  <ng-container *ngIf="dataSource">
    <vdms-hq-ui-loader
      *ngIf="dataSource?.isLoading$ || EMPTY | async"
      [backdrop]="true"
      mode="over-parent"
    ></vdms-hq-ui-loader>
  </ng-container>

  <vdms-hq-ui-empty-results *ngIf="dataSource.emptyResults$ || EMPTY | async"></vdms-hq-ui-empty-results>
  <ng-container *ngIf="dataSource.total$.value && (dataSource.allData$ | async); let timeCodeData">
    <div class="timecodes__table__header">
      <div *ngFor="let column of columns" class="timecodes__table__header__cell">
        <ng-container *ngIf="enabledColumns.includes(column.id)"> {{ $asDataValueColumn(column).label }}</ng-container>
      </div>
    </div>
    <cdk-virtual-scroll-viewport itemSize="50" class="timecodes__table">
      <ng-container *cdkVirtualFor="let row of timeCodeData">
        <div
          class="timecodes__table__row"
          [class.active]="
            currentFrame
              | isBetween
                : row?.timestamp?.timecodeIn?.countFrames() ?? 0
                : (row?.timestamp?.timecodeIn?.countFrames() ?? 0) + 100
          "
          *ngIf="$metadataItem(row); let row"
        >
          <div
            class="timecodes__table__cell border-left"
            [ngClass]="row.pill"
            *ngFor="let enabledColumn of enabledColumns"
          >
            <ng-container [ngSwitch]="enabledColumn">
              <div class="timecodes__table__cell__value" *ngSwitchCase="'type'">
                {{ row.type | translateType : row?.languageKey }}
              </div>
              <div class="timecodes__table__cell__value grey-text mw-175" *ngSwitchCase="'timestamp'">
                <div
                  class="timecodes__table__cell__value--arrow grey-text"
                  [class.clickable-timecode]="row.timestamp.timecodeIn"
                  (click)="onTimecodeClick(row?.timestamp?.timecodeIn, row?.boundingBoxes)"
                >
                  <mat-icon class="icon">arrow_downward</mat-icon>
                  {{ row.timestamp.timecodeIn ?? '__:__:__:__' | offsetAdd }}
                </div>
                <div
                  class="timecodes__table__cell__value--arrow grey-text clickable-timecode"
                  *ngIf="row.timestamp.timecodeOut"
                  (click)="onTimecodeClick(row.timestamp.timecodeOut, row?.boundingBoxes)"
                >
                  <mat-icon class="icon">arrow_upward</mat-icon>
                  {{ row.timestamp.timecodeOut | offsetAdd }}
                </div>
              </div>
              <div class="timecodes__table__cell__value grey-text" *ngSwitchCase="'duration'">
                {{ row.duration }}
              </div>
              <div
                class="timecodes__table__cell__value grey-text"
                *ngSwitchCase="'content'"
                [innerHTML]="row.content"
              ></div>
              <div class="timecodes__table__cell__value grey-text" *ngSwitchCase="'note'">
                <div
                  class="show-on-timeline"
                  *ngIf="
                    (row.type === StreamsType.MI_OBJECTS_RECOGNITION ||
                      row.type === StreamsType.MI_CELEBRITY_RECOGNITION ||
                      row.type === StreamsType.CONTENT_MODERATION ||
                      row.type === StreamsType.MI_SUBTITLES) &&
                    isShowOnTimeline
                  "
                >
                  <vdms-hq-show-on-timeline
                    [type]="row.type"
                    [content]="row.content"
                    [languageKey]="row?.languageKey ?? ''"
                    [vttPath]="row?.vttPath ?? ''"
                    [selected]="row.onTimeline"
                    [allData]="timeCodeData"
                  ></vdms-hq-show-on-timeline>
                </div>
                {{ row?.note ?? 100 | confidenceNoteType }}
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  </ng-container>
</div>
