import { Inject, Pipe, PipeTransform } from '@angular/core';
import { FieldDefinitionModel, OptionalPrimitive, ValueFormatter } from '@vdms-hq/shared';
import { APPLIED_FILTER_VALUE_FORMATTER } from '../models/applied-filters.config';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'appliedFilterFormat',
})
export class AppliedFilterFormatPipe implements PipeTransform {
  constructor(@Inject(APPLIED_FILTER_VALUE_FORMATTER) private valueFormatter: ValueFormatter) {}

  transform<T>(value: T, definition: FieldDefinitionModel): Observable<OptionalPrimitive> {
    if (typeof value === 'string' && ['null', '-1'].includes(value.toString().toLowerCase())) {
      return of(null);
    }
    const formattedValue = this.valueFormatter.format(value, { ...definition, valueFormat: definition.format });

    if (['string', 'number', 'boolean', 'undefined'].includes(typeof formattedValue)) {
      if (typeof formattedValue == 'string' && formattedValue.toLowerCase() === 'subtitle')
        return of('Timed Text' as OptionalPrimitive);
      return of(formattedValue as OptionalPrimitive);
    }
    if (typeof formattedValue === 'object' && definition.input?.type === 'select_group' && typeof value === 'string') {
      return of(value.toString() as OptionalPrimitive);
    }
    return formattedValue as Observable<OptionalPrimitive>;
  }
}
