import { createReducer, on } from '@ngrx/store';

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MessageModel } from '../models/message.model';
import * as messagesActions from '../actions/message.action';

export type MessageState = EntityState<MessageModel>;

const selectId = (a: MessageModel): string => a.id;
const sortComparer = (a: MessageModel, b: MessageModel): number =>
  b.createdAt.getMilliseconds() - a.createdAt.getMilliseconds();

export const adapter: EntityAdapter<MessageModel> = createEntityAdapter<MessageModel>({ selectId, sortComparer });

export const initialState: MessageState = adapter.getInitialState({});

export const messagesReducer = createReducer<MessageState>(
  initialState,
  on(messagesActions.reset, () => ({ ...initialState })),
  on(messagesActions.messageAdd, (state, { data }) => adapter.upsertOne(data, state)),
  on(messagesActions.messageUpdate, (state, { data, id }) => adapter.updateOne({ id, changes: data }, state)),
  on(messagesActions.messageClose, (state, { id }) => adapter.removeOne(id, state)),
);

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectMessageIds = selectIds;
export const selectMessageEntities = selectEntities;
export const selectAllMessages = selectAll;
export const selectMessageTotal = selectTotal;
