import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Workflow } from '@vdms-hq/api-contract';
import { Framerate } from '@vdms-hq/timecode';
import { AllListStreamsType, AssetViewComponent } from '@vdms-hq/firebase-contract';

export type MetadataListConfig = {
  framerate: Framerate;
  workflow?: Workflow;
  assetUuid: string;
  defaultStream: AllListStreamsType[];
  enabledStreams: AllListStreamsType[];
  enabledComponents: AssetViewComponent[];
};

@Injectable({ providedIn: 'root' })
export class MetadataListInputService {
  config$ = new ReplaySubject<MetadataListConfig>();

  load(params: MetadataListConfig) {
    this.config$.next({
      assetUuid: params.assetUuid,
      framerate: params.framerate,
      workflow: params.workflow ?? ({} as Workflow),
      defaultStream: params.defaultStream ?? [],
      enabledStreams: params.enabledStreams ?? [],
      enabledComponents: params.enabledComponents ?? [],
    });
  }
}
