<div class="load-more" (click)="loadMore.emit(true)" *ngIf="total > currentLoaded">
  <vdms-hq-ui-button color="transparent" [disabled]="isLoading">
    {{
      isLoading
        ? ('common.load_more.label_loading'
          | translate : { total: total - currentLoaded < batchSize ? total - currentLoaded : batchSize })
        : ('common.load_more.label'
          | translate : { total: total - currentLoaded < batchSize ? total - currentLoaded : batchSize })
    }}
  </vdms-hq-ui-button>

  <div class="sub-label">
    {{ 'common.load_more.loaded_label' | translate : { loaded: currentLoaded, total: total } }}
  </div>
  <vdms-hq-ui-loader *ngIf="isLoading"></vdms-hq-ui-loader>
</div>
