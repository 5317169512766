import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { RecaptchaVerifier } from 'firebase/auth';
import { getAuth } from '@angular/fire/auth';
import { DOCUMENT } from '@angular/common';
import { ThemeSwitcherService } from '@vdms-hq/theming';

@Injectable({
  providedIn: 'root',
})
export class ReCaptchaService {
  private renderer: Renderer2;

  #globalCaptcha: {
    recaptcha: RecaptchaVerifier;
    container: HTMLElement;
  } | null = null;

  #captchas: Record<string, RecaptchaVerifier> = {};

  readonly #globalId = 'recaptcha-container';

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private themeService: ThemeSwitcherService,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  createCaptcha = (containerId?: string) => {
    if (!containerId) {
      return this.#getOrCreateGlobalCaptcha();
    } else {
      return this.#createFreshCaptcha(containerId);
    }
  };

  removeCaptcha = (containerId?: string) => {
    if (!containerId) {
      return this.#removeGlobalCaptcha();
    } else {
      return this.#removeVisibleCaptcha(containerId);
    }
  };

  #createHostElement = (id: string) => {
    const recaptchaContainer = this.renderer.createElement('div');

    this.renderer.setProperty(recaptchaContainer, 'id', id);
    this.renderer.appendChild(this.document.body, recaptchaContainer);
    return recaptchaContainer;
  };

  #createGlobalCaptcha(isInvisible = true) {
    const container = this.#createHostElement(this.#globalId);

    const authService = getAuth();

    const recaptcha = new RecaptchaVerifier(
      this.#globalId,
      {
        theme: this.themeService.theme$.value === 'theme-dark' ? 'dark' : 'light',
        size: isInvisible ? 'invisible' : undefined,
      },
      authService,
    );

    this.#globalCaptcha = {
      recaptcha,
      container,
    };
  }

  #getOrCreateGlobalCaptcha() {
    if (!this.#globalCaptcha) {
      this.#createGlobalCaptcha();
    }

    if (!this.#globalCaptcha) {
      throw 'Unable to create captcha';
    }

    return this.#globalCaptcha?.recaptcha;
  }

  #createFreshCaptcha(containerId: string, isInvisible = true) {
    const captcha = this.#captchas[containerId];
    if (captcha) {
      this.removeCaptcha(containerId);
    }

    const authService = getAuth();
    this.#captchas[containerId] = new RecaptchaVerifier(
      containerId,
      {
        theme: this.themeService.theme$.value === 'theme-dark' ? 'dark' : 'light',
        size: isInvisible ? 'invisible' : undefined,
      },
      authService,
    );

    const createdCaptcha = this.#captchas[containerId];
    if (!createdCaptcha) {
      throw 'Unable to create captcha';
    }
    return createdCaptcha;
  }

  #removeGlobalCaptcha() {
    const captcha = this.#globalCaptcha;
    if (!captcha) {
      return;
    }
    if (captcha) {
      captcha.recaptcha.clear();
      this.renderer.removeChild(parent, captcha.container);
    }

    this.#globalCaptcha = null;
  }

  #removeVisibleCaptcha(containerId: string) {
    const captcha = this.#captchas[containerId];
    if (!captcha) {
      return;
    }

    captcha.clear();

    delete this.#captchas[containerId];
  }
}
