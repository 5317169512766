import { Pipe, PipeTransform } from '@angular/core';
import { Column, SelectColumn } from '../logic/column-config';

@Pipe({
  name: 'isColumnSelect',
  standalone: true,
})
export class IsColumnSelectPipe implements PipeTransform {
  static isColumnSelect(column: Column): false | SelectColumn {
    return 'type' in column && column.type === 'select' ? (column as SelectColumn) : false;
  }

  transform(column: Column) {
    return IsColumnSelectPipe.isColumnSelect(column);
  }
}
