import { Component, Input, ViewChild } from '@angular/core';
import { AssetSearchService, PersistenceSearchParams } from '@vdms-hq/api-contract';
import { Observable } from 'rxjs';
import { filterEmpty, SelectOption } from '@vdms-hq/shared';
import { AuthService } from '@vdms-hq/auth';
import { map, startWith, take } from 'rxjs/operators';
import { LastFiltersService } from '../../../logic/services/last-filters.service';
import { ReturnButtonService } from '../../../logic/services/return-button.service';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { SuffixConfig } from '@vdms-hq/ui';

@Component({
  selector: 'app-simple-query-input',
  templateUrl: './simple-query-input.component.html',
  styleUrls: ['./simple-query-input.component.scss'],
})
export class SimpleQueryInputComponent {
  currentQuery = '';
  @Input() placeholder = 'pages.home.search.placeholder';
  @Input() alternativeAppearance = false;
  @Input() displayReturnButton = true;
  @Input() displaySearchButton = true;

  @ViewChild('input', { read: NgModel }) input: NgModel;

  suffixConfig$: Observable<SuffixConfig | null> = this.returnButtonService.visible$.pipe(
    startWith(false),
    map((visibility: boolean) => {
      const returnButtonVisible = visibility && this.displayReturnButton;
      const searchButtonVisible = this.displaySearchButton;

      if (returnButtonVisible) {
        return {
          text: 'Back to results',
          onFocus: false,
          type: 'secondary',
        };
      }

      if (searchButtonVisible) {
        return {
          text: 'Search',
          onFocus: true,
          type: 'primary',
        };
      }

      return null;
    }),
  );
  latestQueries$: Observable<SelectOption[]> = this.lastFiltersService.getSavedSearchesSelectOption$();

  constructor(
    public assetSearchService: AssetSearchService,
    private returnButtonService: ReturnButtonService,
    private authService: AuthService,
    private lastFiltersService: LastFiltersService,
    private router: Router,
  ) {}

  updateQuery() {
    if (this.displayReturnButton && this.returnButtonService.savedRouteBeforeLeave$.value && !this.currentQuery) {
      const previousRoute = '/browse/' + this.returnButtonService.savedRouteBeforeLeave$.value || '/browse/all';
      this.router.navigateByUrl(previousRoute);
      return;
    }

    if (this.input.invalid) {
      return;
    }

    this.authService.userAttributes$.pipe(filterEmpty(), take(1)).subscribe((attributes) => {
      this.assetSearchService.applyParams(
        PersistenceSearchParams.initialQuery(this.currentQuery, attributes.vida.preferredPageSize),
      );
    });
  }

  removeAutocompleteItem(item: SelectOption) {
    this.lastFiltersService.removeSavedSearchPhrase(item);
  }

  keyUp($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.updateQuery();
    }
  }
}
