import { Injectable } from '@angular/core';
import { Pagination } from '@vdms-hq/ui';
import { CollectionAssetViewModel } from '../models/collection-asset-view.model';
import { BehaviorSubject, catchError, combineLatest, shareReplay, switchMap } from 'rxjs';
import { DataProviderService } from '@vdms-hq/selectors';
import { StorageUrlService } from '@vdms-hq/storage';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { FieldsConfigService } from '@vdms-hq/config';
import { PageEvent } from '@angular/material/paginator';
import { CollectionItemGet, CollectionsService, SortOptions } from '@vdms-hq/api-contract';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { FieldConfigId, SelectionManager } from '@vdms-hq/shared';
import { TableAdvancedDataSource } from '@vdms-hq/ui';
import { SingleCollectionResolverService } from '../services/single-collection-resolver.service';
import { ToastService } from '@vdms-hq/toast';
import { AssetFiltersForm } from '../services/collection-assets-filter.service';
import { CollectionsRefresh } from '../utils/collections-refresh';
import { ParamsPagination } from '@vdms-hq/view-settings';

@Injectable({ providedIn: 'root' })
export class SingleCollectionAssetsDataSource
  extends ParamsPagination
  implements TableAdvancedDataSource<CollectionAssetViewModel>
{
  isLoading$ = new BehaviorSubject<boolean>(true);
  total$ = new BehaviorSubject<number>(0);

  #refresh$ = this.collectionsRefresher.refreshAssets$;

  sortBy$ = new BehaviorSubject<FieldConfigId>('');
  sortDirection$ = new BehaviorSubject<SortOptions['direction']>('asc');
  filters$ = new BehaviorSubject<Partial<AssetFiltersForm>>({});

  selection: SelectionManager<CollectionAssetViewModel>;
  uuid$ = this.collectionIdParamResolver.currentIdDefinite$;

  allData$ = combineLatest([
    this.pageIndex$,
    this.pageSize$,
    this.sortBy$,
    this.sortDirection$,
    this.filters$,
    this.uuid$,
    this.#refresh$,
  ]).pipe(
    withLatestFrom(this.fieldsConfigService.resultsDefinitions$.pipe(take(1))),
    switchMap(([[page, perPage, orderBy, orderDir, filters, uuid], fieldsDefinitions]) => {
      const sort = fieldsDefinitions.find((field) => {
        return field.id == orderBy;
      });

      const pagination = Pagination.create({
        page,
        perPage,
        orderBy: sort?.results2?.sortObjectPath ?? sort?.results2?.objectPath ?? orderBy,
        orderDir,
      });

      return this.collectionsService
        .getAssets(uuid, pagination, {
          filter: filters.text,
          'tx-date-from': filters.txDate?.from?.toISOString(),
          'tx-date-to': filters.txDate?.to?.toISOString(),
          'facility-of-origin': filters.facilityOfOrigin,
        })
        .pipe(
          map((response) => {
            const data = response.data.map((item: CollectionItemGet) =>
              CollectionAssetViewModel.fromSingleCollectionAsset(item, {
                dataProvider: this.dataProvider,
                storageUrlService: this.storageUrlService,
                activatedClientService: this.activatedClientService,
              }),
            );
            return {
              ...response,
              data,
            };
          }),
          catchError(() => {
            this.isLoading$.next(false);
            this.toastService.error({
              id: 'collection',
              message: 'Failed to load collection assets. Please try again.',
            });
            return [];
          }),
        );
    }),
    map(({ data, total }) => {
      this.total$.next(total);
      this.isLoading$.next(false);

      return data;
    }),
    shareReplay(1),
  );

  connection$ = this.allData$;

  emptyResults$ = combineLatest([this.isLoading$, this.total$]).pipe(
    map(([isLoading, total]) => {
      return isLoading === false && total === 0;
    }),
  );

  constructor(
    private collectionsService: CollectionsService,
    private collectionsRefresher: CollectionsRefresh,
    private collectionIdParamResolver: SingleCollectionResolverService,
    private dataProvider: DataProviderService,
    private storageUrlService: StorageUrlService,
    private activatedClientService: ActivatedClientService,
    private fieldsConfigService: FieldsConfigService,
    private toastService: ToastService,
  ) {
    super();
    this.selection = new SelectionManager<CollectionAssetViewModel>(this, (item) => item.context.uuid);
  }

  override async pageChange($event: PageEvent) {
    await super.pageChange($event);
    this.isLoading$.next(true);
  }

  sortChange($event: { active: string; direction: SortOptions['direction'] }) {
    this.isLoading$.next(true);
    this.sortBy$.next($event.active);
    this.sortDirection$.next($event.direction);
    this.changePageIndex$.next(0);
  }

  applyFilters(filters: AssetFiltersForm) {
    this.filters$.next(filters);
  }
}
