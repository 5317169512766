import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { SupportedExtension, supportedExtensions } from '../../../ui-preview';
import { PreviewUISettings } from '../../../ui-preview/supports/any-file.component';
import { FileDestination } from '@vdms-hq/shared';

interface SampleFile {
  bucketPath: string;
  extension: SupportedExtension | string;
}

interface SampleFileWithUrl extends SampleFile {
  url: string;
}

const filesOnBucket = [
  'csv_medium.csv',
  'csv_small.csv',
  'image_square.jpg',
  'sheet_medium_2.xlsx',
  'sheet_big.xlsx',
  'document_large.docx',
  'document_large.odt',
  'pdf_small.pdf',
  'image.png',
  'audio-29s.wav',
  'pdf_medium.pdf',
  'audio-9s.mp3',
  'image_vertical.jpeg',
  'image_horizontal.jpg',
  'animation.gif',
  'image_4k.bmp',
  'video.mp4',
  'video.mov',
  'sheet_medium.xlsx',
  'sheet_small.ods',
  'doc_small_image.docx',
  'video-horizontal.mp4',
  'doc_small.odt',
  'sample.xml',
  'sample.yml',
  'sample.srt',
  'sample.txt',
];

@Component({
  selector: 'vdms-hq-ui-preview-usage',
  templateUrl: './preview-usage.component.html',
  styleUrls: ['preview-usage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewUsageComponent implements OnInit {
  supportedExtensions = supportedExtensions;
  #cached: Record<string, string> = {};
  settings?: PreviewUISettings;
  files: SampleFile[] = filesOnBucket.map((fileName) => ({
    extension: fileName.split('.').pop()?.trim() as SupportedExtension,
    bucketPath: `/ui-samples/${fileName}`,
  }));

  selectedFile?: SampleFileWithUrl;

  constructor(private storage: AngularFireStorage, private cdr: ChangeDetectorRef) {}

  async ngOnInit(): Promise<void> {
    await this.select(this.files[0]);
  }

  async getLink(path: string) {
    if (!this.#cached[path]) {
      const fileRef = this.storage.ref(path);

      this.#cached[path] = await firstValueFrom(fileRef.getDownloadURL().pipe(take(1)));
    }
    return this.#cached[path];
  }

  async select(file: SampleFile) {
    const url = await this.getLink(file.bucketPath);

    const fileDestination = FileDestination.fromUrl(url);

    if (!fileDestination) {
      return;
    }
    this.settings = {
      file: fileDestination,
      includeCookiesInRequest: true,
      pdfWithToolbar: true,
      pdfWithNavPanes: true,
    };

    this.cdr.markForCheck();
  }
}
