import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'vdms-hq-ui-grid-item-header',
  templateUrl: './grid-item-header.component.html',
  styleUrls: ['./grid-item-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  encapsulation: ViewEncapsulation.None,
})
export class GridItemHeaderComponent {
  @Input() title!: string;
}
