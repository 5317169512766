import { DataColumn } from '@vdms-hq/ui';

export const hotColdLogsTableColumns: DataColumn[] = [
  {
    id: 'log_id',
    label: 'Log ID',
    valuePath: 'id',
    sortable: false,
  },
  {
    id: 'created_date',
    label: 'Created Date',
    valuePath: 'createdAt',
    viewFormat: {
      modifiers: {
        dateFormat: 'date-time',
      },
    },
    sortable: false,
  },
  {
    id: 'log_type',
    label: 'Changed to',
    valuePath: 'storageChangeDirection',
    viewFormat: {
      pills: {
        cold: 'cold-blue',
        hot: '',
      },
    },
    sortable: false,
  },
  {
    id: 'message',
    label: 'Message',
    valuePath: 'message',
    sortable: false,
  },
];
