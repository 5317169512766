import { Injectable, TrackByFunction } from '@angular/core';
import { LoadableDataSource, LocalDataSource, PageableDataSource, SortableDataSource } from '@vdms-hq/shared';
import { ClientsFetcher } from './clients-fetcher';
import { Client } from '@vdms-hq/api-contract';
import { ViewSettingsService } from '@vdms-hq/view-settings';

@Injectable()
export class ClientsDataSource
  extends LocalDataSource<Client>
  implements SortableDataSource, PageableDataSource, LoadableDataSource
{
  isLoading$ = this.clientsFetcher.loading$;

  public trackBy: TrackByFunction<Client> = (index: number, client: Client) => client.uuid;

  constructor(private readonly clientsFetcher: ClientsFetcher, private viewSettingsService: ViewSettingsService) {
    super(clientsFetcher.clients$);
    this.viewSettingsService.perPageUserSettings$.subscribe((perPage) => {
      this.pageSize$.next(perPage);
    });
  }

  refresh() {
    this.clientsFetcher.refresh$.next(true);
  }
}
