import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import * as docx from 'docx-preview';
import { AnyFileComponent } from '../any-file.component';

@Component({
  selector: 'vdms-hq-ui-doc-support',
  templateUrl: './doc-support.component.html',
  styleUrls: ['./doc-support.component.scss', './../any-file.component.scss'],
})
export class DocSupportComponent extends AnyFileComponent implements OnDestroy, AfterViewInit {
  static supportsExtensions = ['docx'] as const;
  private resizeObserver?: ResizeObserver;

  @ViewChild('docPlaceholder', { read: ElementRef }) docPlaceholder?: ElementRef<HTMLElement>;
  @ViewChild('docParent', { read: ElementRef }) docParent?: ElementRef<HTMLElement>;
  scale = 'scale(1)';

  ngOnDestroy(): void {
    if (!this.docParent?.nativeElement) {
      return;
    }
    this.resizeObserver?.unobserve(this.docParent?.nativeElement);
  }

  async ngAfterViewInit(): Promise<void> {
    if (!this.docParent?.nativeElement) {
      return;
    }

    this.resizeObserver = new ResizeObserver((entries) => {
      const parentWidth = entries[0].target.clientWidth;
      const doc = 800;

      this.scale = `scale(${parentWidth / doc})`;

      this.cdr.markForCheck();
    });

    const buffer = await this.fetchBuffer();

    if (buffer && this.docPlaceholder?.nativeElement) {
      await docx.renderAsync(buffer, this.docPlaceholder?.nativeElement);
    }

    this.resizeObserver.observe(this.docParent.nativeElement);
    this.loaded();
  }
}
