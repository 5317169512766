export enum FieldsEndpoints {
  ROOT = 'fields',
}

export enum OptionsEndpoints {
  ALL = 'options',
  ONE = 'option',
}

export enum FieldOptionsEndpoints {
  ALL_TYPES = 'field-types',
  ONE_TYPE = 'field-type',
  ONE = 'field',
  ALL = 'all',
}
