import { Injectable } from '@angular/core';
import {
  FilterStreamMsgFactory,
  generateProgressToastMessage,
  IMAGE_CACHE_ENUM,
  ImageCacheService,
  WebsocketCollectionNotificationMessage,
  WebsocketNotificationActionEnum,
  WsProgressToast,
} from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import { StreamService } from '@vdms-hq/api-contract';
import { AdminSubscriptionsDatasource } from '../logic/admin-subscriptions.datasource';

@Injectable()
export class AdminSubscriptionWebsocketUpdatesService {
  readonly connect$ = this.streamService.connect();
  popToast = {
    WS_RESTART_PROGRESS: (
      id: string,
      percent: number,
      counter?: { processing: number; all: number; errors: WebsocketCollectionNotificationMessage },
    ) =>
      this.toastService.processing({
        id,
        message: generateProgressToastMessage(
          'subscriptions',
          percent,
          this.imageCache.getImage(IMAGE_CACHE_ENUM.PROGRESS_LOADER_INFO_ICON),
          counter,
        ),
      }),
    WS_RESTART_SUCCESS: (id: string) =>
      this.toastService.success({
        id,
        message: `Subscriptions updating success`,
      }),
    WS_RESTART_ERROR: (id: string) =>
      this.toastService.error({
        id,
        message: `Subscriptions updating error`,
      }),
  };

  constructor(
    private toastService: ToastService,
    private imageCache: ImageCacheService,
    private streamService: StreamService,
    private adminSubscriptionsDatasource: AdminSubscriptionsDatasource,
  ) {}

  registerWebSocketListener() {
    return this.connect$.pipe(
      FilterStreamMsgFactory([
        WebsocketNotificationActionEnum.SUBSCRIPTION_CREATE,
        WebsocketNotificationActionEnum.SUBSCRIPTION_UPDATE,
        WebsocketNotificationActionEnum.SUBSCRIPTION_DELETE,
      ]),
      WsProgressToast(
        {
          SUCCESS: this.popToast.WS_RESTART_SUCCESS,
          PROGRESS: this.popToast.WS_RESTART_PROGRESS,
          ERROR: this.popToast.WS_RESTART_ERROR,
        },
        () => {
          this.adminSubscriptionsDatasource.selection.clear();
          this.adminSubscriptionsDatasource.refresh$.next(new Date().toString());
        },
      ),
    );
  }
}
