import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StatusColor } from '../../logic/model/color.model';

@Component({
  selector: 'vdms-hq-ui-text-status',
  templateUrl: './text-status.component.html',
  styleUrls: ['./text-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextStatusComponent {
  @Input() dot = true;
  @Input() color: StatusColor = 'done';
}
