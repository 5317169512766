import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'daysFrom',
  standalone: true,
})
export class DaysFromPipe implements PipeTransform {
  transform(date: string, from: string = new Date().toString()): number {
    const daysDiff = moment(from).diff(moment(date), 'days');
    return daysDiff;
  }
}
