<mat-form-field
  appearance="outline"
  class="material-custom-field"
  [class.hide-footer]="!withFooter"
  [class.with-touched-indicator]="withTouchedIndicator"
>
  <mat-label>{{ label | translate }}</mat-label>
  <mat-select [formControl]="innerFormControl" [multiple]="multiple">
    <mat-select-trigger>
      <ng-container *ngIf="selectedLabels; let selectedLabels">
        {{((multiple ? selectedLabels?.[0] : selectedLabels?.[0]) ?? '') | translate}}
        <span *ngIf="(selectedLabels?.length || 0) > 1" class="additional-selection">
          (+{{ (selectedLabels?.length || 0) - 1 }} {{ selectedLabels?.length === 2 ? 'other' : 'others' }})
        </span>
      </ng-container>
    </mat-select-trigger>
    <mat-optgroup *ngFor="let group of selectOptions" [label]="group.label">
      <mat-option
        *ngFor="let option of group.options"
        [value]="option.key"
        [disabled]="
          onePerGroup
            ? (option
              | onePerGroup
                : {
                    group: group,
                    chosenOptions: this.innerFormControl.value ?? []
                  })
            : false
        "
      >
        <span class="label">{{ option.label }}</span>
        <span class="suffix">{{ option.suffix }}</span>
      </mat-option>
    </mat-optgroup>
  </mat-select>
</mat-form-field>
