import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { VideoObjectRectangle } from '@vdms-hq/api-contract';
import { AssetViewComponent } from '@vdms-hq/firebase-contract';
import { OffsetAddPipe, PlayerService } from '@vdms-hq/player';
import {
  castTo,
  ConnectableDataSource,
  IsBetweenPipe,
  LoadableDataSource,
  MissingDatasourceError,
} from '@vdms-hq/shared';
import { Timecode, TimecodeModule } from '@vdms-hq/timecode';
import { DataColumn, DataValueColumn, UIEmptyResultsModule, UIFormModule, UIModule } from '@vdms-hq/ui';
import { BehaviorSubject, EMPTY, Subject, takeUntil } from 'rxjs';
import { ViewPlayerMetadataItem } from '../../logic/metadata-list.model';
import { ConfidenceNotePipe } from '../../logic/pipes/confidence-note.pipe';
import { TranslateTypePipe } from '../../logic/pipes/translate-type.pipe';
import { StreamsType } from '../../logic/streams/streams-type';
import { ShowOnTimelineComponent } from '../show-on-timeline/show-on-timeline.component';

type UiTimecodesListDataSource<T> = ConnectableDataSource<T> &
  Partial<LoadableDataSource> & { total$: BehaviorSubject<number> };
type Frame = number;
type FirstIndex = number;

@Component({
  selector: 'vdms-hq-timecode-table',
  standalone: true,
  imports: [
    CommonModule,
    UIModule,
    UIFormModule,
    TimecodeModule,
    UIEmptyResultsModule,
    TranslateTypePipe,
    ConfidenceNotePipe,
    ScrollingModule,
    ShowOnTimelineComponent,
    IsBetweenPipe,
    OffsetAddPipe,
  ],
  templateUrl: './timecode-table.component.html',
  styleUrls: ['./timecode-table.component.scss'],
})
export class TimecodeTableComponent<T extends ViewPlayerMetadataItem> implements OnInit, OnChanges, OnDestroy {
  @Input() enabledColumns: string[] = [];
  @Input() columns: DataColumn[] = [];
  @Input() dataSource!: UiTimecodesListDataSource<T>;
  @Input() enabledComponents: AssetViewComponent[] = [];

  @ViewChild(CdkVirtualScrollViewport) viewPort!: CdkVirtualScrollViewport;

  $metadataItem = castTo<ViewPlayerMetadataItem>();
  $asDataValueColumn = castTo<DataValueColumn>();

  protected readonly EMPTY = EMPTY;
  #destroyed = new Subject<void>();

  enabledAndDefined: string[] = [];
  firstIndexesForFrame = new Map<Frame, FirstIndex>();
  StreamsType = StreamsType;
  isShowOnTimeline = true;
  currentFrame = 0;

  constructor(private playerService: PlayerService) {}

  ngOnInit(): void {
    if (!this.dataSource.allData$) {
      throw new MissingDatasourceError();
    }

    this.#prepareScrollBehavior();
    this.playerService.currentTimecode$.pipe(takeUntil(this.#destroyed)).subscribe((timecode) => {
      timecode && this.scrollToTimeCode(timecode.countFrames());
    });

    this.isShowOnTimeline = [AssetViewComponent.ADVANCED_PLAYER, AssetViewComponent.TIMELINE].every((item) =>
      this.enabledComponents.includes(item),
    );
  }

  ngOnDestroy(): void {
    this.#destroyed.next();
    this.#destroyed.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['enabled'] || changes['columns']) {
      this.#updateEnabledColumns();
    }
  }

  onTimecodeClick(tc?: Timecode | null, boundingBoxes: VideoObjectRectangle[] = []) {
    if (!tc) {
      return;
    }
    this.playerService.goToTimecode(tc);

    this.playerService.drawCanvas({
      timecode: tc,
      shapes: boundingBoxes.map((shape) => ({
        height: Number(shape.BoundingBox.Height),
        left: Number(shape.BoundingBox.Left),
        top: Number(shape.BoundingBox.Top),
        width: Number(shape.BoundingBox.Width),
      })),
    });
  }

  scrollToTimeCode(currentFrame: number) {
    this.currentFrame = currentFrame;
    const foundElementIndex = this.firstIndexesForFrame.get(currentFrame);

    if (!foundElementIndex) {
      return;
    }

    const isPossibleToScrollSmoothly = 25 > Math.abs(this.viewPort.getRenderedRange().start - foundElementIndex);

    this.viewPort.scrollToIndex(foundElementIndex, isPossibleToScrollSmoothly ? 'smooth' : 'auto');
  }
  #prepareScrollBehavior() {
    this.dataSource.allData$.pipe(takeUntil(this.#destroyed)).subscribe((data) => {
      this.firstIndexesForFrame.clear();
      data.forEach((row, index) => {
        const frame = row.timestamp.timecodeIn?.countFrames() ?? 0;

        const exist = this.firstIndexesForFrame.get(frame);
        if (!exist) {
          this.firstIndexesForFrame.set(frame, index);
        }
      });
    });
  }

  #updateEnabledColumns() {
    this.enabledAndDefined = this.enabledColumns.filter((enabledColumnKey) => {
      const foundDefinition = this.columns.find((definition) => definition.id === enabledColumnKey);

      if (!foundDefinition) {
        console.warn(`Missing column definition ${enabledColumnKey}, make sure its enabled in admin panel.`);
        return false;
      }

      return true;
    });
  }
}
