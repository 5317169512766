export enum PowerBiRoot {
  ROOT = 'powerbi',
}

export enum PowerBiEndpoints {
  EmbedToken = 'embedToken',
}

export interface EmbedParams {
  accessToken: string,
  embedUrl: {
    embedUrl: string,
    reportId: string,
    reportName: string
  }[],
  expiry: string,
}
