import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsFormService } from '../../logic/settings-form.service';
import { SelectOption } from '@vdms-hq/shared';
import { dateFormats, pageSizeOptions, timeFormats, timezones, tableViewOptions } from '@vdms-hq/shared';
import { UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'vdms-hq-user-system-settings',
  standalone: true,
  imports: [CommonModule, UILayoutModule, FormsModule, ReactiveFormsModule, TranslateModule, UIFormModule],
  templateUrl: './user-system-settings.component.html',
})
export class UserSystemSettingsComponent {
  settingsFormService = inject(SettingsFormService);

  form = this.settingsFormService.systemSettingsControl;
  readonly timezones: SelectOption[] = timezones;
  readonly dateFormats: SelectOption[] = dateFormats;
  readonly timeFormats: SelectOption[] = timeFormats;
  readonly pageSizeOptions: SelectOption[] = pageSizeOptions;
  readonly tableViewOptions: SelectOption[] = tableViewOptions;
  readonly tableViewOptionsNoGrid: SelectOption[] = tableViewOptions.filter((option) => option.key !== 'grid');
}
