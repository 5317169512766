import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteNavigationComponent } from './site-navigation/site-navigation.component';

@NgModule({
  declarations: [SiteNavigationComponent],
  imports: [CommonModule],
  exports: [SiteNavigationComponent],
})
export class UISiteNavigationModule {}
