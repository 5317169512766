import { Routes } from '@angular/router';
import { ReleaseNotesWrapperComponent } from './release-notes-wrapper/release-notes-wrapper.component';
import { AuthOnlyGuard } from '@vdms-hq/auth';
import { ReleaseNoteComponent } from './release-note/release-note.component';

export enum RELEASE_NOTES_ROUTER_BASE {
  ROOT = 'release-notes',
  ARCHIVED = 'release-notes-archived',
  RELEASE = ':releaseNo',
}

export const releaseNotesRoutes: Routes = [
  {
    path: '',
    component: ReleaseNotesWrapperComponent,
    canActivate: [AuthOnlyGuard],
    children: [
      {
        path: RELEASE_NOTES_ROUTER_BASE.RELEASE,
        component: ReleaseNoteComponent,
      },
    ],
  },
];
