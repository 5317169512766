import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UILinkCopyVisitComponent } from './components/ui-link-copy-visit/ui-link-copy-visit.component';
import { UIModule } from '../ui.module';
import { UIFormModule } from '../ui-form';

@NgModule({
  declarations: [UILinkCopyVisitComponent],
  imports: [CommonModule, UIModule, UIFormModule],
  exports: [UILinkCopyVisitComponent],
})
export class UILinkModule {}
