export enum CART_ENDPOINTS {
  CART = 'cart',
  CART_FOLDER = 'cart/add-search',
  COUNT = 'count',
  ORDER = 'order',
  EMAILS = 'emails',
  ASSETS = 'assets',
  CATALOG_ITEMS = 'catalog-items',
  INVALID = 'invalid',
}
