import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[maxHeightAs]',
})
export class MaxHeightAsDirective<T> implements OnInit, OnDestroy {
  @Input() maxHeightAs?: HTMLElement;
  private resizeObserver?: ResizeObserver;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    if (!this.maxHeightAs) {
      return;
    }
    this.resizeObserver = new ResizeObserver(() => {
      if (this.maxHeightAs) {
        this.el.nativeElement.style.maxHeight = `${this.maxHeightAs.offsetHeight}px`;
      }
    });

    this.resizeObserver.observe(this.maxHeightAs);
  }

  ngOnDestroy(): void {
    if (!this.maxHeightAs) {
      return;
    }
    this.resizeObserver?.unobserve(this.maxHeightAs);
  }
}
