import { ChangeDetectorRef, Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { FormControlValueAccessorComponent } from '@vdms-hq/ui';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DataProviderService } from '../../logic/data-provider.service';
import { SelectOption } from '@vdms-hq/shared';
import { Observable } from 'rxjs';
import { SelectorSourceType } from '../../logic/selector-source.type';

@Component({
  selector: 'vdms-hq-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxListComponent),
    },
  ],
})
export class CheckboxListComponent extends FormControlValueAccessorComponent<string> implements OnInit {
  innerFormControl = new UntypedFormControl(null);
  @Input() sourceType!: SelectorSourceType;
  @Input() multiple = false;
  @Input() enableDeselectAll = false;
  @Input() context: 'search' | 'forms' = 'forms';
  @Input() nullValue = true;

  loading$?: Observable<boolean>;
  list$?: Observable<SelectOption[]>;

  constructor(private provider: DataProviderService, injector: Injector, changeDetectorRef: ChangeDetectorRef) {
    super(injector, changeDetectorRef);
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.provider) {
      return;
    }

    this.loading$ = this.provider.isLoading(this.sourceType);
    this.list$ = this.provider.listForSelectors(this.sourceType, this.context, this.multiple, this.nullValue);
  }
}
