<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <vdms-hq-ui-dialog-wrapper>
    <h1 title>{{ 'media_insights.dialog.title' | translate }}</h1>
    <div content class="dialog-content">
      <div class="mb-large">
        <vdms-hq-ui-form-checkbox-list
          [withFooter]="false"
          [available]="triggerList"
          formControlName="triggerList"
        ></vdms-hq-ui-form-checkbox-list>
      </div>

      <vdms-hq-selector
        formControlName="sourceLanguage"
        label="common.global.insights.source_language"
        [sourceType]="sourceType.MEDIA_INSIGHTS_SOURCE_LANGUAGE"
      >
      </vdms-hq-selector>

      <vdms-hq-selector
        [multiple]="true"
        [enableDeselectAll]="true"
        formControlName="targetLanguage"
        label="common.global.insights.target_language"
        [sourceType]="sourceType.MEDIA_INSIGHTS_TARGET_LANGUAGE"
      >
      </vdms-hq-selector>
    </div>
    <div footer class="footer buttons-group">
      <vdms-hq-ui-button color="secondary" (click)="onNoClick()">{{
        'common.global.cancel' | translate
      }}</vdms-hq-ui-button>
      <vdms-hq-ui-button color="primary" [isSubmit]="true" [disabled]="form.invalid">{{
        'common.global.create' | translate
      }}</vdms-hq-ui-button>
    </div>
  </vdms-hq-ui-dialog-wrapper>
</form>
