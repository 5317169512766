<div
  class="content"
  (click)="selectContent.emit(content)"
  [class.content--loading]="!content"
  [class.active]="selectedContent === content"
  *ngIf="content !== null"
>
  <span typingAnimation [text]="content" [typeSpeed]="7" *ngIf="content; else loader"></span>
  <div class="voice-reader-control" *ngIf="content">
    <vdms-hq-voice-reader *ngIf="isVoiceReader; else playBtn" [text]="content"></vdms-hq-voice-reader>
  </div>
</div>

<ng-template #loader>
  <vdms-hq-ui-loader mode="over-parent"></vdms-hq-ui-loader>
</ng-template>

<ng-template #playBtn>
  <vdms-hq-ui-button
    [disabled]="(voiceAiService.selectedVoice$ | async) === null"
    [icon]="'play_arrow'"
    [iconOnly]="true"
    (click)="isVoiceReader = true"
    tooltip="Generate description reading"
    color="primary"
  ></vdms-hq-ui-button>
</ng-template>
