import { AwsV4Signature } from '@vdms-hq/api-contract';

export enum UploadState {
  IN_PROGRESS = 'in_progress',
  ERROR = 'error',
  COMPLETED = 'completed',
}

export interface FileUploadProgressState {
  uploaded: boolean;
  progress: number;
}

export interface CreateMultipartUploadOutput {
  Bucket?: string;
  Key?: string;
  UploadId?: string;
}

export interface FileUploadState<STATUSES> {
  state: UploadState;
  status: STATUSES;
  uploadParams?: CreateMultipartUploadOutput;
  signature?: AwsV4Signature;
  file?: FileUploadProgressState;
  error?: string;
}
