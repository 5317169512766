import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TileIcon, TileIconIndicator, TileMetadata } from '../../logic/model';

@Component({
  selector: 'vdms-hq-ui-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TileComponent {
  @Input() size: 'small' | 'medium' | 'big' | 'full-height' = 'medium';
  @Input() topHeader?: null | string;
  @Input() header?: string;
  @Input() subheader?: null | string;
  @Input() background?: string;
  @Input() backgroundHover?: string;
  @Input() isLoading = false;
  @Input() backgroundScaleDown = false;
  @Input() metadata: TileMetadata[] = [];
  /**
   * First n metadata items will be always visible, rest will shown after hover.
   */
  @Input() maxVisibleMetadataEntries = 3;
  @Input() icon: TileIcon = false;
  @Input() iconIndicator: TileIconIndicator = 'neutral';
  isMouseOver = false;
  mock = false;

  setHover(value: boolean) {
    this.isMouseOver = value;
  }
}
