import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  tap,
  zip,
  combineLatest,
  shareReplay,
} from 'rxjs';
import { DashboardModel, DashboardService } from '@vdms-hq/api-contract';
import { RefreshService } from '@vdms-hq/shared';

@Injectable({ providedIn: 'root' })
export class DashboardsFetcher {
  refresh$ = new Subject<DashboardModel | null>();
  loading$ = new BehaviorSubject<boolean>(false);

  dashboards$ = combineLatest([this.refresh$, this.refreshService.refresh$]).pipe(
    startWith([null]),
    tap(() => this.loading$.next(true)),
    switchMap(([model]) => zip([this.dashboardService.getList(), of(model)])),
    map(([dashboards, updatedModel]) => this.#updateSingleModel(dashboards, updatedModel)),
    tap(() => this.loading$.next(false)),
    shareReplay(1),
  );

  constructor(private readonly dashboardService: DashboardService, private refreshService: RefreshService) {}

  create(model: DashboardModel): Observable<DashboardModel> {
    return this.dashboardService.create(model);
  }

  update(uuid: string, model: DashboardModel): Observable<DashboardModel> {
    return this.dashboardService.update(uuid, model);
  }

  #updateSingleModel(models: DashboardModel[], updatedModel: DashboardModel | null): DashboardModel[] {
    if (!updatedModel) {
      return models;
    }
    return models.map((model) => {
      if (updatedModel?.uuid === model.uuid) {
        return { ...model, ...updatedModel };
      }
      return model;
    });
  }
}
