<vdms-hq-ui-dialog-wrapper class="drawer-wrapper">
  <div title class="drawer-wrapper__title" *ngIf="data.portals?.header; let header" id="drawerDialogHeader">
    <ng-template [cdkPortalOutlet]="header"></ng-template>
  </div>
  <div content *ngIf="data.portals?.content; let content" id="drawerDialogContent">
    <ng-template [cdkPortalOutlet]="content"></ng-template>
  </div>
  <div footer *ngIf="data.portals?.footer; let footer" id="drawerDialogFooter">
    <ng-template [cdkPortalOutlet]="footer"></ng-template>
  </div>
</vdms-hq-ui-dialog-wrapper>
