<div class="chart-wrapper">
  <div *ngIf="additionalText" [innerHTML]="additionalText"></div>
  <canvas
    baseChart
    *ngIf="chartOptions"
    [ngStyle]="{ maxHeight: this.maxHeight ?? '180px' }"
    [datasets]="dataset"
    [labels]="labels"
    [type]="chartType"
    [options]="chartOptions"
    [plugins]="chartPlugins"
    [legend]="chartLegend"
  >
  </canvas>
</div>
