import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable, ReplaySubject } from 'rxjs';
import { QueryBuilder } from '../../query-builder/query-builder';
import { SalesforceAccount, SalesforceAccountResponse } from './models/queries/get-salesforce-account';
import { SourceTable } from './models/queries/source-table';
import { BridgedRequest } from '../tools/bridge/bridge';
import { BridgeService } from '../tools/bridge/bridge.service';
import { GettyAuthTokenInfo } from './models/getty-auth';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { SalesforceProject, SalesforceProjectResponse } from './models/queries/salesforce-project';
import { Broadcaster } from './models/queries/broadcaster';
import { SalesforceBroadcasterResponse } from './models/queries/broadcaster-response';

@Injectable({ providedIn: 'root' })
export class CcSalesforceApiService {
  #serviceUrl = '';
  #salesforceInstanceUrl = '';

  activeToken$: ReplaySubject<GettyAuthTokenInfo> = new ReplaySubject<GettyAuthTokenInfo>(1);

  constructor(private readonly apiService: ApiService, private readonly bridgeService: BridgeService) {}

  public connect(salesforceInstanceUrl: string, serviceUrl: string) {
    this.#salesforceInstanceUrl = salesforceInstanceUrl;
    this.#serviceUrl = serviceUrl;
  }

  getAccounts = (email: string, status: string): Observable<SalesforceAccountResponse> => {
    const GET_SALESFORCE_QUERY: string = new QueryBuilder<SalesforceAccount>()
      .select(['Id', 'Email', 'FirstName', 'LastName', 'Name', 'Account.id', 'Account.name'])
      .from(SourceTable.SALESFORCE_ACCOUNT)
      .where('Status__c', '=', status)
      .and('Email', '=', email)
      .limit(10000)
      .build();

    const bridgedGetSalesforceAccountsRequest: BridgedRequest = {
      url: `${this.#connectionString()}/query/?q=${GET_SALESFORCE_QUERY}`,
      method: 'GET',
    };

    return this.bridgeService
      .passThroughBridge<SalesforceAccountResponse>(bridgedGetSalesforceAccountsRequest)
      .pipe(GetResponseData);
  };

  getProjects = (accountId: string, isClosed: boolean): Observable<SalesforceProjectResponse> => {
    const GET_SALESFORCE_PROJECT_QUERY: string = new QueryBuilder<SalesforceProject>()
      .select([
        'Id',
        'Name',
        'AccountId',
        'Account.Name',
        'Broadcaster__c',
        'Broadcaster__r.Name',
        'Contact__c',
        'Contact__r.Name',
        'Project_Title__c',
        'Project_Description__c',
        'Usage_Rights_Required__c',
        'Project_Deadline__c',
        'Context_of_Use__c',
        'End_Client__c',
      ])
      .from(SourceTable.SALESFORCE_PROJECTS)
      .where('AccountId', '=', accountId)
      .and('Broadcaster__c', '!=', null)
      .and('Show_project_Opportunity_in_VIDA__c', '=', true)
      .and('IsClosed', '=', isClosed)
      .build();

    const bridgedGetSalesforceAccountsRequest: BridgedRequest = {
      url: `${this.#connectionString()}/query/?q=${GET_SALESFORCE_PROJECT_QUERY}`,
      method: 'GET',
    };

    return this.bridgeService
      .passThroughBridge<SalesforceProjectResponse>(bridgedGetSalesforceAccountsRequest)
      .pipe(GetResponseData);
  };

  getBroadcasters = (isDeleted: boolean): Observable<SalesforceBroadcasterResponse> => {
    const GET_BROADCASTERS_QUERY: string = new QueryBuilder<Broadcaster>()
      .select(['id', 'name'])
      .from(SourceTable.BROADCASTERS)
      .where('isDeleted', '=', isDeleted)
      .build();

    const bridgedGetSalesforceAccountsRequest: BridgedRequest = {
      url: `${this.#connectionString()}/query/?q=${GET_BROADCASTERS_QUERY}`,
      method: 'GET',
    };

    return this.bridgeService
      .passThroughBridge<SalesforceBroadcasterResponse>(bridgedGetSalesforceAccountsRequest)
      .pipe(GetResponseData);
  };

  /**
   * @remarks - serviceUrl has '/' at the beginning
   * @private
   */
  #connectionString = (): string => `${this.#salesforceInstanceUrl}${this.#serviceUrl}`;
}
