import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIButtonModule, UIFormModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { SearchBarComponent } from './smart/search-bar/search-bar.component';
import { SimpleQueryInputComponent } from './smart/simple-query-input/simple-query-input.component';
import { SimpleAiQueryInputComponent } from './smart/simple-ai-query-input/simple-ai-query-input.component';
import { SharedModule } from '../../shared/shared.module';
import { TourItemComponent } from '@vdms-hq/tour-guide';

@NgModule({
  declarations: [SearchBarComponent, SimpleQueryInputComponent, SimpleAiQueryInputComponent],
  imports: [CommonModule, UIFormModule, TranslateModule, UIButtonModule, SharedModule, TourItemComponent],
  exports: [SearchBarComponent, SimpleAiQueryInputComponent],
})
export class BrowseSearchBarModule {}
