<ng-container *ngIf="selectOptions$ && (selectOptions$ | async); let selectOptions">
  <ng-container *ngIf="selectOptions?.length; else picker">
    <vdms-hq-ui-form-input-select
      [selectOptions]="selectOptions"
      [label]="label"
      [formControl]="selectorFormControl"
      [withTouchedIndicator]="withTouchedIndicator"
    ></vdms-hq-ui-form-input-select>
    <ng-container *ngIf="triggerDatePicker$ | async">
      <ng-container [ngTemplateOutlet]="dateTimePicker"></ng-container>
    </ng-container>
  </ng-container>

  <ng-template #picker>
    <ng-container [ngTemplateOutlet]="dateTimePicker"></ng-container>
  </ng-template>
</ng-container>

<ng-template #dateTimePicker>
  <vdms-hq-ui-form-input-date-time
    [label]="label"
    [formControl]="innerFormControl"
    [timepicker]="true"
    [withTouchedIndicator]="withTouchedIndicator"
  ></vdms-hq-ui-form-input-date-time>
</ng-template>
