import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CollectionsRefresh {
  public refreshAssets$ = new BehaviorSubject<boolean>(true);
  public addToCollection$ = new BehaviorSubject<boolean>(true);
  public refreshSubCollectionsPagination$ = new BehaviorSubject<boolean>(false);
  public clearSelection$ = new BehaviorSubject<boolean>(false);
}
