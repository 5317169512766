import { createAction, props } from '@ngrx/store';
import { MessageModel, MessageModelCreate, MessageState } from '../models/message.model';

const prefix = '[TOAST] ';

const Action = {
  MESSAGE: prefix + 'Any',
  RESET: prefix + 'Reset',
  ADD: prefix + 'Add',
  UPDATE: prefix + 'Update',
  CLOSE: prefix + 'Close',
};

export const reset = createAction(Action.RESET);
export const message = createAction(Action.MESSAGE, props<{ data: MessageModelCreate }>());
export const messageAdd = createAction(Action.ADD, props<{ data: MessageModel }>());
export const messageUpdate = createAction(Action.UPDATE, props<{ id: string; data: Partial<MessageModel> }>());
export const messageClose = createAction(Action.CLOSE, props<{ id: string }>());

interface Input {
  id?: string;
  message: string;
  translationContext?: any;
}

export const success = (input: Input) => {
  return message({
    data: {
      message: input.message,
      id: input.id ?? new Date().toISOString(),
      autoHide: true,
      translationContext: input.translationContext,
      state: MessageState.success,
    },
  });
};

export const processing = (input: Input) => {
  return message({
    data: {
      message: input.message,
      id: input.id ?? new Date().toISOString(),
      autoHide: false,
      translationContext: input.translationContext,
      state: MessageState.processing,
    },
  });
};

export const error = (input: Input) => {
  return message({
    data: {
      message: input.message,
      id: input.id ?? new Date().toISOString(),
      autoHide: true,
      translationContext: input.translationContext,
      state: MessageState.error,
    },
  });
};

export const info = (input: Input) => {
  return message({
    data: {
      message: input.message,
      id: input.id ?? new Date().toISOString(),
      autoHide: true,
      translationContext: input.translationContext,
      state: MessageState.info,
    },
  });
};
