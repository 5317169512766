import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, switchMap, map, tap, shareReplay, of, debounceTime } from 'rxjs';
import { TableAdvancedDataSource } from '@vdms-hq/ui';
import { NotificationSubscriptionsService, NotificationTypes } from '@vdms-hq/api-contract';
import { PAGE_SIZE_OPTIONS, Selection, SelectionManager } from '@vdms-hq/shared';
import { PageEvent } from '@angular/material/paginator';
import { AdminNotificationSubscriptionViewModel } from './admin-notification-subscription.model';

@Injectable({ providedIn: 'root' })
export class AdminSubscriptionsDatasource implements TableAdvancedDataSource<AdminNotificationSubscriptionViewModel> {
  typeSearch$ = new BehaviorSubject<string>('all');
  userSearch$ = new BehaviorSubject<string>('');

  pageIndex$ = new BehaviorSubject<number>(0);
  pageSize$ = new BehaviorSubject<number>(12);
  pageSizeOptions = PAGE_SIZE_OPTIONS;
  emptyResults$ = new BehaviorSubject<boolean>(false);
  total$ = new BehaviorSubject<number>(0);
  refresh$ = new BehaviorSubject<string>(new Date().toString());

  loading$ = new BehaviorSubject(false);
  allData$: Observable<AdminNotificationSubscriptionViewModel[]> = combineLatest([
    this.typeSearch$.asObservable(),
    this.userSearch$.asObservable(),
    this.pageIndex$.asObservable(),
    this.pageSize$.asObservable(),
    this.refresh$,
  ]).pipe(
    debounceTime(300),
    tap(() => this.loading$.next(true)),
    switchMap(([type, userInput, pageIndex, pageSize]) => {
      if (type !== 'all') {
        return this.notificationSubscriptionService.getSubscriptionsByType(type as NotificationTypes, {
          pageIndex,
          pageSize,
          text: userInput,
        });
      }

      return this.notificationSubscriptionService.getAllSubscriptions({ pageIndex, pageSize, text: userInput });
    }),
    tap(({ total }) => {
      this.total$.next(total);
      this.emptyResults$.next(!total);
    }),
    map(({ data }) => {
      return data.map((subscription) => new AdminNotificationSubscriptionViewModel(subscription));
    }),
    tap(() => this.loading$.next(false)),
    shareReplay(),
  );
  connection$: Observable<AdminNotificationSubscriptionViewModel[]> = this.allData$;
  selection: Selection<AdminNotificationSubscriptionViewModel> =
    new SelectionManager<AdminNotificationSubscriptionViewModel>(this, (item) => item.props.uuid);

  constructor(private notificationSubscriptionService: NotificationSubscriptionsService) {}

  trackBy(_: number, item: AdminNotificationSubscriptionViewModel) {
    return item.props.uuid;
  }

  pageChange($event: PageEvent) {
    this.pageIndex$.next($event.pageIndex);
    this.pageSize$.next($event.pageSize);
  }
}
