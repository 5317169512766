import { inject, Injectable } from '@angular/core';
import { map, take } from 'rxjs';
import { ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { QuarantineConfig, QuarantineConfigRules } from './quarantine.model';

const enum QUARANTINE_ENDPOINTS {
  ALL_CONFIGS = 'quarantine-configs',
  ONE_CONFIG = 'quarantine-config',
}

@Injectable({ providedIn: 'root' })
export class QuarantineApiService {
  private apiService = inject(ApiService);

  updateConfig(config: QuarantineConfig, rules: QuarantineConfigRules) {
    if (!config?.uuid) {
      throw new Error('Config UUID is missing');
    }
    return this.apiService.patch<QuarantineConfig, ApiResponse<any>>(
      `${QUARANTINE_ENDPOINTS.ONE_CONFIG}\\${config.uuid}`,
      {
        ...config,
        rules,
      },
    );
  }

  createConfig(clientUuid: string, rules: QuarantineConfigRules, supplierUuid?: string) {
    const payload = {
      group_uuid: clientUuid,
      rules,
    } as QuarantineConfig;

    if (supplierUuid) {
      payload.supplier_uuid = supplierUuid;
    }

    return this.apiService.post<QuarantineConfig, ApiResponse<any>>(`${QUARANTINE_ENDPOINTS.ONE_CONFIG}`, payload);
  }

  getAllConfigs() {
    return this.apiService.get<ApiResponse<QuarantineConfig[]>>(QUARANTINE_ENDPOINTS.ALL_CONFIGS);
  }

  getConfig(uuid: string) {
    return this.apiService.get<ApiResponse<QuarantineConfig>>(`${QUARANTINE_ENDPOINTS.ONE_CONFIG}/${uuid}`);
  }

  getClientConfig(clientUuid: string | null | undefined) {
    if (!clientUuid) {
      throw new Error('Client UUID is missing');
    }

    return this.apiService.get<ApiResponse<QuarantineConfig[]>>(`${QUARANTINE_ENDPOINTS.ALL_CONFIGS}`).pipe(
      take(1),
      map((configs) => {
        return configs.data.find((config) => config.group_uuid === clientUuid && !config.supplier_uuid);
      }),
    );
  }

  getClientSupplierConfig(clientUuid: string | null | undefined, supplierUuid: string | null | undefined) {
    if (!clientUuid) {
      throw new Error('Client UUID is missing');
    }

    if (!supplierUuid) {
      throw new Error('Supplier UUID is missing');
    }

    return this.apiService.get<ApiResponse<QuarantineConfig[]>>(`${QUARANTINE_ENDPOINTS.ALL_CONFIGS}`).pipe(
      take(1),
      map((configs) => {
        return configs.data.find((config) => config.group_uuid === clientUuid && config.supplier_uuid === supplierUuid);
      }),
    );
  }
}
