import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { ApiEmptyResponse, ApiPaginatedResponse, ApiResponse, IdArrayResponse } from '../api.model';
import { LinkAccountPost, LoginUserPayload, UserModel, UserPatchRequest, UserPostRequest } from './user.model';
import { Observable } from 'rxjs';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(private apiService: ApiService) {}

  linkAccount(data: LinkAccountPost) {
    return this.apiService.post<LinkAccountPost, ApiEmptyResponse>('user/link', data);
  }

  getUsers() {
    return this.apiService.get<ApiResponse<UserModel[]>>(`users`).pipe(GetResponseData);
  }

  getPaginatedUsers(
    pagination?: { text?: string; perPage?: string; page?: string },
    orderBy?: string,
    orderDir?: string,
  ) {
    const headers = new HttpHeaders({
      text: pagination?.text ?? '',
      'per-page': pagination?.perPage ?? '24',
      page: pagination?.page ?? '0',
      'order-by': orderBy ?? 'user.displayName',
      'order-dir': orderDir ?? 'ASC',
    });

    return this.apiService.get<ApiPaginatedResponse<UserModel>>(`users`, { headers });
  }

  getUser(uuid?: string, log?: { perPage: number; page: number }): Observable<UserModel> {
    const path = uuid ? `user/${uuid}` : 'user';

    const options = {
      headers: new HttpHeaders(),
    };

    if (log) {
      options.headers = new HttpHeaders({
        'activity-log-page': String(log?.page),
        'activity-log-per-page': String(log?.perPage),
      });
    }

    return this.apiService.get<ApiResponse<UserModel>>(path, options).pipe(GetResponseData);
  }

  getPaginatedUsersShare(
    pagination?: {
      text?: string;
      perPage?: string;
      page?: string;
    },
    orderBy?: string,
    orderDir?: string,
  ): Observable<ApiPaginatedResponse<UserModel>> {
    const headers = new HttpHeaders({
      text: pagination?.text ?? '',
      'per-page': pagination?.perPage ?? '24',
      page: pagination?.page ?? '0',
      'order-by': orderBy ?? 'user.displayName',
      'order-dir': orderDir ?? 'ASC',
    });
    return this.apiService.get<ApiPaginatedResponse<UserModel>>('users/share', { headers });
  }

  getPaginatedUsersShareMailStrict(mail: string): Observable<ApiPaginatedResponse<UserModel>> {
    const headers = new HttpHeaders({
      mail,
      'per-page': '1',
      page: '0',
    });
    return this.apiService.get<ApiPaginatedResponse<UserModel>>('users/share', { headers });
  }

  patchCurrentUser(json: UserPatchRequest) {
    return this.apiService.patch<UserPatchRequest, ApiResponse<UserModel>>(`user`, json).pipe(GetResponseData);
  }

  postUser(json: UserPostRequest) {
    return this.apiService.post<UserPostRequest, ApiResponse<UserModel>>(`user`, json).pipe(GetResponseData);
  }

  patchUser(userUuid: string, json: UserPatchRequest) {
    return this.apiService.patch<UserPatchRequest, IdArrayResponse>(`user/${userUuid}`, json);
  }

  delete(userUuid: string) {
    const headers = new HttpHeaders({
      'soft-delete': 'true',
    });
    return this.apiService.delete<IdArrayResponse>(`user/${userUuid}`, undefined, headers);
  }

  generateApiToken(user_uuid: string): Observable<{ token: string }> {
    return this.apiService
      .post<{ user_uuid: string }, ApiResponse<{ token: string }>>('user/generate-api-token', { user_uuid })
      .pipe(GetResponseData);
  }

  getCustomToken(staticToken: string, tokenType = 'Token') {
    const headers = new HttpHeaders({
      Authorization: `${tokenType} ${staticToken}`,
    });
    return this.apiService.get<ApiResponse<{ token: string }>>('user/custom-token', { headers }).pipe(GetResponseData);
  }

  loginPasswordLess(params: LoginUserPayload) {
    return this.apiService.post<LoginUserPayload, ApiEmptyResponse>('user/login', params);
  }
}
