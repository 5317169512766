<vdms-hq-ui-dialog-wrapper footerAlign="space-between">
  <div title>
    {{
      (tabState === 'sms' ? 'common.account_settings.mfa.header' : 'common.account_settings.mfa.totp_header')
        | translate
    }}
  </div>
  <div content>
    <mat-tab-group class="material-custom-tab" (selectedTabChange)="tabChanged($event.index)">
      <mat-tab [label]="'via SMS'">
        <ng-template matTabContent>
          <ng-container [ngTemplateOutlet]="sms"></ng-container>
        </ng-template>
      </mat-tab>
      <mat-tab [label]="'Authenticator'" *ngIf="totpDoesntExist$ | async">
        <ng-container [ngTemplateOutlet]="totp"></ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>
  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="closeDialog()" *ngIf="!dialogRef.disableClose">{{
      'common.global.cancel' | translate
    }}</vdms-hq-ui-button>

    <ng-container *ngIf="tabState === 'sms'; else totpActions">
      <ng-container [ngTemplateOutlet]="smsButtons"></ng-container>
    </ng-container>
    <ng-template #totpActions>
      <ng-container [ngTemplateOutlet]="totpButtons"></ng-container>
    </ng-template>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>

<ng-template #sms>
  <h3 class="subtitle">{{ 'common.account_settings.mfa.subheader' | translate }}</h3>

  <form [formGroup]="form">
    <div formGroupName="step1" class="controls">
      <vdms-hq-ui-form-input-text
        formControlName="name"
        [label]="'common.account_settings.mfa.name_label' | translate"
        [placeholder]="'common.account_settings.mfa.name_placeholder' | translate"
      >
      </vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-input-phone
        formControlName="phone"
        [label]="'common.account_settings.mfa.phone_label' | translate"
      ></vdms-hq-ui-form-input-phone>
    </div>

    <div formGroupName="step2" class="controls" *ngIf="verificationId">
      <vdms-hq-ui-form-input-number
        formControlName="code"
        [label]="'common.account_settings.mfa.code_label' | translate"
      ></vdms-hq-ui-form-input-number>
    </div>
  </form>
</ng-template>

<ng-template #totp>
  <form [formGroup]="secondaryForm">
    <canvas id="canvas"></canvas>
    <ng-container *ngIf="totpSecretKey$ | async; let totp">
      <vdms-hq-ui-form-input-text formControlName="code" [label]="'Verification code'"></vdms-hq-ui-form-input-text>
    </ng-container>
  </form>
</ng-template>

<ng-template #smsButtons>
  <vdms-hq-ui-button
    class="ml-auto"
    color="primary"
    [disabled]="step1Ctrl.invalid"
    (click)="sendVerification()"
    [loading]="processing"
    *ngIf="!verificationId"
  >
    {{ 'common.account_settings.mfa.send_code' | translate }}
  </vdms-hq-ui-button>

  <vdms-hq-ui-button
    color="primary"
    class="ml-auto"
    [disabled]="step2Ctrl.invalid"
    (click)="confirmNumber()"
    [loading]="processing"
    *ngIf="verificationId"
  >
    {{ 'common.account_settings.mfa.confirm' | translate }}
  </vdms-hq-ui-button>
</ng-template>

<ng-template #totpButtons>
  <ng-container *ngIf="totpSecretKey$ | async; let secret">
    <vdms-hq-ui-button
      color="primary"
      class="ml-auto"
      (click)="finalizeTotpVerification(secret)"
      [disabled]="secondaryForm.invalid"
    >
      {{ 'common.account_settings.mfa.finalize' | translate }}
    </vdms-hq-ui-button>
  </ng-container>
</ng-template>
