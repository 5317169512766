import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DashboardModel, DashboardPatchModel } from './dashboard.model';
import { DashboardEndpoints } from './endpoint';
import { ApiService } from '../api.service';
import { ApiResponse } from '../api.model';
import { GetResponseData } from '../../operators/get-response-data.operator';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  constructor(private readonly apiService: ApiService) {}

  get(uuid: string = 'default'): Observable<DashboardModel> {
    return this.apiService.get<ApiResponse<DashboardModel>>(`${DashboardEndpoints.ROOT}/${uuid}`).pipe(GetResponseData);
  }

  getList(): Observable<DashboardModel[]> {
    return this.apiService.get<ApiResponse<DashboardModel[]>>(`${DashboardEndpoints.ROOT_LIST}`).pipe(GetResponseData);
  }

  create(json: DashboardModel): Observable<DashboardModel> {
    return this.apiService.post<DashboardModel, DashboardModel>(`${DashboardEndpoints.ROOT}`, json);
  }

  update(uuid: string, json: DashboardPatchModel): Observable<DashboardModel> {
    return this.apiService.patch<DashboardPatchModel, DashboardModel>(`${DashboardEndpoints.ROOT}/${uuid}`, json);
  }
}
