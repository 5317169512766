export interface DeliveryPackUpload {
  deliveryPackUuid: string;
}

export interface DeliveryPackReUpload extends DeliveryPackUpload {
  deliveryJobUuid: string;
}

export interface CatalogPlaceholderUpload {
  catalogItem: string;
}

export interface AssetPlaceholderUpload {
  assetPlaceholderUuid: string;
}

export type UploadContext =
  | DeliveryPackUpload
  | DeliveryPackReUpload
  | CatalogPlaceholderUpload
  | AssetPlaceholderUpload;

export const isDeliveryPack = (obj: UploadContext) => {
  return (
    typeof obj === 'object' && 'deliveryPackUuid' in obj && !('deliveryJobUuid' in obj) && (obj as DeliveryPackUpload)
  );
};

export const isDeliveryJobReUpload = (obj: UploadContext) => {
  return typeof obj === 'object' && 'deliveryJobUuid' in obj && (obj as DeliveryPackReUpload);
};

export const isCatalogPlaceholder = (obj: UploadContext) => {
  return typeof obj === 'object' && 'catalogItem' in obj && (obj as CatalogPlaceholderUpload);
};

export const isAssetPlaceholder = (obj: UploadContext) => {
  return typeof obj === 'object' && 'assetPlaceholderUuid' in obj && (obj as AssetPlaceholderUpload);
};
