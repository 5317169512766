import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

type OuterValue = number | string | null | undefined;
type InnerValue = number | null;

@Component({
  selector: 'vdms-hq-ui-form-slider',
  templateUrl: './form-slider.component.html',
  styleUrls: ['./form-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormSliderComponent),
    },
  ],
})
export class FormSliderComponent extends FormControlValueAccessorComponent<OuterValue, InnerValue> {
  innerFormControl = new UntypedFormControl(null);
  @Input() sliderMinValue = 0;
  @Input() sliderMaxValue = 100;
  @Input() disabled = false;
  @Input() step = 1;
  @Input() discrete = true;
  @Input() displayWithFn: (value: number) => string = (value) => value.toString();

  override transformOuterToInner(value: OuterValue): InnerValue {
    return value === undefined ? Number(value) : Number(value);
  }

  update(value: number) {
    this.innerFormControl.setValue(value);
  }
}
