<div class="list-item" [class.list-item--draggable]="draggable">
  <mat-icon class="list-item__icon" *ngIf="draggable">drag_indicator</mat-icon>
  <ng-content select="[prefix]"></ng-content>
  <div
    class="list-item__label"
    #label
    [matTooltip]="tooltip"
    [matTooltipDisabled]="label.offsetWidth === label.scrollWidth"
  >
    <ng-content></ng-content>
  </div>
  <mat-icon class="list-item__icon list-item__icon-suffix" *ngIf="suffixIcon" (click)="suffixAction.emit()">{{
    suffixIcon
  }}</mat-icon>
</div>
