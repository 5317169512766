import { createAction, props } from '@ngrx/store';
import { WebsocketNotificationInterface, WebsocketProgressInterface } from '@vdms-hq/shared';

const WebsocketPrefix = '[WEBSOCKET] ';

const WebsocketAction = {
  START_COLLECTIONS_PROGRESS: WebsocketPrefix + 'Collection progress start listening',
  COLLECTIONS_UPDATING_PROGRESS_PROCESSING: WebsocketPrefix + 'Collection updating processing',
  COLLECTIONS_UPDATING_PROGRESS_FAIL: WebsocketPrefix + 'Collection updating fail',

  START_CART_PROGRESS: WebsocketPrefix + 'Cart progress start listening',
  CART_UPDATING_PROGRESS_PROCESSING: WebsocketPrefix + 'Cart updating processing',
  CART_UPDATING_PROGRESS_FAIL: WebsocketPrefix + 'Cart updating fail',

  CART_UPDATING_AFTER_ASSET_REMOVED: WebsocketPrefix + 'Cart updating after asset removed',
  CART_PROGRESS_DONE_RESET: WebsocketPrefix + 'Cart progress - reset done counter',

  RESET_LISTENING: WebsocketPrefix + 'Reset',
};

// collections
export const startCollectionsProgress = createAction(WebsocketAction.START_COLLECTIONS_PROGRESS);
export const collectionsUpdatingProgressProcessing = createAction(
  WebsocketAction.COLLECTIONS_UPDATING_PROGRESS_PROCESSING,
  props<{ actionId: string; percent: number }>(),
);

export const collectionsUpdatingProgressFail = createAction(
  WebsocketAction.COLLECTIONS_UPDATING_PROGRESS_FAIL,
  props<{ actionId: string }>(),
);

// cart
export const cartUpdatingProgressProcessing = createAction(
  WebsocketAction.CART_UPDATING_PROGRESS_PROCESSING,
  props<{
    percent: number;
    notProcessed: number;
    added: number;
    total: number;
  }>(),
);

// reset
export const resetWebSockets = createAction(WebsocketAction.RESET_LISTENING);

export const cartUpdatingProgressFail = createAction(
  WebsocketAction.CART_UPDATING_PROGRESS_FAIL,
  props<{ msg: WebsocketNotificationInterface<WebsocketProgressInterface> }>(),
);

export const cartUpdatingAfterAssetRemoved = createAction(WebsocketAction.CART_UPDATING_AFTER_ASSET_REMOVED);
export const cartProgressDoneReset = createAction(WebsocketAction.CART_PROGRESS_DONE_RESET);
