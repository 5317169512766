import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TourConfig } from '../../ui-sidenav/sidenav/sidenav.component';
import { SidenavState, SidenavStatusEnum } from '../../ui-sidenav/logic/models/sidenav.model';
import { MatButton } from '@angular/material/button';
import { Theme } from '@vdms-hq/theming';

@Component({
  selector: 'vdms-hq-ui-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements AfterViewInit, OnDestroy {
  @Input() tourConfig: TourConfig = {};
  @Input() menuOptions: {
    templateRef?: TemplateRef<ElementRef<Element>>;
    label?: string;
    routerLink?: string;
    clickFn?: () => void;
  }[] = [];
  readonly SidebarStatusEnum = SidenavStatusEnum;
  buttonWidth = 0;

  @Input() displayName = '';
  @Input() sidenavState: SidenavState = 'expanded';
  @Input() theme: Theme.dark | Theme.white = Theme.dark;

  @Output() logout = new EventEmitter<void>();
  @Output() toggleTheme = new EventEmitter<void>();
  @Output() toggleSidenav = new EventEmitter<void>();

  private resizeObserver!: ResizeObserver;

  @ViewChild('menuButton') el!: MatButton;

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.resizeObserver = new ResizeObserver((entries) => {
      this.buttonWidth = entries[0].target.clientWidth;
      this.cd.detectChanges();
    });

    this.resizeObserver.observe(this.el._elementRef.nativeElement);
  }

  ngOnDestroy() {
    this.resizeObserver.unobserve(this.el._elementRef.nativeElement);
  }
}
