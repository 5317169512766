<div class="columns" cdkDropListGroup>
  <div class="column">
    <h3>Metadata visible in {{ viewName }} by default ({{ cdkLeftList.length }})</h3>
    <div class="list" cdkDropList [cdkDropListData]="cdkLeftList" (cdkDropListDropped)="drop($event)">
      <div cdkDrag *ngFor="let defaultField of cdkLeftList">
        <vdms-hq-ui-list-item-placeholder *cdkDragPlaceholder></vdms-hq-ui-list-item-placeholder>
        <vdms-hq-ui-list-item
          *ngIf="allowDeleting"
          (suffixAction)="deleteItem('default', defaultField.key)"
          suffixIcon="close"
          [tooltip]="defaultField.label"
        >
          {{ defaultField.label }}
        </vdms-hq-ui-list-item>
        <vdms-hq-ui-list-item *ngIf="!allowDeleting" [tooltip]="defaultField.label">
          {{ defaultField.label }}
        </vdms-hq-ui-list-item>
      </div>
    </div>
  </div>
  <div class="divider"></div>
  <div class="column">
    <h3>Metadata available to enable in {{ viewName }} ({{ cdkRightList.length }})</h3>

    <div class="list" cdkDropList [cdkDropListData]="cdkRightList" (cdkDropListDropped)="drop($event)">
      <div cdkDrag *ngFor="let availableField of cdkRightList">
        <vdms-hq-ui-list-item-placeholder *cdkDragPlaceholder></vdms-hq-ui-list-item-placeholder>
        <vdms-hq-ui-list-item
          *ngIf="allowDeleting"
          (suffixAction)="deleteItem('available', availableField.key)"
          suffixIcon="close"
          [tooltip]="availableField.label"
        >
          {{ availableField.label }}
        </vdms-hq-ui-list-item>
        <vdms-hq-ui-list-item *ngIf="!allowDeleting" [tooltip]="availableField.label">
          {{ availableField.label }}
        </vdms-hq-ui-list-item>
      </div>
    </div>
    <div class="list-footer" *ngIf="allowDeleting">
      <vdms-hq-ui-form-input-select
        [selectOptions]="outsideList"
        [withFooter]="false"
        [(ngModel)]="selectedOptions"
        [enableSelectAll]="true"
        [enableDeselectAll]="true"
        [multiple]="true"
        label="Add more metadata"
      >
      </vdms-hq-ui-form-input-select>
      <vdms-hq-ui-button color="primary" [disabled]="!selectedOptions" (click)="addSelected()">{{
        'common.global.add' | translate
      }}</vdms-hq-ui-button>
    </div>
  </div>
</div>
