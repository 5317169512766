<ng-container>
  <div class="actions-blank"></div>
  <footer class="actions-footer" [ngClass]="menuState" #footerContainer>
    <div class="actions-footer__buttons">
      <div class="actions-footer__buttons__col">
        <ng-content select="[left]"></ng-content>
      </div>
      <div class="actions-footer__buttons__col">
        <ng-content select="[center]"></ng-content>
      </div>
      <div class="actions-footer__buttons__col">
        <ng-content select="[right]"></ng-content>
      </div>
    </div>
  </footer>
</ng-container>
