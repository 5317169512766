import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DeliveryUploadJob } from '@vdms-hq/api-contract';

@Injectable({ providedIn: 'root' })
export class LastUploadedJobsService {
  #uploadedJobs$ = new BehaviorSubject<DeliveryUploadJob[]>([]);
  uploadedJobs$ = this.#uploadedJobs$.asObservable();

  setJobs(nextJobs: DeliveryUploadJob[]) {
    const prevJobs = this.#uploadedJobs$.value;
    this.#uploadedJobs$.next([...nextJobs, ...prevJobs]);
  }

  reset() {
    this.#uploadedJobs$.next([]);
  }
}
