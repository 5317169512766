import { ComponentFactoryResolver, inject, Injectable, ViewContainerRef } from '@angular/core';
import { ComponentPortal, ComponentType, DomPortalOutlet } from '@angular/cdk/portal';
import { DialogDrawerForDialogComponent } from '../components/dialog-drawer-for-dialog/dialog-drawer-for-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService, UserAttributes } from '@vdms-hq/auth';
import { DialogDrawerHeaderComponent } from '../components/dialog-drawer-header/dialog-drawer-header.component';
import { DialogDrawerForDrawerComponent } from '../components/dialog-drawer-for-drawer/dialog-drawer-for-drawer.component';

export type DialogDrawerConfig = {
  drawerPlaceholder: string;
  header: string;
  subheader?: string;
  actionOptions: [
    {
      key: 'drawer';
      label: string;
    },
    {
      key: 'dialog';
      label: string;
    },
  ];
};

export type PortalsToRender = {
  header: ComponentPortal<unknown>;
  content: ComponentPortal<unknown>;
  footer?: ComponentPortal<unknown>;
};

@Injectable({
  providedIn: 'root',
})
export class DrawerDialogService {
  #componentFactoryResolver = inject(ComponentFactoryResolver);
  #dialog = inject(MatDialog);
  #authService = inject(AuthService);

  state$: Observable<'dialog' | 'drawer'> = this.#authService.userAttributesDefinite$.pipe(
    map((userAttributes: UserAttributes) => userAttributes.vida.preferredDialogDrawerView),
  );
  #renderedPortals?: PortalsToRender;
  #dialogContainer?: MatDialogRef<DialogDrawerForDialogComponent>;

  #viewContainerRef?: ViewContainerRef;
  #drawerContainer?: ComponentPortal<DialogDrawerForDrawerComponent>;

  config: DialogDrawerConfig = {
    drawerPlaceholder: 'multiple-data-drawer-slot',
    header: 'Quick Preview',
    actionOptions: [
      {
        key: 'drawer',
        label: 'Preview in side box',
      },
      {
        key: 'dialog',
        label: 'Preview in dialog box',
      },
    ],
  };

  changeState(nextState: 'dialog' | 'drawer') {
    this.#authService
      .updateAttributes({
        vida: {
          preferredDialogDrawerView: nextState,
        },
      })
      .subscribe(() => {
        if (!this.#renderedPortals) {
          return;
        }

        this.#render(this.#renderedPortals);
      });
  }

  open<CONTENT_COMPONENT, FOOTER_COMPONENT>(
    viewContainerRef: ViewContainerRef,
    contentComponent: ComponentType<CONTENT_COMPONENT>,
    footerComponent?: ComponentType<FOOTER_COMPONENT>,
    config?: Partial<DialogDrawerConfig>,
  ) {
    this.#viewContainerRef = viewContainerRef;

    const portals: PortalsToRender = {
      header: new ComponentPortal(DialogDrawerHeaderComponent, viewContainerRef),
      content: new ComponentPortal(contentComponent, viewContainerRef),
      footer: footerComponent ? new ComponentPortal(footerComponent, viewContainerRef) : undefined,
    };

    this.config = {
      ...this.config,
      ...config,
    };

    this.#render(portals);
  }

  #render(portals: PortalsToRender) {
    this.state$.pipe(take(1)).subscribe((state) => {
      if (state === 'dialog') {
        this.#renderDialog(portals);
      } else {
        this.#renderDrawer(portals);
      }
    });
  }

  close() {
    this.#unRenderAll();
    this.#dialogContainer?.close({ emit: false });
  }

  #getPortalFor(id: string) {
    const container = document.getElementById(id);
    if (!container) {
      throw new Error(`[Runtime error] Element with id ${id} not found`);
    }

    return new DomPortalOutlet(container, this.#componentFactoryResolver);
  }

  #renderDrawer(portals: PortalsToRender) {
    this.#dialogContainer?.close({ emit: false });
    this.#unRenderAll();

    const portalHost = this.#getPortalFor(this.config.drawerPlaceholder);

    const drawerContainer = new ComponentPortal(DialogDrawerForDrawerComponent, this.#viewContainerRef);

    const componentComponentRef = drawerContainer.attach(portalHost);

    componentComponentRef.instance.portals = portals;
    this.#renderedPortals = portals;
    this.#drawerContainer = drawerContainer;

    componentComponentRef.instance.detectChanges();
  }

  #renderDialog(portals: PortalsToRender) {
    this.#dialogContainer = this.#dialog.open<DialogDrawerForDialogComponent>(DialogDrawerForDialogComponent, {
      data: {
        portals,
      },
    });

    this.#dialogContainer?.afterOpened().subscribe(() => {
      this.#renderedPortals = portals;
    });

    this.#dialogContainer?.afterClosed().subscribe((data?: { emit?: boolean }) => {
      if (data?.emit !== false) {
        this.#unRenderAll();
      }

      this.#dialogContainer = undefined;
    });
  }

  #unRenderAll() {
    this.#renderedPortals?.header.isAttached && this.#renderedPortals?.header?.detach();
    this.#renderedPortals?.content.isAttached && this.#renderedPortals?.content?.detach();
    this.#renderedPortals?.footer?.isAttached && this.#renderedPortals?.footer?.detach();
    this.#renderedPortals = undefined;

    this.#drawerContainer?.isAttached && this.#drawerContainer?.detach();
    this.#drawerContainer = undefined;
  }
}
