<div class="ui-sn__layout" [ngClass]="state$ | async">
  <div class="ui-sn__navbar">
    <ng-content select="[navbar]"></ng-content>
  </div>
  <div class="ui-sn__main">
    <div class="ui-sn__sidebar">
      <ng-content select="[sidebar]"></ng-content>
    </div>
    <div class="ui-sn__root">
      <ng-content></ng-content>
    </div>
  </div>
</div>
