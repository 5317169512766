<vdms-hq-ui-layout-page-wrapper [breadcrumbs]="[{ name: 'common.account_settings.security' }]">
  <div class="form-group">
    <vdms-hq-ui-loader mode="over-parent" [backdrop]="true" *ngIf="processing"></vdms-hq-ui-loader>
    <vdms-hq-ui-form-input-checkbox
      [formControl]="control"
      [label]="(control.value ? 'pages.settings.mfa_enabled' : 'pages.settings.mfa_disabled') | translate"
      (valueChange)="onChangeValue($event)"
    ></vdms-hq-ui-form-input-checkbox>

    <div class="factors-wrapper">
      <div class="factors-container">
        <div *ngFor="let factor of factors$ | async" class="single-factor">
          <span>{{ factor.displayName }}</span>
          <span>{{ factor.phoneNumber ?? (factor.factorId === 'totp' ? 'Authenticator' : factor.factorId) }}</span>
          <vdms-hq-ui-button
            [iconOnly]="true"
            icon="delete"
            color="transparent"
            (click)="delete(factor.uid)"
          ></vdms-hq-ui-button>
        </div>
      </div>
      <vdms-hq-ui-button (click)="add()" color="primary" *ngIf="control.value">{{
        'common.global.add_new' | translate
      }}</vdms-hq-ui-button>
    </div>
  </div>
</vdms-hq-ui-layout-page-wrapper>
