import { map } from 'rxjs/operators';
import {
  ApiChildResourcePaginatedResponse,
  ApiDetailedResourcePaginatedResponse,
  ApiLicensedPackageResponse,
  ApiResponse,
} from '../rest/api.model';
import { Observable } from 'rxjs';

/**
 * @summary Gets a response data from any ApiResponse
 * @param source
 * @constructor
 * @see ApiResponse
 */
export const GetResponseData = <T>(source: Observable<ApiResponse<T>>): Observable<T> =>
  source.pipe(map((response: ApiResponse<T>) => response.data));

export const GetUploadResponseData = <T, R>(source: Observable<ApiLicensedPackageResponse<T, R>>): Observable<R> =>
  source.pipe(map((response: ApiLicensedPackageResponse<T, R>) => response.data.uploadData));

export const GetResponsePaginationData = <T>(
  source: Observable<ApiChildResourcePaginatedResponse<T>>,
): Observable<{ data: T[]; total: number }> =>
  source.pipe(
    map((response: ApiChildResourcePaginatedResponse<T>) => ({ data: response.data, total: response.total })),
  );

export const GetDetailedResponsePaginationData = <T>(
  source: Observable<ApiDetailedResourcePaginatedResponse<T>>,
): Observable<{ data: T; total: number }> =>
  source.pipe(
    map((response: ApiDetailedResourcePaginatedResponse<T>) => ({ data: response.data, total: response.total })),
  );
