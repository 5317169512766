import { Injectable, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { CartApiService, DestinationApiService, DestinationConfigModel, DestinationModel } from '@vdms-hq/api-contract';
import { AuthService } from '@vdms-hq/auth';
import { DateValidator, emailsArrayValidator, SelectOption, SelectOptionKey } from '@vdms-hq/shared';
import moment from 'moment-timezone';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  merge,
  Observable,
  of,
  Subject,
  takeUntil,
  throwError,
} from 'rxjs';
import { catchError, filter, map, startWith, take, tap } from 'rxjs/operators';

type DestinationsFormModel = { configUuid: FormControl<string | null>; uuid: FormControl<string | null> };
type DeliveryFormType = {
  emails: FormControl<string[] | null>;
  expiryDate: FormControl<string | null>;
  deliveryMethod: FormControl<DeliveryMethods.MANUAL | DeliveryMethods.PREDEFINED | DeliveryMethods.WARM_UP | null>;
  subject: FormControl<string | null>;
  destinations: FormArray<FormGroup<DestinationsFormModel>>;
  purchaseOrder: FormControl<string | null>;
  comment: FormControl<string | null>;
  notificationEmails: FormControl<string[] | null>;
  expiry: FormControl<string | null>;
  deliveryDate: FormControl<string | null>;
  packageTitle: FormControl<string | null>;
  deliveryDelay: FormControl<string | null>;
};
export interface ValidationErrorList {
  type: 'error' | 'warning';
  message: string;
  assetUuid?: string;
  cartItemUuids?: string[];
  assetOriginalFilename?: string;
}
export enum DeliveryMethods {
  MANUAL = 'manual',
  PREDEFINED = 'predefined',
  WARM_UP = 'warm_up',
}

type DeliveryMethodsType = (typeof DeliveryMethods)[keyof typeof DeliveryMethods];

@Injectable({
  providedIn: 'root',
})
export class CartCheckoutFormService implements OnDestroy {
  #formInitialized = false;
  #destroy$ = new Subject<void>();
  #defaultExpiryDate = moment().add(7, 'd').toISOString();
  #form: FormGroup<DeliveryFormType> = new FormGroup({
    destinations: new FormArray([
      new FormGroup({
        uuid: new FormControl<string | null>(null, Validators.required),
        configUuid: new FormControl<string | null>(null, Validators.required),
      }),
    ]),
    emails: new FormControl<string[]>([], [Validators.required, emailsArrayValidator(true)]),
    notificationEmails: new FormControl<string[]>([], [emailsArrayValidator(true)]),
    packageTitle: new FormControl<string>('', Validators.required),
    purchaseOrder: new FormControl<string>('', Validators.required),
    subject: new FormControl<string>('', Validators.required),
    deliveryDelay: new FormControl<string>('immediate'),
    deliveryDate: new FormControl<string>(moment().add(15, 'm').toISOString()),
    deliveryMethod: new FormControl<DeliveryMethods | null>(null),
    expiry: new FormControl<string>('7'),
    expiryDate: new FormControl<string>(this.#defaultExpiryDate),
    comment: new FormControl<string>(''),
  });
  allDeliveriesConfig$ = new BehaviorSubject<DestinationConfigModel[]>([]);
  #allDeliveries$ = new BehaviorSubject<DestinationModel[]>([]);

  selectedDeliveryMethod$ = this.#form.controls.deliveryMethod.valueChanges;
  orderTitleValid$ = new BehaviorSubject<boolean>(false);
  purchaseOrderValid$ = new BehaviorSubject<boolean>(false);
  deliveryDatesChecked$ = new BehaviorSubject<boolean>(false);
  expiryDatesChecked$ = new BehaviorSubject<boolean>(false);

  destinationsValidated$ = new BehaviorSubject(false);
  requiresValidation$ = combineLatest([this.destinationsValidated$, this.selectedDeliveryMethod$])
    .pipe(map(([isValidated, deliveryMethod]) => !isValidated && deliveryMethod === DeliveryMethods.PREDEFINED))
    .pipe(startWith(false));

  isFormValid$ = new BehaviorSubject(this.#form.valid);
  isLoading$ = new BehaviorSubject(false);
  generalExpiryTimeOptions: SelectOption[] = [
    {
      key: '7',
      label: 'pages.order.expiry_one_week',
    },
    {
      key: '14',
      label: 'pages.order.expiry_two_weeks',
    },
    {
      key: '30',
      label: 'pages.order.expiry_one_month',
    },
    {
      key: 'custom',
      label: 'pages.order.expiry_custom',
    },
  ];
  expiryTimeOptions$: BehaviorSubject<SelectOption[]> = new BehaviorSubject<SelectOption[]>([]);
  deliveryMinDate = new Date().toISOString().split('T')[0];

  deliveryTimeOptions$: BehaviorSubject<SelectOption[]> = new BehaviorSubject<SelectOption[]>([]);
  deliveryTimeSelectOptions$: Observable<SelectOption[]> = this.activatedClientService.integrations$.pipe(
    filter((value) => !value.salesforce?.enabled),
    map(() => [
      {
        key: 'immediate',
        label: 'pages.order.deliver_now',
      },
      {
        key: 'delayed',
        label: 'pages.order.select_date',
      },
    ]),
  );

  validationErrorList$ = new BehaviorSubject<ValidationErrorList[] | null>(null);
  validationErrorAssetUuids$ = new BehaviorSubject<string[]>([]);
  hasValidationError$ = new BehaviorSubject(false);

  emailsPlaceholder = 'pages.order.email_placeholder';
  emailOptions: SelectOption[] = [];

  expiryMinDate = moment().add(1, 'd').toISOString().split('T')[0];
  expiryMaxDate = moment().add(90, 'd').toISOString().split('T')[0];
  submitted = false;

  get deliveryMethod() {
    switch (this.#form?.controls.deliveryMethod.value) {
      case DeliveryMethods.MANUAL:
        return 'Email delivery';
      case DeliveryMethods.PREDEFINED:
        return 'Delivery destinations';
      case DeliveryMethods.WARM_UP:
        return 'Warm up';
      default:
        return null;
    }
  }

  get orderTitle() {
    return this.#form?.controls.packageTitle.value;
  }

  get purchaseOrder() {
    return this.#form?.controls.purchaseOrder.value;
  }

  get deliveryTime() {
    switch (this.#form?.controls.deliveryDelay.value) {
      case 'immediate':
        return 'Right now';
      default:
        return new Date(this.#form?.controls.deliveryDate.value as string).toLocaleString(undefined, {
          timeStyle: 'medium',
          dateStyle: 'short',
        });
    }
  }

  get expiryTime() {
    switch (this.#form?.controls.expiry.value) {
      case '7':
        return '7 days';
      case '14':
        return '14 days';
      case '30':
        return 'One month';
      case 'custom':
        return new Date(this.#form?.controls.expiryDate.value as string).toLocaleString(undefined, {
          timeStyle: 'medium',
          dateStyle: 'short',
        });
      default:
        return null;
    }
  }

  get deliveryItems() {
    return this.#form?.controls.destinations.value.map((data) => {
      const config = this.allDeliveriesConfig$.value.find((config) => config.uuid === data.configUuid);
      const delivery = this.#allDeliveries$.value.find((delivery) => delivery.uuid === data.uuid);
      return {
        label: delivery?.name,
        config: config?.name,
      };
    });
  }

  get unavailableOptions(): string[] {
    return this.#form.controls.destinations.value.map((data) => (data?.configUuid ?? '') as string) || [];
  }

  get isFormValid() {
    return this.#form.valid;
  }

  get areDestinationsValid() {
    return this.#form.controls.destinations.length > 0 && this.#form.controls.destinations.valid;
  }

  get destinations() {
    return this.#form.controls.destinations;
  }

  get destinationsConfigs() {
    return this.destinations.value
      ?.map(({ configUuid, uuid }) => ({ configUuid, uuid }))
      ?.filter(({ uuid, configUuid }) => uuid && configUuid);
  }

  constructor(
    private activatedClientService: ActivatedClientService,
    private authService: AuthService,
    private destinationsService: DestinationApiService,
    private cartApiService: CartApiService,
  ) {}

  ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  resetForm() {
    this.#formInitialized = false;
    this.#form.controls.deliveryMethod.setValue(null);
    this.#form.controls.expiry.setValue('7');
    this.#form.controls.deliveryDelay.setValue('immediate');
    this.#form.controls.deliveryDate.setValue(moment().add(15, 'm').toISOString());
    this.#form.controls.expiryDate.setValue(this.#defaultExpiryDate);
    this.#form.controls.destinations.clear();
    this.#form.controls.destinations.push(
      new FormGroup({
        uuid: new FormControl<string | null>(null, Validators.required),
        configUuid: new FormControl<string | null>(null, Validators.required),
      }),
    );
    this.#form.controls.emails.setValue([]);
    this.#form.controls.notificationEmails.setValue([]);
    this.#form.controls.packageTitle.setValue('');
    this.#form.controls.purchaseOrder.setValue('');
    this.#form.controls.subject.setValue('');
    this.#form.controls.comment.setValue('');
    this.deliveryDatesChecked$.next(false);
    this.expiryDatesChecked$.next(false);
    this.resetValidationErrors();
  }

  resetValidationErrors() {
    this.validationErrorList$.next(null);
    this.hasValidationError$.next(false);
    this.destinationsValidated$.next(false);
  }

  getForm() {
    return this.authService.auth$.pipe(
      tap(() => {
        !this.#formInitialized && this.#initialize();
      }),
      take(1),
      map(() => this.#form as FormGroup<DeliveryFormType>),
    );
  }

  #initialize() {
    this.expiryTimeOptions$.next(this.#getGenericTimeOptions());
    this.#updateDeliveryTimeOptions();

    this.destinationsService
      .getAll()
      .pipe(
        take(1),
        tap((resp) => this.#allDeliveries$.next(resp.data)),
        takeUntil(this.#destroy$),
        map(({ data }) => data.map(({ configs }) => configs).reduce((prev, curr) => [...prev, ...curr], [])),
        tap((data) => this.allDeliveriesConfig$.next(data)),
      )
      .subscribe();

    this.cartApiService
      .getRecentEmails()
      .pipe(
        catchError(() => of([])),
        takeUntil(this.#destroy$),
        map((emails) => emails?.map((res: string) => ({ key: res, label: res }))),
        tap((resp) => (this.emailOptions = resp)),
      )
      .subscribe();

    this.#startListeners()
      .pipe(
        takeUntil(this.#destroy$),
        tap(() => (this.#formInitialized = true)),
      )
      .subscribe();
  }

  #startListeners() {
    const destinationsChanges$ = this.#form.controls.destinations.valueChanges.pipe(
      distinctUntilChanged(),
      tap(() => {
        this.destinationsValidated$.next(false);
      }),
    );

    const formChanges$ = this.#form.valueChanges.pipe(
      tap(() => {
        this.isFormValid$.next(this.#form.valid);
      }),
    );

    const orderTitleChanges$ = this.#form.controls.packageTitle.valueChanges.pipe(
      tap(() => {
        this.orderTitleValid$.next(this.#form.controls.packageTitle.valid);
      }),
    );

    const purchaseOrderChanges$ = this.#form.controls.purchaseOrder.valueChanges.pipe(
      tap(() => {
        this.purchaseOrderValid$.next(this.#form.controls.purchaseOrder.valid);
      }),
    );

    const deliveryMethodChanges$ = this.#form.controls.deliveryMethod.valueChanges.pipe(
      startWith('manual'),
      tap((method) => {
        this.#form.controls.emails.reset();
        this.#form.controls.notificationEmails.reset();
        this.#form.controls.destinations.clear();
        this.#form.controls.deliveryDelay.setValue('immediate');
        this.validationErrorList$.next(null);
        this.addDestination();

        if (method === 'predefined') {
          this.#form.controls.expiry.setValue('30');
          this.#form.controls.expiry.disable();
          this.#form.controls.destinations.enable();
        }

        if (method === 'manual') {
          this.#form.controls.expiry.setValue('7');
          this.#form.controls.expiry.enable();
          this.#form.controls.destinations.disable();
        }
      }),
    );

    const deliveryDelayChanges$ = this.#form.controls.deliveryDelay.valueChanges.pipe(
      tap((shipping) => {
        const validators = [Validators.required, DateValidator.dateNotPast()];

        if (shipping === 'delayed') {
          this.#form.controls.deliveryDate.addValidators(validators);
        } else if (shipping === 'immediate') {
          this.#form.controls.deliveryDate.removeValidators(validators);
          this.#form.controls.deliveryDate.setValue(moment().add(15, 'm').toISOString());
        }
        this.#form.updateValueAndValidity();
      }),
    );

    const expiryChanges$ = this.#form.controls.expiry.valueChanges.pipe(
      tap((expiry) => {
        switch (expiry) {
          case '14':
            this.#form.controls.expiryDate.setValue(moment().add(14, 'd').toISOString());
            break;
          case '30':
            this.#form.controls.expiryDate.setValue(moment().add(30, 'd').toISOString());
            break;
          case 'custom':
            this.#form.controls.expiryDate.setValue(moment().add(7, 'd').toISOString());
            break;
          default:
            this.#form.controls.expiryDate.setValue(moment().add(7, 'd').toISOString());
        }
        this.expiryDatesChecked$.next(true);
      }),
    );

    const emailsChanges$ = this.#form.controls.emails.valueChanges.pipe(
      tap((value) => {
        value?.length ? (this.emailsPlaceholder = '') : (this.emailsPlaceholder = 'pages.order.email_placeholder');
      }),
    );

    const deliveryDateChanges$ = this.#form.controls.deliveryDate.valueChanges.pipe(
      tap(() => {
        this.#form.controls.deliveryDelay.value === 'delayed' && this.#updateDeliveryTimeOptions();
      }),
    );

    return merge(
      destinationsChanges$,
      formChanges$,
      orderTitleChanges$,
      purchaseOrderChanges$,
      deliveryMethodChanges$,
      deliveryDelayChanges$,
      expiryChanges$,
      emailsChanges$,
      deliveryDateChanges$,
    );
  }

  addDestination() {
    this.#form.controls.destinations?.push(
      new FormGroup<DestinationsFormModel>({
        uuid: new FormControl<string | null>(null, Validators.required),
        configUuid: new FormControl<string | null>(null, Validators.required),
      }),
    );
  }

  removeDestination(index: number) {
    this.#form.controls.destinations.removeAt(index);
    this.completeEmailControls();
  }

  completeEmailControls() {
    const selectedDeliveries = this.#allDeliveries$.value
      .filter((delivery) =>
        this.#form.controls.destinations.value.some((destination) => destination.uuid === delivery.uuid),
      )
      .map((item) => item.email);

    const notificationEmails = selectedDeliveries.length
      ? selectedDeliveries.map((item) => item.notification).reduce((p, c) => p.concat(c))
      : [];
    const deliverEmails = selectedDeliveries.length
      ? selectedDeliveries.map((item) => item.delivery).reduce((p, c) => p.concat(c))
      : [];

    this.#form.controls.notificationEmails.setValue([...new Set(notificationEmails)]);
    this.#form.controls.emails.setValue([...new Set(deliverEmails)]);
  }

  #getGenericTimeOptions() {
    const timeOptions: SelectOption[] = [];
    const date = moment().utc();

    for (let h = 0; h < 24; ++h) {
      for (let m = 0; m < 60; m = m + 15) {
        date.hours(h);
        date.minutes(m);

        const key = date.format('HH:mm');

        timeOptions.push({
          key,
          label: key,
        });
      }
    }

    return timeOptions;
  }

  #updateDeliveryTimeOptions() {
    if (!this.#form.controls.deliveryDate?.value || this.#form.controls.deliveryDate?.invalid) {
      return;
    }

    const timeOptions: SelectOption[] = [];
    const selectedDate = moment(this.#form.controls.deliveryDate?.value);

    if (
      selectedDate.isSame(moment(), 'year') &&
      selectedDate.isSame(moment(), 'month') &&
      selectedDate.isSame(moment(), 'day')
    ) {
      const counter = moment(selectedDate);

      counter.minutes(Math.floor(counter.minutes() / 15) * 15);

      while (counter.day() == selectedDate.day()) {
        counter.add(15, 'm');

        const key = counter.format('HH:mm');

        timeOptions.push({
          key,
          label: key,
        });
      }
    } else if (selectedDate.isAfter(moment(), 'day')) {
      for (let h = 0; h < 24; ++h) {
        for (let m = 0; m < 60; m = m + 15) {
          selectedDate.hours(h);
          selectedDate.minutes(m);

          const key = selectedDate.format('HH:mm');

          timeOptions.push({
            key,
            label: key,
          });
        }
      }
    }

    this.deliveryTimeOptions$.next(timeOptions);
    this.deliveryDatesChecked$.next(true);
  }

  setDeliveryMethod(deliveryMethod: SelectOptionKey | string) {
    const method = deliveryMethod?.toString();
    const deliveryMethods: string[] = Object.values(DeliveryMethods);

    if (method && deliveryMethods.includes(method)) {
      this.#form.controls.deliveryMethod.setValue(method as DeliveryMethodsType);
    } else {
      this.#form.controls.deliveryMethod.setValue(DeliveryMethods.MANUAL);
    }
  }
}
