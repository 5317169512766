import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';

type OuterValue = number | null;
type InnerValue = number | null;

@Component({
  selector: 'vdms-hq-ui-form-discount-multiplier',
  templateUrl: './form-input-discount-multiplier.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputDiscountMultiplierComponent),
    },
  ],
})
export class FormInputDiscountMultiplierComponent extends FormControlValueAccessorComponent<OuterValue, InnerValue> {
  innerFormControl = new UntypedFormControl(null);
  min = 0;
  max = 100;

  override transformOuterToInner(value: OuterValue): InnerValue {
    if (value === null) return 0;
    return 100 - value * 100;
  }

  override transformInnerToOuter(value: InnerValue): OuterValue {
    if (value === null) return null;
    return (100 - value) / 100;
  }

  handleChanges(): void {
    if (this.innerFormControl?.value < this.min) {
      this.innerFormControl.setValue(this.min);
    }

    if (this.innerFormControl?.value > this.max) {
      this.innerFormControl.setValue(this.max);
    }
  }
}
