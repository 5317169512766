import { inject, Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  EMAIL_NOTIFICATION_TYPES,
  LicensedPackagesNotificationTypes,
  NotificationSubscription,
  NotificationSubscriptionsService,
  NotificationTypes,
  PatchNotificationSubscriptionInterface,
  PostNotificationSubscription,
} from '@vdms-hq/api-contract';
import { BehaviorSubject, catchError, forkJoin, Observable, take, tap, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from '@vdms-hq/toast';

@Injectable({ providedIn: 'root' })
export class NotificationSettingsFormService {
  #notificationsService = inject(NotificationSubscriptionsService);
  #toast = inject(ToastService);

  loading$ = new BehaviorSubject<boolean>(false);
  form = new FormGroup({
    NewAssetArrived: new FormGroup({
      uuid: new FormControl<string | null>(null),
      notification_event_type: new FormControl<NotificationTypes.NEW_ASSET_ARRIVED>(
        NotificationTypes.NEW_ASSET_ARRIVED,
      ),
      app_notifications_enabled: new FormControl<boolean>(false),
      email_notifications_enabled: new FormControl<boolean>(false),
      email_notification_type: new FormControl<EMAIL_NOTIFICATION_TYPES>(EMAIL_NOTIFICATION_TYPES.PER_OCCURRENCE),
    }),
    AssetIngestCompleted: new FormGroup({
      uuid: new FormControl<string | null>(null),
      notification_event_type: new FormControl<NotificationTypes.ASSET_INGEST_COMPLETED>(
        NotificationTypes.ASSET_INGEST_COMPLETED,
      ),
      app_notifications_enabled: new FormControl<boolean>(false),
      email_notifications_enabled: new FormControl<boolean>(false),
      email_notification_type: new FormControl<EMAIL_NOTIFICATION_TYPES>(EMAIL_NOTIFICATION_TYPES.PER_OCCURRENCE),
    }),
    NewLaunchpadPackagePendingApproval: new FormGroup({
      uuid: new FormControl<string | null>(null),
      notification_event_type: new FormControl<NotificationTypes.NEW_LAUNCHPAD_PACKAGE_PENDING_APPROVAL>(
        NotificationTypes.NEW_LAUNCHPAD_PACKAGE_PENDING_APPROVAL,
      ),
      app_notifications_enabled: new FormControl<boolean>(false),
      email_notifications_enabled: new FormControl<boolean>(false),
      email_notification_type: new FormControl<EMAIL_NOTIFICATION_TYPES>(EMAIL_NOTIFICATION_TYPES.PER_OCCURRENCE),
    }),
    LaunchpadPackageApproved: new FormGroup({
      uuid: new FormControl<string | null>(null),
      notification_event_type: new FormControl<NotificationTypes.LAUNCHPAD_PACKAGE_APPROVED>(
        NotificationTypes.LAUNCHPAD_PACKAGE_APPROVED,
      ),
      app_notifications_enabled: new FormControl<boolean>(false),
      email_notifications_enabled: new FormControl<boolean>(false),
      email_notification_type: new FormControl<EMAIL_NOTIFICATION_TYPES>(EMAIL_NOTIFICATION_TYPES.PER_OCCURRENCE),
    }),
    AssetAddedLicensedPackage: new FormGroup({
      uuid: new FormControl<string | null>(null),
      notification_event_type: new FormControl<LicensedPackagesNotificationTypes.ASSET_ADDED_LICENSED_PACKAGE>(
        LicensedPackagesNotificationTypes.ASSET_ADDED_LICENSED_PACKAGE,
      ),
      email_notifications_enabled: new FormControl<boolean>(false),
      email_notification_type: new FormControl<EMAIL_NOTIFICATION_TYPES>(EMAIL_NOTIFICATION_TYPES.PER_OCCURRENCE),
    }),
    RightsContractStart: new FormGroup({
      uuid: new FormControl<string | null>(null),
      notification_event_type: new FormControl<LicensedPackagesNotificationTypes.RIGHTS_CONTRACT_START>(
        LicensedPackagesNotificationTypes.RIGHTS_CONTRACT_START,
      ),
      email_notifications_enabled: new FormControl<boolean>(false),
      email_notification_type: new FormControl<EMAIL_NOTIFICATION_TYPES>(EMAIL_NOTIFICATION_TYPES.PER_OCCURRENCE),
    }),
    RightsContractEnd: new FormGroup({
      uuid: new FormControl<string | null>(null),
      notification_event_type: new FormControl<LicensedPackagesNotificationTypes.RIGHTS_CONTRACT_END>(
        LicensedPackagesNotificationTypes.RIGHTS_CONTRACT_END,
      ),
      email_notifications_enabled: new FormControl<boolean>(false),
      email_notification_type: new FormControl<EMAIL_NOTIFICATION_TYPES>(EMAIL_NOTIFICATION_TYPES.PER_OCCURRENCE),
    }),
  });

  getUserSubscriptions() {
    this.loading$.next(true);
    this.#notificationsService.getUserSubscriptions().subscribe(({ data }) => {
      data.forEach(
        ({
          uuid,
          notification_event_type,
          app_notifications_enabled,
          email_notification_type,
          email_notifications_enabled,
        }) => {
          const controlExist = this.form.controls[notification_event_type];
          controlExist &&
            controlExist.patchValue({
              uuid,
              app_notifications_enabled,
              email_notifications_enabled,
              email_notification_type:
                email_notification_type === 'per_occurrence'
                  ? EMAIL_NOTIFICATION_TYPES.PER_OCCURRENCE
                  : EMAIL_NOTIFICATION_TYPES.DAILY_SUMMARY,
            });
        },
      );
      this.loading$.next(false);
    });
  }

  save() {
    if (this.form.invalid || this.form.pristine) {
      return;
    }

    this.#save();
  }

  #save() {
    this.loading$.next(true);
    this.form.disable({ emitEvent: false });
    const requests$ = this.#formToRequest() as Array<Observable<unknown>>;

    forkJoin(requests$)
      .pipe(
        take(1),
        catchError((error) => {
          this.#displaySettingsError(error);
          this.loading$.next(false);
          this.form.enable({ emitEvent: false });
          this.form.markAsPristine();
          return throwError(() => error);
        }),
      )
      .subscribe(() => {
        this.form.markAsPristine();
        this.loading$.next(false);
        this.form.enable({ emitEvent: false });
      });
  }

  #formToRequest() {
    return Object.entries(this.form.value)
      .map(([, value]) => this.#itemToRequest(value as Partial<NotificationSubscription>))
      .filter(Boolean);
  }

  #itemToRequest = (changes: Partial<NotificationSubscription>) => {
    if (!changes.uuid && !changes.email_notifications_enabled && !changes.app_notifications_enabled) {
      return null;
    }

    if (!changes.uuid) {
      const payload = <PostNotificationSubscription>{
        app_notifications_enabled: changes?.app_notifications_enabled,
        email_notifications_enabled: changes.email_notifications_enabled,
        email_notification_type: changes.email_notification_type,
        notification_event_type: changes.notification_event_type,
      };
      return this.#notificationsService.createSubscription(payload).pipe(
        tap((response) => {
          const controlExist = this.form.controls[response.data.notification_event_type];
          controlExist && controlExist.controls.uuid.setValue(response.data.uuid, { emitEvent: false });
          this.form.updateValueAndValidity({ emitEvent: false });
        }),
      );
    }

    if (!changes.app_notifications_enabled && !changes.email_notifications_enabled && changes.uuid) {
      return this.#notificationsService.deleteSubscription(changes.uuid).pipe(
        tap(() =>
          this.form.controls[changes.notification_event_type as NotificationTypes].controls.uuid.setValue(null, {
            emitEvent: false,
          }),
        ),
      );
    }

    const payload = <PatchNotificationSubscriptionInterface>{
      app_notifications_enabled: changes.app_notifications_enabled,
      email_notifications_enabled: changes.email_notifications_enabled,
      email_notification_type: changes.email_notification_type,
    };
    return this.#notificationsService.updateSubscription(changes.uuid as string, payload).pipe(
      tap((response) => {
        const controlExist = this.form.controls[response.data.notification_event_type];
        controlExist && controlExist.controls.uuid.setValue(response.data.uuid, { emitEvent: false });
        this.form.updateValueAndValidity({ emitEvent: false });
      }),
    );
  };

  #displaySettingsError(err: HttpErrorResponse) {
    this.#toast.error({ id: err.url ?? 'settings_save', message: err.message });
  }
}
