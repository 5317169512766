import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StreamsType } from './streams/streams-type';

export interface MetadataItemOnTimelineSelected {
  type: StreamsType;
  content: string;
  languageKey: string;
}

@Injectable({ providedIn: 'root' })
export class ShowOnTimelineService {
  timelineSelected$ = new BehaviorSubject<MetadataItemOnTimelineSelected | null>(null);
}
