import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { API_CONFIG, ApiConfig } from '../config-token';
import { Observable } from 'rxjs';
import { CacheControlState } from '../fetcher/cache-control.model';
import { shorthandUrl } from './helper/shorthand-url';

@Injectable()
export class CacheControlInterceptor implements HttpInterceptor {
  constructor(@Inject(API_CONFIG) private env: ApiConfig) {
    CacheControlState.clear();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newRequest = request.clone();
    const api = shorthandUrl(this.env.apiUrl, request);
    if (CacheControlState.isCached(api, request)) {
      const headers = request.headers.append('Cache-Control', 'max-age=0');
      newRequest = request.clone({ headers });
    } else if (api !== '') {
      CacheControlState.toggle(api, true);
    }
    CacheControlState.setLastRequest(request);
    return next.handle(newRequest);
  }
}
