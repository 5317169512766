<div class="data">
  <span class="data__major">
    {{ statsStaticVM.majorData }}
  </span>

  <span class="data__description">
    {{ statsStaticVM.description }}
  </span>

  <span class="data__minor">{{ statsStaticVM.minorData }}</span>
</div>
