import { StreamsType } from '../streams/streams-type';

export const METADATA_TYPES = [
  {
    key: StreamsType.MI_TRANSCRIPTION,
    label: 'MI Transcribe',
  },
  {
    key: StreamsType.MI_SUBTITLES,
    label: 'MI Subtitles',
  },
  {
    key: StreamsType.MI_OBJECTS_RECOGNITION,
    label: 'MI Object recognition',
  },
  {
    key: StreamsType.MI_CELEBRITY_RECOGNITION,
    label: 'MI Celebrity recognition',
  },
  {
    key: StreamsType.MI_TECHNICAL_CUE_DETECTION,
    label: 'MI Technical cue detection',
  },
  {
    key: StreamsType.MI_SHOT_DETECTION,
    label: 'MI Shot detection',
  },
  {
    key: StreamsType.CONTENT_MODERATION,
    label: 'Content moderation',
  },
  {
    key: StreamsType.TRANSCRIPTION,
    label: 'Transcription',
  },
];

export const METADATA_CONFIDENCE_THRESHOLD = [
  {
    key: '100',
    label: '100%',
  },
  {
    key: '95',
    label: 'Over 95%',
  },
  {
    key: '90',
    label: 'Over 90%',
  },
  {
    key: '85',
    label: 'Over 85%',
  },
  {
    key: '85',
    label: 'Over 85%',
  },
  {
    key: '80',
    label: 'Over 80%',
  },
  {
    key: '75',
    label: 'Over 75%',
  },
  {
    key: '70',
    label: 'Over 70%',
  },
];
