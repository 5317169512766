import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIButtonModule } from '@vdms-hq/ui';
import { MatMenuModule } from '@angular/material/menu';
import { BdcWalkModule, BdcWalkPopupComponent, BdcWalkService } from 'bdc-walkthrough';
import { TourCfg } from '../models/tour-config';
import { TourGuideSettingsService } from '../logic/tour-guide-settings.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'vdms-hq-tour-item',
  standalone: true,
  imports: [CommonModule, UIButtonModule, MatMenuModule, BdcWalkModule],
  templateUrl: './tour-item.component.html',
  styleUrls: ['./tour-item.component.scss'],
})
export class TourItemComponent extends BdcWalkPopupComponent implements AfterViewInit, OnDestroy {
  tourCfg?: TourCfg;
  showExit = true;

  destroy$ = new Subject<void>();

  @Input() set configName(name: string) {
    const tourCfg = this.tourGuideSettingsService.settings.find((item) => item.name === name);

    if (!tourCfg) {
      return;
    }

    this.mustCompleted = tourCfg?.mustCompleted ?? null;
    this.tourCfg = tourCfg;
    this.name = name;

    this.closeOnClick = false;
    if (tourCfg?.clickToComplete) {
      this.showButton = false;
      this.closeOnClick = true;
      this.showExit = false;
    } else {
      this.showCloseButton = false;
      this.showExit = true;
      this.showButton = true;
      this.buttonText = 'Next';
    }

    this.buttonText = tourCfg?.buttonText ?? this.buttonText;
    this.xPosition = tourCfg?.xPosition ?? 'before';
    this.yPosition = tourCfg?.yPosition ?? 'below';
    this.horizontal = tourCfg?.horizontal ?? false;
    this.showButton = tourCfg?.button ?? this.showButton;
  }

  constructor(
    private service: BdcWalkService,
    private tourGuideSettingsService: TourGuideSettingsService,
    private router: Router,
  ) {
    super(service);
  }

  close(): void {
    this.trigger.close(true);
  }

  exitTour(): void {
    this.service.disableAll(true);
  }

  ngAfterViewInit(): void {
    this.#reRenderHandler();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  #reRenderHandler(): void {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe(() => (this.configName = this.name));

    this.service.changes.pipe(takeUntil(this.destroy$)).subscribe(() => (this.configName = this.name));
  }
}
