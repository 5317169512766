import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIButtonModule, WrapperHeaderAction } from './../../../index';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActionIdentifier } from '../../ui-results-wrapper/logic/wrapper.model';

@Component({
  selector: 'vdms-hq-ui-data-header-actions',
  templateUrl: './data-header-actions.component.html',
  styleUrls: ['./data-header-actions.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, UIButtonModule, MatTooltipModule],
})
export class UIDataHeaderActionsComponent {
  @Input() headerClass = false;
  @Input() actions: WrapperHeaderAction[] = [];
  @Output() headerAction = new EventEmitter<{ event: MouseEvent; key: ActionIdentifier }>();
}
