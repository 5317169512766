import { InjectionToken, Provider } from '@angular/core';

export interface OrdersConfig {
  cdn: string;
}

export const ORDERS_CONFIG: InjectionToken<OrdersConfig> = new InjectionToken('ORDERS_CONFIG');

export const configureOrders = (config: OrdersConfig): Provider[] => {
  return [{ provide: ORDERS_CONFIG, useValue: config }];
};
