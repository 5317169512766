<vdms-hq-ui-results-wrapper
  [dataSource]="credentialsDataSource"
  [breadcrumbs]="[{ name: ('common.credentials.title' | translate) }]"
  [configuration]="headerConfig"
  (headerAction)="handleAction($event)"
>
  <vdms-hq-ui-loader
    *ngIf="credentialsDataSource.isLoading$ | async"
    [backdrop]="true"
    mode="over-parent"
  ></vdms-hq-ui-loader>
  <vdms-hq-ui-multiple-data-presentation
    [dataSource]="credentialsDataSource"
    [configuration]="viewConfig"
    (action)="handleAction($event)"
  >
  </vdms-hq-ui-multiple-data-presentation>
</vdms-hq-ui-results-wrapper>
