import { inject, Injectable } from '@angular/core';
import { of, catchError, map, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';

enum EXTERNAL_API_PATHS {
  COUNTRIES = 'https://countriesnow.space/api/v0.1/countries/iso',
}

interface IExternalCountriesResponse {
  data: ICountry[];
  error: boolean;
  msg: string;
}

interface ICountry {
  name: string;
  Iso2: string;
  Iso3: string;
}

@Injectable({
  providedIn: 'root',
})
export class ExternalApiService {
  httpClient = inject(HttpClient);
  #countries: string[] = [];

  getCountries() {
    if (this.#countries.length) {
      return of(this.#countries);
    }
    return this.httpClient.get<IExternalCountriesResponse>(EXTERNAL_API_PATHS.COUNTRIES).pipe(
      map((countries) => countries.data.map((c) => c.name).sort()),
      tap((countries) => (this.#countries = countries || [])),
      catchError(() => of([])),
    );
  }
}
