<div class="timeline">
  <h3>{{ title | translate }}</h3>
  <ng-container *ngIf="timelines?.length; else empty">
    <ol class="numbered">
      <li *ngFor="let result of timelines">
        {{ result.date | date : 'dd-MM-yyyy hh:mm a' }}
      </li>
    </ol>
  </ng-container>
  <ng-template #empty>
    <vdms-hq-ui-empty-results></vdms-hq-ui-empty-results>
  </ng-template>
</div>
