<div class="navbar-container">
  <button
    mat-button
    type="button"
    data-tourrole-toggle-sidebar
    [bdcWalkTriggerFor]="tourConfig.menuSidebarToggle"
    (click)="toggleSidenav.emit()"
    class="navbar-item"
  >
    <ng-container [ngSwitch]="sidenavState">
      <mat-icon *ngSwitchCase="SidebarStatusEnum.Closed" class="menu-switch">menu</mat-icon>
      <mat-icon *ngSwitchCase="SidebarStatusEnum.Collapsed" class="menu-switch">close</mat-icon>
      <mat-icon *ngSwitchCase="SidebarStatusEnum.Hovered" class="menu-switch">close</mat-icon>
      <mat-icon *ngSwitchCase="SidebarStatusEnum.Expanded" class="menu-switch">menu_open</mat-icon>
    </ng-container>
  </button>

  <div class="navbar-logo">
    <ng-content select="[logo]"></ng-content>
  </div>
  <div class="search-container">
    <ng-content select="[search]"></ng-content>
  </div>
  <ng-content select="[clients]"></ng-content>
  <ng-content select="[notificationsButton]"></ng-content>
  <ng-content select="[cartButton]"></ng-content>
  <button
    mat-button
    type="button"
    [bdcWalkTriggerFor]="tourConfig.userPanel"
    [matMenuTriggerFor]="menu"
    class="navbar-item identity-menu"
    #menuButton
  >
    <div class="identity-menu-content">
      <mat-icon>perm_identity</mat-icon>
      <span class="user-name">{{ displayName }}</span>
      <mat-icon>keyboard_arrow_down</mat-icon>
    </div>
  </button>
</div>

<mat-menu #menu="matMenu" xPosition="after">
  <div class="identity-menu-items" [style.width.px]="buttonWidth">
    <button *ngIf="theme" mat-menu-item class="menu-item" (click)="toggleTheme.emit()">
      {{
        (theme === 'theme-dark' ? 'common.nav.switch_theme_to_white' : 'common.nav.switch_theme_to_dark') | translate
      }}
    </button>
    <ng-content select="[menuOptions]"></ng-content>
    <button mat-menu-item class="menu-item menu-item-warn" (click)="logout.emit()">
      {{ 'pages.home.menu.logout' | translate }}
    </button>
  </div>
</mat-menu>
