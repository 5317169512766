import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VoiceAiService } from '../../logic/voice-ai-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface VimeoDialogData {
  assetVimeoUrl: string;
}

@Component({
  selector: 'vdms-hq-suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionComponent implements OnInit, OnDestroy {
  @Input() content?: string | null;
  @Input() selectedContent: string | null = null;
  @Output() selectContent = new EventEmitter<string>();

  isVoiceReader = false;
  #destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: VimeoDialogData,
    public voiceAiService: VoiceAiService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.voiceAiService.selectedVoice$.pipe(takeUntil(this.#destroy$)).subscribe(() => {
      this.isVoiceReader = false;
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }
}
