import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Folder } from '../logic/ui-folder-model';
import { UILoaderModule } from '../../ui-loader';
import { UIPipesModule } from '../../ui-pipes/ui-pipes.module';
import { CalculateFoldersContainerHeightPipe } from '../logic/calculate-folders-container-height.pipe';
import { FloatingControlsV2Component } from '../../ui-data-presentation/components/floating-controls-v2/floating-controls-v2.component';
import { DataAction } from '../../ui-data-presentation';
import { ActionIdentifier } from '../../ui-results-wrapper';

@Component({
  selector: 'vdms-hq-ui-folder',
  templateUrl: './ui-folder.component.html',
  styleUrls: ['./ui-folder.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    UILoaderModule,
    ScrollingModule,
    UIPipesModule,
    MatTooltipModule,
    CalculateFoldersContainerHeightPipe,
    FloatingControlsV2Component,
  ],
})
export class UIFolderComponent implements OnChanges {
  @Input() loading = false;
  @Input() actions?: DataAction<Folder>[];
  @Input() customIconPath!: string;
  @Input() perRow = 4;
  @Output() clickSegment = new EventEmitter<Folder>();
  @Output() action = new EventEmitter<{
    key: ActionIdentifier;
    item: Folder;
  }>();
  @Input() folders: Folder[] = [];
  folderRows: Folder[][] = [];

  ngOnChanges(changes: SimpleChanges): void {
    this.foldersToRows(changes.folders.currentValue);
  }

  emitAction(key: ActionIdentifier, item: Folder) {
    this.action.emit({ key, item });
  }

  foldersToRows(folders: Folder[]) {
    this.folderRows = [];
    for (let i = 0; i < folders.length; i += this.perRow) {
      this.folderRows.push(folders.slice(i, i + this.perRow));
    }
  }
}
