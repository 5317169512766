<mat-form-field
  appearance="outline"
  class="material-custom-field"
  [class.hide-footer]="!withFooter"
  [class.with-touched-indicator]="withTouchedIndicator"
  [class.new-field]="newField && innerFormControl.pristine"
  [class.ng-touched]="selectedAndVirtualScrollEnabled"
  [class.ng-dirty]="selectedAndVirtualScrollEnabled"
  [class.mat-form-field-should-float]="selectedLabels?.length"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <vdms-hq-ui-loader matPrefix [size]="20" *ngIf="loading || innerFormControl.pending"></vdms-hq-ui-loader>

  <div class="custom-select-trigger" *ngIf="selectedAndVirtualScrollEnabled">
    <ng-container [ngTemplateOutlet]="customTriggerRef"></ng-container>
  </div>

  <mat-select
    [panelClass]="virtualScroll ? 'virtual-scroll' : ''"
    [multiple]="multiple"
    (blur)="propagateTouch()"
    [formControl]="innerFormControl"
    [placeholder]="placeholder"
    [required]="isRequired"
    data-testid="FormInputSelect:select"
    #selector
  >
    <div class="selector-filter" *ngIf="selectOptions && selectOptions.length > requiredOptionsLengthToDisplayFilter">
      <mat-form-field class="material-custom-field material-custom-field-tiny w100 sticky" appearance="outline">
        <mat-label>{{ 'common.fields.select_filter' | translate }}</mat-label>
        <vdms-hq-ui-loader matPrefix [size]="20" *ngIf="loading || innerFormControl.pending"></vdms-hq-ui-loader>
        <input
          (keydown)="$event.stopPropagation()"
          matInput
          placeholder="..."
          [(ngModel)]="filterValue"
          (ngModelChange)="emitInputChange()"
          #input
        />
      </mat-form-field>
    </div>

    <mat-select-trigger>
      <ng-container *ngIf="!virtualScroll">
        <ng-container [ngTemplateOutlet]="customTriggerRef"></ng-container>
      </ng-container>
    </mat-select-trigger>

    <ng-container *ngIf="!virtualScroll">
      <ng-container *ngIf="selectOptions | filterByProp : filterValue : 'label'; let filteredValues">
        <mat-option
          [matTooltip]="item?.label | translate"
          [matTooltipDisabled]="!item?.label?.length || label.offsetWidth === label.scrollWidth"
          *ngFor="let item of filteredValues | sortByExceptNull : (isSortedByLabel ? '' : 'label'); trackBy: trackBy"
          [value]="item?.key"
          [disabled]="!!item?.disabled"
          (onSelectionChange)="onSelectionChange($event)"
        >
          <span
            class="label"
            #label
            [ngClass]="{ empty: !item['key'], disabled: !!item?.disabled }"
            [innerHTML]="(item?.disabled && disabledMsg !== '' ? disabledMsg : '') + ' ' + (item?.label | translate)"
          ></span>
          <span class="suffix">{{ item['suffix'] }}</span>
        </mat-option>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="virtualScroll">
      <div
        class="viewport-outer"
        [style.height]="filteredElementsLength(filteredValues) * ITEM_SIZE_PX + 'px'"
        *ngIf="selectOptions | filterByProp : filterValue : 'label' : innerFormControl.value; let filteredValues"
      >
        <cdk-virtual-scroll-viewport [itemSize]="ITEM_SIZE_PX" minBufferPx="1500" maxBufferPx="2000" class="viewport">
          <mat-option
            [matTooltip]="item.label | translate"
            [matTooltipDisabled]="!item.label.length || label.offsetWidth === label.scrollWidth"
            *cdkVirtualFor="let item of filteredValues; trackBy: trackBy; templateCacheSize: 0"
            [value]="item.key"
            [disabled]="!!item.disabled"
            [class.hidden]="item?.hidden"
            (onSelectionChange)="onSelectionChange($event)"
          >
            <span class="label" #label [class.empty]="!item.key" [innerHTML]="item.label | rename | translate"></span>
            <span class="suffix">{{ item.suffix }}</span>
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </div>
    </ng-container>

    <div class="list-footer" *ngIf="enableDeselectAll || enableSelectAll || enableClear">
      <vdms-hq-ui-button *ngIf="enableDeselectAll" (click)="deselectAll()" class="select-deselect-all"
        >Deselect all
      </vdms-hq-ui-button>
      <vdms-hq-ui-button *ngIf="enableSelectAll" (click)="selectAll()" class="select-deselect-all"
        >Select all
      </vdms-hq-ui-button>
      <vdms-hq-ui-button *ngIf="enableClear" (click)="clear()" class="select-deselect-all">Clear</vdms-hq-ui-button>
    </div>
  </mat-select>

  <mat-hint *ngIf="withFooter && hint">{{ hint }}</mat-hint>
  <mat-error *ngIf="withFooter">
    <span *ngFor="let error of errors">{{ error.transLang | translate : error.contextParams }}</span>
  </mat-error>
</mat-form-field>

<ng-template #customTriggerRef>
  <ng-container *ngIf="selectedLabels; let selectedLabels">
    <span
      [innerHTML]="multiple ? (selectedLabels[0] | rename | translate) : (selectedLabels[0] | rename | translate)"
    ></span>
    <span *ngIf="(selectedLabels?.length || 0) > 1" class="additional-selection">
      (+{{ (selectedLabels?.length || 0) - 1 }} {{ selectedLabels?.length === 2 ? 'other' : 'others' }})
    </span>
  </ng-container>
</ng-template>
