import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { ApiResponse } from '../api.model';
import { GetResponseData } from '../../operators/get-response-data.operator';
import {
  CredentialTypeEnum,
  GetCredentialInterface,
  PatchCredentialInputInterface,
  PostCredentialInputInterface,
} from './credentials.model';
import { CREDENTIALS_ENDPOINTS } from './credentials-endpoints.model';

@Injectable({ providedIn: 'root' })
export class CredentialsApiService {
  constructor(private readonly apiService: ApiService) {}

  getAll = () =>
    this.apiService
      .get<ApiResponse<GetCredentialInterface<CredentialTypeEnum>[]>>(`${CREDENTIALS_ENDPOINTS.ROOT_PLURAL}`)
      .pipe(GetResponseData);

  getByType = (type: CredentialTypeEnum) =>
    this.apiService
      .get<ApiResponse<GetCredentialInterface<CredentialTypeEnum>[]>>(`${CREDENTIALS_ENDPOINTS.ROOT_PLURAL}/${type}`)
      .pipe(GetResponseData);

  getByUuid = (uuid: string) =>
    this.apiService
      .get<ApiResponse<GetCredentialInterface<CredentialTypeEnum>>>(`${CREDENTIALS_ENDPOINTS.ROOT}/${uuid}`)
      .pipe(GetResponseData);

  create = (body: PostCredentialInputInterface<CredentialTypeEnum>) =>
    this.apiService
      .post<PostCredentialInputInterface<CredentialTypeEnum>, ApiResponse<GetCredentialInterface<CredentialTypeEnum>>>(
        `${CREDENTIALS_ENDPOINTS.ROOT}`,
        body,
      )
      .pipe(GetResponseData);

  update = (uuid: string, body: PatchCredentialInputInterface<CredentialTypeEnum>) =>
    this.apiService
      .patch<
        PatchCredentialInputInterface<CredentialTypeEnum>,
        ApiResponse<GetCredentialInterface<CredentialTypeEnum>>
      >(`${CREDENTIALS_ENDPOINTS.ROOT}/${uuid}`, body)
      .pipe(GetResponseData);

  delete = (uuid: string) => this.apiService.delete(`${CREDENTIALS_ENDPOINTS.ROOT}/${uuid}`);
}
