import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { ApiResponse } from '../api.model';
import { Observable } from 'rxjs';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { EmbedParams, PowerBiEndpoints, PowerBiRoot } from './power-bi.model';

@Injectable({ providedIn: 'root' })
export class PowerBiService {
  constructor(private readonly apiService: ApiService) {}

  getEmbedParams = (reportId: string): Observable<EmbedParams> =>
    this.apiService.get<ApiResponse<EmbedParams>>(`${PowerBiRoot.ROOT}/${PowerBiEndpoints.EmbedToken}/${reportId}`).pipe(GetResponseData);
}
