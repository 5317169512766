import { Injectable } from '@angular/core';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { ColumnsFetcherService } from './columns-fetcher.service';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ColumnSettingsScope } from '@vdms-hq/firebase-contract';
import { ColumnsConfigService } from './columns-config.service';
import { FieldsConfigService } from '@vdms-hq/config';

@Injectable()
export class ColumnsSettingsService {
  getGridMetadata$ = (scopeName: ColumnSettingsScope) => this.fieldsConfigService.gridMetadata2$(scopeName);

  getColumns$ = (scopeName: ColumnSettingsScope, hiddenColumns: string[] = []) =>
    this.fieldsConfigService.columns2$(scopeName, hiddenColumns);
  getEnabledColumns$ = (scopeName: ColumnSettingsScope) =>
    combineLatest([
      this.activatedClientService.getColumns$(scopeName),
      this.columnsFetcherService.userConfig$(scopeName),
    ]).pipe(map(([clientConfig, userConfig]) => (userConfig.length > 0 ? userConfig : clientConfig?.default ?? [])));

  constructor(
    private fieldsConfigService: FieldsConfigService,
    private activatedClientService: ActivatedClientService,
    private columnsFetcherService: ColumnsFetcherService,
    private columnsConfigService: ColumnsConfigService,
  ) {}

  openColumnsSettingsPopup(scopeName: ColumnSettingsScope) {
    this.columnsConfigService.popColumnsConfigDialog(scopeName);
  }
}
