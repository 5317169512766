<ng-container *ngIf="listConfig; let list">
  <ng-container *ngFor="let item of list">
    <span [class.w-full]="item.size === 'wide'"
      >{{ item.title | translate }}:
      <span [class.empty]="!item.value">{{
        item.value
          ? item.type === 'date' || item.type === 'date-time'
            ? (item.value | date : (item.type === 'date-time' ? 'dd-MM-yyyy hh:mm a' : 'dd-MM-yyyy'))
            : (item.value.toString() | translate)
          : ('N/A' | truncate : 250)
      }}</span></span
    >
  </ng-container>
</ng-container>
