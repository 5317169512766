<div class="wrapper">
  <vdms-hq-ui-form-input-text-area
    [formControl]="innerFormControl"
    [label]="label"
    [placeholder]="placeholder"
    [hint]="hint"
    [withFooter]="withFooter"
    [withTouchedIndicator]="withTouchedIndicator"
  >
  </vdms-hq-ui-form-input-text-area>

  <vdms-hq-ui-button
    [iconOnly]="true"
    icon="auto_awesome"
    class="action-icon"
    tooltip="Would you like to try with AI?"
    (click)="openSummaryAIDialog()"
  ></vdms-hq-ui-button>
</div>
