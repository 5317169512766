import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientSwitchViewModel } from '../../../logic/models/client-switch.view-model';
import { ClientRef } from '../../../logic/models/client.model';
import { EMPTY, Observable } from 'rxjs';

@Component({
  selector: 'vdms-hq-ac-client-switch-view',
  templateUrl: './client-switch-view.component.html',
  styleUrls: ['./client-switch-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientSwitchViewComponent {
  @Input() public viewModel$: Observable<ClientSwitchViewModel> = EMPTY;
  @Output() public clientChanged: EventEmitter<ClientRef> = new EventEmitter<ClientRef>();
}
