import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vdms-hq-shared-days-ago-displaying[days]',
  templateUrl: './days-displaying.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class DaysDisplayingComponent {
  @Input() days!: number;
}
