import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UIErrorsModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { MfaDialogComponent, MfaDialogOutput } from '@vdms-hq/user-settings';

@Component({
  selector: 'vdms-hq-errors-not-authorized',
  standalone: true,
  imports: [CommonModule, UIErrorsModule, TranslateModule],
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss'],
})
export class NotAuthorizedComponent implements OnInit {
  matDialog = inject(MatDialog);
  router = inject(Router);

  ngOnInit(): void {
    this.matDialog
      .open<MfaDialogComponent, unknown, MfaDialogOutput>(MfaDialogComponent, { disableClose: true })
      .afterClosed()
      .subscribe((value) => {
        if (value?.added) {
          this.router.navigate(['/']);
        }
      });
  }
}
