import { SelectOption, SelectOptionKey } from '../models/selector.model';

export const pairWithDefinition = (items: SelectOptionKey[], definitions: SelectOption[]) => {
  return items
    .map((key) => definitions.find((fromAll) => fromAll.key === key))
    .filter((item) => !!item) as SelectOption[];
};

export const exclude = (items: SelectOptionKey[], exclude: SelectOptionKey[]) => {
  return items.filter((item) => exclude.indexOf(item) === -1) as SelectOptionKey[];
};
