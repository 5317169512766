<vdms-hq-ui-dialog-wrapper footerAlign="flex-end" size="wide">
  <div title>
    <div class="title">Generate description with a little help from the AI</div>
    <div class="description">Description will be generated basing on the latest transcription for this asset</div>
  </div>

  <div content>
    <ng-container *ngIf="dataSourceService.defaultTranscriptionUuid$ | async; else transcriptionNotFound">
      <div class="form">
        <vdms-hq-ui-form-input-select
          class="input-select"
          label="Select description length"
          [selectOptions]="(modelsSelectOptions$ | async) ?? []"
          [withFooter]="false"
          [(ngModel)]="selectedModel"
          [disabled]="triggeringNew"
        >
        </vdms-hq-ui-form-input-select>
        <vdms-hq-ui-form-input-select
          class="input-select"
          label="Select description tone"
          [selectOptions]="(descriptionToneSelectOptions$ | async) ?? []"
          [withFooter]="false"
          [disabled]="!selectedModel || triggeringNew"
          [(ngModel)]="selectedToneModel"
        >
        </vdms-hq-ui-form-input-select>
        <vdms-hq-ui-button (click)="triggerNew()" color="primary" [disabled]="triggeringNew || !selectedModel">{{
          'common.global.generate' | translate
        }}</vdms-hq-ui-button>
        <vdms-hq-ui-loader *ngIf="triggeringNew"></vdms-hq-ui-loader>
      </div>

      <div class="form">
        <vdms-hq-ui-form-input-select
          class="input-select"
          label="Select voice to read"
          [selectOptions]="(voicesSelectOptions$ | async) ?? []"
          [withFooter]="false"
          [(ngModel)]="selectedVoice"
          (ngModelChange)="voiceAiService.selectedVoice$.next(this.selectedVoice ?? null)"
        ></vdms-hq-ui-form-input-select>
      </div>

      <div class="content">
        <vdms-hq-suggestion
          *ngFor="let suggestion of suggestions"
          [content]="suggestion"
          [selectedContent]="selectedSuggestion"
          (selectContent)="selectedSuggestion = $event"
        >
        </vdms-hq-suggestion>
      </div>
    </ng-container>

    <ng-template #transcriptionNotFound>
      <vdms-hq-ui-empty-results message="Please generate transcription for this asset"> </vdms-hq-ui-empty-results>
    </ng-template>
  </div>

  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="cancel()">{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button>
    <vdms-hq-ui-button (click)="close()" color="primary" [disabled]="!selectedSuggestion">{{
      'common.global.confirm' | translate
    }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
