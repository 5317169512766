export class MissingUserDataException extends Error {
  readonly missingInformation = true;
  constructor(missingInfo: string, public email?: string) {
    super(`Authenticated user has missing information: ${missingInfo}`);
  }
}


export const isMissingUserDataException = (obj: any): obj is MissingUserDataException => {
  return 'missingInformation' in obj;
}
