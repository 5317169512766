import { Injectable } from '@angular/core';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { AuthService } from '@vdms-hq/auth';
import { UserContractService, UserClientFiltersConfig } from '@vdms-hq/firebase-contract';
import { filterEmpty } from '@vdms-hq/shared';

@Injectable({
  providedIn: 'root',
})
export class FiltersFetcherService {
  constructor(
    private activatedClient: ActivatedClientService,
    private authService: AuthService,
    private userContractService: UserContractService,
  ) {}

  userConfig$ = (scopeName: string) =>
    this.activatedClient.clientIdDefinite$.pipe(
      withLatestFrom(this.authService.auth$.pipe(filterEmpty())),
      switchMap(([clientId, auth]) =>
        this.userContractService
          .userFiltersConfig(auth.email, clientId)
          .pipe(map((filters: UserClientFiltersConfig) => filters[scopeName] ?? [])),
      ),
    );
}
