<vdms-hq-ui-dialog-wrapper>
  <div title class="title-container">
    <span class="title">{{ 'common.global.share_to_with_new_title' | translate }}</span>
    <span class="subtitle">{{ 'common.global.share_to_with_new_subtitle' | translate }}</span>
  </div>
  <div content class="relative">
    <form [formGroup]="form" class="form-content">
      <vdms-hq-ui-form-section layout="single" [divider]="false">
        <vdms-hq-ui-form-input-text
          formControlName="name"
          [label]="'common.global.share_to_with_new_name' | translate"
        ></vdms-hq-ui-form-input-text>
        <vdms-hq-ui-form-input-chips
          formControlName="users"
          [label]="'common.global.share_to_with_new_email' | translate"
        ></vdms-hq-ui-form-input-chips>
      </vdms-hq-ui-form-section>
    </form>
    <div class="content-container">
      <vdms-hq-ui-grid-item-header>
        <vdms-hq-grid-title>{{ nameControl.value }}</vdms-hq-grid-title>
      </vdms-hq-ui-grid-item-header>
      <vdms-hq-ui-simple-details-list [listConfig]="shareConfig"></vdms-hq-ui-simple-details-list>
    </div>
  </div>
  <ng-container footer>
    <vdms-hq-ui-button (click)="close()" color="secondary">{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button>
    <vdms-hq-ui-button (click)="submit()" [disabled]="usersControl.invalid || usersControl.pristine" color="primary">{{
      'common.global.share_to_with_new_confirm' | translate
    }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
