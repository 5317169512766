import { ViewPlayerMetadataItem } from '../metadata-list.model';
import { Framerate, Timecode } from '@vdms-hq/timecode';
import {
  MediaInsightsCelebrityRecognitionMetadata,
  MediaInsightsObjectRecognitionMetadata,
  MediaInsightsSubtitlesMetadata,
  MediaInsightsTechnicalCueDetectionMetadata,
  MediaInsightsTranscriptionMetadataResultsItem,
  TranscriptionItem,
  VideoAnalysisModerationLabels,
} from '@vdms-hq/api-contract';
import { StreamsType } from '../streams/streams-type';

const emptyItem = 'N/A';

export const fromMiObjectRecognition = (
  item: MediaInsightsObjectRecognitionMetadata,
  type: StreamsType,
  framerate: Framerate,
): ViewPlayerMetadataItem => {
  const isBoundingBoxAsterisk = item?.Label?.Instances?.length ? ' *' : '';
  const confidenceNote = item?.Label?.Confidence || emptyItem;
  const content = item?.Label?.Name + isBoundingBoxAsterisk || emptyItem;
  const timestamp = {
    timecodeIn: Timecode.fromSeconds(item.Timestamp / 1000, framerate, false),
    timecodeOut: null,
  };

  return {
    type,
    timestamp,
    duration: null,
    content: content,
    confidence: Number(item?.Label?.Confidence),
    note: confidenceNote,
    pill: 'done-light',
    onTimeline: false,
    boundingBoxes: item?.Label?.Instances,
  };
};

export const fromMiTechnicalCueDetection = (
  item: MediaInsightsTechnicalCueDetectionMetadata,
  type: StreamsType,
  framerate: Framerate,
): ViewPlayerMetadataItem => {
  const confidenceNote = item?.TechnicalCueSegment?.Confidence || emptyItem;
  const content = item?.TechnicalCueSegment?.Type || emptyItem;
  const timestamp = {
    timecodeIn: Timecode.fromSeconds(item.StartTimestampMillis / 1000, framerate, false),
    timecodeOut: Timecode.fromSeconds(item.EndTimestampMillis / 1000, framerate, false),
  };

  const duration = Timecode.fromTimecode(item.DurationSMPTE, framerate, false);

  return {
    type,
    timestamp,
    duration,
    content: content,
    confidence: Number(item?.TechnicalCueSegment?.Confidence),
    note: confidenceNote,
    pill: 'white-dashed',
    onTimeline: false,
  };
};

export const fromMiCelebrityRecognition = (
  item: MediaInsightsCelebrityRecognitionMetadata,
  type: StreamsType,
  framerate: Framerate,
): ViewPlayerMetadataItem => {
  const isBoundingBoxAsterisk = item?.Celebrity?.Face?.length ? ' *' : '';
  const confidenceNote = item?.Celebrity?.Confidence || emptyItem;
  const content = item?.Celebrity?.Name + isBoundingBoxAsterisk || emptyItem;
  const timestamp = {
    timecodeIn: Timecode.fromSeconds(item.Timestamp / 1000, framerate, false),
    timecodeOut: null,
  };

  return {
    type,
    timestamp,
    duration: null,
    content: content,
    confidence: Number(item?.Celebrity?.Confidence),
    note: confidenceNote,
    pill: 'yellow',
    onTimeline: false,
    boundingBoxes: item?.Celebrity?.Face,
  };
};

export const fromMiTranscription = (
  item: MediaInsightsTranscriptionMetadataResultsItem,
  type: StreamsType,
  framerate: Framerate,
): ViewPlayerMetadataItem => {
  const confidenceNote = Number(item?.alternatives?.[0]?.confidence) * 100;
  const content = item?.alternatives?.[0]?.content || emptyItem;
  const timestamp = {
    timecodeIn: Timecode.fromSeconds(item.start_time, framerate, false),
    timecodeOut: Timecode.fromSeconds(item.end_time, framerate, false),
  };
  const duration = timestamp.timecodeOut?.subtract(<Timecode>timestamp.timecodeIn) ?? null;

  return {
    type,
    timestamp,
    duration,
    content: content,
    confidence: confidenceNote,
    note: confidenceNote.toString() || emptyItem,
    pill: 'cold-blue',
    onTimeline: false,
  };
};

export const fromMiSubtitles = (
  item: MediaInsightsSubtitlesMetadata,
  type: StreamsType,
  framerate: Framerate,
  languageKey = '',
  vttPath = '',
): ViewPlayerMetadataItem => {
  const content = item?.caption || emptyItem;
  const timestamp = {
    timecodeIn: Timecode.fromSeconds(item.start, framerate, false),
    timecodeOut: Timecode.fromSeconds(item.end, framerate, false),
  };
  const duration = timestamp.timecodeOut?.subtract(<Timecode>timestamp.timecodeIn) ?? null;

  return {
    type,
    timestamp,
    duration,
    content: content,
    confidence: 100,
    pill: 'done',
    onTimeline: false,
    languageKey,
    vttPath,
  };
};

export const fromContentModeration = (
  item: VideoAnalysisModerationLabels,
  type: StreamsType,
  framerate: Framerate,
): ViewPlayerMetadataItem => {
  const confidenceNote = item.ModerationLabel.Confidence.toString();
  const content =
    `${item.ModerationLabel.Name}` + (item.ModerationLabel.ParentName ? `(${item.ModerationLabel.ParentName})` : '') ||
    emptyItem;
  const timestamp = {
    timecodeIn: Timecode.fromSeconds(item.Timestamp / 1000, framerate, false),
    timecodeOut: Timecode.fromSeconds(item.Timestamp / 1000, framerate, false),
  };
  const duration = timestamp.timecodeOut?.subtract(<Timecode>timestamp.timecodeIn) ?? null;

  return {
    type,
    timestamp,
    duration,
    content: content,
    confidence: item.ModerationLabel.Confidence,
    note: confidenceNote,
    pill: 'failed',
    onTimeline: false,
  };
};

export const fromTranscription = (
  item: TranscriptionItem,
  type: StreamsType,
  framerate: Framerate,
): ViewPlayerMetadataItem => {
  const content = item.content || emptyItem;
  const timestamp = {
    timecodeIn: Timecode.fromSeconds(item.timecodeIn, framerate, false),
    timecodeOut: item.timecodeOut ? Timecode.fromSeconds(item.timecodeOut, framerate, false) : null,
  };
  const duration = timestamp.timecodeOut?.subtract(<Timecode>timestamp.timecodeIn) ?? null;

  return {
    type,
    timestamp,
    duration,
    content: content,
    confidence: 100,
    pill: 'orange',
    onTimeline: false,
  };
};
