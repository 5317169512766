import { DynamicFilterInput } from '@vdms-hq/dynamic-filters';
import { FilterType, ResourceModel, SelectOption, ValueFormat } from '@vdms-hq/shared';
import { METADATA_CONFIDENCE_THRESHOLD, METADATA_TYPES } from './metadata-list-filters-select-options';
import { StreamsType } from '../streams/streams-type';

export const defaultFilters = (enabledOptions: string[], miEnabledLanguages: string[] = []): DynamicFilterInput[] => [
  {
    id: 'type',
    label: 'Show on the timeline',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    isMultiple: true,
    enableSelectAll: true,
    enableDeselectAll: true,
    filters: {
      objectPath: 'type',
      validFormat: 'keyword',
      type: FilterType.SELECTOR,
    },
    selectOptions: filteredMetadataTypes(METADATA_TYPES, enabledOptions, miEnabledLanguages),
  },
  {
    id: 'phrase',
    label: 'Search for a phrase',
    resource: [ResourceModel.ASSET_FILTERS],
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'phrase',
      validFormat: 'keyword',
      type: FilterType.CHIPS,
    },
  },
  {
    id: 'confidence',
    label: 'Confidence Threshold',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'type',
      validFormat: 'keyword',
      type: FilterType.SELECTOR,
    },
    selectOptions: METADATA_CONFIDENCE_THRESHOLD,
  },
];

export const filteredMetadataTypes = (
  definition: SelectOption<string>[],
  enabled: string[],
  miEnabledLanguages: string[] = [],
) => {
  let filtered: SelectOption<string>[] = [];
  let langs: SelectOption<string>[] = [];

  if (enabled.includes(StreamsType.MI_SUBTITLES)) {
    enabled = enabled.filter((item) => item !== StreamsType.MI_SUBTITLES);

    langs = miEnabledLanguages.map((lang) => ({
      key: `${StreamsType.MI_SUBTITLES}_${lang}`,
      label: `MI Timed Texts (${lang})`,
    }));
  }

  filtered = enabled.map((item) => definition.filter((def) => def.key == item)[0]);

  return [...langs, ...filtered];
};
