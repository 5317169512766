<div class="section" [class.accent]="accent" [class.padding-like-page]="pagePadding" [class.margin]="margin">
  <div class="section__header" *ngIf="breadcrumbs">
    <vdms-hq-ui-layout-breadcrumbs [breadcrumbs]="breadcrumbs" *ngIf="breadcrumbs"></vdms-hq-ui-layout-breadcrumbs>
  </div>

  <div
    class="section__content"
    [class.horizontal]="orientation === 'horizontal'"
    [class.vertical]="orientation === 'vertical'"
  >
    <ng-content></ng-content>
  </div>
</div>
