export type Modifiers = {
  truncateCharacters?: number;
  dateFormat?: string | 'time' | 'date-time' | 'date';
  timeZoneSensitive?: boolean;
  bitrateFormat?: boolean;
  fileSizeFormat?: boolean;
  translate?: boolean;
  discountValue?: boolean;
  yesNo?: boolean;
  yesFallback?: boolean;
  textTransform?: 'camelCase' | 'uppercase' | 'snake_case' | 'capitalize';
  asNumberWithZero?: boolean;
  asPercentage?: boolean;
  removeDashes?: boolean;
  removeUnderscores?: boolean;
};

// todo: refactor keys to more meaningful names (e.g. 'Orange' -> 'Warning')
export enum PillColorEnum {
  ColdBlue = 'cold-blue',
  Orange = 'orange',
  WhiteDashed = 'white-dashed',
  YellowDashed = 'yellow-dashed',
  OrangeLight = 'orange-light',
  Yellow = 'yellow',
  DoneLight = 'done-light',
  Done = 'done',
  Failed = 'failed',
}

export type PillColor2 = (typeof PillColorEnum)[keyof typeof PillColorEnum];

/**
 * @deprecated Use PillColor2 instead
 */
export type PillColor =
  | 'cold-blue'
  | 'orange'
  | 'white-dashed'
  | 'yellow-dashed'
  | 'orange-light'
  | 'yellow'
  | 'done-light'
  | 'done'
  | 'failed'
  | '';

export type PillValue = 'default' | 'past' | 'future' | string;
export type Pills = {
  [key: PillValue]: PillColor | PillColor2;
};
export type ValueDisplayFormatConfig = {
  maxVisibleValues?: number;
  modifiers?: Modifiers;
  pills?: Pills;
  pillsMatch?: 'exact' | 'include';
  greyLineIf?: string | 'empty' | 'false' | 'past';
};

export type RendererValue = string | boolean | number | Date | null | undefined;
