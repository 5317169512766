import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, shareReplay, switchMap } from 'rxjs';
import { SelectOption } from '@vdms-hq/shared';
import { map, tap } from 'rxjs/operators';
import { SourceProvider } from '../source-provider';
import { FieldsOptionsService } from '@vdms-hq/api-contract';
import { SelectorSourceType } from '../selector-source.type';
import { ActivatedClientService } from '@vdms-hq/activated-client';

@Injectable({
  providedIn: 'root',
})
export class ApiFieldsSourceService implements SourceProvider {
  #isLoadingSubject$ = new BehaviorSubject(true);
  isLoading$ = this.#isLoadingSubject$.asObservable();

  #all = combineLatest([this.activatedClientService.clientIdDefinite$]).pipe(
    switchMap(() => this.fieldsOptionsService.getTypes()),
    tap(() => {
      this.#isLoadingSubject$.next(false);
    }),
    shareReplay(1),
  );

  constructor(
    private fieldsOptionsService: FieldsOptionsService,
    private activatedClientService: ActivatedClientService,
  ) {}

  listByType(type: SelectorSourceType): Observable<SelectOption[]> {
    return this.#all.pipe(
      map((fields) => fields.find((field) => field.name === type && field.fields.length > 0)),
      map((type) => (type ? type.fields.map(({ key, label }) => ({ key, label })) : [])),
    );
  }

  listAllByType(type: SelectorSourceType) {
    return this.#all.pipe(
      map((fields) => fields.find((field) => field.name === type)),
      map((type) => (type ? type.fields : [])),
    );
  }
}
