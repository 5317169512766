<div class="tile" (mouseenter)="setHover(true)" (mouseleave)="setHover(false)" [class]="size">
  <img
    [class]="'tile-background ' + (mock ? 'mock' : '')"
    [src]="isMouseOver ? backgroundHover : background"
    [alt]="header || '-----'"
    (error)="mock = true"
    [class.scale-down]="backgroundScaleDown"
  />
  <div class="tile-overlay">
    <div class="icon-outer" [class]="iconIndicator" *ngIf="icon || isLoading">
      <mat-icon *ngIf="!isLoading" class="icon">{{ icon }}</mat-icon>
      <mat-spinner *ngIf="isLoading" diameter="15" class="spinner"></mat-spinner>
    </div>
    <div class="content">
      <span *ngIf="topHeader" class="line header">{{ topHeader }}</span>
      <span class="line header">{{ header }}</span>
      <span class="line subheader" *ngIf="subheader">{{ subheader }}</span>

      <div class="line info">
        <div class="metadata">
          <ng-container *ngFor="let item of metadata | slice : 0 : maxVisibleMetadataEntries">
            <span class="metadata-item visible" *ngIf="item.value">
              <ng-container [ngTemplateOutlet]="metadataOutlet" [ngTemplateOutletContext]="{ item: item }">
              </ng-container>
            </span>
          </ng-container>

          <ng-container *ngFor="let item of metadata | slice : maxVisibleMetadataEntries">
            <span class="metadata-item hidden" *ngIf="item.value">
              <ng-container [ngTemplateOutlet]="metadataOutlet" [ngTemplateOutletContext]="{ item: item }">
              </ng-container>
            </span>
          </ng-container>

          <ng-content select="[metadata-items]"></ng-content>
        </div>
        <div class="additional hidden">
          <ng-content select="[additional]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #metadataOutlet let-item="item">
  <ng-container *ngIf="item.type === 'date'">{{ item.value | date : 'dd-MM-yyyy' }}</ng-container>
  <ng-container *ngIf="item.type !== 'date'">{{ item.value }}</ng-container>
</ng-template>
