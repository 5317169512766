import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectorComponent } from './smart/selector/selector.component';
import { UIButtonModule, UIFormModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { SelectorValueDirective } from './smart/selector-value/selector-value.directive';
import { AutocompleteComponent } from './smart/autocomplete/autocomplete.component';
import { GroupedSelectorComponent } from './smart/grouped-selector/grouped-selector.component';
import { CheckboxListComponent } from './smart/checkbox-list/checkbox-list.component';
import { FormInputDateTimeUnixTimestampLimitedComponent } from './smart/form-input-date-time-unix-timestamp-limited/form-input-date-time-unix-timestamp-limited.component';

const exportedDeclarations = [
  SelectorComponent,
  GroupedSelectorComponent,
  SelectorValueDirective,
  AutocompleteComponent,
  CheckboxListComponent,
  FormInputDateTimeUnixTimestampLimitedComponent,
];

@NgModule({
  imports: [CommonModule, UIFormModule, TranslateModule, UIButtonModule],
  declarations: [exportedDeclarations],
  exports: [...exportedDeclarations],
})
export class SelectorsModule {}
