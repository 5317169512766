import { Injectable } from '@angular/core';
import { ActivatedClientService, ClientModel } from '@vdms-hq/activated-client';
import { AssetFlat } from '@vdms-hq/api-contract';
import { TileMetadataRef, TitleFormat } from '@vdms-hq/firebase-contract';
import { StorageUrlService } from '@vdms-hq/storage';
import { Framerate, Timecode } from '@vdms-hq/timecode';
import { TileMetadata, TileModel } from '@vdms-hq/ui';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { getFallbackBackground, getIcon } from './asset-media-config';

export type TransformableAssetFlat = Partial<AssetFlat> & Pick<AssetFlat, 'uuid' | 'thumbnails' | 'type'>;

export type AssetFlatView = TransformableAssetFlat & {
  tile: TileModel;
};

@Injectable({
  providedIn: 'root',
})
export class AssetFlatTransformerService {
  constructor(private activatedClientService: ActivatedClientService, private storageUrlService: StorageUrlService) {}

  transformWrappedFlatAssetToView$<
    FlatAsset extends TransformableAssetFlat,
    FlatAssetWrapper extends {
      asset: FlatAsset;
    },
  >(
    parentItems: FlatAssetWrapper[],
  ): Observable<
    (FlatAssetWrapper & {
      transformedAsset: FlatAsset & AssetFlatView;
    })[]
  > {
    return this.activatedClientService.clientDefinite$.pipe(
      take(1),
      map((client) => {
        return parentItems.map((parentItem) => ({
          ...parentItem,
          transformedAsset: this.#transformAsset(parentItem.asset, client),
        }));
      }),
    );
  }

  transformFlatAssetToView$<FlatAsset extends TransformableAssetFlat>(
    assets: FlatAsset[],
  ): Observable<(FlatAsset & AssetFlatView)[]> {
    return this.activatedClientService.clientDefinite$.pipe(
      take(1),
      map((client) => assets.map((asset) => this.#transformAsset<FlatAsset>(asset, client))),
    );
  }

  #transformAssetToTile(asset: TransformableAssetFlat, client: ClientModel, scopeName = 'default'): TileModel {
    const metadataConfig = client.vida?.grid?.metadata ?? [TileMetadataRef.originalFilename, TileMetadataRef.duration];
    const metadataV2Config = client.vida?.grid?.metadataV2;
    const titleFormat = client.vida?.grid?.titleFormat;

    const header = this.#getTitle(asset, titleFormat) || 'N/A';
    const subheader = asset?.production_no;

    const iconIndicator = asset?.library_sales_rights === false ? 'alert' : 'neutral';
    const icon = getIcon(asset.type);

    const metadata = this.#getMetadata(asset, subheader ? [header, subheader] : [header], metadataConfig);

    return {
      id: asset.uuid,
      header: header,
      subheader,
      metadata,
      background: this.#getBackground(asset),
      backgroundHover: this.#getBackgroundHover(asset),
      iconIndicator,
      icon,
    };
  }

  #getBackgroundHover = (asset: TransformableAssetFlat) => {
    const gifThumbnail = asset.thumbnails?.find((thumb) => thumb.type === 'gif');

    if (asset.type === 'video' && gifThumbnail?.url) {
      const url = this.storageUrlService.updateCdn(gifThumbnail?.url);

      if (url) {
        return url;
      }
    }

    return this.#getBackground(asset);
  };

  #getBackground = (asset: TransformableAssetFlat): string => {
    const externalThumbnail = asset.thumbnails?.find((thumb) => thumb.type === 'external');

    if (asset.type === 'video' && externalThumbnail?.url) {
      const url = this.storageUrlService.updateCdn(externalThumbnail?.url);

      if (url) {
        return url;
      }
    }

    const smallThumbnail = asset.thumbnails?.find((thumb) => thumb.type === 'main');
    const bigThumbnail = asset.thumbnails?.find((thumb) => thumb.type === 'big');

    const thumbToUse = smallThumbnail ?? bigThumbnail;

    if (thumbToUse) {
      const url = this.storageUrlService.updateCdn(thumbToUse.url);

      if (url) {
        return url;
      }
    }

    return getFallbackBackground(asset.type) ?? 'assets/common/asset_fallbacks/unknown.png';
  };

  #getMetadata = (asset: TransformableAssetFlat, visibleMetadata: string[], metadataToDisplay: string[]) => {
    return metadataToDisplay
      .map((metadataKey) => {
        switch (metadataKey) {
          case TileMetadataRef.uploadDate:
            return asset?.uploaded_at
              ? {
                  type: 'date',
                  value: asset?.uploaded_at,
                }
              : undefined;
          case TileMetadataRef.txDate:
            return asset?.tx_date
              ? {
                  type: 'date',
                  value: asset?.tx_date,
                }
              : undefined;
          case TileMetadataRef.originalFilename:
            return asset?.original_filename && !visibleMetadata.includes(asset?.original_filename)
              ? {
                  type: 'text',
                  value: asset?.original_filename,
                }
              : undefined;

          case TileMetadataRef.duration:
            return asset?.duration && ['video', 'audio'].includes(asset.type)
              ? {
                  type: 'text',
                  value: asset?.duration,
                }
              : undefined;
        }

        return undefined;
      })
      .filter((item) => item !== undefined) as TileMetadata[];
  };

  #getTitle = (asset: TransformableAssetFlat, titleFormat?: TitleFormat): string => {
    let name = '';

    switch (titleFormat) {
      case TitleFormat.seriesNameAndSxxExxOrEpisodeName:
        if (asset?.series_name) {
          name += asset?.series_name;

          if (asset?.series_number) {
            name += ' S' + this.#addLeadingZeros(asset?.series_number);
          }

          if (asset?.episode_number) {
            name += ' E' + this.#addLeadingZeros(asset?.episode_number);
          }
        } else if (asset?.episode_name) {
          name += asset?.episode_name;
        }

        return name;

      case TitleFormat.episodeNameAndSxExOrOriginalFilename:
        if (asset?.episode_name) {
          name += asset?.episode_name;
        }

        if (asset?.series_number) {
          name += ' ' + asset?.series_number;
        }

        if (asset?.episode_number) {
          name += ' ' + asset?.episode_number;
        }

        if (name !== '') {
          return name;
        }

        return String(asset.original_filename);

      case TitleFormat.originalFilename:
        return String(asset.original_filename);

      case TitleFormat.programmeNumber:
        return String(asset.programme_number);

      case TitleFormat.truncatedEpisodeNameProgrammeNumber:
        if (asset.episode_name && asset.episode_name.length > 124) {
          name += asset.episode_name.slice(0, 110) + '...';
        }

        if (asset.episode_name && asset.episode_name.length < 124) {
          name += asset?.episode_name;
        }

        if (asset?.programme_number) {
          name += '\n' + asset.programme_number;
        }

        return name;

      default:
        return String(
          asset.episode_name && asset.episode_name.length > 124
            ? asset.episode_name.slice(0, 110) + '...'
            : asset?.episode_name || asset.original_filename,
        );
    }
  };

  #addLeadingZeros = (value?: string | number) => value?.toString().padStart(2, '0');

  #transformAsset<T extends TransformableAssetFlat>(asset: T, client: ClientModel) {
    return {
      ...asset,
      duration: this.#transformVirtualAssetDuration(asset),
      tile: this.#transformAssetToTile(asset, client),
      container_duration: this.#transformContainerDurationToTimecode(asset.container_duration),
    };
  }

  #transformVirtualAssetDuration(asset: TransformableAssetFlat) {
    if (!asset.is_virtual || asset?.duration) {
      return asset.duration;
    }

    if (!asset?.timecode_in || !asset?.timecode_out) {
      return null;
    }

    const framerate = asset?.framerate ? Framerate.fromValue(asset?.framerate) : Framerate.default();

    const tcIn = Timecode.fromTimecode(asset.timecode_in, framerate)?.countSeconds() ?? 0;
    const tcOut = Timecode.fromTimecode(asset.timecode_out, framerate)?.countSeconds() ?? 0;
    const duration = Timecode.fromSeconds(tcOut - tcIn)?.substring(0, 8);

    return duration ?? null;
  }

  #transformContainerDurationToTimecode(container_duration: string | undefined | null) {
    if (!container_duration) {
      return null;
    }
    const newCD = Timecode.fromSeconds(parseInt(container_duration, 10))?.substring(0, 8);

    return newCD ?? null;
  }
}
