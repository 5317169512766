import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vdms-hq-ui-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent {
  @Input() draggable = true;
  @Output() suffixAction = new EventEmitter<void>();
  @Input() suffixIcon?: string;
  @Input() tooltip = '';
}
