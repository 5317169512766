import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Timeline } from '../../logic/model';

@Component({
  selector: 'vdms-hq-ui-timeline',
  styleUrls: ['./timeline.component.scss'],
  templateUrl: './timeline.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent {
  @Input() timelines: Timeline[] = [];
  @Input() title = '';
}
