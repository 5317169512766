<div class="multi-data-container">
  <vdms-hq-ui-data-presentation-header
    (action)="customActionHandler($event.key)"
    [actions]="headerActions$ | async"
    [title]="title | translate"
    [counterIndicator]="multiDataSource.total$ | async"
  >
  </vdms-hq-ui-data-presentation-header>

  <vdms-hq-ui-multiple-data-presentation
    *ngIf="collectionConfiguration$ | async; let configuration"
    [configuration]="configuration"
    [dataSource]="multiDataSource"
    (action)="customActionHandler($event.key, $event.item)"
    (changeDefaultView)="setPreferredTableView($event)"
  >
    <vdms-hq-dynamic-form-group-renderer
      filters
      [form]="multiDataSource.filters"
      [fieldsConfig]="filtersConfig"
      [hasLoader]="true"
    ></vdms-hq-dynamic-form-group-renderer>
  </vdms-hq-ui-multiple-data-presentation>
</div>
