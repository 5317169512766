import { Component, Input } from '@angular/core';
import { Theme, ThemeSwitcherService } from '@vdms-hq/theming';
import { map } from 'rxjs/operators';

@Component({
  selector: 'vdms-hq-ui-preview-error',
  templateUrl: './preview-error.component.html',
  styleUrls: ['./preview-error.component.scss'],
})
export class PreviewErrorComponent {
  src$ = this.theme.theme$.pipe(
    map((theme) =>
      theme === Theme.dark
        ? `/assets/ui/preview/preview_not_available_dark.svg`
        : `/assets/ui/preview/preview_not_available_light.svg`,
    ),
  );

  @Input() header = 'Preview not available';
  @Input() reason?: string;

  constructor(readonly theme: ThemeSwitcherService) {}
}
