import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Timecode } from '@vdms-hq/timecode';
import { percentageToValue } from '../../helpers/ranges';

type OuterValue = number | string | null | undefined;
type InnerValue = number | null;

@Component({
  selector: 'vdms-hq-ui-form-timecode-slider',
  templateUrl: './form-timecode-slider.component.html',
  styleUrls: ['./form-timecode-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormTimecodeSliderComponent),
    },
  ],
})
export class FormTimecodeSliderComponent extends FormControlValueAccessorComponent<OuterValue, InnerValue> {
  innerFormControl = new UntypedFormControl(null);
  @Input() sliderMinValue = 0;
  @Input() sliderMaxValue = 100;

  @Input() min = Timecode.fromSeconds(0);
  @Input() max = Timecode.fromSeconds(7200); //2h

  get #minValue() {
    return this.min?.countFrames() ?? 0;
  }

  get #maxValue() {
    return this.max?.countFrames() ?? 0;
  }

  formatLabel = (givenPercentage: number | null) => {
    if (givenPercentage === null) {
      return 'N/A';
    }
    return (
      Timecode.fromFrames(
        percentageToValue(givenPercentage, this.#minValue, this.#maxValue),
        undefined,
        false,
      )?.toString() ?? 'N/A'
    );
  };

  override transformOuterToInner(value: OuterValue): InnerValue {
    return value === undefined ? Number(value) : Number(value);
  }

  update(givenPercentage: number) {
    const value = Timecode.fromFrames(
      percentageToValue(givenPercentage, this.#minValue, this.#maxValue),
      undefined,
      false,
    );
    this.innerFormControl.setValue(value);
  }
}
