<div class="multi-data-container">
  <vdms-hq-ui-data-presentation-header
    [title]="'pages.release_notes.archived' | translate"
    [subtitle]="'pages.release_notes.archived_sub' | translate"
    [size]="'large'"
    [actions]="actions"
    (action)="onAction($event)"
  >
  </vdms-hq-ui-data-presentation-header>
  <div class="relative">
    <vdms-hq-ui-loader *ngIf="isLoading$ | async" [mode]="'over-parent'" [backdrop]="true"></vdms-hq-ui-loader>
    <iframe class="iframe" [scrolling]="'yes'" [src]="themeBasedUrl$ | async" (load)="onLoad()"> </iframe>
  </div>
</div>
