import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';
import moment, { Moment } from 'moment';

type OutsideValue = string | number | Date | null | undefined | Moment;
type OutsideValuePair = {
  from: OutsideValue;
  to: OutsideValue;
};
type InsideValue = Moment | null;

type InsideValuePair = {
  from: InsideValue;
  to: InsideValue;
};

@Component({
  selector: 'vdms-hq-form-input-date-range',
  templateUrl: './form-input-date-range.component.html',
  styleUrls: ['./../../styles/ranges.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputDateRangeComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormInputDateRangeComponent),
    },
  ],
})
export class FormInputDateRangeComponent extends FormControlValueAccessorComponent<OutsideValuePair, InsideValuePair> {
  innerFormControl = new FormGroup(
    {
      from: new FormControl<InsideValue>(null),
      to: new FormControl<InsideValue>(null),
    },
    { updateOn: 'blur' },
  );

  from = this.innerFormControl.get('from');
  to = this.innerFormControl.get('to');

  override transformInnerToOuter(value: InsideValuePair): OutsideValuePair {
    return {
      from: value?.from?.startOf('day') ?? null,
      to: value?.to?.endOf('day') ?? null,
    };
  }

  override transformOuterToInner(value: OutsideValuePair): InsideValuePair {
    return {
      from: value?.from ? moment(value?.from) : null,
      to: value?.to ? moment(value?.to) : null,
    };
  }
}
