const multiplySizes = ['', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'] as const;

export type Prefix = (typeof multiplySizes)[number];

export type CalcResponse = {
  value: number;
  prefix: Prefix;
};

export const convertBytes = (
  bytes: number | string | null | undefined,
  decimals = 2,
  to?: Prefix,
  notation = 1024,
): CalcResponse => {
  bytes = isNaN(Number(bytes)) ? 0 : Number(bytes);
  if (bytes === 0) {
    return {
      value: 0,
      prefix: to ?? '',
    };
  }

  const dm = decimals < 0 ? 0 : decimals;

  let i;

  if (to !== undefined) {
    i = multiplySizes.indexOf(to);
  } else {
    i = Math.floor(Math.log(bytes) / Math.log(notation));
  }

  return {
    value: parseFloat((bytes / Math.pow(notation, i)).toFixed(dm)),
    prefix: multiplySizes[i],
  };
};

export const convertToBytes = (
  value: number | string | null | undefined,
  from: Prefix,
  decimals = 2,
  notation = 1024,
): number => {
  value = isNaN(Number(value)) ? 0 : Number(value);
  if (value === 0) {
    return 0;
  }
  const dm = decimals < 0 ? 0 : decimals;

  const i = multiplySizes.indexOf(from);

  return parseFloat((value * Math.pow(notation, i)).toFixed(dm));
};
