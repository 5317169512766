import { AwsV4Signature } from '../upload/upload';

export enum AttachmentEndpoints {
  ROOT = 'attachment',
}

export enum AttachmentStatusEnum {
  PENDING = 'pending',
  UPLOADED = 'uploaded',
  ERROR = 'error',
}

export interface AttachmentCreateRequest {
  type?: string;
  file: string;
  file_type: string;
  asset_uuid: string;
}

export interface AttachmentPatchRequest {
  status: AttachmentStatusEnum;
}

export interface AttachmentPatchResponse {
  uuid: string;
  upload_access: AwsV4Signature;
  download_link?: string; // when status uploaded (forced content disposition = attachment)
  view_link?: string; // when status uploaded
  status: AttachmentStatusEnum;
  file: string;
  type?: string;
  file_type: string;
}
