import { QualityLevel } from 'videojs-contrib-quality-levels';

export const ToQualityLevelLabel = (id: QualityLevel['id'], height: QualityLevel['height'] | string) => {
  let quality;
  if (id.includes('v00')) {
    quality = '2.5mbps';
  }
  if (id.includes('v01')) {
    quality = '500kbps';
  }
  if (id.includes('v02')) {
    quality = '100kbps';
  }

  return `${quality} @ ${height}`;
};
