import { Injectable } from '@angular/core';
import { ApiPaginatedResponse, ApiResponse } from '../api.model';
import { GetResponseData, GetResponsePaginationData } from '../../operators/get-response-data.operator';
import {
  PatchPartnerUser,
  RIGHTS_PARTNERS_ENDPOINTS,
  RightsPartnerCreate,
  RightsPartnerGet,
  RightsPartnerListItem,
  RightsPartnerUpdate,
  RightsPartnerUsersPatch,
} from './rights-partners.model';
import { Pagination } from '@vdms-hq/ui';
import { HttpHeaders } from '@angular/common/http';
import { ApiService } from '../api.service';
import { UserModel } from '../user/user.model';

@Injectable({ providedIn: 'root' })
export class RightsPartnersService {
  constructor(private readonly apiService: ApiService) {}

  getMany = (pagination?: Pagination, filter?: string) => {
    let headers = new HttpHeaders();
    headers = pagination ? pagination.applyToHeaders(headers) : headers;
    headers = filter ? headers.append('filter', filter) : headers;
    headers = headers.append('Cache-Control', 'max-age=0');

    return this.apiService
      .get<ApiPaginatedResponse<RightsPartnerListItem>>(`${RIGHTS_PARTNERS_ENDPOINTS.ROOT_LIST}`, { headers })
      .pipe(GetResponsePaginationData);
  };

  getOne = (uuid: string) => {
    return this.apiService
      .get<ApiResponse<RightsPartnerGet>>(`${RIGHTS_PARTNERS_ENDPOINTS.ROOT}/${uuid}`)
      .pipe(GetResponseData);
  };

  create = (partner: RightsPartnerCreate) => {
    return this.apiService.post<RightsPartnerCreate, ApiResponse<RightsPartnerGet>>(
      `${RIGHTS_PARTNERS_ENDPOINTS.ROOT}`,
      partner,
    );
  };

  patch = (uuid: string, partner: RightsPartnerUpdate) => {
    return this.apiService.patch<RightsPartnerUpdate, ApiResponse<RightsPartnerGet>>(
      `${RIGHTS_PARTNERS_ENDPOINTS.ROOT}/${uuid}`,
      partner,
    );
  };

  remove = (uuid: string) => {
    return this.apiService.delete<null, undefined>(`${RIGHTS_PARTNERS_ENDPOINTS.ROOT}/${uuid}`);
  };

  addUsers = (uuid: string, users: PatchPartnerUser[]) => {
    return this.apiService.patch<RightsPartnerUsersPatch, ApiResponse<null>>(
      `${RIGHTS_PARTNERS_ENDPOINTS.ROOT}/${uuid}/attach-users`,
      { users: { attach: users } },
    );
  };

  removeUsers = (uuid: string, users: PatchPartnerUser[]) => {
    return this.apiService.patch<RightsPartnerUsersPatch, ApiResponse<null>>(
      `${RIGHTS_PARTNERS_ENDPOINTS.ROOT}/${uuid}/detach-users`,
      { users: { detach: users } },
    );
  };

  getUsers(partner_uuid: string) {
    const headers = new HttpHeaders({
      text: '',
      'per-page': '10000',
      page: '0',
      'order-by': 'user.displayName',
      'order-dir': 'ASC',
      'partner-id': partner_uuid,
    });

    return this.apiService
      .get<ApiPaginatedResponse<UserModel>>(`users/share`, { headers })
      .pipe(GetResponsePaginationData);
  }
}
