import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilteringPipe implements PipeTransform {
  transform(array: any[], value?: string, ...keys: string[]): any {
    if (value === '' || !value) {
      return array;
    }

    return array.filter((item) => {
      for (const key of keys) {
        if (item[key] && item[key].toLowerCase().indexOf(value.toLowerCase()) > -1) {
          return true;
        }
      }

      return false;
    });
  }
}
