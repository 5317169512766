import { AssetFlat } from '../asset/models/asset-flat.model';

type EmailRef = string;

export type DetailedPreviewRequest = AssetFlat[];

export interface PreviewRequest {
  uuid: string;
  sales_force_id: number;
  sales_force_name: string;
  sales_force_owner_id: number;
  sales_force_title: string;
  owner: EmailRef;
  users: EmailRef[];
  assets: AssetFlat[];
}

export enum APIEndpoints {
  ROOT = 'preview-request',
  ROOT_LIST = 'preview-requests',
  ASSETS = 'assets',
}
