import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'vdms-hq-ui-form-input-accordion',
  templateUrl: './form-input-accordion.component.html',
  styleUrls: ['./form-input-accordion.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ height: 0 }), animate(100)]),
      transition(':leave', [animate(100, style({ height: 0 }))]),
    ]),
  ],
})
export class FormInputAccordionComponent {
  @Input() wrappedFormControl?: UntypedFormControl;
  @Input() label?: string;
  @Input() optionsLength = 0;
  visible = true;
  expanded = false;
  readonly NO_EXPANDED_MAX_ELEMENTS = 5;

  toggleVisibility() {
    this.visible = !this.visible;
  }

  toggleMore() {
    this.expanded = !this.expanded;
  }
}
