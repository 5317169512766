<vdms-hq-ui-dialog-wrapper footerAlign="space-between">
  <ng-container title>
    {{ 'common.orders.redeliver_order.title' | translate }}
  </ng-container>

  <div [formGroup]="form" content class="relative">
    <vdms-hq-ui-loader *ngIf="loading || saving" mode="over-parent" [backdrop]="true"></vdms-hq-ui-loader>

    <vdms-hq-ui-form-checkbox-list
      [columns]="1"
      [available]="emails"
      formControlName="emails"
      label="common.orders.redeliver_order.emails"
    >
    </vdms-hq-ui-form-checkbox-list>
  </div>

  <ng-container footer>
    <vdms-hq-ui-button (click)="abort()" color="secondary">{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button>
    <vdms-hq-ui-button (click)="save()" [loading]="saving" [disabled]="form.invalid" color="primary">{{
      'common.orders.redeliver_order.save' | translate
    }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
