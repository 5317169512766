import { QualityControlAnalyseConfig } from '@vdms-hq/api-contract';

type RowValue = 'passed' | string | string[] | null;
export class QualityControlAnalyseRow {
  constructor(private props: QualityControlAnalyseConfig) {}

  static fromResponse(response: QualityControlAnalyseConfig): QualityControlAnalyseRow {
    return new QualityControlAnalyseRow(response);
  }

  findResult(key: string): RowValue {
    const result = this.props.find((result) => result.param === key);

    if (!result) {
      return null;
    }

    if (result.status) {
      return `Passed: ${result.detectedValue.join(', ')}`;
    }

    const detected = result.detectedValue.length > 0 ? `Detected: ${result.detectedValue.join(', ')}` : 'Not detected';

    const accepted = `Expected: ${result.acceptedValues.join(', ')}`;

    return [detected, accepted];
  }

  get summary() {
    if (this.props.every((result) => result.status)) {
      return 'passed';
    }

    if (this.props.every((result) => !result.status)) {
      return 'failed';
    }

    const passed = this.props.filter((result) => result.status).length;
    const failed = this.props.filter((result) => !result.status).length;

    return `${passed}/${failed + passed}`;
  }

  get assetType(): RowValue {
    return this.findResult('Asset Type');
  }
  get fileExtension(): RowValue {
    return this.findResult('File Ext');
  }

  get timecodeStart(): RowValue {
    return this.findResult('Start TC');
  }

  get droppedFrames(): RowValue {
    return this.findResult('DF / NDF');
  }
  get videoCodec(): RowValue {
    return this.findResult('Video Codec');
  }
  get videoBitDepth(): RowValue {
    return this.findResult('Video Bit Depth');
  }
  get chromaFormat(): RowValue {
    return this.findResult('Chroma Subsampling');
  }
  get framerate(): RowValue {
    return this.findResult('Framerate');
  }
  get interlaceMode(): RowValue {
    return this.findResult('Scan type');
  }
  get width(): RowValue {
    return this.findResult('Width');
  }
  get height(): RowValue {
    return this.findResult('Height');
  }
  get audioCodec(): RowValue {
    return this.findResult('Audio Codec');
  }
  get channelCount(): RowValue {
    return this.findResult('Channel Count');
  }
  get channelsPerTrack(): RowValue {
    return this.findResult('Channels per Track');
  }
  get audioSampleRate(): RowValue {
    return this.findResult('Audio Sample Rate');
  }
  get audioBitDepth(): RowValue {
    return this.findResult('Audio Bit Depth');
  }
}
