import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileDestination } from '@vdms-hq/shared';

export type PreviewUISettings = {
  file: FileDestination;
  includeCookiesInRequest: boolean;
  pdfWithToolbar: boolean;
  pdfWithNavPanes: boolean;
  waveformUrl?: string;
};

@Component({
  template: '',
})
export abstract class AnyFileComponent implements OnInit {
  @Input() settings!: PreviewUISettings;
  loading = true;

  constructor(protected sanitizer: DomSanitizer, protected cdr: ChangeDetectorRef) {}

  protected async fetchBuffer() {
    return await (await this.fetch()).arrayBuffer();
  }

  protected async fetch() {
    return await fetch(
      this.settings.file.url,
      this.settings.includeCookiesInRequest
        ? {
            credentials: 'include',
          }
        : undefined,
    );
  }

  protected loaded() {
    this.loading = false;
  }

  ngOnInit(): void {
    this.loading = true;
  }
}
