import { APP_INITIALIZER, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AUTH_CONFIG, AuthConfig } from './logic/config-token';
import { AuthService } from './logic/services/auth.service';
import { initAuth } from './auth.initializer';
import { AuthStateService } from './logic/services/auth-state.service';
import { AuthLoginService } from './logic/services/auth-login.service';
import { GuestOnlyGuard } from './logic/guards/guest-only.guard';
import { AuthOnlyGuard } from './logic/guards/auth-only.guard';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { ToastModule } from '@vdms-hq/toast';
import { AuthAttributesService } from './logic/services/auth-attributes.service';

@NgModule({
  imports: [CommonModule, AngularFireModule, ToastModule],
  providers: [AuthOnlyGuard, GuestOnlyGuard],
})
export class AuthModule {
  static forRoot(config: AuthConfig): ModuleWithProviders<AuthModule> {
    const providers: Provider[] = [
      AuthService,
      AuthStateService,
      AuthLoginService,
      AuthAttributesService,
      {
        provide: FIREBASE_OPTIONS,
        useValue: config.firebase,
      },
      {
        provide: AUTH_CONFIG,
        useValue: config,
      },
      {
        provide: APP_INITIALIZER,
        useFactory: initAuth,
        deps: [AuthStateService, AuthLoginService],
        multi: true,
      },
    ];

    return {
      ngModule: AuthModule,
      providers,
    };
  }
}
