import { Pipe, PipeTransform } from '@angular/core';
import { StreamsType } from '../streams/streams-type';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateType',
  pure: true,
  standalone: true,
})
export class TranslateTypePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(type: string, languageKey?: string): string | null {
    if (!type) {
      return null;
    }

    return this.translateService.instant(`common.player_metadata_list.stream_type_label.${type}`, {
      lang: languageKey,
    });
  }
}
