import { denormalize as normalizrDenormalize, schema } from 'normalizr';

const singleItem = new schema.Entity('children', undefined, {
  idAttribute: 'uuid',
});
const childrenSchema = new schema.Array(singleItem);
singleItem.define({ children: childrenSchema });

export type Denormalized<T> = Omit<T, 'children'> & { children: T[] };
export type Normalized<T> = Omit<T, 'children'> & { children: string[] };

export interface NormalizedDictionary<T> {
  [key: string]: Normalized<T>;
}

export function denormalizeTree<T>(entities: NormalizedDictionary<T>, rootId: string): Denormalized<T> | null {
  if (!rootId) {
    return null;
  }

  return normalizrDenormalize(rootId, singleItem, {
    children: entities,
  });
}
