<div class="stats" [class.without-padding]="withoutPadding">
  <p class="stats__header">
    <mat-icon class="stats__icon">{{ statsConfig.icon }}</mat-icon>
    <span class="stats__title">{{ statsConfig.title }}</span>
  </p>
  <mat-divider></mat-divider>
  <ng-container [ngSwitch]="statsConfig.type">
    <vdms-hq-ui-stats-static
      [statsStaticVM]="$any(statsConfig.viewModel)"
      *ngSwitchCase="StatsType.STATIC"
    ></vdms-hq-ui-stats-static>

    <vdms-hq-ui-stats-chart-line
      style="height: 100%; display: block"
      *ngSwitchCase="StatsType.CHART_LINE"
      [statsChartVM]="$any(statsConfig.viewModel)"
    ></vdms-hq-ui-stats-chart-line>

    <vdms-hq-ui-stats-chart-pie
      *ngSwitchCase="StatsType.CHART_PIE"
      [statsChartVM]="$any(statsConfig.viewModel)"
    ></vdms-hq-ui-stats-chart-pie>
  </ng-container>
</div>
