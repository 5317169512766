<div
  class="material-custom-field toggle-outer"
  [class.with-touched-indicator]="withTouchedIndicator"
  [class.hide-footer]="!withFooter"
>
  <div class="toggle-outer__control">
    <div class="additional-label">{{ additionalLabel }}</div>
    <mat-slide-toggle
      [formControl]="innerFormControl"
      (blur)="propagateTouch()"
      [required]="isRequired"
      class="mat-form-field material-custom-slide-toggle"
    >
      {{ label | translate }}
    </mat-slide-toggle>
  </div>
  <mat-hint *ngIf="withFooter && hint">{{ hint }}</mat-hint>
  <mat-error *ngIf="withFooter && innerFormControl.touched">
    <span *ngFor="let error of errors">{{ error.transLang | translate : error.contextParams }}</span>
  </mat-error>
</div>
