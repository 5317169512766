import { ActivatedRouteSnapshot, ResolveFn, Routes } from '@angular/router';
import { DestinationResultsComponent } from './pages/destination-results/destination-results.component';
import { inject } from '@angular/core';
import { DestinationDataSourceService } from './logic/destination-data-source';
import { DestinationFormService } from './logic/destination-form.service';
import { DestinationEditConfigComponent } from './components/destination-edit-config/destination-edit-config.component';
import { DestinationAddConfigComponent } from './components/destination-add-config/destination-add-config.component';
import { DestinationDetailsComponent } from './pages/destination-details/destination-details.component';
import { DeliveryDestinationsDeactivateGuard } from './logic/delivery-destinations-deactivate.guard';
import { Permission, RequiresPermissionsGuard } from '@vdms-hq/activated-client';

export enum DELIVERY_DESTINATIONS_ROUTING {
  ROOT = 'delivery-destinations',
}

const currentIdResolver: ResolveFn<void> = (route: ActivatedRouteSnapshot) => {
  return inject(DestinationDataSourceService).setId(route.paramMap.get('id'));
};
const currentIdConfigResolver: ResolveFn<void> = (route: ActivatedRouteSnapshot) => {
  return inject(DestinationFormService).setConfigId(route.paramMap.get('configId'));
};

export const destinationsRoutes: Routes = [
  {
    path: '',
    component: DestinationResultsComponent,
  },
  {
    path: ':id',
    component: DestinationDetailsComponent,
    canActivate: [RequiresPermissionsGuard],
    data: { permissions: [Permission.EDIT_DELIVERY_DESTINATIONS] },
    canDeactivate: [DeliveryDestinationsDeactivateGuard],
    resolve: {
      destination: currentIdResolver,
    },
    children: [
      {
        path: 'add',
        component: DestinationAddConfigComponent,
        canActivate: [RequiresPermissionsGuard],
        data: { permissions: [Permission.EDIT_DELIVERY_DESTINATIONS] },
      },
      {
        path: ':configId',
        component: DestinationEditConfigComponent,
        canActivate: [RequiresPermissionsGuard],
        data: { permissions: [Permission.EDIT_DELIVERY_DESTINATIONS] },
        resolve: {
          destinationConfigId: currentIdConfigResolver,
        },
        canDeactivate: [DeliveryDestinationsDeactivateGuard],
      },
    ],
  },
  {
    path: '**',
    redirectTo: `/${DELIVERY_DESTINATIONS_ROUTING.ROOT}`,
    pathMatch: 'full',
  },
];
