<ng-container *ngIf="dataSource && configuration; else missingDataSource">
  <table
    mat-table
    matSort
    [dataSource]="(dataSource.connection$ | async) ?? []"
    (matSortChange)="isSortableDataSource(dataSource) && dataSource.sortChange($event)"
    [trackBy]="dataSource.trackBy ?? defaultTrackBy"
    *ngIf="columns; let columns"
    class="material-custom-table table-advanced"
  >
    <ng-container *ngIf="configuration.layout?.headers ?? true">
      <tr mat-header-row *matHeaderRowDef="columns.enabled; sticky: true" class="table-advanced__row"></tr>
    </ng-container>

    <tr
      class="table-advanced__row"
      mat-row
      *matRowDef="let row; columns: columns.enabled"
      (click)="handleClick($event, row)"
      (dblclick)="handleDoubleClick($event, row)"
      (contextmenu)="showContextMenu($event, row)"
      [class.cursor-pointer]="hasSingleClick"
      [class.disabled-row]="(configuration.rows | disableIfRow : row).disabled"
      [ngClass]="row | rowClasses : columns.defined : columns.enabled : configuration.rows?.highlightIf?.condition(row)"
    ></tr>

    <ng-container *ngIf="configuration.footer && isSelectableDataSource(dataSource)">
      <tr mat-footer-row *matFooterRowDef="columns.enabled" [hidden]="(dataSource.selection.total$ | async) === 0"></tr>
    </ng-container>

    <ng-container
      [matColumnDef]="column.id"
      [sticky]="!!(column | isColumnSelect)"
      [stickyEnd]="!!(column | isColumnAction) || !!(column | isSettingsColumn)"
      *ngFor="let column of columns.defined"
    >
      <ng-container *ngIf="column | isSettingsColumn; let column">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container *ngIf="configuration.settingsAction; let settings">
            <vdms-hq-ui-button
              [matTooltip]="settings.label ?? 'common.global.edit_columns' | translate"
              (click)="action.emit({ key: settings.id ?? 'settings' })"
              [iconOnly]="true"
              [icon]="settings.icon ?? 'settings'"
              [color]="settings.color ?? 'transparent'"
            >
            </vdms-hq-ui-button>
          </ng-container>
        </th>
        <td mat-cell *matCellDef="let row"></td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container *ngIf="column | isColumnAction; let column">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container *ngIf="configuration.settingsAction; let settings">
            <vdms-hq-ui-button
              [matTooltip]="settings.label ?? 'common.global.edit_columns' | translate"
              (click)="action.emit({ key: settings.id ?? 'settings' })"
              [iconOnly]="true"
              [icon]="settings.icon ?? 'settings'"
              [color]="settings.color ?? 'transparent'"
            >
            </vdms-hq-ui-button>
          </ng-container>
        </th>
        <td
          class="table-advanced__row-actions"
          mat-cell
          *matCellDef="let row"
          [style]="configuration.layout?.actionsSpace ? 'min-width: 125px' : ''"
        >
          <div *ngIf="configuration.actions | ifHiddenControls : row" class="table-advanced__row-actions-controls">
            <vdms-hq-ui-floating-controls-v2
              [actions]="configuration.actions ?? []"
              (action)="action.emit($event)"
              [item]="row"
              [alternativePillConfig]="configuration.rows"
            >
            </vdms-hq-ui-floating-controls-v2>
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container *ngIf="column | isColumnSelect; let column">
        <th mat-header-cell *matHeaderCellDef>
          <vdms-hq-ui-select-all-on-page
            *ngIf="configuration.selectAllVisible ?? true"
            [selection]="dataSource.selection"
          ></vdms-hq-ui-select-all-on-page>
        </th>
        <td mat-cell *matCellDef="let row" [class.disabled-row]="(configuration.rows | disableIfRow : row).disabled">
          <vdms-hq-ui-select-one [selection]="dataSource.selection" [item]="row"></vdms-hq-ui-select-one>
        </td>
        <td mat-footer-cell *matFooterCellDef class="no-bg"></td>
      </ng-container>

      <ng-container *ngIf="column | isColumnValue; let column">
        <th
          mat-header-cell
          *matHeaderCellDef
          [mat-sort-header]="column?.sortObjectPath ?? column.id"
          [disabled]="!isSortableDataSource(dataSource) || !(column?.sortable ?? false)"
        >
          {{ column.label | translate }}
          <ng-container *ngIf="column?.foldValues ?? false">
            <vdms-hq-ui-button
              (click)="toggleFoldUnfold($event, column.id)"
              [icon]="columnsUnfolded.has(column.id) ? 'unfold_less' : 'unfold_more'"
              style="transform: rotate(270deg)"
              [iconOnly]="true"
            ></vdms-hq-ui-button>
          </ng-container>
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          [matTooltip]="configuration.actions | isRowTooltip | translate"
          [matTooltipShowDelay]="500"
        >
          <vdms-hq-ui-value-renderer
            [value]="row | valueByPath : column.valuePath | async"
            [format]="column.viewFormat"
            [forcedUnfolded]="columnsUnfolded.has(column.id)"
            [fallback]="column.fallback ?? 'N/A'"
          ></vdms-hq-ui-value-renderer>
        </td>
        <td mat-footer-cell *matFooterCellDef class="no-bg">
          <ng-template
            [cdkPortalOutlet]="columnPortalOutlet"
            *ngIf="configuration.footer?.[column.id] let columnPortalOutlet"
          ></ng-template>
        </td>
      </ng-container>
    </ng-container>
  </table>
</ng-container>

<vdms-hq-ui-context-menu
  [actions]="configuration?.contextMenu?.actions ?? []"
  (action)="action.emit($event)"
></vdms-hq-ui-context-menu>

<ng-template #missingDataSource>
  <vdms-hq-ui-runtime-error></vdms-hq-ui-runtime-error>
</ng-template>
