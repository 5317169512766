import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@vdms-hq/auth';
import { PermissionAwareItem } from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import { InternalMenuItem, UISidenavMenuItem } from '@vdms-hq/ui';
import { Observable, of, switchMap } from 'rxjs';
import { map, take, tap, withLatestFrom } from 'rxjs/operators';
import { Permission } from '../../permission/permission';
import { PermissionService } from '../../permission/permission.service';
import { ACTIVATED_CLIENT_CONFIG_TOKEN, ActivatedClientConfig } from '../models/activated-client-config.model';
import { ActivatedClientService } from '../services/activated-client.service';

@Injectable({
  providedIn: 'root',
})
export class RequiresPermissionsGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly activatedClientService: ActivatedClientService,
    private readonly permissionService: PermissionService,
    @Inject(ACTIVATED_CLIENT_CONFIG_TOKEN) private activatedClientConfig: ActivatedClientConfig,
    private readonly authService: AuthService,
    private readonly toastService: ToastService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    if (!route.data?.permissions) {
      // TODO: consider Logger util implementation
      console.warn(
        `[WARNING] Permissions required to activate this route (/${route.url}) have not been specified. Are you sure this implementation is correct?`,
      );
    }

    return this.activatedClientService.permissions$.pipe(
      take(1),
      map((userPermissions: Permission[]) => {
        const hasPermissions = this.permissionService.verifyPermissions(
          route.data?.permissions,
          userPermissions,
          route.data?.operator,
        );
        if (!route.data?.requirePermissions) {
          return hasPermissions;
        }
        return (
          hasPermissions && this.permissionService.verifyPermissions(route.data?.requirePermissions, userPermissions)
        );
      }),
      switchMap((hasPermission) => {
        if (hasPermission) {
          return of(true);
        }
        if (route.data?.withRedirectToExisting) {
          return this.#findFirstPermittedRoute(route.data.withRedirectToExisting);
        }
        return of(this.router.parseUrl(this.activatedClientConfig.routing.insufficientPermissionsRoute)).pipe(
          tap(() =>
            this.toastService.error({
              id: route.data?.permissions,
              message: route.data?.message ?? 'common.notifications.permission.no_app_access',
            }),
          ),
        );
      }),
    );
  }

  #findFirstPermittedRoute({ menu }: { menu: { menuItems: UISidenavMenuItem[] }[] }) {
    return of(menu).pipe(
      take(1),
      withLatestFrom(this.activatedClientService.permissions$),
      map(([menuItems, userPermissions]) => {
        const firstPermittedRoute = (
          menuItems
            ?.reduce((prev: Array<UISidenavMenuItem & PermissionAwareItem>, curr) => [...prev, ...curr.menuItems], [])
            ?.find(({ permissions }) =>
              permissions?.ids[permissions?.comparison || 'some']((p) => userPermissions.includes(p as Permission)),
            ) as InternalMenuItem
        )?.route;
        return this.router.parseUrl(
          firstPermittedRoute ?? this.activatedClientConfig.routing.insufficientPermissionsRoute,
        );
      }),
    );
  }
}
