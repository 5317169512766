import { Injectable } from '@angular/core';
import { fromEvent, Observable, Subscription, switchMap } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { UIConfirmationDialogService } from '@vdms-hq/ui';

@Injectable({ providedIn: 'root' })
export class StorageHandlerService {
  #storageCleanListener: Observable<unknown> = fromEvent(window, 'storage').pipe(
    filter(({ key }: StorageEvent) => key === null),
    switchMap(() => {
      this.confirmationDialogService.open({
        title: 'Storage has been cleared',
        message: 'Browser local storage has been cleared - the page will be refreshed.',
        okAction: {
          label: 'ok',
          color: 'primary',
        },
        abortAction: null,
      });
      return this.confirmationDialogService.afterClosed$;
    }),
    tap(async () => {
      window.location.reload();
    }),
  );
  #listeners?: Subscription;

  constructor(private readonly confirmationDialogService: UIConfirmationDialogService) {}

  registerListeners(): void {
    if (this.#listeners) {
      return;
    }

    this.#listeners = this.#storageCleanListener.subscribe();
  }
}
