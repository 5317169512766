import { Injectable } from '@angular/core';
import { CloudFrontCookie, CookieEndpoint } from './cookie.model';
import { Observable } from 'rxjs';
import { ApiResponse } from '../api.model';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { ApiService } from '../api.service';

@Injectable({ providedIn: 'root' })
export class CookieApi {
  constructor(private apiService: ApiService) {}

  get = (): Observable<CloudFrontCookie> =>
    this.apiService.get<ApiResponse<CloudFrontCookie>>(`${CookieEndpoint.GET}`).pipe(GetResponseData);
}
