import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Policy, PolicyPatchRequest, PolicyPostRequest, PolicyResponse } from './policy.model';
import { PolicyEndpoints } from './endpoints';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { ApiService } from '../api.service';
import { ApiPaginatedResponse, ApiResponse } from '../api.model';
import { UserModel } from '../user/user.model';
import { HttpHeaders } from '@angular/common/http';
import { Pagination, PaginationProps } from '@vdms-hq/ui';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PolicyService {
  constructor(private readonly apiService: ApiService) {}

  public getPolicies(): Observable<Policy[]> {
    return this.apiService.get<ApiResponse<Policy[]>>(PolicyEndpoints.ROOT_PLURAL).pipe(
      GetResponseData,
      map((policies) =>
        policies.map((policy) => ({
          ...policy,
          isSuperAdmin: ['a4e7f94b-ab5a-466a-953c-37b984faf9b8'].includes(policy.uuid), // TODO: Remove this line when vida-backend/4302 will be done
        })),
      ),
    );
  }

  public getPoliciesUnRestricted(): Observable<Policy[]> {
    return this.apiService.get<ApiResponse<Policy[]>>(PolicyEndpoints.EMPTY).pipe(GetResponseData);
  }

  public postPolicy(policy: PolicyPostRequest): Observable<PolicyResponse> {
    return this.apiService
      .post<PolicyPostRequest, ApiResponse<PolicyResponse>>(PolicyEndpoints.ROOT, policy)
      .pipe(GetResponseData);
  }

  public patchPolicy(uuid: string, policy: PolicyPatchRequest): Observable<PolicyResponse> {
    return this.apiService
      .patch<PolicyPatchRequest, ApiResponse<PolicyResponse>>(`${PolicyEndpoints.ROOT}/${uuid}`, policy)
      .pipe(GetResponseData);
  }

  public getPolicyUsers(
    uuid: string,
    pagination?: PaginationProps & { text?: string },
  ): Observable<ApiPaginatedResponse<UserModel>> {
    let headers = Pagination.create(pagination).applyToHeaders(new HttpHeaders());
    headers = headers.append('text', pagination?.text ?? '');
    return this.apiService.get<ApiPaginatedResponse<UserModel>>(`${PolicyEndpoints.ROOT}/${uuid}/users`, { headers });
  }
}
