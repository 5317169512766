import {
  AssetIngestCompletedMessage,
  LaunchpadPackageApprovedMessage,
  NewAssetArrivedMessage,
  NewLaunchpadPackagePendingApprovalMessage,
  Notification,
  NotificationTypes,
} from '@vdms-hq/api-contract';
import { camelToSnakeCase } from '@vdms-hq/shared';

export class NotificationMessageViewModel {
  constructor(public props: Notification) {}

  get type() {
    return this.props.event_type;
  }

  get status() {
    return this.props.category.toLowerCase();
  }

  get isSuccess() {
    return this.status === 'successful';
  }

  get isRead() {
    return this.props.read_at;
  }

  get title() {
    return 'common.notification_subscriptions.types.type' + camelToSnakeCase(this.props.event_type).toLowerCase();
  }

  get message() {
    return this.props.message;
  }

  get launchpadApprovedMessage(): LaunchpadPackageApprovedMessage | null {
    const isApproval = this.type === NotificationTypes.LAUNCHPAD_PACKAGE_APPROVED;

    if (!isApproval) {
      return null;
    }

    return this.message as LaunchpadPackageApprovedMessage;
  }

  get launchpadPendingApprovalMessage(): NewLaunchpadPackagePendingApprovalMessage | null {
    const isApproval = this.type === NotificationTypes.NEW_LAUNCHPAD_PACKAGE_PENDING_APPROVAL;

    if (!isApproval) {
      return null;
    }

    return this.message as NewLaunchpadPackagePendingApprovalMessage;
  }

  get newAssetArrivedMessage(): NewAssetArrivedMessage | null {
    const isApproval = this.type === NotificationTypes.NEW_ASSET_ARRIVED;

    if (!isApproval) {
      return null;
    }

    return this.message as NewAssetArrivedMessage;
  }

  get ingestCompletedMessage(): AssetIngestCompletedMessage | null {
    const isApproval = this.type === NotificationTypes.ASSET_INGEST_COMPLETED;

    if (!isApproval) {
      return null;
    }

    return this.message as AssetIngestCompletedMessage;
  }

  get createdAt() {
    return this.props.created_at;
  }
}
