import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BreadCrumb } from '../../models/breadcrumb.model';

@Component({
  selector: 'vdms-hq-ui-layout-page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageWrapperComponent {
  @Input() breadcrumbs: BreadCrumb[] = [];
  @Input() additionalInfo = '';
  @Input() loading = false;
  @Input() gapSize: 'small' | 'medium' | null = null;
  @Input() breadcrumbsSize: 'small' | 'medium' = 'medium';
  @Input() maxWidth?: string;
}
