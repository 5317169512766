<div [formGroup]="audioForm" class="field-wrapper audio-tracks">
  <span class="field-label">{{ label }}</span>

  <div *ngIf="!forceReadOnly" class="audio-tracks-selector">
    <vdms-hq-ui-form-input-select
      label="Select number of audio tracks"
      formControlName="audioTracksCounter"
      [selectOptions]="possibleAudioTracks"
      [withFooter]="false"
    >
    </vdms-hq-ui-form-input-select>

    <vdms-hq-ui-button
      [disabled]="!isValueChanged()"
      (click)="isValueChanged() && updateAudioTracksForm()"
      color="primary"
      >Update fields
    </vdms-hq-ui-button>
  </div>

  <div formArrayName="audioTracks">
    <div *ngFor="let item of audioControls; let audioTrack = index" class="grid" [formGroupName]="audioTrack">
      <div class="grid-cell-values">
        <ng-container [ngSwitch]="type">
          <ng-container *ngSwitchCase="fieldType.AUDIO_TRACK">
            <div class="field-wrapper">
              <span class="field-label">{{ 'common.asset.audio_tracks.layout' | translate }}</span>
              <vdms-hq-selector
                *ngIf="!forceReadOnly"
                [sourceType]="selectorType.LAYOUT"
                formControlName="layout"
                class="material-custom-form"
              ></vdms-hq-selector>
              <span
                *ngIf="forceReadOnly"
                [selectorValue]="item.get('layout')?.value"
                [selectorSourceType]="selectorType.LAYOUT"
              ></span>
            </div>

            <div class="field-wrapper">
              <span class="field-label">{{ 'common.global.language' | translate }}</span>
              <vdms-hq-selector
                *ngIf="!forceReadOnly"
                [sourceType]="selectorType.LANGUAGE"
                [filterMethod]="filterMethod"
                formControlName="language"
                class="material-custom-form"
              ></vdms-hq-selector>
              <span
                *ngIf="forceReadOnly"
                [selectorValue]="item.get('language')?.value"
                [selectorSourceType]="selectorType.LANGUAGE"
              ></span>
            </div>

            <div class="field-wrapper">
              <span class="field-label">{{ 'common.asset.audio_tracks.audio_description' | translate }}</span>
              <vdms-hq-selector
                *ngIf="!forceReadOnly"
                [sourceType]="selectorType.AUDIO_DESCRIPTION"
                formControlName="audio_description"
                class="material-custom-form"
              ></vdms-hq-selector>
              <span
                *ngIf="forceReadOnly"
                [selectorValue]="item.get('audio_description')?.value"
                [selectorSourceType]="selectorType.AUDIO_DESCRIPTION"
              ></span>
            </div>

            <div class="field-wrapper">
              <span class="field-label">{{ 'common.asset.audio_tracks.loudness_standard' | translate }}</span>
              <vdms-hq-selector
                *ngIf="!forceReadOnly"
                [sourceType]="selectorType.LOUDNESS_STANDARD"
                formControlName="loudness_standard"
                class="material-custom-form"
              ></vdms-hq-selector>
              <span
                *ngIf="forceReadOnly"
                [selectorValue]="item.get('loudness_standard')?.value"
                [selectorSourceType]="selectorType.LOUDNESS_STANDARD"
              ></span>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="fieldType.AUDIO_TRACK_TEDIAL">
            <vdms-hq-ui-form-input-text
              *ngIf="!forceReadOnly"
              label="Class"
              formControlName="class"
            ></vdms-hq-ui-form-input-text>
            <span *ngIf="forceReadOnly">{{ item.get('class')?.value }}</span>

            <div class="field-wrapper">
              <span class="field-label">{{ 'common.global.language' | translate }}</span>
              <vdms-hq-selector
                *ngIf="!forceReadOnly"
                [sourceType]="selectorType.LANGUAGE"
                formControlName="language"
                class="material-custom-form"
              ></vdms-hq-selector>
              <span
                *ngIf="forceReadOnly"
                [selectorValue]="item.get('language')?.value"
                [selectorSourceType]="selectorType.LANGUAGE"
              ></span>
            </div>

            <vdms-hq-ui-form-input-text
              *ngIf="!forceReadOnly"
              label="Layout"
              formControlName="layout"
            ></vdms-hq-ui-form-input-text>
            <span *ngIf="forceReadOnly">{{ item.get('layout')?.value }}</span>
          </ng-container>
        </ng-container>
      </div>
      <div class="grid-cell-label">
        <div>Audio Track {{ audioTrack + 1 }}</div>
        <div class="unlock" *ngIf="!forceReadOnly">
          <vdms-hq-ui-button
            *ngIf="item.disabled"
            (click)="item.enable()"
            [iconOnly]="true"
            icon="lock"
            color="secondary"
          >
          </vdms-hq-ui-button>
        </div>
      </div>
    </div>
  </div>
</div>
