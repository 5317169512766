import { AfterViewInit, Component, Input } from '@angular/core';
import { AnyFileComponent } from '../any-file.component';
import { SafeHtml } from '@angular/platform-browser';
import hljs, { AutoHighlightResult } from 'highlight.js';

@Component({
  selector: 'vdms-hq-ui-code-support',
  templateUrl: './code-support.component.html',
  styleUrls: ['./code-support.component.scss', './../any-file.component.scss'],
})
export class CodeSupportComponent extends AnyFileComponent implements AfterViewInit {
  static supportsExtensions = ['yml', 'yaml', 'xml', 'txt', 'json', 'srt'] as const;
  code = '';
  @Input() config = true;
  private result?: AutoHighlightResult;
  html?: SafeHtml;

  async ngAfterViewInit(): Promise<void> {
    const response = await this.fetch();

    if (response) {
      this.code = await response.text();

      switch (this.settings.file.extension) {
        case 'srt':
        case 'txt':
          this.result = hljs.highlight('plaintext', this.code);
          break;
        case 'xml':
          this.result = hljs.highlight(this.code, { language: 'xml' });
          break;
        default:
          this.result = hljs.highlightAuto(this.code);
      }
      this.html = this.sanitizer.bypassSecurityTrustHtml(this.result.value);
    }

    this.loaded();
    this.cdr.detectChanges();
  }
}
