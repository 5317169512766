<app-layout-section>
  <div class="aspera-message">
    <div class="aspera-message__icon">
      <img src="/assets/common/logos/aspera.svg" alt="Aspera Connect">
    </div>

    <div class="aspera-message__content">
      <p>Aspera Connect is not detected!</p>
      <p>Please test and troubleshoot your Aspera setup with online
        <a href="https://test-connect.ibmaspera.com/" target="_blank">
          IBM Aspera Connect Diagnostic Tool</a>
      </p>
    </div>

    <div class="aspera-message__button">
      <a href="https://test-connect.ibmaspera.com/" target="_blank">
        <vdms-hq-ui-button color="primary">START DIAGNOSTICS</vdms-hq-ui-button>
      </a>
    </div>
  </div>

</app-layout-section>


