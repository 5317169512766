<div class="player" #playerContainer>
  <div class="player__video" id="advanced-player-window"></div>
  <ng-container *ngIf="(advancedPlayerService.state$ | async)?.state; let state">
    <vdms-hq-ui-loader *ngIf="state === 'loading'" [backdrop]="true" mode="over-parent"></vdms-hq-ui-loader>
    <vdms-hq-ui-empty-results
      *ngIf="state === 'error'"
      message="Unable to load this video, please contact support."
    ></vdms-hq-ui-empty-results>
    <div
      class="player__controls"
      [class.player__controls--fixed]="fixedControls"
      *ngIf="state === 'ready' && advancedPlayerService.player; let player"
    >
      <vdms-hq-ui-button
        (click)="advancedPlayerService.handleAction('togglePlayPause')"
        size="small"
        [icon]="player.video.isPlaying() ? 'pause' : 'play_arrow'"
        [iconOnly]="true"
      >
      </vdms-hq-ui-button>

      <div
        class="player__controls__voice-section"
        *ngIf="(peakMeterService.isConnected$ | async) === false; else volumeOff"
      >
        <vdms-hq-ui-button
          size="small"
          class="player__controls__voice-section__mute-btn"
          (click)="advancedPlayerService.handleAction('toggleMute')"
          [icon]="player.video.getVolume() === 0 ? 'volume_off' : 'volume_up'"
          [iconOnly]="true"
        >
        </vdms-hq-ui-button>

        <div class="advanced-player-slider voice-level-slider">
          <mat-slider
            thumbLabel
            [displayWith]="formatVolumeTooltip"
            [step]="0.05"
            [min]="0"
            [max]="1"
            [value]="advancedPlayerService.player.video.getVolume()"
            (valueChange)="updateVolume($event)"
            [vertical]="true"
          >
            <input matSliderThumb />
          </mat-slider>
        </div>
      </div>

      <ng-template #volumeOff>
        <vdms-hq-ui-button
          size="small"
          class="player__controls__voice-section__mute-btn"
          [icon]="'volume_mute'"
          [iconOnly]="true"
          [disabled]="true"
        >
        </vdms-hq-ui-button>
      </ng-template>

      <div class="advanced-player-slider timeline-control">
        <mat-slider
          thumbLabel
          [displayWith]="formatSliderTooltip"
          [step]="0.01"
          [min]="0"
          [max]="(advancedPlayerService.duration$ | async)?.countSeconds() ?? 0"
          [value]="(advancedPlayerService.currentTimecode$ | async)?.countSeconds() ?? 0"
          (valueChange)="advancedPlayerService.goToTime($event ?? 0)"
          (mousemove)="mouseMoveTimelineBar($event)"
        >
          <input matSliderThumb />
        </mat-slider>
        <ng-container>
          <span>{{ (advancedPlayerService.currentTimecode$ | async) ?? fallbackTimecode }}</span>
          <span class="lighter">/ {{ advancedPlayerService.duration$ | async }}</span>
        </ng-container>
        <div class="preview" [ngStyle]="spriteCss" *ngIf="spriteCss">
          <div class="thumb-label-timecode"></div>
        </div>
      </div>
      <vdms-hq-ui-button
        size="small"
        [iconOnly]="true"
        icon="headphones"
        [matMenuTriggerFor]="audioTracksMenu"
        [disabled]="(advancedPlayerService.audioTracks$ | async)?.length === 0"
      ></vdms-hq-ui-button>
      <vdms-hq-ui-button
        size="small"
        [matMenuTriggerFor]="subtitlesMenu"
        [icon]="(advancedPlayerService.currentSubtitle$ | async) ? 'closed_caption' : 'closed_caption_disabled'"
        [iconOnly]="true"
      >
      </vdms-hq-ui-button>
      <vdms-hq-ui-button [iconOnly]="true" (click)="advancedPlayerService.handleAction('increasePlaybackRate')">
        <span class="small-icon-text">x{{ advancedPlayerService.currentPlaybackRate$ | async }}</span>
      </vdms-hq-ui-button>
      <vdms-hq-ui-button
        size="small"
        [iconOnly]="true"
        (click)="fixedControls = !fixedControls"
        [icon]="fixedControls ? 'visibility' : 'visibility_off'"
      >
      </vdms-hq-ui-button>
      <vdms-hq-ui-button
        size="small"
        [iconOnly]="true"
        icon="settings"
        [matMenuTriggerFor]="qualityMenu"
      ></vdms-hq-ui-button>
      <vdms-hq-ui-button
        size="small"
        [iconOnly]="true"
        (click)="advancedPlayerService.handleAction('toggleFullScreen')"
        icon="fullscreen"
      >
      </vdms-hq-ui-button>
    </div>
  </ng-container>
</div>

<mat-menu #subtitlesMenu="matMenu">
  <button
    mat-menu-item
    (click)="advancedPlayerService.handleAction('changeSubtitles')"
    [class.highlighted]="(advancedPlayerService.currentSubtitle$ | async) === null"
  >
    {{ 'common.advanced_player.action_buttons.no_subtitles' | translate }}
  </button>
  <button
    mat-menu-item
    *ngFor="let subtitle of advancedPlayerService.loadedSubtitles$ | async"
    (click)="advancedPlayerService.handleAction('changeSubtitles', subtitle)"
    [class.highlighted]="(advancedPlayerService.currentSubtitle$ | async)?.path === subtitle.path"
  >
    {{ subtitle.language }}
  </button>
</mat-menu>

<mat-menu #qualityMenu="matMenu">
  <button
    mat-menu-item
    (click)="advancedPlayerService.handleAction('changeSubtitles', -1)"
    [class.highlighted]="(advancedPlayerService.currentQuality$ | async) === -1"
  >
    {{ 'common.advanced_player.action_buttons.quality_auto' | translate }}
  </button>
  <button
    mat-menu-item
    *ngFor="let quality of advancedPlayerService.qualityOptions$ | async; let i = index"
    (click)="advancedPlayerService.handleAction('changeQualityLevel', i)"
    [class.highlighted]="(advancedPlayerService.currentQuality$ | async) === i"
  >
    {{ quality.label }}
  </button>
</mat-menu>

<mat-menu #audioTracksMenu="matMenu">
  <button
    mat-menu-item
    *ngFor="let track of advancedPlayerService.audioTracks$ | async"
    (click)="advancedPlayerService.handleAction('changeAudioTrack', track.id)"
    [class.highlighted]="(advancedPlayerService.currentAudioTrack$ | async) === track.id"
  >
    {{ track.name }}
  </button>
</mat-menu>
