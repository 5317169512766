import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyCartComponent } from './empty-cart/empty-cart.component';
import { MatIconModule } from '@angular/material/icon';
import { UIButtonModule } from '../ui-button';
import { RouterLink } from '@angular/router';

@NgModule({
  declarations: [EmptyCartComponent],
  exports: [EmptyCartComponent],
  imports: [CommonModule, MatIconModule, UIButtonModule, RouterLink],
})
export class UiEmptyCartModule {}
