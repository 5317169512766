<div class="multi-data-container">
  <vdms-hq-ui-data-presentation-header
    (action)="handleHeaderAction($event)"
    [actions]="headerActions$ | async"
    [breadcrumbs]="[
      { name: 'Home', path: '/' },
      { name: 'common.title', path: '../' }
    ]"
    [title]="(dataSource.collectionData$ | async)?.name ? (dataSource.collectionData$ | async)?.name : ' '"
    [counterIndicator]="dataSource.total$ | async"
    [listConfig]="(dataSource.collectionData$ | async)?.metadata ?? []"
  >
  </vdms-hq-ui-data-presentation-header>

  <div class="sub-collections" *ngIf="(subCollectionDataSource.allData$ | async)?.length > 0">
    <vdms-hq-ui-multiple-data-presentation
      (action)="emitAction($event)"
      [dataSource]="subCollectionDataSource"
      [configuration]="subCollectionConfig"
    >
      <h2 filters>{{ 'pages.lists.sub' | translate }}</h2>
    </vdms-hq-ui-multiple-data-presentation>
    <mat-divider></mat-divider>
  </div>

  <vdms-hq-asset-results-2
    *ngIf="actions$ | async as actions"
    [dataSource]="collectionAssetDataSource"
    [actions]="actions"
    [contextActions]="assetResultsMenuConfig2"
    [scopeName]="scopeName"
    (action)="customActionHandler($event)"
  >
    <vdms-hq-dynamic-form-group-renderer
      filters
      *ngIf="filterService.config as fieldsConfig"
      [form]="filterService.form"
      [fieldsConfig]="fieldsConfig"
      [isLoading]="dataSource.isLoading$ | async"
    ></vdms-hq-dynamic-form-group-renderer>
  </vdms-hq-asset-results-2>
</div>

<vdms-hq-ui-actions-footer *ngIf="collectionAssetDataSource.selection.total$ | async; let totalSelected">
  <ng-container left>
    <vdms-hq-ui-button color="secondary" (click)="customActionHandler({ key: 'deselect_all' })">
      {{ 'common.global.deselect_all' | translate }}
    </vdms-hq-ui-button>
    <ng-container>
      <vdms-hq-ui-button
        *requiresPermissions="[Permission.EDIT_ASSETS]"
        (click)="customActionHandler({ key: 'batch_update' })"
        color="primary"
        >{{ 'pages.assets_list.batch_update' | translate }}
      </vdms-hq-ui-button>
    </ng-container>
    <vdms-hq-ui-button
      *requiresPermissions="[Permission.EDIT_COLLECTIONS]"
      (click)="customActionHandler({ key: 'asset.collection' })"
      color="secondary"
      >{{ 'pages.lists.add_to_collection' | translate }}
    </vdms-hq-ui-button>
  </ng-container>

  <vdms-hq-ui-button center>
    {{ 'common.global.selected' | translate : { selected: totalSelected } }}
  </vdms-hq-ui-button>
  <ng-container right>
    <ng-container
      *ngIf="
        (collectionAssetDataSource.selection.entities$ | async) &&
        (dataSource.collectionData$ | async)?.access_type !== 'dashboard'
      "
    >
      <vdms-hq-ui-button
        *requiresPermissions="[Permission.EDIT_COLLECTIONS]"
        (click)="customActionHandler({ key: 'asset.delete' })"
        color="warn"
        >{{ 'common.global.remove_from_collection' | translate }}
      </vdms-hq-ui-button>
    </ng-container>
    <vdms-hq-ui-button
      *ngIf="cartStateService.isClipOnlyAndPermitted$ | async"
      (click)="customActionHandler({ key: 'cart.add' })"
      color="primary"
      >{{ 'pages.assets_list.add_to_basket' | translate }}
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-actions-footer>
