import * as fromMessages from './messages.reducer';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

export const featureKey = 'toast';

export interface ToastState {
  messages: fromMessages.MessageState;
}

export const reducers: ActionReducerMap<ToastState> = {
  messages: fromMessages.messagesReducer,
};

const featureSelector = createFeatureSelector<ToastState>(featureKey);
const toastMessagesState = createSelector(featureSelector, (state) => state.messages);

export const getAllMessages = createSelector(toastMessagesState, fromMessages.selectAllMessages);
export const getAllMessagesEntities = createSelector(toastMessagesState, fromMessages.selectMessageEntities);

export const getMessageById = (id: string) => createSelector(getAllMessagesEntities, (items) => items[id] ?? null);
