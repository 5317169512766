import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { isCompositeItem, isSeparator, Separator, SidebarItem, SidebarNestedItem } from '../../models/sidebar.model';
import { castTo } from '@vdms-hq/shared';

@Component({
  selector: 'vdms-hq-ui-inner-sidebar',
  templateUrl: './inner-sidebar.component.html',
  styleUrls: ['./inner-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InnerSidebarComponent {}
