<div class="multi-data-container" *ngIf="configuration$ | async; let configuration">
  <vdms-hq-ui-data-presentation-header
    [title]="title | translate"
    [counterIndicator]="dataSource.total$ | async"
    [actions]="headerActions"
    (action)="handleHeaderAction($event)"
  >
  </vdms-hq-ui-data-presentation-header>

  <vdms-hq-ui-multiple-data-presentation
    [configuration]="configuration"
    [dataSource]="dataSource"
    (action)="emitAction($event)"
    (changeDefaultView)="changeDefaultView.emit($event)"
  >
    <vdms-hq-dynamic-form-group-renderer
      filters
      [form]="filtersForm.filters"
      [fieldsConfig]="filtersConfig"
    ></vdms-hq-dynamic-form-group-renderer>
  </vdms-hq-ui-multiple-data-presentation>
</div>
