import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/browser';

if (environment.production) {
  enableProdMode();
}
const isRunningOutsideLocalhost = !window.location.hostname.includes('localhost');
const isStg = environment.type === 'stage';
const sentryDSN = environment.sentryDSN;

if (isRunningOutsideLocalhost && isStg && sentryDSN) {
  Sentry.init({
    dsn: sentryDSN,
    debug: false,
    environment: environment.type,
    attachStacktrace: true,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: ['stage', 'demo'].includes(environment.type) ? 1 : 0.1,
    release: environment.version,
    ignoreErrors: [
      'ResizeObserver loop completed with undelivered notifications',
      'Proof of ownership of a second factor is required to complete',
    ],
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
