import {
  CollectionChildGet,
  CollectionModelFlat,
  CollectionModelGet,
  FlatCollectionViewModel,
  CollectionViewModel,
} from '@vdms-hq/api-contract';
import { SimpleDetailsList } from '@vdms-hq/ui';
import { COLLECTIONS_ROUTER_BASE } from '../../pages/collections-routes';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { StorageUrlService } from '@vdms-hq/storage';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class CollectionMetadataTransformerSnippet {
  constructor(
    private storageUrlService: StorageUrlService,
    private datePipe: DatePipe,
    private translate: TranslateService,
  ) {}

  transformCollectionWithMetadata(
    collection: CollectionModelGet | CollectionChildGet | CollectionModelFlat,
    userId?: string,
  ): FlatCollectionViewModel | CollectionViewModel {
    return {
      metadata: this.createMetadata(collection, userId),
      statuses: {
        color: 'success',
        hideDot: false,
        label: '',
      },
      title: collection.name,
      ...collection,
      routerLink: `/${COLLECTIONS_ROUTER_BASE.COLLECTIONS}/${collection.uuid}`,
      tile: {
        id: collection.uuid,
        background: this.storageUrlService.updateCdn(collection.custom_cover_path) ?? '',
        backgroundHover: this.storageUrlService.updateCdn(collection.custom_cover_path) ?? '',
        header: '',
        icon: '',
        iconIndicator: 'neutral',
        metadata: [],
        subheader: '',
      },
    };
  }

  createMetadata(
    collection: CollectionModelGet | CollectionChildGet | CollectionModelFlat,
    userId?: string,
  ): SimpleDetailsList[] {
    let metadata: SimpleDetailsList[] = [];
    const isDashboard = collection.access_type === 'dashboard';

    if (isDashboard) {
      metadata = [
        {
          title: 'common.collection_type',
          value: 'common.collection_type_dashboard',
        },
      ];
    }

    if (collection.owner && !isDashboard) {
      const isOwner = userId && collection.owner === userId;
      metadata = [
        {
          title: 'common.collection_type',
          value: isOwner ? 'common.collection_type_owned' : 'common.collection_type_shared',
        },
      ];

      if (!isOwner) {
        metadata = [
          ...metadata,
          {
            title: 'common.collection_owner',
            value: collection.owner_name ?? 'N/A',
          },
        ];
      }
    }

    if (collection.number_of_assets) {
      metadata = [
        ...metadata,
        {
          title: 'common.assets_number',
          value: collection.number_of_assets,
        },
      ];
    }

    if (collection.created_at && collection.created_at !== '0000-00-00 00:00:00') {
      metadata = [
        ...metadata,
        {
          title: 'common.created_at',
          value: this.datePipe.transform(collection.created_at, 'dd-MM-yyyy hh:mm'),
        },
      ];
    }

    if (collection.updated_at && collection.updated_at !== '0000-00-00 00:00:00') {
      metadata = [
        ...metadata,
        {
          title: 'common.updated_at',
          value: this.datePipe.transform(collection.updated_at, 'dd-MM-yyyy hh:mm'),
        },
      ];
    }

    metadata = [
      ...metadata,
      {
        title: 'common.description',
        value: collection.description,
        size: 'wide',
      },
    ];

    return metadata;
  }
}
