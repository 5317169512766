export enum CredentialTypeEnum {
  AWS = 'AWS',
  FTP = 'FTP', //(not implemented yet)
  WASABI = 'WASABI',
  ASPERA = 'ASPERA',
}
export interface VisibleDataTypes {
  [CredentialTypeEnum.AWS]: {
    accessKeyId: string;
  };
  [CredentialTypeEnum.FTP]: {
    username: string;
  };
  [CredentialTypeEnum.WASABI]: {
    host: string;
    name: string;
    region: string;
    accessKeyId: string;
  };
  [CredentialTypeEnum.ASPERA]: {
    apiUrl: string;
    apiPort: string;
    destinationRoot: string;
    username: string;
  };
}
export interface SecuredDataTypes {
  [CredentialTypeEnum.AWS]: {
    secretAccessKey: string;
  };
  [CredentialTypeEnum.FTP]: {
    password: string;
  };
  [CredentialTypeEnum.WASABI]: {
    secretAccessKey: string;
  };
  [CredentialTypeEnum.ASPERA]: {
    password: string;
  };
}
export interface ConfigDataTypes {
  [CredentialTypeEnum.AWS]: {
    region: string;
    bucketName: string;
    prefix: string;
  };
  [CredentialTypeEnum.FTP]: {
    path: string;
  };
}
type VisibleDataType<T extends CredentialTypeEnum> = VisibleDataTypes[T];
type SecuredDataType<T extends CredentialTypeEnum> = SecuredDataTypes[T];

export interface CredentialInterface<T extends CredentialTypeEnum> {
  is_used: boolean;
  uuid: string;
  name: string;
  type: T;
  visible_data: VisibleDataType<T>;
  secured_data?: SecuredDataType<T>;
}
export type GetCredentialInterface<T extends CredentialTypeEnum> = Omit<CredentialInterface<T>, 'secured_data'>;
export type PatchCredentialInputInterface<T extends CredentialTypeEnum> = Omit<CredentialInterface<T>, 'uuid' | 'type'>;
export type PostCredentialInputInterface<T extends CredentialTypeEnum> = CredentialInterface<T>;
