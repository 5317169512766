import { Routes } from '@angular/router';
import { CredentialsListComponent } from './pages/credentials-list/credentials-list.component';

export enum CREDENTIALS_ROUTES {
  ROOT = 'credentials',
}

export const CREDENTIALS_ROUTING: Routes = [
  {
    path: '',
    component: CredentialsListComponent,
  },
  {
    path: '**',
    redirectTo: `/${CREDENTIALS_ROUTES.ROOT}`,
    pathMatch: 'full',
  },
];
