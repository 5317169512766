import { AfterViewInit, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsFormService } from '../../logic/settings-form.service';
import { FormsModule } from '@angular/forms';
import { UILayoutModule } from '@vdms-hq/ui';
import { UserProfileDetailsComponent } from '../user-profile-details/user-profile-details.component';
import { UserProfileSecurityComponent } from '../user-profile-security/user-profile-security.component';

@Component({
  selector: 'vdms-hq-user-profile-settings',
  standalone: true,
  imports: [CommonModule, FormsModule, UILayoutModule, UserProfileDetailsComponent, UserProfileSecurityComponent],
  templateUrl: './user-profile-settings.component.html',
  styleUrls: ['./user-profile-settings.component.scss'],
})
export class UserProfileSettingsComponent implements AfterViewInit {
  settingsFormService = inject(SettingsFormService);
  form = this.settingsFormService.profileSettingsControl;

  ngAfterViewInit() {
    this.form.markAsPristine();
    this.settingsFormService.markAsPristine();
    this.settingsFormService.profileSettingsControl.markAsPristine();
  }
}
