import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from '@vdms-hq/auth';
import { API_CONFIG, ApiConfig } from '../config-token';
import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isApplicableUrl } from './helper/applicable-url';
import { publicPrefixes } from './config/public-prefixes';

@Injectable()
export class BearerInterceptor implements HttpInterceptor {
  constructor(@Inject(API_CONFIG) private env: ApiConfig, private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!isApplicableUrl(this.env.apiUrl, request.url, publicPrefixes)) {
      return next.handle(request.clone());
    }

    return this.authService.activeToken$.pipe(
      mergeMap((token) => {
        let headers = request.headers;

        headers = headers.append('Authorization', `Bearer ${token ?? ''}`);

        const newReq = request.clone({ headers });

        return next.handle(newReq);
      }),
    );
  }
}
