export enum FieldType {
  TAGS = 'tags',
  AUDIO_TRACK = 'audio_tracks',
  AUDIO_TRACK_TEDIAL = 'audio_tracks_tedial',
  AUDIO_TRACK_TABLE = 'audio_track_table',
  CHECKBOX = 'checkbox',
  CHECKBOX_LIST = 'checkbox_list',
  SLIDER = 'slider',
  CHIPS = 'chips',
  DATE = 'date',
  TIMECODE = 'timecode',
  TIMECODE_AS_SECONDS = 'timecode_as_seconds',
  FILE_SIZE = 'file_size',
  EMAIL = 'email',
  NUMBER = 'number',
  RADIO = 'radio',
  SELECT = 'select',
  SELECT_MULTI = 'select_multi',
  SELECT_GROUP = 'select_group',
  TEXT = 'text',
  TEXTAREA = 'textarea',
  DATE_TIME_UNIX_TIMESTAMP_LIMITED = 'DATE_TIME_UNIX_TIMESTAMP_LIMITED',
  AI_TRANSCODE_SUMMARY = 'ai_transcode_summary',
}
