import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SimplePlayerService } from '../../logic/simple-player.service';
import { CommonModule } from '@angular/common';
import { UIEmptyResultsModule, UILoaderModule } from '@vdms-hq/ui';
import { map } from 'rxjs/operators';

@Component({
  selector: 'vdms-hq-simple-player',
  templateUrl: './simple-player.component.html',
  styleUrls: ['./simple-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UILoaderModule, UIEmptyResultsModule],
})
export class SimplePlayerComponent implements OnInit, OnDestroy {
  @ViewChild('videoHost', { read: ElementRef }) videoHostRef?: ElementRef<HTMLElement>;
  spriteCss: Record<string, string | number> | null = null;
  state$ = this.simplePlayerService.state$.pipe(map((item) => item.state));

  ngOnInit(): void {
    this.simplePlayerService.load();
  }

  ngOnDestroy() {
    this.simplePlayerService.unload();
  }

  constructor(public simplePlayerService: SimplePlayerService) {}

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: MouseEvent) {
    const spriteOptions = this.simplePlayerService.config?.thumbnailsSprite;
    if (!spriteOptions) {
      this.spriteCss = null;
      return;
    }

    const target = e.target as HTMLElement;
    if (
      !target.classList.contains('vjs-progress-control') &&
      !target.classList.contains('vjs-mouse-display') &&
      !target.classList.contains('vjs-slider-bar') &&
      !target.classList.contains('vjs-slider')
    ) {
      this.spriteCss = null;
      return;
    }

    const controls = this.videoHostRef?.nativeElement.querySelectorAll('.vjs-progress-control')?.[0] as HTMLElement;
    if (!controls) {
      this.spriteCss = null;
      return;
    }

    const timeElement = this.videoHostRef?.nativeElement.querySelectorAll('.vjs-time-tooltip')?.[0] as HTMLElement;
    if (!timeElement) {
      this.spriteCss = null;
      return;
    }

    const options = spriteOptions;
    let time = timeElement?.innerText;
    if (/^\d+$/.test(time)) {
      time = '0:0:' + time;
    } else if (/^\d+:\d+$/.test(time)) {
      time = '0:' + time;
    }

    const temp = time.split(':');
    const timeSeconds = +temp[0] * 60 * 60 + +temp[1] * 60 + +temp[2];

    const thumbNumber = Math.floor(timeSeconds / options.second);

    const positionX = thumbNumber * options.thumbnailWidth * -1;
    const videoHostLeftMargin = this.videoHostRef?.nativeElement.getBoundingClientRect().x ?? 0;
    const spritePosition = e.x - videoHostLeftMargin;

    const left = spritePosition - options.thumbnailWidth / 2;

    this.spriteCss = {
      position: 'absolute',
      width: options.thumbnailWidth + 'px',
      height: options.thumbnailHeight + 'px',
      bottom: '55px',
      border: 'solid 1px black',
      boxShadow: '2px -1px 36px -6px black',
      borderRadius: '5px',
      backgroundColor: 'black',
      backgroundImage: 'url(' + options.spriteUrl + ')',
      backgroundPositionX: positionX + 'px',
      display: 'block',
      backgroundRepeat: 'no-repeat',
      left: left + 'px',
    };
  }
}
