import { Injectable } from '@angular/core';
import { AuthenticatedUserModel, AuthService } from '@vdms-hq/auth';
import { TourCfg, TourSettings } from '../models/tour-config';
import { TOUR_SETTINGS } from '../models/tour-settings.config';
import { catchError, distinctUntilChanged, last, map, NEVER, takeWhile, tap, timer } from 'rxjs';
import { filter, take, withLatestFrom } from 'rxjs/operators';
import { ActivatedClientService, Permission } from '@vdms-hq/activated-client';
import { sortBy } from 'lodash';
import { NavigationEnd, Router } from '@angular/router';
import { BdcWalkService } from 'bdc-walkthrough';

@Injectable({ providedIn: 'root' })
export class TourGuideSettingsService {
  private clientSwitcherExist = true;
  private cartButtonExist = true;
  private menuLibraryExist = true;

  settings: TourSettings = sortBy(TOUR_SETTINGS, 'order');

  constructor(
    private router: Router,
    private authService: AuthService,
    private readonly activatedClientService: ActivatedClientService,
    private bdcWalkService: BdcWalkService,
  ) {
    this.authService.auth$
      .pipe(
        tap(this.#resetOnLogout.bind(this)),
        filter(Boolean),
        withLatestFrom(this.activatedClientService.permissions$),
        tap(([auth]) => this.#verifyClients(auth)),
        tap(([, permissions]) => {
          this.#verifyCartButton(permissions);
          this.#verifyMenuLibrary(permissions);
        }),
        tap(() => (this.settings = this.settings.filter(Boolean))),
      )
      .subscribe(this.#setLastToExitTour.bind(this));

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map(() => window.location.pathname),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.#switchSearchBarClickOnComplete();
        this.#verifySearchOpenAsset();
      });
  }

  #resetOnLogout(auth?: AuthenticatedUserModel | null): void {
    if (!auth) {
      this.bdcWalkService.disableAll(true);
      this.bdcWalkService.reset();
      this.bdcWalkService.setTaskCompleted('taskInitializedTour', false);
    }
  }

  #verifyClients(authUser: AuthenticatedUserModel): void {
    if (authUser.clients.length <= 1) {
      this.settings = this.settings
        .map((s: TourCfg | null) => {
          if (s?.name === 'clientSwitcher') {
            this.clientSwitcherExist = false;
            return null;
          }
          return s;
        })
        .map((s: TourCfg | null) => {
          if (s?.name === 'cartButton') {
            return { ...s, mustCompleted: { userPanel: true } } as TourCfg;
          }
          return s;
        }) as TourSettings;
    }
  }

  #verifyCartButton(permissions: Permission[]): void {
    if (![Permission.SHOPPING_CART, Permission.SHOPPING_CART_V2].some((p) => permissions.includes(p))) {
      this.settings = this.settings
        .map((s: TourCfg | null) => {
          if (s?.name === 'cartButton') {
            this.cartButtonExist = false;
            return null;
          }
          return s;
        })
        .map((s: TourCfg | null) => {
          if (s?.name === 'menuSidebarToggle') {
            const mustCompleted = this.clientSwitcherExist ? { clientSwitcher: true } : { userPanel: true };
            return { ...s, mustCompleted } as TourCfg;
          }
          return s;
        }) as TourSettings;
    }
  }

  #verifyMenuLibrary(permissions: Permission[]): void {
    if (!permissions.includes(Permission.BROWSE_ASSETS_LIBRARY)) {
      this.settings = this.settings
        .map((s: TourCfg | null) => {
          if (s?.name === 'menuLibrary') {
            this.menuLibraryExist = false;
            return null;
          }
          return s;
        })
        .map((s: TourCfg | null) => {
          if (s?.name === 'searchBar') {
            const mustCompleted = this.menuLibraryExist ? { menuLibrary: true } : { menuAssets: true };
            return { ...s, mustCompleted } as TourCfg;
          }
          return s;
        }) as TourSettings;
    }
  }

  #verifySearchOpenAsset(): void {
    timer(1000, 1000)
      .pipe(
        takeWhile(() => this.isBrowseUrl),
        map(() => document.querySelector('[data-tourguide-item="searchOpenAsset"]')),
        takeWhile((element) => element === null, true),
        take(5),
        last(),
        catchError(() => NEVER),
      )
      .subscribe({
        next: (element) => {
          this.settings.forEach((settings, index) => {
            if (settings.name === 'searchListSettings') {
              settings.button = !!element;
            }
          });
        },
      });
  }

  #setLastToExitTour(): void {
    this.settings[this.settings.length - 1].button = false;
  }

  #switchSearchBarClickOnComplete(): void {
    const setting = this.settings.find((s) => s.name === 'searchBar');
    if (!setting) {
      return;
    }
    setting.clickToComplete = !this.isBrowseUrl;
    setting.text = setting.text.replace('[Click it] ', '');
    if (setting.clickToComplete) {
      setting.text = '[Click it] ' + setting.text;
    }
  }

  get isBrowseUrl(): boolean {
    const pathName = window.location.pathname;
    const isExcluded = ['/browse-library'].some((p) => pathName.startsWith(p));
    return pathName.startsWith('/browse') && !isExcluded;
  }
}
