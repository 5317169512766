<div class="checkout-buttons">
  <ng-container *ngFor="let item of config">
    <vdms-hq-ui-button
      [color]="item.color"
      *ngIf="item.visible"
      [disabled]="item.disabled || false"
      [loading]="item.isLoading || false"
      [size]="item.size || 'full'"
      (click)="actions.emit(item.action)"
    >
      {{ isString(item.label) ? (item.label | translate) : (item.label.text | translate : item.label.params || {}) }}
    </vdms-hq-ui-button>
  </ng-container>
</div>
