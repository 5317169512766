import { CommonModule } from '@angular/common';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { AuthModule } from '@vdms-hq/auth';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LoginFormModule } from './login-form.module';
import { LicenseAgreementDialogComponent } from './components/license-agreement-dialog/license-agreement-dialog.component';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { LicenseContentComponent } from './components/license-agreement-dialog/lecense-content/license-content.component';
import { MfaCodeDialogComponent } from './components/mfa-code-dialog/mfa-code-dialog.component';
import { PasswordlessResolver } from './logic/passwordless-resolver';
import { MfaReminderDialogComponent } from './components/mfa-reminder-dialog/mfa-reminder-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: LoginPageComponent, resolve: { passwordLess: PasswordlessResolver } },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    AuthModule,
    TranslateModule,
    LoginFormModule,
    UIButtonModule,
    UIDialogWrapperModule,
    UIFormModule,
    MatCheckboxModule,
    MatTooltipModule,
  ],
  declarations: [
    LoginPageComponent,
    LicenseAgreementDialogComponent,
    LicenseContentComponent,
    MfaCodeDialogComponent,
    MfaReminderDialogComponent,
  ],
  exports: [LoginPageComponent, LicenseAgreementDialogComponent],
})
export class LoginModule {}
