import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TemplateBinding } from '@angular/compiler';
import { UIButtonModule } from './../../ui-button/ui-button.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BdcWalkModule } from 'bdc-walkthrough';
import { NameTransformerPipe } from './name-transformer.pipe';
import { Folder } from '../../ui-folder';
import { DataAction } from '../../ui-data-presentation';

@Component({
  selector: 'vdms-hq-ui-floating-controls',
  templateUrl: './floating-controls.component.html',
  styleUrls: ['./floating-controls.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [UIButtonModule, MatTooltipModule, CommonModule, TranslateModule, BdcWalkModule, NameTransformerPipe],
})
export class FloatingControlsComponent {
  @Input() viewConfigurationActions: DataAction<Folder | TemplateBinding>[] | undefined;
  @Input() item!: TemplateBinding | Folder;
  @Output() action = new EventEmitter<{ key: string; item: any }>();

  emitAction($event: Event, key: string, item: any) {
    $event.stopPropagation();
    $event.preventDefault();
    this.action.emit({ key, item });
  }
}
