import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { messageAdd, MessageType } from '../actions/message.action';
import * as toast from '../../toast/public-api';

@Injectable()
export class MessageEffect {
  addMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof messageAdd>>(messageAdd.type),
      map((message) => {
        if (message.messageType === MessageType.error) {
          return toast.error({
            message: message.message,
            translationContext: message.context,
          });
        }
        if (message.messageType === MessageType.success) {
          return toast.success({
            message: message.message,
            translationContext: message.context,
          });
        }
        return toast.info({
          message: message.message,
          translationContext: message.context,
        });
      }),
    ),
  );

  constructor(private actions$: Actions) {}
}
