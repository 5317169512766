import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';

export enum Response {
  ok = 1,
  abort,
}

export interface ConfirmationOutput {
  data: {
    reason: string;
    billable: boolean;
  };
}

export interface ConfirmationInput {
  totalAssets: number;
}

@Component({
  selector: 'vdms-hq-assets-delete-confirmation-dialog',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, UIButtonModule, UIDialogWrapperModule, UIFormModule],
  templateUrl: './assets-delete-confirmation-dialog.component.html',
  styles: [],
})
export class AssetsDeleteConfirmationDialogComponent {
  form = new FormGroup({
    reason: new FormControl<string>('', { nonNullable: true }),
    billable: new FormControl<number>(1, { nonNullable: true }),
  });

  constructor(
    public dialogRef: MatDialogRef<AssetsDeleteConfirmationDialogComponent, ConfirmationOutput>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationInput,
  ) {}

  abort() {
    this.dialogRef.close();
  }

  ok() {
    if (this.form.invalid) {
      this.form.markAsTouched();
      return;
    }

    this.dialogRef.close({
      data: {
        reason: this.form.controls.reason.value,
        billable: this.form.controls.billable.value === 1,
      },
    });
  }
}
