<vdms-hq-ui-layout-page-wrapper
  [breadcrumbs]="[{ name: 'common.reindex_automatization.title' }]"
  [loading]="dataSource.loading$ | async"
  gapSize="medium"
>
  <div class="filters">
    <vdms-hq-ui-form-radio-list
      orientation="horizontal"
      [selectOptions]="sortByOptions"
      [formControl]="sortByControl"
    ></vdms-hq-ui-form-radio-list>
    <vdms-hq-ui-form-input-select
      [selectOptions]="sortDirOptions"
      [formControl]="sortDirControl"
    ></vdms-hq-ui-form-input-select>
  </div>
  <vdms-hq-ui-multiple-data-presentation [dataSource]="dataSource" [configuration]="configuration">
  </vdms-hq-ui-multiple-data-presentation>
</vdms-hq-ui-layout-page-wrapper>
<vdms-hq-ui-actions-footer *ngIf="(dataSource.selection.identifiers$ | async)?.length">
  <vdms-hq-ui-button right (click)="reindex(true)" color="primary">
    {{ 'common.reindex_automatization.buttons.sync_index' | translate }}
  </vdms-hq-ui-button>
  <vdms-hq-ui-button right (click)="reindex(false)" color="secondary">
    {{ 'common.reindex_automatization.buttons.reindex' | translate }}
  </vdms-hq-ui-button>
</vdms-hq-ui-actions-footer>
