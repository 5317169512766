import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { ClientLanguage, ClientLanguagesEndpoints } from './client-languages';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { ApiResponse } from '../api.model';

@Injectable({ providedIn: 'root' })
export class ClientLanguagesService {
  constructor(private readonly apiService: ApiService) {}

  get(): Observable<ClientLanguage[]> {
    return this.apiService
      .get<ApiResponse<ClientLanguage[]>>(ClientLanguagesEndpoints.ROOT_PLURAL)
      .pipe(GetResponseData);
  }

  post(payload: ClientLanguage): Observable<ClientLanguage[]> {
    return this.apiService
      .post<ClientLanguage, ApiResponse<ClientLanguage[]>>(ClientLanguagesEndpoints.ROOT_PLURAL, payload)
      .pipe(GetResponseData);
  }

  patch(uuid: string, payload: ClientLanguage): Observable<ClientLanguage[]> {
    return this.apiService
      .patch<ClientLanguage, ApiResponse<ClientLanguage[]>>(`${ClientLanguagesEndpoints.ROOT_PLURAL}/${uuid}`, payload)
      .pipe(GetResponseData);
  }

  delete(uuid: string): Observable<void> {
    return this.apiService.delete(`${ClientLanguagesEndpoints.ROOT_PLURAL}/${uuid}`);
  }
}
