import { Component, inject, Input } from '@angular/core';
import { ThemeSwitcherService } from '@vdms-hq/theming';

@Component({
  selector: 'vdms-hq-ui-dialog-wrapper',
  templateUrl: './dialog-wrapper.component.html',
  styleUrls: ['./dialog-wrapper.component.scss'],
})
export class DialogWrapperComponent {
  public themeSwitcherService = inject(ThemeSwitcherService);
  @Input() size: 'default' | 'narrow' | 'wide' = 'default';
  @Input() loading = false;
  @Input() footerAlign: 'center' | 'flex-start' | 'flex-end' | 'space-between' = 'flex-end';
  @Input() withFooter = true;
  @Input() withGradient = true;
}
