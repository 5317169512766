import { Pipe, PipeTransform } from '@angular/core';
import { BitrateUnit, getPrefixFromBitrateUnit } from '../models/bitrate-unit.type';
import { convertBytes, convertToBytes } from './bytes-calculator';

@Pipe({
  name: 'formatBitrate',
})
export class FormatBitratePipe implements PipeTransform {
  transform = FormatBitratePipe.transformWithUnit;

  static transformWithUnit = (bitrateValue?: number | null, fallback: null | string = null) => {
    if (bitrateValue === undefined || bitrateValue === null) {
      return fallback;
    }

    const result = convertBytes(bitrateValue, 1, undefined, 1000);

    return `${result.value}${result.prefix}bps`;
  };

  static transform = (bitrateValue?: number | null, unit: BitrateUnit = 'Mbps') => {
    const bytesUnit = getPrefixFromBitrateUnit(unit);

    const result = convertBytes(bitrateValue, 1, bytesUnit, 1000);

    return result.value;
  };

  static transformToBytes = (value?: number | null, unit: BitrateUnit = 'Mbps') => {
    const bytesUnit = getPrefixFromBitrateUnit(unit);

    return convertToBytes(value, bytesUnit, 1, 1000);
  };
}
