import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowseRouterService } from './logic/services/browse-router.service';
import { LastFiltersService } from './logic/services/last-filters.service';
import { ReturnButtonService } from './logic/services/return-button.service';
import { AssetSearchService } from '@vdms-hq/api-contract';

@NgModule({
  imports: [CommonModule],
  providers: [
    BrowseRouterService,
    LastFiltersService,
    AssetSearchService,
    {
      provide: APP_INITIALIZER,
      useFactory: (routeParamListener: BrowseRouterService) => () => routeParamListener.registerListeners(),
      multi: true,
      deps: [BrowseRouterService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (lastFiltersService: LastFiltersService) => () => lastFiltersService.registerListeners(),
      multi: true,
      deps: [LastFiltersService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (returnButtonService: ReturnButtonService) => () => returnButtonService.registerListeners(),
      multi: true,
      deps: [ReturnButtonService],
    },
  ],
})
export class BrowseCoreModule {}
