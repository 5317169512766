export type CollectionUploadStatus =
  | 'updating'
  | 'collection_updated'
  | 'collection_error'
  | 'uploading'
  | 'upload_error'
  | 'done';

export enum CollectionUploadStatusEnum {
  UPLOADED = 'uploaded',
  SUCCESS = 'success',
  ERROR = 'error',
}
