<vdms-hq-ui-dialog-wrapper footerAlign="space-between">
  <ng-container title>
    {{ 'common.global.transcode_status.more' | translate }}
  </ng-container>

  <ng-container content>
    <mat-accordion>
      <mat-expansion-panel
        *ngFor="let destination of data.delivery_destinations; first as isFirst"
        [expanded]="isFirst"
        #destinationPanel
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="destination-title" [style.max-width]="!destinationPanel.expanded ? '150px' : 'unset'">
              {{ destination.name }}
            </p>
          </mat-panel-title>
          <mat-panel-description *ngIf="!destinationPanel.expanded">
            <div class="destination-description">
              <span>{{ destination.configs.length }} {{ destination.configs.length > 1 ? 'configs' : 'config' }}</span>
              <span>{{ destination.summary }}</span>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="destination-wrapper">
          <div class="config-header">
            <span>Transcode</span>
            <span>Publish</span>
            <span>Config</span>
          </div>
          <div class="destination-config" *ngFor="let config of destination.configs">
            <ng-container *ngIf="config.jobs.length === 0; else jobs">
              <div class="pill done">Download Ready</div>
              <div>N/A</div>
            </ng-container>
            <ng-template #jobs>
              <div *ngIf="config.jobs.length === 1 && config.jobs[0].type === 'publish'" class="pill done">
                Download Ready
              </div>
              <div *ngFor="let job of config.jobs" class="pill" [ngClass]="job.status">
                {{ job.status !== 'completed' ? job.status : job.type === 'transcode' ? 'Completed' : 'Published' }}
              </div>
              <div *ngIf="config.jobs.length === 1 && config.jobs[0].type === 'transcode'">N/A</div>
            </ng-template>
            <div class="config-name">
              {{ config.name }}
            </div>
            <div class="actions">
              <vdms-hq-ui-button
                *requiresPermissions="[Permission.RETRY_DELIVERY_DESTINATIONS]"
                [iconOnly]="true"
                [size]="'small'"
                [icon]="'refresh'"
                [loading]="loading$ | async"
                (click)="retryDeliveryDestination(config)"
                [disabled]="config | canRefreshDelivery : data?.items"
              ></vdms-hq-ui-button>
              <vdms-hq-ui-button
                [iconOnly]="true"
                [size]="'small'"
                [icon]="'download'"
                [disabled]="
                  !data.orderActive ||
                  data.isEmbargoActive ||
                  (config.jobs.length === 0 ? false : getTranscodeJobsStatus(config.jobs) !== 'completed')
                "
                (click)="download(config.uuid)"
              ></vdms-hq-ui-button>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>

  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="close()">{{ 'common.global.close' | translate }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
