import { Observable } from 'rxjs';
import { SortBy, SortDirection, SortEvent } from '../../models/sort/sort.model';

export interface SortableDataSource {
  sortDirection$: Observable<SortDirection>;
  sortBy$: Observable<SortBy>;

  sortChange($event: SortEvent): void;
}

export const isSortableDataSource = (obj: any): obj is SortableDataSource => {
  return 'sortBy$' in obj && 'sortDirection$' in obj;
};
