import { Injectable } from '@angular/core';
import { DestroyComponent } from '@vdms-hq/shared';
import { BehaviorSubject, EMPTY, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { ReleaseNotesDsService } from './release-notes-ds.service';
import { GithubEndpoints, GithubService, GITHUB_REPO } from '@vdms-hq/api-contract';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SingleReleaseNoteDsService extends DestroyComponent {
  releaseNo$ = this.releaseNotesDsService.currentReleaseNo$;
  isLoading$ = new BehaviorSubject<boolean>(true);
  public refresh$ = new BehaviorSubject(new Date());

  constructor(private releaseNotesDsService: ReleaseNotesDsService, private fetcher: GithubService) {
    super();
  }

  releaseNote$ = this.refresh$.pipe(
    withLatestFrom(this.releaseNo$, this.releaseNotesDsService.allReleases$),
    tap(() => this.isLoading$.next(true)),
    switchMap(([, releaseNo, releases]) => {
      if (!releaseNo) {
        this.isLoading$.next(false);
        return EMPTY;
      }

      const release = releases.find((r) => r.tag_name.split(' ')[0] === releaseNo);
      if (!release) {
        this.isLoading$.next(false);
        return EMPTY;
      }
      return this.fetcher
        .markdownToHTML({
          path: GithubEndpoints.RAW_MARKDOWN,
          method: 'POST',
          owner: this.releaseNotesDsService.owner,
          repo: this.releaseNotesDsService.repo,
          body: {
            text: release.body,
            mode: 'gfm',
            context: GITHUB_REPO,
          },
        })
        .pipe(
          map((markdown) => ({ ...release, body: markdown })),
          tap(() => this.isLoading$.next(false)),
          catchError(() => of(release.body)),
        );
    }),
  );
}
