export interface ClientLanguage {
  name: string;
  uuid: string;
  language_uuid: string;
  language_label: string;
  code: string;
  label: string;
  title_manager: boolean;
  video: boolean;
  audio: boolean;
  timed_text: boolean;
  image: boolean;
  archive: boolean;
  document: boolean;
  other: boolean;
  custom_languages_enabled: boolean;
}

export enum ClientLanguagesEndpoints {
  ROOT = 'languages',
  ROOT_PLURAL = 'languages/client',
  ALL = 'languages/all',
}
