import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CcSalesforceApiService } from '../rest/cc-salesforce/cc-salesforce.api.service';
import { mergeMap } from 'rxjs/operators';
import { ToolsEndpoints } from '../rest/tools/tools';

@Injectable()
export class CcSalesforceTokenInterceptor implements HttpInterceptor {
  constructor(private readonly ccSalesforceApiService: CcSalesforceApiService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      req.url.includes(`${ToolsEndpoints.ROOT}/${ToolsEndpoints.BRIDGE}`) &&
      req.body.url.includes('salesforce.com')
    ) {
      return this.ccSalesforceApiService.activeToken$.pipe(
        mergeMap(({ access_token, token_type }: { access_token: string; token_type: string }) => {
          const newReq = req.clone({
            body: {
              ...req.body,
              headers: {
                Authorization: `${token_type} ${access_token}`,
              },
            },
          });
          return next.handle(newReq);
        }),
      );
    }
    return next.handle(req.clone());
  }
}
