<vdms-hq-ui-layout-page-wrapper
  [breadcrumbs]="[{ name: 'common.notification_subscriptions.all_notifications' | translate }]"
  [loading]="!!(dataSource.isLoading$ | async)"
>
  <div class="actions" [formGroup]="form">
    <vdms-hq-ui-form-input-select
      formControlName="sortDir"
      [label]="'Sort'"
      [selectOptions]="sortDirSelectOptions"
    ></vdms-hq-ui-form-input-select>
    <vdms-hq-ui-form-input-select
      formControlName="types"
      [multiple]="true"
      [label]="'Event type'"
      [selectOptions]="(notificationTypes$ | async) ?? []"
    ></vdms-hq-ui-form-input-select>
    <vdms-hq-ui-form-radio-list
      formControlName="categories"
      orientation="horizontal"
      [selectOptions]="categoriesSelectOptions"
    ></vdms-hq-ui-form-radio-list>
  </div>
  <div class="content" *ngIf="dataSource$ | async; let notifications; else: loading">
    <ng-container *ngIf="notifications?.length; else empty">
      <div class="mark--all" *ngIf="notifications?.length">
        <span (click)="markAllNotificationsAsRead()">
          {{ 'common.notification_subscriptions.buttons.mark_all_as_read' | translate }}
        </span>
      </div>
      <vdms-hq-ui-infinity-loader
        (scrolled)="dataSource.loadMore()"
        [loadOnScroll]="false"
        [isLoading]="(dataSource.isLoading$ | async) ?? false"
        [total]="(dataSource.totalNotifications$ | async) ?? 0"
        [batchSize]="(dataSource.perPage$ | async) ?? 0"
        [loaded]="notifications?.length ?? 0"
      >
        <div *ngFor="let notification of notifications; trackBy: dataSource.trackBy">
          <div class="notification--upper__bar">
            <div class="notification--created_date">
              <div class="notification--unread__dot" *ngIf="!notification.isRead"></div>
              <ng-container *ngIf="notification.props.created_at | daysFrom; else today">
                {{ 'common.notification_subscriptions.notification_date' | translate }}:
                {{ notification.props.created_at | date : 'yyyy-MM-dd HH:mm' }}
              </ng-container>
            </div>
            <ng-container *ngIf="!notification.isRead">
              <span class="notification--mark__read" (click)="markAsRead(notification.props.uuid)">{{
                'common.notification_subscriptions.buttons.mark_as_read' | translate
              }}</span>
            </ng-container>
          </div>

          <vdms-hq-notification-message
            [notification]="notification"
            [detailedView]="true"
          ></vdms-hq-notification-message>
        </div>
      </vdms-hq-ui-infinity-loader>
    </ng-container>
  </div>
</vdms-hq-ui-layout-page-wrapper>

<ng-template #loading>
  <vdms-hq-ui-loader></vdms-hq-ui-loader>
</ng-template>

<ng-template #empty>
  <vdms-hq-ui-empty-results></vdms-hq-ui-empty-results>
</ng-template>

<ng-template #today>Today</ng-template>
