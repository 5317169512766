<mat-form-field
  appearance="outline"
  class="material-custom-field field-timecode"
  [class.custom-suffix]="!innerFormControl.pending"
  [class.hide-footer]="!withFooter"
  [class.with-touched-indicator]="withTouchedIndicator"
  [class.with-suffix-button]="buttonConfig?.visible"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    #timecodeInput
    matInput
    type="text"
    class="mat-form-field"
    [formControl]="innerFormControl"
    (blur)="propagateTouch()"
    autocomplete="off"
    [mask]="mask"
    [specialCharacters]="specialCharacters"
    [showMaskTyped]="true"
    [dropSpecialCharacters]="false"
    [required]="isRequired"
    (keyup)="keyUp($event)"
  />
  <mat-hint *ngIf="withFooter">{{
    hint ?? (innerFormControl.value && innerFormControl.valid && innerFormControl.valid ? hintFallback : '')
  }}</mat-hint>
  <mat-error *ngIf="withFooter">
    <span *ngFor="let error of errors">{{ error.transLang | translate : { param: error.contextParams } }}</span>
  </mat-error>
  <vdms-hq-ui-loader matSuffix [size]="20" *ngIf="innerFormControl.pending"></vdms-hq-ui-loader>
  <span
    matSuffix
    *ngIf="!innerFormControl.pending && !hideFramerate"
    class="fps-label"
    [class.uses-default]="!framerate"
    >{{ framerate?.shortLabel || defaultFramerate.shortLabel }}</span
  >
  <vdms-hq-ui-button
    matSuffix
    *ngIf="buttonConfig?.visible"
    (click)="buttonClick.emit()"
    [color]="buttonConfig?.color || 'secondary'"
    >{{ buttonConfig?.label }}</vdms-hq-ui-button
  >
</mat-form-field>
