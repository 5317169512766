export enum FilterType {
  MASTER_TEXT = 'master_text',
  TEXT = 'text',
  SELECTOR = 'selector',
  SELECTOR_GROUP = 'selector_group',
  TEXT_AUTOCOMPLETE = 'text_autocomplete',
  NUMBER = 'number',
  TIMECODE_RANGE = 'timecode_range',
  SIZE_RANGE = 'size_range',
  BITRATE_RANGE = 'bitrate_range',
  DATEPICKER_RANGE = 'datepicker_range',
  CHECKBOX_LIST = 'checkbox_list',
  CHIPS = 'chips',
  RADIO_LIST = 'radio_list',
  TOGGLE = 'toggle',
}
