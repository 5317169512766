import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BillingReportEndpoints, ReportType } from './billing-reports';
import { ApiService } from '../api.service';

@Injectable({ providedIn: 'root' })
export class BillingReportsService {
  constructor(private readonly apiService: ApiService) {}

  post(payload: ReportType): Observable<void> {
    return this.apiService.post(BillingReportEndpoints.ROOT_PLURAL, payload);
  }
}
