import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  castTo,
  ConnectableDataSource,
  isSelectableDataSource,
  LoadableDataSource,
  SelectableDataSource,
  ValueDisplayFormatConfig,
} from '@vdms-hq/shared';
import { CommonModule } from '@angular/common';
import { RuntimeErrorComponent } from '../components/runtime-error/runtime-error.component';
import { ConfigActions, ContextMenu, TranslatableName } from '../logic/common-config';
import { RouterLinkWithHref } from '@angular/router';
import { defaultTrackBy } from '../logic/common-functions';
import { ContextMenuComponent } from '../components/context-menu/context-menu.component';
import { ValueRendererComponent } from '../../ui-value-renderer';
import { FloatingControlsV2Component } from '../components/floating-controls-v2/floating-controls-v2.component';
import { IsRowTooltipPipe } from '../pipes/is-row-tooltip.pipe';
import { SelectOneComponent } from '../components/select-one/select-one.component';
import { TileImageComponent } from '../components/tile-image/tile-image.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { take } from 'rxjs';
import { ValueByPathPipe } from '../pipes/value-by-path.pipe';
import { DisableIfRowPipe } from '../pipes/disable-if-row.pipe';
import { RowViewConfig } from '../logic/models';

export type GridTilesDataSource<T> = ConnectableDataSource<T> &
  Partial<LoadableDataSource> &
  Partial<SelectableDataSource<T>>;

export type GridTilesConfig<T> = ContextMenu<T> &
  ConfigActions<T> & {
    rows?: RowViewConfig<T>;
    content?: Partial<{
      iconPath: string;
      titlePath: string;
      additionalInfo?: {
        label: TranslatableName;
        valuePath: string;
        viewFormat?: ValueDisplayFormatConfig;
      };
      metadata: {
        label: TranslatableName;
        viewFormat?: ValueDisplayFormatConfig;
        valuePath: string;
        fallback?: string;
        fullLine?: boolean;
        hiddenIf?: (item: T) => boolean;
      }[];
    }>;
    layout?: {
      columns?: 'up-to-6';
    };
    image?: Partial<{
      bgPath: string;
      bgHoverPath: string;
    }>;
  };

@Component({
  selector: 'vdms-hq-ui-grid-tiles',
  templateUrl: './grid-tiles.component.html',
  styleUrls: ['./grid-tiles.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RuntimeErrorComponent,
    RouterLinkWithHref,
    ContextMenuComponent,
    FloatingControlsV2Component,
    IsRowTooltipPipe,
    SelectOneComponent,
    TileImageComponent,
    TranslateModule,
    ValueRendererComponent,
    MatTableModule,
    MatTooltipModule,
    MatIconModule,
    ValueByPathPipe,
    DisableIfRowPipe,
  ],
})
export class GridTilesComponent<T> {
  @Input() dataSource?: GridTilesDataSource<T>;
  @Input() configuration?: GridTilesConfig<T>;
  @Output() action = new EventEmitter<{ key: string; item?: T }>();
  @ViewChild(ContextMenuComponent) contextMenu?: ContextMenuComponent<T>;

  defaultTrackBy = defaultTrackBy;
  isSelectable = isSelectableDataSource;
  $castToString = castTo<string>();

  isRowDisabled(item: T) {
    return Boolean(this.configuration?.rows?.disabledIf?.condition(item));
  }

  showContextMenu($event: MouseEvent, item: T) {
    if (this.isRowDisabled(item)) {
      return;
    }
    if (!this.configuration?.contextMenu?.actions || this.configuration?.contextMenu?.actions?.length === 0) {
      return;
    }
    if (!this.contextMenu) {
      return;
    }
    $event.preventDefault();
    this.contextMenu.open($event, item);
  }

  handleDoubleClick($event: MouseEvent, item: T) {
    if ($event?.target instanceof Element && $event.target.closest('mat-checkbox')) {
      $event.stopImmediatePropagation();
      return;
    }

    if (this.isRowDisabled(item)) {
      return;
    }

    const existingAction = (this.configuration?.actions ?? []).find((action) => action.onDoubleClick);
    if (!existingAction) {
      return;
    }

    return this.action.emit({
      key: existingAction.key,
      item,
    });
  }

  selectItem(item: T) {
    if (!this.isSelectable(this.dataSource)) {
      return;
    }

    this.dataSource.selection
      .isSelected$(item)
      .pipe(take(1))
      .subscribe((selected) => {
        if (selected) this.dataSource?.selection?.changeOne(item, false, false);
        else this.dataSource?.selection?.changeOne(item, true, false);
      });
  }
}
