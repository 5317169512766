import { Prefix } from '../pipes/bytes-calculator';

export type BitrateUnit = 'bps' | 'kbps' | 'Mbps' | 'Gbps';

export const getPrefixFromBitrateUnit = (unit: BitrateUnit): Prefix => {
  switch (unit) {
    case 'bps':
      return '';
    case 'kbps':
    case 'Mbps':
    case 'Gbps':
      return unit.slice(0, 1) as Prefix;
  }
};
