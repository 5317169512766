export enum websocketContext {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  PROGRESS = 'PROGRESS',
  INFO = 'INFO',
  ERROR = 'ERROR',
}

export type StorageMessages =
  | 'asset_not_found'
  | 'asset_in_active_order'
  | 'is_virtual'
  | 'is_not_uploaded'
  | 'is_quarantined'
  | 'dont_have_proxy_video_error'
  | 'dont_have_stream_audio'
  | 'dont_have_stream_audio_cold_to_hot'
  | 'dont_have_proxy_video_cold_to_hot'
  | 'dont_have_md5'
  | 'dont_have_waveform'
  | 'already_in_cold'
  | 'missing_in_aws'
  | 'missing_in_wasabi'
  | 'file_size_mismatch'
  | 'already_in_hot'
  | 'already_manipulating_to_hot';

export type WebsocketContext = 'CREATE' | 'UPDATE' | 'DELETE' | 'PROGRESS' | 'INFO' | 'ERROR';

export interface WebsocketNotificationInterface<T> {
  uuid: string;
  groupUuid: string; // only backend relevant
  stream: string; // only backend relevant
  context: WebsocketContext;
  data?: T & {
    action: WebsocketNotificationActionEnum;
    message: 'has_embargo' | 'already_in_cart' | 'already_in_collection' | 'error' | 'retry_dd_jobs' | StorageMessages;
  };
}

export type WebsocketCollectionNotificationMessage = {
  already_in_collection?: number;
} & WebsocketAnyNotificationMessage;
export type WebsocketCartNotificationMessage = { already_in_cart?: number } & WebsocketAnyNotificationMessage;
export type WebsocketStorageNotificationMessage = {
  [key in StorageMessages]?: number;
} & WebsocketAnyNotificationMessage;
export type WebsocketAnyNotificationMessage = { has_embargo: number; error: number };

export interface WebsocketProgressInterface {
  total: number;
  processed: number;
  not_processed: number;
}

export enum WebsocketNotificationActionEnum {
  COLLECTION_ADD = 'collection_add',
  COLLECTION_REMOVE = 'collection_remove',
  CART_ADD = 'cart_add',
  ASSET_BATCH_UPDATE = 'asset_batch_update',
  RETRY_DD_JOBS = 'retry_dd_jobs',
  LICENSED_PACKAGE_REMOVE = 'LICENSED_PACKAGE_REMOVE',
  SUBSCRIPTION_CREATE = 'NOTIFICATION_SUBSCRIPTION_BATCH_CREATE',
  SUBSCRIPTION_DELETE = 'NOTIFICATION_SUBSCRIPTION_BATCH_DELETE',
  SUBSCRIPTION_UPDATE = 'NOTIFICATION_SUBSCRIPTION_BATCH_UPDATE',
  MOVE_TO_HOT = 'move_to_hot',
  MOVE_TO_COLD = 'move_to_cold',
}

export type WebsocketEntitiesUpdateInterface<T> = WebsocketEntitiesUpdate | T;

export type WebsocketEntitiesUpdate = {
  entityType: 'Asset';
  uuids: string[];
};

export interface GetAssetsDetailsMessagePayload {
  uuids: string[];
  groupUuid: string;
}

export interface WsRequestMessage {
  action: 'request';
  method: 'getAssetsDetails';
  data: GetAssetsDetailsMessagePayload;
}
