import { Injectable } from '@angular/core';
import {
  ConnectableDataSource,
  FilterableDataSource,
  LoadableDataSource,
  PAGE_SIZE_OPTIONS,
  PageableDataSource,
  PageEvent,
  SelectableDataSource,
  Selection,
} from '@vdms-hq/shared';
import { BehaviorSubject, combineLatest, debounceTime, merge, Observable, Subject, switchMap, tap } from 'rxjs';
import { UserApiService, UserModel } from '@vdms-hq/api-contract';
import { map } from 'rxjs/operators';
import { AuthService } from '@vdms-hq/auth';
import { ParamsPagination } from '@vdms-hq/view-settings';

@Injectable({ providedIn: 'root' })
export class UsersDatasourceService
  extends ParamsPagination
  implements
    ConnectableDataSource<UserModel>,
    SelectableDataSource<UserModel>,
    LoadableDataSource,
    PageableDataSource,
    FilterableDataSource
{
  text$ = new BehaviorSubject<string>('');
  connection$!: Observable<UserModel[]>;
  emptyResults$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  selection!: Selection<UserModel>;

  allData$: Observable<UserModel[]> = combineLatest([this.pageIndex$, this.pageSize$, this.text$]).pipe(
    tap(() => this.isLoading$.next(true)),
    debounceTime(500),
    switchMap(([pageIndex, pageSize, text]) =>
      this.userApiService.getPaginatedUsers({ text, perPage: pageSize.toString(), page: pageIndex.toString() }),
    ),
    tap((response) => {
      this.isLoading$.next(false);
      !response && this.emptyResults$.next(true);
      this.total$.next(response?.total ?? 0);
    }),
    map((response) =>
      response.data.map((user) => ({
        ...user,
        groupsNames: (user.groups ?? []).map((group) => group.name),
      })),
    ),
  );

  total$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private readonly userApiService: UserApiService) {
    super();
    this.connection$ = this.allData$;
  }

  applyFilter(value: string, fields?: string[]) {
    if (value?.length < 3 && value?.length !== 0) {
      return;
    } else if (value?.length === 0) {
      this.text$.next('');
    } else {
      this.text$.next(value);
    }
    this.changePageIndex$.next(0);
  }
}
