export enum MessageState {
  error,
  success,
  info,
  processing,
}

export interface MessageModelCreate {
  id: string;
  message: string;
  state: MessageState;
  translationContext?: any;
  autoHide?: boolean;
}

export interface MessageModel {
  id: string;
  message: string;
  state: MessageState;
  translationContext?: any;
  autoHide: boolean;
  createdAt: Date;
  updatedAt: Date;
}
