import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UIFormModule } from '../../ui-form';
import { ConfigDragDropComponent } from '../../ui-config-drag-drop';
import { SelectOption } from '@vdms-hq/shared';

@Component({
  selector: 'vdms-hq-ui-library-schema-form',
  templateUrl: './schema-form.component.html',
  styleUrls: ['./schema-form.component.scss'],
  imports: [UIFormModule, ConfigDragDropComponent],
  standalone: true,
})
export class SchemaFormComponent {
  @Input() schemaForm!: FormGroup<{
    name: FormControl<string>;
    dragDrop: FormControl<{
      defaultList: string[];
      availableList: string[];
    }>;
  }>;
  @Input() all: SelectOption[] = [];
}
