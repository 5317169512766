import { Component, OnInit } from '@angular/core';
import {
  SelectedFilesService,
  UIButtonModule,
  UIDialogWrapperModule,
  UIFormModule,
  UILayoutModule,
  UINativeUploadModule,
} from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { BehaviorSubject, switchMap, tap } from 'rxjs';
import { FormControl } from '@angular/forms';
import { VideoCodecsApiService } from '@vdms-hq/api-contract';
import { DestroyComponent } from '@vdms-hq/shared';
import { MatDialogRef } from '@angular/material/dialog';
import { S3SignatureUploadService } from '@vdms-hq/storage';

@Component({
  selector: 'vdms-hq-video-codecs-import-csv-dialog',
  templateUrl: './video-codecs-import-csv-dialog.component.html',
  standalone: true,
  imports: [
    UIDialogWrapperModule,
    TranslateModule,
    UILayoutModule,
    AsyncPipe,
    UIButtonModule,
    UIFormModule,
    NgIf,
    UINativeUploadModule,
  ],
  providers: [],
})
export class VideoCodecsImportCsvDialogComponent extends DestroyComponent implements OnInit {
  files = new FormControl<File[]>([]);
  loading$ = new BehaviorSubject(false);

  constructor(
    private readonly api: VideoCodecsApiService,
    private readonly selectedFilesService: SelectedFilesService,
    private readonly ref: MatDialogRef<VideoCodecsImportCsvDialogComponent>,
    private readonly s3SignatureUploadService: S3SignatureUploadService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.#handleFilesUploading();
  }

  close(isSuccess = false) {
    this.selectedFilesService.clear();
    this.loading$.next(false);
    this.ref.close(isSuccess);
  }

  save() {
    const [file] = this.files.value || [];

    this.loading$.next(true);

    this.api
      .importCSV({ filename: file.name })
      .pipe(
        switchMap((signature) => this.s3SignatureUploadService.uploadSmallFileWithSignature(file, signature)),
        tap(() => this.selectedFilesService.clear()),
      )
      .subscribe({
        next: () => this.close(true),
        error: () => this.close(),
      });
  }

  #handleFilesUploading() {
    this.selectedFilesService.clear();
    this.selectedFilesService.selectedFiles$
      .pipe(this.takeUntilDestroyed())
      .subscribe((files) => this.files.setValue(files));
  }
}
