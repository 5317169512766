import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { AUTH_CONFIG, AuthConfig } from '../config-token';
import { ProviderType } from '../models/provider.type';
import { Router } from '@angular/router';
import { AuthStateService } from './auth-state.service';
import { InvalidPasswordLessParamsException } from '../exceptions/invalid-password-less-params.exception';
import { SignInResponse } from '../models/sign-in-response.model';
import * as Sentry from '@sentry/browser';

@Injectable()
export class AuthLoginService {
  loginFailedUrl = '';

  constructor(
    @Inject(AUTH_CONFIG) private env: AuthConfig,
    private auth: AngularFireAuth,
    private state: AuthStateService,
    private router: Router,
  ) {}

  logout = async (url?: string): Promise<void> => {
    await this.auth.signOut();
    await this.state.setFromFirebaseUser();
    Sentry.setUser(null);
    this.loginFailedUrl = url ?? '';

    await this.router.navigate([this.env?.routing?.forUnauthorized]);
  };

  loginPasswordLess = async (email: string) => {
    const { origin, pathname, search } = window.location;

    const actionCodeSettings = {
      url: `${origin}${pathname}${search}${search ? '&' : '?'}attemptVerifyEmail=true`,
      handleCodeInApp: true,
    };

    localStorage.setItem('emailForSignIn', email);
    await this.auth.sendSignInLinkToEmail(email, actionCodeSettings);
  };

  loginWithCustomToken = async (token: string) => {
    const response = await this.auth.signInWithCustomToken(token);

    await this.state.setFromFirebaseUser();
    await this.navigateToAuthorized();

    return {
      isNewUser: !!response?.additionalUserInfo?.isNewUser,
    };
  };

  async passwordLessVerification(): Promise<SignInResponse> {
    let email = localStorage.getItem('emailForSignIn');

    if (!email) {
      email = window.prompt('Please provide your email for confirmation');
    }

    if (!email) {
      throw new InvalidPasswordLessParamsException();
    }

    const response = await this.auth.signInWithEmailLink(email, window.location.href);

    localStorage.removeItem('emailForSignIn');
    Sentry.setUser({ email: email });

    await this.state.setFromFirebaseUser();
    await this.navigateToAuthorized();

    return {
      isNewUser: !!response?.additionalUserInfo?.isNewUser,
    };
  }

  loginWithProvider = async (type: ProviderType): Promise<SignInResponse> => {
    let response;

    switch (type) {
      case 'google':
        response = await this.loginWithGoogle();
        break;
      case 'microsoft':
        response = await this.loginWithMicrosoft();
        break;
      default:
        throw Error(`Unsupported provider name ${type}`);
    }

    await this.state.setFromFirebaseUser();
    await this.navigateToAuthorized();

    return {
      isNewUser: !!response?.additionalUserInfo?.isNewUser,
    };
  };

  private async loginWithMicrosoft() {
    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      prompt: 'consent',
      tenant: this.env?.microsoftTenantId,
    });

    return this.auth.signInWithPopup(provider);
  }

  private async loginWithGoogle() {
    return this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  public navigateToAuthorized = async () => {
    this.loginFailedUrl === ''
      ? this.router.navigate([this.env?.routing?.forAuthorized])
      : this.loginFailedUrl.includes('?')
      ? await this.router.navigateByUrl(this.loginFailedUrl)
      : await this.router.navigate([this.loginFailedUrl]);

    this.loginFailedUrl = '';
  };
}
