import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { StatsConfig, StatsType, UIConfirmationDialogService } from '../../../../index';
import { EMPTY, Observable } from 'rxjs';
import { LayoutService } from '@vdms-hq/theming';
import { sampleImage, sampleImageHover } from '../samples';

@Component({
  selector: 'vdms-hq-ui-misc',
  templateUrl: './misc.component.html',
  styleUrls: ['./misc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiscComponent implements OnInit {
  theme$: Observable<string> = EMPTY;

  constructor(private confirmationDialog: UIConfirmationDialogService, private layoutSwitcher: LayoutService) {}

  sampleImage = sampleImage;
  sampleImageHover = sampleImageHover;
  stats: StatsConfig[] = [
    new StatsConfig(StatsType.STATIC, 'Static', 'upload', {
      majorData: 100,
      description: 'Lorem ipsum',
      minorData: '2034 / 01 / 01',
    }),
    new StatsConfig(StatsType.STATIC, 'Static #2', 'upload', {
      majorData: 100000,
      description: 'Lorem ipsum',
      minorData: '2034 / 01 / 01',
    }),
    new StatsConfig(StatsType.CHART_PIE, 'Example stats view', 'whatshot', {
      labelsTextColor: '#A4A9B7',
      labels: ['One', 'Two', 'Three', 'Four'],
      chartDataSets: [
        {
          data: [10, 30, 15, 30],
          fill: true,
          borderColor: '#3573FF',
          pointBorderColor: '#3573FF',
          pointBackgroundColor: '#3573FF',
          tension: 0.4,
        },
      ],
      lineSettings: {
        gradientUnderLine: [
          { step: 0, color: 'rgba(53,115,255,0.50)' },
          { step: 1, color: 'rgba(21,22,25,0.25)' },
        ],
      },
    }),
  ];
  ngOnInit() {
    this.theme$ = this.layoutSwitcher.currentTheme();
  }

  openDialog() {
    this.confirmationDialog.open();
  }

  toggleTheme() {
    this.layoutSwitcher.toggleTheme();
  }
}
