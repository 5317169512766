import { Injectable } from '@angular/core';
import { RefreshService } from '@vdms-hq/shared';
import { DiscountsFiltersView, DiscountsService, GetDiscountCodeInterface } from '@vdms-hq/api-contract';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  EMPTY,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { MultipleViewDataSource, Pagination } from '@vdms-hq/ui';
import { ToastService } from '@vdms-hq/toast';
import { FormControl, FormGroup } from '@angular/forms';
import { DiscountsViewModel } from './discounts-view.model';
import { ParamsPagination } from '@vdms-hq/view-settings';

@Injectable({ providedIn: 'root' })
export class DiscountsDsService extends ParamsPagination implements MultipleViewDataSource<GetDiscountCodeInterface> {
  static readonly defaultPerPage = 192;
  static readonly defaultPage = 0;
  total$ = new BehaviorSubject(0);
  emptyResults$ = new BehaviorSubject(this.total$.value === 0);

  isLoading$ = new BehaviorSubject(true);
  refresh$ = this.refreshService.refresh$;

  filters = new FormGroup({
    keyword: new FormControl<string>(''),
    active: new FormControl<boolean | null>(null),
    sort: new FormControl<string | null>('createdAt_desc'),
  });

  values$: Observable<DiscountsFiltersView> = this.filters.valueChanges.pipe(
    startWith(this.filters.value),
    debounceTime(400),
    switchMap(() => {
      const formValue = this.filters.getRawValue();
      const filters = <DiscountsFiltersView>{};

      if (formValue?.keyword) {
        filters.keyword = formValue.keyword;
      }

      if (formValue.active !== null) {
        filters.active = formValue.active;
      }

      if (formValue?.sort) {
        filters.sort = formValue.sort.split('_')[0];
        filters.direction = formValue.sort.split('_')[1];
      }

      this.changePageIndex$.next(0);
      return of(filters);
    }),
  );

  allData$: Observable<GetDiscountCodeInterface[]> = combineLatest([
    this.values$,
    this.pageSize$,
    this.pageIndex$,
    this.refresh$,
  ]).pipe(
    tap(() => this.isLoading$.next(true)),
    switchMap(([filters, pageSize, pageIndex]) => {
      const headers = Pagination.create({
        page: pageIndex,
        perPage: pageSize,
        orderBy: filters.sort,
        orderDir: filters.direction,
      });

      return this.discountsApiService.getAll(headers, filters).pipe(catchError(() => this.#errorHandler()));
    }),
    tap((response) => {
      this.total$.next(response.total);
      this.emptyResults$.next(response.data.length === 0);
      return response.data.map((item) => DiscountsViewModel.fromResponse(item));
    }),
    map((response) => response.data),
    tap(() => this.isLoading$.next(false)),
    shareReplay(1),
  );

  connection$ = this.allData$;

  constructor(
    private discountsApiService: DiscountsService,
    private refreshService: RefreshService,
    private toastService: ToastService,
  ) {
    super();
  }

  refresh() {
    this.refreshService.refresh();
  }

  #errorHandler() {
    this.toastService.error({ id: 'discounts-list', message: 'pages.clients.discounts.get_error' });
    this.isLoading$.next(false);
    this.emptyResults$.next(true);
    return EMPTY;
  }
}
