import { Component } from '@angular/core';
import { ApiContractModule } from '@vdms-hq/api-contract';
import { ActivatedClientModule } from '@vdms-hq/activated-client';
import { CommonModule, DatePipe } from '@angular/common';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { TranslateModule } from '@ngx-translate/core';
import { FlatMediaPulseViewModel, MediaPulseOrdersDs } from '../../logic/media-pulse-orders-ds';
import { MediaPulseOrdersForm } from '../../logic/media-pulse-form';
import {
  DataPresentationHeaderComponent,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UIInfinityLoaderModule,
  UILayoutModule,
  UISimpleDetailsListModule,
} from '@vdms-hq/ui';
import { FormatBytesPipe } from '@vdms-hq/shared';
import { OrderMetadataTransformerService } from '../../../logic/order-metadata-transformer';
import { mediaPulseFilters } from '../../../logic/config';

@Component({
  selector: 'vdms-hq-media-pulse-orders',
  templateUrl: './media-pulse-orders.component.html',
  standalone: true,
  imports: [
    CommonModule,
    DynamicFiltersModule,
    TranslateModule,
    ActivatedClientModule,
    ApiContractModule,
    UIInfinityLoaderModule,
    UILayoutModule,
    UISimpleDetailsListModule,
    MultipleDataPresentationComponent,
    DataPresentationHeaderComponent,
  ],
  providers: [MediaPulseOrdersDs, MediaPulseOrdersForm, OrderMetadataTransformerService, DatePipe, FormatBytesPipe],
})
export class MediaPulseOrdersComponent {
  title = 'common.orders.media_pulse_title';
  filtersConfig: DynamicFilterInput[] = mediaPulseFilters;

  enabled: string[] = [
    'woNo',
    'woDesc',
    'accountRepDesc',
    'contact',
    'po',
    'phaseCode',
    'statusNo',
    'woEndDt',
    'dateChanged',
  ];

  isLoading$ = this.dataSource.isLoading$;

  viewConfiguration: MultipleViewConfiguration<FlatMediaPulseViewModel> = {
    tableAdvanced: {
      columnsEnabled: this.enabled,
      columns: [
        {
          id: 'woNo',
          label: 'Order No',
          valuePath: 'wo_no',
          sortable: true,
        },
        {
          id: 'woDesc',
          label: 'Title',
          valuePath: 'wo_desc',
          sortable: true,
        },
        {
          id: 'accountRepDesc',
          label: 'Account Manager',
          valuePath: 'account_rep_desc',
          sortable: true,
        },
        {
          id: 'contact',
          label: 'Client Contact',
          valuePath: 'contact',
          sortable: true,
        },
        {
          id: 'po',
          label: 'PO',
          valuePath: 'po',
          sortable: true,
        },
        {
          id: 'phaseCode',
          label: 'PHASE',
          valuePath: 'phase_code',
          sortable: true,
        },
        {
          id: 'woEndDt',
          label: 'Due Date',
          valuePath: 'wo_end_dt',
          viewFormat: {
            modifiers: {
              dateFormat: 'date-time',
            },
          },
          sortable: true,
        },
        {
          id: 'dateChanged',
          label: 'Modified Date',
          valuePath: 'date_changed',
          viewFormat: {
            modifiers: {
              dateFormat: 'date-time',
            },
          },
          sortable: true,
        },
        {
          id: 'statusNo',
          label: 'Status',
          valuePath: 'status_no',
          sortable: true,
        },
      ],
    },
  };

  constructor(public dataSource: MediaPulseOrdersDs, public filtersForm: MediaPulseOrdersForm) {}
}
