<div class="floating-controls">
  <span class="floating-controls-well">
    <ng-container *ngFor="let actionDef of viewConfigurationActions ?? []">
      <vdms-hq-ui-button
        [matTooltip]="actionDef.label | nameTransformer : item | translate"
        (click)="$event.preventDefault(); emitAction($event, actionDef.key, item)"
        [icon]="actionDef.icon"
        [iconOnly]="true"
        [disabled]="actionDef?.disabledIf(item) ?? false"
        [bdcWalkTriggerFor]="actionDef?.tourItem ?? defaultPopUp"
        [attr.data-tourguide-item]="actionDef?.tourItem?.name ?? ''"
        *ngIf="!actionDef?.hiddenIf(item)"
      ></vdms-hq-ui-button>
    </ng-container>
  </span>
</div>

<bdc-walk-popup #defaultPopUp>default popup workaround</bdc-walk-popup>
