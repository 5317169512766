import { Component, forwardRef, inject, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UIButtonModule, UIModule } from '@vdms-hq/ui';
import { SharedDragAndDropDirective } from '../../directives/drag-and-drop.directive';
import { TranslateModule } from '@ngx-translate/core';
import { ToastService } from '@vdms-hq/toast';

@Component({
  selector: 'vdms-hq-storage-shared-form-file',
  templateUrl: './form-file.component.html',
  styleUrls: ['./form-file.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormFileComponent),
    },
  ],
  imports: [UIModule, SharedDragAndDropDirective, TranslateModule, UIButtonModule],
  standalone: true,
})
export class FormFileComponent implements ControlValueAccessor, OnInit {
  @Input() multiple = false;
  @Input() type: 'image' | 'video' | 'pdf' | 'audio' | 'any' = 'any';
  @Input() dragLabel = 'pages.upload.drop_here';
  @Input() maxFileSize = 0;

  private files: FileList | File | null = null;
  private file: FileList | File | null = null;
  disabled!: boolean;

  toastService = inject(ToastService);

  propagateChange?: (value: FileList | File) => void;

  propagateTouch?: () => void;

  acceptMimes = '';

  ngOnInit() {
    this.#setTypesDepending();
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: ((value: FileList | File) => void) | undefined): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: (() => void) | undefined): void {
    this.propagateTouch = fn;
  }

  writeValue(obj: FileList | File | null): void {
    if (this.multiple) {
      this.files = obj;
    } else {
      this.file = obj;
    }
  }

  onFilesSelected(event: Event | FileList | File) {
    const files = event;
    if (!files) {
      return;
    }

    if (
      this.type !== 'any' &&
      event instanceof Event &&
      event.target &&
      event.target instanceof HTMLInputElement &&
      event.target.files &&
      event.target.files instanceof FileList
    ) {
      if (!event.target.files[0].type.includes(this.type)) {
        this.toastService.error({ id: `${this.type}-only`, message: `Only ${this.type} files are allowed` });
        return;
      }
      if (this.maxFileSize > 0 && event.target.files[0].size > this.maxFileSize) {
        this.toastService.error({
          id: `max-file-size`,
          message: `File size should be less than ${this.maxFileSize / 1048576} MB`,
        });
        return;
      }
    }

    if (this.type !== 'any' && event instanceof FileList && !event[0].type.includes(this.type)) {
      if (!event[0].type.includes(this.type)) {
        this.toastService.error({ id: `${this.type}-only`, message: `Only ${this.type} files are allowed` });
        return;
      }
      if (this.maxFileSize > 0 && event[0].size > this.maxFileSize) {
        this.toastService.error({
          id: `max-file-size`,
          message: `File size should be less than ${this.maxFileSize / 1048576} MB`,
        });
        return;
      }
    }

    if (!this.multiple && (files as FileList).length > 1) {
      return;
    }

    if (this.multiple) {
      this.files = files as FileList;
      this.propagateChange?.(this.files);
    } else {
      this.file = ((files as Event).target as HTMLInputElement)?.files?.item(0) || (files as FileList).item(0);
      if (this.file) {
        this.propagateChange?.(this.file);
      }
    }
  }

  #setTypesDepending() {
    const allFileExtensions = ['audio/*', 'image/*', 'video/*', '.pdf', '.doc', '.docx', '.xls', '.xlsx'];

    switch (this.type) {
      case 'video':
        this.acceptMimes = 'video/*';
        break;
      case 'image':
        this.acceptMimes = 'image/*';
        break;
      case 'audio':
        this.acceptMimes = 'audio/*';
        break;
      case 'pdf':
        this.acceptMimes = '.pdf';
        break;
      default:
        this.acceptMimes = allFileExtensions.join(',');
        break;
    }
  }
}
