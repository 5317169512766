<h2>{{ 'common.generate_api_token.heading' | translate }}</h2>
<ng-container *ngIf="message$ | async; let message">
  <vdms-hq-ui-info-bar
    [type]="(type$ | async) ?? InfoBarType.WARNING"
    [message]="message"
    [disableClose]="true"
  ></vdms-hq-ui-info-bar>
</ng-container>
<div class="action">
  <vdms-hq-ui-button color="secondary" (click)="generateToken()">{{
    'common.generate_api_token.generate_button' | translate
  }}</vdms-hq-ui-button>
</div>
