import { Injectable } from '@angular/core';
import { ClipboardCopy } from '@vdms-hq/shared';
import { ResultsActions } from '../results-actions.model';
import { ActionIdentifier } from '@vdms-hq/ui';
import { Observable, of, take } from 'rxjs';
import { Params } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ContextMenuActionsService {
  constructor(private clipboardCopy: ClipboardCopy) {}

  handleAction(
    $event: { key: ResultsActions | ActionIdentifier | string; item?: any },
    params: Observable<Params> = of({}),
  ) {
    params.pipe(take(1)).subscribe((params) => {
      if (!$event?.item?.props?.uuid) {
        console.log('[ ERROR ] props.uuid doesnt exist');
        return;
      }

      let url = `/asset/${$event.item.props.uuid}/details?`;
      const queryParams = new URLSearchParams(params).toString();
      queryParams && (url += queryParams);

      switch ($event.key as string) {
        case ResultsActions.TAB:
          window.open(url, '_blank');
          return;
        case ResultsActions.WINDOW:
          window.open(url, 'AssetPreview', 'width=1300,height=700');
          return;
        case ResultsActions.UUID:
          this.clipboardCopy.copyToClipboard($event.item.props.uuid, 'uuid');
          return;
      }
    });
    return;
  }
}
