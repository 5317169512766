import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypingLettersDirective } from './directives/typing-letters.directive';

const exportedComponents = [TypingLettersDirective];

@NgModule({
  declarations: exportedComponents,
  providers: [],
  imports: [CommonModule],
  exports: exportedComponents,
})
export class UITypingLettersModule {}
