import {
  AfterViewInit,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { DataColumn, MultipleViewConfiguration, UIConfirmationDialogService } from '@vdms-hq/ui';
import { AudioTrackDataSource } from '../../logic/services/audio-track-data-source';
import { audioTrackTableEnabledColumns } from '../../logic/audio-track-table.config';
import { AudioTrack } from '@vdms-hq/api-contract';
import { AudioTrackFormService } from '../../logic/services/audio-track-form.service';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Portal, TemplatePortal } from '@angular/cdk/portal';
import { AudioTrackValidator } from '../../logic/audio-track.validator';
import { SelectionIdentifier, SelectionVisibility } from '@vdms-hq/shared';
import { DataProviderService, SelectorSourceType } from '@vdms-hq/selectors';

@Component({
  selector: 'vdms-hq-audio-tracks-table',
  templateUrl: './audio-tracks-table.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AudioTracksTableComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => AudioTracksTableComponent),
    },
  ],
})
export class AudioTracksTableComponent
  implements ControlValueAccessor, AfterViewInit, OnChanges, OnInit, OnDestroy, Validator
{
  protected readonly selectorType = SelectorSourceType;
  private destroyed$ = new Subject<void>();

  @Input() readonly = false;
  @Input() trackChannelsValue = 0;
  @ViewChild('templatePortalContent') templatePortalContent!: TemplateRef<unknown>;

  configuration: MultipleViewConfiguration<AudioTrack> = {
    multiView: {
      defaultView: 'tableAdvanced',
      fitToVisibleArea: false,
      emptyResults: {
        message: 'Could not find any entities',
      },
    },
    tableAdvanced: {
      footer: {},
      actions: [],
      columns: [
        {
          id: 'select',
          type: 'select',
        },
        {
          id: 'track',
          label: 'Track',
          valuePath: 'track',
        },
        {
          id: 'layout',
          label: 'Layout',
          valuePath: 'layout',
        },
        {
          id: 'channels',
          label: 'Channel layout',
          valuePath: 'channels',
          viewFormat: {
            maxVisibleValues: 1000,
          },
        },
        {
          id: 'language',
          label: 'Language',
          valuePath: 'language',
        },
        {
          id: 'class',
          label: 'Class',
          valuePath: 'class',
        },
        {
          id: 'codec',
          label: 'Codec',
          valuePath: 'codec',
        },
        {
          id: 'loudness',
          label: 'Loudness',
          valuePath: 'loudness',
        },
        {
          id: 'sample_rate',
          label: 'Sample Rate',
          valuePath: 'sample_rate',
        },
        {
          id: 'bit_depth',
          label: 'Bit Depth',
          valuePath: 'bit_depth',
        },
        {
          id: 'qc_status',
          label: 'QC Status',
          valuePath: 'qc_status',
        },
        {
          id: 'actions',
          type: 'actions',
        },
      ],
      columnsEnabled: audioTrackTableEnabledColumns,
    },
  };

  modifyForm = new UntypedFormGroup({});
  isRequired = false;
  disabled?: boolean;

  channelsSelectOptions$ = combineLatest([
    this.provider.listForSelectors(this.selectorType.CHANNEL, 'forms'),
    this.modifyForm.valueChanges.pipe(startWith(this.modifyForm.value)),
  ]).pipe(
    map(([channels, formValue]) => {
      const layout = Number(
        formValue.layout
          ?.split('.')
          ?.map(Number)
          ?.reduce((a: number, b: number) => a + b, 0),
      );

      if (isNaN(layout)) {
        return channels;
      }

      return channels.filter((item) => {
        const layoutItem = item.key?.toString().replace(/\s/g, '');
        const layoutItemArray = item.key?.toString().split(',');

        if (!layoutItem?.length) {
          return [];
        }

        if (!layoutItemArray?.length) {
          return layoutItem;
        }

        return layoutItem.split(',').length === layout;
      });
    }),
  );

  constructor(
    public audioTrackFormService: AudioTrackFormService,
    public audioTrackDataSource: AudioTrackDataSource,
    private _viewContainerRef: ViewContainerRef,
    private confirmationDialog: UIConfirmationDialogService,
    private provider: DataProviderService,
  ) {}

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  propagateChange: (_: AudioTrack[]) => void = () => undefined;

  propagateTouch: () => void = () => undefined;

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouch = fn;
  }

  writeValue(value: AudioTrack[] | null | undefined): void {
    this.audioTrackFormService.value$
      .pipe(take(1))
      .subscribe((audioTracks) => this.audioTrackFormService.buildTracks(audioTracks?.length ? audioTracks : value));
  }

  validate(): ValidationErrors | null {
    return this.audioTrackFormService.getErrors();
  }

  ngAfterViewInit(): void {
    this.audioTrackFormService.audioTracks.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((values) => {
      this.propagateChange(values as AudioTrack[]);
    });
    this.#createPortals();
    this.#prepareForm();
    this.#updateValues();
    this.#clearAfterSelection();
  }

  ngOnInit() {
    this.audioTrackFormService.resetForm();
  }

  ngOnChanges(changes: { readonly?: SimpleChange }): void {
    if (changes.readonly && this.configuration.tableAdvanced) {
      this.#resetSelectedIdentifiersValue();
      this.readonly = changes.readonly.currentValue;

      this.configuration.tableAdvanced.actions = this.readonly
        ? []
        : [
            {
              key: 'delete',
              icon: 'delete',
              onDoubleClick: false,
              label: 'common.global.delete',
            },
          ];
      this.audioTrackDataSource?.selection?.changeVisibility(
        changes.readonly.currentValue ? SelectionVisibility.DISABLED : SelectionVisibility.ENABLED,
      );
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.#detachPortals();
  }

  addTrack() {
    this.audioTrackDataSource?.selection?.clear();
    this.audioTrackFormService.addTrack();
  }

  async handleAction($event: { key: string; item?: AudioTrack }) {
    switch ($event.key) {
      case 'delete':
        if ($event.item) {
          this.confirmationDialog
            .openDelete()
            .pipe(take(1), filter(Boolean))
            .subscribe(() => {
              if ($event.item) {
                this.audioTrackFormService.removeTrack($event.item);
              }
            });
        }
        break;
    }
  }

  #clearAfterSelection() {
    this.audioTrackDataSource.selection.identifiers$
      .pipe(
        takeUntil(this.destroyed$),
        startWith([]),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      )
      .subscribe(() =>
        Object.values(this.modifyForm.controls).forEach((control) => control.setValue(null, { emitEvent: false })),
      );
  }

  #updateValues() {
    this.configuration.tableAdvanced?.columns.forEach((column) => {
      const formControl = this.modifyForm.get(column.id);

      if (!formControl) {
        return;
      }

      // todo shouldn't we unsubscribe previous subscription?
      formControl.valueChanges
        .pipe(
          takeUntil(this.destroyed$),
          map((formValue) =>
            column?.id === 'channels' ? formValue.split(',').map((channel: string) => channel.trim()) : formValue,
          ),
          withLatestFrom(this.audioTrackDataSource.selection.identifiers$),
        )
        .subscribe(([formValue, selected]) => {
          selected.forEach((index: SelectionIdentifier) => {
            this.audioTrackFormService.updateTrack(Number(index) - 1, column.id, formValue);
          });
        });
    });
  }

  #createPortals(): void {
    if (!this.configuration.tableAdvanced) {
      return;
    }

    this.#detachPortals();
    const portals: Record<string, Portal<unknown>> = {};
    for (const col of this.configuration.tableAdvanced?.columns ?? []) {
      portals[col.id] = new TemplatePortal(this.templatePortalContent, this._viewContainerRef, {
        column: col as DataColumn,
      });
    }

    this.configuration.tableAdvanced.footer = portals;
  }

  #detachPortals(): void {
    const portals = Object.values(this.configuration.tableAdvanced?.footer ?? {});
    for (const portal of portals) {
      if (portal.isAttached) {
        portal.detach();
      }
    }
  }

  #setCustomValidator(): void {
    this.audioTrackFormService.form.setValidators(AudioTrackValidator.audioCountMatch(this.trackChannelsValue));
  }

  #resetSelectedIdentifiersValue() {
    this.audioTrackDataSource?.selection.clear();
  }

  #prepareForm(): void {
    this.configuration.tableAdvanced?.columns.forEach((column) => {
      this.modifyForm.setControl(column.id, new UntypedFormControl());
    });
  }
}
