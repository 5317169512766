import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, map, Observable, shareReplay, take, tap } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeliveryPackApiService, EditMandatoryFieldsType, FieldsOptionsService } from '@vdms-hq/api-contract';
import { OptionType, RefreshService, SelectOption } from '@vdms-hq/shared';
import { uniqBy } from 'lodash';

type FieldObjects = { [x: string]: boolean }[] | undefined;

@Component({
  selector: 'vdms-hq-edit-dialog',
  standalone: true,
  imports: [CommonModule, UIDialogWrapperModule, TranslateModule, UILayoutModule, UIFormModule, UIButtonModule],
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss'],
})
export class EditDialogComponent {
  form = new FormGroup({
    content_type: new FormControl<string | null>('', [Validators.required]),
    fields: new FormArray<FormControl>([]),
  });

  loading$ = new BehaviorSubject(false);
  contentTypes$: Observable<SelectOption[]> = this.fieldsOptionsService.getTypeByName(OptionType.CONTENT_TYPE).pipe(
    take(1),
    map(({ fields }) => fields.map((f) => ({ key: f.key, label: f.label }))),
  );

  allPossibleFieldsSelectOptions$: Observable<SelectOption[]> = this.fieldsOptionsService
    .getAllFieldTypesSettings()
    .pipe(
      take(1),
      map((items) =>
        items.map((item) => ({
          key: item,
          label: item.includes('.') ? item.split('.')[1] : item,
        })),
      ),
      map((items) => uniqBy(items, 'key')),
      shareReplay(1),
    );

  tmpPatchValues: string[] = [];

  constructor(
    private ref: MatDialogRef<EditDialogComponent>,
    private fieldsOptionsService: FieldsOptionsService,
    private deliveryPackApiService: DeliveryPackApiService,
    private refreshService: RefreshService,
    @Inject(MAT_DIALOG_DATA) public data: { contentType: string },
  ) {
    if (data) {
      this.#patchForm(data.contentType);
    }
  }

  get contentType() {
    return this.form.controls.content_type;
  }

  get formArray() {
    return this.form.controls.fields;
  }

  createNewFormArrayElement(value = '') {
    return new FormControl<string | null>(value, [Validators.required]);
  }

  removeField(index: number) {
    this.formArray.removeAt(index);
  }

  addField(value = '') {
    this.formArray.push(this.createNewFormArrayElement(value));
  }

  save() {
    if (this.form.invalid) {
      return;
    }

    this.loading$.next(true);

    const fields: FieldObjects = this.form.value.fields?.map((value) => ({ [value as string]: true }));
    const removedFields: FieldObjects = this.tmpPatchValues
      ?.filter((item) => this.form.value.fields?.indexOf(item) === -1)
      .map((value) => ({ [value as string]: false }));

    const data: EditMandatoryFieldsType = {
      content_type: this.form.value.content_type ?? '',
      fields:
        fields?.concat(removedFields)?.reduce((acc, curr) => Object.assign(acc, curr), {}) ??
        ({} as EditMandatoryFieldsType['fields']),
    };

    this.deliveryPackApiService
      .updateMandatoryFields(data)
      .pipe(
        take(1),
        tap(() => this.loading$.next(false)),
      )
      .subscribe({
        next: () => {
          this.close(true);
          this.refreshService.refresh();
        },
      });
  }

  close(success?: boolean) {
    this.ref.close(success);
  }

  #patchForm(contentType: string) {
    this.loading$.next(true);
    this.deliveryPackApiService
      .getMandatoryField(contentType)
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          this.contentType.patchValue(Object.keys(data)?.[0]);

          const fields = Object.values(data)?.[0];
          fields.forEach((value) => {
            this.addField(value);
          });
          this.tmpPatchValues = fields;

          this.loading$.next(false);
        },
        error: (err) => {
          this.loading$.next(false);
          console.error(err);
        },
      });
  }
}
