import { Routes } from '@angular/router';
import { RightsPartnersResultsComponent } from './pages/rights-partners-results/rights-partners-results.component';

export enum RIGHTS_PARTNERS_ROUTER_BASE {
  ROOT = 'rights-partners',
}

export const rightsPartnersRouting: Routes = [
  {
    path: '',
    component: RightsPartnersResultsComponent,
  },
  {
    path: '**',
    redirectTo: `/${RIGHTS_PARTNERS_ROUTER_BASE.ROOT}`,
    pathMatch: 'full',
  },
];
