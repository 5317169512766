import { AsperaNotifier } from '../../../../aspera-notifier.service';
declare const AW4: any;
type ConnectInstaller = any;

export const statusEventUploadListener =
  (asperaNotifier: AsperaNotifier, asperaInstaller: ConnectInstaller) => (eventType: unknown, data: unknown) => {
    if (eventType === AW4.Connect.EVENT.STATUS && data === AW4.Connect.STATUS.INITIALIZING) {
      asperaInstaller.showLaunching();
    } else if (eventType === AW4.Connect.EVENT.STATUS && data === AW4.Connect.STATUS.FAILED) {
      asperaInstaller.showDownload();
    } else if (eventType === AW4.Connect.EVENT.STATUS && data === AW4.Connect.STATUS.OUTDATED) {
      asperaInstaller.showUpdate();
    } else if (eventType === AW4.Connect.EVENT.STATUS && data === AW4.Connect.STATUS.RUNNING) {
      asperaInstaller.connected();
    } else if (
      eventType === AW4.Connect.EVENT.STATUS &&
      (data === AW4.Connect.STATUS.EXTENSION_INSTALL || data === AW4.Connect.STATUS.FAILED)
    ) {
      asperaNotifier.notDetected();
    }
  };

export const statusEventDownloadListener = (asperaNotifier: AsperaNotifier) => (eventType: unknown, data: unknown) => {
  if (
    eventType === AW4.Connect.EVENT.STATUS &&
    (data === AW4.Connect.STATUS.EXTENSION_INSTALL || data === AW4.Connect.STATUS.FAILED)
  ) {
    asperaNotifier.notDetected();
  }
};
