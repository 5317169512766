import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormSectionComponent, UIButtonModule, UIDialogWrapperModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { emailsArrayValidator, enumToSelectOptions } from '@vdms-hq/shared';
import { DestinationStatus } from '@vdms-hq/api-contract';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DestinationFormService } from '../../logic/destination-form.service';

@Component({
  selector: 'vdms-hq-edit-destination-dialog',
  templateUrl: './edit-destination-dialog.component.html',
  imports: [
    CommonModule,
    MatDialogModule,
    UIDialogWrapperModule,
    FormSectionComponent,
    UIFormModule,
    UIButtonModule,
    TranslateModule,
    UILayoutModule,
  ],
  standalone: true,
})
export class DestinationEditDialogComponent {
  statusOptions = enumToSelectOptions(DestinationStatus, 'common.delivery_destinations.status.', true);

  constructor(
    public dialogRef: MatDialogRef<DestinationEditDialogComponent>,
    public formService: DestinationFormService,
  ) {}

  form = new FormGroup({
    name: new FormControl<string>(this.formService.form.controls.name.value, {
      validators: [Validators.required],
    }),
    status: new FormControl<DestinationStatus>(this.formService.form.controls.status.value, {
      validators: [Validators.required],
    }),
    type: new FormControl<string | null>(this.formService.form.controls.type.value),
    method: new FormControl<string | null>({ disabled: true, value: this.formService.form.controls.method.value }),
    emailDelivery: new FormControl<string[]>(this.formService.form.controls.email.controls.delivery.value, {
      validators: [Validators.required, emailsArrayValidator(true)],
      nonNullable: true,
    }),
    deliveryEmails: new FormControl<string[]>(this.formService.form.controls.email.controls.notification.value, {
      validators: [emailsArrayValidator(true)],
      nonNullable: true,
    }),
  });

  close() {
    this.dialogRef.close();
  }

  save() {
    if (this.form.valid) {
      const value = {
        name: this.form.controls.name.value,
        emailDelivery: this.form.controls.emailDelivery.value?.join(','),
        deliveryEmails: this.form.controls.deliveryEmails.value?.join(','),
        type: this.form.controls.type.value,
        method: this.form.controls.method.value,
        status: this.form.controls.status.value,
      };

      this.dialogRef.close({ success: true, value });
    }
  }
}
