import { Injectable } from '@angular/core';
import { LoadableDataSource } from '@vdms-hq/shared';
import { BehaviorSubject, combineLatest, map, shareReplay, switchMap, take } from 'rxjs';
import { ClientsService, SupplierService } from '@vdms-hq/api-contract';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { AsperaKeysViewModel } from './aspera-keys-view.model';

@Injectable()
export class AsperaKeysDs implements Partial<LoadableDataSource> {
  refresh$ = new BehaviorSubject<number>(Date.now());
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  emptyResults$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  suppliers$ = this.suppliersService
    .getPaginatedSuppliers({ text: '', perPage: '999', page: '0' })
    .pipe(take(1), shareReplay(1));

  allData$ = combineLatest([this.refresh$, this.activatedClientService.clientId$, this.suppliers$]).pipe(
    switchMap(([, clientId, suppliers]) => {
      return this.clientsService.getSupplierAsperaKeys(clientId as string).pipe(
        map((resp) => {
          let supplierName = '';

          if (!resp.length) {
            this.emptyResults$.next(true);
          }

          this.isLoading$.next(false);

          return resp.map((item) => {
            supplierName = suppliers?.data?.filter((supplier) => supplier.uuid === item.supplier_uuid)?.[0]?.name;
            return AsperaKeysViewModel.fromResponse(item, { supplierName });
          });
        }),
      );
    }),
  );

  connection$ = this.allData$;

  constructor(
    private clientsService: ClientsService,
    private activatedClientService: ActivatedClientService,
    private suppliersService: SupplierService,
  ) {}

  refresh() {
    this.refresh$.next(Date.now());
  }
}
