import { InjectionToken } from '@angular/core';

export interface ApiConfig {
  apiUrl: string;
  production: boolean;
  releaseNotes?: {
    dark: string;
    light: string;
  };
  dolbyRaspApiUrl?: string;
  websocketUrl?: string;
  interceptors: {
    bearer: boolean;
    client: boolean;
    errorHandling: boolean;
    cloudfrontCookie:
      | boolean
      | {
          notRequiredForPrefixes: string[];
        };
    ccSalesforceToken: boolean;
    cacheControl: boolean;
  };
  cookieDomain?: string;
  errorsRouting?: {
    notFound?: string;
  };
}

export const API_CONFIG: InjectionToken<ApiConfig> = new InjectionToken('API_CONFIG');
