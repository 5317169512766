import { Component, Input } from '@angular/core';
import { PreviewErrorModel } from '../../logic/preview-error.model';

@Component({
  selector: 'vdms-hq-ui-preview-outer',
  templateUrl: './preview-outer.component.html',
  styleUrls: ['./preview-outer.component.scss'],
})
export class PreviewOuterComponent {
  @Input() overflowHidden = false;
  @Input() limitHeight = true;
  @Input() limitWidth = false;
  @Input() loading = false;
  @Input() error: PreviewErrorModel | null = null;
}
