import { Routes } from '@angular/router';
import { DiscountsListComponent } from './pages/discounts-list/discounts-list.component';

export enum DISCOUNTS_ROUTER_BASE {
  ROOT = 'discounts',
}

export const DISCOUNTS_ROUTING: Routes = [
  {
    path: '',
    component: DiscountsListComponent,
  },
  {
    path: '**',
    redirectTo: `/${DISCOUNTS_ROUTER_BASE.ROOT}`,
    pathMatch: 'full',
  },
];
