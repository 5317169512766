import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EMPTY, forkJoin, map, Observable, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { AuthMfaService, AuthService } from '@vdms-hq/auth';
import { MfaReminderDialogComponent } from '../components/mfa-reminder-dialog/mfa-reminder-dialog.component';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MfaReminderDialogService {
  mfaReminderDate$ = this.auth.userAttributesDefinite$.pipe(
    take(1),
    map((attributes) => attributes?.mfaReminderDate),
  );

  mfaEnabled$: Observable<string | boolean> = this.mfa.mfaEnabled$.pipe(take(1));

  #dialogRef?: MatDialogRef<MfaReminderDialogComponent>;
  #closed$ = new Subject<void>();

  constructor(
    private readonly matDialog: MatDialog,
    private auth: AuthService,
    private mfa: AuthMfaService,
    private router: Router,
  ) {}

  get auth$() {
    return this.auth.auth$;
  }

  popMfaReminderDialog() {
    forkJoin([this.mfaReminderDate$, this.mfaEnabled$])
      .pipe(
        take(1),
        switchMap((response) => {
          const [mfaReminderDate, mfaEnabled] = response;
          if (mfaEnabled || mfaReminderDate === null) {
            return EMPTY;
          }
          if (!mfaReminderDate) {
            return this.#openReminderDialog();
          }
          const today = new Date();
          if (mfaReminderDate < today) {
            return this.#openReminderDialog();
          }
          return EMPTY;
        }),
      )
      .subscribe();
  }

  async #openReminderDialog() {
    this.#dialogRef = this.matDialog.open(MfaReminderDialogComponent, { disableClose: true });

    this.#dialogRef?.afterOpened().subscribe(() => {
      this.auth$
        .pipe(
          takeUntil(this.#closed$),
          filter((auth) => !auth),
        )
        .subscribe(() => {
          this.#dialogRef?.close();
        });
    });

    this.#dialogRef
      .afterClosed()
      .pipe(
        take(1),
        tap(() => this.#closed$.next()),
        switchMap((response) => {
          return this.auth
            .updateAttributes({
              mfaReminderDate: response.mfaReminderDate,
            })
            .pipe(map(() => response));
        }),
      )
      .subscribe((response) => {
        if (response.mfaReminderDate === null) {
          this.router.navigate(['/account-settings/profile']);
        }
      });
  }
}
