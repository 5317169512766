import { OrderDeliveryStatus, OrderDownloadStatus } from '@vdms-hq/api-contract';
import { PaginationProps } from '@vdms-hq/ui';

export const STATUS_OPTIONS = [
  {
    key: 'all',
    label: 'common.orders.approval.all',
  },
  {
    key: 'pending',
    label: 'common.orders.approval.pending',
  },
  {
    key: 'approved',
    label: 'common.orders.approval.approved',
  },
];
export const DOWNLOAD_STATUS = [
  {
    key: null,
    label: 'common.orders.download.all',
  },
  {
    key: OrderDownloadStatus.DOWNLOADED,
    label: 'common.orders.download.downloaded',
  },
  {
    key: OrderDownloadStatus.ACTIVE,
    label: 'common.orders.download.active',
  },
  {
    key: OrderDownloadStatus.EXPIRED,
    label: 'common.orders.download.expired',
  },
];

export const ORDER_TYPE = [
  {
    key: 'all',
    label: 'common.orders.order_type.all',
  },
  {
    key: 'shared',
    label: 'common.orders.order_type.shared',
  },
  {
    key: 'own',
    label: 'common.orders.order_type.own',
  },
];

export const DELIVERY_STATUS = [
  {
    key: null,
    label: 'common.orders.delivery.all',
  },
  {
    key: OrderDeliveryStatus.COLLECTING_ASSETS,
    label: 'common.orders.delivery.created',
  },
  {
    key: OrderDeliveryStatus.DELIVERY,
    label: 'common.orders.delivery.delivery',
  },
  {
    key: OrderDeliveryStatus.SUBMITTED_TO_SF,
    label: 'common.orders.delivery.submitted_to_getty_images',
  },
  {
    key: OrderDeliveryStatus.GETTYIMAGES_API_FAILED,
    label: 'common.orders.delivery.getty_images_failed',
  },
  {
    key: OrderDeliveryStatus.DELIVERED,
    label: 'common.orders.delivery.delivered',
  },
  {
    key: OrderDeliveryStatus.FAILED,
    label: 'common.orders.delivery.failed',
  },
];

export const SORT_BY = [
  {
    key: 'created_desc',
    label: 'common.sort.created_newest_first',
    props: <PaginationProps>{
      orderBy: 'order.createdAt',
      orderDir: 'desc',
    },
  },
  {
    key: 'created_asc',
    label: 'common.sort.created_latest_first',
    props: <PaginationProps>{
      orderBy: 'order.createdAt',
      orderDir: 'asc',
    },
  },
];
