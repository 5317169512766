export enum MetadataSnapshotEndpoints {
  ONE = 'metadata-snapshot',
  RUN = 'metadata-snapshot/run',
}

export interface MetadataSnapshotLabel {
  value: string;
  label: string;
}

export interface MetadataSnapshot {
  uuid?: string;
  delivery_type: MetadataSnapshotDeliveryType;
  columns: string[];
  labels: MetadataSnapshotLabel[];
  delivery_config?: {
    region?: string;
    bucketName?: string;
    prefix?: string;
    credentialUuid?: string;
    emails?: string[];
  };
  schedule_config: {
    deliveryDayMonth?: number;
    deliveryMonth?: number;
    deliveryDayWeek?: number;
  } | null;
  delivery_destination: string;
  storage_days: number;
  created_at?: string;
  updated_at?: string;
  last_export?: string;
}

export enum MetadataSnapshotDeliveryType {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  ANNUALLY = 'annually',
}

export enum MetadataSnapshotDeliveryDestination {
  EMAIL = 'email',
  S3 = 's3',
}
