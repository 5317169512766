import { Routes } from '@angular/router';
import { QualityControlPageComponent } from './pages/quality-control-page.component';

export enum QUALITY_CONTROL_ROUTES {
  ROOT = 'quality-control',
}

export const QUALITY_CONTROL_ROUTING: Routes = [
  {
    path: '',
    component: QualityControlPageComponent,
  },
  {
    path: '**',
    redirectTo: `/${QUALITY_CONTROL_ROUTES}`,
    pathMatch: 'full',
  },
];
