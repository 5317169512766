import { Observable } from 'rxjs';
import { ActivatedClientService } from './services/activated-client.service';
import { ActivatedClientForHostnameService } from './services/activated-client-for-hostname.service';
import { map } from 'rxjs/operators';

export function initialize(
  clientSelector: ActivatedClientService,
  clientForHostname: ActivatedClientForHostnameService,
): () => Observable<void> {
  return () => clientForHostname.client$.pipe(
    map((client) => clientSelector.initialize(client))
  );
}
