import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NameTransformerPipe } from '../../../ui-floating-controls/component/name-transformer.pipe';
import { Action } from '../../logic/common-config';
import { RowViewConfig } from '../../logic/models';
import { ActionStatePipe } from '../../pipes/action-state.pipe';
import { DisableIfRowPipe } from '../../pipes/disable-if-row.pipe';
import { RuntimeErrorComponent } from '../runtime-error/runtime-error.component';
import { UIButtonModule } from './../../../ui-button/ui-button.module';

@Component({
  selector: 'vdms-hq-ui-floating-controls-v2',
  templateUrl: './floating-controls-v2.component.html',
  styleUrls: ['./floating-controls-v2.component.scss'],
  standalone: true,
  imports: [
    UIButtonModule,
    MatTooltipModule,
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatRippleModule,
    ActionStatePipe,
    RuntimeErrorComponent,
    NameTransformerPipe,
    DisableIfRowPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatingControlsV2Component<T> {
  #actions: Action<T>[] = [];

  @Input() item?: T;

  @Input() set actions(actions: Action<T>[]) {
    this.#actions = actions.filter((action) => Object.keys(action).length !== 0);
  }
  get actions(): Action<T>[] {
    return this.#actions;
  }

  @Input() alternativePillConfig?: RowViewConfig<T>;
  @Output() action = new EventEmitter<{ key: string; item?: T }>();

  emitAction($event: Event, action: Action<T>) {
    if (!this.item) {
      return;
    }

    if (action?.disabledIf && action.disabledIf(this.item)) {
      return;
    }
    $event.stopPropagation();
    $event.preventDefault();
    this.action.emit({ key: action.key, item: this.item });
  }
}
