import { TourSettings } from './tour-config';

export const TOUR_SETTINGS: TourSettings = [
  {
    order: 1,
    name: 'userPanel',
    text: 'This is your user profile, from here you can switch theme (Black/White), update your name, contact details & personal settings.',
    mustCompleted: { taskInitializedTour: true },
  },
  {
    order: 2,
    name: 'clientSwitcher',
    text: 'This dropdown allows you to\n' + 'change catalogue.',
    mustCompleted: { userPanel: true },
    buttonText: 'Got it',
  },
  {
    order: 4,
    name: 'menuSidebarToggle',
    text: 'This button allows you to open main menu.',
    mustCompleted: { cartButton: true },
    xPosition: 'after',
  },
  {
    order: 5,
    name: 'menuSidebar',
    text: 'This is the main Menu, this is where you will navigate around VIDA',
    mustCompleted: { menuSidebarToggle: true },
    xPosition: 'after',
    horizontal: true,
  },
  {
    order: 6,
    name: 'menuAssets',
    text: 'The Assets page is where you will find all of the assets in this catalogue.',
    mustCompleted: { menuSidebar: true },
    xPosition: 'after',
    horizontal: true,
  },
  {
    order: 7,
    name: 'menuLibrary',
    text: 'Library allows you to build and browse virtual folder structures for your assets.',
    mustCompleted: { menuAssets: true },
    xPosition: 'after',
    horizontal: true,
  },
  {
    order: 8,
    name: 'searchBar',
    mustCompleted: { menuLibrary: true },
    text:
      'Search, the search field on the top bar will\n' +
      'perform a ‘similar’ search across all fields.\n' +
      'A ‘similar’ search allows for typos and\n' +
      'spelling mistakes.',
    clickToComplete: true,
  },
  {
    order: 9,
    name: 'advancedSearch',
    text:
      'For exact match searching use the fields in the dropdown,\n' +
      'these will return exact matches and matches that contain\n' +
      'what you search for in only the field used.\n' +
      'Multiple fields can be used to create complex search\n' +
      'criteria.',
    mustCompleted: { searchBar: true },
    xPosition: 'after',
    horizontal: true,
  },
  {
    order: 10,
    name: 'searchListSettings',
    text: 'The columns on the list view can be customised using the settings icon.',
    mustCompleted: { advancedSearch: true },
  },
  {
    order: 11,
    name: 'searchOpenAsset',
    text: '[Click it] Select an asset to preview',
    clickToComplete: true,
    yPosition: 'above',
    xPosition: 'before',
    mustCompleted: { searchListSettings: true },
  },
  {
    order: 11,
    name: 'assetsPreviewPlayer',
    text: 'The player allows you to preview a proxy of your assets.',
    xPosition: 'after',
    horizontal: true,
    mustCompleted: { searchListSettings: true },
  },
  {
    order: 12,
    name: 'assetsPreviewMetadata',
    text: 'Asset Metadata is also displayed here.',
    xPosition: 'before',
    horizontal: true,
    mustCompleted: { assetsPreviewPlayer: true },
  },
  {
    order: 13,
    name: 'assetsPreviewAddToCart',
    text: 'Assets can be added the cart using the add to cart button.',
    yPosition: 'above',
    mustCompleted: { assetsPreviewMetadata: true },
    button: false,
  },
  {
    order: 3,
    name: 'cartButton',
    text: 'The cart can be accessed by clicking the cart icon. The number of items in the cart is displayed on the cart icon.',
    mustCompleted: {
      clientSwitcher: true,
    },
  },
];
