import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { CheckoutComponent } from '../../pages/checkout/checkout.component';
import { CartCheckoutService } from './cart-checkout.service';

@Injectable()
export class CartCheckoutGuard implements CanDeactivate<CheckoutComponent> {
  checkoutService = inject(CartCheckoutService);

  canDeactivate(
    component: CheckoutComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return combineLatest([this.checkoutService.checkoutInProgress$, component.cartState.checkoutStep$]).pipe(
      switchMap(([checkoutInProgress, checkoutStep]) => {
        if (checkoutStep && checkoutInProgress) {
          return this.checkoutService.leavingCartConfirmation().pipe(
            tap((result) => {
              if (result) {
                this.checkoutService.resetValidationErrors();
              }
            }),
          );
        }
        return of(true);
      }),
      take(1),
    );
  }
}
