import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DestroyComponent, FilterType, ResourceModel, SelectOption, ValueFormat } from '@vdms-hq/shared';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BehaviorSubject, of, switchMap, take, tap } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RightsContractsService, RightsContractStatus } from '@vdms-hq/api-contract';
import { RightsContractsRefreshService } from '../../logic/rights-contracts-refresh.service';
import { RightsContractsActionsService } from '../../logic/rights-contracts-actions.service';
import {
  FormSectionComponent,
  TileSelectableConfig,
  UiAddDialogSelectableTilesComponent,
  UIButtonModule,
  UIDialogWrapperModule,
  UIEmptyResultsModule,
  UIFormModule,
} from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { RightsPartnersAddDsService } from '@vdms-hq/rights-partners';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RightsContractsAddDsService } from '../../logic/rights-contracts-add-ds.service';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { MatDividerModule } from '@angular/material/divider';
import {
  RightsContractsLicensePackagesDsService,
  LicensePackagesAddData,
} from '../../logic/rights-contracts-license-packages-ds.service';
import moment from 'moment';
import { ToastService } from '@vdms-hq/toast';

@Component({
  selector: 'vdms-hq-rights-contracts-create-edit-dialog',
  standalone: true,
  imports: [
    CommonModule,
    UIDialogWrapperModule,
    TranslateModule,
    UIButtonModule,
    FormSectionComponent,
    ReactiveFormsModule,
    UIFormModule,
    MatPaginatorModule,
    DynamicFiltersModule,
    MatDividerModule,
    UIEmptyResultsModule,
    UiAddDialogSelectableTilesComponent,
  ],
  templateUrl: './rights-contracts-create-edit-dialog.component.html',
  styles: [],
})
export class RightsContractsCreateEditDialogComponent extends DestroyComponent {
  readyToReleaseControl = new FormControl<boolean>(false);
  form = new FormGroup({
    name: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    contract_id: new FormControl<string>(''),
    start_date: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    end_date: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    partner_uuid: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    licensed_package_uuids: new FormControl<string[]>([], { nonNullable: true }),
    notifications_enabled: new FormControl(false),
    status: new FormControl<RightsContractStatus | null>(null),
  });

  selectedLicensePackagesControl = this.form.get('licensed_package_uuids') as FormControl<string[]>;
  baseLicensedPackages: string[] = [];

  isLoading$ = new BehaviorSubject(false);
  editMode = new BehaviorSubject(false);
  editPackages = new BehaviorSubject(false);

  title = '';
  saveButton = '';
  editPackagesButton = '';

  partnersOptions: SelectOption[] = [];

  filtersConfig: DynamicFilterInput[] = [
    {
      id: 'keyword',
      label: 'common.license_package.table.filter_placeholder',
      resource: [ResourceModel.LICENSED_PACKAGE],
      format: ValueFormat.AS_IS,
      filters: {
        objectPath: 'name',
        validFormat: 'keyword',
        type: FilterType.MASTER_TEXT,
      },
    },
  ];

  licensedPackagesTilesConfig: TileSelectableConfig<LicensePackagesAddData> = {
    label: 'label',
    key: 'key',
    metadata: [
      {
        valuePath: 'status',
        viewFormat: {
          pills: {
            active: 'done',
            draft: '',
          },
        },
      },
      {
        label: 'Number of assets',
        valuePath: 'number_of_assets',
        viewFormat: {
          modifiers: {
            asNumberWithZero: true,
          },
        },
      },
    ],
  };

  constructor(
    public licensedPackagesAddDsService: RightsContractsLicensePackagesDsService,
    private rightsContractsService: RightsContractsService,
    public rightsPartnersAddDsService: RightsPartnersAddDsService,
    private rightsContractsAddDsService: RightsContractsAddDsService,
    private rightsContractsActionsService: RightsContractsActionsService,
    private rightsContractsRefreshService: RightsContractsRefreshService,
    private toast: ToastService,
    public dialogRef: MatDialogRef<RightsContractsCreateEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { uuid: string | undefined; editPackages: boolean },
  ) {
    super();
    this.rightsPartnersAddDsService.allData$.pipe(this.takeUntilDestroyed(), take(1)).subscribe((data) => {
      this.partnersOptions = data;
    });
    if (data.uuid) {
      if (data.editPackages) {
        this.editPackages.next(true);
      }
      this.saveButton = 'common.dialogs.rights_contracts.edit.uploading_data';
      this.editPackagesButton = 'common.dialogs.rights_contracts.edit.edit_packages';
      this.setEditMode(data.uuid);
    } else {
      this.readyToReleaseControl.setValue(false);
      this.readyToReleaseControl.disable();
      this.title = 'common.dialogs.rights_contracts.create.title';
      this.saveButton = 'common.dialogs.rights_contracts.create.button';
      this.editPackagesButton = 'common.dialogs.rights_contracts.create.add_packages';
    }
  }

  get isReadyToRelease() {
    return this.readyToReleaseControl.value && this.readyToReleaseControl.enabled;
  }

  setReadyToRelease() {
    this.isLoading$.next(true);
    return this.rightsContractsService.setReadyToRelease(this.data.uuid as string).pipe(
      take(1),
      tap({
        next: () => {
          this.rightsContractsActionsService.popToast.UPDATE_SUCCESS();
          this.handleComplete();
        },
        error: () => {
          this.rightsContractsActionsService.popToast.UPDATE_FAILURE();
          this.handleComplete();
        },
      }),
    );
  }

  setEditMode(uuid: string) {
    this.title = 'common.dialogs.rights_contracts.edit.title';
    this.isLoading$.next(true);
    this.editMode.next(true);

    this.rightsContractsService
      .getOne(uuid)
      .pipe(this.takeUntilDestroyed(), take(1))
      .subscribe({
        next: (contract) => {
          this.form.patchValue({
            name: contract.name,
            contract_id: contract.contract_id,
            start_date: this.#dateToUTC(contract.start_date),
            end_date: this.#dateToUTC(contract.end_date),
            partner_uuid: contract.partner ? contract.partner.uuid : '',
            licensed_package_uuids: contract.licensed_package_uuids ?? [],
            notifications_enabled: contract.notifications_enabled,
            status: contract.status,
          });
          contract.status !== RightsContractStatus.COLLECTING_CONTENT &&
            (() => {
              this.readyToReleaseControl.setValue(true);
              this.readyToReleaseControl.disable();
            })();
          this.baseLicensedPackages = contract.licensed_package_uuids ?? [];
          this.saveButton = 'common.dialogs.rights_contracts.edit.button';
          this.isLoading$.next(false);
        },
        error: (error) => {
          this.isLoading$.next(false);
          this.rightsContractsActionsService.popToast.GET_ONE_FAILURE();
          throw error;
        },
      });
  }

  onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.isLoading$.next(true);
    if (this.editMode.value && this.data.uuid) {
      const toDetach = this.baseLicensedPackages.filter(
        (item) => !this.selectedLicensePackagesControl.value.includes(item),
      );
      const toAttach = this.selectedLicensePackagesControl.value.filter(
        (item) => !this.baseLicensedPackages.includes(item),
      );

      const edited = {
        name: this.form.value.name as string,
        start_date: this.#dateToUTC(this.form.value.start_date as string, true),
        end_date: this.#dateToUTC(this.form.value.end_date as string),
        contract_id: this.form.value.contract_id as string,
        partner_uuid: this.form.value.partner_uuid as string,
        licensed_package_uuids: this.form.value.licensed_package_uuids as string[],
        licensed_packages_to_attach: toAttach,
        licensed_packages_to_detach: toDetach,
        notifications_enabled: this.form.value.notifications_enabled,
      };
      this.rightsContractsService
        .patch(this.data.uuid, edited)
        .pipe(
          this.takeUntilDestroyed(),
          take(1),
          switchMap(() => {
            return this.isReadyToRelease ? this.setReadyToRelease() : of(true);
          }),
        )
        .subscribe({
          next: () => {
            this.rightsContractsActionsService.popToast.UPDATE_SUCCESS();
            this.handleComplete();
          },
          error: (error) => {
            this.isLoading$.next(false);
            this.rightsContractsActionsService.popToast.UPDATE_FAILURE();
            throw error;
          },
        });
    } else {
      const newContract = {
        name: this.form.value.name as string,
        start_date: this.#dateToUTC(this.form.value.start_date as string, true),
        end_date: this.#dateToUTC(this.form.value.end_date as string),
        partner_uuid: this.form.value.partner_uuid as string,
        licensed_package_uuids: this.selectedLicensePackagesControl.value as string[],
        notifications_enabled: this.form.value.notifications_enabled,
      };
      this.rightsContractsService
        .create(newContract)
        .pipe(
          this.takeUntilDestroyed(),
          take(1),
          switchMap(() => {
            return this.isReadyToRelease ? this.setReadyToRelease() : of(true);
          }),
        )
        .subscribe({
          next: () => {
            this.rightsPartnersAddDsService.refresh$.next(true);
            this.rightsContractsActionsService.popToast.CREATE_SUCCESS();
            this.handleComplete();
          },
          error: (error) => {
            this.isLoading$.next(false);
            this.rightsContractsActionsService.popToast.CREATE_FAILURE();
            throw error;
          },
        });
    }
  }

  handleComplete() {
    this.isLoading$.next(false);
    this.rightsContractsRefreshService.refreshRightsContractsList$.next(true);
    this.rightsContractsAddDsService.refresh$.next(true);

    this.closeDialog();
  }

  toggleEditPackages() {
    this.editPackages.next(!this.editPackages.value);
  }

  selectLicensedPackage(item: LicensePackagesAddData) {
    if (this.selectedLicensePackagesControl.value.includes(item.key)) {
      this.selectedLicensePackagesControl.setValue(
        this.selectedLicensePackagesControl.value.filter((id) => id !== item.key),
      );
    } else {
      this.selectedLicensePackagesControl.setValue([...this.selectedLicensePackagesControl.value, item.key]);
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  #dateToUTC(date: string, startDate = false) {
    if (startDate && moment(date).isSame(moment(), 'day')) {
      return moment.utc(moment().toISOString(true)).toISOString();
    }
    return moment.utc(moment(date).toISOString(true).substr(0, 10)).startOf('day').toISOString();
  }
}
