import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetadataListDatasource } from '../../logic/metadata-list.datasource';
import { UIEmptyResultsModule, UILayoutModule } from '@vdms-hq/ui';
import { metadataListTableColumns, metadataListTableColumnsEnabled } from '../../logic/cfg/metadata-table-config';
import { MetadataListForm } from '../../logic/metadata-list-form';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { defaultFilters, filteredMetadataTypes } from '../../logic/cfg/metadata-list-filters-config';
import { TimecodeTableComponent } from '../timecode-table/timecode-table.component';
import { MetadataListConfig, MetadataListInputService } from '../../logic/metadata-list-input.service';
import { map, take } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { METADATA_TYPES } from '../../logic/cfg/metadata-list-filters-select-options';

@Component({
  selector: 'vdms-hq-player-metadata-list',
  standalone: true,
  imports: [CommonModule, UIEmptyResultsModule, DynamicFiltersModule, TimecodeTableComponent, UILayoutModule],
  providers: [MetadataListDatasource],
  templateUrl: './metadata-list.component.html',
  styleUrls: ['./metadata-list.component.scss'],
})
export class MetadataListComponent implements OnInit, OnDestroy {
  @Input() config!: MetadataListConfig;
  @Input() withHeader = true;

  columns = metadataListTableColumns;
  enabled = metadataListTableColumnsEnabled;
  filtersConfig: DynamicFilterInput[] = [];

  constructor(
    public dataSource: MetadataListDatasource,
    private metadataInput: MetadataListInputService,
    public metadataListForm: MetadataListForm,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.filtersConfig = [];
    this.metadataInput.load(this.config);

    filteredMetadataTypes(
      METADATA_TYPES,
      this.config.enabledStreams,
      this.config?.workflow?.params?.subtitles?.targetLanguages,
    ).forEach((item) => (item.disabled = false));

    this.filtersConfig = defaultFilters(
      this.config.enabledStreams,
      this.config?.workflow?.params?.subtitles?.targetLanguages,
    );

    this.#setTypesEnabledValues();
    this.#setTranscriptionValues();
  }

  #setTranscriptionValues() {
    this.route.queryParams
      .pipe(
        take(1),
        map((params: Params) => params?.['transcriptions']),
      )
      .subscribe((transcriptions) => {
        if (transcriptions) {
          const value = Array.isArray(transcriptions) ? transcriptions : [transcriptions];
          value && this.metadataListForm.filters?.get('phrase')?.setValue(value, { emitEvent: false });
        }

        this.dataSource.init();
      });
  }

  #setTypesEnabledValues() {
    this.metadataInput.config$
      .pipe(
        take(1),
        map((cfg) => cfg.defaultStream),
      )
      .subscribe((defaultStream) => {
        this.metadataListForm.filters.get('type')?.setValue(defaultStream);
      });
  }

  ngOnDestroy() {
    this.filtersConfig = [];
    defaultFilters([], []);
  }
}
