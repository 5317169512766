export enum ApprovalStatus {
  REQUESTED = 0,
  APPROVED = 1,
  REJECTED = 2,
}

export enum OrderDeliveryStatus {
  CREATED = 'created',
  SCHEDULED = 'scheduled',
  COLLECTING_ASSETS = 'collecting_assets',
  COLLECTING_CATALOG_ITEMS = 'collecting_catalog_items',
  DELIVERY = 'delivery',
  DELIVERED = 'delivered',
  FAILED = 'failed',
  GETTYIMAGES_API_FAILED = 'gettyimages_api_failed',
  SUBMITTED_TO_SF = 'submitted_to_sf',
  RETRIEVING_FROM_COLD = 'retrieving_from_cold',
}

export enum OrderStatus {
  RELEASED_TO_QUEUE = 'Released to Queue',
  HOLD = 'HOLD',
  DELIVERED = 'Delivered',
  DELIVERY_IN_PROGRESS = 'Delivery in Progress',
  TRANSCODE = 'Transcode',
}

export enum FilterOrderStatus {
  PENDING = 'submitted',
  APPROVED = 'approved',
}

export enum OrderDownloadStatus {
  ACTIVE = 'new',
  DOWNLOADED = 'downloaded',
  EXPIRED = 'expired',
}
