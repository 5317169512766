<div class="multi-data-container">
  <vdms-hq-ui-data-presentation-header
    [title]="'pages.home.menu.users'"
    [actions]="headerActions"
    (action)="handleAction($event)"
  >
  </vdms-hq-ui-data-presentation-header>
  <vdms-hq-ui-multiple-data-presentation
    *ngIf="viewConfiguration$ | async; let config"
    [dataSource]="dataSource"
    [configuration]="config"
    (action)="handleAction($event)"
    [drawerVisible]="isPaneVisible"
  >
    <vdms-hq-dynamic-form-group-renderer
      filters
      [form]="filters"
      [fieldsConfig]="filtersConfig"
    ></vdms-hq-dynamic-form-group-renderer>

    <ng-container drawer-footer *ngIf="currentUser$ | async; let user">
      <vdms-hq-ui-button
        *requiresPermissions="[Permission.DELETE_USERS]"
        (click)="handleAction({ key: 'delete', item: user })"
        color="warn"
        >{{ 'common.global.delete' | translate }}
      </vdms-hq-ui-button>
    </ng-container>

    <ng-container drawer-content>
      <vdms-hq-ui-loader [backdrop]="true" mode="over-parent" *ngIf="isCurrentUserLoading$ | async"></vdms-hq-ui-loader>

      <ng-container *ngIf="currentUser$ | async as user">
        <div class="content">
          <vdms-hq-ui-form-input-text
            [ngModel]="user?.email"
            [disabled]="true"
            [placeholder]="'pages.settings.email_placeholder' | translate"
            [label]="'pages.settings.email' | translate"
          >
          </vdms-hq-ui-form-input-text>

          <vdms-hq-ui-form-input-text
            [ngModel]="user?.name"
            [disabled]="true"
            [placeholder]="'pages.settings.name_placeholder' | translate"
            [label]="'pages.settings.name' | translate"
          >
          </vdms-hq-ui-form-input-text>

          <vdms-hq-ui-timeline
            [timelines]="user?.activity?.result || []"
            [title]="'pages.users.latest_activity'"
          ></vdms-hq-ui-timeline>
        </div>
      </ng-container>
    </ng-container>
  </vdms-hq-ui-multiple-data-presentation>
</div>
