import { FilterType, ResourceModel, ValueFormat } from '@vdms-hq/shared';
import { DynamicFilterInput } from '@vdms-hq/dynamic-filters';
import { SORT_BY, STATUS_OPTIONS } from '../../logic/utils/collections-filters-provider';

export const defaultFilters: DynamicFilterInput[] = [
  {
    id: 'keyword',
    label: 'common.collections.table.filter',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'keyword',
      validFormat: 'keyword',
      type: FilterType.MASTER_TEXT,
    },
  },
  {
    id: 'sort',
    label: 'common.orders.filters.sort',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'sort',
      type: FilterType.SELECTOR,
    },
    selectOptions: SORT_BY,
  },
  {
    id: 'collectionType',
    label: '',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'owner',
      type: FilterType.RADIO_LIST,
    },
    selectOptions: STATUS_OPTIONS,
  },
];

export const defaultMetadata = ['ownerType', 'assetsNumber', 'totalSize', 'description', 'name'];
