import { AfterViewChecked, Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOption, SelectOptionGroup, SelectOptionKey } from '@vdms-hq/shared';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';

@Component({
  selector: 'vdms-hq-ui-form-input-select-group[selectOptions]',
  templateUrl: './form-input-select-group.component.html',
  styleUrls: ['./form-input-select-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputSelectGroupComponent),
    },
  ],
})
export class FormInputSelectGroupComponent
  extends FormControlValueAccessorComponent<SelectOptionKey>
  implements AfterViewChecked, OnInit
{
  @Input() selectOptions: SelectOptionGroup[] = [];
  @Input() loading: boolean | null = false;
  @Input() multiple = false;
  @Input() onePerGroup = false;
  innerFormControl = new FormControl();
  #selectOptionsMappedToGroups: SelectOption[] = [];

  get selectedLabels(): string[] | null {
    if (!this.selectOptions || !this.#selectOptionsMappedToGroups) {
      return null;
    }
    return (this.innerFormControl.value || []).map(
      (innerVal: string) =>
        this.#selectOptionsMappedToGroups?.find((option) => innerVal === option?.key)?.label || '[ empty ]',
    );
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.#selectOptionsMappedToGroups = this.selectOptions
      ?.map((select) => select.options)
      ?.reduce((p, c) => [...p, ...c], []);
  }

  checkIsGroupDisabled(group: SelectOptionGroup, option: SelectOption): boolean {
    return (
      group.options.some((so: SelectOption) => this.innerFormControl?.value?.includes(so.key)) &&
      !this.innerFormControl?.value?.includes(option.key)
    );
  }
}
