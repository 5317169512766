<div class="container">
  <div class="container__cell backdrop">
    <vdms-hq-login-logo class="logo-wrapper"></vdms-hq-login-logo>
    <p class="slogan"><span>></span> Store, manage, and distribute your media assets</p>
  </div>
  <div class="container__separator"></div>
  <div class="container__cell">
    <div class="form-wrapper">
      <vdms-hq-login-form></vdms-hq-login-form>

      <vdms-hq-provider-buttons></vdms-hq-provider-buttons>
    </div>
    <div class="vida-powered">
      <img alt="Powered by VIDA" src="assets/login/powered-vida.svg" />
    </div>
  </div>
</div>
