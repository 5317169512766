import { Injectable } from '@angular/core';
import { StreamsType } from './streams-type';
import {
  MediaInsightsApiService,
  MediaInsightsCelebrityRecognitionMetadata,
  MediaInsightsObjectRecognitionMetadata,
  MediaInsightsSubtitlesMetadata,
  MediaInsightsTechnicalCueDetectionMetadata,
  MediaInsightsTranscriptionMetadata,
  MiWorkflowOperator,
  TranscriptionService,
  VideoAnalysisApiService,
} from '@vdms-hq/api-contract';
import { Observable, of, map, shareReplay, switchMap, combineLatest, EMPTY, filter } from 'rxjs';
import { MetadataListSources, ViewPlayerMetadataItem } from '../metadata-list.model';
import {
  fromContentModeration,
  fromMiCelebrityRecognition,
  fromMiObjectRecognition,
  fromMiSubtitles,
  fromMiTechnicalCueDetection,
  fromMiTranscription,
  fromTranscription,
} from '../transformer/media-insights.transformer';
import { catchError } from 'rxjs/operators';
import { ToastService } from '@vdms-hq/toast';
import { TranslateService } from '@ngx-translate/core';
import { MetadataListInputService } from '../metadata-list-input.service';
import { MetadataListForm } from '../metadata-list-form';
import { filteredMetadataTypes } from '../cfg/metadata-list-filters-config';
import { METADATA_TYPES } from '../cfg/metadata-list-filters-select-options';

@Injectable({ providedIn: 'root' })
export class StreamsSourceService {
  constructor(
    private miApiService: MediaInsightsApiService,
    private videoAnalysisApiService: VideoAnalysisApiService,
    private transcriptionService: TranscriptionService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private metadataListInputService: MetadataListInputService,
    private metadataListForm: MetadataListForm,
  ) {}

  sources$: Observable<MetadataListSources[]> = combineLatest([
    this.metadataListInputService.config$,
    this.metadataListForm.values$,
  ]).pipe(
    map(([{ workflow, assetUuid, framerate }, values]) => {
      if (!assetUuid || !workflow) {
        return [];
      }

      let streamSources: MetadataListSources[] = [];
      streamSources = [
        {
          key: StreamsType.MI_TRANSCRIPTION,
          source: this.miApiService.getStatusWorkflow(workflow?.uuid, MiWorkflowOperator.TRANSCRIBE).pipe(
            map((data) => (data.metadata as MediaInsightsTranscriptionMetadata)?.results?.items ?? []),
            map((items) => items.map((item) => fromMiTranscription(item, StreamsType.MI_TRANSCRIPTION, framerate))),
            catchError((err) => this.#errorHandler(StreamsType.MI_TRANSCRIPTION, err)),
            shareReplay(1),
          ),
        },
        {
          key: StreamsType.MI_OBJECTS_RECOGNITION,
          source: this.miApiService.getStatusWorkflow(workflow?.uuid, MiWorkflowOperator.OBJECT_DETECTION).pipe(
            map((data) => (data?.metadata as MediaInsightsObjectRecognitionMetadata[]) ?? []),
            map((items) =>
              items.map((metadata) => fromMiObjectRecognition(metadata, StreamsType.MI_OBJECTS_RECOGNITION, framerate)),
            ),
            catchError((err) => this.#errorHandler(StreamsType.MI_OBJECTS_RECOGNITION, err)),
            shareReplay(1),
          ),
        },
        {
          key: StreamsType.MI_CELEBRITY_RECOGNITION,
          source: this.miApiService.getStatusWorkflow(workflow?.uuid, MiWorkflowOperator.CELEBRITY_RECOGNITION).pipe(
            map((data) => (data?.metadata as MediaInsightsCelebrityRecognitionMetadata[]) ?? []),
            map((items) =>
              items.map((metadata) =>
                fromMiCelebrityRecognition(
                  metadata as MediaInsightsCelebrityRecognitionMetadata,
                  StreamsType.MI_CELEBRITY_RECOGNITION,
                  framerate,
                ),
              ),
            ),

            catchError((err) => this.#errorHandler(StreamsType.MI_CELEBRITY_RECOGNITION, err)),
            shareReplay(1),
          ),
        },
        {
          key: StreamsType.MI_TECHNICAL_CUE_DETECTION,
          source: this.miApiService.getStatusWorkflow(workflow?.uuid, MiWorkflowOperator.TECHNICAL_CUE_DETECTION).pipe(
            map((data) => (data?.metadata as MediaInsightsTechnicalCueDetectionMetadata[]) ?? []),
            map((items) =>
              items.map((metadata) =>
                fromMiTechnicalCueDetection(
                  metadata as MediaInsightsTechnicalCueDetectionMetadata,
                  StreamsType.MI_TECHNICAL_CUE_DETECTION,
                  framerate,
                ),
              ),
            ),
            catchError((err) => this.#errorHandler(StreamsType.MI_TECHNICAL_CUE_DETECTION, err)),
            shareReplay(1),
          ),
        },
        {
          key: StreamsType.MI_SHOT_DETECTION,
          source: this.miApiService.getStatusWorkflow(workflow?.uuid, MiWorkflowOperator.SHOT_DETECTION).pipe(
            map(() => [] as ViewPlayerMetadataItem[]), // todo!!!
            catchError(() => this.#errorHandler(StreamsType.MI_SHOT_DETECTION)),
            shareReplay(1),
          ),
        },
        {
          key: StreamsType.CONTENT_MODERATION,
          source: this.videoAnalysisApiService.getResults(assetUuid).pipe(
            map((data) => data?.ModerationLabels ?? []),
            map((items) =>
              items.map((label) => fromContentModeration(label, StreamsType.CONTENT_MODERATION, framerate)),
            ),
            catchError(() => this.#errorHandler(StreamsType.CONTENT_MODERATION)),
            shareReplay(1),
          ),
        },
        {
          key: StreamsType.TRANSCRIPTION,
          source: this.transcriptionService.listForAsset(assetUuid).pipe(
            map(
              (transcriptions) =>
                transcriptions.filter((transcription) => transcription.status === 'complete')[0]?.uuid,
            ),
            switchMap((transcriptionUuid) => {
              if (!transcriptionUuid) {
                this.#emptyData(StreamsType.TRANSCRIPTION);
                return of([]);
              } else {
                return this.transcriptionService.transcriptionItems(transcriptionUuid).pipe(shareReplay(1));
              }
            }),
            map((items) => items.map((item) => fromTranscription(item, StreamsType.TRANSCRIPTION, framerate))),
            catchError((err) => this.#errorHandler(StreamsType.TRANSCRIPTION, err)),
            shareReplay(1),
          ),
        },
      ];

      const miSubtitlesLang = values.type
        .filter((item) => item.includes(StreamsType.MI_SUBTITLES))
        .map((item) => item.split('_').pop());

      if (miSubtitlesLang) {
        miSubtitlesLang.forEach((lang) => {
          streamSources.push({
            key: `${StreamsType.MI_SUBTITLES}_${lang}`,
            source: this.miApiService
              .getStatusWorkflow(workflow?.uuid, `${MiWorkflowOperator.WEB_CAPTIONS}_${lang}`)
              .pipe(
                map((data) => {
                  const metadata = (data?.metadata as MediaInsightsSubtitlesMetadata[]) ?? [];
                  const vttPath = data.vtt;
                  return metadata.map((metadata) =>
                    fromMiSubtitles(
                      metadata as MediaInsightsSubtitlesMetadata,
                      StreamsType.MI_SUBTITLES,
                      framerate,
                      lang,
                      vttPath,
                    ),
                  );
                }),
                catchError((err) => this.#errorHandler(StreamsType.MI_SUBTITLES, err)),
                shareReplay(1),
              ),
          });
        });
      }

      return streamSources;
    }),
  );

  #emptyData(type: StreamsType) {
    this.toastService.warning({
      id: 'playerMetadataList',
      message: 'common.player_metadata_list.stream_error',
      interpolatedParams: {
        type: this.translateService.instant(`common.player_metadata_list.stream_type_label.${type}`),
      },
    });

    const streamTypesArray = this.metadataListForm.filters.get('type')?.value ?? [];
    const targetLanguages = streamTypesArray.filter((item) => item.includes(StreamsType.MI_SUBTITLES));
    filteredMetadataTypes(METADATA_TYPES, streamTypesArray, targetLanguages).filter(
      (item) => item?.key === type,
    )[0].disabled = true;
  }

  #errorHandler(type: StreamsType, err?: Error) {
    if (err !== undefined) {
      console.error(type, err);
    } else {
      this.#emptyData(type);
    }

    return of([]);
  }
}
