<div class="section-container">
  <div class="section-title">
    <vdms-hq-ui-form-section-title
      *ngIf="title.length"
      [title]="title"
      [size]="titleSize"
    ></vdms-hq-ui-form-section-title>

    <ng-content select="[action]"></ng-content>
  </div>

  <ng-content select="[header]"></ng-content>

  <div [ngClass]="classCss" [ngStyle]="styleCss">
    <ng-content></ng-content>
  </div>

  <mat-divider *ngIf="divider" [ngStyle]="{ 'margin-block': dividerMarginBlock ? '5px' : '' }"></mat-divider>
</div>
