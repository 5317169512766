import { Injectable } from '@angular/core';
import { AssetApiService } from '@vdms-hq/api-contract';
import { BehaviorSubject, filter, shareReplay, Subject, switchMap, tap } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Injectable()
export class HotColdAssetLogsFetcher {
  assetUuid = '';
  constructor(private assetApiService: AssetApiService) {}

  refresh$ = new Subject<void>();
  loading$ = new BehaviorSubject(true);

  logs$ = this.refresh$.pipe(
    startWith(null),
    tap(() => this.loading$.next(true)),
    filter(() => !!this.assetUuid),
    switchMap(() => this.assetApiService.getAssetStorageChangeLogs(this.assetUuid)),
    tap(() => this.loading$.next(false)),
    shareReplay(1),
  );
}
