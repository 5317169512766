import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedClientModule, Permission } from '@vdms-hq/activated-client';
import { QUARANTINE_ALL_ASSET_TYPES } from '@vdms-hq/api-contract';
import { SelectOption } from '@vdms-hq/shared';
import { FormSectionComponent, UIButtonModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { QuarantineFormService } from '../logic/quarantine-form.service';

@Component({
  selector: 'vdms-hq-quarantine',
  standalone: true,
  imports: [
    CommonModule,
    UILayoutModule,
    ActivatedClientModule,
    FormSectionComponent,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    UIFormModule,
    UIButtonModule,
  ],
  templateUrl: './quarantine-admin.component.html',
})
export class QuarantineAdminComponent {
  protected readonly Permission = Permission;
  private formService = inject(QuarantineFormService);

  isLoading$ = this.formService.isLoading$;
  isSaving$ = this.formService.isSaving$;

  form$ = this.formService.getForm$();

  quarantineSelectOptions: SelectOption[] = QUARANTINE_ALL_ASSET_TYPES.map((type) => ({
    key: type,
    label: type,
  }));

  save() {
    this.formService.save();
  }
}
