import { Injectable } from '@angular/core';
import { ClientsService } from '@vdms-hq/api-contract';
import { BehaviorSubject, switchMap, tap } from 'rxjs';

@Injectable()
export class ClientsFetcher {
  constructor(private readonly clientsService: ClientsService) {}
  refresh$ = new BehaviorSubject<boolean>(true);
  loading$ = new BehaviorSubject<boolean>(false);

  clients$ = this.refresh$.pipe(
    tap(() => this.loading$.next(true)),
    switchMap(() => this.clientsService.getClients()),
    tap(() => this.loading$.next(false)),
  );
}
