import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment/moment';
import { Observable, of, interval, startWith, takeWhile } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'countdown',
  standalone: true,
})
export class CountdownPipe implements PipeTransform {
  private counter = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    complete: false,
    update: function (endTime: moment.Moment) {
      const duration = moment.duration(endTime.diff(moment().utc(true)));
      if (duration.asSeconds() <= 0) {
        this.complete = true;
        this.reset();
        return;
      }
      this.days = duration.days();
      this.hours = duration.hours() + this.days * 24;
      this.minutes = duration.minutes();
      this.seconds = duration.seconds();
    },
    reset: function () {
      this.days = 0;
      this.hours = 0;
      this.minutes = 0;
      this.seconds = 0;
    },
    toString: function () {
      return `${this.hours.toString().padStart(2, '0')}:${this.minutes.toString().padStart(2, '0')}:${this.seconds
        .toString()
        .padStart(2, '0')}`;
    },
  };

  transform(value: unknown): Observable<string> {
    const isString = (value: any): value is string => typeof value === 'string';
    const isDate = (value: any): value is Date => value instanceof Date;

    if (!isString(value) && !isDate(value)) {
      return of('Not a valid date');
    }

    const endTime = moment(value);

    if (!endTime.isValid()) {
      return of('Not a valid date');
    }

    if (endTime.isBefore(moment())) {
      this.counter.reset();
    }

    this.counter.update(endTime);

    return interval(1000).pipe(
      takeWhile(() => !this.counter.complete),
      map(() => {
        this.counter.update(endTime);
        return this.counter.toString();
      }),
      startWith(this.counter.toString()),
    );
  }
}
