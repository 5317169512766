import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogResponse } from '@vdms-hq/shared';
import {
  InfoBarType,
  SelectedFilesService,
  UIButtonModule,
  UIDialogWrapperModule,
  UIFormModule,
  UILayoutModule,
  UINativeUploadModule,
} from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { AsyncPipe, NgIf } from '@angular/common';
import { firstValueFrom } from 'rxjs';
import { CollectionCreateUploadResponse } from '@vdms-hq/api-contract';
import { map } from 'rxjs/operators';
import { SingleCollectionImportService } from '../../logic/services/single-collection-import.service';
import { CollectionUploadStatusEnum } from '../../logic/models/statuses-type.model';
import { ActivatedClientModule, Permission } from '@vdms-hq/activated-client';

@Component({
  standalone: true,
  selector: 'vdms-hq-collection-create-dialog',
  templateUrl: './collection-create-dialog.component.html',
  styleUrls: ['./collection-create-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    UIDialogWrapperModule,
    TranslateModule,
    UIFormModule,
    UIButtonModule,
    MatTabsModule,
    UINativeUploadModule,
    AsyncPipe,
    NgIf,
    ActivatedClientModule,
    UILayoutModule,
  ],
})
export class CollectionCreateDialogComponent {
  protected readonly InfoBarType = InfoBarType;
  protected readonly types = ['text/csv'];
  collectionForm: FormGroup;
  loading = false;
  hasSelected$ = this.selectedFilesService.hasSelected$;

  permission = Permission;

  constructor(
    private selectedFilesService: SelectedFilesService,
    private collectionsUploadService: SingleCollectionImportService,
    public dialogRef: MatDialogRef<CollectionCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string },
  ) {
    this.collectionForm = new FormGroup({
      name: new FormControl<string>(this.data.name, Validators.required),
      description: new FormControl<string>(''),
    });
  }

  closeDialog(dialogResult?: any): void {
    this.selectedFilesService.clear();
    this.dialogRef.close(dialogResult ?? null);
  }

  onSubmit() {
    this.collectionForm.markAllAsTouched();

    if (this.collectionForm.invalid) {
      return;
    }

    this.dialogRef.close({
      status: DialogResponse.OK,
      action: 'create',
      collection: {
        name: this.collectionForm.controls['name'].value,
        description: this.collectionForm.controls['description'].value,
      },
    });
  }

  async uploadFile() {
    const file: File = await firstValueFrom(
      this.selectedFilesService.selectedFiles$.pipe(map((files) => files[0] ?? null)),
    );

    if (!file) {
      return;
    }

    this.loading = true;
    const fileMetadata = (await this.collectionsUploadService.prepareUpload(file)) as CollectionCreateUploadResponse;

    const { status } = await this.collectionsUploadService.uploadToS3(file, fileMetadata);

    if (status === CollectionUploadStatusEnum.ERROR) {
      this.closeDialog({
        status: DialogResponse.ABORT,
        action: 'import',
        message: 'Failed to upload file',
      });
      return;
    } else {
      this.closeDialog({
        status: DialogResponse.OK,
        action: 'import',
        message: 'File uploaded',
        uploadParams: {
          filename: file.name,
          file_key: fileMetadata.data.upload_access.file_key,
        },
      });
      this.loading = false;
    }
  }
}
