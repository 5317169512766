import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FiltersVisibilityService {
  #openedSubject$ = new BehaviorSubject<boolean>(true);
  opened$ = this.#openedSubject$.asObservable();

  open(): void {
    this.#openedSubject$.next(true);
  }

  close(): void {
    this.#openedSubject$.next(false);
  }
}
