import { Injectable } from '@angular/core';
import { first, map } from 'rxjs/operators';
import { DataProviderService } from './data-provider.service';
import { LanguageModel } from './models/language';

@Injectable()
export class LanguagesProviderService {
  constructor(private dataProviderService: DataProviderService) {}

  public getById(id: string) {
    return this.getForSelector().pipe(
      first(),
      map((items) => items.find((item) => item.key === id)),
    );
  }

  public getForSelector = () =>
    this.dataProviderService.connect().pipe(
      map((list) =>
        list.map((item) => {
          const code = item?.code;

          const additional = code ? ` (${code})` : '';

          return {
            key: item.uuid,
            label: `${item.name}${additional}`,
          };
        }),
      ),
    );

  public getDictionary = () => {
    return this.dataProviderService.connect().pipe(
      map((items) => {
        return items.reduce(
          (obj, item) => ({
            ...obj,
            [item['uuid']]: item,
          }),
          {},
        ) as Record<string, LanguageModel>;
      }),
    );
  };
}
