<form class="filter-view" [formGroup]="form">
  <vdms-hq-ui-form-input-accordions>
    <vdms-hq-ui-form-input-accordion [wrappedFormControl]="bitrateRange" label="Bitrate" [optionsLength]="0">
      <vdms-hq-form-input-bitrate-range
        [predefinedRangesOptions]="facetsBitrate"
        [formControl]="bitrateRange"
        [range]="bitrateRangeMinMax"
        [enabledUnits]="['bps', 'kbps', 'Mbps']"
      >
      </vdms-hq-form-input-bitrate-range>
    </vdms-hq-ui-form-input-accordion>
    <vdms-hq-ui-form-input-accordion
      [wrappedFormControl]="timecodeRange"
      label="Duration"
      [optionsLength]="facetsTimecode.length"
    >
      <vdms-hq-ui-form-radio-list [selectOptions]="facetsTimecode" [formControl]="timecodeRange">
      </vdms-hq-ui-form-radio-list>
      <vdms-hq-form-input-timecode-range [formControl]="timecodeRange" [range]="timecodeRangeMinMax">
      </vdms-hq-form-input-timecode-range>
    </vdms-hq-ui-form-input-accordion>

    <vdms-hq-ui-form-input-accordion
      [wrappedFormControl]="bytesRange"
      label="File size"
      [optionsLength]="facetsFileSize.length"
    >
      <vdms-hq-form-input-size-range
        [formControl]="bytesRange"
        [range]="filesizeRangeMinMax"
        [predefinedRangesOptions]="facetsFileSize"
        [enabledUnits]="['B', 'kB', 'MB', 'GB', 'TB']"
      >
      </vdms-hq-form-input-size-range>
    </vdms-hq-ui-form-input-accordion>

    <vdms-hq-ui-form-input-accordion
      [wrappedFormControl]="checkboxList"
      label="Example short list"
      [optionsLength]="selectOptions.length"
    >
      <vdms-hq-ui-form-checkbox-list
        [placeholder]="placeholder"
        [withFooter]="withFooter"
        [available]="selectOptions"
        [columns]="1"
        orientation="vertical"
        [formControl]="checkboxList"
      ></vdms-hq-ui-form-checkbox-list>
    </vdms-hq-ui-form-input-accordion>
    <vdms-hq-ui-form-input-accordion
      [wrappedFormControl]="checkboxList"
      label="Example long list"
      [optionsLength]="selectOptionsLong.length"
    >
      <vdms-hq-ui-form-checkbox-list
        [placeholder]="placeholder"
        [withFooter]="withFooter"
        [available]="selectOptionsLong"
        [columns]="1"
        orientation="vertical"
        [formControl]="checkboxList"
      ></vdms-hq-ui-form-checkbox-list>
    </vdms-hq-ui-form-input-accordion>
    <vdms-hq-ui-form-input-accordion [wrappedFormControl]="text" label="Example text">
      <vdms-hq-ui-form-input-text
        [formControl]="text"
        [withFooter]="withFooter"
        [customErrorMessages]="customErrorMessages"
        [hint]="hint"
      >
      </vdms-hq-ui-form-input-text>
    </vdms-hq-ui-form-input-accordion>
  </vdms-hq-ui-form-input-accordions>
  <div>
    <vdms-hq-ui-control-state [control]="$any(form)"></vdms-hq-ui-control-state>
  </div>
</form>
