import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogWrapperComponent } from './dialog-wrapper/dialog-wrapper.component';
import { UILoaderModule } from '../ui-loader';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [DialogWrapperComponent],
  imports: [CommonModule, UILoaderModule, MatDialogModule],
  exports: [DialogWrapperComponent, MatDialogModule],
})
export class UIDialogWrapperModule {}
