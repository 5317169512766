<vdms-hq-orders-results
  [tableType]="tableType"
  [columnsSettingsScope]="columnsSettingsScope"
  [title]="title"
  [actions]="actions"
  [filtersConfig]="filtersConfig"
  (changeDefaultView)="changeDefaultView($event)"
  (action)="handleAction($event)"
>
</vdms-hq-orders-results>
