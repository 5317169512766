import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TransformableAssetFlat } from './asset-flat-transformer.service';
import { AssetFlatView2 } from './asset-flat-view-2.model';

export const StreamAssetsToViewModel = (
  allAssets: AssetFlatView2[],
): ((stream: Observable<Array<TransformableAssetFlat & { touchedAt?: string }>>) => Observable<AssetFlatView2[]>) => {
  return (stream: Observable<Array<TransformableAssetFlat & { touchedAt?: string }>>) =>
    stream.pipe(
      map((updatedAssets) => {
        const newValue = allAssets.map((asset) => {
          const newValueExist = updatedAssets.find((updatedAsset) => updatedAsset?.uuid === asset?.props?.uuid);
          if (newValueExist) {
            asset.updateProps(newValueExist);
          }
          return asset;
        });
        return newValue;
      }),
    );
};
