<div *ngIf="control.invalid && (control.dirty || control.touched)">
  <mat-error class="error-wrapper">
    <mat-icon aria-hidden="false">error_outline</mat-icon>
    <span *ngIf="control?.errors?.required" class="error"> This field is required </span>
    <span *ngIf="control?.errors?.emailPattern && !control?.errors?.required" class="error">
      This field should be a valid email address
    </span>
    <span *ngIf="control?.errors?.min; let minError"> Minimum required number is {{ minError.min }} </span>
    <span *ngIf="control?.errors?.max; let maxError"> Maximum number can be {{ maxError.max }} </span>
    <span *ngIf="control?.errors?.minlength; let minLengthError">
      Minimum required length is {{ minLengthError.requiredLength }}
    </span>
    <span *ngIf="control?.errors?.maxlength; let maxLengthError">
      Maximum length is {{ maxLengthError.requiredLength }}
    </span>
    <span *ngIf="control?.errors?.emailExist"> Email already exists </span>
  </mat-error>
</div>
