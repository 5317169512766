import { APP_INITIALIZER } from '@angular/core';
import { AppearanceInitializer } from './appearance-initializer';
import { SubAppName } from '@vdms-hq/firebase-contract';

export function configureClientAppearance(subApp: SubAppName = 'storefront') {
  return {
    provide: APP_INITIALIZER,
    useFactory: (appearance: AppearanceInitializer) => () => appearance.initialize(subApp),
    multi: true,
    deps: [AppearanceInitializer],
  };
}
