import { createAction, props } from '@ngrx/store';
import {
  MediaInsightsWorkflowParamsRequest,
  MediaInsightsWorkflowParamsResponse,
  MediaInsightsWorkflowStatus,
} from '@vdms-hq/api-contract';

const ActionPrefix = '[MEDIA INSIGHTS] ';

export const MIAction = {
  CLEAR_MEDIA_INSIGHTS_STATE: ActionPrefix + 'Clear Media Insights Engine state',

  RUN_MEDIA_INSIGHTS_TRIGGER: ActionPrefix + 'Run Media Insights Engine trigger',
  RUN_MEDIA_INSIGHTS_WORKFLOW: ActionPrefix + 'Run Media Insights Engine workflow',
  RUN_MEDIA_INSIGHTS_WORKFLOW_SUCCESS: ActionPrefix + 'Run Media Insights Engine workflow success',
  RUN_MEDIA_INSIGHTS_WORKFLOW_FAIL: ActionPrefix + 'Run Media Insights Engine workflow fail',

  CHECK_MEDIA_INSIGHTS_STATUS_WORKFLOW: ActionPrefix + 'Check Media Insights Engine workflow status',
  CHECK_MEDIA_INSIGHTS_WORKFLOW_STATUS_SUCCESS: ActionPrefix + 'Check Media Insights Engine workflow status success',
  CHECK_MEDIA_INSIGHTS_WORKFLOW_STATUS_FAIL: ActionPrefix + 'Check Media Insights Engine workflow status fail',

  MEDIA_INSIGHTS_WORKFLOW_START_SUCCESS: ActionPrefix + 'Media Insights Engine workflow start success',
  MEDIA_INSIGHTS_WORKFLOW_START_FAIL: ActionPrefix + 'Media Insights Engine workflow start fail',

  ABORT_DIALOG: ActionPrefix + 'Abort dialog',
};

export const clearMediaInsightsState = createAction(MIAction.CLEAR_MEDIA_INSIGHTS_STATE);

export const runMediaInsightsTrigger = createAction(
  MIAction.RUN_MEDIA_INSIGHTS_TRIGGER,
  props<{ assetUuid: string }>(),
);

export const runMediaInsightsWorkflow = createAction(
  MIAction.RUN_MEDIA_INSIGHTS_WORKFLOW,
  props<{ assetUuid: string; payload: MediaInsightsWorkflowParamsRequest }>(),
);
export const runMediaInsightsWorkflowSuccess = createAction(
  MIAction.RUN_MEDIA_INSIGHTS_WORKFLOW_SUCCESS,
  props<{ response: MediaInsightsWorkflowParamsResponse }>(),
);
export const runMediaInsightsWorkflowFail = createAction(
  MIAction.RUN_MEDIA_INSIGHTS_WORKFLOW_FAIL,
  props<{ error: Error }>(),
);

export const checkMediaInsightsWorkflowStatus = createAction(
  MIAction.CHECK_MEDIA_INSIGHTS_STATUS_WORKFLOW,
  props<{ assetUuid: string; workflowUuid: string; operator: string }>(),
);
export const checkMediaInsightsWorkflowStatusSuccess = createAction(
  MIAction.CHECK_MEDIA_INSIGHTS_WORKFLOW_STATUS_SUCCESS,
  props<{ workflow: MediaInsightsWorkflowStatus; language: string }>(),
);
export const checkMediaInsightsWorkflowStatusFail = createAction(
  MIAction.CHECK_MEDIA_INSIGHTS_WORKFLOW_STATUS_FAIL,
  props<{ error: Error }>(),
);

export const mediaInsightsWorkflowStartSuccess = createAction(MIAction.MEDIA_INSIGHTS_WORKFLOW_START_SUCCESS);
export const mediaInsightsWorkflowStartFail = createAction(MIAction.MEDIA_INSIGHTS_WORKFLOW_START_FAIL);
export const abortDialog = createAction(MIAction.ABORT_DIALOG);
