import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@vdms-hq/auth';
import { ColumnSettingsScope, UserContractService } from '@vdms-hq/firebase-contract';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { DialogResponse, filterEmpty, SelectOption, SelectOptionKey } from '@vdms-hq/shared';
import { combineLatest, Observable, of } from 'rxjs';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { ColumnsConfigDialogComponent } from '../components/columns-config-dialog/columns-config-dialog.component';
import {
  defaultOrdersColumns,
  defaultSalesForceOrdersColumns,
  ordersColumns,
} from '../../details/logic/view-configurations';
import { DataValueColumn } from '@vdms-hq/ui';
import { TranslateService } from '@ngx-translate/core';
import { ColumnsFetcherService } from '@vdms-hq/asset-results';

@Injectable({ providedIn: 'root' })
export class ColumnsConfigService {
  columnDefinitions: SelectOption[] = ordersColumns.map((item) => ({
    key: item.id,
    label: this.translateService.instant((item as DataValueColumn).label),
  }));

  columnDefinitionsForOrderSettings$: Observable<SelectOption[]> = of([
    {
      key: 'actions',
      label: this.translateService.instant('common.global.actions'),
    },
    ...this.columnDefinitions,
    {
      key: 'select',
      label: this.translateService.instant('common.global.select'),
    },
  ]);

  getEnabledColumns$ = (scopeName: ColumnSettingsScope) =>
    combineLatest([this.activatedClient.clientDefinite$, this.columnsFetcherService.userConfig$(scopeName)]).pipe(
      map(([client, userConfig]) => {
        const defaultColumns = client.integrations.salesforce ? defaultSalesForceOrdersColumns : defaultOrdersColumns;
        return userConfig.length > 0 ? userConfig : defaultColumns ?? [];
      }),
    );

  constructor(
    public readonly matDialog: MatDialog,
    private authService: AuthService,
    private activatedClient: ActivatedClientService,
    private userContractService: UserContractService,
    private translateService: TranslateService,
    private columnsFetcherService: ColumnsFetcherService,
  ) {}

  popColumnsConfigDialog(scopeName: ColumnSettingsScope) {
    const dialogRef = this.matDialog.open(ColumnsConfigDialogComponent, { data: { scopeName, hiddenColumns: [] } });
    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((data: { status: number; columns: SelectOptionKey[] }) => !!data && data.status === DialogResponse.OK),
        map((data: { columns: SelectOptionKey[] }) => data.columns),
        switchMap((columns: SelectOptionKey[]) => this.update(columns, scopeName)),
      )
      .subscribe({
        error: (err) => {
          console.error(err);
        },
      });
  }

  update(columnsConfig: SelectOptionKey[], scopeName: ColumnSettingsScope): Observable<void> {
    return this.authService.auth$.pipe(
      filterEmpty(),
      take(1),
      withLatestFrom(this.activatedClient.clientDefinite$),
      switchMap(([user, client]) => {
        const clientLevel = client.columnsConfig.default;
        const userLevel = columnsConfig;
        const sameConfig =
          clientLevel.length === userLevel.length && clientLevel.every((value, index) => value === userLevel[index]);

        return this.userContractService.updateClient(user.email, client.uuid, {
          columnsConfig: {
            [scopeName]: sameConfig ? [] : (userLevel as string[]),
          },
        });
      }),
    );
  }
}
