import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActionContextLess,
  DataPresentationHeaderComponent,
  SidebarItem,
  SidebarNestedItem,
  UILayoutModule,
  UILoaderModule,
} from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { RELEASE_NOTES_ROUTER_BASE } from '../release-notes-routes';
import { ReleaseNotesDsService } from '../../logic/release-notes-ds.service';
import { filterEmpty } from '@vdms-hq/shared';
import { SingleReleaseNoteDsService } from '../../logic/single-release-note.ds.service';

@Component({
  selector: 'vdms-hq-release-notes-wrapper',
  standalone: true,
  imports: [
    CommonModule,
    DataPresentationHeaderComponent,
    TranslateModule,
    RouterOutlet,
    UILayoutModule,
    UILoaderModule,
  ],
  templateUrl: './release-notes-wrapper.component.html',
  styleUrls: ['./release-notes-wrapper.component.scss'],
})
export class ReleaseNotesWrapperComponent implements OnInit, OnDestroy {
  router = inject(Router);
  releaseNotesService = inject(ReleaseNotesDsService);
  singleReleaseNoteDS = inject(SingleReleaseNoteDsService);

  destroyed$ = new Subject<void>();
  isFetching$ = this.releaseNotesService.isLoading$;
  showLoadMore$ = this.releaseNotesService.showLoadMore$;
  navItems: (SidebarItem | SidebarNestedItem)[] = [];
  actions: ActionContextLess[] = [
    {
      key: 'release-notes-archived',
      label: 'Archived releases',
      color: 'secondary',
    },
  ];
  actionButton: { label: string; key: string } = { label: 'Load more', key: 'release.more' };

  ngOnInit(): void {
    this.setNavItems();
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const releaseNo = event.url.split('/')[2].replace(/-/g, '.');
        if (!releaseNo) return;
        this.releaseNotesService.currentReleaseNo$.next(releaseNo);
        this.singleReleaseNoteDS.refresh$.next(new Date());
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$?.next();
    this.destroyed$?.complete();
  }

  setNavItems(): void {
    this.releaseNotesService.allData$.pipe(takeUntil(this.destroyed$), filterEmpty()).subscribe((data) => {
      this.navItems = data.map((release) => ({
        name: `VidaOS ${release.tag_name}`,
        additionalInfo: new Date(release.created_at).toLocaleDateString(),
        routerLink: `/${RELEASE_NOTES_ROUTER_BASE.ROOT}/${release.tag_name.replace(/\./g, '-')}`,
      }));
      if (this.navItems.length === 0) return;
      this.router.navigate([this.navItems[0].routerLink]);
    });
  }

  onAction($event: { key: string }) {
    this.router.navigate([$event.key]);
  }

  loadMore() {
    this.releaseNotesService.loadMore();
  }
}
