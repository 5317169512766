import { SelectOptionKey } from '@vdms-hq/shared';

export const calculateColumn = (item: SelectOptionKey, items: SelectOptionKey[], columns = 3) => {
  const perColumn = Math.ceil(items.length / columns);

  const index = items.indexOf(item);

  if (index === -1) {
    return null;
  }

  return Math.floor(index / perColumn);
};
