import { createAction, props } from '@ngrx/store';

const AuthPrefix = '[CORE] ';

const AutoloadAction = {
  AUTH_LOGIN: AuthPrefix + 'Login',
  AUTH_LOGOUT: AuthPrefix + 'Logout',
  CLIENT_CHANGE: AuthPrefix + 'Change Client',
};

export const login = createAction(AutoloadAction.AUTH_LOGIN, props<{ id: string }>());
export const logout = createAction(AutoloadAction.AUTH_LOGOUT);

export const clientChange = createAction(AutoloadAction.CLIENT_CHANGE, props<{ id: string | null | undefined }>());
