import { createReducer, on } from '@ngrx/store';
import * as MIActions from '../actions/media-insights.action';
import { MediaInsightsWorkflowStatus } from '@vdms-hq/api-contract';

export interface MediaInsightsState extends MediaInsightsWorkflowStatus {
  loading: boolean;
  language: string;
}

export const initialState: MediaInsightsState = {
  workflow_uuid: null,
  status: null,
  metadata: null,
  vtt: null,
  language: null,
  loading: false,
};

export const MediaInsightsReducer = createReducer<MediaInsightsState>(
  initialState,
  on(MIActions.checkMediaInsightsWorkflowStatus, (state) => ({
    ...state,
    loading: true,
  })),
  on(MIActions.checkMediaInsightsWorkflowStatusSuccess, (state, { workflow, language }) => ({
    ...state,
    workflow_uuid: workflow.workflow_uuid,
    status: workflow.status,
    metadata: workflow.metadata,
    vtt: workflow.vtt,
    language: language,
    loading: false,
  })),
  on(MIActions.checkMediaInsightsWorkflowStatusFail, (state) => ({
    ...state,
    loading: false,
  })),
  on(MIActions.clearMediaInsightsState, () => ({
    ...initialState,
  })),
);
