import { Pipe, PipeTransform } from '@angular/core';
import { DeliveryDestinationConfig, DeliveryDestinationJobStatusEnum } from '@vdms-hq/api-contract';
import { OrderAssetViewModel } from '../../logic/models';
import { isEmbargoActive } from '@vdms-hq/shared';

@Pipe({
  name: 'canRefreshDelivery',
  pure: true,
  standalone: true,
})
export class RefreshDeliveryDestinationPipe implements PipeTransform {
  transform(config: DeliveryDestinationConfig, assets?: OrderAssetViewModel[]): boolean {
    const isEmbargoed = assets?.find(({ embargoed_to }) => isEmbargoActive(embargoed_to));
    if (isEmbargoed) {
      return true;
    }

    const { jobs } = config;
    return (
      jobs.every(({ status }) => status === DeliveryDestinationJobStatusEnum.COMPLETED) ||
      jobs.some(({ status }) => status === DeliveryDestinationJobStatusEnum.NOT_INITIALIZED)
    );
  }
}
