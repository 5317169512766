<div class="multi-data-container">
  <vdms-hq-ui-data-presentation-header
    (action)="handleAction({ id: $event.key })"
    [actions]="(dataSource.total$ | async) !== 0 ? (headerActions$ | async) : []"
    [title]="'pages.home.menu.cart' | translate"
    [counterIndicator]="dataSource.total$ | async"
  >
  </vdms-hq-ui-data-presentation-header>

  <div class="checkout">
    <div class="checkout-details">
      <vdms-hq-asset-results-2
        [dataSource]="dataSource"
        [actions]="actions$ | async"
        [contextActions]="assetResultsMenuConfig2"
        [scopeName]="scopeName"
        (action)="handleAction({ id: $event.key, item: $event.item })"
      ></vdms-hq-asset-results-2>
    </div>

    <vdms-hq-cart-summary
      *ngIf="(dataSource.total$ | async) !== 0"
      [config]="actionsConfig$ | async"
      (actions)="handleAction({ id: $event })"
    ></vdms-hq-cart-summary>
  </div>

  <vdms-hq-cart-results-actions
    *ngIf="(dataSource?.selection.total$ | async) > 0"
    [selectionManager]="dataSource.selection"
    (actions)="handleAction({ id: $event })"
  ></vdms-hq-cart-results-actions>
</div>
