import { Injectable } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { distinct } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UILoaderService {
  #fullscreenLoaderActive$ = new BehaviorSubject(false);
  fullscreenLoaderActive$ = from(this.#fullscreenLoaderActive$).pipe(distinct());

  setFullscreenLoaderActive(value: boolean) {
    this.#fullscreenLoaderActive$.next(value);
  }
}
