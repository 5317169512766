import { Injectable } from '@angular/core';
import { UIDataSelectorDataSource } from '@vdms-hq/ui';
import { UserApiService, UserModel } from '@vdms-hq/api-contract';
import { BehaviorSubject, Observable, switchMap, tap, withLatestFrom, combineLatest, of, debounceTime } from 'rxjs';
import { PageEvent, RefreshService, SelectionIdentifier, SelectionManager, SelectOption } from '@vdms-hq/shared';
import { map, shareReplay } from 'rxjs/operators';
import { transformUserToSelectOption } from './user-transformer';

@Injectable({ providedIn: 'root' })
export class UsersShareDataSource extends RefreshService implements UIDataSelectorDataSource {
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  listType: 'share' | 'all' = 'share';

  text$ = new BehaviorSubject<string>('');
  pageIndex$ = new BehaviorSubject<number>(0);
  pageSize$ = new BehaviorSubject<number>(24);
  pagination$ = new BehaviorSubject<{ page: string; perPage: string } | null>({
    page: '0',
    perPage: '24',
  });
  sorting$ = new BehaviorSubject<{ orderBy: string; orderDir: string } | null>({
    orderBy: 'user.displayName',
    orderDir: 'ASC',
  });
  pageSizeOptions: number[] = [24];
  total$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  emptyResults$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectedUsers$ = new BehaviorSubject<SelectOption<SelectionIdentifier>[] | null>(null);
  selection: SelectionManager<SelectOption<SelectionIdentifier>> = new SelectionManager(this, (item) => item.key);
  allData$: Observable<SelectOption<SelectionIdentifier>[]> = combineLatest([this.text$, this.refresh$]).pipe(
    debounceTime(100),
    withLatestFrom(this.pagination$, this.sorting$, this.selectedUsers$),
    tap(() => this.isLoading$.next(true)),
    switchMap(([[text], pagination, sorting, selectedUsersList]) => {
      if (selectedUsersList) {
        return of({
          data: <SelectOption<SelectionIdentifier>[]>selectedUsersList,
          total: selectedUsersList?.length,
        });
      }

      switch (this.listType) {
        case 'all':
          return this.userApiService.getPaginatedUsers({ ...pagination, text }, sorting?.orderBy, sorting?.orderDir);
        case 'share':
        default:
          return this.userApiService.getPaginatedUsersShare(
            { ...pagination, text },
            sorting?.orderBy,
            sorting?.orderDir,
          );
      }
    }),
    tap((response) => {
      this.isLoading$.next(false);
      !response && this.emptyResults$.next(true);
      response.total && this.total$.next(response.total);
    }),
    map((response) =>
      response.data.map((user) => {
        return this.#isSelectOption(user)
          ? <SelectOption<SelectionIdentifier>>user
          : transformUserToSelectOption(user as UserModel);
      }),
    ),
  );
  connection$: Observable<SelectOption<SelectionIdentifier>[]> = this.allData$.pipe(shareReplay());

  constructor(private readonly userApiService: UserApiService) {
    super();
  }

  pageChange(event: PageEvent) {
    const pagination = {
      perPage: event?.pageSize.toString(),
      page: event?.pageIndex.toString(),
    };
    this.pageIndex$.next(event?.pageIndex);
    this.pageSize$.next(event?.pageSize);
    this.pagination$.next(pagination);
    this.text$.next(this.text$.value);
  }

  applyFilter(value: string) {
    if (value?.length < 3 && value?.length !== 0) {
      return;
    } else if (value?.length === 0) {
      this.text$.next('');
    } else {
      this.text$.next(value);
    }
    this.pagination$.next({ page: '0', perPage: this.pageSize$.value.toString() });
    this.pageIndex$.next(0);
    this.pageSize$.next(this.pageSize$.value);
  }

  #isSelectOption = (option: any) => 'key' in option && 'label' in option;
}
