import { HttpRequest } from '@angular/common/http';

const CacheBustingTriggers = ['POST', 'PATCH', 'DELETE'];
export class CacheControlState {
  static lastCacheBustTriggeringRequests: string[] = [];
  static lastRequest: { [p: string]: string } = {};
  static readonly key = 'cache-control';

  static clear(): void {
    localStorage.removeItem(CacheControlState.key);
  }

  static toggle(api: string, cache = false): void {
    const cached = JSON.parse(localStorage.getItem(CacheControlState.key) || '{}');
    cached[api] = cache ? '1' : '0';
    localStorage.setItem(CacheControlState.key, JSON.stringify(cached));
  }

  static isCached(api: string, request: HttpRequest<any>): boolean {
    if (api === '') {
      return false;
    }
    if (request.method !== 'GET') {
      return false;
    }
    if (CacheControlState.lastCacheBustTriggeringRequests.includes(request.url)) {
      CacheControlState.lastCacheBustTriggeringRequests = CacheControlState.lastCacheBustTriggeringRequests.filter(
        (l) => l !== request.url,
      );
      return true;
    }
    const isCached = !!JSON.parse(localStorage.getItem(CacheControlState.key) || '{}')?.[api];
    const lastIsPostOrPatch = CacheBustingTriggers.includes((Object.keys(CacheControlState.lastRequest) || [])[0]);
    return isCached && lastIsPostOrPatch;
  }

  static setLastRequest(request: HttpRequest<any>): void {
    CacheControlState.lastRequest = {
      [request.method]: request.url,
    };
    if (CacheBustingTriggers.includes(request.method)) {
      CacheControlState.lastCacheBustTriggeringRequests.push(request.url);
    }
  }
}
