<vdms-hq-ui-dialog-wrapper>
  <div title>
    <span class="title">{{ 'common.aspera-keys.new_key_dialog.title' | translate }}</span>
  </div>
  <div content>
    <vdms-hq-ui-info-bar *ngIf="error.length" [type]="InfoBarType.ERROR" [message]="error"></vdms-hq-ui-info-bar>

    <ng-container [formGroup]="form">
      <vdms-hq-ui-form-input-select
        formControlName="supplierUuid"
        [label]="'common.aspera-keys.new_key_dialog.supplier' | translate"
        [selectOptions]="suppliers$ | async"
      ></vdms-hq-ui-form-input-select>

      <vdms-hq-ui-form-input-text
        formControlName="name"
        [label]="'common.aspera-keys.new_key_dialog.name' | translate"
      ></vdms-hq-ui-form-input-text>
    </ng-container>

    <div class="aspera-data-wrapper" *ngIf="asperaKeyData">
      <div><span>Uuid: </span> {{ asperaKeyData?.uuid }}</div>
      <div><span>Name: </span> {{ asperaKeyData?.name }}</div>
      <div><span>Key ID: </span> {{ asperaKeyData?.key_id }}</div>
      <div><span>Group UUID: </span> {{ asperaKeyData?.group_uuid }}</div>
      <div><span>Supplier UUID: </span> {{ asperaKeyData?.supplier_uuid }}</div>
      <div><span>Created By: </span> {{ asperaKeyData?.created_by }}</div>
      <div><span>Created At: </span> {{ asperaKeyData?.created_at }}</div>
      <div><span>Facility of Origin: </span> {{ asperaKeyData?.facility_of_origin_name }}</div>
      <div>
        <span>Secret: </span> <span *ngIf="showSecret">{{ asperaKeyData?.secret }}</span
        ><span *ngIf="!showSecret">xxxxxxxxxxxxxxxxx</span>
        <mat-icon (mousedown)="showSecret = true" (mouseup)="showSecret = false">visibility</mat-icon>
      </div>
    </div>
  </div>
  <div footer>
    <vdms-hq-ui-button color="secondary" (click)="close(true)">
      {{ 'common.global.close' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button color="primary" [loading]="loading$ | async" (click)="save()" [disabled]="form.invalid">
      {{ 'common.global.generate' | translate }}
    </vdms-hq-ui-button>
  </div>
</vdms-hq-ui-dialog-wrapper>
