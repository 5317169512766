import { inject, Injectable } from '@angular/core';
import { ToastService } from '@vdms-hq/toast';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
  private toastService = inject(ToastService);

  handleMfaErrors<T>(error: T & { code: string }): T {
    let message = 'Something went wrong, please try again';
    switch (error.code) {
      case 'auth/invalid-verification-code':
        message = 'Verification code is not valid.';
        break;
      case 'auth/code-expired':
        message = `Code expired, try again`;
        break;
      case 'auth/second-factor-already-in-use':
        message = `Phone number is already enrolled on this account.`;
        break;
      case 'auth/requires-recent-login':
        message =
          'This operation is sensitive and requires recent authentication. Log in again before retrying set up this configuration.';
        break;
      case 'auth/invalid-phone-number':
        message = `Phone number has invalid format`;
        break;
      case 'auth/multi-factor-info-not-found':
        message = `Multi factor not found`;
        break;
      case 'auth/unverified-email':
        message = `Email is not verified, please re login using email link to continue`;
        break;
      case 'auth/user-token-expired':
        message = `User token has expired. Log in and try again`;
        break;
    }

    this.toastService.error({
      id: 'auth_error',
      message,
    });

    return error;
  }
}
