import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { SelectOption } from '@vdms-hq/shared';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedClientService, ClientModel } from '@vdms-hq/activated-client';
import { take, takeUntil } from 'rxjs/operators';
import objectPath from 'object-path';
import { Subject } from 'rxjs';
import { SelectedFilesService } from '@vdms-hq/ui';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vdms-hq-import-export-dialog',
  templateUrl: './import-export-dialog.component.html',
  styleUrls: ['./import-export-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportExportDialogComponent implements OnInit, OnDestroy {
  constructor(
    private sanitizer: DomSanitizer,
    private activatedClient: ActivatedClientService,
    private selectedFilesService: SelectedFilesService,
    private dialogRef: MatDialogRef<ImportExportDialogComponent>,
  ) {}

  hasSelected$ = this.selectedFilesService.hasSelected$;
  #destroyed = new Subject<void>();
  loading = false;
  exportPaths = [];
  downloadJsonHref?: SafeUrl;
  filename = 'export.json';
  exportOptions: SelectOption[] = [
    {
      key: 'columnsConfig',
      label: 'Asset Table Columns',
    },
    {
      key: 'assetView',
      label: 'Asset Details',
    },
    {
      key: 'search',
      label: 'Search Filters',
    },
    {
      key: 'vida',
      label: 'Other vida settings',
    },
    {
      key: 'launchpad',
      label: 'Launchpad settings',
    },
    {
      key: 'storefront',
      label: 'Storefront settings',
    },
  ];

  generateJson() {
    this.activatedClient.clientDefinite$.pipe(take(1)).subscribe((client) => {
      const toExport = {};
      this.exportPaths.forEach((path) => {
        objectPath.set(toExport, path, objectPath.get(client, path));
      });

      const theJSON = JSON.stringify(toExport);
      this.downloadJsonHref = this.sanitizer.bypassSecurityTrustUrl(
        'data:text/json;charset=UTF-8,' + encodeURIComponent(theJSON),
      );
    });
  }

  save(client: Partial<ClientModel>) {
    this.loading = true;
    this.activatedClient.update(client).subscribe(() => {
      this.loading = false;
      this.selectedFilesService.clear();
      window.location.reload();
    });
  }

  uploadFile() {
    this.selectedFilesService.selectedFiles$.pipe(take(1)).subscribe((files) => {
      const file = files[0];

      const fileReader = new FileReader();
      fileReader.readAsText(file, 'UTF-8');

      fileReader.onload = () => {
        if (typeof fileReader.result === 'string') {
          this.save(JSON.parse(fileReader.result));
        }
      };
      fileReader.onerror = (error) => {
        console.log(error);
      };
    });
  }

  ngOnInit(): void {
    this.activatedClient.clientDefinite$.pipe(takeUntil(this.#destroyed)).subscribe((client) => {
      this.filename = `Export client settings [${client.name}] ${new Date().toISOString().split('T')[0]}.json`;
    });
  }

  ngOnDestroy(): void {
    this.#destroyed.next();
    this.#destroyed.complete();
  }

  clearFile() {
    this.selectedFilesService.clear();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
