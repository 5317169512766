<mat-toolbar class="menu">
  <span routerLink="misc" routerLinkActive="active">Misc</span>
  <span routerLink="data-presentation" routerLinkActive="active">Data presentation</span>
  <span routerLink="form" routerLinkActive="active">Form elements</span>
  <span routerLink="filters" routerLinkActive="active">Filters</span>
  <span routerLink="preview" routerLinkActive="active">Preview</span>
</mat-toolbar>
<div class="page-view">
  <router-outlet></router-outlet>
</div>
