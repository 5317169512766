import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  QcResultsDialogComponent,
  QCResultsDialogInput,
} from '../components/qc-results-dialog/qc-results-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class QualityControlResultsDialogService {
  constructor(private readonly matDialog: MatDialog) {}

  popQCResultsDialog(assetUuid: string) {
    this.matDialog.open<QcResultsDialogComponent, QCResultsDialogInput>(QcResultsDialogComponent, {
      data: { assetUuid },
    });
  }
}
