export interface LanguageModel {
  uuid: string;
  name: string;
  code: string;
}

export enum APIEndpoints {
  ROOT = 'languages',
  ROOT_PLURAL = 'languages/all',
}
