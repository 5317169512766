import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogResponse } from '../../../shared/logic/models/dialog';
import { SelectorSourceType } from '@vdms-hq/selectors';
import { SelectOption } from '@vdms-hq/shared';
import { ConfigLessComponentType } from '@vdms-hq/firebase-contract';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-run-media-insights-dialog',
  templateUrl: './run-media-insights-dialog.component.html',
})
export class RunMediaInsightsDialogComponent implements OnInit {
  form: UntypedFormGroup;
  sourceType = SelectorSourceType;
  triggerList: SelectOption[] = [];

  constructor(
    public dialogRef: MatDialogRef<RunMediaInsightsDialogComponent>,
    public formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.triggerList = Object.values(ConfigLessComponentType)
      .filter((item) => item.includes('mi_'))
      .map((item) => ({
        key: item,
        label: this.translateService.instant('common.admin.available_components_list.' + item),
      }));
    this.form = this.formBuilder.group({
      triggerList: new UntypedFormControl([], Validators.required),
      sourceLanguage: new UntypedFormControl([], Validators.required),
      targetLanguage: new UntypedFormControl([], Validators.required),
    });
  }

  get sourceSelectedTriggerList() {
    return this.form.get('triggerList');
  }

  get sourceLang() {
    return this.form.get('sourceLanguage');
  }

  get targetLang(): UntypedFormGroup {
    return this.form.get('targetLanguage') as UntypedFormGroup;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.dialogRef.close({
      status: DialogResponse.OK,
      language: {
        source: this.sourceLang.value,
        target: this.targetLang.value,
      },
      triggerList: this.sourceSelectedTriggerList.value,
    });
  }
}
