<div class="thumb-container">
  <img [src]="src" class="thumbnail" [alt]="alt | translate" />
  <div class="thumb-overlay">
    <div class="info">
      <vdms-hq-ui-button (click)="deleteFile.emit()" icon="delete" color="warn"> </vdms-hq-ui-button>
      <vdms-hq-ui-button *ngIf="downloadUrl !== ''" (click)="download()" icon="cloud_download" color="primary">
      </vdms-hq-ui-button>
    </div>
  </div>
</div>
