<mat-form-field
  class="material-custom-field"
  appearance="outline"
  [class.hide-footer]="!withFooter"
  [class.with-touched-indicator]="withTouchedIndicator"
>
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [matDatepicker]="picker"
    [formControl]="innerFormControl"
    (dateInput)="dateChangeFn($event)"
    (dateChange)="dateInputFn($event)"
    [required]="isRequired"
    [min]="minDate"
    [max]="maxDate"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>

  <mat-hint *ngIf="withFooter && hint">{{ hint }}</mat-hint>
  <mat-error *ngIf="withFooter">
    <span *ngFor="let error of errors">{{ error.transLang | translate : error.contextParams }}</span>
  </mat-error>
  <vdms-hq-ui-loader matSuffix [size]="20" *ngIf="innerFormControl.pending"></vdms-hq-ui-loader>
</mat-form-field>
