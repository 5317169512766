import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { delay, filter, map, withLatestFrom } from 'rxjs/operators';
import * as messagesActions from '../actions/message.action';
import { getAllMessagesEntities } from '../reducers/index.reducer';
import { TranslateService } from '@ngx-translate/core';
import { AutoloadEffect } from '@vdms-hq/state';

@Injectable()
export class MessageEffect {
  triggerManageMessage = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof messagesActions.message>>(messagesActions.message),
      withLatestFrom(this.store.select(getAllMessagesEntities)),
      map(([{ data }, entities]) => {
        const object = { ...data };
        if (object.message) {
          object.message = this.translate.instant(data.message, data?.translationContext);
        }

        if (entities[data.id]) {
          return messagesActions.messageUpdate({
            id: data.id,
            data: {
              ...object,
              updatedAt: new Date(),
            },
          });
        }

        return messagesActions.messageAdd({
          data: {
            ...object,
            autoHide: data.autoHide || false,
            createdAt: new Date(),
            updatedAt: new Date(),
          },
        });
      }),
    ),
  );

  message$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof messagesActions.messageAdd>>(messagesActions.messageAdd, messagesActions.messageUpdate),
      filter(({ data }) => data?.autoHide),
      delay(5000),
      map(({ data }) => messagesActions.messageClose({ id: data.id })),
    ),
  );

  triggerReset$ = this.autoloadEffect.whenLogoutOrClientChange(messagesActions.reset);

  constructor(
    private actions$: Actions,
    private store: Store,
    private translate: TranslateService,
    private autoloadEffect: AutoloadEffect,
  ) {}
}
