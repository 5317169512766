import { Component, Input } from '@angular/core';
import {
  AbstractControl,
} from '@angular/forms';

@Component({
  selector: 'vdms-hq-ui-control-state',
  templateUrl: './control-state-demo.component.html',
  styleUrls: ['./control-state-demo.component.scss'],
})
export class ControlStateDemoComponent {
  onlySelf = false;
  @Input() control!: AbstractControl;
}
