import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpHeaders } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

export interface errorData {
  error: {
    data: {
      allowed: string[];
      forbidden: string[];
    };
    error: string;
    status: string;
  };
  headers: HttpHeaders;
  message: string;
  name: string;
  ok: boolean;
  status: number;
  statusText: string;
  url: string;
}

export interface errorColdInput {
  allowedAssets: string[];
  forbiddenAssets: string[];
}

export interface errorColdOutput {
  response: boolean;
}

@Component({
  selector: 'vdms-hq-assets-cold-error-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    UIButtonModule,
    UIDialogWrapperModule,
    FormsModule,
    ReactiveFormsModule,
    UIFormModule,
  ],
  templateUrl: './assets-cold-error-dialog.component.html',
  styleUrls: ['./assets-cold-error-dialog.component.scss'],
})
export class AssetsColdErrorDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AssetsColdErrorDialogComponent, errorColdOutput>,
    @Inject(MAT_DIALOG_DATA) public data: errorColdInput,
  ) {}

  abort() {
    this.dialogRef.close({
      response: false,
    });
  }

  retry() {
    this.dialogRef.close({
      response: true,
    });
  }
}
