import { createAction, props } from '@ngrx/store';
/**
 * @deprecated use toast module instead
 */
export enum MessageType {
  error,
  success,
  info,
}
const MESSAGE_ADD = '[Message] Add';

/**
 * @deprecated use toast module instead
 */
export const messageAdd = createAction(
  MESSAGE_ADD,
  props<{
    message: string;
    messageType: MessageType;
    context?: any;
  }>(),
);
