import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { EmptyResultsComponent } from './empty-results/empty-results.component';
import { MatIconModule } from '@angular/material/icon';
import { UIButtonModule } from '../ui-button';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [EmptyResultsComponent],
  exports: [EmptyResultsComponent],
  imports: [CommonModule, MatIconModule, UIButtonModule, RouterModule, TranslateModule, NgOptimizedImage],
})
export class UIEmptyResultsModule {}
