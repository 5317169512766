<section *ngIf="dataSource" class="filtered-list" [class.with-touched-indicator]="withTouchedIndicator">
  <div class="filtered-list-controls">
    <div class="filtered-list-searching">
      <mat-form-field appearance="outline" class="material-custom-field hide-footer">
        <input
          type="text"
          matInput
          [disabled]="innerFormControl.disabled"
          (keyup)="dataSource.applyFilter($any($event.target).value, ['filterableValue'])"
          [placeholder]="'common.global.search' | translate"
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="filtered-list-sorting" *ngIf="sortingOptions.length">
      <span class="label-left"
        ><span>{{ 'common.global.sort_order' | translate }}</span
        >:</span
      >
      <mat-form-field appearance="standard" class="material-custom-field hide-footer">
        <mat-select [disabled]="innerFormControl.disabled" (selectionChange)="updateSorting($event)" [value]="0">
          <mat-option *ngFor="let option of sortingOptions; let index = index" [value]="index">
            {{ option?.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div>
    <div class="filtered-list-results" *ngIf="dataSource.connection$ | async; let options">
      <ng-container *ngIf="(dataSource.isLoading$ | async) === false">
        <ng-container *ngIf="options.length; else noData">
          <vdms-hq-ui-form-radio-list
            [selectOptions]="options"
            [formControl]="innerFormControl"
            [label]="label"
            [hint]="hint"
            (blur)="propagateTouch()"
            [withFooter]="withFooter"
          >
          </vdms-hq-ui-form-radio-list>
        </ng-container>

        <ng-template #noData>
          <vdms-hq-ui-empty-results type="compact"></vdms-hq-ui-empty-results>
        </ng-template>
      </ng-container>
    </div>
    <vdms-hq-ui-loader
      *ngIf="dataSource.isLoading$ | async"
      [centerPadding]="loaderCenterPadding"
      [text]="loaderText"
    ></vdms-hq-ui-loader>
    <div class="paginator-container">
      <mat-paginator
        [disabled]="innerFormControl.disabled"
        [length]="dataSource.total$ | async"
        [pageSize]="dataSource.pageSize$ | async"
        [pageSizeOptions]="dataSource.pageSizeOptions"
        (page)="dataSource.pageChange($event)"
        aria-label="Select page"
      >
      </mat-paginator>
    </div>
  </div>
</section>
