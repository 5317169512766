import { Injectable } from '@angular/core';
import { TextToSpeechBodyModel, Voice, VoicesResponse } from './models/model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { VOICE_AI_ENDPOINTS } from './models/voice-ai-endpoints.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class VoiceAiApiService {
  constructor(private readonly http: HttpClient) {}

  textToSpeech(
    apiUrl: string,
    apiKey: string,
    voiceId: string,
    body: TextToSpeechBodyModel,
    optimizeStreamingLatency = 0,
  ) {
    const headers: HttpHeaders = new HttpHeaders({
      accept: 'audio/mpeg',
      'content-type': 'application/json',
      'xi-api-key': apiKey,
    });
    return this.http.post(
      `${apiUrl}/${VOICE_AI_ENDPOINTS.TEXT_TO_SPEECH}/${voiceId}?optimize_streaming_latency=${optimizeStreamingLatency}`,
      body,
      { headers, responseType: 'blob' },
    );
  }

  getVoices(apiUrl: string, apiKey: string): Observable<Voice[]> {
    const headers: HttpHeaders = new HttpHeaders({
      accept: 'audio/mpeg',
      'content-type': 'application/json',
      'xi-api-key': apiKey,
    });

    return this.http
      .get<VoicesResponse>(`${apiUrl}/${VOICE_AI_ENDPOINTS.VOICES}`, { headers })
      .pipe(map((response) => response?.voices.sort((a, b) => a.name.localeCompare(b.name))));
  }
}
