import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { ApiResponse } from '../api.model';
import { Observable } from 'rxjs';
import { RatingSystem } from './systems.model';
import { SystemEndpoints } from './endpoints';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { RatingEndpoints } from '../ratings/endpoints';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SystemsService {
  constructor(private readonly apiService: ApiService) {}

  public getSystems(): Observable<RatingSystem[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Cache-Control', 'max-age=0');
    return this.apiService
      .get<ApiResponse<RatingSystem[]>>(`${RatingEndpoints.ROOT}/${SystemEndpoints.ROOT_PLURAL}`, { headers })
      .pipe(GetResponseData);
  }

  public getSystem(id: Pick<RatingSystem, 'uuid'>): Observable<RatingSystem> {
    return this.apiService
      .get<ApiResponse<RatingSystem>>(`${RatingEndpoints.ROOT}/${SystemEndpoints.ROOT}/${id}`)
      .pipe(GetResponseData);
  }

  public deleteSystem(id: string) {
    return this.apiService.delete<ApiResponse<RatingSystem>>(`${RatingEndpoints.ROOT}/${SystemEndpoints.ROOT}/${id}`);
  }

  public postSystem(data: { name: string }): Observable<RatingSystem> {
    return this.apiService
      .post<{ name: string }, ApiResponse<RatingSystem>>(`${RatingEndpoints.ROOT}/${SystemEndpoints.ROOT}`, data)
      .pipe(GetResponseData);
  }

  public patchSystem(id: string, data: { name: string }): Observable<RatingSystem> {
    return this.apiService
      .patch<{ name: string }, ApiResponse<RatingSystem>>(`${RatingEndpoints.ROOT}/${SystemEndpoints.ROOT}/${id}`, data)
      .pipe(GetResponseData);
  }
}
