<vdms-hq-ui-dialog-wrapper>
  <h1 title>Cannot set some assets as Cold Only!</h1>

  <div content class="dialog-content">
    <h2>Assets currently in active orders which can not be set as Cold Only listed below.</h2>
    <span class="forbidden" *ngFor="let forbidden of data.forbiddenAssets">{{ forbidden }}</span>
    <p>If You want to continue with this request but without listed assets, click "Retry" button.</p>
  </div>

  <div footer class="footer buttons-group">
    <vdms-hq-ui-button (click)="abort()" color="secondary">{{
      'common.global.dialog.cancel' | translate
    }}</vdms-hq-ui-button>
    <vdms-hq-ui-button (click)="retry()" color="primary" [isSubmit]="true">{{
      'common.global.dialog.retry' | translate
    }}</vdms-hq-ui-button>
  </div>
</vdms-hq-ui-dialog-wrapper>
