import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-layout-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent {
  @Input() header: string;
  @Input() paddingLess = false;
  @Input() withBackButton = false;
  @Input() fullWidthHeader = false;

  constructor(private location: Location) {}

  back(): void {
    this.location.back();
  }
}
