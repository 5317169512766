import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'vdms-hq-ui-infinity-loader',
  templateUrl: './infinity-loader.component.html',
  styleUrls: ['./infinity-loader.component.scss'],
})
export class InfinityLoaderComponent {
  readonly defaultPerPage = 24;
  @Input() infiniteScrollDistance = 0;
  @Input() isLoading = false;
  @Input() total?: number;
  @Input() loadOnScroll = true;
  @Input() loaded?: number;
  @Input() batchSize?: number;
  @Output() scrolled: EventEmitter<void> = new EventEmitter<void>();
}
