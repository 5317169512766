import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { DELIVERY_DESTINATIONS_ROUTING } from '../../delivery-destinations-routing';
import { DestinationModel } from '@vdms-hq/api-contract';
import { tap } from 'rxjs/operators';
import { DeliveryDestinationView, DestinationDataSourceService } from '../../logic/destination-data-source';

@Component({
  selector: 'vdms-hq-destination-add-config-dialog',
  templateUrl: './destination-add-config-dialog.component.html',
  standalone: true,
  imports: [
    UIDialogWrapperModule,
    TranslateModule,
    UIButtonModule,
    ReactiveFormsModule,
    UIFormModule,
    AsyncPipe,
    UILayoutModule,
  ],
})
export class DestinationAddConfigDialogComponent implements OnInit {
  name = new FormControl<string>('', Validators.required);
  loading$ = new BehaviorSubject(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { currentItem: DeliveryDestinationView | null },
    private ref: MatDialogRef<DestinationAddConfigDialogComponent>,
    private dataSource: DestinationDataSourceService,
  ) {}

  ngOnInit(): void {
    !this.data?.currentItem && this.close();
  }

  save() {
    this.loading$.next(true);
    const { currentItem } = this.data;
    const newConfig = {
      uuid: uuidv4(),
      name: this.name.value as string,
      rules: [],
    };

    const nextValue: DestinationModel = {
      ...currentItem,
      configs: [...(currentItem?.configs || []), newConfig],
    } as DestinationModel;

    this.dataSource
      .update(nextValue, currentItem?.uuid)
      .pipe(
        tap(() => {
          this.loading$.next(false);
          this.dataSource.setId(currentItem?.uuid as string);
          this.ref.close(['/', DELIVERY_DESTINATIONS_ROUTING.ROOT, currentItem?.uuid, newConfig?.uuid]);
        }),
      )
      .subscribe();
  }

  close() {
    const { currentItem } = this.data;
    if (currentItem?.uuid) {
      this.ref.close(['/', DELIVERY_DESTINATIONS_ROUTING.ROOT, currentItem?.uuid]);
    } else {
      this.ref.close(['/', DELIVERY_DESTINATIONS_ROUTING.ROOT]);
    }
  }
}
