import { Pipe, PipeTransform } from '@angular/core';
import { Action } from '../logic/common-config';

@Pipe({
  name: 'ifHiddenControls',
  standalone: true,
})
export class IfHiddenControlsPipe<T> implements PipeTransform {
  transform(actions?: Action<T>[], item?: T): boolean {
    if (!actions?.length) {
      return false;
    }

    const allElementsHidden = actions.every((action) => (action.hiddenIf ? action.hiddenIf(item) : false));
    return !allElementsHidden;
  }
}
