import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIButtonModule, UIEmptyResultsModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { switchMap, take } from 'rxjs';
import { DestinationDataSourceService } from '../../logic/destination-data-source';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DestinationAddConfigDialogComponent } from '../destination-add-config-dialog/destination-add-config-dialog.component';

@Component({
  selector: 'vdms-hq-destination-add-config',
  template: '',
  imports: [
    CommonModule,
    UILayoutModule,
    TranslateModule,
    UIEmptyResultsModule,
    ReactiveFormsModule,
    UIFormModule,
    UIButtonModule,
  ],
  standalone: true,
})
export class DestinationAddConfigComponent implements OnInit {
  constructor(private dataSource: DestinationDataSourceService, private router: Router, private dialog: MatDialog) {}

  ngOnInit() {
    this.#openNewConfigDialog();
  }

  #openNewConfigDialog() {
    this.dataSource.currentItem$
      .pipe(
        take(1),
        switchMap((currentItem) =>
          this.dialog
            .open(DestinationAddConfigDialogComponent, { disableClose: true, data: { currentItem } })
            .afterClosed(),
        ),
      )
      .subscribe((url) => this.router.navigate(url));
  }
}
