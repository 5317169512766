import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { filterEmptyArray } from '@vdms-hq/shared';
import { of, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UploadContext } from '../../logic/model/context.interface';
import { AssetUploadService } from '../../logic/services/asset-upload.service';
import { AsperaTransferBatch } from '../../logic/aspera/value-object/aspera-batch';
import { AssetApiService } from '@vdms-hq/api-contract';
import { TransferableBatch } from '../../logic/model/transferable-file.interface';

export const ProgressVisibilityEnum = {
  ALL: 'all',
  COMPACT: 'compact',
  HIDDEN: 'hidden',
};

type ValueOf<T> = T[keyof T];
export type ProgressVisibility = ValueOf<typeof ProgressVisibilityEnum>;

@Component({
  selector: 'vdms-hq-storage-upload-container',
  templateUrl: './upload-container.component.html',
  styleUrls: ['./upload-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadContainerComponent implements OnInit, OnDestroy {
  protected readonly ProgressVisibilityEnum = ProgressVisibilityEnum;

  @Input() size?: 'compact';
  @Input() buttonSize?: 'small' | 'medium';
  @Input() progressVisibility: ProgressVisibility = ProgressVisibilityEnum.ALL;

  @Input() multiple = true;
  @Input() withDropzone = true;
  @Input() autoUpload = true;
  @Input() clearSelectedOnDone = false;
  @Input() imageUrl = 'assets/storage/icons/aspera-upload.png';
  @Input() context?: UploadContext;
  @Input() customText?: string;

  @Output() singleUploadCompleted = new EventEmitter();
  @Output() uploadCompleted = new EventEmitter();
  @Output() countSelected = new EventEmitter<number>();
  @Output() currentBatch = new EventEmitter<AsperaTransferBatch[]>();
  countSelected$ = this.assetUploadService.selected$.pipe(
    map((selected) => selected.length),
    tap((count) => {
      this.countSelected.emit(count);
    }),
  );
  private destroyed$ = new Subject<void>();

  constructor(public assetUploadService: AssetUploadService, private assetApiService: AssetApiService) {}

  ngOnInit(): void {
    this.assetUploadService.initializeView(
      {
        draggablePlaceholderCss: this.withDropzone ? '.upload-dropzone' : undefined,
        allowMultipleSelection: this.multiple,
        autoUpload: this.autoUpload,
        clearSelectedOnDone: this.clearSelectedOnDone,
      },
      this.context,
      (batches: AsperaTransferBatch[]) => this.currentBatch?.emit(batches),
    );

    this.assetUploadService.selected$
      .pipe(
        takeUntil(this.destroyed$),
        filterEmptyArray(),
        switchMap((selected) => {
          if (this.multiple) {
            return of(selected.length === 0 ? 'done' : null);
          } else {
            return selected[0]?.state$.pipe(map((state) => state.status)) ?? of(null);
          }
        }),
      )
      .subscribe((status) => {
        if (status === 'done') {
          this.multiple ? this.uploadCompleted.next(true) : this.singleUploadCompleted.next(true);
        }
      });
  }

  selectFiles() {
    this.assetUploadService.selectFiles(this.context);
  }

  deleteTransfer(item: TransferableBatch) {
    this.assetApiService
      .deleteAssets({ deleteReason: 'Aspera upload canceled', deletionBillable: false, assetUuids: [item.files[0].id] })
      .pipe(switchMap(() => this.assetUploadService.deleteFromTransfer(item)))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.assetUploadService.destroy();
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
