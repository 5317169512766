<div class="accordion-item">
  <div class="accordion-item__header">
    <span class="accordion-item__label" (click)="toggleVisibility()">{{ label }}</span>
    <span
      *ngIf="wrappedFormControl && !(wrappedFormControl?.value | vdmsHqIsEmpty)"
      (click)="wrappedFormControl.reset()"
      >Clear</span
    >
    <mat-icon class="accordion-item__action" (click)="toggleVisibility()">{{
      visible ? 'expand_less' : 'expand_more'
    }}</mat-icon>
  </div>
  <div
    class="accordion-item__content"
    *ngIf="visible"
    [@enterAnimation]
    [class.accordion-item__content--collapsed]="optionsLength > NO_EXPANDED_MAX_ELEMENTS && !expanded"
  >
    <ng-content></ng-content>

    <span *ngIf="optionsLength > NO_EXPANDED_MAX_ELEMENTS" class="accordion-item__more" (click)="toggleMore()">{{
      expanded ? 'show less' : 'show more'
    }}</span>
  </div>
</div>
