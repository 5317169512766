import { QUALITY_CONTROL_ROUTES } from '@vdms-hq/quality-control';
import { MetadataSnapshotsPageComponent } from './pages/metadata-snapshots-page.component';
import { Routes } from '@angular/router';

export enum METADATA_SNAPSHOTS_ROUTES {
  ROOT = 'metadata-snapshots',
}

export const METADATA_SNAPSHOTS_ROUTING: Routes = [
  {
    path: '',
    component: MetadataSnapshotsPageComponent,
  },
  {
    path: '**',
    redirectTo: `/${QUALITY_CONTROL_ROUTES}`,
    pathMatch: 'full',
  },
];
