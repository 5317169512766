<vdms-hq-ui-dialog-wrapper>
  <div title>
    <span class="title">{{ 'common.mandatory-fields.title' | translate }}</span>
  </div>
  <div content [formGroup]="form">
    <vdms-hq-ui-loader [center]="true" [backdrop]="true" *ngIf="loading$ | async; else formRef"></vdms-hq-ui-loader>
    <ng-template #formRef>
      <vdms-hq-ui-form-input-select
        formControlName="content_type"
        [label]="'common.mandatory-fields.modal_fields.content_type' | translate"
        [selectOptions]="contentTypes$ | async"
      ></vdms-hq-ui-form-input-select>

      <ng-container formArrayName="fields">
        <div class="form-field" *ngFor="let control of formArray.controls; let arrayIndex = index">
          <vdms-hq-ui-form-input-select
            [formControlName]="arrayIndex"
            [label]="('common.mandatory-fields.modal_fields.field' | translate) + ' #' + (arrayIndex + 1)"
            [selectOptions]="allPossibleFieldsSelectOptions$ | async"
          ></vdms-hq-ui-form-input-select>
          <vdms-hq-ui-button [iconOnly]="true" icon="remove" (click)="removeField(arrayIndex)"></vdms-hq-ui-button>
        </div>
      </ng-container>

      <vdms-hq-ui-button color="secondary" (click)="addField()">{{
        'common.mandatory-fields.add_field' | translate
      }}</vdms-hq-ui-button>
    </ng-template>
  </div>
  <div footer>
    <vdms-hq-ui-button color="secondary" (click)="close()">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button color="primary" [loading]="loading$ | async" (click)="save()" [disabled]="form.invalid">
      {{ 'common.global.save' | translate }}
    </vdms-hq-ui-button>
  </div>
</vdms-hq-ui-dialog-wrapper>
