import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UIButtonModule, UIDataSelectorComponent, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { UsersSelectorComponent } from '@vdms-hq/users';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotificationSubscriptionsService, UserApiService } from '@vdms-hq/api-contract';
import { BehaviorSubject, catchError, debounceTime, map, switchMap, throwError } from 'rxjs';
import { emailsArrayValidator } from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import { HttpErrorResponse } from '@angular/common/http';
import { TRIGGER_NOTIFICATIONS_ERROR_REASONS } from '../../logic/trigger-notifications';

@Component({
  selector: 'vdms-hq-trigger-notifications-dialog',
  templateUrl: './trigger-notifications-dialog.component.html',
  styleUrls: ['./trigger-notifications-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    UIButtonModule,
    UIDialogWrapperModule,
    UIFormModule,
    UsersSelectorComponent,
    UIDataSelectorComponent,
    MatTooltipModule,
  ],
})
export class TriggerNotificationsDialogComponent {
  #ref = inject(MatDialogRef<TriggerNotificationsDialogComponent>);
  #userApiService = inject(UserApiService);
  #notificationsService = inject(NotificationSubscriptionsService);
  #inputText = new BehaviorSubject('');
  #dialogData: { packageUuid: string; assetUuids: string[] } = inject(MAT_DIALOG_DATA);
  #translate = inject(TranslateService);
  #toast = inject(ToastService);

  loading$ = new BehaviorSubject<boolean>(false);
  emailOptions$ = this.#inputText.pipe(
    debounceTime(400),
    switchMap((text) => {
      return this.#userApiService
        .getPaginatedUsersShare({ text })
        .pipe(map((response) => response.data.map(({ email }) => ({ key: email, label: email }))));
    }),
  );
  form = new FormControl<string[] | null>(null, [Validators.required, emailsArrayValidator(true)]);

  close() {
    this.#ref.close();
  }

  confirm() {
    if (this.form.invalid) {
      return;
    }

    const { packageUuid, assetUuids } = this.#dialogData;
    this.loading$.next(true);
    this.#notificationsService
      .triggerNotifications(packageUuid, { assetUuids, emails: this.form.value as string[] })
      .pipe(
        catchError(
          (err: {
            error: HttpErrorResponse & { data: Array<{ email: string; reason: TRIGGER_NOTIFICATIONS_ERROR_REASONS }> };
          }) => {
            const message =
              err.error.error +
              '<br>' +
              err.error.data
                .map(
                  (item) =>
                    item.email +
                    ' - ' +
                    this.#translate.instant('common.notification_subscriptions.trigger.' + item.reason.toLowerCase()),
                )
                .join('<br>');
            this.#toast.error({ id: 'trigger', message });
            this.form.markAsPristine();
            this.loading$.next(false);
            return throwError(() => err);
          },
        ),
      )
      .subscribe({
        next: () => {
          this.#ref.close();
          this.loading$.next(false);
          this.#toast.success({ id: 'trigger', message: 'common.notification_subscriptions.trigger.trigger_success' });
        },
      });
  }

  valueChange(text: string) {
    this.#inputText.next(text ?? '');
  }
}
