import { Routes } from '@angular/router';
import { QuarantineAdminComponent } from './quarantine-admin.component';

export enum QUARANTINE_ROUTES {
  ROOT = 'quarantine',
}

export const QUARANTINE_ROUTING: Routes = [
  {
    path: '',
    component: QuarantineAdminComponent,
  },
  {
    path: '**',
    redirectTo: `/${QUARANTINE_ROUTES.ROOT}`,
    pathMatch: 'full',
  },
];
