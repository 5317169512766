import { Injectable } from '@angular/core';
import { LambdaResponse } from '../../shared/logic/models/lambda';
import { environment } from '../../../environments/environment';
import { LanguageModel } from './models/language';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable()
export class DataProviderService {
  private languages: ReplaySubject<LanguageModel[]> = new ReplaySubject(1);
  private initialized = false;

  constructor(private http: HttpClient) {}

  public connect(): Observable<LanguageModel[]> {
    if (!this.initialized) {
      this.initialized = true;

      this.fetch()
        .pipe(first())
        .subscribe((response) => this.languages.next(response.data));
    }

    return this.languages;
  }

  private fetch() {
    return this.http.get<LambdaResponse<LanguageModel[]>>(`${environment.apiUrl}/languages`);
  }
}
