export const isApplicableUrl = (apiUrl: string, testedUrl: string, omitPrefixes: string[]): boolean => {
  if (!apiUrl) {
    console.warn('Missing apiUrl configuration for interceptor');
    return false;
  }

  if (!testedUrl.startsWith(apiUrl)) {
    return false;
  }

  return omitPrefixes.every((prefix) => !testedUrl.includes(prefix));
}
