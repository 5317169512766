import { AssetFlat } from '../../asset';
import { GroupCurrencyISO } from '@vdms-hq/shared';

export interface InvalidCartAsset {
  assetUuid: string;
  assetOriginalFilename: string;
  cartItemUuids: string[];
  invalidTypes: string[];
}

export interface AssetFlatCartItem {
  uuid: string;
  timecode_in: null | string;
  timecode_out: null | string;
  note: string;
  asset: AssetFlat;
}

export interface LibraryItem {
  uuid: string;
}

export interface Counters {
  added?: number;
  not_added?: number;
  total: number;
}

export enum DISCOUNT_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  WRONG = 'wrong',
  'NONE' = 'none',
}

export interface CartSummary {
  total: number;
  cold: number;
  price: {
    egress?: string;
    restore?: string;
    processing?: string;
    transcode?: string;
    proxy_transcode?: string;
    total?: string;
  };
  discount_status: DISCOUNT_STATUS;
  discount_prices: {
    core?: string;
    egress?: string;
    restore?: string;
    processing?: string;
    transcode?: string;
    proxy_transcode?: string;
    total?: string;
  };
  settings: {
    currency: GroupCurrencyISO;
  };
}

export interface CartSummaryRequest {
  discount_code?: string;
  destinations?: { uuid: any; configUuid: any }[];
}

export interface OrderPdfResponse {
  url: string;
  discount_status: DISCOUNT_STATUS;
  discount_prices?: {
    price_per_gb: number;
    total_price: string;
  };
}

export interface OrderPostModel {
  uuid: string;
  destinations: {
    uuid: string | null;
    config_uuid: string | null;
  }[];
  subject: string;
  package_title: string;
  purchase_order_no: string;
  delivery_date: string;
  delivery_emails: string[];
}

export interface OrderPostResponse {
  status: string;
  data: {
    id: number;
  };
}

export type RecentEmailsResponse = string[];

export interface AddAsset {
  assetId: string;
  offset?: string | null;
  tcIn?: string;
  tcOut?: string;
  note?: string;
  voiceOverRequired?: string;
}

export interface AddPayload {
  type: 'asset' | 'collection' | 'library_item' | 'order';
  entity_uuid: string;
}

export interface AddAssetPayload extends AddPayload {
  extra:
    | (Partial<AddAsset> & {
        timecode_in: string | undefined;
        timecode_out: string | undefined;
      })
    | undefined;
}

export interface DestinationsValidateResponse {
  cartItemUuids?: string[];
  assetOriginalFilename?: string;
  configUuid?: string;
  assetUuid?: string;
  note: string;
  type: 'ERR' | 'WARN';
}
