import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LicenseAgreementDialogComponent } from '../components/license-agreement-dialog/license-agreement-dialog.component';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map, Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '@vdms-hq/auth';

@Injectable({ providedIn: 'root' })
export class LicenseAgreementDialogService {
  acceptLicense$: Observable<boolean> = this.auth.userAttributesDefinite$.pipe(
    map((attributes) => !!attributes?.acceptLicense),
  );

  constructor(private readonly matDialog: MatDialog, private afs: AngularFirestore, private auth: AuthService) {}

  get auth$() {
    return this.auth.auth$;
  }

  popLicenseAgreementDialog() {
    this.acceptLicense$
      .pipe(
        take(1),
        filter((attribute) => !attribute),
        switchMap(() => this.#openLicenseDialog()),
      )
      .subscribe();
  }

  #openLicenseDialog() {
    const dialogRef = this.matDialog.open(LicenseAgreementDialogComponent, { disableClose: true });

    return dialogRef.afterClosed().pipe(
      take(1),
      filter((response: { acceptLicense: boolean }) => !!response?.acceptLicense),
      switchMap(() => {
        return this.auth.updateAttributes({ eulaAcceptedDate: new Date() });
      }),
    );
  }
}
