import { Injectable } from '@angular/core';
import { ActivatedClientService, ClientRef, Integrations, PermissionService } from '@vdms-hq/activated-client';
import { PermissionAwareItem } from '@vdms-hq/shared';
import { UISidenavConfig, UISidenavSection } from '@vdms-hq/ui';
import { BehaviorSubject, combineLatest, Observable, switchMap } from 'rxjs';
import { ClientVidaModel } from '@vdms-hq/firebase-contract';

@Injectable({
  providedIn: 'root',
})
export class SidenavConfigService {
  #isLoading$ = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.#isLoading$.asObservable();

  constructor(private permissionService: PermissionService, private activatedClientService: ActivatedClientService) {}

  public getSidenavConfig$(sidenavConfig: UISidenavSection[] = []): Observable<UISidenavConfig> {
    const mapLegacyRoutesFactoryFn = () => {
      return (item: PermissionAwareItem): PermissionAwareItem => {
        const mappedItem = { ...item } as PermissionAwareItem;

        if (mappedItem?.id === 'collections' && 'route' in mappedItem) {
          mappedItem.route = '/collections';
        }

        return mappedItem;
      };
    };

    const filterEnabledForFactoryFn = (
      clientId?: ClientRef | null,
      integrations?: Integrations,
      vida?: Partial<ClientVidaModel>,
    ) => {
      return (item: PermissionAwareItem): boolean => {
        const salesForceEnabled = integrations?.salesforce?.enabled ?? false;
        const vidaSupport =
          vida?.vidaSupportButton?.enabled === undefined
            ? true
            : vida?.vidaSupportButton?.enabled !== undefined
            ? vida?.vidaSupportButton?.enabled
            : false;
        const thirdPartySupport = vida?.thirdPartySupport?.enabled ?? false;

        if (item?.enabledFor) {
          return (
            item.enabledFor.includes(clientId) ||
            (salesForceEnabled && item.enabledFor.includes('salesforce')) ||
            (vidaSupport && item.enabledFor.includes('vidaSupport')) ||
            (thirdPartySupport && item.enabledFor.includes('thirdPartySupport'))
          );
        } else {
          return true;
        }
      };
    };

    return combineLatest([
      this.activatedClientService.clientId$,
      this.activatedClientService.integrations$,
      this.activatedClientService.vida$,
    ]).pipe(
      switchMap(([clientId, integrations, vida]) => {
        return this.permissionService.filterItemsAgainstPermissions$(sidenavConfig, {
          customMapFn: mapLegacyRoutesFactoryFn(),
          customFilterFn: filterEnabledForFactoryFn(clientId, integrations, vida),
        });
      }),
    );
  }
}
