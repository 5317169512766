import { isEmptyInputValue } from './email-array.validator';
import { AbstractControl, ValidatorFn } from '@angular/forms';

export function emailPatternValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (isEmptyInputValue(control.value) || control.value == '') return null;
    return checkIfPatternIsValid(control.value) ? null : { emailPattern: true };
  };
}

export function hasOwnerUuidValidator(uuid: string | null): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (isEmptyInputValue(control.value) || control.value == '') return null;
    return isEmptyInputValue(uuid) ? { hasOwnerUuid: true } : null;
  };
}

const EMAIL_REGEX_PATTERN = new RegExp('^[a-z0-9A-Z._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$');

function checkIfPatternIsValid(value: string | string[]): boolean {
  let isValid = true;
  Array.isArray(value)
    ? value.forEach((email) => {
        if (!EMAIL_REGEX_PATTERN.test(email)) {
          isValid = false;
        }
      })
    : (isValid = EMAIL_REGEX_PATTERN.test(value));
  return isValid;
}
