import { PaginationProps } from '@vdms-hq/ui';

export const STATUS_OPTIONS = [
  {
    key: 'all',
    label: 'common.owner_type.all',
  },
  {
    key: 'shared',
    label: 'common.owner_type.shared',
  },
  {
    key: 'owned',
    label: 'common.owner_type.owned',
  },
];

export const SORT_BY = [
  {
    key: 'created_desc',
    label: 'common.sort.created_newest_first',
    props: <PaginationProps>{
      orderBy: 'collection.createdAt',
      orderDir: 'desc',
    },
  },
  {
    key: 'created_asc',
    label: 'common.sort.created_latest_first',
    props: <PaginationProps>{
      orderBy: 'collection.createdAt',
      orderDir: 'asc',
    },
  },
  {
    key: 'name_desc',
    label: 'common.sort.name_az',
    props: <PaginationProps>{
      orderBy: 'collection.name',
      orderDir: 'asc',
    },
  },
  {
    key: 'name_asc',
    label: 'common.sort.name_za',
    props: <PaginationProps>{
      orderBy: 'collection.name',
      orderDir: 'desc',
    },
  },
];
