<div class="multi-data-container">
  <vdms-hq-ui-data-presentation-header
    [actions]="headerActions$ | async"
    (action)="actionHandler($event)"
    [title]="'common.rights_partners.table.title' | translate"
    [counterIndicator]="rightsPartnersResultsMultiDs.total$ | async"
  >
  </vdms-hq-ui-data-presentation-header>

  <vdms-hq-ui-multiple-data-presentation
    *ngIf="rightsPartnersConfig$ | async; let configuration"
    [configuration]="configuration"
    [dataSource]="rightsPartnersResultsMultiDs"
    (action)="actionHandler($event)"
    (changeDefaultView)="setPreferredTableView($event)"
  >
    <vdms-hq-dynamic-form-group-renderer
      filters
      [form]="rightsPartnersResultsMultiDs.filters"
      [fieldsConfig]="filtersConfig"
      [hasLoader]="true"
    ></vdms-hq-dynamic-form-group-renderer>
  </vdms-hq-ui-multiple-data-presentation>
</div>
