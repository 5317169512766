import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SelectedFilesService {
  selectedFiles$ = new BehaviorSubject<File[]>([]);
  #selectedFiles: File[] = [];
  hasSelected$ = this.selectedFiles$.pipe(map(items => items.length > 0))

  remove(index: number) {
    this.#selectedFiles.splice(index, 1);
    this.selectedFiles$.next(this.#selectedFiles);
  }

  set(files: File[]) {
    this.#selectedFiles = files;
    this.selectedFiles$.next(this.#selectedFiles);
  }

  clear() {
    this.#selectedFiles = [];
    this.selectedFiles$.next(this.#selectedFiles);
  }
}
