<vdms-hq-ui-layout-page-wrapper
  [breadcrumbs]="[{ name: 'Quarantine Config' }]"
  *ngIf="form$ | async as form; else loading"
>
  <vdms-hq-ui-form-section
    *requiresPermissions="[Permission.MANAGE_QUARANTINE_CONFIGS]"
    [divider]="true"
    layout="single"
  >
    <div [formGroup]="form">
      <vdms-hq-ui-form-checkbox-list
        [loading]="isLoading$ | async"
        [columns]="1"
        [label]="'pages.admin.quarantine' | translate"
        orientation="horizontal"
        formControlName="quarantine"
        [available]="quarantineSelectOptions"
      >
      </vdms-hq-ui-form-checkbox-list>
    </div>
  </vdms-hq-ui-form-section>
</vdms-hq-ui-layout-page-wrapper>

<vdms-hq-ui-actions-footer *ngIf="form$ | async as form">
  <vdms-hq-ui-button
    right
    color="primary"
    (click)="save()"
    [loading]="isSaving$ | async"
    [disabled]="form.invalid || form.pristine"
  >
    {{ 'common.global.save' | translate }}
  </vdms-hq-ui-button>
</vdms-hq-ui-actions-footer>

<ng-template #loading>
  <vdms-hq-ui-loader></vdms-hq-ui-loader>
</ng-template>
