import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newFeature',
})
export class NewFeaturePipe implements PipeTransform {
  transform(date?: Date): boolean {
    return date ? date > new Date(Date.now() - 12096e5) : false;
  }
}
