<ng-container [formGroup]="innerFormControl">
  <div class="range">
    <mat-form-field appearance="outline" class="material-custom-field" [class.hide-footer]="!withFooter">
      <mat-label>{{ label !== '' ? label : 'Enter a date range' }}</mat-label>
      <mat-date-range-input [formGroup]="innerFormControl" [rangePicker]="picker">
        <input matStartDate formControlName="from" placeholder="Start date" />
        <input matEndDate formControlName="to" placeholder="End date" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>

      <mat-error *ngIf="innerFormControl.controls.from.hasError('matStartDateInvalid')">Invalid start date</mat-error>
      <mat-error *ngIf="innerFormControl.controls.to.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    </mat-form-field>
  </div>
</ng-container>
