import { createAction, props } from '@ngrx/store';

const ClientPrefix = '[CLIENT] ';

const ClientAction = {
  ASK_TO_CHANGE_CLIENT: ClientPrefix + 'Ask to change client',
};

export const askToChangeClient = createAction(
  ClientAction.ASK_TO_CHANGE_CLIENT,
  props<{
    clientId: string;
  }>(),
);
