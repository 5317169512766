<div class="multi-data-container">
  <vdms-hq-ui-data-presentation-header
    [title]="'common.delivery_destinations.title'"
    (action)="handleAction($event)"
    [actions]="headerActions$ | async"
  >
  </vdms-hq-ui-data-presentation-header>
  <vdms-hq-ui-multiple-data-presentation
    [dataSource]="dataSource"
    [configuration]="viewConfiguration"
    (action)="handleAction($event)"
  >
    <vdms-hq-dynamic-form-group-renderer
      filters
      [form]="filters"
      [fieldsConfig]="filtersConfig"
    ></vdms-hq-dynamic-form-group-renderer>
  </vdms-hq-ui-multiple-data-presentation>
</div>
