<div class="fit-inputs">
  <vdms-hq-ui-form-input-select
    [label]="label"
    [placeholder]="'pages.order.delivery_destination_placeholder' | translate"
    [formControl]="uuid"
    [selectOptions]="selectorOptions"
    (valueChange)="changeDestination()"
  >
  </vdms-hq-ui-form-input-select>

  <vdms-hq-ui-form-input-select
    [label]="'pages.delivery_destinations.form.config' | translate"
    [placeholder]="'pages.order.config_placeholder' | translate"
    [formControl]="configUuid"
    [selectOptions]="configOptions"
    [disabledMsg]="'pages.order.disabled_config_msg' | translate"
  >
    <mat-error *ngIf="submitted && destinationGroup.errors && destinationGroup.errors.deliveryDestinationRequired">
      {{ 'pages.order.delivery_destination_required' | translate }}
    </mat-error>
  </vdms-hq-ui-form-input-select>
</div>
