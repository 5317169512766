<div class="drawer">
  <div class="drawer-header">
    <vdms-hq-ui-button
      *ngIf="showClose"
      (click)="action.emit({ key: 'close_drawer' })"
      [iconOnly]="true"
      [tooltip]="'tooltip.close' | translate"
      icon="close"
    ></vdms-hq-ui-button>
    <ng-content select="[drawer-header]"></ng-content>
  </div>
  <div class="drawer-content">
    <ng-content select="[drawer-content]"></ng-content>
  </div>
  <div class="drawer-footer drawer-actions">
    <ng-content select="[drawer-footer]"></ng-content>
  </div>
</div>
