import { Injectable, TrackByFunction } from '@angular/core';
import { DestinationApiService, DestinationFlatModel, DestinationModel } from '@vdms-hq/api-contract';
import { FilterableDataSource, LoadableDataSource, PageableDataSource } from '@vdms-hq/shared';
import { BehaviorSubject, Observable, combineLatest, tap, debounceTime, switchMap, map } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ParamsPagination } from '@vdms-hq/view-settings';

export type DeliveryDestinationView = DestinationFlatModel;

@Injectable()
export class DestinationsResultsDataSource
  extends ParamsPagination
  implements PageableDataSource, LoadableDataSource, FilterableDataSource
{
  emptyResults$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  total$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  text$ = new BehaviorSubject<string>('');
  connection$!: Observable<DestinationModel[]>;
  allData$: Observable<DestinationModel[]> = combineLatest([this.pageIndex$, this.pageSize$, this.text$]).pipe(
    tap(() => this.isLoading$.next(true)),
    debounceTime(500),
    switchMap(([pageIndex, pageSize, text]) =>
      this.deliveryService.getPaginated({ text, page: pageIndex.toString(), perPage: pageSize.toString() }),
    ),
    tap((response) => {
      this.isLoading$.next(false);
      !response && this.emptyResults$.next(true);
      response.total && this.total$.next(response.total);
    }),
    map((response) => response.data),
    shareReplay(1),
  );

  public trackBy: TrackByFunction<DeliveryDestinationView> = (index: number, model: DeliveryDestinationView) =>
    model.uuid;

  constructor(private readonly deliveryService: DestinationApiService) {
    super();
    this.connection$ = this.allData$;
  }

  applyFilter(value: string, fields?: string[]) {
    if (value?.length < 1 && value?.length !== 0) {
      return;
    } else if (value?.length === 0) {
      this.text$.next('');
    } else {
      this.text$.next(value);
    }
    this.changePageIndex$.next(0);
  }
}
