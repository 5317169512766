import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

/**
 * @deprecated use FormControlValueAccessorComponent[errorMessages] instead
 */
@Component({
  selector: 'vdms-hq-ui-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss'],
})
export class FormErrorsComponent {
  @Input() control!: AbstractControl;
}
