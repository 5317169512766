import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedClientService, Permission } from '@vdms-hq/activated-client';
import { ToastService } from '@vdms-hq/toast';
import { GlobalSettingsService, SettingsThresholdsType } from '@vdms-hq/api-contract';
import { BehaviorSubject, catchError, shareReplay, Subject, takeUntil, tap, withLatestFrom } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { FormSectionComponent, UIButtonModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'vdms-hq-global-configuration',
  standalone: true,
  imports: [CommonModule, UIFormModule, TranslateModule, UIButtonModule, UILayoutModule, FormSectionComponent],
  templateUrl: './global-configuration.component.html',
  styleUrls: ['./global-configuration.component.scss'],
})
export class GlobalConfigurationComponent implements OnInit, OnDestroy {
  globalSettingsService = inject(GlobalSettingsService);
  activatedClient = inject(ActivatedClientService);
  toastService = inject(ToastService);

  settingsForm = new FormGroup({
    threshold_sd: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    threshold_hd_2k: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    threshold_uhd_4k: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    threshold_5k: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    threshold_6k: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    threshold_8k: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    threshold_high_fps: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    threshold_ultra_high_fps: new FormControl<number | undefined>(undefined, { nonNullable: true }),
  });

  activeControl$ = new BehaviorSubject<string | null>('threshold_sd');
  #destroyed = new Subject<void>();
  loading$ = new BehaviorSubject(true);

  hasEditPermission$ = new BehaviorSubject<boolean>(false);

  get controls() {
    return Object.keys(this.settingsForm.getRawValue());
  }

  ngOnInit(): void {
    this.globalSettingsService
      .get()
      .pipe(
        tap(() => this.loading$.next(true)),
        withLatestFrom(this.activatedClient.permissions$),
        takeUntil(this.#destroyed),
        shareReplay(1),
        catchError(() => {
          this.toastService.error({
            id: 'global_settings_get',
            message: 'pages.clients.global_settings.notifications.global_settings_upload_failed',
          });
          this.loading$.next(false);
          this.settingsForm.disable();
          this.hasEditPermission$.next(false);
          return [];
        }),
      )
      .subscribe((data) => {
        const settings = data[0].thresholds as SettingsThresholdsType;
        const hasEditPermission = data[1].some((permission) => permission === Permission.EDIT_THRESHOLDS);
        this.settingsForm.patchValue(settings);
        if (!hasEditPermission) {
          this.settingsForm.disable();
          this.hasEditPermission$.next(false);
        } else {
          this.settingsForm.enable();
          this.hasEditPermission$.next(true);
        }
        this.loading$.next(false);
      });
  }

  ngOnDestroy() {
    this.#destroyed.next();
    this.#destroyed.complete();
  }

  setThreshold(control: string, width: number, height: number) {
    if (!width || !height || width < 100 || height < 100) {
      return;
    }
    this.settingsForm.get(control)?.setValue(width * height);
    this.settingsForm.markAsDirty();
  }

  saveSettings() {
    this.loading$.next(true);

    this.globalSettingsService.patch({ thresholds: this.settingsForm.value }).subscribe({
      next: () => {
        this.toastService.success({
          id: 'global_settings_patch',
          message: 'pages.clients.global_settings.notifications.global_settings_saved',
        });
        this.loading$.next(false);
      },
      error: () => {
        this.toastService.error({
          id: 'global_settings_patch',
          message: 'pages.clients.global_settings.notifications.global_settings_save_failed',
        });
        this.loading$.next(false);
      },
    });
  }
}
