import { Component, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TranslateModule } from '@ngx-translate/core';
import { UIFormModule } from '../../ui-form';
import { UILoaderModule } from '../../ui-loader';
import { UIDataSelectorDataSource } from '../model/ui-data-selector.ds';
import { UIPipesModule } from '../../ui-pipes/ui-pipes.module';
import { FormControl, FormGroup } from '@angular/forms';
import { PaginatorComponent } from '../../ui-pagination';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DiacriticsConverter } from '@vdms-hq/shared';

@Component({
  selector: 'vdms-hq-ui-data-selector',
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule,
    TranslateModule,
    UIFormModule,
    UILoaderModule,
    UIPipesModule,
    PaginatorComponent,
  ],
  templateUrl: './data-selector.component.html',
  styleUrls: ['./data-selector.component.scss'],
})
export class UIDataSelectorComponent implements OnDestroy {
  #destroy$ = new Subject<void>();

  @Input() dataSource!: UIDataSelectorDataSource;
  @Input() label!: string;
  @Input() previewOnly = false;
  @Input() showSelectAll = true;
  formGroup: FormGroup;
  @Input() hidePageSize = false;
  @Input() searchDebounceEnabled = false;
  @Input() isShareSelector = false;
  @Input() userEmail = '';

  constructor() {
    this.formGroup = new FormGroup({
      innerInputFormControl: new FormControl<string>(''),
    });
    this.formGroup.controls['innerInputFormControl'].valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged(), takeUntil(this.#destroy$))
      .subscribe((value) => this.applyFilter(value));
  }

  ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  onSelectNone(): void {
    this.dataSource.selection?.clear();
  }

  onSelectVisible(): void {
    this.dataSource.selection?.toggleAllVisible(true);
  }

  onSelectAll(): void {
    this.dataSource.selection?.toggleAll(true);
  }

  applyFilter(value: string): void {
    this.dataSource.pagination$?.next({ page: '0', perPage: <string>this.dataSource.pagination$?.value?.perPage });
    this.dataSource.applyFilter(DiacriticsConverter(value));
  }
}
