import { Query } from './models/query';
import { ClauseValue } from './models/clause-value';
import { Operator } from './models/operator';

export class QueryBuilder<T> {
  private _query: Query = '';

  select(fields?: (keyof T)[]) {
    this._query = this._query.concat('SELECT');
    if (fields) {
      this._query = this._query.concat(' ', `${fields?.join(', ')}`);
      return this;
    }
    this._query = this._query.concat(' ', '*', ' ');
    return this;
  }

  from(tableName: string) {
    this._query = this._query.concat(' ', 'FROM', ' ', `${tableName}`);
    return this;
  }

  where(field: keyof T, operator: Operator, value: ClauseValue) {
    const f = typeof field === 'symbol' ? String(field) : (field as number | string);
    value = this.checkQuoteEscape(value);

    this._query = this._query.concat(' ', `WHERE ${f} ${operator} ${value}`);
    return this;
  }

  and(field: keyof T, operator: Operator, value: ClauseValue) {
    const f = typeof field === 'symbol' ? String(field) : (field as number | string);
    value = this.checkQuoteEscape(value);
    this._query = this._query.concat(' ', `AND ${String(f)} ${operator} ${value}`);
    return this;
  }

  limit(amount: number) {
    this._query = this._query.concat(' ', `LIMIT ${amount}`);
    return this;
  }

  build() {
    return this._query;
  }

  /**
   * @summary - Checks if value should be quote-escaped basing on the value's type
   * @remarks - Object is being checked for "null" - see the type, disqualifying object
   * @see ClauseValue
   * @param value
   */
  checkQuoteEscape = (value: ClauseValue) =>
    ['object', 'boolean'].includes(typeof value) ? String(value) : `'${value}'`;
}
