export enum HybrikJobStatus {
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export interface HybrikAnalizeResponse {
  data: HybrikAnalizeData;
  status: string;
}

export interface HybrikJobsList {
  job_id: number;
  status: HybrikJobStatus;
  name: string;
  errors: HybrikJobError[];
  created_at: string;
  updated_at: string;
  type: HybrikJobsListTypes;
  hint_significant_error: string;
  machine_time: number | string;
  completed_at: string;
}

export interface HybrikAnalizeData {
  hybrik_profile: HybrikProfile;
  general_properties: GeneralProperties;
}

export interface GeneralProperties {
  asset: HybrikAsset;
  audio: HybrikAudio[];
  video: HybrikVideo;
  subtitle: HybrikSubtitle[];
  container: HybrikContainer;
}

export interface HybrikAsset {
  asset_url: string;
  tagged_date: string;
  content_type: string;
  creation_date: string;
}

export interface HybrikAudio {
  pid: number;
  codec: string;
  profile: string;
  channels: number;
  bitrate_kb: number;
  sample_rate: number;
  bitrate_mode: string;
  duration_sec: number;
  channel_order: string;
  channel_designators: string[];
}

export interface HybrikContainer {
  kind: string;
  size_kb: number;
  faststart: boolean;
  bitrate_kb: number;
  duration_sec: number;
}

export interface HybrikVideo {
  dar: number;
  par: number;
  pid: number;
  codec: string;
  level: number;
  width: number;
  height: number;
  profile: string;
  color_trc: string;
  bitrate_kb: number;
  frame_rate: number;
  color_range: string;
  color_space: string;
  color_matrix: string;
  duration_sec: number;
  chroma_format: string;
  bit_resolution: number;
  interlace_mode: string;
  ire_range_mode: string;
  color_primaries: string;
  frame_rate_mode: string;
  clean_aperture_width: number;
  clean_aperture_height: number;
}

export interface HybrikSubtitle {
  format: string;
}

interface HybrikProfile {
  target: GeneralProperties;
  compliance: string;
  scan_version: string;
  profile_compiler_version: string;
}

interface Job {
  id: number;
  name: string;
  flags: number;
  status: string;
  priority: number;
  progress: number;
  user_tag: unknown;
  job_class: number;
  user_data: unknown;
  is_api_job: number;
  task_count: number;
  creation_time: string;
  first_started: string;
  render_status: string;
  last_completed: string;
  expiration_time: string;
  subscription_key: string;
  last_times_updated: string;
  hint_active_time_sec: number;
  hint_machine_time_sec: number;
  hint_significant_error: string;
}

interface Task {
  id: number;
  uid: string;
  kind: string;
  name: string;
  status: string;
  assigned: string;
  priority: number;
  retry_nr: number;
  completed: string;
  documents: Document[];
  retry_count: number;
  element_name: string;
  retry_nr_aog: number;
  creation_time: string;
  max_runtime_sec: unknown;
  related_asset_id: unknown;
  max_retry_count_aog: number;
  config?: unknown;
  fetcher_id?: number;
}

interface Response {
  job: Job;
  tasks: Task[];
  errors: HybrikJobError[];
}

interface HybrikJobError {
  id: number;
  job_class: number;
  is_api_job: number;
  priority: number;
  creation_time: string;
  expiration_time: string;
  hint_active_time_sec: number;
  hint_machine_time_sec: number;
  hint_significant_error: string;
  last_times_updated: string;
  subscription_key: string;
  flags: number;
  user_tag: string;
  status: string;
  render_status: string;
  task_count: number;
  progress: number;
  name: string;
  first_started: string;
  last_completed: string;
  user_data: string;
}

export enum HybrikJobsListTypesEnum {
  PUBLISH_FOR_DELIVERY_DESTINATION = 'publish_for_delivery_destination',
  TRANSCODE_FOR_DELIVERY_DESTINATION = 'transcode_for_delivery_destination',
}
export type HybrikJobsListTypes =
  | HybrikJobsListTypesEnum.TRANSCODE_FOR_DELIVERY_DESTINATION
  | HybrikJobsListTypesEnum.PUBLISH_FOR_DELIVERY_DESTINATION;
