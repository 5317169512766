<mat-paginator
  *ngIf="isPageableDataSource(dataSource)"
  [pageSizeOptions]="dataSource.pageSizeOptions"
  [length]="dataSource.total$ | async"
  [pageIndex]="dataSource.pageIndex$ | async"
  [pageSize]="dataSource.pageSize$ | async"
  (page)="dataSource.pageChange($event)"
  [hidePageSize]="hidePageSize"
  [showFirstLastButtons]="true"
>
</mat-paginator>
