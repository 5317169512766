import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { initCommon } from './initializer';
import { ThemeSwitcherService } from './logic/services/theme-switcher.service';
import { LayoutService } from './logic/services/layout.service';

@NgModule({
  imports: [CommonModule],
})
export class ThemingModule {
  static forRoot(): ModuleWithProviders<ThemingModule> {
    return {
      ngModule: ThemingModule,
      providers: [
        LayoutService,
        ThemeSwitcherService,
        {
          provide: APP_INITIALIZER,
          useFactory: initCommon,
          deps: [ThemeSwitcherService],
          multi: true,
        },
      ],
    };
  }
}
