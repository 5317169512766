<vdms-hq-ui-dialog-wrapper>
  <div title>
    <vdms-hq-ui-layout-section
      [breadcrumbs]="[
        {
          name: 'common.video_codecs.title' | translate
        }
      ]"
    >
    </vdms-hq-ui-layout-section>
  </div>
  <div content>
    <vdms-hq-native-upload-component
      [mimeTypes]="['text/csv']"
      [loading]="loading$ | async"
      hint="Drag and Drop CSV file here"
    ></vdms-hq-native-upload-component>
  </div>

  <div footer>
    <vdms-hq-ui-button color="secondary" (click)="close()">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button color="primary" [loading]="loading$ | async" [disabled]="!files.value?.length" (click)="save()">
      {{ 'common.global.save' | translate }}
    </vdms-hq-ui-button>
  </div>
</vdms-hq-ui-dialog-wrapper>
