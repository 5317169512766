<div class="timeline">
  <div id="advanced-player-timeline" class="timeline__content"></div>

  <ng-container *ngIf="timelineService.allRows$ | async; let rows">
    <div *ngFor="let row of rows; trackBy: timelineService.trackBy" [id]="row.id"></div>
  </ng-container>

  <div class="timeline__controls" *ngIf="(timelineService.timeline$ | async)?.timeline; let timeline">
    <ng-container *ngIf="timeline.getZoomPercent(); let currentZoom">
      <div>Zoom: {{ currentZoom }}%</div>
      <vdms-hq-ui-form-slider
        [ngModel]="100"
        (ngModelChange)="timelineService.zoomTo($event)"
        [sliderMinValue]="100"
        [sliderMaxValue]="1500"
        [step]="25"
      ></vdms-hq-ui-form-slider>

      <vdms-hq-ui-button (click)="timelineService.zoomTo(100)">Reset</vdms-hq-ui-button>
    </ng-container>
  </div>
</div>
