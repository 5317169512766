<vdms-hq-ui-dialog-wrapper [footerAlign]="'space-between'">
  <h1 title class="title">
    {{
      (reindexView ? 'Update index name for ' : 'Sync index for ') +
        data.entities?.length +
        (data.entities?.length > 1 ? ' clients' : ' client')
    }}
  </h1>

  <div content class="dialog-content">
    <ng-container *ngIf="reindexView">
      <vdms-hq-ui-form-radio-list
        [selectOptions]="radioSelectOptions"
        [orientation]="'horizontal'"
        [formControl]="radioControl"
      ></vdms-hq-ui-form-radio-list>
      <vdms-hq-ui-form-input-text
        [label]="radioControl.value | titlecase"
        [formControl]="formatterValue"
      ></vdms-hq-ui-form-input-text>
    </ng-container>
    <vdms-hq-ui-form-input-date-time
      [formControl]="requestedAtControl"
      [label]="'Requested At'"
      [timepicker]="true"
    ></vdms-hq-ui-form-input-date-time>

    <ng-container *ngIf="reindexView">
      <span>Index name preview:</span>
      <div>
        <span *ngIf="radioControl.value === 'prefix' && formatterValue.value">{{ formatterValue.value + '-' }}</span
        ><span class="grey-text" [innerHTML]="REINDEX_NAME_PATTERN"></span
        ><span *ngIf="radioControl.value === 'suffix' && formatterValue.value">{{ '-' + formatterValue.value }}</span>
      </div>
    </ng-container>
  </div>

  <ng-container footer>
    <vdms-hq-ui-button (click)="close()" color="secondary">
      {{ 'common.reindex_automatization.buttons.cancel' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button (click)="reindex()" color="primary" [disabled]="formatterValue.invalid">
      {{ 'common.reindex_automatization.buttons.confirm' | translate }}
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
