import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ActivatedClientService } from '../services/activated-client.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationGuard implements CanLoad {
  #cartUrlTree: UrlTree = this.router.parseUrl('/cart');
  #cartV3UrlTree: UrlTree = this.router.parseUrl('/cart-v3');

  constructor(private router: Router, private activatedClientService: ActivatedClientService) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
    return this.activatedClientService.vida$.pipe(
      take(1),
      map((vida) => vida?.advancedCheckoutEnabled ?? false),
      map((hasAdvancedCheckout) => {
        const firstSegmentPath = segments[0].path;

        if (hasAdvancedCheckout && firstSegmentPath === 'cart-v3') {
          return true;
        } else if (hasAdvancedCheckout) {
          return this.#cartV3UrlTree;
        } else if (firstSegmentPath === 'cart') {
          return true;
        } else {
          return this.#cartUrlTree;
        }
      }),
    );
  }
}
