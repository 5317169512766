import { Injectable } from '@angular/core';
import { ConnectableDataSource, LoadableDataSource } from '@vdms-hq/shared';
import { BehaviorSubject, Observable, of, shareReplay, tap } from 'rxjs';
import { MetadataListFetcher } from './metadata-list-fetcher';
import { ViewPlayerMetadataItem } from './metadata-list.model';
import { map } from 'rxjs/operators';

@Injectable()
export class MetadataListDatasource implements ConnectableDataSource<ViewPlayerMetadataItem>, LoadableDataSource {
  isLoading$: BehaviorSubject<boolean> = this.metadataListFetcher.isLoading$;
  total$ = new BehaviorSubject<number>(0);
  allData$!: Observable<ViewPlayerMetadataItem[]>;
  emptyResults$: Observable<boolean> = of(false);
  connection$!: Observable<ViewPlayerMetadataItem[]>;

  constructor(private metadataListFetcher: MetadataListFetcher) {}

  init() {
    this.allData$ = this.metadataListFetcher.loadSelected$.pipe(
      tap((metadata) => this.total$.next(metadata.length)),
      tap((metadata) => (this.emptyResults$ = of(metadata.length === 0))),
      map((metadata) => metadata),
      shareReplay(1),
    );
    this.connection$ = this.allData$;
  }
}
