<button
  mat-button
  [color]="matColor"
  [type]="isSubmit ? 'submit' : 'button'"
  [class.mat-flat-button]="isMatFlatBtn"
  [class.mat-button]="isMatBtn"
  [class.icon-only]="iconOnly"
  [class.with-icon]="icon"
  [class]="'color--' + color"
  [ngClass]="size ? size : ''"
  [disabled]="loading || disabled"
  [class.disabled]="loading || disabled"
  [matTooltip]="tooltip"
  [loading]="loading"
  class="material-custom-button"
  [class.download]="asDownloadLink"
>
  <mat-icon *ngIf="icon" class="icon">{{ icon }}</mat-icon>
  <ng-content></ng-content>
</button>
