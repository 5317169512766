import { Injectable } from '@angular/core';
import {
  WebsocketEntitiesUpdate,
  WebsocketEntitiesUpdateInterface,
  WebsocketNotificationInterface,
  WsRequestMessage,
} from '@vdms-hq/shared';
import { UpdatesStreamService } from './updates-stream.service';
import { map, tap } from 'rxjs/operators';
import { startWith } from 'rxjs';
import { AssetFlat } from '@vdms-hq/api-contract';

type AssetFlatView = Partial<AssetFlat> & Pick<AssetFlat, 'uuid'>;

@Injectable({ providedIn: 'root' })
export class AssetsUpdaterService {
  #existingUuids?: string[];

  constructor(private readonly stream: UpdatesStreamService) {}

  listener$ = this.stream.connect().pipe(
    map((next: WebsocketNotificationInterface<AssetFlatView | WebsocketEntitiesUpdate>) => {
      if (this.#isUpdatedData(next) && this.#existingUuids?.length) {
        const uuids = (next.data as unknown as WebsocketEntitiesUpdate)?.uuids?.filter((nextUuid) =>
          this.#existingUuids?.includes(nextUuid),
        );
        return {
          ...next,
          data: next.data,
          uuids: uuids,
        } as WebsocketNotificationInterface<WebsocketEntitiesUpdate>;
      }

      return next;
    }),
    tap((next) => {
      const message = this.#generateAssetDetailsMessage(
        next as WebsocketNotificationInterface<WebsocketEntitiesUpdate>,
      );
      message?.data?.uuids?.length && this.stream.sendWSMessage(message);
    }),
    map((next: WebsocketNotificationInterface<AssetFlatView | WebsocketEntitiesUpdate>) => {
      if (this.#isUpdatedData(next)) {
        // todo fix type please!
        return next.data as unknown as AssetFlatView[];
      }

      if (this.#isDeletedData(next)) {
        return this.#handleDeletedData(next);
      }

      return [];
    }),
    startWith([]),
  );

  unregisterListener() {
    this.stream.destroy();
  }

  enableFilteringOnlyExistingUuids(uuids: string[]) {
    this.#existingUuids = uuids;
  }

  disableFilteringExistingUuids() {
    this.#existingUuids = undefined;
  }

  #generateAssetDetailsMessage(
    message: WebsocketNotificationInterface<WebsocketEntitiesUpdate>,
  ): WsRequestMessage | undefined {
    if (!message?.groupUuid) {
      return;
    }

    return {
      action: 'request',
      method: 'getAssetsDetails',
      data: {
        uuids: message?.data?.uuids ?? [],
        groupUuid: message.groupUuid,
      },
    };
  }

  #isUpdatedData(message: WebsocketNotificationInterface<AssetFlatView | WebsocketEntitiesUpdate>) {
    return 'data' in message && Array.isArray(message?.data);
  }

  #isDeletedData(message: WebsocketNotificationInterface<AssetFlatView | WebsocketEntitiesUpdate>) {
    return message.context === 'DELETE' && !!message.data && 'uuids' in message.data;
  }

  #handleDeletedData(
    nextData: WebsocketNotificationInterface<WebsocketEntitiesUpdate | WebsocketEntitiesUpdateInterface<AssetFlatView>>,
  ) {
    const deletedAssets = (nextData.data as WebsocketEntitiesUpdate).uuids.map((uuid) => ({
      uuid,
      deleted_at: new Date().toString(),
    })) as AssetFlatView[];

    return deletedAssets;
  }
}
