import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoBarType, UIButtonModule, UIDialogWrapperModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, map, switchMap, take, tap } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientsService, SupplierAsperaKey } from '@vdms-hq/api-contract';
import { AsperaKeysDs } from '../../logic/aspera-keys.ds';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from '@vdms-hq/toast';

@Component({
  selector: 'vdms-hq-new-dialog',
  standalone: true,
  imports: [CommonModule, UIDialogWrapperModule, TranslateModule, UILayoutModule, UIFormModule, UIButtonModule],
  providers: [AsperaKeysDs],
  templateUrl: './new-dialog.component.html',
  styleUrls: ['./new-dialog.component.scss'],
})
export class NewDialogComponent implements OnInit {
  protected readonly InfoBarType = InfoBarType;
  suppliers$ = this.asperaKeysDs.suppliers$.pipe(
    map((resp) => resp?.data.map((supplier) => ({ key: supplier.uuid, label: supplier.name }))),
  );

  loading$ = new BehaviorSubject(false);

  form = new FormGroup({
    supplierUuid: new FormControl<string>('', [Validators.required]),
    name: new FormControl<string>('', [Validators.required]),
  });
  asperaKeyData: SupplierAsperaKey | null = null;
  error = '';

  showSecret = false;

  constructor(
    private ref: MatDialogRef<NewDialogComponent>,
    private asperaKeysDs: AsperaKeysDs,
    private clientsService: ClientsService,
    private activatedClientService: ActivatedClientService,
    private toast: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: { supplierUuid?: string },
  ) {}

  get supplierUuid() {
    return this.form.controls.supplierUuid;
  }

  get name() {
    return this.form.controls.name;
  }

  ngOnInit() {
    if (this.data.supplierUuid) {
      this.form.controls.supplierUuid.patchValue(this.data.supplierUuid);
    }
  }

  save() {
    if (this.form.invalid) {
      return;
    }

    this.loading$.next(true);

    this.activatedClientService.clientId$
      .pipe(
        switchMap((clientId) =>
          this.clientsService
            .postSupplierAsperaKey(clientId as string, this.supplierUuid.value ?? '', { name: this.name.value ?? '' })
            .pipe(
              take(1),
              tap(() => this.loading$.next(false)),
            ),
        ),
      )
      .subscribe({
        next: (asperaKeyData) => {
          this.asperaKeyData = asperaKeyData;
          this.toast.success({ id: 'success', message: 'common.notifications.update.done' });
          this.asperaKeysDs.refresh();
        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          this.error = err.error.data;
          this.loading$.next(false);
        },
      });
  }

  close(success?: boolean) {
    this.ref.close(success);
  }
}
