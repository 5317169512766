import { Pipe, PipeTransform } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Pipe({
  name: 'calculateFoldersContainerHeight',
  standalone: true,
})
export class CalculateFoldersContainerHeightPipe implements PipeTransform {
  transform(numberOfItems?: number, perRow = 3): string {
    const rowHeight = 88;

    const rows = Math.ceil((numberOfItems ?? 1) / perRow);
    const padding = 40;

    return rows * rowHeight + padding + 'px';
  }
}
