<ng-container *ngIf="state$ | async as state">
  <vdms-hq-ui-menu
    [branding]="branding"
    [isSalesForceEnabled]="isSalesForceEnabled"
    [isExternalSupport]="isExternalSupport"
    [config]="config"
    [state]="state"
    [ngClass]="state"
    [tourConfig]="tourConfig"
    [version]="version"
    (mousemove)="onMouseOver(state)"
    (mouseleave)="onMouseLeave(state)"
  ></vdms-hq-ui-menu>
</ng-container>
