import { Injectable } from '@angular/core';
import { AuthService } from '@vdms-hq/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BdcWalkService } from 'bdc-walkthrough';
import { UIConfirmationDialogService } from '@vdms-hq/ui';
import { filter, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { map, of, tap } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class TourGuideService {
  private userPath = '';

  constructor(
    private authService: AuthService,
    private afs: AngularFirestore,
    private bdcWalkService: BdcWalkService,
    private confirmationDialog: UIConfirmationDialogService,
    private router: Router,
  ) {
    this.bdcWalkService.disableAll(true);
  }

  openDialog(callback: () => void): void {
    const { pathname, search } = window.location;
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        take(1),
        map(() => `${pathname}${search}`),
        filter((url) => this.#filterWithCallback(url?.startsWith('/home'), callback)),
        switchMap(() => this.authService.userAttributesDefinite$.pipe(take(1))),
        filter((attributes) => this.#filterWithCallback(!attributes?.vida.finishedTour, callback)),
        withLatestFrom(this.authService.email$),
        tap(([, email]) => (this.userPath = `/users/${email ?? ''}`)),
        switchMap(() => {
          const dialogRef = this.confirmationDialog.open({
            title: 'Welcome to VIDA!',
            message: 'Would you like the tour?',
          });
          callback();
          return dialogRef;
        }),
        take(1),
        map((dialogResponse) => ({ isConfirmed: dialogResponse })),
        switchMap(({ isConfirmed }) => {
          if (!isConfirmed) {
            return this.#markAsCompleted().pipe(map(() => isConfirmed));
          }
          return of(isConfirmed);
        }),
        filter(Boolean),
      )
      .subscribe(() => this.#startTourOnce());
  }

  runGuide(): void {
    this.authService.auth$
      .pipe(
        tap((user) => (this.userPath = `/users/${user?.email || ''}`)),
        take(1),
      )
      .subscribe(() => this.#startTourOnce());
  }

  #startTourOnce(): void {
    this.bdcWalkService.reset();
    this.bdcWalkService.disableAll(false);
    this.bdcWalkService.setTaskCompleted('taskInitializedTour', true);

    this.bdcWalkService.changesDisplaying
      .pipe(
        filter(() => this.bdcWalkService.disabled),
        take(1),
        switchMap(() => this.#markAsCompleted()),
      )
      .subscribe();
  }

  #markAsCompleted() {
    return this.authService.updateAttributes({
      vida: {
        finishedTour: true,
      },
    });
  }

  #filterWithCallback(condition: boolean, callback: () => void): boolean {
    if (condition) {
      return true;
    }
    callback();
    return false;
  }
}
