<div
  infiniteScroll
  [infiniteScrollDistance]="infiniteScrollDistance"
  [infiniteScrollThrottle]="50"
  (scrolled)="loadOnScroll && scrolled.emit()"
>
  <ng-content></ng-content>
  <vdms-hq-ui-load-more
    *ngIf="loaded"
    [isLoading]="isLoading"
    [currentLoaded]="loaded"
    [total]="total ?? 0"
    [batchSize]="batchSize ?? defaultPerPage"
    (loadMore)="scrolled.emit()"
  ></vdms-hq-ui-load-more>
</div>
