import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AuthService } from '@vdms-hq/auth';
import { MultiFactorError } from '@firebase/auth/dist/node-esm';
import { MfaCodeDialogComponent, MfaCodeDialogInput } from '../components/mfa-code-dialog/mfa-code-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastService } from '@vdms-hq/toast';

@Injectable({
  providedIn: 'root',
})
export class PasswordlessResolver implements Resolve<boolean> {
  constructor(private authService: AuthService, private matDialog: MatDialog, private toast: ToastService) {}

  resolve = async (route: ActivatedRouteSnapshot): Promise<boolean> => {
    const action = route.queryParamMap.get('attemptVerifyEmail');

    if (action) {
      try {
        await this.authService.passwordLessVerify();
      } catch (error) {
        await this.askForMfaCodeIfPossible(error as MultiFactorError);
      }
    }

    return true;
  };

  private async askForMfaCodeIfPossible(error: MultiFactorError) {
    if (error.code === 'auth/multi-factor-auth-required') {
      this.matDialog.open<MfaCodeDialogComponent, MfaCodeDialogInput>(MfaCodeDialogComponent, { data: { error } });
    }
  }
}
