import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIButtonModule, UIEmptyResultsModule, UIFormModule } from '@vdms-hq/ui';
import { ControlComponent } from './view/control/control.component';
import { SelectorsModule } from '@vdms-hq/selectors';
import { ControlValueFormatPipe } from './pipes/control-value-format.pipe';
import { ControlReadOnlyComponent } from './view/control-read-only/control-read-only.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedClientModule } from '@vdms-hq/activated-client';
import { AudioTracksModule } from '@vdms-hq/audio-tracks';
import { IsRelationLoadingPipe } from './pipes/is-relation-loading.pipe';
import { FieldDefinitionPipe } from './pipes/field-definition.pipe';
import { TranscriptionModule } from '@vdms-hq/transcription';

@NgModule({
  declarations: [
    ControlComponent,
    ControlValueFormatPipe,
    IsRelationLoadingPipe,
    ControlReadOnlyComponent,
    FieldDefinitionPipe,
  ],
  providers: [],
  imports: [
    CommonModule,
    UIFormModule,
    FormsModule,
    SelectorsModule,
    UIButtonModule,
    TranslateModule,
    ActivatedClientModule,
    SelectorsModule,
    AudioTracksModule,
    UIEmptyResultsModule,
    TranscriptionModule,
  ],
  exports: [ControlComponent, FieldDefinitionPipe],
})
export class FormBuilderModule {}
