import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import { ToastModule } from '@vdms-hq/toast';
import {
  DataPresentationHeaderComponent,
  FormSectionComponent,
  MultipleDataPresentationComponent,
  UIButtonModule,
  UIDialogModule,
  UIDialogWrapperModule,
  UIFormModule,
  UILayoutModule,
  UIModule,
  UIResultsWrapperModule,
} from '@vdms-hq/ui';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedClientModule } from '@vdms-hq/activated-client';
import { ClientsTableComponent } from './clients-table/clients-table.component';
import { ClientsFetcher } from '../logic/services/clients-fetcher';
import { ClientsDataSource } from '../logic/services/clients-data-source';
import { ClientsEditCreateDialogComponent } from './clients-edit-create-dialog/clients-edit-create-dialog.component';
import { ClientFormService } from '../logic/services/client-form.service';
import { ClientFormComponent } from './client-form/client-form.component';
import { CredentialsFetcher } from '../logic/services/credentials-fetcher';
import { DynamicFiltersModule } from '@vdms-hq/dynamic-filters';

const routes: Route[] = [
  {
    path: '',
    component: ClientsTableComponent,
  },
];

@NgModule({
  declarations: [ClientsTableComponent, ClientsEditCreateDialogComponent],
  imports: [
    RouterModule.forChild(routes),
    ToastModule,
    CommonModule,
    UILayoutModule,
    UIFormModule,
    UIDialogModule,
    UIButtonModule,
    UIResultsWrapperModule,
    MatDialogModule,
    MatSortModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatTooltipModule,
    TranslateModule,
    ActivatedClientModule,
    UIDialogWrapperModule,
    UIModule,
    FormSectionComponent,
    ClientFormComponent,
    MultipleDataPresentationComponent,
    DataPresentationHeaderComponent,
    DynamicFiltersModule,
  ],
  exports: [ClientsTableComponent, ClientsEditCreateDialogComponent],
  providers: [ClientsDataSource, ClientsFetcher, ClientFormService, CredentialsFetcher],
})
export class ClientsEditorModule {}
