import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class MediaFetcherService {
  constructor(private readonly http: HttpClient) {}

  getMedia(url: string) {
    return this.http.get(url, {
      responseType: 'blob',
      withCredentials: true,
    });
  }
}
