import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { PostVideoCodec, VideoCodec, VideoCodecsApiService } from '@vdms-hq/api-contract';
import { InfoBarType, UIButtonModule, UIDialogWrapperModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { BehaviorSubject, debounceTime, iif, map, Observable, of, shareReplay, tap } from 'rxjs';
import { codecsUniqueValidator } from '../../logic/codecs-unique-validator';
import { VideoCodecsDatasourceService } from '../../logic/video-codecs-datasource.service';

@Component({
  selector: 'vdms-hq-video-codecs-edit-dialog',
  templateUrl: './video-codecs-edit-dialog.component.html',
  styleUrls: ['./video-codecs-edit-dialog.component.scss'],
  standalone: true,
  imports: [UIDialogWrapperModule, TranslateModule, UILayoutModule, AsyncPipe, UIButtonModule, UIFormModule, NgIf],
})
export class VideoCodecsEditDialogComponent implements OnInit {
  protected readonly InfoBarType = InfoBarType;
  #selectedCodec: Observable<VideoCodec | null>;

  initialValue: VideoCodec | null = null;
  nullProfile = new FormControl<boolean>(false);

  nullVideoCodec = new FormControl<boolean>(false);
  form = new FormGroup({
    video_codec: new FormControl<string | null>(''),
    profile: new FormControl<string | null>(''),
    display_name: new FormControl<string>('', Validators.required),
  });
  formDirty$ = this.form.valueChanges.pipe(
    debounceTime(300),
    map((formValue) => {
      return !(
        formValue?.display_name !== this.initialValue?.display_name ||
        formValue?.profile !== this.initialValue?.profile ||
        formValue?.video_codec !== this.initialValue?.video_codec
      );
    }),
  );

  loading$ = new BehaviorSubject(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { uuid: string | undefined },
    private ref: MatDialogRef<VideoCodecsEditDialogComponent>,
    private dataSource: VideoCodecsDatasourceService,
    private api: VideoCodecsApiService,
  ) {
    this.#selectedCodec = this.data?.uuid ? this.api.getOne(this.data.uuid) : of(null);
  }

  get codecUuid() {
    return this.data?.uuid;
  }

  get videoCodec() {
    return this.form.controls.video_codec;
  }

  get hasDuplicateError() {
    return 'alreadyExist' in (this.form?.errors ?? {});
  }

  get profile() {
    return this.form.controls.profile;
  }

  ngOnInit(): void {
    this.#init();
  }

  onNullSetterChange(event: unknown, fieldName: 'videoCodec' | 'profile') {
    this[fieldName].setValue(event ? null : '');
    this[fieldName][event ? 'disable' : 'enable']();
  }

  close(success?: boolean) {
    this.ref.close(success);
  }

  save() {
    if (this.form.invalid) {
      return;
    }

    this.loading$.next(true);
    iif(() => !!this.codecUuid, this.#patch(), this.#post())
      .pipe(tap(() => this.loading$.next(false)))
      .subscribe({
        next: () => {
          this.close(true);
        },
      });
  }

  #init() {
    if (!this.codecUuid) {
      this.#setAsyncValidators();
      return;
    }

    this.loading$.next(true);
    this.#selectedCodec.subscribe((codec) => {
      this.initialValue = codec;
      this.form.patchValue(<VideoCodec>codec);
      this.nullProfile.setValue(codec?.profile === null);
      this.nullVideoCodec.setValue(codec?.video_codec === null);
      this.#setAsyncValidators(true);
      this.loading$.next(false);
    });
  }

  #post() {
    const payload = <PostVideoCodec>{ ...this.form.getRawValue() };
    return this.api.post(payload);
  }

  #patch() {
    const uuid = this.codecUuid as string;
    const payload = <VideoCodec>{ ...this.form.getRawValue(), uuid };
    return this.api.patch(uuid, payload);
  }

  #setAsyncValidators(withValue = false) {
    this.form.setAsyncValidators(
      codecsUniqueValidator(
        this.dataSource.videoCodecsApiService.getAll().pipe(shareReplay()),
        withValue ? this.initialValue : null,
      ),
    );
  }
}
