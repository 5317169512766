export interface QualityControlConfig {
  id?: string;
  uuid?: string;
  asset_type: string;
  file_extension: string[];
  timecode_start: string[];
  dropped_frames: boolean | null;
  video_codec: string[];
  bit_depth: string[];
  chroma_format: string[];
  framerate: string[];
  interlace_mode: string[];
  width: number[];
  height: number[];
  audio_codec: string[];
  channel_count: number[];
  channels_per_track: number[];
  audio_sample_rate: number[];
  audio_bit_depth: number[];
}

export enum QualityControlConfigEndpoints {
  MANY = 'qa-configs',
  ONE = 'qa-config',
}

export interface QualityControlAnalyseConfigParam {
  uuid: string;
  qaConfig: string;
  param: string;
  detectedValue: (string | number)[];
  acceptedValues: (string | number)[];
  status: boolean;
}
export type QualityControlAnalyseConfig = Array<QualityControlAnalyseConfigParam>;

export type QualityControlAnalyseConfigs = Array<QualityControlAnalyseConfig>;
