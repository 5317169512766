import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ActivatedClientService } from '../logic/services/activated-client.service';
import { Permission } from '../permission/permission';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PermissionService } from '../permission/permission.service';

export type RequiresPermissionsComparator = 'some' | 'every';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[requiresPermissions]', // TODO: consider prefix here (readability)
})
export class RequiresPermissionsDirective implements OnInit, OnDestroy {
  @Input() public requiresPermissions: Permission[] = [];
  @Input() public requiresPermissionsComparison: RequiresPermissionsComparator = 'every';

  private destroy$ = new Subject<boolean>();

  constructor(
    private readonly activatedClientService: ActivatedClientService,
    private readonly permissionService: PermissionService,
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
  ) {}

  public ngOnInit(): void {
    this.activatedClientService.permissions$
      .pipe(
        takeUntil(this.destroy$),
        tap((userClientPermissions: Permission[]) =>
          this.permissionService.verifyPermissions(
            this.requiresPermissions,
            userClientPermissions,
            this.requiresPermissionsComparison,
          )
            ? this.#recreateEmbeddedView()
            : this.viewContainer.clear(),
        ),
      )
      .subscribe();
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  #recreateEmbeddedView = (): void => {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef).detectChanges();
  };
}
