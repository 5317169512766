import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '@vdms-hq/auth';
import { DialogResponse, filterEmpty, SelectOptionKey } from '@vdms-hq/shared';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { ColumnSettingsScope, UserContractService } from '@vdms-hq/firebase-contract';
import { FiltersConfigDialogComponent } from './components/filters-config-dialog/filters-config-dialog.component';

@Injectable()
export class FiltersConfigService {
  constructor(
    public readonly matDialog: MatDialog,
    private authService: AuthService,
    private activatedClient: ActivatedClientService,
    private userContractService: UserContractService,
  ) {}

  popFiltersConfigDialog(scopeName: ColumnSettingsScope) {
    const dialogRef = this.matDialog.open(FiltersConfigDialogComponent, { data: { scopeName } });
    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((data: { status: number; columns: SelectOptionKey[] }) => !!data && data.status === DialogResponse.OK),
        map((data: { columns: SelectOptionKey[] }) => data.columns),
        switchMap((filters: SelectOptionKey[]) => this.update(filters, scopeName)),
      )
      .subscribe({
        error: (err) => {
          console.error(err);
        },
      });
  }

  update(filtersConfig: SelectOptionKey[], scopeName: ColumnSettingsScope) {
    return this.authService.auth$.pipe(
      filterEmpty(),
      take(1),
      withLatestFrom(this.activatedClient.clientDefinite$),
      switchMap(([user, client]) => {
        const clientLevel = client.filters?.[scopeName];
        const userLevel = filtersConfig;
        const sameConfig =
          clientLevel?.length === userLevel.length && clientLevel.every((value, index) => value === userLevel[index]);

        return this.userContractService.updateClient(user.email, client.uuid, {
          filtersConfig: {
            [scopeName]: sameConfig ? [] : (userLevel as string[]),
          },
        });
      }),
    );
  }
}
