import { AbstractControl, ValidationErrors } from '@angular/forms';

export class DeliveryDestinationsValidators {
  static hybrikProfileJsonValidator(hybrikProfile: AbstractControl): ValidationErrors | null {
    const { value } = hybrikProfile;

    if (!value) {
      return null;
    }

    try {
      JSON.parse(value);
      return null;
    } catch (e) {
      return { hybrikProfileIsNotJson: true };
    }
  }
}
