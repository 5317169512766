import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StreamService } from '@vdms-hq/api-contract';
import {
  FilterStreamMsgFactory,
  generateProgressToastMessage,
  IMAGE_CACHE_ENUM,
  ImageCacheService,
  WebsocketCartNotificationMessage,
  WebsocketNotificationActionEnum,
  WebsocketNotificationInterface,
  WebsocketProgressInterface,
  WsProgressToast,
} from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import { CartStateService } from './cart-state.service';

@Injectable({ providedIn: 'root' })
export class CartWebsocketService {
  #popToast = {
    WS_ADD_TO_CART_PROGRESS: (
      id: string,
      percent: number,
      counter?: { processing: number; all: number; errors: WebsocketCartNotificationMessage },
    ) =>
      this.toastService.processing({
        id,
        message: generateProgressToastMessage(
          'cart',
          percent,
          this.imageCache.getImage(IMAGE_CACHE_ENUM.PROGRESS_LOADER_INFO_ICON),
          counter,
        ),
      }),
    WS_ADD_TO_CART_SUCCESS: (id: string) =>
      this.toastService.success({
        id,
        message: 'pages.cart.notifications.add.done',
      }),
    WS_ADD_TO_CART_ERROR: (id: string) =>
      this.toastService.error({
        id,
        message: 'notifications.websockets.cart_failed',
      }),
  };
  readonly connect$ = this.streamService.connect();

  constructor(
    private streamService: StreamService,
    private toastService: ToastService,
    private imageCache: ImageCacheService,
    private cartStateService: CartStateService,
  ) {}

  registerWebSocketListener(): Observable<WebsocketNotificationInterface<WebsocketProgressInterface>> {
    this.#initToastElement();

    return this.connect$.pipe(
      FilterStreamMsgFactory([WebsocketNotificationActionEnum.CART_ADD]),
      WsProgressToast({
        SUCCESS: (groupId: string) => {
          this.#popToast.WS_ADD_TO_CART_SUCCESS(groupId);
          this.cartStateService.refreshCart();
          this.cartStateService.isUpdating$.next(false);
        },
        PROGRESS: this.#popToast.WS_ADD_TO_CART_PROGRESS,
        ERROR: (groupId: string) => {
          this.#popToast.WS_ADD_TO_CART_ERROR(groupId);
          this.cartStateService.isUpdating$.next(false);
        },
      }),
    );
  }

  #initToastElement() {
    this.imageCache.cacheImage(IMAGE_CACHE_ENUM.PROGRESS_LOADER_INFO_ICON);
  }
}
