<vdms-hq-ui-dialog-wrapper [footerAlign]="'space-between'">
  <h1 title class="title">
    {{ 'common.notification_subscriptions.trigger.title' | translate }}
  </h1>

  <div content class="dialog-content">
    <vdms-hq-ui-form-input-chips
      [formControl]="form"
      [autocompleteOptions]="(emailOptions$ | async) ?? []"
      [placeholder]="'pages.delivery_destinations.form.email' | translate"
      matTooltip="Press enter to add"
      matTooltipPosition="above"
      (inputChange)="valueChange($event)"
      [label]="'pages.delivery_destinations.form.email' | translate"
    ></vdms-hq-ui-form-input-chips>
  </div>

  <ng-container footer>
    <vdms-hq-ui-button (click)="close()" color="secondary">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button
      (click)="confirm()"
      [loading]="(loading$ | async) ?? false"
      [disabled]="form.invalid || form.pristine"
      color="primary"
    >
      {{ 'common.global.confirm' | translate }}
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
