import { Pipe, PipeTransform } from '@angular/core';
import { Column, ValueColumn } from '../logic/column-config';

@Pipe({
  name: 'isColumnValue',
  standalone: true,
})
export class IsColumnValuePipe implements PipeTransform {
  static isColumnValue(column: Column): false | ValueColumn {
    return !('type' in column) ? (column as ValueColumn) : false;
  }

  transform(column: Column) {
    return IsColumnValuePipe.isColumnValue(column);
  }
}
