import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionDetailsType } from '@vdms-hq/api-contract';
import { TransactionsDataSource } from '../logic/transactions.ds';
import {
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UILayoutModule,
  UILoaderModule,
} from '@vdms-hq/ui';

@Component({
  selector: 'vdms-hq-asset-preview-transactions',
  standalone: true,
  imports: [CommonModule, UILayoutModule, UILoaderModule, MultipleDataPresentationComponent],
  providers: [TransactionsDataSource, UILoaderModule],
  templateUrl: './asset-preview-transactions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetPreviewTransactionsComponent implements OnInit {
  @Input() transactions: TransactionDetailsType[] = [];
  @Input() withHeader = true;

  configuration: MultipleViewConfiguration<TransactionDetailsType> = {
    multiView: {
      defaultView: 'tableAdvanced',
      fitToVisibleArea: false,
      emptyResults: {
        message: 'Could not find any entities',
      },
    },
    tableAdvanced: {
      actions: [],
      columns: [
        {
          id: 'transaction_id',
          label: 'ID',
          valuePath: 'id',
        },
        {
          id: 'transaction_order_id',
          label: 'Order ID',
          valuePath: 'order_id',
        },
        {
          id: 'transaction_type',
          label: 'Type',
          valuePath: 'type',
        },
        {
          id: 'ai_model_name',
          label: 'AI Model Name',
          valuePath: 'ai_model_name',
        },
        {
          id: 'created_date',
          label: 'Created Date',
          valuePath: 'created_at',
          viewFormat: {
            modifiers: {
              dateFormat: 'date-time',
            },
          },
        },
        {
          id: 'created_by',
          label: 'Created By',
          valuePath: 'created_by.name',
        },
      ],
      columnsEnabled: [
        'transaction_id',
        'transaction_order_id',
        'transaction_type',
        'ai_model_name',
        'created_date',
        'created_by',
      ],
    },
  };

  constructor(public dataSource: TransactionsDataSource) {}

  ngOnInit(): void {
    this.dataSource.addTransactions(this.transactions ?? []);
  }
}
