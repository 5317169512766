export const POSSIBLE_KEYS = [
  'filename',
  'episodeName',
  'episodeNumber',
  'seriesName',
  'seriesNumber',
  'programmeNumber',
  'facilityOfOrigin',
  'language',
  'elements',
  'segments',
  'genre',
  'productionCompany',
  'contentClass',
  'contentType',
  'theme',
  'variation',
  'category',
  'seasonTitle',
  'releaseYear',
] as const;
export const POSSIBLE_CASINGS = ['camelCase', 'PascalCase', 'snake_case', 'kebab-case', 'UPPER_CASE'] as const;
export type PossibleKeyType = (typeof POSSIBLE_KEYS)[number];
export type PossibleCasingType = (typeof POSSIBLE_CASINGS)[number];

export interface FilenameAnalysisRequest {
  data: FilenameAnalysisInputData[];
  conventions: FilenameAnalysisInputConvention[];
}
export interface FilenameAnalysisInputData {
  uuid: string;
  filename: string;
}
export interface FilenameAnalysisInputConvention {
  id: number;
  key: string;
  separator: string;
}
export interface FilenameAnalysisOutputData extends FilenameAnalysisInputData {
  matches: FilenameAnalysisMatchType[];
}
export interface FilenameAnalysisConventionObjectType {
  index: number;
  original: string;
  key: PossibleKeyType;
  casing: PossibleCasingType;
}
export interface FilenameAnalysisResultType extends FilenameAnalysisConventionObjectType {
  value: string;
  score: number;
  match: string;
  percents?: number;
}
export interface FilenameAnalysisMatchType {
  conventionId: number;
  parts: FilenameAnalysisResultType[];
}

export const TRANSLATED_POSSIBLE_KEYS: Record<PossibleKeyType, string> = {
  filename: 'Filename',
  episodeName: 'Episode Name',
  episodeNumber: 'Episode Number',
  seriesName: 'Series Name',
  seriesNumber: 'Series Number',
  programmeNumber: 'Programme Number',
  facilityOfOrigin: 'Facility of Origin',
  language: 'Language',
  elements: 'Elements',
  segments: 'Segments',
  genre: 'Genre',
  productionCompany: 'Production Company',
  contentClass: 'Content Class',
  contentType: 'Content Type',
  theme: 'Theme',
  variation: 'Variation',
  category: 'Category',
  seasonTitle: 'Season Title',
  releaseYear: 'Release Year',
};
