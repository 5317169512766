import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadAttachmentComponent } from './view/upload-attachment/upload-attachment.component';
import { ListAttachmentComponent } from './view/list-attachment/list-attachment.component';
import { ApiContractModule } from '@vdms-hq/api-contract';
import { UIButtonModule, UIDialogModule, UIEmptyResultsModule, UILayoutModule, UILoaderModule } from '@vdms-hq/ui';
import { SharedModule } from '@vdms-hq/shared';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FilenameFromPathPipe } from './logic/filename-from-path.pipe';
import { ActivatedClientModule } from '@vdms-hq/activated-client';
import { FormFileComponent, SelectedFileComponent } from '../storage-multipart-upload';

@NgModule({
  declarations: [UploadAttachmentComponent, ListAttachmentComponent, FilenameFromPathPipe],
  imports: [
    CommonModule,
    ApiContractModule,
    UILoaderModule,
    UIDialogModule,
    UIButtonModule,
    SharedModule,
    MatIconModule,
    FormsModule,
    MatProgressBarModule,
    ActivatedClientModule,
    SelectedFileComponent,
    FormFileComponent,
    UILayoutModule,
    UIEmptyResultsModule,
  ],
  exports: [
    UploadAttachmentComponent,
    FormFileComponent,
    SelectedFileComponent,
    ListAttachmentComponent,
    FilenameFromPathPipe,
  ],
})
export class StorageAttachmentsModule {}
