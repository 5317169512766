import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDividerModule } from '@angular/material/divider';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CartStateService } from '@vdms-hq/cart-core';
import {
  InfoBarClickAction,
  InfoBarType,
  SuffixConfig,
  UIButtonModule,
  UIFormModule,
  UILayoutModule,
  UILoaderModule,
  UiPriceComponent,
} from '@vdms-hq/ui';
import { CartDataSource } from '../../logic/data-sources/cart-data-source';
import { CartActions, CartActionsType } from '../../logic/models/cart-actions.model';
import { CartCheckoutFormService } from '../../logic/services/cart-checkout-form.service';
import { CartCheckoutActions } from '../../logic/services/cart-checkout.service';
import { CartCheckoutActionsComponent } from '../cart-checkout-actions/cart-checkout-actions.component';
import { GroupCurrencyISO, SharedModule } from '@vdms-hq/shared';
import { DISCOUNT_STATUS } from '@vdms-hq/api-contract';

@Component({
  selector: 'vdms-hq-cart-summary',
  standalone: true,
  imports: [
    CommonModule,
    CartCheckoutActionsComponent,
    MatDividerModule,
    TranslateModule,
    UIButtonModule,
    SharedModule,
    UILoaderModule,
    UIFormModule,
    UILayoutModule,
    UiPriceComponent,
  ],
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.scss'],
})
export class CartSummaryComponent implements OnDestroy {
  protected readonly GroupCurrencyISO = GroupCurrencyISO;
  protected readonly CartActions = CartActions;
  protected readonly InfoBarType = InfoBarType;
  protected readonly InfoBarClickAction = InfoBarClickAction;
  protected readonly DISCOUNT_STATUS = DISCOUNT_STATUS;
  dataSource = inject(CartDataSource);
  cartState = inject(CartStateService);
  formService = inject(CartCheckoutFormService);

  router = inject(Router);

  clientDiscountEnabled$ = this.cartState.clientDiscountEnabled$;

  destroyed$ = new Subject<void>();
  @Input() config: CartCheckoutActions = [];

  @Output() actions: EventEmitter<CartActionsType> = new EventEmitter();

  discountInputConfig: SuffixConfig = {
    text: 'Apply',
    type: 'primary-transparent',
    onFocus: true,
  };

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get totalDiscount(): string {
    const summary = this.dataSource.cartSummary$.value;
    if (!summary || !summary.price.total || !summary.discount_prices.total) {
      return '';
    }
    return (parseFloat(summary.price.total) - parseFloat(summary.discount_prices.total)).toFixed(2);
  }

  cancel() {
    this.formService.resetForm();
    this.cartState.checkoutStep$.next(0);
    this.router.navigate(['/cart-v3']);
  }
}
