import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

type OuterValue = boolean | undefined | null;
type InnerValue = boolean | null;

@Component({
  selector: 'vdms-hq-ui-form-input-toggle',
  templateUrl: './form-input-toggle.component.html',
  styleUrls: ['./form-input-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputToggleComponent),
    },
  ],
})
export class FormInputToggleComponent extends FormControlValueAccessorComponent<OuterValue, InnerValue> {
  innerFormControl = new UntypedFormControl(false);

  override transformOuterToInner(value: OuterValue): InnerValue {
    return value === undefined ? false : value;
  }
}
