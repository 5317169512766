import { CommonModule } from '@angular/common';
import { AuthModule } from '@vdms-hq/auth';
import { UIButtonModule, UIFormModule, UILoaderModule, UIModule } from '@vdms-hq/ui';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { NgModule } from '@angular/core';
import { ProviderButtonsComponent } from './components/provider-buttons/provider-buttons.component';
import { LoginLogoComponent } from './components/login-logo/login-logo.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TokenFormComponent } from './components/token-form/token-form.component';

const uiModules = [UIModule, UILoaderModule, UIFormModule, UIButtonModule];

const exportedComponents = [LoginFormComponent, TokenFormComponent, ProviderButtonsComponent, LoginLogoComponent];

@NgModule({
  imports: [CommonModule, AuthModule, ...uiModules, TranslateModule, MatDialogModule],
  declarations: exportedComponents,
  exports: exportedComponents,
})
export class LoginFormModule {}
