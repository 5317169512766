import moment from 'moment/moment';

/**
 * @deprecated
 * comparator confuses the logic.
 *
 * Use isEmbargoActive instead.
 */
export const isEmbargoExpired = (
  embargoed_to: string | undefined | null,
  comparator: '>=' | '<=' | '>' | '<',
): boolean => {
  if (!embargoed_to) {
    return false;
  }

  switch (comparator) {
    case '>':
      return moment().diff(moment(embargoed_to), 'minutes') > 0;
    case '<':
      return moment().diff(moment(embargoed_to), 'minutes') < 0;
    case '<=':
      return moment().diff(moment(embargoed_to), 'minutes') <= 0;
    case '>=':
      return moment().diff(moment(embargoed_to), 'minutes') >= 0;
    default:
      return false;
  }
};

export const isEmbargoActive = (embargoed_to: string | undefined | null): boolean => {
  if (!embargoed_to) {
    return false;
  }

  const embargoedMoment = moment(embargoed_to).local();
  const now = moment();

  return now.isSameOrBefore(embargoedMoment);
};
