<div class="material-custom-form"
     [class.with-touched-indicator]="withTouchedIndicator">
  <span class="field-label">{{label}}</span>

  <mat-slider
    [max]="sliderMaxValue"
    [min]="sliderMinValue"
    [step]="1"
    [discrete]="true"
    [displayWith]="formatLabel"
    showTickMarks
  >
    <input matSliderThumb (valueChange)="update($event)">
  </mat-slider>
  <ng-content select="[action]"></ng-content>

  <mat-hint *ngIf="withFooter && hint">{{hint}}</mat-hint>
  <mat-error *ngIf="withFooter">
    <span *ngFor="let error of errors">{{error.transLang| translate: error.contextParams}}</span>
  </mat-error>
  <vdms-hq-ui-loader matSuffix [size]="20" *ngIf="innerFormControl.pending"></vdms-hq-ui-loader>
</div>
