import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FilteredResultsService {
  sidebarOpened$?: BehaviorSubject<boolean>;

  toggleFilters() {
    this.sidebarOpened$?.next(!this.sidebarOpened$.value);
  }
}
