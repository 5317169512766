import { AbstractControl, ValidatorFn } from '@angular/forms';

type UsersFlatModel = { email: string; groups?: { uuid: string }[] };

export function EmailExistValidator(users: UsersFlatModel[], userEmail: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === userEmail) return { emailExist: true };
    const foundUserInGroup = users.find((user) => user.email === control.value);

    return control.value ? (foundUserInGroup ? { emailExist: true } : null) : null;
  };
}
