<div class="input-phone__wrapper" [formGroup]="innerFormControl" [class.hide-footer]="!withFooter">
  <div class="fields">
    <vdms-hq-ui-form-input-select
      [label]="'ui-form.fields.country_code' | translate"
      formControlName="code"
      class="code"
      [selectOptions]="countryCodes"
      (valueChange)="updatePrefix($any($event))"
      [withFooter]="false"
    ></vdms-hq-ui-form-input-select>
    <vdms-hq-ui-form-input-number
      [prefixText]="phonePrefix"
      formControlName="number"
      class="number"
      [placeholder]="exampleNumber"
      [label]="numberLabel | translate"
      [withFooter]="false"
    ></vdms-hq-ui-form-input-number>
  </div>

  <div class="input-phone__footer">
    <mat-hint *ngIf="withFooter && hint">{{ hint }}</mat-hint>
    <mat-error *ngIf="withFooter">
      <span *ngFor="let error of errors">{{ error.transLang | translate : error.contextParams }}</span>
    </mat-error>
  </div>
</div>
