<vdms-hq-ui-form-input-select
  [withTouchedIndicator]="withTouchedIndicator"
  [formControl]="innerFormControl"
  [withFooter]="withFooter"
  [multiple]="multiple"
  [virtualScroll]="virtualScroll"
  [required]="isRequired"
  [loading]="!!(loading$ | async)"
  [enableDeselectAll]="enableDeselectAll"
  [label]="label | translate"
  [placeholder]="placeholder | translate"
  [selectOptions]="(list$.value | async) ?? []"
  [newField]="newField"
>
</vdms-hq-ui-form-input-select>
