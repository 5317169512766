import { DynamicFilterInput } from '@vdms-hq/dynamic-filters';
import { ValueFormat, SelectOption, FilterType, ResourceModel } from '@vdms-hq/shared';
import { SORT_BY } from '@vdms-hq/api-contract';

export function getFilters(contracts: SelectOption[], partners: SelectOption[]): DynamicFilterInput[] {
  return [
    {
      id: 'keyword',
      label: 'common.license_package.table.filter_placeholder',
      resource: [ResourceModel.LICENSED_PACKAGE],
      format: ValueFormat.AS_IS,
      filters: {
        objectPath: 'name',
        validFormat: 'keyword',
        type: FilterType.MASTER_TEXT,
      },
    },
    {
      id: 'partners',
      label: 'common.license_package.table.partners_placeholder',
      resource: [ResourceModel.LICENSED_PACKAGE],
      format: ValueFormat.SELECTOR_MULTI,
      filters: {
        objectPath: 'partners_uuid',
        validFormat: 'keyword',
        type: FilterType.SELECTOR,
      },
      selectOptions: partners,
      isMultiple: true,
      enableDeselectAll: true,
      enableSelectAll: true,
    },
    {
      id: 'contracts',
      label: 'common.license_package.table.contracts_placeholder',
      resource: [ResourceModel.LICENSED_PACKAGE],
      format: ValueFormat.SELECTOR_MULTI,
      filters: {
        objectPath: 'contracts_uuid',
        validFormat: 'keyword',
        type: FilterType.SELECTOR,
      },
      selectOptions: contracts,
      isMultiple: true,
      enableDeselectAll: true,
      enableSelectAll: true,
    },
    {
      id: 'sort',
      label: 'common.orders.filters.sort',
      resource: [ResourceModel.ASSET_ORDER],
      format: ValueFormat.AS_IS,
      filters: {
        validFormat: 'keyword',
        objectPath: 'sort',
        type: FilterType.SELECTOR,
      },
      selectOptions: SORT_BY,
    },
  ];
}
