import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pagination } from '@vdms-hq/ui';
import {
  GetResponseData,
  GetResponsePaginationData,
  GetUploadResponseData,
} from '../../operators/get-response-data.operator';
import { ApiLicensedPackageResponse, ApiPaginatedResponse, ApiResponse } from '../api.model';
import {
  LICENSED_PACKAGE_ENDPOINTS,
  LicensedPackage,
  LicensedPackageAssetGet,
  LicensedPackageCreate,
  LicensedPackageDownloadPayload,
  LicensedPackageFiltersView,
  LicensedPackageListItem,
  LicensedPackagePatchItems,
  LicensedPackageUpdate,
} from './license-packages.model';
import { ApiService } from '../api.service';
import { AwsV4Signature } from '../upload/upload';
import { AssetSearchFilterParam } from '../asset';
import { AsperaDownloadableFile } from '../aspera/aspera.model';

@Injectable({ providedIn: 'root' })
export class LicensePackagesService {
  constructor(private readonly apiService: ApiService) {}

  getMany = (pagination?: Pagination, filters?: LicensedPackageFiltersView) => {
    let headers = new HttpHeaders();
    let params = new HttpParams();
    headers = pagination ? pagination.applyToHeaders(headers) : headers;
    headers = headers.append('Cache-Control', 'max-age=0');
    if (filters) {
      headers = filters.keyword ? headers.append('filter', filters.keyword) : headers;
      headers = filters.view_state ? headers.append('view-state', filters.view_state) : headers;
      headers = filters.status ? headers.append('status', filters.status) : headers;
      params =
        filters.partners && filters.partners.length ? params.appendAll({ partner_uuids: filters.partners }) : params;
      params =
        filters.contracts && filters.contracts.length
          ? params.appendAll({ contract_uuids: filters.contracts })
          : params;
    }
    const url = `${LICENSED_PACKAGE_ENDPOINTS.ROOT_LIST}`;
    return this.apiService
      .get<ApiPaginatedResponse<LicensedPackageListItem>>(url, {
        headers,
        params,
      })
      .pipe(GetResponsePaginationData);
  };

  getOne = (uuid: string) => {
    const headers = new HttpHeaders().set('Cache-Control', 'max-age=0');
    const url = `${LICENSED_PACKAGE_ENDPOINTS.ROOT}/${uuid}`;
    return this.apiService.get<ApiResponse<LicensedPackage>>(url, { headers }).pipe(GetResponseData);
  };

  create = (title: LicensedPackageCreate) => {
    return this.apiService
      .post<LicensedPackageCreate, ApiResponse<LicensedPackage>>(`${LICENSED_PACKAGE_ENDPOINTS.ROOT}`, title)
      .pipe(GetResponseData);
  };

  patch = (uuid: string, data: LicensedPackageUpdate) => {
    return this.apiService
      .patch<LicensedPackageUpdate, ApiResponse<LicensedPackage>>(`${LICENSED_PACKAGE_ENDPOINTS.ROOT}/${uuid}`, data)
      .pipe(GetResponseData);
  };

  updateAndGetSignature = (uuid: string, data: LicensedPackageUpdate) => {
    return this.apiService
      .patch<LicensedPackageUpdate, ApiLicensedPackageResponse<LicensedPackage, AwsV4Signature>>(
        `${LICENSED_PACKAGE_ENDPOINTS.ROOT}/${uuid}`,
        data,
      )
      .pipe(GetUploadResponseData);
  };

  remove = (uuid: string) => {
    return this.apiService.delete<null, undefined>(`${LICENSED_PACKAGE_ENDPOINTS.ROOT}/${uuid}`);
  };

  getItems = (uuid: string, pagination?: Pagination) => {
    let headers = new HttpHeaders();
    if (pagination) {
      headers = pagination.applyToHeaders(headers);
    }
    headers = headers.append('Cache-Control', 'max-age=0');
    return this.apiService
      .get<ApiPaginatedResponse<LicensedPackageAssetGet>>(`${LICENSED_PACKAGE_ENDPOINTS.ROOT}/${uuid}/items`, {
        headers,
      })
      .pipe(GetResponsePaginationData);
  };

  removeItems = (uuid: string, items: string[]) => {
    return this.apiService.delete<string[], { items: string[] }>(
      `${LICENSED_PACKAGE_ENDPOINTS.ROOT}/${uuid}/remove-items`,
      { items },
    );
  };

  addItems = (uuid: string, items: LicensedPackagePatchItems) => {
    return this.apiService.patch<LicensedPackagePatchItems, string[]>(
      `${LICENSED_PACKAGE_ENDPOINTS.ROOT}/${uuid}/add-items`,
      items,
    );
  };

  addFilters = (uuid: string, filters: AssetSearchFilterParam) => {
    return this.apiService.patch<AssetSearchFilterParam, string[]>(
      `${LICENSED_PACKAGE_ENDPOINTS.ROOT}/${uuid}/add-search`,
      filters,
    );
  };

  downloadAssets(uuid: string, payload: LicensedPackageDownloadPayload) {
    const url = `${LICENSED_PACKAGE_ENDPOINTS.ROOT}/${uuid}/download`;
    return this.apiService.post<LicensedPackageDownloadPayload, ApiResponse<AsperaDownloadableFile>>(url, payload);
  }
}
