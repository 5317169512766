import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TimelineService } from '../../logic/timeline.service';
import { UIButtonModule, UIFormModule } from '@vdms-hq/ui';
import { SelectorsModule } from '@vdms-hq/selectors';

@Component({
  selector: 'vdms-hq-advanced-timeline',
  templateUrl: './advanced-timeline.component.html',
  styleUrls: ['advanced-timeline.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, UIButtonModule, UIFormModule, SelectorsModule],
})
export class AdvancedTimelineComponent {
  zoomOptions = [100, 125, 150, 175, 200, 300, 400, 500, 600, 1000, 1500].map((percent) => ({
    key: String(percent),
    label: `${percent}%`,
  }));
  constructor(public timelineService: TimelineService) {}
}
