import { Injectable } from '@angular/core';
import { catchError, Observable, of, startWith } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { CollectionModelPatch, CollectionsService } from '@vdms-hq/api-contract';
import { S3SignatureUploadService, UploadState } from '@vdms-hq/storage';
import { CollectionUploadStatus } from '../models/statuses-type.model';
import { FileUploadState } from '@vdms-hq/storage';

export type CollectionUpdateAndUploadState = FileUploadState<CollectionUploadStatus>;

@Injectable({ providedIn: 'root' })
export class CollectionsUploadService {
  constructor(
    private collectionsService: CollectionsService,
    private signatureUploadService: S3SignatureUploadService,
  ) {}

  updateCollectionAndUpload(
    file: File,
    id: string,
    collection: CollectionModelPatch,
  ): Observable<CollectionUpdateAndUploadState> {
    const initState: CollectionUpdateAndUploadState = {
      status: 'updating',
      state: UploadState.IN_PROGRESS,
    };

    const updateCollection$ = this.collectionsService
      .updateAndGetSignature(id, {
        ...collection,
        filename: uuidv4() + '.' + file.name.substring(file.name.lastIndexOf('.') + 1),
      })
      .pipe(
        map(
          (item) =>
            ({
              state: UploadState.IN_PROGRESS,
              status: 'collection_updated',
              signature: item.data,
            } as CollectionUpdateAndUploadState),
        ),
        catchError((error) =>
          of({
            state: UploadState.ERROR,
            status: 'collection_error',
            error,
          } as CollectionUpdateAndUploadState),
        ),
      );

    return updateCollection$.pipe(
      startWith(initState),
      switchMap((state) => {
        if (state.status === 'collection_updated' && state.signature) {
          return this.signatureUploadService.uploadSmallFileWithSignature(file, state.signature).pipe(
            map(
              (uploadProcess) =>
                ({
                  ...state,
                  status: uploadProcess.uploaded ? 'done' : 'uploading',
                  state: uploadProcess.uploaded ? UploadState.COMPLETED : UploadState.IN_PROGRESS,
                  file: uploadProcess,
                } as CollectionUpdateAndUploadState),
            ),
          );
        }
        return of(state);
      }),
    );
  }
}
