export interface PreviewErrorModel {
  header: string;
  message?: string;
}

export class PreviewNotAvailable extends Error implements PreviewErrorModel {
  header = 'Preview not available';
}

export class FileNotYetAvailable extends Error implements PreviewErrorModel {
  header = 'File not yet available';
}
