<ng-container *ngIf="label">
  <span class="grid__status__label">{{ label }}:</span>
</ng-container>

<ng-template #iconOnly>
  <div class="grid__status__icon" [matTooltip]="tooltip || '' | translate">
    <mat-icon>{{ icon }}</mat-icon>
  </div>
</ng-template>

<ng-template #iconAndText>
  <div class="grid__status__icon">
    <mat-icon>{{ icon }}</mat-icon>
  </div>
  <div class="grid__status__text">
    <ng-content></ng-content>
  </div>
</ng-template>

<ng-template #textOnly>
  <div class="grid__status grid__status--{{ color }}" [class.grid__status--dot-hidden]="dotHidden">
    <ng-content></ng-content>
  </div>
</ng-template>

<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'icon'">
    <ng-container *ngTemplateOutlet="iconOnly"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'iconAndText'">
    <ng-container *ngTemplateOutlet="iconAndText"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'textOnly'">
    <ng-container *ngTemplateOutlet="textOnly"></ng-container>
  </ng-container>
</ng-container>
