import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActionIdentifier,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  ResultsWrapperViewConfiguration,
  UILoaderModule,
  UIResultsWrapperModule,
} from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { MandatoryFieldsDatasource } from '../../logic/mandatory-fields.datasource';
import { MatDialog } from '@angular/material/dialog';
import { filter, take } from 'rxjs';
import { ToastService } from '@vdms-hq/toast';
import { EditDialogComponent } from '../../components/edit-dialog/edit-dialog.component';

@Component({
  selector: 'vdms-hq-mandatory-fields',
  standalone: true,
  imports: [CommonModule, MultipleDataPresentationComponent, TranslateModule, UILoaderModule, UIResultsWrapperModule],
  providers: [MandatoryFieldsDatasource],
  templateUrl: './mandatory-fields.component.html',
})
export class MandatoryFieldsComponent implements OnInit {
  headerConfig: ResultsWrapperViewConfiguration = {
    headerActions: [
      {
        key: 'new',
        name: 'common.mandatory-fields.new',
        color: 'primary',
      },
    ],
    withLoading: true,
  };

  viewConfig: MultipleViewConfiguration<unknown> = {
    multiView: {
      defaultView: 'tableAdvanced',
      fitToVisibleArea: true,
    },
    tableAdvanced: {
      actions: [
        {
          key: 'edit',
          icon: 'edit',
          onDoubleClick: false,
          label: 'common.global.edit',
        },
      ],
      columns: [
        {
          id: 'content_type',
          label: 'Content Type',
          valuePath: 'contentType',
        },
        {
          id: 'fields',
          label: 'Fields',
          valuePath: 'fields',
          foldValues: true,
        },
        {
          id: 'actions',
          type: 'actions',
        },
      ],
      columnsEnabled: ['content_type', 'fields', 'actions'],
    },
  };

  constructor(public dataSource: MandatoryFieldsDatasource, private dialog: MatDialog, private toast: ToastService) {}

  ngOnInit(): void {
    this.dataSource.refresh();
  }

  handleAction($event: { key: ActionIdentifier; item?: { contentType: string; fields: string[] } }) {
    const { key, item } = $event;

    switch (key) {
      case 'new':
        this.#new();
        break;
      case 'edit':
        if ($event?.item) {
          this.#edit($event.item.contentType);
        }
        break;
    }
  }

  #new() {
    this.dialog
      .open(EditDialogComponent)
      .afterClosed()
      .pipe(take(1), filter(Boolean))
      .subscribe(() => {
        this.toast.success({ id: 'success', message: 'common.notifications.update.done' });
        this.dataSource.refresh();
      });
  }

  #edit(contentType: string) {
    this.dialog
      .open(EditDialogComponent, { data: { contentType } })
      .afterClosed()
      .pipe(take(1), filter(Boolean))
      .subscribe(() => {
        this.toast.success({ id: 'success', message: 'common.notifications.update.done' });
        this.dataSource.refresh();
      });
  }
}
