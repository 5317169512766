<vdms-hq-ui-layout-section
  [margin]="false"
  [breadcrumbs]="withHeader ? [{ name: 'common.admin.components.peak_meter' }] : undefined"
>
  <div class="peak-wrapper">
    <vdms-hq-ui-info-bar
      *ngIf="(playerService.state$ | async)?.state !== 'ready'; else audioMeter"
      [type]="InfoBarType.INFO"
      message="Please initialize player to enable audio peak meter."
    ></vdms-hq-ui-info-bar>
    <ng-template #audioMeter>
      <vdms-hq-ui-form-input-toggle
        [ngModel]="peakMeterService.isConnected$ | async"
        (ngModelChange)="peakMeterService.toggleEnabled()"
        label="Connect audio peak meter"
      ></vdms-hq-ui-form-input-toggle>
    </ng-template>
    <div class="peak-meter-scroll">
      <div>
        <div class="peak-meter-instances" id="peak-meter-wrapper" label="Connect audio to peak meter"></div>
        <div class="peak-meter-additional">
          <div
            *ngFor="let audioTrack of peakMeterService.audioTracks$ | async; let index = index"
            class="channels-{{ audioTrack?.audioTrack?.attrs?.CHANNELS }}"
          >
            <div class="peak-meter-labels">
              <span>L</span>
              <span>R</span>
              <ng-container *ngIf="audioTrack?.audioTrack?.attrs?.CHANNELS === '6'">
                <span>C</span>
                <span>LFE</span>
                <span>Ls</span>
                <span>Rs</span>
              </ng-container>
            </div>
            <span class="audio-track-name">{{ audioTrack.audioTrack.name }}</span>
            <div>
              <vdms-hq-ui-button
                size="small"
                [iconOnly]="true"
                icon="volume_up"
                *ngIf="audioTrack.element.volume !== 0"
                (click)="audioTrack.element.volume = 0"
              ></vdms-hq-ui-button>
              <vdms-hq-ui-button
                size="small"
                [iconOnly]="true"
                icon="volume_off"
                *ngIf="audioTrack.element.volume === 0"
                (click)="audioTrack.element.volume = 1"
              ></vdms-hq-ui-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</vdms-hq-ui-layout-section>
