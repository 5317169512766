import { Component, Input } from '@angular/core';

@Component({
  selector: 'vdms-hq-ui-empty-cart',
  templateUrl: './empty-cart.component.html',
  styleUrls: ['./empty-cart.component.scss'],
})
export class EmptyCartComponent {
  @Input() isLoading = false;
  @Input() navigateLink = '/browse';
  @Input() icon = 'shopping_cart';
  @Input() message = 'Your shopping cart is empty';
  @Input() btnText = 'Browse assets';
}
