import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIButtonModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { take } from 'rxjs';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FilenameAnalysisInputConvention, POSSIBLE_CASINGS } from '@vdms-hq/api-contract';
import { SelectOption } from '@vdms-hq/shared';
import { v4 as uuidv4 } from 'uuid';
import { METADATA_MINIMUM_PERCENT_ACCURACY } from '../../logic/filename-conventions.validator';
import { ToastService } from '@vdms-hq/toast';

@Component({
  selector: 'vdms-hq-filename-conventions',
  templateUrl: './filename-conventions.component.html',
  styleUrls: ['./filename-conventions.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UILayoutModule, TranslateModule, UIButtonModule, UIFormModule],
  providers: [],
})
export class FilenameConventionsComponent implements OnInit {
  conventions$ = this.activatedClient.metadataRecognition$;
  loading = false;
  form = new FormGroup({
    conventions: new FormArray<FormGroup>([]),
  });
  separatorSelectOptions: SelectOption[] = [
    { key: '.', label: '.' },
    { key: '_', label: '_' },
  ];
  casingSelectOptions: SelectOption[] = POSSIBLE_CASINGS.map((casing: string) => ({ key: casing, label: casing }));
  #savedPercentAccuracy: number | null = null;

  constructor(
    @Inject(METADATA_MINIMUM_PERCENT_ACCURACY) private percentAccuracy: number,
    private activatedClient: ActivatedClientService,
    private toastService: ToastService,
  ) {}

  get conventionsFormArray() {
    return this.form.controls.conventions;
  }

  ngOnInit() {
    this.conventions$.pipe(take(1)).subscribe((conventions) => {
      this.#savedPercentAccuracy = conventions?.minimumPercentageAccuracy ?? null;
      if (!conventions?.filenameConventions?.length) {
        this.add();
      }

      conventions?.filenameConventions.forEach((data) => this.add(data));
      this.form.markAsPristine();
      this.form.markAsUntouched();
    });
  }

  add(data?: FilenameAnalysisInputConvention) {
    this.conventionsFormArray.push(
      new FormGroup({
        id: new FormControl(data?.id ?? uuidv4()),
        key: new FormControl(data?.key ?? null, [Validators.required]),
        separator: new FormControl(data?.separator ?? null, [Validators.required]),
      }),
    );
  }

  save() {
    if (this.conventionsFormArray.invalid || this.loading) {
      return;
    }

    const payload = {
      filenameConventions: this.conventionsFormArray.value,
      minimumPercentageAccuracy: this.#savedPercentAccuracy ?? this.percentAccuracy,
    };
    this.activatedClient
      .update({ metadataRecognition: payload })
      .pipe(take(1))
      .subscribe(() => {
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.toastService.success({ id: 'metadata_recognition', message: 'notifications.update.done' });
      });
  }
}
