import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedClientService, Permission } from '@vdms-hq/activated-client';
import { QuarantineService } from '@vdms-hq/quarantine';
import { SidebarItem, SidebarNestedItem, UILayoutModule } from '@vdms-hq/ui';
import { Subject, switchMap } from 'rxjs';
import { map, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';
import { DISCOUNTS_ROUTER_BASE } from '@vdms-hq/discounts';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { ImportExportModule } from '../import-export/import-export.module';
import { ClientDataService } from '../general/logic/client-data.service';
import { adminNavItemsConfig } from './admin-nav-items.config';

@Component({
  selector: 'vdms-hq-admin-nav-wrapper',
  templateUrl: './admin-nav-wrapper.component.html',
  styleUrls: ['./admin-nav-wrapper.component.scss'],
  standalone: true,
  imports: [UILayoutModule, ImportExportModule, RouterOutlet, AsyncPipe],
})
export class AdminNavWrapperComponent implements OnInit, OnDestroy {
  private clientDataService = inject(ClientDataService);
  private quarantineService = inject(QuarantineService);
  private activatedClient = inject(ActivatedClientService);

  navItems: (SidebarItem | SidebarNestedItem)[] = [];
  version$ = this.activatedClient.clientDefiniteValueChanges$.pipe(
    map((current) => current?.version ?? 0),
    switchMap((currentVersion) =>
      this.activatedClient.snapshotLatestVersion$.pipe(
        startWith({ version: 0 }),
        map((latest) => latest?.version ?? 0),
        map((lastVersion) => (currentVersion < lastVersion ? `${currentVersion} (restored)` : currentVersion)),
      ),
    ),
  );
  clientDefinite$ = this.activatedClient.clientDefinite$;
  #destroy = new Subject<void>();

  ngOnDestroy(): void {
    this.#destroy.next();
    this.#destroy.complete();
  }

  ngOnInit() {
    this.activatedClient.clientDefiniteValueChanges$
      .pipe(
        takeUntil(this.#destroy),
        withLatestFrom(
          this.activatedClient.integrations$,
          this.activatedClient.permissions$,
          this.quarantineService.canManageQuarantineConfigs$,
        ),
      )
      .subscribe(([client, integrations, permissions, canManageQuarantineConfigs]) => {
        this.clientDataService.getClientData(client.uuid);
        const hasBrowseCredentials: boolean = permissions.some(
          (permission) => permission === Permission.BROWSE_CREDENTIALS,
        );
        const hasBrowseGlobalConfiguration: boolean = permissions.some(
          (permission) => permission === Permission.BROWSE_THRESHOLDS,
        );
        const hasBrowseRateCard: boolean = permissions.some((permission) => permission === Permission.BROWSE_RATES);
        const hasBrowseDiscounts: boolean = permissions.some(
          (permission) => permission === Permission.BROWSE_DISCOUNTS,
        );
        this.navItems = adminNavItemsConfig(client);

        if (integrations?.salesforce?.enabled) {
          (this.navItems?.filter((item) => item?.name === 'Asset tables')[0] as SidebarNestedItem)?.children?.push({
            name: 'Preview request',
            routerLink: '/admin/asset-tables/preview-request',
          });
        }
        if (hasBrowseCredentials) {
          this.navItems.push({
            name: 'Credentials',
            routerLink: 'credentials',
          });
        }
        if (hasBrowseDiscounts) {
          const discounts = {
            name: 'Discounts',
            routerLink: DISCOUNTS_ROUTER_BASE.ROOT,
          };
          this.navItems.splice(2, 0, discounts);
        }
        if (hasBrowseRateCard) {
          const rateCard = {
            name: 'Billing configuration',
            routerLink: 'billing',
          };
          this.navItems.splice(2, 0, rateCard);
        }
        if (hasBrowseGlobalConfiguration) {
          const globalCard = {
            name: 'Global configuration',
            routerLink: 'global',
          };
          this.navItems.splice(1, 0, globalCard);
        }
        if (canManageQuarantineConfigs) {
          this.navItems.push({
            name: 'Quarantine',
            routerLink: 'quarantine',
          });
        }
      });
  }
}
