import { FormControl } from '@angular/forms';
import { SelectOption } from '@vdms-hq/shared';

export enum PhoneType {
  MOBILE = 'MOBILE',
  OFFICE = 'OFFICE',
}
export interface InputPhoneFormGroupType {
  type: FormControl<PhoneType | null>;
  number: FormControl<string | null>;
}
export interface InputPhoneFormType {
  type: PhoneType | null;
  number: string | null;
}

export const PhoneTypes: PhoneType[] = [PhoneType.MOBILE, PhoneType.OFFICE];
export const PhoneTypeSelectOptions = PhoneTypes.map((pt) => <SelectOption>{ key: pt, label: `${pt}` });
