import { VideoCodec } from '@vdms-hq/api-contract';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

export const codecsUniqueValidator = (api: Observable<VideoCodec[]>, current: VideoCodec | null) => {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return api.pipe(
      take(1),
      map((codecs) => {
        const { video_codec, profile } = control.value;
        const alreadyExist = codecs
          .filter(({ uuid }) => uuid !== current?.uuid)
          .find(
            (codec) =>
              codec?.video_codec?.toLowerCase() === video_codec?.toLowerCase() &&
              codec?.profile?.toLowerCase() === profile?.toLowerCase(),
          );

        return alreadyExist ? { alreadyExist: true } : null;
      }),
    );
  };
};
