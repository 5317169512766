import { AssetFlatView2 } from '@vdms-hq/asset-results';
import { AssetFlat, CollectionItemGet } from '@vdms-hq/api-contract';
import { DataProviderService } from '@vdms-hq/selectors';
import { StorageUrlService } from '@vdms-hq/storage';
import { ActivatedClientService } from '@vdms-hq/activated-client';

type Context = {
  uuid: string;
};

export class CollectionAssetViewModel extends AssetFlatView2 {
  constructor(
    props: AssetFlat,
    deps: {
      dataProvider: DataProviderService;
      storageUrlService: StorageUrlService;
      activatedClientService: ActivatedClientService;
    },
    public context: Context,
  ) {
    super(props, deps);
  }

  static fromSingleCollectionAsset(
    originalAsset: CollectionItemGet,
    deps: {
      dataProvider: DataProviderService;
      storageUrlService: StorageUrlService;
      activatedClientService: ActivatedClientService;
    },
  ) {
    const asset = {
      ...originalAsset,
      uuid: originalAsset.asset_uuid,
    };
    return new CollectionAssetViewModel(asset, deps, {
      uuid: originalAsset.asset_uuid || originalAsset.uuid,
    });
  }
}
