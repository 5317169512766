import { DocSupportComponent } from '../supports/doc-support/doc-support.component';
import { ImgSupportComponent } from '../supports/img-support/img-support.component';
import { PdfSupportComponent } from '../supports/pdf-support/pdf-support.component';
import { SheetSupportComponent } from '../supports/sheet-support/sheet-support.component';
import { CodeSupportComponent } from '../supports/code-support/code-support.component';

export const supportedExtensions = [
  ...DocSupportComponent.supportsExtensions,
  ...ImgSupportComponent.supportsExtensions,
  ...PdfSupportComponent.supportsExtensions,
  ...SheetSupportComponent.supportsExtensions,
  ...CodeSupportComponent.supportsExtensions,
];

export type SupportedExtension = (typeof supportedExtensions)[number];

export const isPdf = (extension: string) =>
  (PdfSupportComponent.supportsExtensions as ReadonlyArray<string>).includes(extension);
export const isImage = (extension: string) =>
  (ImgSupportComponent.supportsExtensions as ReadonlyArray<string>).includes(extension);
export const isSheet = (extension: string) =>
  (SheetSupportComponent.supportsExtensions as ReadonlyArray<string>).includes(extension);
export const isDoc = (extension: string) =>
  (DocSupportComponent.supportsExtensions as ReadonlyArray<string>).includes(extension);
export const isCode = (extension: string) =>
  (CodeSupportComponent.supportsExtensions as ReadonlyArray<string>).includes(extension);

export const isSupportedExtension = (extension: string) =>
  extension && (supportedExtensions as ReadonlyArray<string>).includes(extension);
