import { Injectable } from '@angular/core';
import { Selection, SelectionManager } from '@vdms-hq/shared';
import { AudioTrack } from '@vdms-hq/api-contract';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AudioTrackFormService } from './audio-track-form.service';
import { LanguagesSourceService } from '@vdms-hq/selectors';
import { TableAdvancedDataSource } from '@vdms-hq/ui';

@Injectable()
export class AudioTrackDataSource implements TableAdvancedDataSource<AudioTrack> {
  connection$: Observable<AudioTrack[]>;
  total$ = new BehaviorSubject(0);
  emptyResults$: Observable<boolean> = of(false);
  isLoading$: Observable<boolean> = of(false);
  selection: Selection<AudioTrack>;
  allData$: Observable<AudioTrack[]>;

  constructor(public formService: AudioTrackFormService, private languageService: LanguagesSourceService) {
    this.allData$ = combineLatest([formService.value$, languageService.listByType()]).pipe(
      tap(([data, languages]) => {
        this.isLoading$ = of(false);
        this.emptyResults$ = of(!data?.length);
      }),
      map(([data, languages]) => {
        return data.map((track) => {
          const language = languages.find((lang) => lang.key === track.language);
          return {
            ...track,
            language: language?.label ?? 'N/A',
          };
        });
      }),
    );

    this.connection$ = this.allData$;
    this.selection = new SelectionManager<AudioTrack>(this, (item: AudioTrack) => item.track);
  }

  trackBy = (index: number, option: AudioTrack) => option.track;
}
