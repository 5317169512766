import { inject, Injectable } from '@angular/core';
import { InstallAsperaDialogComponent } from './install-aspera-dialog/install-aspera-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class AsperaNotifier {
  private dialog = inject(MatDialog);
  notDetected() {
    this.dialog.open(InstallAsperaDialogComponent);
  }
}
