export class Framerate {
  get label() {
    return `${this.value}fps ${this.isDrop ? 'DF' : ''}`;
  }

  get shortLabel() {
    return `${this.value} ${this.isDrop ? 'DF' : 'NDF'}`;
  }

  constructor(public value: number, public isDrop: boolean) {}

  static fromValue(fps?: string | number, isDrop?: boolean) {
    if (!fps) {
      return this.default();
    }
    let properFps = Number(fps);
    const validFPS = [23.976, 24, 25, 29.97, 30, 50, 59.94, 60, 1000];

    if (!validFPS.includes(properFps)) {
      properFps = validFPS.reduce((prev, curr) =>
        Math.abs(curr - properFps) < Math.abs(prev - properFps) ? curr : prev,
      );
    }

    return new Framerate(properFps, isDrop || false);
  }

  static default() {
    return new Framerate(24, false);
  }

  equals(second: Framerate) {
    return this.value === second.value && this.isDrop === second.isDrop;
  }
}
