export const reduce = (item: { [key: string]: any }, filteredFields?: string[]): string => {
  const filteredItems: string[] = [];

  if (!filteredFields?.length) {
    return Object.values(item)
      .reduce((accumulator, currentValue) => accumulator + ' ' + currentValue)
      .toLowerCase();
  }

  filteredFields.forEach((key) => {
    filteredItems.push(item[key]?.valueOf().toLowerCase());
  });

  return filteredItems.join(' ');
};
