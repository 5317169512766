import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export enum IMAGE_CACHE_ENUM {
  PROGRESS_LOADER_INFO_ICON = 'PROGRESS_LOADER_INFO_ICON',
}
export type IMAGE_CACHE_KEYS = 'PROGRESS_LOADER_INFO_ICON';

@Injectable({ providedIn: 'root' })
export class ImageCacheService {
  #cachedImages: Record<string, string> = {};

  constructor(private http: HttpClient) {}

  getImage(key: IMAGE_CACHE_KEYS): string | undefined {
    return this.#cachedImages[key];
  }

  cacheImage(key: string, forceOverride = false) {
    if (this.#cachedImages[key] && !forceOverride) {
      return;
    }

    const url = this.#getPathByKey(key);
    if (!url) {
      return;
    }

    this.http
      .get(url, { responseType: 'blob' })
      .subscribe((response) => (this.#cachedImages[key] = URL.createObjectURL(response)));
  }

  #getPathByKey(key: string): string | undefined {
    switch (key) {
      case IMAGE_CACHE_ENUM.PROGRESS_LOADER_INFO_ICON:
        return 'assets/common/asset_fallbacks/info.svg';
      default:
        return undefined;
    }
  }
}
