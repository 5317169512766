import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnsConfigDialogComponent } from '../../components/columns-config-dialog/columns-config-dialog.component';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from '@vdms-hq/auth';
import { DialogResponse, filterEmpty, SelectOptionKey } from '@vdms-hq/shared';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { ColumnSettingsScope, UserContractService } from '@vdms-hq/firebase-contract';

@Injectable()
export class ColumnsConfigService {
  constructor(
    public readonly matDialog: MatDialog,
    private authService: AuthService,
    private activatedClient: ActivatedClientService,
    private userContractService: UserContractService,
  ) {}

  popColumnsConfigDialog(scopeName: ColumnSettingsScope, hiddenColumns: string[] = []) {
    const dialogRef = this.matDialog.open(ColumnsConfigDialogComponent, { data: { scopeName, hiddenColumns } });
    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((data: { status: number; columns: SelectOptionKey[] }) => !!data && data.status === DialogResponse.OK),
        map((data: { columns: SelectOptionKey[] }) => data.columns),
        switchMap((columns: SelectOptionKey[]) => this.update(columns, scopeName)),
      )
      .subscribe({
        error: (err) => {
          console.error(err);
        },
      });
  }

  update(columnsConfig: SelectOptionKey[], scopeName: ColumnSettingsScope): Observable<void> {
    return this.authService.auth$.pipe(
      filterEmpty(),
      take(1),
      withLatestFrom(this.activatedClient.clientDefinite$),
      switchMap(([user, client]) => {
        const clientLevel = client.columnsConfig.default;
        const userLevel = columnsConfig;
        const sameConfig =
          clientLevel.length === userLevel.length && clientLevel.every((value, index) => value === userLevel[index]);

        return this.userContractService.updateClient(user.email, client.uuid, {
          columnsConfig: {
            [scopeName]: sameConfig ? [] : (userLevel as string[]),
          },
        });
      }),
    );
  }
}
