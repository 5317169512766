import { inject, Injectable, ViewContainerRef } from '@angular/core';
import { ActiveAssetService } from './active-asset.service';
import { FormBuilderService } from '@vdms-hq/dynamic-form';
import { take } from 'rxjs/operators';
import { AssetQuickPreviewComponent } from '../../components/asset-quick-preview/asset-quick-preview.component';
import { AssetEditFormFooterComponent } from '../../components/asset-edit-form-footer/asset-edit-form-footer.component';
import { DrawerDialogService } from '@vdms-hq/ui';

@Injectable({ providedIn: 'root' })
export class AssetQuickPreviewFormService {
  activeAssetService = inject(ActiveAssetService);
  formBuilderService = inject(FormBuilderService);
  drawerDialogService = inject(DrawerDialogService);

  openAsset(
    viewContainerRef: ViewContainerRef,
    assetId: string,
    options: {
      edit: boolean;
    },
  ) {
    this.drawerDialogService.open(
      viewContainerRef,
      AssetQuickPreviewComponent,
      options.edit ? AssetEditFormFooterComponent : undefined,
    );
    if (options.edit) {
      this.formBuilderService.unlock();
    } else {
      this.formBuilderService.lock();
    }

    // todo custom config for quick preview
    this.activeAssetService.setActiveAsset(assetId, 'launchpad_preview');

    this.activeAssetService.currentAssetView$.pipe(take(1)).subscribe(() => {
      options.edit && this.formBuilderService.unlock();
    });
  }
}
