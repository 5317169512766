import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFilterInput } from './../dynamic-filters/dynamic-filters.component';

@Component({
  selector: 'vdms-hq-dynamic-form-group-renderer[fieldsConfig]',
  templateUrl: './dynamic-form-group-renderer.component.html',
  styleUrls: ['./dynamic-form-group-renderer.component.scss'],
})
export class DynamicFormGroupRendererComponent {
  @Input() form!: FormGroup;
  @Input() fieldsConfig!: DynamicFilterInput[];
  @Input() hasLoader = false;
  @Input() isLoading = false;
  @Input() fullWidth = false;
}
