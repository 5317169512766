import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  LICENSED_PACKAGE_VIEW_STATE,
  LicensedPackageCreate,
  LICENSED_PACKAGE_STATUS,
  LicensedPackageUpdate,
  LicensePackagesService,
  LICENSED_PACKAGE_CROP,
  LICENSED_PACKAGE_OPTIONS,
} from '@vdms-hq/api-contract';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  FormSectionComponent,
  ThumbComponent,
  UIButtonModule,
  UIDialogWrapperModule,
  UIEmptyResultsModule,
  UIFormModule,
  UILayoutModule,
  UINativeUploadModule,
} from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, take, tap } from 'rxjs';
import { DestroyComponent, enumToSelectOptions, SelectOption } from '@vdms-hq/shared';
import { FileUploadState, FormFileComponent, SelectedFileComponent, UploadState } from '@vdms-hq/storage';
import { LicensePackagesActionsService, LicensePackageUploadStatus } from '../../../logic/license-packages-actions';
import { MatExpansionModule } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { LicensePackagesRefreshService } from '../../../logic/license-packages-refresher.service';
import { RightsContractsAddDsService } from '@vdms-hq/rights-contracts';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'vdms-hq-create-edit-license-package-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UIDialogWrapperModule,
    TranslateModule,
    UIFormModule,
    UIButtonModule,
    UINativeUploadModule,
    UILayoutModule,
    FormSectionComponent,
    FormFileComponent,
    SelectedFileComponent,
    ThumbComponent,
    UIEmptyResultsModule,
    MatExpansionModule,
    MatTooltipModule,
  ],
  templateUrl: './create-edit-license-package-dialog.component.html',
  styles: [],
})
export class CreateEditLicensePackageDialogComponent extends DestroyComponent {
  file: File | null = null;
  uploadState: FileUploadState<LicensePackageUploadStatus> | null = null;

  statusOptions = enumToSelectOptions(LICENSED_PACKAGE_STATUS, 'common.dialogs.license_package.status.', true);
  viewStateOptions = enumToSelectOptions(
    LICENSED_PACKAGE_VIEW_STATE,
    'common.dialogs.license_package.view_state.',
    true,
  );

  cropOptions = enumToSelectOptions(LICENSED_PACKAGE_CROP, 'common.dialogs.license_package.crop.', true);
  contractsOptions: SelectOption[] = [];

  form = new FormGroup({
    name: new FormControl<string>('', Validators.required),
    description: new FormControl<string>(''),
    status: new FormControl<LICENSED_PACKAGE_STATUS>(LICENSED_PACKAGE_STATUS.DRAFT, Validators.required),
    view_state: new FormControl<LICENSED_PACKAGE_VIEW_STATE>(LICENSED_PACKAGE_VIEW_STATE.NONE),
    custom_cover: new FormControl<boolean>(false),
    custom_cover_path: new FormControl<string>(''),
    contract_uuids: new FormControl<string[]>([]),
    options: new FormGroup({
      custom_cover_position: new FormControl<LICENSED_PACKAGE_CROP>(LICENSED_PACKAGE_CROP.FROM_TOP),
    }),
  });
  isLoading$ = new BehaviorSubject(false);
  editMode$ = new BehaviorSubject(false);

  title = '';
  saveButton = 'common.dialogs.license_package.update.button';

  constructor(
    private licensePackagesService: LicensePackagesService,
    private licensePackagesActionsService: LicensePackagesActionsService,
    public rightsContractsAddDsService: RightsContractsAddDsService,
    private licensePackageRefresher: LicensePackagesRefreshService,
    private router: Router,
    public dialogRef: MatDialogRef<CreateEditLicensePackageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { uuid: string | undefined },
  ) {
    super();
    if (data.uuid) {
      this.title = 'common.dialogs.license_package.update.title';
      this.saveButton = 'common.dialogs.license_package.update.button';
      this.isLoading$.next(true);
      this.editMode$.next(true);

      combineLatest([this.licensePackagesService.getOne(data.uuid), this.rightsContractsAddDsService.allData$])
        .pipe(this.takeUntilDestroyed(), take(1))
        .subscribe({
          next: ([licensedPackage, contracts]) => {
            this.form.patchValue({
              name: licensedPackage.name,
              description: licensedPackage.description,
              status: licensedPackage.status,
              view_state: licensedPackage.view_state ?? LICENSED_PACKAGE_VIEW_STATE.NONE,
              custom_cover: !!licensedPackage.custom_cover_path,
              custom_cover_path: licensedPackage.custom_cover_path,
              contract_uuids: licensedPackage.contracts.map((contract) => contract.uuid),
              options: {
                custom_cover_position:
                  (licensedPackage?.options?.custom_cover_position as LICENSED_PACKAGE_CROP) ??
                  LICENSED_PACKAGE_CROP.FROM_TOP,
              },
            });
            this.contractsOptions = contracts;
            this.isLoading$.next(false);
          },
          error: () => {
            this.licensePackagesActionsService.popToast.GET_ONE_FAILURE();
            this.isLoading$.next(false);
          },
        });
    } else {
      this.title = 'common.dialogs.license_package.create.title';
      this.saveButton = 'common.dialogs.license_package.create.button';
    }
  }
  onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.isLoading$.next(true);
    if (this.editMode$.getValue()) {
      const edited: LicensedPackageUpdate = {
        name: this.form.value.name as string,
        description: this.form.value.description as string,
        custom_cover: this.form.value.custom_cover as boolean,
        custom_cover_path: this.form.value.custom_cover_path as string,
        status: this.form.value.status as LICENSED_PACKAGE_STATUS,
        view_state: this.form.value.view_state as LICENSED_PACKAGE_VIEW_STATE,
        contract_uuids: this.form.value.contract_uuids as string[],
        options: {
          custom_cover_position: this.form.value.options?.custom_cover_position as LICENSED_PACKAGE_CROP,
        },
      };

      if (this.file && this.data.uuid) {
        this.licensePackagesActionsService.updateLicensePackageAndUpload(this.file, this.data.uuid, edited).subscribe({
          next: (uploadState) => {
            this.uploadState = uploadState;
            if (uploadState.state === UploadState.COMPLETED) {
              this.licensePackagesActionsService.popToast.ADD_COVER_SUCCESS();
              setTimeout(() => {
                this.handleComplete();
              }, 2000);
            }
          },
          error: (error) => {
            this.handleUpdateError(error);
          },
        });
      } else {
        this.editPackageTitle(edited);
      }
    } else {
      const created: Omit<LicensedPackageCreate, 'uuid'> = {
        name: this.form.value.name as string,
        description: this.form.value.description as string,
      };
      this.licensePackagesActionsService
        .createNew(created)
        .pipe(
          this.takeUntilDestroyed(),
          take(1),
          tap(() => {
            this.licensePackagesActionsService.popToast.CREATE_SUCCESS();
            this.licensePackagesActionsService.resultsRefresh();
          }),
        )
        .subscribe({
          next: (res) => {
            this.closeDialog();
            this.router.navigate(['licensed-packages', res.uuid]);
          },
          error: (error) => {
            this.licensePackagesActionsService.popToast.CREATE_FAILURE();
            this.isLoading$.next(false);
            throw error;
          },
        });
    }
  }

  editPackageTitle(edited: LicensedPackageUpdate) {
    if (!this.data.uuid) return;
    this.licensePackagesActionsService
      .update(this.data.uuid, edited)
      .pipe(this.takeUntilDestroyed(), take(1))
      .subscribe({
        next: () => {
          this.licensePackagesActionsService.popToast.UPDATE_SUCCESS();
          this.handleComplete();
        },
        error: (error) => {
          this.handleUpdateError(error);
        },
      });
  }

  handleComplete() {
    this.isLoading$.next(false);
    this.licensePackageRefresher.refreshSingleLicensePackage$.next(true);
    this.licensePackagesActionsService.resultsRefresh();
    this.dialogRef.close({ success: true });
  }

  handleUpdateError(error: any) {
    this.licensePackagesActionsService.popToast.UPDATE_FAILURE();
    this.isLoading$.next(false);
    throw error;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  deleteCover() {
    this.form.controls['custom_cover_path'].setValue('');
    this.form.controls['custom_cover'].setValue(false);
  }
}
