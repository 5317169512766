import { Routes } from '@angular/router';
import { UsersComponent } from './pages/users/users.component';

export enum UsersRouter {
  USERS = 'users',
}

export const usersRoutes: Routes = [
  {
    path: '',
    component: UsersComponent,
  },
  {
    path: '**',
    redirectTo: UsersRouter.USERS,
    pathMatch: 'full',
  },
];
