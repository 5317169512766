<div
  style="visibility: hidden; position: fixed"
  class="context-menu"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>
<mat-menu #contextMenu="matMenu" xPosition="after">
  <ng-template matMenuContent>
    <button *ngFor="let action of actions" mat-menu-item class="menu-item" (click)="emitAction($event, action)">
      {{ action.label | nameTransformer : item | translate }}
    </button>
  </ng-template>
</mat-menu>
