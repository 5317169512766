import { inject, Injectable, NgZone } from '@angular/core';
import { ASPERA_CONFIG, AsperaConfig } from '../config-token';
import { pathSource } from '@vdms-hq/api-contract';
import { AsperaNotifier } from '../../../aspera-notifier.service';
import { statusEventDownloadListener } from './model/status-handler';

declare const AW4: any;

@Injectable({
  providedIn: 'root',
})
export class AsperaDownloaderService {
  private config = inject<AsperaConfig>(ASPERA_CONFIG);
  private ngZone = inject(NgZone);
  private asperaNotifier = inject(AsperaNotifier);

  asperaWeb: any;

  initAsperaConnect() {
    if (this.asperaWeb) {
      return;
    }

    this.asperaWeb = new AW4.Connect();
    this.asperaWeb.initSession();

    this.asperaWeb.addEventListener(AW4.Connect.EVENT.STATUS, statusEventDownloadListener(this.asperaNotifier));
  }

  runOutsideAngular(transferSpec: { [p: string]: string | pathSource[] | number }): void {
    this.ngZone.runOutsideAngular(() => {
      if (this.config.debug) {
        // eslint-disable-next-line no-restricted-syntax
        console.debug('DEBUG: Aspera download triggered', transferSpec);
      }

      this.asperaWeb.startTransfer(transferSpec);
    });
  }
}
