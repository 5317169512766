import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineComponent } from './view/timeline/timeline.component';
import { UIEmptyResultsModule } from '../ui-empty-results/ui-empty-results.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, UIEmptyResultsModule, TranslateModule],
  exports: [TimelineComponent],
  declarations: [TimelineComponent],
})
export class UiTimelineModule {}
