import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'vdms-hq-ui-empty-results',
  templateUrl: './empty-results.component.html',
  styleUrls: ['./empty-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyResultsComponent implements OnInit {
  @Input() type?: 'default' | 'compact';
  @Input() icon?: string | null;
  @Input() path?: string | null;
  @Input() message?: string;
  @Input() navigateLink?: string;
  @Input() btnText?: string;
  @Input() showButton?: boolean;

  ngOnInit() {
    this.type = this.type || 'default';
    this.icon = this.icon || 'insights';
    this.message = this.message || 'The list is empty';
    this.showButton === undefined && (this.showButton = true);
  }
}
