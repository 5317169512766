import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { ApiResponse } from '../api.model';
import { HybrikAnalizeData, HybrikJobsList } from './hybrik.model';
import { HybrikAnalizeEndpoints } from './endpoints';
import { ASSETS_ENDPOINTS } from '../asset/models/asset-endpoints.model';

@Injectable({ providedIn: 'root' })
export class HybrikService {
  constructor(private readonly apiService: ApiService) {}

  public getHybrikAnalyseResults(assetUuid: string): Observable<HybrikAnalizeData> {
    return this.apiService
      .get<ApiResponse<HybrikAnalizeData>>(`${ASSETS_ENDPOINTS.ONE}/${assetUuid}/${HybrikAnalizeEndpoints.ROOT}`)
      .pipe(GetResponseData);
  }

  public getHybrikJobsList(assetUuid: string): Observable<HybrikJobsList[]> {
    return this.apiService
      .get<ApiResponse<HybrikJobsList[]>>(`${HybrikAnalizeEndpoints.ROOT}/${assetUuid}/list`)
      .pipe(GetResponseData);
  }

  public restartHybrikJob(jobId: number) {
    return this.apiService.post(`${HybrikAnalizeEndpoints.ROOT}/${jobId}/restart`, null);
  }
}
