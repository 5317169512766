import { InjectionToken } from '@angular/core';
import { InterpolatedParams } from './interpolated-params.model';
import { SafeHtml } from '@angular/platform-browser';

export enum MessageState {
  error,
  success,
  info,
  warning,
  processing,
}

export type ToastPosition = 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left';
export type ToastIcon = 'error_outline' | 'warning' | 'check_circle_outline' | 'info_outline' | 'processing';
export type ToastColorClass = 'error-message' | 'success-message' | 'warning-message' | 'info-message';

interface MessageCore {
  state: MessageState;
  icon: ToastIcon;
  colorClass: ToastColorClass;
  autoHide: boolean;
}

export interface MessageModelCreate extends MessageCore {
  input: ToastInput;
}

export interface SafeMessageModel extends MessageCore {
  id: string;
  message: SafeHtml;
}

export interface MessageModel extends MessageCore {
  id: string;
  message: string;
}

export interface ToastInput {
  id: string;
  message: string;
  interpolatedParams?: InterpolatedParams;
}

export interface ToastConfig {
  durationInSeconds: number;
  position: ToastPosition;
  isCloseButton: boolean;
}

export const TOAST_CONFIG: InjectionToken<ToastConfig> = new InjectionToken('TOAST_CONFIG');
