import { FieldType } from '@vdms-hq/shared';

export type LaunchpadSettings = {
  defaultMetadata: string;
  quickViewMetadata: string[];
  deliveryFieldsConfig: DeliveryFieldsConfig;
  uploadFieldsConfig: UploadFieldsConfig;
};

export type DeliveryFieldsConfig = {
  grid: DeliveryFieldGrid[];
};

type CustomField = {
  key: string;
  label: string;
  required: boolean;
  type: FieldType
}

type DefinedField = {
  key: string;
  required: boolean;
}

export type DeliveryFieldGrid = (CustomField | DefinedField) & GridSettings;


export const isCustomGridField = (field: DeliveryFieldGrid) => 'label' in field && 'type' in field && field as CustomField;
export const isDefinedGridField = (field: DeliveryFieldGrid) => !isCustomGridField(field) && field as DefinedField;

export type GridSettings = {
  cols: number | undefined;
};

export type UploadFieldsConfig = BasicField[];

type BasicField = {
  key: string;
};
