<div class="grid grid-upto2 grid-gap-x" [formGroup]="innerFormControl">
  <mat-form-field
    class="material-custom-field"
    appearance="outline"
    [class.hide-footer]="!withFooter"
    [class.with-touched-indicator]="withTouchedIndicator"
  >
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      [matDatepicker]="picker"
      formControlName="date"
      [required]="isRequired"
      [min]="minDate"
      [max]="maxDate"
    />

    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>

    <mat-hint *ngIf="withFooter && hint">{{ hint }}</mat-hint>
    <mat-error *ngIf="withFooter">
      <span *ngFor="let error of errors">{{ error.transLang | translate : error.contextParams }}</span>
    </mat-error>
    <vdms-hq-ui-loader matSuffix [size]="20" *ngIf="innerFormControl.pending"></vdms-hq-ui-loader>
  </mat-form-field>

  <input
    type="time"
    class="vdms-custom-timepicker"
    formControlName="time"
    [disabled]="innerFormControl.disabled"
    [class.disabled]="innerFormControl.disabled"
    [class.with-touched-indicator]="withTouchedIndicator"
    *ngIf="timepicker; else defaultInput"
  />

  <ng-template #defaultInput>
    <vdms-hq-ui-form-input-text
      *ngIf="this.timeAutocompleteList.length > 0"
      [formControl]="timeFormControl"
      [autocompleteList]="timeAutocompleteList"
      [attr.aria-label]="timeLabel | translate"
      [label]="timeLabel | translate"
      [fallbackSuffixIcon]="'schedule'"
      (clickAutocompleteItem)="updateDate($event)"
      [autocompleteIncludeNull]="autocompleteIncludeNull"
      [withTouchedIndicator]="withTouchedIndicator"
    >
    </vdms-hq-ui-form-input-text>

    <vdms-hq-ui-form-input-text
      *ngIf="this.timeAutocompleteList.length <= 0"
      [mask]="'Hh:m0'"
      [formControl]="timeFormControl"
      [attr.aria-label]="timeLabel | translate"
      [label]="timeLabel | translate"
      [fallbackSuffixIcon]="'schedule'"
      [withTouchedIndicator]="withTouchedIndicator"
    ></vdms-hq-ui-form-input-text>
  </ng-template>
</div>
