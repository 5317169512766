import { Component, Input } from '@angular/core';

@Component({
  selector: 'vdms-hq-ui-tiles',
  templateUrl: './tiles.component.html',
  styleUrls: ['./tiles.component.scss'],
})
export class TilesComponent {
  @Input() size: 'small' | 'medium' | 'big' = 'medium';
}
