import { Directive, ElementRef, Input, OnChanges, OnDestroy } from '@angular/core';
import { SelectorSourceType } from '../../logic/selector-source.type';
import { DataProviderService } from '../../logic/data-provider.service';
import { iif, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[selectorValue]',
})
export class SelectorValueDirective implements OnChanges, OnDestroy {
  @Input() selectorValue?: string | string[];
  @Input() selectorSourceType?: SelectorSourceType;
  @Input() isGroupSelector = false;
  @Input() fallbackValue = '';

  #destroyed = new Subject<void>();

  constructor(private el: ElementRef, private provider: DataProviderService) {}

  ngOnChanges(): void {
    if (!this.selectorSourceType) {
      this.#writeValue('Missing configuration, please provide [selectorSourceType]');
      return;
    }

    iif(
      () => this.isGroupSelector,
      this.provider.getValueForGroupSelector(this.selectorSourceType, this.selectorValue ?? []),
      this.provider.getValueForSelector(this.selectorSourceType, this.selectorValue)
    )?.pipe(takeUntil(this.#destroyed))
      .subscribe((value) => {
        this.#writeValue(value ?? this.fallbackValue);
      });
  }

  ngOnDestroy(): void {
    this.#destroyed.next();
    this.#destroyed.complete();
  }

  #writeValue(value?: string) {
    this.el.nativeElement.innerHTML = value;
  }
}
