<vdms-hq-ui-dialog-wrapper [size]="'narrow'">
  <h1 title>
    {{ isEdit ? 'Edit quality assurance configuration' : 'Add a new quality assurance configuration' }}
  </h1>
  <div content class="content">
    <ng-container [formGroup]="form">
      <vdms-hq-ui-form-input-select formControlName="asset_type" [label]="'Asset type'" [selectOptions]="assetTypes">
      </vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-input-select
        formControlName="file_extension"
        [multiple]="true"
        [label]="'File extension'"
        [selectOptions]="fileExtensions"
      >
      </vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-input-select
        formControlName="bit_depth"
        [multiple]="true"
        [label]="'Video bit depth'"
        [selectOptions]="bitDepths"
      >
      </vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-input-select
        formControlName="chroma_format"
        [multiple]="true"
        [label]="'Chrome subsampling'"
        [selectOptions]="chromeSampling"
      >
      </vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-input-select
        formControlName="video_codec"
        [multiple]="true"
        [label]="'Video codecs'"
        [selectOptions]="videoCodecs"
      >
      </vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-input-select
        formControlName="framerate"
        [multiple]="true"
        [label]="'Framerates'"
        [selectOptions]="framerate"
      >
      </vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-input-select
        formControlName="interlace_mode"
        [multiple]="true"
        [label]="'Interlace modes'"
        [selectOptions]="interlaceMode"
      >
      </vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-input-select
        formControlName="audio_codec"
        [multiple]="true"
        [label]="'Audio codecs'"
        [selectOptions]="audioCodec"
      >
      </vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-input-select
        formControlName="audio_sample_rate"
        [multiple]="true"
        [label]="'Audio sample rate'"
        [selectOptions]="audioSampleRate"
      >
      </vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-input-select
        formControlName="audio_bit_depth"
        [multiple]="true"
        [label]="'Audio bit depths'"
        [selectOptions]="audioBitDepth"
      >
      </vdms-hq-ui-form-input-select>
      <vdms-hq-input-multiple inputType="number" formControlName="height" label="Height"> </vdms-hq-input-multiple>
      <vdms-hq-input-multiple inputType="number" formControlName="width" label="Width"> </vdms-hq-input-multiple>
      <vdms-hq-input-multiple inputType="number" formControlName="channel_count" [numberMax]="32" label="Channel count">
      </vdms-hq-input-multiple>
      <vdms-hq-input-multiple
        inputType="number"
        formControlName="channels_per_track"
        [numberMax]="32"
        label="Channels per track"
      >
      </vdms-hq-input-multiple>
      <vdms-hq-input-multiple inputType="timecode" formControlName="timecode_start" label="Timecode start">
      </vdms-hq-input-multiple>
      <vdms-hq-ui-form-input-checkbox formControlName="dropped_frames" [label]="'Dropped frames'">
      </vdms-hq-ui-form-input-checkbox>
    </ng-container>
  </div>
  <div class="footer" footer>
    <vdms-hq-ui-button color="secondary" (click)="cancel()">{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button>
    <vdms-hq-ui-button [disabled]="form.invalid" (click)="save()" color="primary">{{
      'common.global.save' | translate
    }}</vdms-hq-ui-button>
  </div>
</vdms-hq-ui-dialog-wrapper>
