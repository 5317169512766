import { Injectable, OnDestroy } from '@angular/core';
import { filter, map, Observable, Subject, switchMap, take, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ToastService } from '@vdms-hq/toast';
import { DashboardsFetcher } from './dashboards.fetcher';
import {
  DashboardCreateEditDialogComponent,
  DashboardDialogInput,
  DashboardDialogResponse,
} from '../components/dashboard-create-dialog/dashboard-create-edit-dialog.component';
import { DialogResponse, RefreshService } from '@vdms-hq/shared';
import { DashboardModel } from '@vdms-hq/api-contract';
import { HttpErrorResponse } from '@angular/common/http';
import { DashboardsDs } from './dashboards.ds';

@Injectable({ providedIn: 'root' })
export class DashboardsService implements OnDestroy {
  private destroyed$: Subject<void> = new Subject<void>();

  #popToast = {
    CREATE_PROCESSING: () =>
      this.toastService.processing({
        id: 'create_dashboard',
        message: 'notifications.create.processing',
      }),
    CREATE_SUCCESS: () =>
      this.toastService.success({
        id: 'create_dashboard',
        message: 'notifications.create.done',
      }),
    CREATE_FAILURE: (reason: string = 'Not specified') =>
      this.toastService.error({
        id: 'create_dashboard',
        message: 'notifications.create.failed',
        interpolatedParams: { reason },
      }),
    UPDATE_PROCESSING: () => {
      this.toastService.processing({
        id: 'update_dashboard',
        message: 'notifications.update.processing',
      });
    },
    UPDATE_SUCCESS: () =>
      this.toastService.success({
        id: 'update_dashboard',
        message: 'notifications.update.done',
      }),
    UPDATE_FAILURE: () =>
      this.toastService.error({
        id: 'update_dashboard',
        message: 'notifications.update.failed',
      }),
  };

  constructor(
    public dataSource: DashboardsDs,
    private matDialog: MatDialog,
    private toastService: ToastService,
    private dashboardsFetcher: DashboardsFetcher,
    private refreshService: RefreshService,
  ) {}

  applyFilter($event: KeyboardEvent): void {
    this.dataSource.applyFilter(($event.target as HTMLInputElement).value);
  }

  openCreateDialog(): void {
    const dialogRef = this.matDialog.open<
      DashboardCreateEditDialogComponent,
      DashboardDialogInput,
      DashboardDialogResponse
    >(DashboardCreateEditDialogComponent, {
      data: {},
    });
    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((response) => response?.status === DialogResponse.OK),
        filter((response) => !!response?.entity),
        map((response) => response?.entity as DashboardModel),
        tap(() => this.#popToast.CREATE_PROCESSING()),
        switchMap(this.createDashboard.bind(this)),
      )
      .subscribe({
        next: this.dataSource.optionsChanged.bind(this),
        error: this.createErrorHandler.bind(this),
      });
  }

  openEditDialog(model: DashboardModel): void {
    const dialogRef = this.matDialog.open<
      DashboardCreateEditDialogComponent,
      DashboardDialogInput,
      DashboardDialogResponse
    >(DashboardCreateEditDialogComponent, {
      data: {
        entity: model,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((response) => response?.status === DialogResponse.OK),
        filter((response) => !!response?.entity),
        map((response) => response?.entity as DashboardModel),
        tap(() => this.#popToast.UPDATE_PROCESSING()),
        switchMap(this.editDashboard.bind(this)),
      )
      .subscribe({
        next: this.dataSource.optionsChanged.bind(this),
        error: this.editErrorHandler.bind(this),
      });
  }

  ngOnDestroy(): void {
    this.destroyed$?.next();
    this.destroyed$?.complete();
  }

  createDashboard(model: DashboardModel): Observable<DashboardModel> {
    return this.dashboardsFetcher.create(model).pipe(
      tap(() => this.#popToast.CREATE_SUCCESS()),
      tap(() => this.refreshService.refresh()),
    );
  }

  editDashboard(model: DashboardModel): Observable<DashboardModel> {
    return this.dashboardsFetcher.update(model.uuid, model).pipe(
      tap(() => this.#popToast.UPDATE_SUCCESS()),
      tap(() => this.refreshService.refresh()),
    );
  }

  createErrorHandler(err: HttpErrorResponse): void {
    this.#popToast.CREATE_FAILURE();
    throw err;
  }

  editErrorHandler(err: HttpErrorResponse): void {
    this.#popToast.UPDATE_FAILURE();
    throw err;
  }
}
