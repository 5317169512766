import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ObjectPipesModule } from 'ng-pipes';
import { ApiContractModule } from '@vdms-hq/api-contract';
import { UIButtonModule, UILoaderModule, UIStatusModule } from '@vdms-hq/ui';
import { AsperaUploadService } from '../../logic/aspera/aspera-upload.service';
import { AssetUploadService } from '../../logic/services/asset-upload.service';
import { TransferableBatchComponent } from '../transferable-batch/transferable-batch.component';
import { TranslateModule } from '@ngx-translate/core';
import { UploadContainerComponent } from './upload-container.component';

@Component({
  selector: 'vdms-hq-storage-upload-container-standalone',
  templateUrl: './upload-container.component.html',
  styleUrls: ['./upload-container.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    ObjectPipesModule,
    ApiContractModule,
    UILoaderModule,
    UIButtonModule,
    TransferableBatchComponent,
    UIStatusModule,
    TranslateModule,
  ],
  providers: [AsperaUploadService, AssetUploadService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadContainerStandaloneComponent extends UploadContainerComponent {}
