<div
  class="material-custom-field-radio"
  [class.with-touched-indicator]="withTouchedIndicator"
  [class.horizontal]="horizontalView"
>
  <mat-label class="material-custom-field-label" *ngIf="label" [style.width]="labelWidth ?? 'unset'">
    {{ label }}
    <span class="required-indicator" *ngIf="isRequired">*</span>
  </mat-label>

  <div class="lists columns-1">
    <mat-radio-group
      [formControl]="innerFormControl"
      [required]="isRequired"
      (blur)="propagateTouch()"
      [class.horizontal]="orientation === 'horizontal'"
      class="column"
    >
      <mat-radio-button *ngFor="let item of options" (change)="propagateByClick($event)" [value]="item.key">
        <div class="label">
          <div [innerHTML]="item.label | translate"></div>
          <div class="suffix" *ngIf="item.suffix">{{ item.suffix }}</div>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="mt-medium" *ngIf="withFooter">
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    <mat-error *ngIf="innerFormControl.touched">
      <span *ngFor="let error of errors">{{ error.transLang | translate : error.contextParams }}</span>
    </mat-error>
  </div>
</div>
