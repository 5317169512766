export enum CartActions {
  // PREVIEW = 'preview',
  // APPROVE = 'approve',
  // REJECT = 'reject',
  // APPROVE_ORDER = 'approve_order',
  // CLEAR_SELECTION = 'clear_selection',
  // DESELECT_ALL = 'deselect_all',
  // BATCH_UPDATE = 'batch_update',
  // ADD_TO_COLLECTION = 'add_to_collection',
  // ADD_TO_CART = 'add_to_cart',
  // ADD_TO_LICENSED_PACKAGE = 'add_to_licensed_package',
  // DELETE = 'delete',
  REMOVE_ALL = 'remove_all',
  // EXPORT = 'export',
  // EDIT = 'edit',
  // DOWNLOAD_ALL_ASPERA_FOLDERS = 'download_all_aspera_folders',
  // DOWNLOAD_ALL_ASPERA = 'download_all_aspera',
  // DOWNLOAD_ASPERA = 'download_aspera',
  // DOWNLOAD_SELECTED_ASPERA_FOLDERS = 'download_selected_aspera_folders',
  // DOWNLOAD_SELECTED_ASPERA = 'download_selected_aspera',
  // NEW_DELIVERY = 'new_delivery',
  // TOAST_EMBARGO_ERROR = 'toast_embargo_error',
  // TRANSCODE_STATUS = 'transcode_status',
  // SET_AS_COLD = 'set_as_cold',
  // SETTINGS = 'settings',
  // DOWNLOAD_CLEARANCE = 'download_clearance_notes',
  // RETRY_DELIVERY_DESTINATIONS = 'retry_delivery_destinations',
  // UUID = 'uuid',
  // TAB = 'tab',
  // WINDOW = 'window',
  // SEND_FILTERS_TO_LICENSED_PACKAGE = 'send_filters_to_licensed_package',
  CHECKOUT = 'cart.checkout',
  CHECKOUT_DELIVERY_DETAILS = 'checkout.delivery_details',
  CHECKOUT_PLACE_ORDER = 'checkout.place_order',
  VALIDATE_DELIVERY_DESTINATIONS = 'checkout.validate_delivery_destinations',
  REMOVE_FAILED_VALIDATE_DELIVERY_DESTINATIONS = 'checkout.remove_failed_validate_delivery_destinations',
  CHECKOUT_PREVIOUS_STEP = 'checkout.previous_step',
  CHECKOUT_NEXT_STEP = 'checkout.next_step',
  CHECKOUT_MANUAL = 'checkout.manual',
  CHECKOUT_PREDEFINED = 'checkout.predefined',
  CHECKOUT_WARM_UP = 'checkout.warm_up',
  CHECKOUT_WARM_UP_ORDER = 'checkout.warm_up_order',
  CHECKOUT_DISCOUNT_CHECK = 'checkout.discount_check',
  CHECKOUT_DISCOUNT_RESET = 'checkout.discount_reset',
}

export type CartActionsType = (typeof CartActions)[keyof typeof CartActions];
