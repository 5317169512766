import { Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollectionModelFlat, DashboardModel } from '@vdms-hq/api-contract';
import { DialogResponse } from '@vdms-hq/shared';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as uuid from 'uuid';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import {
  FormSectionComponent,
  UIButtonModule,
  UIDialogWrapperModule,
  UIEmptyResultsModule,
  UIFormModule,
  UIPipesModule,
} from '@vdms-hq/ui';
import { debounceTime, shareReplay, Subject, takeUntil, tap } from 'rxjs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DashboardCollectionsDS } from '../../logic/dashboard-collections.ds';

export type DashboardDialogInput = {
  entity?: DashboardModel;
  collections?: CollectionModelFlat[];
};

export type DashboardDialogResponse = {
  status: DialogResponse;
  entity?: DashboardModel;
};

type SelectionType = { [uuid: string]: boolean };

@Component({
  selector: 'vdms-hq-dashboard-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DragDropModule,
    ReactiveFormsModule,
    TranslateModule,
    UIButtonModule,
    UIDialogWrapperModule,
    UIFormModule,
    UIPipesModule,
    FormSectionComponent,
    MatPaginatorModule,
    MatTooltipModule,
    UIEmptyResultsModule,
  ],
  templateUrl: './dashboard-create-edit-dialog.component.html',
  styleUrls: ['./dashboard-create-edit-dialog.component.scss'],
})
export class DashboardCreateEditDialogComponent implements OnDestroy {
  form = new FormGroup({
    name: new FormControl<string | null>(null, Validators.required),
    show_latest: new FormControl<boolean>(false),
    show_collections_in_carousel: new FormControl<boolean>(false),
    is_default: new FormControl<boolean>(false),
    filterControl: new FormControl<string | number>(''),
  });

  selectedIdsForGrid: SelectionType = {};
  selectedIdsForCarousel: SelectionType = {};
  collections: CollectionModelFlat[] = [];
  collectionsSelected: CollectionModelFlat[] = [];

  selectedFilterValue = '';
  previewLoader = true;

  private destroyed$ = new Subject<void>();

  @ViewChild('collectionsList') collectionsList: ElementRef | undefined;

  constructor(
    public dialogRef: MatDialogRef<DashboardCreateEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DashboardDialogInput,
    public collectionsService: DashboardCollectionsDS,
  ) {
    this.collectionsService.connection$
      .pipe(
        takeUntil(this.destroyed$),
        tap((collections) => (this.data.collections = collections)),
        shareReplay(1),
      )
      .subscribe(() => this.#sortCollections());

    this.form.controls['filterControl'].valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      if (value === null) return;
      this.collectionsService.pageIndex$.next(0);
      this.collectionsService.filterValue = value.toString();
      this.collectionsService.refresh$.next(true);
    });
  }

  ngOnDestroy() {
    this.collectionsService.clear();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get isEdit(): boolean {
    return !!this.data.entity;
  }

  close() {
    this.dialogRef.close({
      status: DialogResponse.ABORT,
    });
  }

  save() {
    const dashboard = {
      uuid: this.data.entity?.uuid || uuid.v4(),
      ...this.form.value,
      collections: this.#extractCollections(this.selectedIdsForGrid),
      carousel_collections: this.#extractCollections(this.selectedIdsForCarousel),
    };

    delete dashboard['filterControl'];

    this.dialogRef.close({
      status: DialogResponse.OK,
      entity: dashboard,
    });
  }

  drop($event: CdkDragDrop<DashboardModel, any>) {
    moveItemInArray(this.collectionsSelected, $event.previousIndex, $event.currentIndex);
  }

  removeFromSelected(collection: CollectionModelFlat) {
    delete this.selectedIdsForGrid[collection.uuid];
    delete this.selectedIdsForCarousel[collection.uuid];
    this.collectionsSelected = this.collectionsSelected.filter((item) => item.uuid !== collection.uuid);
  }

  removeAllFromSelected() {
    this.selectedIdsForGrid = {};
    this.selectedIdsForCarousel = {};
    this.collectionsSelected = [];
  }

  isEnabledFor(collection: CollectionModelFlat, type: 'thumb' | 'carousel'): boolean {
    switch (type) {
      case 'thumb':
        return this.selectedIdsForGrid[collection.uuid] ?? false;
      case 'carousel':
        return this.selectedIdsForCarousel[collection.uuid] ?? false;
      default:
        return false;
    }
  }

  toggleFor(collection: CollectionModelFlat, type: 'thumb' | 'carousel'): void {
    const isEnabled = this.isEnabledFor(collection, type);

    if (isEnabled && type === 'thumb') {
      delete this.selectedIdsForGrid[collection.uuid];
      if (!this.isEnabledFor(collection, 'carousel')) {
        this.collectionsSelected = this.collectionsSelected.filter((item) => item.uuid !== collection.uuid);
      }
    } else if (isEnabled && type === 'carousel') {
      delete this.selectedIdsForCarousel[collection.uuid];
      if (!this.isEnabledFor(collection, 'thumb')) {
        this.collectionsSelected = this.collectionsSelected.filter((item) => item.uuid !== collection.uuid);
      }
    } else if (type === 'thumb') {
      this.selectedIdsForGrid[collection.uuid] = true;
      if (!this.collectionsSelected.find((item) => item.uuid === collection.uuid)) {
        this.collectionsSelected.push(collection);
      }
    } else if (type === 'carousel') {
      this.selectedIdsForCarousel[collection.uuid] = true;
      if (!this.collectionsSelected.find((item) => item.uuid === collection.uuid)) {
        this.collectionsSelected.push(collection);
      }
    }
  }

  #sortCollections(): void {
    this.collectionsList?.nativeElement.scrollTo(0, 0);
    this.collections = _.cloneDeep(this.data.collections ?? []);
    if (this.isEdit) {
      if (this.previewLoader) {
        this.form.patchValue(this.data.entity ?? {});
        this.data?.entity?.collections?.forEach((collection) => {
          if (collection?.uuid) {
            this.selectedIdsForGrid[collection.uuid] = true;
            if (!this.collectionsSelected.find((item) => item.uuid === collection.uuid)) {
              this.collectionsSelected.push(collection);
            }
          }
        });
        this.data?.entity?.carousel_collections?.forEach((collection) => {
          if (collection?.uuid) {
            this.selectedIdsForCarousel[collection.uuid] = true;
            if (!this.collectionsSelected.find((item) => item.uuid === collection.uuid)) {
              this.collectionsSelected.push(collection);
            }
          }
        });
        this.previewLoader = false;
      }

      this.collectionsSelected = this.collectionsSelected
        .map((item) => {
          const collectionOrder = this.data?.entity?.collections?.find(
            ({ collection }) => collection?.uuid === item.uuid,
          )?.order;
          const carouselOrder = this.data?.entity?.carousel_collections?.find(
            ({ collection }) => collection?.uuid === item.uuid,
          )?.order;
          item.order = carouselOrder ?? collectionOrder;

          return item;
        })
        .sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0));

      this.collectionsService.isLoading$.next(false);
    }
    this.previewLoader = false;
    this.collectionsService.isLoading$.next(false);
  }

  #extractCollections(selected: { [key: string]: boolean }) {
    return this.collectionsSelected
      .map((item, index) => ({
        ...item,
        order: index,
      }))
      .filter((collection) => selected[collection.uuid] !== undefined);
  }
}
