import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import {
  AttachmentCreateRequest,
  AttachmentPatchRequest,
  AttachmentPatchResponse,
  AttachmentEndpoints,
} from './attachment.model';
import { Observable } from 'rxjs';
import { ApiResponse } from '../api.model';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { Attachment } from '../asset';

@Injectable({ providedIn: 'root' })
export class AttachmentService {
  constructor(private readonly apiService: ApiService) {}

  create = (data: AttachmentCreateRequest): Observable<Attachment> =>
    this.apiService
      .post<AttachmentCreateRequest, ApiResponse<Attachment>>(AttachmentEndpoints.ROOT, data)
      .pipe(GetResponseData);

  update = (uuid: string, data: AttachmentPatchRequest): Observable<AttachmentPatchResponse> =>
    this.apiService
      .patch<AttachmentPatchRequest, ApiResponse<AttachmentPatchResponse>>(`${AttachmentEndpoints.ROOT}/${uuid}`, data)
      .pipe(GetResponseData);

  delete = (uuid: string) => this.apiService.delete(`${AttachmentEndpoints.ROOT}/${uuid}`);
}
