import { Pipe, PipeTransform } from '@angular/core';
import { Action } from '../logic/common-config';

@Pipe({
  name: 'actionState',
  standalone: true,
})
export class ActionStatePipe<T> implements PipeTransform {
  transform(action: Action<T>, state: 'ifDisabled' | 'ifHidden', item?: T): boolean {
    if (state == 'ifDisabled') {
      if (!action?.disabledIf) {
        return false;
      }

      if (item) return action.disabledIf(item);
      else return action.disabledIf();
    }

    if (state == 'ifHidden') {
      if (!action?.hiddenIf) {
        return false;
      }

      if (item) return action.hiddenIf(item);
      else return action.hiddenIf();
    }

    return false;
  }
}
