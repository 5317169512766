<vdms-hq-ui-dialog-wrapper>
  <div title>
    <vdms-hq-ui-layout-section
      [breadcrumbs]="[
        {
          name: 'common.video_codecs.title' | translate
        }
      ]"
    >
    </vdms-hq-ui-layout-section>
  </div>
  <div content [formGroup]="form">
    <vdms-hq-ui-loader *ngIf="loading$ | async; else page"></vdms-hq-ui-loader>
    <ng-template #page>
      <ng-container *ngIf="hasDuplicateError">
        <vdms-hq-ui-info-bar
          [type]="InfoBarType.INFO"
          [message]="'common.video_codecs.errors.duplicate_exist' | translate"
          [disableClose]="true"
        ></vdms-hq-ui-info-bar>
      </ng-container>
      <div class="row">
        <vdms-hq-ui-form-input-toggle
          [formControl]="nullVideoCodec"
          [label]="videoCodec.enabled ? 'Set Null' : 'Null'"
          (valueChange)="onNullSetterChange($event, 'videoCodec')"
        ></vdms-hq-ui-form-input-toggle>
        <vdms-hq-ui-form-input-text
          formControlName="video_codec"
          [label]="'common.video_codecs.form.video_codec' | translate"
        ></vdms-hq-ui-form-input-text>
      </div>
      <div class="row">
        <vdms-hq-ui-form-input-toggle
          [formControl]="nullProfile"
          [label]="profile.enabled ? 'Set Null' : 'Null'"
          (valueChange)="onNullSetterChange($event, 'profile')"
        ></vdms-hq-ui-form-input-toggle>
        <vdms-hq-ui-form-input-text
          formControlName="profile"
          [label]="'common.video_codecs.form.profile' | translate"
        ></vdms-hq-ui-form-input-text>
      </div>
      <vdms-hq-ui-form-input-text
        formControlName="display_name"
        [label]="'common.video_codecs.form.display_name' | translate"
      ></vdms-hq-ui-form-input-text>
    </ng-template>
  </div>

  <div footer>
    <vdms-hq-ui-button color="secondary" (click)="close()">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button
      color="primary"
      [loading]="loading$ | async"
      (click)="save()"
      [disabled]="form.invalid || (formDirty$ | async)"
    >
      {{ 'common.global.save' | translate }}
    </vdms-hq-ui-button>
  </div>
</vdms-hq-ui-dialog-wrapper>
