import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActionContextLess,
  DataAction as Action,
  DataPresentationHeaderComponent,
  DefaultMultiViewType,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UIButtonModule,
  UILayoutModule,
} from '@vdms-hq/ui';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { TranslateModule } from '@ngx-translate/core';
import { DestroyComponent, FilterType, ResourceModel, ValueFormat } from '@vdms-hq/shared';
import { ContractListItem, RightsContractStatus } from '@vdms-hq/api-contract';
import { RightsContractsResultsMultiDs } from '../../logic/rights-contracts-results-multi-ds.service';
import { RightsContractsActionsService } from '../../logic/rights-contracts-actions.service';
import { RightsPartnersAddDsService } from '@vdms-hq/rights-partners';
import { combineLatest, Observable } from 'rxjs';
import { Permission } from '@vdms-hq/firebase-contract';
import { ActivatedClientModule, PermissionService } from '@vdms-hq/activated-client';
import { map } from 'rxjs/operators';
import { TABLE_TYPE, ViewSettingsService } from '@vdms-hq/view-settings';

@Component({
  selector: 'vdms-hq-rights-contracts-results',
  standalone: true,
  imports: [
    CommonModule,
    UIButtonModule,
    DynamicFiltersModule,
    TranslateModule,
    UILayoutModule,
    MultipleDataPresentationComponent,
    ActivatedClientModule,
    DataPresentationHeaderComponent,
  ],
  templateUrl: './rights-contracts-results.component.html',
  styles: [],
})
export class RightsContractsResultsComponent extends DestroyComponent {
  protected readonly Permission = Permission;
  headerActions$: Observable<ActionContextLess[]> = this.permissionsService
    .verifyWithOwnedPermissions$([Permission.CREATE_RIGHTS_CONTRACTS])
    .pipe(
      map((hasPermission) =>
        hasPermission
          ? [
              {
                key: 'create',
                label: 'common.rights_contracts.table.actions.create',
              },
            ]
          : [],
      ),
    );

  filtersConfig$: Observable<DynamicFilterInput[]> = combineLatest([
    this.rightsPartnersAddDsService.allData$,
    this.permissionsService.verifyWithOwnedPermissions$([Permission.EDIT_RIGHTS_CONTRACTS]),
    this.permissionsService.verifyWithOwnedPermissions$([Permission.DELETE_RIGHTS_CONTRACTS]),
  ]).pipe(
    this.takeUntilDestroyed(),
    map(([partners]) => {
      return <DynamicFilterInput[]>[
        {
          id: 'keyword',
          label: 'common.rights_contracts.table.filter_placeholder',
          resource: [ResourceModel.RIGHTS_CONTRACT],
          format: ValueFormat.AS_IS,
          filters: {
            objectPath: 'name',
            validFormat: 'keyword',
            type: FilterType.MASTER_TEXT,
          },
        },
        {
          id: 'partners',
          label: 'common.license_package.table.partners_placeholder',
          resource: [ResourceModel.LICENSED_PACKAGE],
          format: ValueFormat.SELECTOR,
          filters: {
            objectPath: 'partners_uuid',
            validFormat: 'keyword',
            type: FilterType.SELECTOR,
          },
          selectOptions: partners,
          enableClear: true,
        },
      ];
    }),
  );

  enabled: string[] = [
    'name',
    'partner',
    'contract_id',
    'license_id',
    'status',
    'start_date',
    'end_date',
    'created_at',
    'updated_at',
    'actions',
  ];

  #actions: Observable<Action<ContractListItem>[]> = combineLatest([
    this.permissionsService.verifyWithOwnedPermissions$([Permission.EDIT_RIGHTS_CONTRACTS]),
    this.permissionsService.verifyWithOwnedPermissions$([Permission.DELETE_RIGHTS_CONTRACTS]),
  ]).pipe(
    this.takeUntilDestroyed(),
    map(([canEdit, canDelete]) => {
      const actions: Action<ContractListItem>[] = [];

      if (canEdit) {
        actions.push({
          key: 'edit',
          label: 'common.rights_contracts.table.actions.edit',
          icon: 'edit',
        });
        actions.push({
          key: 'licensed_packages',
          label: 'common.rights_contracts.table.actions.licensed_packages',
          icon: 'folder_special',
        });
      }
      if (canDelete) {
        actions.push({
          key: 'delete',
          label: 'common.rights_contracts.table.actions.delete',
          icon: 'delete',
        });
      }

      return actions;
    }),
  );

  rightsContractsConfig$: Observable<MultipleViewConfiguration<ContractListItem>> = combineLatest([
    this.#actions,
    this.viewSettingsService.defaultViewFor$(TABLE_TYPE.CONTRACT),
  ]).pipe(
    map(([actions, defaultView]) => {
      return {
        multiView: {
          defaultView: defaultView,
          emptyResults: {
            message: 'Could not find any Rights Contracts',
            icon: 'copyright',
          },
        },
        tableAdvanced: {
          actions: actions,
          layout: {
            headers: true,
          },
          columns: [
            {
              id: 'name',
              label: 'common.rights_contracts.table.columns.name',
              valuePath: 'name',
            },
            {
              id: 'partner',
              label: 'common.rights_contracts.table.columns.partner',
              valuePath: 'partner.name',
            },
            {
              id: 'contract_id',
              label: 'common.rights_contracts.table.columns.contract_id',
              valuePath: 'contract_id',
            },
            {
              id: 'license_id',
              label: 'common.rights_contracts.table.columns.license_id',
              valuePath: 'license_id',
            },
            {
              id: 'status',
              label: 'common.rights_contracts.table.columns.status',
              valuePath: 'status',
              viewFormat: {
                pills: {
                  'second notification sent': 'done',
                  default: 'orange',
                },
              },
            },
            {
              id: 'start_date',
              label: 'common.rights_contracts.table.columns.start_date',
              valuePath: 'start_date',
              viewFormat: {
                modifiers: {
                  dateFormat: 'date',
                },
              },
            },
            {
              id: 'end_date',
              label: 'common.rights_contracts.table.columns.end_date',
              valuePath: 'end_date',
              viewFormat: {
                modifiers: {
                  dateFormat: 'date',
                },
              },
            },
            {
              id: 'created_at',
              label: 'common.rights_contracts.table.columns.created_at',
              valuePath: 'created_at',
              viewFormat: {
                modifiers: {
                  dateFormat: 'date-time',
                },
              },
            },
            {
              id: 'updated_at',
              label: 'common.rights_contracts.table.columns.updated_at',
              valuePath: 'updated_at',
              viewFormat: {
                modifiers: {
                  dateFormat: 'date-time',
                },
              },
            },
            {
              id: 'actions',
              type: 'actions',
            },
          ],
          columnsEnabled: this.enabled,
        },
        gridAdvanced: {
          actions: actions,
          content: {
            titlePath: 'name',
            additionalInfo: {
              label: 'common.rights_contracts.table.columns.partner',
              valuePath: 'partner.name',
            },
            metadata: [
              {
                id: 'status',
                label: 'common.rights_contracts.table.columns.status',
                valuePath: 'status',
                viewFormat: {
                  pills: {
                    'second notification sent': 'done',
                    default: 'orange',
                  },
                },
              },
              {
                label: 'common.rights_contracts.table.columns.contract_id',
                valuePath: 'contract_id',
              },
              {
                label: 'common.rights_contracts.table.columns.license_id',
                valuePath: 'license_id',
              },
              {
                label: 'common.rights_contracts.table.columns.start_date',
                valuePath: 'start_date',
                viewFormat: {
                  modifiers: {
                    dateFormat: 'date',
                  },
                },
              },
              {
                label: 'common.rights_contracts.table.columns.end_date',
                valuePath: 'end_date',
                viewFormat: {
                  modifiers: {
                    dateFormat: 'date',
                  },
                },
              },
              {
                label: 'common.rights_contracts.table.columns.created_at',
                valuePath: 'created_at',
                viewFormat: {
                  modifiers: {
                    dateFormat: 'date-time',
                  },
                },
              },
              {
                label: 'common.rights_contracts.table.columns.updated_at',
                valuePath: 'updated_at',
                viewFormat: {
                  modifiers: {
                    dateFormat: 'date-time',
                  },
                },
              },
            ],
          },
        },
      };
    }),
  );

  constructor(
    private rightsContractsActionsService: RightsContractsActionsService,
    public rightsContractsResultsMultiDs: RightsContractsResultsMultiDs,
    private rightsPartnersAddDsService: RightsPartnersAddDsService,
    private viewSettingsService: ViewSettingsService,
    private permissionsService: PermissionService,
  ) {
    super();
  }

  setPreferredTableView($event: DefaultMultiViewType) {
    this.viewSettingsService.saveDefaultViewFor(TABLE_TYPE.CONTRACT, $event);
  }

  customActionHandler(event: { key: string; item?: ContractListItem }): boolean {
    switch (event.key) {
      case 'create':
        this.rightsContractsActionsService.createDialog();
        return true;
      case 'edit':
        if (!event.item) {
          return false;
        }
        this.rightsContractsActionsService.editDialog(event.item.uuid);
        return true;
      case 'licensed_packages':
        if (!event.item) {
          return false;
        }
        this.rightsContractsActionsService.editLicensedPackagesDialog(event.item.uuid);
        return true;
      case 'delete':
        if (!event.item) {
          return false;
        }
        this.rightsContractsActionsService.deleteDialog(event.item.uuid, event.item.name);
        return true;
    }
    return false;
  }
}
