import { inject, Injectable } from '@angular/core';
import { ToastService } from '@vdms-hq/toast';
import { Clipboard } from '@angular/cdk/clipboard';

@Injectable({ providedIn: 'root' })
export class ClipboardCopy {
  toastService = inject(ToastService);
  clipboard = inject(Clipboard);

  copyToClipboard(value: string, valueName: string) {
    const clipboard = this.clipboard.copy(value);
    if (clipboard) {
      this.toastService.success({ id: 'clipboardCopy', message: `Successfully copied ${valueName} to clipboard` });
    } else {
      this.toastService.error({ id: 'clipboardCopy', message: `Could not copy ${valueName} to clipboard` });
    }
  }
}
