import { FieldConfigId } from '@vdms-hq/shared';
import firebase from 'firebase/compat';
import Timestamp = firebase.firestore.Timestamp;

export type MultiViewKey =
  | 'asset'
  | 'collections'
  | 'orders'
  | 'downloads'
  | 'licensedPackages'
  | 'licenseePartners'
  | 'contracts'
  | 'destinations'
  | string;

export type MultiViewType = 'table' | 'grid' | 'advanced';

export type MultiViewSettings = Record<MultiViewKey, MultiViewType>;

export type ColumnSettingsScope =
  | 'cart'
  | 'all'
  | 'collections'
  | 'preview-request'
  | 'browse'
  | 'purged-assets'
  | 'browse-library'
  | 'orders'
  | 'shared-packs'
  | 'orphan-assets'
  | 'license_package'
  | 'content-corner'
  | ColumnsSettingsScopes.MEDIA_PULSE_ORDERS
  | ColumnsSettingsScopes.LICENSED_PACKAGES
  | ColumnsSettingsScopes.LAUNCHPAD_UPLOAD_JOBS
  | ColumnsSettingsScopes.QUARANTINED_ASSETS
  | ColumnsSettingsScopes.ORDER_ITEMS
  | ColumnsSettingsScopes.SHARED_PACKS_ITEMS
  | 'nested-assets'
  | 'deleted';

export enum ColumnsSettingsScopes {
  CART = 'cart',
  ALL = 'all',
  COLLECTIONS = 'collections',
  PREVIEW_REQUEST = 'preview-request',
  BROWSE = 'browse',
  PURGED_ASSETS = 'purged-assets',
  BROWSE_LIBRARY = 'browse-library',
  ORDERS = 'orders',
  ORDER_ITEMS = 'order-items',
  SHARED_PACKS = 'shared-packs',
  SHARED_PACKS_ITEMS = 'shared-packs-items',
  LAUNCHPAD_UPLOAD_JOBS = 'upload-jobs',
  MEDIA_PULSE_ORDERS = 'media-pulse-orders',
  LICENSED_PACKAGES = 'licensed-packages',
  QUARANTINED_ASSETS = 'quarantined-assets',
}

export interface UserModel extends UserAttributes {
  policies: string[];
  groups: string[];

  /** @deprecated, use vida object */
  finishedTour: boolean;
  /** @deprecated, use vida object */
  preferred_asset_view?: 'table' | 'thumb';
}

export interface UserAttributes {
  preferredTimezone?: string;
  selectedClientId?: string;
  eulaAcceptedDate?: Timestamp;
  mfaReminderDate?: Timestamp;
  vida?: Partial<{
    finishedTour: boolean;
    preferredAssetView: 'table' | 'thumb';
    preferredDefaultTableView: MultiViewType;
    preferredPageSize: number;
    openAssetPreviewInPopup: boolean;
    preferredTableView: Record<MultiViewKey, MultiViewType>;
    preferredDialogDrawerView?: 'drawer' | 'dialog';
  }>;
}

export type UserUpdateAttributes = Partial<{
  preferredTimezone: string;
  selectedClientId: string;
  eulaAcceptedDate: Date;
  mfaReminderDate: Date;
  vida?: Partial<{
    finishedTour: boolean;
    preferredAssetView: 'table' | 'thumb';
    preferredDefaultTableView: MultiViewType;
    preferredPageSize: number;
    preferredTableView: Record<MultiViewKey, MultiViewType>;
    preferredDialogDrawerView: 'drawer' | 'dialog';
  }>;
}>;

export interface UserVidaLibraryAttributes {
  configs: {
    id: string;
    name: string;
    structure: FieldConfigId[];
  }[];
}

export interface UserClient {
  columnsConfig: UserClientColumnsConfig;
  filtersConfig: UserClientFiltersConfig;
  vidaLibrary?: UserVidaLibraryAttributes;
}

export interface UserClientColumnsConfig {
  [key: string]: FieldConfigId[];
}

export type UserClientFiltersConfig = UserClientColumnsConfig;

export interface SavedLatestSearchesModel {
  id?: string;
  createdAt: number;
  phrase: string;
}
