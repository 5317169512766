import { Pipe, PipeTransform } from '@angular/core';
import { Action } from '../logic/common-config';

@Pipe({
  name: 'isRowTooltip',
  pure: true,
  standalone: true,
})
export class IsRowTooltipPipe<T = never> implements PipeTransform {
  transform(actions?: Action<T>[], defaultTooltipContent = 'common.global.double_click_open'): string {
    const actionWithDoubleClickEnabled = (actions ?? []).find((action) => action.onDoubleClick);
    if (actionWithDoubleClickEnabled) {
      return actionWithDoubleClickEnabled?.onDoubleClickTooltip ?? defaultTooltipContent;
    }
    return '';
  }
}
