import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UIButtonModule } from '../../ui-button';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { saveAs } from 'file-saver';

@Component({
  selector: 'vdms-hq-ui-thumb[src]',
  templateUrl: './thumb.component.html',
  styleUrls: ['./thumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, UIButtonModule, NgOptimizedImage],
})
export class ThumbComponent {
  @Input() src!: string;
  @Input() downloadUrl = '';
  @Input() fileName = 'Downloaded_file';
  @Output() deleteFile = new EventEmitter();
  @Input() alt = 'Preview';

  download() {
    saveAs(this.downloadUrl, this.fileName);
  }
}
