import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { filterEmpty } from '@vdms-hq/shared';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class SinglePackageResolverService implements Resolve<string | null> {
  currentUuid$ = new BehaviorSubject<string | null | undefined>(null);
  currentUuidDefinite$ = this.currentUuid$.pipe(filterEmpty());

  resolve(route: ActivatedRouteSnapshot) {
    const licensePackageUuid = route.paramMap.get('uuid') as string;

    if (!licensePackageUuid) {
      return null;
    }

    this.currentUuid$.next(licensePackageUuid);

    return licensePackageUuid;
  }
}
