import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { OffsetAddPipe } from '@vdms-hq/player';
import {
  UIButtonModule,
  UIDialogWrapperModule,
  UIEmptyResultsModule,
  UIFormModule,
  UILayoutModule,
  UIResultsWrapperModule,
  UiTimecodeListModule,
  UITypingLettersModule,
} from '@vdms-hq/ui';
import { AiSummaryComponent } from './smart/ai-summary/ai-summary.component';
import { SuggestionsDialogComponent } from './smart/suggestions-dialog/suggestions-dialog.component';
import { TranscriptionModelsDialogComponent } from './smart/transcription-models-dialog/transcription-models-dialog.component';
import { TranscriptionsComponent } from './smart/transcriptions/transcriptions.component';
import { SuggestionComponent } from './view/suggestion/suggestion.component';
import { VoiceReaderComponent } from './view/voice-reader/voice-reader.component';

@NgModule({
  declarations: [
    AiSummaryComponent,
    SuggestionsDialogComponent,
    SuggestionComponent,
    TranscriptionModelsDialogComponent,
    TranscriptionsComponent,
    VoiceReaderComponent,
  ],
  providers: [],
  imports: [
    CommonModule,
    UIFormModule,
    UIButtonModule,
    UIDialogWrapperModule,
    TranslateModule,
    UITypingLettersModule,
    UIEmptyResultsModule,
    UIResultsWrapperModule,
    UiTimecodeListModule,
    UILayoutModule,
    OffsetAddPipe,
  ],
  exports: [AiSummaryComponent, TranscriptionsComponent],
})
export class TranscriptionModule {}
