import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { filterEmpty } from '@vdms-hq/shared';
import { map, take } from 'rxjs/operators';
import { Appearance, SubAppName } from '@vdms-hq/firebase-contract';
import { ActivatedClientService } from './services/activated-client.service';
import { LayoutService } from '@vdms-hq/theming';
import { setViewGroup } from './view-group';

@Injectable({
  providedIn: 'root',
})
export class AppearanceInitializer {
  constructor(
    private activatedClient: ActivatedClientService,
    private titleService: Title,
    private layoutService: LayoutService,
  ) {}

  subAppName: SubAppName = 'storefront';
  initialize = (subAppName: SubAppName): Observable<true> =>
    this.activatedClient.clientOrHostnameFallbackClient$.pipe(
      filterEmpty(),
      take(1),
      map((client) => {
        if (subAppName == 'storefront') setViewGroup(client[subAppName]?.viewsGroupType);
        this.#setTitle(client.name + (subAppName == 'storefront' ? ' - Storefront' : ' - Content Corner'));
        this.#setAppearance(client[subAppName]?.appearance);
      }),
      map(() => true),
    );

  #setTitle = (clientName: string) => this.titleService.setTitle(`${clientName}`);

  #setAppearance = (appearance?: Appearance) => appearance && this.layoutService.set(appearance);
}
