import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedClientService } from '../../logic/services/activated-client.service';
import { combineLatest, EMPTY, Observable, switchMap } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ClientKeyLabel, ClientRef } from '../../logic/models/client.model';
import { ClientSwitchViewModel } from '../../logic/models/client-switch.view-model';
import { Router } from '@angular/router';
import { ACTIVATED_CLIENT_CONFIG_TOKEN, ActivatedClientConfig } from '../../logic/models/activated-client-config.model';
import { UIConfirmationDialogService, LEAVING_CART_DIALOG_CONFIG } from '@vdms-hq/ui';

@Component({
  selector: 'vdms-hq-ac-client-switch',
  templateUrl: './client-switch.component.html',
})
export class ClientSwitchComponent implements OnInit {
  public viewModel$: Observable<ClientSwitchViewModel> = EMPTY;
  #allClientsKeyLabels$: Observable<ClientKeyLabel[]> = EMPTY;
  #activatedClientId$: Observable<ClientRef> = EMPTY;

  constructor(
    private activatedClientService: ActivatedClientService,
    private router: Router,
    private confirmation: UIConfirmationDialogService,
    @Inject(ACTIVATED_CLIENT_CONFIG_TOKEN) private activatedClientConfig: ActivatedClientConfig,
  ) {}

  ngOnInit(): void {
    this.#allClientsKeyLabels$ = this.activatedClientService.authorizedClientsOptions$;
    this.#activatedClientId$ = this.activatedClientService.selectedClientId$;
    this.viewModel$ = combineLatest([this.#allClientsKeyLabels$, this.#activatedClientId$]).pipe(
      map(([selectOptions, activatedClientId]: [ClientKeyLabel[], ClientRef]) => ({
        clientsSelectOptions: selectOptions.sort((a, b) => a.label.localeCompare(b.label)),
        selectedClientId: activatedClientId,
      })),
    );
  }

  public changeClient(id: ClientRef) {
    if (window.location.href.includes('cart-v3/checkout')) {
      this.confirmation
        .open(LEAVING_CART_DIALOG_CONFIG)
        .pipe(
          take(1),
          filter(Boolean),
          switchMap(() => {
            this.activatedClientService.setActivatedClientId(id);
            return this.router.navigateByUrl(`/${this.activatedClientConfig.routing.clientSwitchRoute || '/'}`);
          }),
        )
        .subscribe();
    } else {
      this.activatedClientService.setActivatedClientId(id);
      this.router.navigateByUrl(`/${this.activatedClientConfig.routing.clientSwitchRoute || '/'}`);
    }
  }
}
