import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranscriptionGeneratorService } from '../../logic/transcription-generator.service';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SelectOption } from '@vdms-hq/shared';

@Component({
  selector: 'vdms-hq-transcription-models-dialog',
  templateUrl: './transcription-models-dialog.component.html',
  styleUrls: ['./transcription-models-dialog.component.scss'],
})
export class TranscriptionModelsDialogComponent {
  modelsSelectOptions$: Observable<SelectOption[]> = this.transcriptionGeneratorService.modelsSelectOptions$;
  selectedModel: string | null = null;
  loading = false;

  run() {
    if (!this.selectedModel) {
      return;
    }
    this.loading = true;

    this.transcriptionGeneratorService
      .generateNewTranscription(this.selectedModel)
      .pipe(take(1))
      .subscribe(() => {
        this.loading = false;
        this.matDialog.close();
      });
  }

  constructor(
    private matDialog: MatDialogRef<TranscriptionModelsDialogComponent>,
    private transcriptionGeneratorService: TranscriptionGeneratorService,
  ) {}

  cancel() {
    this.matDialog.close();
  }
}
