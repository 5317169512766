import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DataPresentationHeaderComponent,
  MultipleDataPresentationComponent,
  UIButtonModule,
  UIEmptyResultsModule,
  UIFormModule,
  UIInfinityLoaderModule,
  UILayoutModule,
} from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationsDatasource } from '../../logic/notifications.datasource';
import { NotificationMessageComponent } from '../../components/notification-message/notification-message.component';
import {
  NOTIFICATIONS_CATEGORIES,
  NotificationSubscriptionsService,
  NotificationTypes,
  SortOptions,
} from '@vdms-hq/api-contract';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from '@vdms-hq/toast';
import { camelToSnakeCase, DaysFromPipe, DestroyComponent } from '@vdms-hq/shared';
import { BehaviorSubject, map, Observable, switchMap } from 'rxjs';
import { NotificationMessageViewModel } from '../../logic/notification-message-view-model';

@Component({
  selector: 'vdms-hq-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MultipleDataPresentationComponent,
    TranslateModule,
    UIButtonModule,
    UIFormModule,
    UILayoutModule,
    DataPresentationHeaderComponent,
    NotificationMessageComponent,
    UIInfinityLoaderModule,
    UIEmptyResultsModule,
    DaysFromPipe,
  ],
  providers: [],
})
export class NotificationCenterComponent extends DestroyComponent implements OnInit {
  form = new FormGroup({
    sortDir: new FormControl<SortOptions['direction']>('desc'),
    categories: new FormControl<NOTIFICATIONS_CATEGORIES>(NOTIFICATIONS_CATEGORIES.ALL),
    types: new FormControl<string[]>([]),
  });
  alreadyReadNotifications$ = new BehaviorSubject<string[]>([]);
  dataSource$: Observable<NotificationMessageViewModel[]> = this.alreadyReadNotifications$.pipe(
    switchMap((uuids) =>
      this.dataSource.notifications$.pipe(
        map((notifications) => {
          if (!uuids?.length) {
            return notifications;
          }

          return notifications.map(
            (notification) =>
              new NotificationMessageViewModel({
                ...notification.props,
                read_at: uuids.includes(notification.props.uuid) ? new Date().toString() : notification.props.read_at,
              }),
          );
        }),
      ),
    ),
  );
  readonly categoriesSelectOptions = [
    { key: NOTIFICATIONS_CATEGORIES.ALL, label: 'All' },
    { key: NOTIFICATIONS_CATEGORIES.ERROR, label: 'Error' },
    { key: NOTIFICATIONS_CATEGORIES.INFORMATION, label: 'Information' },
    { key: NOTIFICATIONS_CATEGORIES.SUCCESSFUL, label: 'Successful' },
  ];
  readonly sortDirSelectOptions = [
    { key: 'asc', label: 'Oldest to newest' },
    { key: 'desc', label: 'Newest to oldest' },
  ];
  readonly notificationTypes$ = this.notificationsService.getNotificationTypes().pipe(
    map((types) => [
      ...types
        .filter((type) => Object.values(NotificationTypes).includes(type))
        .map((type) => ({
          key: type,
          label: `common.notification_subscriptions.types.type${camelToSnakeCase(type).toLowerCase()}`,
        })),
    ]),
  );

  constructor(
    public readonly dataSource: NotificationsDatasource,
    private notificationsService: NotificationSubscriptionsService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.#listenFiltersChanges();
  }

  markAsRead(uuid: string) {
    this.alreadyReadNotifications$.next([...this.alreadyReadNotifications$.value, uuid]);
    this.notificationsService
      .markNotificationAsRead(uuid)
      .subscribe(() => this.dataSource.unreadRefresh$.next(new Date().toString()));
  }

  markAllNotificationsAsRead() {
    this.dataSource.isLoading$.next(true);
    this.notificationsService.markAllNotificationsAsRead().subscribe(() => {
      this.dataSource.isLoading$.next(false);
      this.#refresh();
      this.toastService.success({
        id: 'notifications',
        message: 'common.notification_subscriptions.notification_messages.marked_as_read',
      });
    });
  }

  #refresh() {
    const refresh = new Date().toString();
    this.dataSource.reset();
    this.dataSource.notificationsRefresh$.next(refresh);
    this.dataSource.unreadRefresh$.next(refresh);
  }

  #listenFiltersChanges() {
    this.form.valueChanges.pipe(this.takeUntilDestroyed()).subscribe((form) => {
      this.dataSource.reset();
      this.dataSource.category$.next(form.categories ?? NOTIFICATIONS_CATEGORIES.SUCCESSFUL);
      this.dataSource.eventType$.next(form.types?.join(',') ?? '');
      this.dataSource.sortDir$.next(form.sortDir as SortOptions['direction']);
    });
  }

  #displayPackageDetails(packageUuid: string) {
    // todo
    // this.dialog.open()
  }
}
