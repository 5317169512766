export const audioTrackTableEnabledColumns: string[] = [
  'select',
  'track',
  'layout',
  'channels',
  'language',
  'class',
  'codec',
  'loudness',
  'sample_rate',
  'bit_depth',
  'qc_status',
  'actions',
];
