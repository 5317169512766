import { Inject, Injectable } from '@angular/core';
import { combineLatest, Observable, Subject, takeUntil, withLatestFrom } from 'rxjs';
import { WebsocketEntitiesUpdateInterface, WebsocketNotificationInterface, WsRequestMessage } from '@vdms-hq/shared';
import { filter, map, mergeMap, share, switchMap } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { AuthService } from '@vdms-hq/auth';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { API_CONFIG, ApiConfig } from '@vdms-hq/api-contract';
import { AssetFlatView } from '../transformation/asset-flat-transformer.service';

@Injectable({ providedIn: 'root' })
export class UpdatesStreamService {
  #destroy$ = new Subject<void>();
  #currentWSStream$:
    | WebSocketSubject<WebsocketNotificationInterface<WebsocketEntitiesUpdateInterface<AssetFlatView>>>
    | WsRequestMessage
    | null = null;
  readonly streamName = '{groupUuid}/updates';
  readonly connection$: Observable<WebsocketNotificationInterface<WebsocketEntitiesUpdateInterface<AssetFlatView>>> =
    combineLatest([this.activatedClientService.clientId$, this.auth.auth$]).pipe(
      takeUntil(this.#destroy$),
      withLatestFrom(this.auth.auth$),
      switchMap(([[clientId], auth]) => {
        if (!auth) {
          return [];
        }

        return this.auth.activeToken$.pipe(map((token) => ({ clientId: clientId, token, uid: auth.id })));
      }),
      map((params) => {
        const url = new URL(`${this.env.websocketUrl}`);
        url.searchParams.set('token', `${params.token}`);
        url.searchParams.set('stream', `${this.streamName.replace('{groupUuid}', params.clientId as string)}`);
        return url;
      }),
      mergeMap((url) => {
        this.#currentWSStream$ = webSocket<
          WebsocketNotificationInterface<WebsocketEntitiesUpdateInterface<AssetFlatView>>
        >({
          url: url.toString(),
        });
        return this.#currentWSStream$;
      }),
      filter(
        (msg: WebsocketNotificationInterface<WebsocketEntitiesUpdateInterface<AssetFlatView>>) =>
          typeof msg !== 'string',
      ),
      share(),
    );

  protected constructor(
    protected auth: AuthService,
    @Inject(API_CONFIG) protected env: ApiConfig,
    private activatedClientService: ActivatedClientService,
  ) {}

  sendWSMessage(message: WsRequestMessage) {
    if (!this.#currentWSStream$) {
      return;
    }
    (this.#currentWSStream$ as WebSocketSubject<WebsocketEntitiesUpdateInterface<WsRequestMessage | unknown>>).next(
      message,
    );
  }

  connect = () => {
    this.#destroy$ = new Subject<void>();
    return this.connection$;
  };

  destroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }
}
