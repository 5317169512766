import { PermissionAwareItem } from '@vdms-hq/shared';

export interface UISidenavSection extends Partial<PermissionAwareItem> {
  id: string;
  label?: string;
  walkthroughTrigger?: string;
  menuItems: UISidenavMenuItem[];
}

interface AnyMenuItem extends Partial<PermissionAwareItem> {
  id: string;
  queryParams?: Record<string, string>;
  icon: string;
  label: string;
  enabledFor?: string[];
  disabled?: boolean;
  exact?: boolean;
  walkthroughTrigger?: string;
}

export interface ExternalMenuItem extends AnyMenuItem {
  href: string;
  confirmation?: string;
}

export interface InternalMenuItem extends AnyMenuItem {
  route: string;
  routeParam?: string;
}

export type UISidenavMenuItem = ExternalMenuItem | InternalMenuItem;

export type UISidenavConfig = UISidenavSection[];

export const isExternal = (obj: any): obj is ExternalMenuItem => {
  return typeof obj === 'object' && 'href' in obj;
};

export const isInternal = (obj: any): obj is InternalMenuItem => {
  return typeof obj === 'object' && 'route' in obj;
};

export type SidenavState = 'expanded' | 'collapsed' | 'closed' | 'hovered';

export enum SidenavStatusEnum {
  Expanded = 'expanded',
  Collapsed = 'collapsed',
  Closed = 'closed',
  Hovered = 'hovered',
}
