import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import * as clientActions from '../actions/client.action';
import { Router } from '@angular/router';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { AutoloadEffect } from '@vdms-hq/state';
import { resetWebSockets, startCollectionsProgress } from '../actions/websockets.action';
import { UIConfirmationDialogService } from '@vdms-hq/ui';

@Injectable()
export class ClientEffect {
  triggerLoad$ = this.autoloadEffect.whenClientChange(() => [resetWebSockets(), startCollectionsProgress()]);

  reloadClient$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clientActions.askToChangeClient),
        switchMap(({ clientId }) => {
          return this.confirmationDialog
            .open({
              title: 'Please confirm',
              message: 'The resource exists in a different client, would you like to switch there?',
            })
            .pipe(
              take(1),
              map((dialogResponse) => ({
                clientId,
                isConfirmed: dialogResponse,
              })),
            );
        }),
        tap(({ isConfirmed }) => {
          if (!isConfirmed) {
            this.router.navigate(['/']);
          }
        }),
        filter(({ isConfirmed }) => isConfirmed),
        tap(({ clientId }) => this.activatedClientService.setActivatedClientId(clientId)),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private activatedClientService: ActivatedClientService,
    private autoloadEffect: AutoloadEffect,
    private confirmationDialog: UIConfirmationDialogService,
  ) {}
}
