import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import {
  Asset,
  AssetApiService,
  AssetFlat,
  FieldsOptionsService,
  FilenameAnalysisOutputData,
  FilenameAnalysisRequest,
  MetadataRecognitionApiService,
  POSSIBLE_KEYS,
  PossibleKeyType,
} from '@vdms-hq/api-contract';
import { AbstractControl, FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, combineLatest, filter, forkJoin, Observable, of, switchMap, take, throwError } from 'rxjs';
import { AsyncPipe, JsonPipe, NgForOf, NgIf } from '@angular/common';
import {
  castTo,
  DestroyComponent,
  FieldDefinitionModel,
  OptionType,
  RelationalOption,
  SelectOption,
} from '@vdms-hq/shared';
import { KeyToTitlePipe } from '../../logic/key-to-full-title.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { CellTypeToSelectorValuePipe } from '../../logic/cell-type-to-selector-value.pipe';
import { catchError } from 'rxjs/operators';
import { ToastService } from '@vdms-hq/toast';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { LanguageSources } from '@vdms-hq/selectors';
import { METADATA_MINIMUM_PERCENT_ACCURACY } from '../../logic/filename-conventions.validator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FieldsConfigService } from '@vdms-hq/config';
import objectPath from 'object-path';

@Component({
  selector: 'vdms-hq-metadata-recognition-dialog',
  templateUrl: './metadata-recognition-dialog.component.html',
  styleUrls: ['./metadata-recognition-dialog.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    UIDialogWrapperModule,
    AsyncPipe,
    NgForOf,
    ReactiveFormsModule,
    JsonPipe,
    UIFormModule,
    NgIf,
    KeyToTitlePipe,
    UIButtonModule,
    TranslateModule,
    CellTypeToSelectorValuePipe,
    MatTooltipModule,
  ],
  providers: [],
})
export class MetadataRecognitionDialogComponent extends DestroyComponent implements OnInit {
  form = new FormGroup({
    entities: new FormArray<FormControl>([]),
    metadata: new FormArray<FormGroup>([]),
  });
  selectAll = new FormControl(false);
  loading = false;
  dataSource: Record<string, Record<string, Array<SelectOption & { percents: number }>>> = {};

  readonly columns: PossibleKeyType[] = ['filename'];
  readonly readonlyColumns: PossibleKeyType[] = ['filename'];

  $castToBoolean = castTo<boolean>();
  $castToControl = castTo<FormControl>();

  #definitions = new BehaviorSubject<FieldDefinitionModel[]>([]);
  #EMPTY_VALUE = 'EMPTY';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { entities: AssetFlat[] },
    @Inject(METADATA_MINIMUM_PERCENT_ACCURACY) private readonly percentAccuracy: number,
    private readonly metadataRecognitionApi: MetadataRecognitionApiService,
    private readonly cdr: ChangeDetectorRef,
    private readonly ref: MatDialogRef<MetadataRecognitionDialogComponent>,
    private readonly assetApiService: AssetApiService,
    private readonly activatedClient: ActivatedClientService,
    private readonly fieldsOptionsService: FieldsOptionsService,
    private readonly fieldsConfigService: FieldsConfigService,
    private readonly toastService: ToastService,
  ) {
    super();
  }

  get formArray() {
    return this.form.controls.metadata;
  }

  get formEntitiesArray() {
    return this.form.controls.entities;
  }

  trackByFn(index: number, item: AbstractControl) {
    return item.value['uuid'] ?? item;
  }

  ngOnInit(): void {
    this.#init();
  }

  skip() {
    this.ref.close();
  }

  enableSave() {
    return this.form.value?.entities?.some((e) => e);
  }

  save(all = false) {
    const payload = this.formArray.controls
      .map((control) => (control.enabled || all ? control.value : null))
      .filter(Boolean)
      ?.map((item) => ({ uuid: item.uuid, payload: this.#toPayload(item) }))
      ?.filter(({ payload }) => Object.keys(payload)?.length)
      ?.map(({ uuid, payload }) => this.#updateAsset(uuid, payload));

    if (!payload?.length) {
      return;
    }

    this.loading = true;
    forkJoin(payload)
      .pipe(
        take(1),
        catchError((err: HttpErrorResponse) => {
          this.loading = false;
          this.cdr.detectChanges();
          this.ref.close();
          return of(err);
        }),
      )
      .subscribe(() => {
        this.loading = false;
        this.cdr.detectChanges();
        this.ref.close();
      });
  }

  #init() {
    this.loading = true;
    this.#saveDefinitions();
    this.activatedClient.metadataRecognition$
      .pipe(
        switchMap((client): Observable<[FilenameAnalysisOutputData[], number]> => {
          const data: FilenameAnalysisRequest['data'] = this.#toFileNameAnalysisRequest(this.data.entities);
          const conventions: FilenameAnalysisRequest['conventions'] = client?.filenameConventions ?? [];
          return combineLatest([
            this.metadataRecognitionApi.analyzeFilenames({ data, conventions }).pipe(
              catchError((err: HttpErrorResponse) => {
                this.ref.close();
                return throwError(err);
              }),
            ),
            of(client?.minimumPercentageAccuracy ?? this.percentAccuracy),
          ]).pipe(take(1));
        }),
      )
      .subscribe((data: [FilenameAnalysisOutputData[], number]) => {
        const [metadata, accuracy] = data;
        this.#generateForm(metadata, accuracy);
        this.cdr.detectChanges();
      });
  }

  #generateForm(metadata: FilenameAnalysisOutputData[], accuracy: number) {
    for (const entity of metadata) {
      const controls: Record<string, FormControl> = {
        uuid: new FormControl({ value: entity.uuid, disabled: true }),
        filename: new FormControl({ value: entity.filename, disabled: true }),
      };
      for (const match of entity.matches) {
        for (const part of match.parts) {
          !this.columns.includes(part.key) && POSSIBLE_KEYS.includes(part.key) && this.columns.push(part.key);

          if (!this.dataSource?.[entity.uuid]) {
            this.dataSource[entity.uuid] = {};
          }

          if (part.match && (part?.percents ?? 0) >= accuracy) {
            if (!this.dataSource?.[entity.uuid]?.[part.key]) {
              this.dataSource[entity.uuid][part.key] = [
                { label: part.match, key: part.match, percents: part.percents ?? 0 },
              ];
            }

            if (!this.dataSource[entity.uuid][part.key].some(({ key }) => key === part.match)) {
              this.dataSource[entity.uuid][part.key].push({
                label: part.match,
                key: part.match,
                percents: part.percents ?? 0,
              });
            }

            controls[part.key] = new FormControl({ value: undefined, disabled: true });
          }
        }
      }
      this.formEntitiesArray.push(new FormControl(false));
      this.formArray.push(new FormGroup(controls));
    }
    this.#sortAndMatchDataSources();
    this.#listenEntitiesChange();
    this.#listenSelectAllEntities();
  }

  #toFileNameAnalysisRequest(jobs: AssetFlat[]): FilenameAnalysisRequest['data'] {
    return jobs.map(({ uuid, filename }) => ({ uuid, filename: filename?.replace(' ', '_') }));
  }

  #listenEntitiesChange() {
    this.formEntitiesArray.valueChanges
      .pipe(
        this.takeUntilDestroyed(),
        filter(({ length }) => !!length),
      )
      .subscribe((states: boolean[]) => {
        states?.forEach((state, index) => {
          const control = this.formArray.at(index);
          if ((state && control?.enabled) || (!state && control?.disabled) || !control) {
            return;
          }
          control[state ? 'enable' : 'disable']({ emitEvent: false });
        });
        this.selectAll.setValue(
          states.every((value) => !!value),
          { emitEvent: false },
        );
      });
  }

  #listenSelectAllEntities() {
    this.selectAll.valueChanges.pipe(this.takeUntilDestroyed()).subscribe((state) => {
      this.formEntitiesArray.controls.forEach((entity) => entity.setValue(state));
      this.formArray.controls.forEach((form) => (state ? form.enable() : form.disable()));
    });
  }

  #updateAsset(uuid: string, asset: Asset) {
    return this.assetApiService.batchUpdate([uuid], asset);
  }

  #sortAndMatchDataSources() {
    const KeyTypeToOptionTypeKey: Record<string, string> = {
      episodeName: OptionType.EPISODE_NAME,
      seriesName: OptionType.SERIES_NAME,
      facilityOfOrigin: OptionType.FACILITY_OF_ORIGIN,
      language: LanguageSources.LANGUAGE,
      elements: OptionType.ELEMENTS,
      segments: OptionType.SEAMLESS_SEGMENTED,
      genre: OptionType.GENRE,
      productionCompany: OptionType.PRODUCTION_COMPANY,
      contentClass: OptionType.CONTENT_CLASS,
      contentType: OptionType.CONTENT_CLASS,
      theme: OptionType.THEME,
      variation: OptionType.VARIATION,
      category: OptionType.CATEGORY,
      seasonTitle: OptionType.SEASON_TITLE,
      releaseYear: OptionType.RELEASE_YEAR,
    };
    this.fieldsOptionsService.getTypes().subscribe((type) => {
      Object.entries(this.dataSource).forEach(([uuid, value]) => {
        Object.entries(value).forEach(([key, selectOptions]) => {
          const source = type.find(({ name }) => name === KeyTypeToOptionTypeKey[key])?.fields;
          if (source && this.dataSource[uuid][key]?.length) {
            const matchingData = this.#rawOptionsToExistingOptions(selectOptions, source);
            if (matchingData?.length) {
              this.dataSource[uuid][key] = [...matchingData, { key: this.#EMPTY_VALUE, label: 'N/A', percents: 0 }];
            } else {
              delete this.dataSource[uuid][key];
            }
          } else {
            delete this.dataSource[uuid][key];
          }
        });
      });

      this.#checkDataSourcesContent();
      this.#removeEmptyRecords();
      this.#setHighestRatedOption();
      setTimeout(() => {
        this.loading = false;
        this.cdr.detectChanges();
      });
    });
  }

  #rawOptionsToExistingOptions(
    selectOptions: Array<SelectOption & { percents: number }>,
    source: RelationalOption[],
  ): Array<SelectOption & { percents: number }> {
    return (
      selectOptions
        .map((selectOption) => {
          const matchingOption = source.find(
            ({ key }) => key.toLowerCase() === String(selectOption?.key ?? '')?.toLowerCase(),
          );

          if (!matchingOption) {
            // console.error('Option doesnt exist in data source.', selectOption.key);
            // return null;

            const option = selectOption.label
              .split(' ')
              .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
              .join(' ');
            return {
              key: option,
              label: option,
            };
          }

          return { label: matchingOption?.label, key: matchingOption?.key, percents: selectOption.percents };
        })
        .filter(Boolean) as Array<SelectOption & { percents: number }>
    ).sort((a, b) => a?.percents + b?.percents);
  }

  #setHighestRatedOption() {
    this.formArray.controls.forEach((control) => {
      const uuid = control.value['uuid'];
      this.columns.forEach((column) => {
        if (this.readonlyColumns.includes(column)) {
          return;
        }

        const value = this.dataSource[uuid][column]?.[0]?.key;
        value && control.get(column)?.setValue(value);
      });
    });
  }

  #toPayload(item: { [key: PossibleKeyType[number]]: string }): Asset {
    const asset = <Asset>{};
    const defs = this.#definitions.value;
    Object.entries(item).forEach(([key, value]) => {
      if (key === 'uuid' || key === 'filename' || !value) {
        return;
      }

      const path = defs.find(({ id }) => id === key)?.input?.objectPath;
      if (!path) {
        return;
      }

      const correctValue = value === this.#EMPTY_VALUE ? null : value;
      objectPath.set(asset, path, correctValue);
    });
    return asset;
  }

  #saveDefinitions() {
    this.fieldsConfigService.allFieldDefinitions$.pipe(take(1)).subscribe((defs) => this.#definitions.next(defs));
  }

  #checkDataSourcesContent() {
    const hasAnyMatches = !!Object.values(this.dataSource)
      .map((value) => Object.keys(value)?.length)
      ?.filter(Boolean)?.length;
    if (!hasAnyMatches) {
      this.toastService.info({
        id: 'metadata_recognition',
        message: 'There are no matches for the provided files',
      });
      this.ref.close();
    }
  }

  #removeEmptyRecords() {
    Object.entries(this.dataSource).forEach(([key, value]) => {
      if (!Object.keys(value)?.length) {
        this.#removeControlByUuid(key);
      }
    });

    this.cdr.detectChanges();
  }

  #removeControlByUuid(uuid: string) {
    const index = this.formArray.controls.findIndex(({ value }) => value['uuid'] === uuid);
    if (index < 0) {
      return;
    }

    this.formArray.removeAt(index);
    delete this.dataSource[index];
  }
}
