import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadAsperaComponent } from './upload-aspera.component';
import { UploadAsperaRoutingModule } from './upload-aspera-routing.module';
import { UILayoutModule } from '@vdms-hq/ui';
import { UploadContainerStandaloneComponent } from '@vdms-hq/storage';

@NgModule({
  declarations: [UploadAsperaComponent],
  imports: [CommonModule, UploadAsperaRoutingModule, UILayoutModule, UploadContainerStandaloneComponent],
})
export class UploadAsperaModule {}
