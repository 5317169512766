import { ApiService } from '../api.service';
import { ImportVideoCodecsCSV, PostVideoCodec, VIDEO_CODECS_ENDPOINTS, VideoCodec } from './video-codecs';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../api.model';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { AwsV4Signature } from '../upload/upload';

@Injectable({ providedIn: 'root' })
export class VideoCodecsApiService {
  constructor(private api: ApiService) {}

  getAll = (): Observable<VideoCodec[]> =>
    this.api.get<ApiResponse<VideoCodec[]>>(VIDEO_CODECS_ENDPOINTS.ROOT_MULTIPLE).pipe(GetResponseData);

  getOne = (uuid: string): Observable<VideoCodec> =>
    this.api.get<ApiResponse<VideoCodec>>(`${VIDEO_CODECS_ENDPOINTS.ROOT_SINGLE}/${uuid}`).pipe(GetResponseData);

  post = (payload: PostVideoCodec) => this.api.post(VIDEO_CODECS_ENDPOINTS.ROOT_SINGLE, payload);

  patch = (uuid: string, payload: VideoCodec) =>
    this.api.patch(`${VIDEO_CODECS_ENDPOINTS.ROOT_SINGLE}/${uuid}`, payload);

  delete = (uuid: string) => this.api.delete(`${VIDEO_CODECS_ENDPOINTS.ROOT_SINGLE}/${uuid}`);

  importCSV = (payload: ImportVideoCodecsCSV): Observable<AwsV4Signature> =>
    this.api
      .patch<ImportVideoCodecsCSV, ApiResponse<AwsV4Signature>>(`${VIDEO_CODECS_ENDPOINTS.ROOT_MULTIPLE}/csv`, payload)
      .pipe(GetResponseData);

  exportCSV = () => this.api.post(`${VIDEO_CODECS_ENDPOINTS.ROOT_MULTIPLE}/csv`, {});
}
