export enum NotificationTypes {
  NEW_ASSET_ARRIVED = 'NewAssetArrived',
  ASSET_INGEST_COMPLETED = 'AssetIngestCompleted',
  NEW_LAUNCHPAD_PACKAGE_PENDING_APPROVAL = 'NewLaunchpadPackagePendingApproval',
  LAUNCHPAD_PACKAGE_APPROVED = 'LaunchpadPackageApproved',
}

export enum LicensedPackagesNotificationTypes {
  ASSET_ADDED_LICENSED_PACKAGE = 'AssetAddedLicensedPackage',
  RIGHTS_CONTRACT_START = 'RightsContractStart',
  RIGHTS_CONTRACT_END = 'RightsContractEnd',
}

export enum NOTIFICATIONS_CATEGORIES {
  ALL = 'All',
  ERROR = 'Error',
  SUCCESSFUL = 'Successful',
  INFORMATION = 'Information',
}

export type EmailNotificationTypes = 'per_occurrence' | 'daily_summary';

export enum EMAIL_NOTIFICATION_TYPES {
  PER_OCCURRENCE = 'per_occurrence',
  DAILY_SUMMARY = 'daily_summary',
}

export interface NotificationSubscription {
  id: string;
  uuid: string;
  user: {
    email: string;
    uuid: string;
    name: string;
  };
  notification_event_type: NotificationTypes;
  group: string;
  app_notifications_enabled: boolean;
  email_notifications_enabled: boolean;
  email_notification_type: EmailNotificationTypes;
  subscribed_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface PostNotificationSubscription {
  notification_event_type: NotificationTypes;
  app_notifications_enabled: boolean;
  email_notifications_enabled: boolean;
  email_notification_type: EmailNotificationTypes;
  user_uuid?: string;
  group_uuid?: string;
}

export interface BatchPostNotificationSubscription extends PostNotificationSubscription {
  user_uuid: string;
  group_uuid: string;
}

export interface PatchNotificationSubscriptionInterface {
  app_notifications_enabled: boolean;
  email_notifications_enabled: boolean;
  email_notification_type: EmailNotificationTypes;
}

export interface BatchPatchNotificationSubscriptionInterface extends PatchNotificationSubscriptionInterface {
  subscription_uuid: string;
  user_uuid: string;
  group_uuid: string;
}

export interface BatchDeleteNotificationSubscription {
  subscription_uuid: string;
}

export interface Notification {
  id: number;
  uuid: string;
  event_type: NotificationTypes;
  category: 'Information' | 'Successful' | 'Error';
  subject: string;
  message: NotificationMessage;
  read_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export type NotificationMessage =
  | NewAssetArrivedMessage
  | AssetIngestCompletedMessage
  | NewLaunchpadPackagePendingApprovalMessage
  | LaunchpadPackageApprovedMessage;

export interface AssetDetailsForNotification {
  uuid: string;
  filename: string;
  type: 'image' | 'subtitle' | 'video' | 'audio' | 'archive' | 'document' | 'other' | 'font';
  created_by: string;
  created_at: string;
  md5?: string;
}

export type LaunchpadPackageApprovedMessage = NewLaunchpadPackagePendingApprovalMessage;

export type NewLaunchpadPackagePendingApprovalMessage = {
  package_uuid: string;
  package_title?: string;
  package_owner: string;
  assets_count: number;
  package_url: string;
  assets: AssetDetailsForNotification[];
};

export type NewAssetArrivedMessage = {
  assets: AssetDetailsForNotification[];
};

export type AssetIngestCompletedMessage = {
  assets: AssetDetailsForNotification[];
};
