import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedClientService } from '@vdms-hq/activated-client';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  // todo instead of hardcoded ids, please create settings
  selectedClientId$: Observable<string>;

  constructor(private activatedClientConfig: ActivatedClientService) {}

  ngOnInit(): void {
    this.selectedClientId$ = this.activatedClientConfig.selectedClientId$;
  }
}
