import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, filter, map, startWith } from 'rxjs';
import { METADATA_CONFIDENCE_THRESHOLD } from './cfg/metadata-list-filters-select-options';

type MetadataListFilters = {
  type: string[];
  phrase: string[];
  confidence: number;
};

@Injectable({ providedIn: 'root' })
export class MetadataListForm {
  filters = new FormGroup({
    type: new FormControl<string[]>([], Validators.required),
    phrase: new FormControl<string[]>([]),
    confidence: new FormControl<string>(METADATA_CONFIDENCE_THRESHOLD[7].key),
  });

  values$ = this.filters.valueChanges.pipe(
    debounceTime(400),
    startWith(this.filters.value),
    filter(() => this.filters.valid),
    map(() => {
      const formValue = this.filters.value;
      const filters = <MetadataListFilters>{};

      if (formValue?.phrase) {
        filters.phrase = formValue.phrase;
      }

      if (formValue.type) {
        filters.type = formValue.type;
      }

      if (formValue.confidence) {
        filters.confidence = Number(formValue.confidence);
      }

      return filters;
    }),
  );
}
