import { DestinationRule, SimpleDestinationRule, VideoDestinationRule } from '@vdms-hq/api-contract';

export const transformVideoRuleToSendableObject = (rule: DestinationRule) => {
  if (isSimpleType(rule)) {
    return rule;
  }

  try {
    const overridenProfileInJson = rule.transcode?.overrideProfile
      ? JSON.parse(rule.transcode?.overrideProfile as string)
      : undefined;
    const audioTracks = rule?.transcode?.audioTracks?.map((audio) => {
      const audioTrack = { ...audio };
      !audioTrack?.languageUuid && delete audioTrack?.languageUuid;
      return audioTrack;
    });

    return {
      ...rule,
      transcode: rule.transcode
        ? {
            ...rule.transcode,
            overrideProfile: overridenProfileInJson,
            audioTracks,
          }
        : undefined,
    };
  } catch (e) {
    console.error(e);
    return rule;
  }
};

export const transformVideoRuleToReadableObject = (rule: DestinationRule) => {
  if (isSimpleType(rule)) {
    return rule;
  }

  try {
    const overridenProfileInString = rule.transcode?.overrideProfile
      ? JSON.stringify(rule.transcode?.overrideProfile, null, 2)
      : null;
    return { ...rule, transcode: { ...rule.transcode, overrideProfile: overridenProfileInString } };
  } catch (e) {
    return rule;
  }
};

export const isVideoType = (rule: DestinationRule): rule is VideoDestinationRule => rule.assetType === 'video';
export const isSimpleType = (rule: DestinationRule): rule is SimpleDestinationRule => rule.assetType !== 'video';
