import { inject, Injectable } from '@angular/core';
import {
  getAuth,
  getMultiFactorResolver,
  multiFactor,
  MultiFactorError,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  PhoneMultiFactorInfo,
  TotpMultiFactorGenerator,
  TotpSecret,
} from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { filterEmpty } from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import * as QRCode from 'qrcode';
import { BehaviorSubject, from, Observable, of, switchMap, tap, throwError, withLatestFrom } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler.service';
import { MfaFactor, MfaModel } from './mfa.model';
import { ReCaptchaService } from './re-captcha.service';

@Injectable({ providedIn: 'root' })
export class AuthMfaService {
  private auth = inject(AngularFireAuth);
  private recaptcha = inject(ReCaptchaService);
  private mfaErrorService = inject(ErrorHandlerService);
  private toastService = inject(ToastService);

  #user = this.auth.user.pipe(filterEmpty());
  #session = this.#user.pipe(
    switchMap((user) => {
      return user.multiFactor.getSession();
    }),
  );

  #mfa$: Observable<MfaModel> = this.#user.pipe(
    map((user) => ({
      enabled: user.multiFactor.enrolledFactors.length > 0,
      factors: user.multiFactor.enrolledFactors as PhoneMultiFactorInfo[],
    })),
  );

  factors$ = this.#mfa$.pipe(map((mfa) => mfa.factors));
  mfaEnabled$ = this.#mfa$.pipe(map((mfa) => mfa.enabled));
  totpSecretKey$ = new BehaviorSubject<TotpSecret | null>(null);

  setUpNewPhone = (phoneNumber: string): Observable<{ verificationId: string | null; logout?: boolean }> => {
    return this.#session.pipe(
      take(1),
      switchMap((session) => {
        const authService = getAuth();

        const recaptchaVerifier = this.recaptcha.createCaptcha();

        const phoneInfoOptions = {
          phoneNumber: phoneNumber,
          session,
        };

        const phoneAuthProvider = new PhoneAuthProvider(authService);

        return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
      }),
      tap(() => {
        this.recaptcha.removeCaptcha();
      }),
      map((verificationId) => ({ verificationId })),
      catchError((error) => {
        this.mfaErrorService.handleMfaErrors(error);
        this.recaptcha.removeCaptcha();
        return of({ verificationId: null, logout: error.code === 'auth/requires-recent-login' });
      }),
    );
  };

  initTotpMfa() {
    return this.#user.pipe(
      take(1),
      switchMap((user) => {
        return from(multiFactor(user).getSession());
      }),
      switchMap((mfaSession) => {
        const totpSecret = from(TotpMultiFactorGenerator.generateSecret(mfaSession)).pipe(withLatestFrom(this.#user));
        return totpSecret;
      }),
      switchMap(([totpSecret, user]) => {
        this.totpSecretKey$.next(totpSecret);
        const totpUri = totpSecret.generateQrCodeUrl(user.email as string, 'vida-app');
        return from(QRCode.toCanvas(document.getElementById('canvas'), totpUri));
      }),
      catchError((err) => {
        if (err?.message?.includes('requires-recent-login')) {
          this.toastService.error({
            id: 'requires-recent-login',
            message:
              'This operation is sensitive and requires recent authentication. Log in again before retrying set up this configuration.',
          });
          return of({ errorMsg: 'requires-recent-login' });
        }
        return throwError(err);
      }),
    );
  }

  finalizeTotp(totpSecret: TotpSecret, verificationCode: string) {
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(totpSecret, verificationCode);
    return this.#user.pipe(
      take(1),
      switchMap((user) => from(multiFactor(user).enroll(multiFactorAssertion, user.displayName))),
    );
  }

  signInWithTotp(error: MultiFactorError, otpFromAuthenticator: string, uid: string) {
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(uid, otpFromAuthenticator);

    const auth = getAuth();
    const resolver = getMultiFactorResolver(auth, error);
    return from(resolver.resolveSignIn(multiFactorAssertion));
  }

  confirmNewPhone(label: string, verificationId: string, verificationCode: string) {
    return this.#user.pipe(
      take(1),
      switchMap((user) => {
        const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

        return user.multiFactor.enroll(multiFactorAssertion, label);
      }),
      map(() => true),
      catchError((error) => {
        this.mfaErrorService.handleMfaErrors(error);
        return of(false);
      }),
    );
  }

  removeAllMultiFactors() {
    return this.#user.pipe(
      take(1),
      switchMap(async (user) => {
        for (const factor of user.multiFactor.enrolledFactors) {
          await user.multiFactor.unenroll(factor.uid);
        }
      }),
      map(() => true),
      catchError((error) => {
        this.mfaErrorService.handleMfaErrors(error);
        return of(false);
      }),
    );
  }

  removeMultiFactor(uid: string) {
    return this.#user.pipe(
      switchMap((user) => {
        return user.multiFactor.unenroll(uid);
      }),
      take(1),
      map(() => true),
      tap(() => {
        this.toastService.success({
          id: 'mfa_removed',
          message: 'common.account_settings.mfa.removed',
        });
      }),
      catchError((error) => {
        if (error.code === 'auth/user-token-expired') {
          this.toastService.success({
            id: 'mfa_removed',
            message: 'common.account_settings.mfa.removed',
          });
          return of(true);
        }

        if (error.code === 'auth/multi-factor-info-not-found') {
          this.toastService.success({
            id: 'mfa_removed',
            message: 'common.account_settings.mfa.removed',
          });
          return of(true);
        }

        throw error;
      }),
      catchError((error) => {
        this.mfaErrorService.handleMfaErrors(error);
        return of(false);
      }),
    );
  }

  multiFactorResolver(error: MultiFactorError) {
    const auth = getAuth();
    const resolver = getMultiFactorResolver(auth, error);

    return resolver.hints as MfaFactor[];
  }

  proofOwnership(error: MultiFactorError, hintUid: string): Observable<{ verificationId: string | null }> {
    const auth = getAuth();
    const resolver = getMultiFactorResolver(auth, error);

    const recaptchaVerifier = this.recaptcha.createCaptcha();

    const phoneInfoOptions = {
      multiFactorUid: hintUid,
      session: resolver.session,
    };

    const phoneAuthProvider = new PhoneAuthProvider(auth);

    return from(phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)).pipe(
      tap(() => {
        this.recaptcha.removeCaptcha();
      }),
      map((verificationId) => ({ verificationId })),
      catchError((error) => {
        this.mfaErrorService.handleMfaErrors(error);
        this.recaptcha.removeCaptcha();
        return of({ verificationId: null });
      }),
    );
  }

  proofOwnershipCode(error: MultiFactorError, verificationId: string, verificationCode: string) {
    const auth = getAuth();
    const resolver = getMultiFactorResolver(auth, error);

    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

    return from(resolver.resolveSignIn(multiFactorAssertion)).pipe(
      map(() => true),
      catchError((error) => {
        this.mfaErrorService.handleMfaErrors(error);
        return of(false);
      }),
    );
  }

  destroy() {
    this.totpSecretKey$.next(null);
  }
}
