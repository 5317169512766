import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { FormInputTimecodeComponent, UIButtonModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { CommonModule } from '@angular/common';
import { PlayerService } from '../../logic/player.service';
import { Framerate, Timecode } from '@vdms-hq/timecode';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'vdms-hq-player-advanced-actions',
  templateUrl: 'player-advanced-actions.component.html',
  styleUrls: ['player-advanced-actions.component.scss'],
  standalone: true,
  imports: [CommonModule, UILayoutModule, UIButtonModule, UIFormModule],
})
export class PlayerAdvancedActionsComponent implements OnInit, OnDestroy {
  @ViewChild('timecodeInput') timecodeInput!: FormInputTimecodeComponent;

  timecode: Timecode | null = Timecode.fromTimecode('00:00:00:00', Framerate.default());
  framerateDefault = Framerate.default();
  #destroyed = new Subject<void>();

  constructor(public playerService: PlayerService) {}

  ngOnInit() {
    this.playerService.offset$.pipe(takeUntil(this.#destroyed)).subscribe((offset) => {
      this.timecode = offset;
    });
  }

  ngOnDestroy(): void {
    this.#destroyed.next();
    this.#destroyed.complete();
  }

  keyUp($event: KeyboardEvent) {
    if ($event.code === 'Enter') {
      if (this.timecode && this.timecodeInput.innerFormControl.valid) {
        this.playerService.goToTimecode(this.timecode, true);
      }
    }
  }
}
