<div class="relative">
  <vdms-hq-ui-loader
    mode="over-parent"
    [backdrop]="true"
    *ngIf="(dataSource.isLoadingPackageDetails$ | async) && (dataSource.isLoading$ | async) === false"
  ></vdms-hq-ui-loader>
  <vdms-hq-ui-inner-header
    *ngIf="licensedPackageData$ | async as licensedPackageData"
    [breadcrumbs]="licensedPackageData.name"
    [subheader]="licensedPackageData.subtitle"
    [status]="{
      label: licensedPackageData.status.toUpperCase(),
      color: licensedPackageData.status | statusColor
    }"
    [action]="headerAction"
    (clickAction)="editLicensePackageDetails()"
  >
    <vdms-hq-ui-simple-details-list
      class="grid-list-template"
      [listConfig]="licensedPackageData.details"
    ></vdms-hq-ui-simple-details-list>
  </vdms-hq-ui-inner-header>

  <vdms-hq-ui-layout-page-wrapper *ngIf="dataSource.packageDetailsLoaded$ | async">
    <vdms-hq-ui-multiple-data-presentation
      *ngIf="multiConfig$ | async; let config"
      [dataSource]="dataSource"
      [configuration]="config"
      (action)="customActionHandler($event)"
    >
    </vdms-hq-ui-multiple-data-presentation>
  </vdms-hq-ui-layout-page-wrapper>
</div>

<vdms-hq-asset-results-actions
  *ngIf="dataSource.selection.selected$ | async"
  [enabledActions]="batchActions"
  [actionLabels]="batchActionLabels"
  [selectionManager]="dataSource.selection"
  [cartButtonVisibility]="false"
  (actions)="batchActionHandler({ key: $event })"
>
</vdms-hq-asset-results-actions>
