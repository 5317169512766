import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONFIG, ApiConfig } from '../config-token';
import { DolbyPlayerRequest, DolbyPlayerResponse } from './dolby.model';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlayerDolbyRaspApiService {
  constructor(private http: HttpClient, @Inject(API_CONFIG) private env: ApiConfig) {}

  getPlayerConfig = (token: string, body: DolbyPlayerRequest) => {
    if (!this.env.dolbyRaspApiUrl) {
      throw 'Token to dolby rasp is not provided';
    }

    return this.http.post<DolbyPlayerResponse>(`${this.env.dolbyRaspApiUrl}/hybrik/player`, body, {
      headers: {
        'x-rasp-auth-token': token,
      },
    });
  };
}
