<div
  class="data"
  [visibleArea]="configuration.multiView?.fitToVisibleArea ?? true"
  [class.data--fit-to-visible-area]="configuration.multiView?.fitToVisibleArea ?? true"
>
  <vdms-hq-ui-loader-ds [dataSource]="dataSource"></vdms-hq-ui-loader-ds>

  <div class="data__second-header">
    <div class="data__second-header__filters">
      <ng-content select="[filters]"></ng-content>
    </div>
    <div class="data__second-header__action" *ngIf="enabledViews.length > 1">
      <ng-container *ngIf="configuration.tableAdvanced?.headerActions; let headerActions">
        <ng-container *ngFor="let headerAction of headerActions">
          <vdms-hq-ui-button
            (click)="action.emit({ key: headerAction.key })"
            [iconOnly]="true"
            [disabled]="headerAction.disabled === true"
            [icon]="headerAction.icon"
            [tooltip]="headerAction.label | translate"
          ></vdms-hq-ui-button>
        </ng-container>
      </ng-container>

      <vdms-hq-ui-button
        *ngIf="configuration.tableAdvanced"
        (click)="changeView('tableAdvanced')"
        [iconOnly]="true"
        [icon]="'view_headline'"
        [class.inactive]="view !== 'tableAdvanced'"
        [tooltip]="'tooltip.table' | translate"
      >
      </vdms-hq-ui-button>

      <vdms-hq-ui-button
        *ngIf="configuration.gridAdvanced"
        (click)="changeView('gridAdvanced')"
        [iconOnly]="true"
        [icon]="'view_list'"
        [class.inactive]="view !== 'gridAdvanced'"
        [tooltip]="'tooltip.list' | translate"
      >
      </vdms-hq-ui-button>

      <vdms-hq-ui-button
        *ngIf="configuration.gridTiles"
        (click)="changeView('gridTiles')"
        [iconOnly]="true"
        [icon]="'view_module'"
        [class.inactive]="view !== 'gridTiles'"
        [tooltip]="'tooltip.grid' | translate"
      >
      </vdms-hq-ui-button>
    </div>
  </div>
  <div class="data__results" [ngSwitch]="view">
    <div class="data__results-content" #content>
      <vdms-hq-ui-grid-tiles
        *ngSwitchCase="'gridTiles'"
        [configuration]="configuration.gridTiles"
        [dataSource]="dataSource"
        (action)="action.emit($event)"
      >
      </vdms-hq-ui-grid-tiles>

      <vdms-hq-ui-grid-advanced
        *ngSwitchCase="'gridAdvanced'"
        [dataSource]="dataSource"
        [configuration]="configuration.gridAdvanced"
        (action)="action.emit($event)"
      >
      </vdms-hq-ui-grid-advanced>

      <vdms-hq-ui-table-advanced
        *ngSwitchCase="'tableAdvanced'"
        [dataSource]="dataSource"
        [configuration]="configuration.tableAdvanced"
        (action)="action.emit($event)"
      >
      </vdms-hq-ui-table-advanced>

      <ng-container *ngIf="isWithEmptyResults(dataSource); let emptyDs">
        <vdms-hq-ui-empty-results
          class="data__results-empty"
          *ngIf="emptyDs.emptyResults$ | async"
          [message]="configuration.multiView?.emptyResults?.message"
          [btnText]="configuration.multiView?.emptyResults?.btnText"
          [showButton]="configuration.multiView?.emptyResults?.showButton"
          [navigateLink]="configuration.multiView?.emptyResults?.navigateLink"
          [icon]="configuration.multiView?.emptyResults?.icon"
        ></vdms-hq-ui-empty-results>
      </ng-container>
    </div>

    <div
      class="data__results-drawer"
      id="multiple-data-drawer-slot"
      [maxHeightAs]="content"
      [@enterAnimation]="element.childNodes.length > 0 ? 'enter' : 'close'"
      #element
    ></div>

    <div class="data__results-drawer" [maxHeightAs]="content" *ngIf="drawerVisible" [@enterAnimation]>
      <vdms-hq-ui-drawer-container [showClose]="showDrawerClose" (action)="action.emit($event)">
        <ng-container drawer-header>
          <ng-content select="[drawer-header]"></ng-content>
        </ng-container>
        <ng-container drawer-content>
          <ng-content select="[drawer-content]"></ng-content>
        </ng-container>
        <ng-container drawer-footer>
          <ng-content select="[drawer-footer]"></ng-content>
        </ng-container>
      </vdms-hq-ui-drawer-container>
    </div>
  </div>
  <div class="data__footer">
    <ng-container *ngIf="(configuration.multiView?.pagination ?? true) && isPageableDataSource(dataSource)">
      <vdms-hq-ui-paginator [dataSource]="dataSource"></vdms-hq-ui-paginator>
    </ng-container>
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
