import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { delay, tap } from 'rxjs/operators';
import { SummaryGeneratorService } from '../../logic/summary-generator.service';
import { TranscriptionDataSourceService } from '../../logic/transcription.data-source.service';
import { VoiceAiService } from '../../logic/voice-ai-service';

type ViewModel = string | null | undefined;
export type SuggestionsDialogOutput = string | null;

@Component({
  selector: 'vdms-hq-suggestions-dialog',
  templateUrl: './suggestions-dialog.component.html',
  styleUrls: ['./suggestions-dialog.component.scss'],
})
export class SuggestionsDialogComponent implements OnInit {
  modelsSelectOptions$ = this.summaryGeneratorService.modelsSelectOptions$;
  descriptionToneSelectOptions$ = this.summaryGeneratorService.descriptionToneSelectOptions$;
  voicesSelectOptions$ = this.voiceAiService.voicesSelectOptions$;
  selectedVoice?: string;
  selectedModel?: string;
  selectedToneModel?: string;
  selectedSuggestion: string | null = null;
  triggeringNew = false;

  suggestions: ViewModel[] = [];

  triggerNew = () => {
    const selectedUuid = this.selectedModel;
    if (!selectedUuid) {
      return;
    }

    this.triggeringNew = true;
    this.summaryGeneratorService.generateForDefaultTranscription$(selectedUuid, this.selectedToneModel).subscribe({
      next: (summaries) => {
        this.triggeringNew = false;
        if (Array.isArray(summaries)) {
          this.suggestions[0] = summaries[0]?.content ?? null;
          this.suggestions[1] = summaries[1]?.content ?? null;
          this.suggestions[2] = summaries[2]?.content ?? null;
        }
      },
      error: (error: Error) => {
        console.error(error);
      },
    });
  };

  refresh() {
    this.suggestions = [undefined, undefined, undefined];
    this.summaryGeneratorService
      .fetchLatest(3)
      .pipe(
        tap((summaries) => (this.suggestions[0] = summaries[0]?.content ?? null)),
        delay(1500),
        tap((summaries) => (this.suggestions[1] = summaries[1]?.content ?? null)),
        delay(1000),
        tap((summaries) => (this.suggestions[2] = summaries[2]?.content ?? null)),
      )
      .subscribe();
  }

  constructor(
    private matDialog: MatDialogRef<SuggestionsDialogComponent, SuggestionsDialogOutput>,
    private summaryGeneratorService: SummaryGeneratorService,
    public voiceAiService: VoiceAiService,
    public dataSourceService: TranscriptionDataSourceService,
  ) {}

  cancel() {
    this.matDialog.close();
  }

  close() {
    this.matDialog.close(this.selectedSuggestion);
  }

  ngOnInit(): void {
    this.refresh();
  }
}
