<div class="page-wrapper" [style.max-width]="maxWidth ?? 'unset'">
  <ng-content select="[before-navbar]"></ng-content>

  <div class="page-navbar" *ngIf="breadcrumbs.length > 0">
    <vdms-hq-ui-layout-breadcrumbs
      [breadcrumbs]="breadcrumbs"
      [size]="breadcrumbsSize"
      [loading]="loading"
      [additionalInfo]="additionalInfo"
    ></vdms-hq-ui-layout-breadcrumbs>
    <ng-content select="[actions]"></ng-content>
  </div>
  <div class="page-content" [class.page-content__gap-size-medium]="gapSize === 'medium'">
    <ng-content></ng-content>
  </div>
</div>
