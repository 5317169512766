<vdms-hq-ui-layout-page-wrapper
  [breadcrumbs]="breadcrumbs"
  [additionalInfo]="additionalInfo"
  [loading]="!!this.configuration?.withLoading && isLoadableDataSource(dataSource) && !!(dataSource.isLoading$ | async)"
>
  <div actions class="setting-cogs">
    <vdms-hq-ui-form-input-text
      *ngIf="dataSource.applyFilter"
      (keyup)="applyFilter($event)"
      [placeholder]="'common.global.filter' | translate"
      [withFooter]="false"
    ></vdms-hq-ui-form-input-text>

    <vdms-hq-ui-data-header-actions
      [actions]="configuration?.headerActions ?? []"
      (headerAction)="emitHeaderAction($event.event, $event.key)"
    ></vdms-hq-ui-data-header-actions>
  </div>

  <div class="results-content">
    <ng-content></ng-content>
  </div>
</vdms-hq-ui-layout-page-wrapper>
