import { Injectable } from '@angular/core';
import { CollectionModelGet, FlatCollectionViewModel } from '@vdms-hq/api-contract';
import { SingleCollectionService } from '../services/single-collection.service';
import { LocalDataSource, PageableDataSource } from '@vdms-hq/shared';
import { PageEvent } from '@angular/material/paginator';

@Injectable()
export class SingleCollectionSubDataSource
  extends LocalDataSource<CollectionModelGet | FlatCollectionViewModel>
  implements PageableDataSource
{
  isLoading$ = this.singleCollectionService.isLoading$;

  constructor(private singleCollectionService: SingleCollectionService) {
    super(singleCollectionService.subAllData$, {
      pageSizeOptions: [2, 4, 8],
      defaultPageSize: Number(localStorage.getItem('subcollection-page-size') ?? 2),
    });
  }

  override pageChange($event: PageEvent) {
    localStorage.setItem('subcollection-page-size', String($event.pageSize));
    super.pageChange($event);
  }
}
