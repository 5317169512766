<div class="multi-data-container">
  <vdms-hq-ui-data-presentation-header
    [title]="'pages.release_notes.title' | translate"
    [subtitle]="'pages.release_notes.subtitle' | translate"
    [size]="'large'"
    [actions]="actions"
    (action)="onAction($event)"
  >
  </vdms-hq-ui-data-presentation-header>
  <vdms-hq-ui-inner-sidebar>
    <vdms-hq-ui-inner-sidebar-menu
      [navItems]="navItems"
      left
      (action)="loadMore()"
      [actionLink]="(showLoadMore$ | async) ? actionButton : null"
    ></vdms-hq-ui-inner-sidebar-menu>
    <router-outlet></router-outlet>
    <vdms-hq-ui-loader [mode]="'over-parent'" [backdrop]="true" *ngIf="isFetching$ | async"></vdms-hq-ui-loader>
  </vdms-hq-ui-inner-sidebar>
</div>
