<table>
  <tr>
    <td>Disabled</td>
    <td>Pending</td>
    <td>Valid</td>
    <td>Invalid</td>
    <td>Dirty</td>
    <td>Pristine</td>
    <td>Touched</td>
    <td>UnTouched</td>
  </tr>
  <tr>
    <td>
      <vdms-hq-ui-button color="secondary" (click)="control.enable()" *ngIf="control.disabled">Enable</vdms-hq-ui-button>
      <vdms-hq-ui-button color="secondary" (click)="control.disable()" *ngIf="control.enabled">Disable</vdms-hq-ui-button>
    </td>
    <td>
      <vdms-hq-ui-button color="secondary" (click)="control.markAsPending({onlySelf})">Mark as pending</vdms-hq-ui-button>
    </td>
    <td>
      <vdms-hq-ui-button color="secondary" (click)="control.reset()">Reset</vdms-hq-ui-button><br/>
    </td>
    <td></td>
    <td>
      <vdms-hq-ui-button color="secondary" (click)="control.markAsDirty({onlySelf})">Mark as dirty</vdms-hq-ui-button>
    </td>
    <td>
      <vdms-hq-ui-button color="secondary" (click)="control.markAsPristine({onlySelf})">Mark as pristine</vdms-hq-ui-button>
    </td>
    <td>
      <vdms-hq-ui-button color="secondary" (click)="control.markAsTouched({onlySelf})">Mark as touched</vdms-hq-ui-button> <br/><br/>
      <vdms-hq-ui-button color="secondary" (click)="control.markAllAsTouched()">Mark ALL as touched</vdms-hq-ui-button>
    </td>
    <td>
      <vdms-hq-ui-button color="secondary" (click)="control.markAsUntouched({onlySelf})">Mark as untouched</vdms-hq-ui-button>
    </td>
  </tr>

  <tr>
    <td [class.cell-active]="control.disabled">
      {{control.disabled ? 'Yes' : 'No'}}
    </td>
    <td [class.cell-active]="control.pending">
      {{control.pending ? 'Yes' : 'No'}}
    </td>
    <td [class.cell-active]="control.valid">
      {{control.valid ? 'Yes' : 'No'}}
    </td>
    <td [class.cell-active]="control.invalid">
      {{control.invalid ? 'Yes' : 'No'}}
    </td>
    <td [class.cell-active]="control.dirty">
      {{control.dirty ? 'Yes' : 'No'}}
    </td>
    <td [class.cell-active]="control.pristine">
      {{control.pristine ? 'Yes' : 'No'}}
    </td>
    <td [class.cell-active]="control.touched">
      {{control.touched ? 'Yes' : 'No'}}
    </td>
    <td [class.cell-active]="control.untouched">
      {{control.untouched ? 'Yes' : 'No'}}
    </td>
  </tr>
  <tr>
    <td>Value:</td>
    <td colspan="3">
      <pre>{{control.value | json}}</pre>
    </td>
    <td>Errors:</td>
    <td colspan="2"><pre>{{control.errors | json}}</pre></td>
  </tr>
</table>
