import { Injectable } from '@angular/core';
import { ConnectableDataSource, LoadableDataSource, PageableDataSource } from '@vdms-hq/shared';
import { BehaviorSubject, map, Observable, of, startWith, Subject, switchMap, tap } from 'rxjs';
import { CollectionModelFlat, CollectionsService } from '@vdms-hq/api-contract';
import { PageEvent } from '@angular/material/paginator';
import { Pagination } from '@vdms-hq/ui';

@Injectable({ providedIn: 'root' })
export class DashboardCollectionsDS
  implements LoadableDataSource, ConnectableDataSource<CollectionModelFlat>, PageableDataSource
{
  readonly #firstPage = {
    page: 0,
    perPage: 24,
  };

  filterValue = '';

  refresh$ = new Subject();
  isLoading$ = new BehaviorSubject<boolean>(false);

  pageIndex$ = new BehaviorSubject(0);
  pageSize$: BehaviorSubject<number> = new BehaviorSubject(this.#firstPage.perPage);
  pageSizeOptions: number[] = [24];
  total$ = new BehaviorSubject<number>(0);
  allData$: Observable<CollectionModelFlat[]> = this.refresh$.pipe(
    startWith(null),
    tap(() => this.isLoading$.next(true)),
    switchMap(() =>
      this.collectionsService
        .getList(
          { filter: this.filterValue },
          Pagination.create({
            page: this.pageIndex$.value,
            perPage: this.pageSize$.value,
            orderBy: 'collection.name',
          }),
        )
        .pipe(
          tap((response) => {
            this.total$.next(response.total);
          }),
          map((response) => response.data),
        ),
    ),
  );
  connection$: Observable<CollectionModelFlat[]> = this.allData$;

  constructor(public collectionsService: CollectionsService) {}

  pageChange($event: PageEvent): void {
    this.allData$ = of([]);
    this.pageIndex$.next($event.pageIndex);
    this.refresh$.next(true);
  }

  applyFilter(filterValue: string): void {
    this.allData$ = of([]);
    this.filterValue = filterValue;
    this.refresh$.next(true);
  }

  clear() {
    this.pageIndex$.next(0);
    this.filterValue = '';
  }
}
