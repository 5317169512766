<form [formGroup]="formService.form">
  <vdms-hq-ui-layout-page-wrapper [breadcrumbs]="[{ name: 'Home' }]">
    <ng-container formGroupName="home">
      <vdms-hq-ui-form-section formGroupName="hero" title="Hero" [layout]="'single'" [divider]="false">
        <div class="group-content">
          <vdms-hq-ui-form-input-text formControlName="title" label="Title"></vdms-hq-ui-form-input-text>
          <vdms-hq-ui-form-input-text formControlName="intro" label="Intro"></vdms-hq-ui-form-input-text>
        </div>
      </vdms-hq-ui-form-section>
    </ng-container>
  </vdms-hq-ui-layout-page-wrapper>

  <vdms-hq-ui-layout-page-wrapper [breadcrumbs]="[{ name: 'Asset details' }]">
    <ng-container formGroupName="details">
      <div class="group-content">
        <vdms-hq-ui-form-input-toggle
          formControlName="filtersVisible"
          label="Filter visible"
        ></vdms-hq-ui-form-input-toggle>
        <vdms-hq-ui-form-input-select
          *ngIf="formService.filterVisible"
          formControlName="filterKey"
          label="Parent filter"
          [selectOptions]="formService.filtersDefinitions$ | async"
          [isSortedByLabel]="true"
        ></vdms-hq-ui-form-input-select>
        <vdms-hq-ui-form-section layout="single" title="Global configuration">
          <vdms-hq-ui-form-input-toggle
            formControlName="downloadAllVisible"
            label="Download all visible"
          ></vdms-hq-ui-form-input-toggle>
          <vdms-hq-ui-form-input-toggle
            formControlName="selectAllVisible"
            label="Select all available"
          ></vdms-hq-ui-form-input-toggle>
        </vdms-hq-ui-form-section>
      </div>

      <mat-tab-group
        formArrayName="structure"
        class="material-custom-tab material-custom-accordion-admin"
        [selectedIndex]="selectedTab.value"
        (selectedTabChange)="selectedNewElement = []"
      >
        <ng-container
          *ngFor="
            let control of formService.structureFormArray.controls;
            let arrayIndex = index;
            trackBy: formService.trackStructureBy
          "
          [formGroupName]="arrayIndex"
        >
          <mat-tab>
            <ng-template mat-tab-label>
              {{ control.controls.label.value }}

              <ng-container *ngIf="(formService.policiesLoading$ | async) === false; else policyLoader">
                <br />
                {{
                  (
                    control.controls.restrictedToPolicies.value
                    | first
                    | findSelectOption : (formService.allPolicies$ | async)
                  )?.label ?? 'All Policies'
                }}

                <ng-container *ngIf="control.controls.restrictedToPolicies.value.length > 1">
                  + {{ control.controls.restrictedToPolicies.value.length - 1 }} more
                </ng-container>
              </ng-container>
            </ng-template>

            <ng-template #policyLoader>
              <vdms-hq-ui-loader [size]="16"></vdms-hq-ui-loader>
            </ng-template>
            <ng-container>
              <div class="group-content">
                <div class="actions">
                  <vdms-hq-ui-form-input-text formControlName="label" label="Label"></vdms-hq-ui-form-input-text>

                  <mat-icon class="icon" (click)="reorderBackward(arrayIndex); $event.preventDefault()"
                    >arrow_back
                  </mat-icon>

                  <mat-icon
                    class="icon"
                    (click)="formService.structureFormArray.removeAt(arrayIndex); $event.preventDefault()"
                    >delete
                  </mat-icon>

                  <mat-icon class="icon" (click)="reorderForward(arrayIndex); $event.preventDefault()"
                    >arrow_forward
                  </mat-icon>
                </div>
                <vdms-hq-ui-form-input-text formControlName="key" label="Key"></vdms-hq-ui-form-input-text>

                <vdms-hq-ui-form-section layout="single" title="Override global configuration">
                  <div class="group-content-horizontal">
                    <vdms-hq-ui-form-input-toggle
                      formControlName="downloadAllVisibleOverrideGlobal"
                      [label]="'Override'"
                      (valueChange)="formService.setDisabledState(control.controls.downloadAllVisible, !$event)"
                    ></vdms-hq-ui-form-input-toggle>

                    <vdms-hq-ui-form-input-toggle
                      formControlName="downloadAllVisible"
                      label="Download all visible"
                    ></vdms-hq-ui-form-input-toggle>
                  </div>

                  <div class="group-content-horizontal">
                    <vdms-hq-ui-form-input-toggle
                      formControlName="selectAllVisibleOverrideGlobal"
                      [label]="'Override'"
                      (valueChange)="formService.setDisabledState(control.controls.selectAllVisible, !$event)"
                    ></vdms-hq-ui-form-input-toggle>

                    <vdms-hq-ui-form-input-toggle
                      formControlName="selectAllVisible"
                      label="Select all available"
                    ></vdms-hq-ui-form-input-toggle>
                  </div>

                  <vdms-hq-ui-form-input-select
                    *ngIf="formService.filterVisible"
                    formControlName="filterKey"
                    label="Parent filter"
                    [selectOptions]="formService.filtersDefinitions$ | async"
                    [isSortedByLabel]="true"
                  ></vdms-hq-ui-form-input-select>
                </vdms-hq-ui-form-section>
                <vdms-hq-ui-form-section layout="single" [divider]="false" title="Restrictions">
                  <vdms-hq-ui-form-input-select
                    formControlName="restrictedToPolicies"
                    label="Restricted to policies"
                    hint="For security reasons you can select only policies without permissions for this client."
                    [selectOptions]="formService.emptyPolicies$ | async"
                    [loading]="formService.policiesLoading$ | async"
                    [multiple]="true"
                    [enableClear]="true"
                  ></vdms-hq-ui-form-input-select>

                  <mat-chip-list class="material-custom-chip-input">
                    <ng-container *ngFor="let policyId of control.controls.restrictedToPolicies.value">
                      <mat-chip
                        *ngIf="
                          policyId | findSelectOption : (formService.nonEmptyPolicies$ | async);
                          let nonEmptyPolicy
                        "
                        [selectable]="false"
                        [removable]="true"
                        (removed)="formService.detachPolicy(control, policyId)"
                        class="users-chip"
                      >
                        {{ nonEmptyPolicy.label }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                    </ng-container>
                  </mat-chip-list>
                </vdms-hq-ui-form-section>

                <vdms-hq-ui-form-section [layout]="'grid-2-columns'" [divider]="false" title="Folder structure">
                  <ng-container formArrayName="filters">
                    <ng-container
                      *ngFor="
                        let control of formService.getFiltersFormArray(arrayIndex).controls;
                        let filtersArrayIndex = index;
                        trackBy: formService.trackFiltersBy
                      "
                      [formGroupName]="filtersArrayIndex"
                    >
                      <vdms-hq-ui-form-input-select
                        [formControl]="control.controls.filterKey"
                        label="Filter"
                        [selectOptions]="formService.filtersDefinitions$ | async"
                        [isSortedByLabel]="true"
                      ></vdms-hq-ui-form-input-select>
                      <div class="filter-value-column">
                        <vdms-hq-ui-form-input-chips
                          [formControl]="control.controls.filterValue"
                          label="Filter values"
                        ></vdms-hq-ui-form-input-chips>
                        <vdms-hq-ui-button
                          icon="delete"
                          [iconOnly]="true"
                          color="warn"
                          (click)="formService.removeFilter(arrayIndex, filtersArrayIndex)"
                        ></vdms-hq-ui-button>
                      </div>
                    </ng-container>
                  </ng-container>
                </vdms-hq-ui-form-section>
                <vdms-hq-ui-button (click)="formService.addNewFilter(arrayIndex)" color="primary"
                  >New filter
                </vdms-hq-ui-button>
              </div>
            </ng-container>
            <ng-container
              [ngTemplateOutlet]="foldersStructureRef"
              [ngTemplateOutletContext]="{ index: arrayIndex, control: control }"
            ></ng-container>
          </mat-tab>
        </ng-container>
        <mat-tab [label]="'common.admin.asset_details.tab_asset_preview_add_new' | translate">
          <ng-container [ngTemplateOutlet]="newTabRef"></ng-container>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
    <ng-container formGroupName="support">
      <h3 class="headline">Support Button Settings</h3>
      <div class="form-desc">
        <p>
          When creating a label for a support button, ensure it is clear and compelling. Use phrases like "Support",
          "Contact Us".
        </p>
        <p>This makes the purpose of the button immediately obvious to users, encouraging them to take action.</p>
      </div>
      <div class="group-content-support">
        <div class="group-content-support__form">
          <vdms-hq-ui-form-input-toggle
            formControlName="enabled"
            (valueChange)="supportDisabledToggle($event)"
            label="Connect2 Support Button"
          >
          </vdms-hq-ui-form-input-toggle>
          <vdms-hq-ui-form-input-text formControlName="label" label="Label"></vdms-hq-ui-form-input-text>
          <vdms-hq-ui-form-input-text formControlName="url" label="Url"></vdms-hq-ui-form-input-text>
        </div>
      </div>
    </ng-container>
  </vdms-hq-ui-layout-page-wrapper>
</form>

<ng-template #newTabRef>
  <div class="new-tab-wrapper">
    <vdms-hq-ui-form-input-text
      [label]="'common.admin.containers.input_container_label' | translate"
      [(ngModel)]="name"
      [withFooter]="false"
    ></vdms-hq-ui-form-input-text>
    <vdms-hq-ui-button
      color="secondary"
      [disabled]="!name?.length"
      (click)="formService.addNewTab(name); goToActiveTab()"
    >
      {{ 'common.admin.containers.add_new_tab' | translate }}
    </vdms-hq-ui-button>
  </div>
</ng-template>

<ng-template #foldersStructureRef let-index="index" let-control="control">
  <vdms-hq-ui-layout-page-wrapper>
    <div class="columns" cdkDropListGroup *ngIf="control.value | enabledList | async; let enabledList">
      <div class="column">
        <h3>Folders structure ({{ enabledList.length }})</h3>
        <div
          class="list"
          cdkDropList
          [cdkDropListData]="enabledList"
          (cdkDropListDropped)="drop($event, control, enabledList)"
        >
          <div cdkDrag *ngFor="let enabledField of enabledList">
            <vdms-hq-ui-list-item-placeholder *cdkDragPlaceholder></vdms-hq-ui-list-item-placeholder>
            <vdms-hq-ui-list-item>
              {{ enabledField.label }}
            </vdms-hq-ui-list-item>
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="column" *ngIf="control.value | availableList | async; let availableList">
        <h3>Available folders ({{ availableList.length }})</h3>

        <div
          class="list"
          cdkDropList
          [cdkDropListData]="availableList"
          (cdkDropListDropped)="drop($event, control, enabledList)"
        >
          <div cdkDrag *ngFor="let availableField of availableList">
            <vdms-hq-ui-list-item-placeholder *cdkDragPlaceholder></vdms-hq-ui-list-item-placeholder>
            <vdms-hq-ui-list-item>
              {{ availableField.label }}
            </vdms-hq-ui-list-item>
          </div>
        </div>
      </div>
    </div>
  </vdms-hq-ui-layout-page-wrapper>
</ng-template>

<vdms-hq-ui-actions-footer>
  <vdms-hq-ui-button right color="primary" (click)="formService.save()" [loading]="formService.isSaving$ | async">
    {{ 'common.global.save' | translate }}
  </vdms-hq-ui-button>
</vdms-hq-ui-actions-footer>
