import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RunMediaInsightsDialogComponent } from './view-components/run-media-insights-dialog/run-media-insights-dialog.component';
import { SharedModule } from '../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MediaInsightsEffect } from './logic/effects/media-insights.effect';
import * as fromReducer from './logic/reducer/index.reducer';
import { SelectorsModule } from '@vdms-hq/selectors';
import {
  UIButtonModule,
  UIDialogWrapperModule,
  UIEmptyResultsModule,
  UIFormModule,
  UILayoutModule,
  UILoaderModule,
} from '@vdms-hq/ui';

@NgModule({
  declarations: [RunMediaInsightsDialogComponent],
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature(fromReducer.featureKey, fromReducer.reducers),
    EffectsModule.forFeature([MediaInsightsEffect]),
    SelectorsModule,
    UIButtonModule,
    UILoaderModule,
    UIDialogWrapperModule,
    UILayoutModule,
    UIEmptyResultsModule,
    UIFormModule,
  ],
  exports: [],
})
export class MediaInsightsModule {}
