import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SourceGroupProvider } from '../source-provider';
import { map, share, tap } from 'rxjs/operators';
import { RatingsService } from '@vdms-hq/api-contract';
import { Rating } from '@vdms-hq/api-contract';
import { SelectOptionGroup } from '@vdms-hq/shared';
import { SelectorSourceType } from '../selector-source.type';

@Injectable({
  providedIn: 'root',
})
export class RatingsSourceService implements SourceGroupProvider {
  #isLoadingSubject$ = new BehaviorSubject(true);
  isLoading$ = this.#isLoadingSubject$.asObservable();

  #all = this.ratingsService
    .getRatings()
    .pipe(share())
    .pipe(
      tap(() => {
        this.#isLoadingSubject$.next(false);
      }),
    );

  constructor(private ratingsService: RatingsService) {}

  supports(type: SelectorSourceType): boolean {
    return type?.includes(SelectorSourceType.ASSET_GENERAL_RATINGS) || false;
  }

  listByType(type: SelectorSourceType): Observable<SelectOptionGroup[]> {
    return this.#all.pipe(
      map((items: Rating[]) =>
        [...new Map(items.map((item) => [item.system.name, item.system])).values()].map(({ uuid, name }) => {
          return { key: uuid, label: name, options: this.#filterOptions(items, uuid) };
        }),
      ),
    );
  }

  #filterOptions = (items: Rating[], uuid: string) => {
    return items
      .filter((item: Rating) => item.system.uuid === uuid)
      .map(({ uuid, name }) => {
        return { key: uuid, label: name };
      });
  };
}
