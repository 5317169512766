import { Inject, Injectable, Optional } from '@angular/core';
import { Voice, VoiceAiApiService } from '@vdms-hq/api-contract';
import { map, take } from 'rxjs/operators';
import { SelectOption, SelectOptionKey } from '@vdms-hq/shared';
import { BehaviorSubject } from 'rxjs';
import { TRANSCRIPTION_CONFIG, TranscriptionConfig } from './config-tokens';

@Injectable({ providedIn: 'root' })
export class VoiceAiService {
  constructor(
    @Inject(TRANSCRIPTION_CONFIG) private config: TranscriptionConfig,
    private voiceAiApiService: VoiceAiApiService,
  ) {}
  selectedVoice$ = new BehaviorSubject<string | null>(null);
  voicesSelectOptions$ = this.voiceAiApiService.getVoices(this.config.voiceAIApiUrl, this.config.voiceAIApiKey).pipe(
    take(1),
    map<Voice[], SelectOption[]>((voices) =>
      voices.map((voice) => ({
        key: voice.voice_id,
        label: voice.name,
      })),
    ),
  );

  generateTextToSpeech(text: string) {
    return this.voiceAiApiService.textToSpeech(
      this.config.voiceAIApiUrl,
      this.config.voiceAIApiKey,
      this.selectedVoice$.value as string,
      {
        text,
        model_id: 'eleven_multilingual_v2',
        voice_settings: {
          stability: 0.5,
          similarity_boost: 0.75,
          style: 0,
          use_speaker_boost: true,
        },
      },
    );
  }
}
