import { BehaviorSubject, debounceTime, Observable, ReplaySubject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { PersistenceSearchParams } from './persistence-search-params';
import { Aggregations } from '../rest/api.model';

export abstract class PersistenceSearch<MODEL> {
  #currentParam?: PersistenceSearchParams;
  currentParams$ = new ReplaySubject<PersistenceSearchParams>(1);
  data$ = new ReplaySubject<MODEL[]>(1);
  total$ = new ReplaySubject<number>(1);
  aggregations$ = new ReplaySubject<Aggregations>(1);
  error$: ReplaySubject<Error | null> = new ReplaySubject<Error | null>(1);
  loading$ = new BehaviorSubject(false);
  forceRefresh$ = new BehaviorSubject<boolean>(false);
  #apiCall?: Subscription;

  protected abstract search(
    filter: PersistenceSearchParams,
  ): Observable<{ total: number; data: MODEL[]; aggs: Aggregations }>;

  applyParams = (given: PersistenceSearchParams) => {
    const isNew = !this.#currentParam || !given.equals(this.#currentParam);

    if (isNew || given.initial || this.forceRefresh$.value) {
      this.#setParam(given);
      this.#fetchData(given);
      this.forceRefresh$.value && this.forceRefresh$.next(false);
    }
  };

  refresh = () => {
    this.#currentParam && this.#fetchData(this.#currentParam);
  };

  clearNativeParams(): void {
    this.#currentParam = PersistenceSearchParams.empty();
  }

  #setParam(next: PersistenceSearchParams) {
    this.#currentParam = next;
    this.currentParams$.next(next);
  }

  #fetchData(next: PersistenceSearchParams) {
    this.loading$.next(true);
    this.error$.next(null);
    if (next.initial) {
      this.data$.next([]);
    }

    this.#apiCall?.unsubscribe();

    this.#apiCall = this.search(next)
      .pipe(take(1), debounceTime(1000))
      .subscribe({
        next: (response) => {
          this.data$.next(response.data);
          this.total$.next(response.total);
          this.aggregations$.next(response.aggs);
          this.loading$.next(false);
        },
        error: (error: Error) => {
          this.error$.next(error);
          this.loading$.next(false);
        },
      });
  }
}
