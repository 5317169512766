import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActionIdentifier,
  DataColumn,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  ResultsWrapperViewConfiguration,
  UIConfirmationDialogService,
  UILayoutModule,
  UILoaderModule,
  UIResultsWrapperModule,
} from '@vdms-hq/ui';
import { MatDialog } from '@angular/material/dialog';
import { ToastService } from '@vdms-hq/toast';
import { CredentialInterface, CredentialsApiService, CredentialTypeEnum } from '@vdms-hq/api-contract';
import { CredentialsDatasourceService } from '../../logic/credentials-datasource.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { combineLatest, take, filter, switchMap, tap } from 'rxjs';
import { CredentialsCreateEditDialogComponent } from '../../components/credentials-create-edit-dialog/credentials-create-edit-dialog.component';
import { Permission, PermissionService } from '@vdms-hq/activated-client';

@Component({
  selector: 'vdms-hq-credentials-list',
  standalone: true,
  imports: [
    CommonModule,
    UILayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    UILoaderModule,
    UIResultsWrapperModule,
    MultipleDataPresentationComponent,
  ],
  templateUrl: './credentials-list.component.html',
  providers: [CredentialsDatasourceService, CredentialsApiService, UIConfirmationDialogService],
})
export class CredentialsListComponent implements OnInit {
  headerConfig: ResultsWrapperViewConfiguration = {
    headerActions: [],
    withLoading: true,
  };

  viewConfig: MultipleViewConfiguration<CredentialInterface<CredentialTypeEnum>> = {
    multiView: {
      defaultView: 'tableAdvanced',
      fitToVisibleArea: false,
      pagination: true,
      emptyResults: {
        message: 'Could not find any entities',
      },
    },
    tableAdvanced: {
      actions: [],
      layout: {
        headers: false,
      },
      settingsAction: {},
      columns: [
        {
          id: 'uuid',
          label: 'UUID',
          valuePath: 'uuid',
        },
        {
          id: 'name',
          label: 'Name',
          valuePath: 'name',
        },
        {
          id: 'type',
          label: 'Type',
          valuePath: 'type',
        },
        {
          id: 'actions',
          type: 'actions',
        },
      ],
      columnsEnabled: ['uuid', 'name', 'type', 'actions'],
    },
  };

  enabled: string[] = ['uuid', 'name', 'type', 'actions'];
  columns: DataColumn[] = [
    {
      id: 'uuid',
      label: 'UUID',
      valuePath: 'uuid',
      sortable: false,
    },
    {
      id: 'name',
      label: 'Name',
      valuePath: 'name',
      sortable: false,
    },
    {
      id: 'type',
      label: 'Type',
      valuePath: 'type',
      sortable: false,
    },
  ];

  constructor(
    public credentialsDataSource: CredentialsDatasourceService,
    private credentialsApiService: CredentialsApiService,
    private dialog: MatDialog,
    private toast: ToastService,
    private translateService: TranslateService,
    private confirmationDialog: UIConfirmationDialogService,
    private permissionService: PermissionService,
  ) {
    combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.EDIT_CREDENTIALS]),
      this.permissionService.verifyWithOwnedPermissions$([Permission.DELETE_CREDENTIALS]),
      this.permissionService.verifyWithOwnedPermissions$([Permission.CREATE_CREDENTIALS]),
    ])
      .pipe(take(1))
      .subscribe(([canEdit, canDelete, canCreate]) => {
        if (canCreate && this.headerConfig.headerActions) {
          this.headerConfig.headerActions.push({
            key: 'new',
            name: 'common.credentials.new_credential',
            color: 'primary',
          });
        }
        if (this.viewConfig.tableAdvanced)
          this.viewConfig.tableAdvanced.actions = [
            {
              key: 'edit',
              icon: 'edit',
              onDoubleClick: false,
              label: 'common.global.edit',
              disabledIf: () => !canEdit,
            },
            {
              key: 'delete',
              icon: 'delete',
              onDoubleClick: false,
              label: 'common.global.delete',
              disabledIf: () => !canDelete,
              hiddenIf: (item?: CredentialInterface<CredentialTypeEnum>) => item?.is_used ?? false,
            },
          ];
      });
  }

  ngOnInit(): void {
    this.#refresh();
  }

  handleAction($event: { key: ActionIdentifier; item?: CredentialInterface<CredentialTypeEnum> }) {
    const { key, item } = $event;
    switch (key) {
      case 'edit':
        if (item) this.#edit(item);
        break;

      case 'delete':
        if (item) this.#delete(item);
        break;

      case 'new':
        this.#new();
        break;
    }
  }

  #new() {
    this.dialog.open(CredentialsCreateEditDialogComponent);
  }

  #edit(credential: CredentialInterface<CredentialTypeEnum>) {
    this.dialog.open(CredentialsCreateEditDialogComponent, {
      data: { uuid: credential.uuid },
    });
  }

  #delete(credential: CredentialInterface<CredentialTypeEnum>) {
    const { uuid, name } = credential;
    const title = this.translateService.instant('common.credentials.delete.delete_title');
    const message = this.translateService.instant('common.credentials.delete.delete_question') + name + '?';

    this.confirmationDialog
      .openDelete({ title, message })
      .pipe(
        filter(Boolean),
        tap(() => this.credentialsDataSource.isLoading$.next(true)),
        switchMap(() => this.credentialsApiService.delete(uuid)),
      )
      .subscribe({
        next: () => {
          this.toast.success({ id: 'deleted_credential', message: 'common.credentials.delete.success' });
          this.#refresh();
        },
        error: (err) => {
          this.credentialsDataSource.isLoading$.next(false);
          this.toast.error({ id: 'deleted_credential_failed', message: 'common.credentials.delete.failure' });
          throw err;
        },
      });
  }

  #refresh() {
    this.credentialsDataSource.refresh();
  }
}
