import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'vdms-hq-grid-title',
  templateUrl: './grid-title.component.html',
  styleUrls: ['./grid-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class GridTitleComponent {
  @Input() color: 'success' | 'default' = 'default';
}
