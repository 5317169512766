import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextStatusComponent } from './view/text-status/text-status.component';
import { ProgressStatusComponent } from './view/progress-status/progress-status.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

const components = [TextStatusComponent, ProgressStatusComponent];

@NgModule({
  declarations: components,
  exports: components,
  imports: [CommonModule, MatProgressBarModule],
})
export class UIStatusModule {}
