import { Routes } from '@angular/router';
import { UserSettingsWrapperComponent } from './user-settings-wrapper/user-settings-wrapper.component';
import { AuthOnlyGuard } from '@vdms-hq/auth';
import { UserProfileSettingsComponent } from '../components/user-profile-settings/user-profile-settings.component';
import { UserSystemSettingsComponent } from '../components/user-system-settings/user-system-settings.component';
import { NotificationCenterComponent, NotificationSettingsComponent } from '@vdms-hq/notifications';
import { Permission, RequiresPermissionsGuard } from '@vdms-hq/activated-client';

export enum USER_SETTINGS_ROUTER_BASE {
  ROOT = 'account-settings',
  PROFILE = 'profile',
  SYSTEM = 'system',
  NOTIFICATION_CENTER = 'notification-center',
  NOTIFICATION_SETTINGS = 'notification-settings',
}

export const userSettingsRoutes: Routes = [
  {
    path: '',
    component: UserSettingsWrapperComponent,
    canActivate: [AuthOnlyGuard],
    children: [
      {
        path: '',
        redirectTo: USER_SETTINGS_ROUTER_BASE.PROFILE,
        pathMatch: 'full',
      },
      {
        path: USER_SETTINGS_ROUTER_BASE.PROFILE,
        component: UserProfileSettingsComponent,
      },
      {
        path: USER_SETTINGS_ROUTER_BASE.SYSTEM,
        component: UserSystemSettingsComponent,
      },
      {
        path: USER_SETTINGS_ROUTER_BASE.NOTIFICATION_CENTER,
        component: NotificationCenterComponent,
        canActivate: [RequiresPermissionsGuard],
        data: { permissions: [Permission.BROWSE_NOTIFICATIONS] },
      },
      {
        path: USER_SETTINGS_ROUTER_BASE.NOTIFICATION_SETTINGS,
        component: NotificationSettingsComponent,
        canActivate: [RequiresPermissionsGuard],
        data: { permissions: [Permission.MANAGE_NOTIFICATION_SUBSCRIPTIONS] },
      },
      {
        path: '**',
        redirectTo: USER_SETTINGS_ROUTER_BASE.PROFILE,
        pathMatch: 'full',
      },
    ],
  },
];
