import { createAction, props } from '@ngrx/store';
import { Identifier } from '../../shared/logic/models/identifier.type';

const Prefix = '[ASSET] ';

const AssetsAction = {
  TRIGGER_DELETE_ASSETS: Prefix + 'Trigger delete assets',
  DELETE_ASSETS: Prefix + 'Delete assets',
  DELETE_ASSETS_SUCCESS: Prefix + 'Delete assets success',
  DELETE_ASSETS_FAIL: Prefix + 'Delete assets fail',
};

export const triggerDeleteAssets = createAction(
  AssetsAction.TRIGGER_DELETE_ASSETS,
  props<{ assetIds: Identifier[] }>(),
);
export const deleteAssets = createAction(
  AssetsAction.DELETE_ASSETS,
  props<{
    payload: {
      assetIds: Identifier[];
      reason: string;
      billable: boolean;
    };
  }>(),
);
export const deleteAssetsSuccess = createAction(AssetsAction.DELETE_ASSETS_SUCCESS);
export const deleteAssetsFail = createAction(AssetsAction.DELETE_ASSETS_FAIL, props<{ error: Error }>());
