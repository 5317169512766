import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemeSwitcherService } from '@vdms-hq/theming';

@Component({
  selector: 'vdms-hq-ui-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent {
  @Input() errorType = 404;
  @Input() link = {
    url: '/',
    content: 'Homepage',
  };
  @Input() linkVisible: boolean | null = true;

  constructor(readonly theme: ThemeSwitcherService) {}
}
