<vdms-hq-ui-dialog-wrapper *ngIf="!loading; else customLoader">
  <div title>
    <h1>{{ 'common.metadata_recognition.dialog.title' | translate }}</h1>
    <h3 class="subtitle">
      {{ 'common.metadata_recognition.dialog.subtitle' | translate }}
    </h3>
  </div>
  <div content [formGroup]="form">
    <table>
      <thead>
        <th>
          <vdms-hq-ui-form-input-checkbox
            [formControl]="selectAll"
            [withFooter]="false"
          ></vdms-hq-ui-form-input-checkbox>
        </th>
        <th *ngFor="let headingCol of columns">{{ headingCol | keyToTitle }}</th>
      </thead>
      <tbody formArrayName="metadata">
        <tr
          *ngFor="let row of formArray.controls; trackBy: trackByFn; let arrayIndex = index"
          [formGroupName]="arrayIndex"
        >
          <th>
            <vdms-hq-ui-form-input-checkbox
              [formControl]="$castToControl(formEntitiesArray.controls[arrayIndex])"
              [withFooter]="false"
            ></vdms-hq-ui-form-input-checkbox>
          </th>
          <th *ngFor="let cell of columns; let cellIndex = index">
            <ng-container *ngIf="row.get(columns[cellIndex])?.value; let value; else: emptyRow">
              <ng-container *ngIf="readonlyColumns.includes(cell); else selectCell">
                <span class="readonly-cell" [matTooltip]="value">{{ value }}</span>
              </ng-container>
              <ng-template #selectCell>
                <ng-container *ngIf="dataSource[row?.value?.['uuid']][cell]?.length; else emptyRow;">
                  <vdms-hq-ui-form-input-select
                    [label]="columns[cellIndex] | keyToTitle"
                    [selectOptions]="dataSource[row?.value?.['uuid']][cell]"
                    [formControlName]="columns[cellIndex]"
                    [withFooter]="false"
                  ></vdms-hq-ui-form-input-select>
                </ng-container>
              </ng-template>
            </ng-container>
            <ng-template #emptyRow> <span class="not-available-cell">N/A</span></ng-template>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
  <div footer class="footer-container">
    <div class="left">
      <vdms-hq-ui-button (click)="skip()" color="secondary">{{
        'common.metadata_recognition.buttons.skip' | translate
      }}</vdms-hq-ui-button>
    </div>
    <div class="right">
      <vdms-hq-ui-button (click)="save(true)" color="secondary">{{
        'common.metadata_recognition.buttons.accept_all' | translate
      }}</vdms-hq-ui-button>
      <vdms-hq-ui-button (click)="save()" color="primary" [disabled]="!enableSave()">{{
        'common.metadata_recognition.buttons.save_selected' | translate
      }}</vdms-hq-ui-button>
    </div>
  </div>
</vdms-hq-ui-dialog-wrapper>

<ng-template #customLoader>
  <div class="wrapper">
    <h2>Metadata recognition for uploaded files</h2>
    <p>Your files are currently undergoing analysis to extract metadata.</p>
    <vdms-hq-ui-loader></vdms-hq-ui-loader>
  </div>
</ng-template>
