import { ChangeDetectorRef, Component, forwardRef, Injector } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlValueAccessorComponent } from '@vdms-hq/ui';
import { MatDialog } from '@angular/material/dialog';
import {
  SuggestionsDialogComponent,
  SuggestionsDialogOutput,
} from '../suggestions-dialog/suggestions-dialog.component';

@Component({
  selector: 'vdms-hq-ai-summary',
  templateUrl: './ai-summary.component.html',
  styleUrls: ['./ai-summary.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AiSummaryComponent),
    },
  ],
})
export class AiSummaryComponent extends FormControlValueAccessorComponent {
  innerFormControl = new UntypedFormControl(null);
  constructor(injector: Injector, cdr: ChangeDetectorRef, private dialog: MatDialog) {
    super(injector, cdr);
  }

  openSummaryAIDialog() {
    this.dialog
      .open<SuggestionsDialogComponent, unknown, SuggestionsDialogOutput>(SuggestionsDialogComponent)
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          this.innerFormControl.setValue(response);
        }
      });
  }
}
