export enum BillingReportEndpoints {
  ROOT = 'report',
  ROOT_PLURAL = 'report/export',
}

export interface ReportType {
  type: string;
  startDate?: string;
  endDate?: string;
}
