import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { SidenavService, SidenavState, SidenavStatusEnum } from '../../../ui-sidenav';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'vdms-hq-ui-actions-footer',
  templateUrl: './actions-footer.component.html',
  styleUrls: ['./actions-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ActionsFooterComponent implements AfterViewInit, OnDestroy {
  private destroy$ = new Subject<void>();

  #menuState?: SidenavState;

  get menuState() {
    if (!this.#menuState) {
      return SidenavStatusEnum.Closed as string;
    }

    return this.#menuState as string;
  }

  constructor(private sidenavService: SidenavService, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.sidenavService.sidenavStatus$.pipe(takeUntil(this.destroy$)).subscribe((status) => {
      this.#menuState = status;
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
