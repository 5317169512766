import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { emailPatternValidator } from '@vdms-hq/shared';
import { Policy } from '@vdms-hq/api-contract';

export interface UserFormGroup {
  email: FormControl<string>;
  policies: FormControl<string[] | undefined>;
}

@Injectable({
  providedIn: 'root',
})
export class RightsPartnersCreateEditFormService {
  form = new FormGroup({
    name: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    partner_id: new FormControl<string | null>(null),
    users: new FormArray<FormGroup<UserFormGroup>>([]),
  });

  get usersValue() {
    return this.form.controls.users.value;
  }

  get usersFormArray() {
    return this.form.get('users') as FormArray<FormGroup<UserFormGroup>>;
  }

  get usersControls() {
    return this.usersFormArray.controls as FormGroup<UserFormGroup>[];
  }

  get userEmailsArray() {
    return this.form.controls.users.value.map((user) => user.email as string);
  }

  get isInvalid() {
    return this.form.invalid;
  }

  get partnerId() {
    return this.form.value.partner_id && this.form.value.partner_id !== '' ? this.form.value.partner_id : null;
  }

  get name() {
    return this.form.value.name as string;
  }

  resetForm() {
    this.form = new FormGroup({
      name: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
      partner_id: new FormControl<string | null>(null),
      users: new FormArray<FormGroup<UserFormGroup>>([]),
    });
  }

  indexByEmail(email: string) {
    const controlArray = this.form.controls.users.value;
    return controlArray.findIndex((user) => user.email === email);
  }

  newUserFormGroup(email: string, policies?: Policy[]) {
    return new FormGroup<UserFormGroup>({
      email: new FormControl<string>(email, { nonNullable: true, validators: [emailPatternValidator()] }),
      policies: new FormControl<string[] | undefined>(policies?.map((policy) => policy.uuid) ?? [], {
        nonNullable: true,
      }),
    });
  }

  insertUser(index: number, formGroup: FormGroup<UserFormGroup>) {
    this.usersFormArray.insert(index, formGroup);
  }

  removeUser(index: number) {
    this.usersFormArray.removeAt(index);
  }
}
