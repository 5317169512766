/**
 *
 * @remarks maybe adding ability to delete nested objects' properties would add more awesomeness to this already
 * awesome function
 */

export function updateNestedProperties<T>(originalObj: T & { [key: string]: any }, partialObj: { [key: string]: any }) {
  for (const key in partialObj) {
    if (Object.prototype.hasOwnProperty.call(partialObj, key)) {
      if (partialObj[key] instanceof Object && Object.prototype.hasOwnProperty.call(originalObj, key)) {
        updateNestedProperties(originalObj[key], partialObj[key]);
      } else {
        (originalObj as any)[key] = partialObj[key];
      }
    }
  }
}
