import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImportExportDialogComponent } from '../import-export-dialog/import-export-dialog.component';

@Component({
  selector: 'vdms-hq-import-export-button',
  templateUrl: './import-export-button.component.html',
  styleUrls: ['./import-export-button.component.scss'],
})
export class ImportExportButtonComponent {
  constructor(private matDialogService: MatDialog) {}

  popDialog() {
    this.matDialogService.open(ImportExportDialogComponent, {
      autoFocus: false,
    });
  }
}
