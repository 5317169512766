import { Injectable } from '@angular/core';
import { Pagination } from '@vdms-hq/ui';
import { BehaviorSubject, combineLatest, EMPTY, Observable, switchMap } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { FlatMediaPulseOrder, OrderService, OrdersType } from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import { MediaPulseOrdersForm } from './media-pulse-form';
import { SortableDataSource, SortBy, sortByKey, SortDirection } from '@vdms-hq/shared';
import { Sort } from '@angular/material/sort';

export interface FlatMediaPulseViewModel extends FlatMediaPulseOrder {
  wo_desc_sortfield: string;
}

@Injectable()
export class MediaPulseOrdersDs implements SortableDataSource {
  readonly #firstPage = {
    page: 0,
    perPage: 200,
  };
  pageSize = this.#firstPage.perPage;

  #allData: FlatMediaPulseViewModel[] = [];

  isLoading$ = new BehaviorSubject<boolean>(true);
  sortDirection$ = new BehaviorSubject<SortDirection>('');
  sortBy$ = new BehaviorSubject<SortBy>(null);

  allData$ = combineLatest([this.mediaPulseOrdersForm.values$, this.sortBy$]).pipe(
    tap(() => this.isLoading$.next(true)),
    switchMap(([filters, sortBy]) => {
      const pagination = {
        page: this.#firstPage.page,
        perPage: this.#firstPage.perPage,
      };
      return this.ordersApiService
        .getOrdersMediaPulse(
          {
            'orders-type': filters.ordersType as OrdersType,
            'tx-date-from': filters.txDate?.from?.toISOString(),
            'tx-date-to': filters.txDate?.to?.toISOString(),
            wo_no: filters.orderNo?.toString(),
            po: filters.poNo,
          },
          Pagination.create({
            ...pagination,
          }),
        )
        .pipe(
          catchError(() => {
            return this.#errorHandler();
          }),
          map(({ data }) => {
            this.#allData = data.map((order) => this.#orderToGridListInput(order));

            if (filters.title && filters.title !== '') {
              this.#allData = this.#allData.filter((order) =>
                order.wo_desc.toLowerCase()?.includes(filters.title?.toLowerCase() as string),
              );
            }
            this.total$.next(this.#allData.length);
            if (sortBy) {
              const direction = this.sortDirection$.getValue();
              this.#allData = sortByKey(sortBy, this.#allData, direction);
            }
            return this.#allData;
          }),
          tap((data) => {
            this.#allData = data;
          }),
          tap(() => this.isLoading$.next(false)),
        );
    }),
    shareReplay(1),
  );

  connection$ = this.allData$;

  total$ = new BehaviorSubject<number>(0);
  emptyResults$ = combineLatest([this.allData$, this.isLoading$]).pipe(
    map(([data, loading]) => !loading && data.length === 0),
  );

  constructor(
    private ordersApiService: OrderService,
    private toastService: ToastService,
    public mediaPulseOrdersForm: MediaPulseOrdersForm,
  ) {}

  sortChange($event: Sort): void {
    let sortField = $event.active.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
    sortField === 'wo_desc' && (sortField = 'wo_desc_sortfield');
    this.sortBy$.next(sortField);
    this.sortDirection$.next($event.direction);
  }

  #errorHandler(): Observable<never> {
    this.toastService.error({ id: 'mediaPulseOrders', message: 'common.orders.errors.media_pulse_list' });
    this.isLoading$.next(false);
    return EMPTY;
  }

  #orderToGridListInput(order: FlatMediaPulseOrder): FlatMediaPulseViewModel {
    return {
      ...order,
      wo_desc: order?.wo_desc?.trim() ?? '',
      wo_desc_sortfield: this.#toWordWithoutSpecialCharsAndSpaces(order?.wo_desc?.trim()),
    };
  }

  #toWordWithoutSpecialCharsAndSpaces(word: string | undefined) {
    if (!word) {
      return '';
    }

    return word
      .toLowerCase()
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .replace(/\s/g, '');
  }
}
