import { Routes } from '@angular/router';
import { MediaPulseOrdersComponent } from './results/pages/media-pulse-orders/media-pulse-orders.component';
import { OrderResolverService } from './details/logic/order-resolver.service';
import { ORDERS_ROUTER_BASE } from '../index';
import { OrderDetailsComponent } from './details/pages/order-details/order-details.component';

export const mediaPulseOrdersRouting: Routes = [
  {
    path: '',
    component: MediaPulseOrdersComponent,
  },
  {
    path: ':orderId',
    component: OrderDetailsComponent,
    resolve: {
      orderId: OrderResolverService,
    },
    providers: [OrderResolverService],
  },
  {
    path: '**',
    redirectTo: `/${ORDERS_ROUTER_BASE.ORDERS_MEDIA_PULSE}`,
    pathMatch: 'full',
  },
];
