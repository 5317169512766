import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { camelToSnakeCase, SharedModule } from '@vdms-hq/shared';
import { TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UIButtonModule, UILayoutModule } from '@vdms-hq/ui';
import { NotificationsDatasource } from '../../logic/notifications.datasource';
import { NotificationMessageComponent } from '../notification-message/notification-message.component';
import { NOTIFICATION_SUBSCRIPTION_ROUTES } from '../../notification-subscription.routes';

@Component({
  selector: 'vdms-hq-notifications-button-overlay',
  templateUrl: './notifications-button-overlay.component.html',
  styleUrls: ['./notifications-button-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    SharedModule,
    TranslateModule,
    UILayoutModule,
    UIButtonModule,
    NotificationMessageComponent,
  ],
  providers: [],
})
export class NotificationsButtonOverlayComponent {
  #router = inject(Router);
  datasource = inject(NotificationsDatasource);
  camelToSnakeCase = camelToSnakeCase;

  goToNotificationsCenter() {
    this.#router.navigate([NOTIFICATION_SUBSCRIPTION_ROUTES.NOTIFICATIONS_PATH]);
  }
}
