import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ActivatedClientService } from '@vdms-hq/activated-client';

@Injectable({ providedIn: 'root' })
export class ReturnButtonService {
  visible$: Observable<boolean>;
  savedRouteBeforeLeave$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  routeChangeListener$!: Observable<unknown>;

  constructor(private readonly router: Router, private readonly activatedClient: ActivatedClientService) {
    this.routeChangeListener$ = this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map(() => Boolean(!this.isBrowsePage)),
    );
  }

  get isBrowsePage(): boolean {
    return this.router.url?.split(/[\/?]/)?.filter(Boolean)[0] === 'browse';
  }

  registerListeners(): void {
    this.visible$ = combineLatest([this.routeChangeListener$, this.savedRouteBeforeLeave$]).pipe(
      map(([listener, savedRoute]) => Boolean(listener && savedRoute)),
    );
    this.activatedClient.clientDefiniteValueChanges$.subscribe(() => this.#resetLastSavedRoute());
  }

  #resetLastSavedRoute(): void {
    if (!this.savedRouteBeforeLeave$.value) {
      return;
    }
    this.savedRouteBeforeLeave$.next(null);
  }
}
