<div class="menu-wrapper">
  <vdms-hq-ui-loader
    [mode]="'over-parent'"
    [backdrop]="true"
    *ngIf="loading || navItems.length === 0"
  ></vdms-hq-ui-loader>

  <div *ngFor="let item of navItems; trackBy: trackBy" class="links">
    <vdms-hq-ui-inner-sidebar-link [item]="item" [hasChildren]="isNestedItem(item)"></vdms-hq-ui-inner-sidebar-link>

    <div class="children" *ngIf="isNestedItem(item)">
      <vdms-hq-ui-inner-sidebar-link
        [item]="item"
        [smaller]="true"
        *ngFor="let item of $composite(item).children; trackBy: trackBy"
      >
      </vdms-hq-ui-inner-sidebar-link>
    </div>
  </div>

  <div class="action-link" *ngIf="actionLink" (click)="action.emit({ key: actionLink.key })">
    {{ actionLink.label | translate }}
  </div>
</div>
