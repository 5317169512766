import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[vdmsHqCustomElement][customElementId]',
  standalone: true,
})
export class CustomElementDirective {
  @Input() customElementId!: string;
  constructor(public templateRef: TemplateRef<unknown>) {}
}
