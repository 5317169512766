<vdms-hq-ui-dialog-wrapper>
  <h1 title>{{ 'common.lists.edit' | translate }}</h1>
  <div content class="dialog-content fit-inputs metadata-wrapper">
    <form [formGroup]="collectionForm" (ngSubmit)="onSubmit()">
      <vdms-hq-ui-form-input-text
        formControlName="name"
        [label]="'common.lists.list_name' | translate"
        [placeholder]="'common.lists.list_name' | translate"
      >
      </vdms-hq-ui-form-input-text>

      <vdms-hq-ui-form-input-text-area
        formControlName="description"
        [label]="'common.lists.list_description' | translate"
      >
      </vdms-hq-ui-form-input-text-area>

      <vdms-hq-ui-form-input-text
        [label]="'common.lists.change_ownership' | translate"
        formControlName="owner"
        [hint]="
          (activatedClientService.userListHidden$ | async) ? '' : 'Provide at least 3 characters to get autocomplete'
        "
        (clickAutocompleteItem)="onSelectedUser($event)"
        [autocompleteList]="(activatedClientService.userListHidden$ | async) ? [] : (filteredOptions$ | async) ?? []"
      >
      </vdms-hq-ui-form-input-text>
    </form>

    <div class="field-wrapper">
      <span class="field-label">{{ 'common.lists.cover_photo' | translate }}</span>
      <vdms-hq-ui-thumb
        *ngIf="collectionForm.controls['coverPath'].value; let path; else: upload"
        [src]="path"
        (deleteFile)="collectionForm.controls['coverPath'].setValue(null)"
      ></vdms-hq-ui-thumb>
      <ng-template #upload>
        <vdms-hq-storage-shared-form-file
          *ngIf="!file"
          [(ngModel)]="file"
          [multiple]="false"
          (ngModelChange)="uploadState = null"
          type="image"
        >
        </vdms-hq-storage-shared-form-file>
        <ng-container *ngIf="file">
          <vdms-hq-storage-shared-selected-file
            [file]="file"
            [uploadState]="uploadState"
            (clear)="file = null"
          ></vdms-hq-storage-shared-selected-file>
        </ng-container>
      </ng-template>
    </div>
  </div>
  <div footer class="footer buttons-group">
    <vdms-hq-ui-button (click)="closeDialog()" color="secondary"
      >{{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button
      [disabled]="collectionForm.invalid || collectionForm.controls['owner'].pending"
      (click)="onSubmit()"
      color="primary"
      >{{ (this.file ? 'common.global.upload_and_save' : 'common.global.save') | translate }}
    </vdms-hq-ui-button>
  </div>
</vdms-hq-ui-dialog-wrapper>
