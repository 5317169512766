import { inject, Injectable } from '@angular/core';
import { debounceTime, tap } from 'rxjs';
import { NotificationsStreamService } from '@vdms-hq/api-contract';
import { NotificationsDatasource } from './notifications.datasource';
import { NOTIFICATION_SUBSCRIPTION_ROUTES } from '../notification-subscription.routes';

@Injectable({ providedIn: 'root' })
export class NotificationsWebsocketService {
  private apiContractService = inject(NotificationsStreamService);
  private notificationsDatasource = inject(NotificationsDatasource);

  get notificationCenterPage() {
    return window.location.href.includes(NOTIFICATION_SUBSCRIPTION_ROUTES.NOTIFICATIONS_PATH);
  }

  registerListeners() {
    return this.apiContractService.connection$.pipe(
      debounceTime(5000),
      tap(() => {
        this.notificationCenterPage && this.notificationsDatasource.notificationsRefresh$.next(new Date().toString());
        this.notificationsDatasource.unreadRefresh$.next(new Date().toString());
      }),
    );
  }
}
