<div class="checkout-summary">
  <h1>Cart Summary</h1>
  <div class="checkout-summary__totals">
    <vdms-hq-ui-loader
      [mode]="'over-parent'"
      [backdrop]="true"
      *ngIf="(dataSource.cartSummary$ | async) === null || (dataSource.isLoading$ | async)"
      [text]="'pages.cart.checkout.estimation_loading' | translate"
    ></vdms-hq-ui-loader>
    <ng-container *ngIf="dataSource.cartSummary$ | async; let summary">
      <div>
        <span>{{ dataSource.total$ | async }} {{ (dataSource.total$ | async) === 1 ? 'asset' : 'assets' }}</span>
      </div>
      <mat-divider></mat-divider>
      <div *ngIf="summary.price.restore && summary.price.restore !== '0.00'">
        <span>{{ 'pages.cart.checkout.restore' | translate }}</span>
        <vdms-hq-ui-price
          [price]="summary.price.restore"
          [currency]="summary.settings ? summary.settings.currency : GroupCurrencyISO.GBP"
          [discountPrice]="summary.discount_prices.restore"
          [discountTotal]="summary.discount_prices.total"
        ></vdms-hq-ui-price>
      </div>
      <div *ngIf="summary?.price?.processing && summary.price.processing !== '0.00'">
        <span>{{ 'pages.cart.checkout.processing' | translate }}</span>
        <vdms-hq-ui-price
          [price]="summary.price.processing"
          [currency]="summary.settings ? summary.settings.currency : GroupCurrencyISO.GBP"
          [discountPrice]="summary.discount_prices.processing"
          [discountTotal]="summary.discount_prices.total"
        ></vdms-hq-ui-price>
      </div>
      <div *ngIf="summary?.price?.transcode && summary.price.transcode !== '0.00'">
        <span>{{ 'pages.cart.checkout.transcode' | translate }}</span>
        <vdms-hq-ui-price
          [price]="summary.price.transcode"
          [currency]="summary.settings ? summary.settings.currency : GroupCurrencyISO.GBP"
          [discountPrice]="summary.discount_prices.transcode"
          [discountTotal]="summary.discount_prices.total"
        ></vdms-hq-ui-price>
      </div>
      <div *ngIf="summary?.price?.egress">
        <span>{{ 'pages.cart.checkout.egress' | translate }}</span>
        <vdms-hq-ui-price
          [price]="summary.price.egress"
          [currency]="summary.settings ? summary.settings.currency : GroupCurrencyISO.GBP"
          [discountPrice]="summary.discount_prices.egress"
          [discountTotal]="summary.discount_prices.total"
        ></vdms-hq-ui-price>
      </div>
      <div *ngIf="clientDiscountEnabled$ | async" class="department-code">
        <ng-container *ngIf="cartState.discount$ | async as discountState">
          <vdms-hq-ui-form-input-text
            *ngIf="discountState.discountStatus === DISCOUNT_STATUS.NONE"
            [ngModel]="discountState.discountCode"
            (ngModelChange)="
              cartState.discount$.next({ discountCode: $event, discountStatus: discountState.discountStatus })
            "
            [suffixConfig]="discountInputConfig"
            [withFooter]="false"
            [label]="'pages.cart.checkout.department_code' | translate"
            (clickSuffix)="actions.emit(CartActions.CHECKOUT_DISCOUNT_CHECK)"
          ></vdms-hq-ui-form-input-text>
          <ng-container
            *ngIf="discountState.discountStatus !== DISCOUNT_STATUS.NONE"
            [ngSwitch]="discountState.discountStatus"
          >
            <vdms-hq-ui-info-bar
              *ngSwitchCase="DISCOUNT_STATUS.ACTIVE"
              (action)="actions.emit(CartActions.CHECKOUT_DISCOUNT_RESET)"
              [closeWithEmitAction]="true"
              [type]="InfoBarType.SUCCESS"
              [emitMsgTooltip]="'pages.cart.checkout.discount_reset' | translate"
              [message]="
                'pages.cart.checkout.discount_applied' | translate : { code: discountState.discountCode ?? ' ' }
              "
            ></vdms-hq-ui-info-bar>
            <vdms-hq-ui-info-bar
              *ngSwitchCase="DISCOUNT_STATUS.INACTIVE"
              (action)="actions.emit(CartActions.CHECKOUT_DISCOUNT_RESET)"
              [closeWithEmitAction]="true"
              [type]="InfoBarType.WARNING"
              [emitMsgTooltip]="'pages.cart.checkout.discount_reset' | translate"
              [message]="
                'pages.cart.checkout.discount_inactive' | translate : { code: discountState.discountCode ?? ' ' }
              "
            ></vdms-hq-ui-info-bar>
            <vdms-hq-ui-info-bar
              *ngSwitchCase="DISCOUNT_STATUS.WRONG"
              (action)="actions.emit(CartActions.CHECKOUT_DISCOUNT_RESET)"
              [closeWithEmitAction]="true"
              [type]="InfoBarType.ERROR"
              [emitMsgTooltip]="'pages.cart.checkout.discount_reset' | translate"
              [message]="'pages.cart.checkout.discount_wrong' | translate : { code: discountState.discountCode ?? ' ' }"
            ></vdms-hq-ui-info-bar>
          </ng-container>
        </ng-container>
      </div>
      <div class="total-discount" *ngIf="summary.discount_prices.total">
        <span>{{ 'pages.cart.checkout.total_discount' | translate }}</span>
        <span>{{
          totalDiscount | clientCurrency : (summary.settings ? summary.settings.currency : GroupCurrencyISO.GBP)
        }}</span>
      </div>
      <div *ngIf="summary.price.total">
        <span>{{ 'pages.cart.checkout.estimated' | translate }}</span>
        <span>{{
          summary.discount_prices.total ?? summary.price.total
            | clientCurrency : (summary.settings ? summary.settings.currency : GroupCurrencyISO.GBP)
        }}</span>
      </div>
    </ng-container>
  </div>
  <vdms-hq-cart-checkout-actions
    *ngIf="(dataSource?.total$ | async) > 0"
    [config]="config"
    (actions)="actions.emit($event)"
  ></vdms-hq-cart-checkout-actions>
  <ng-container *ngIf="formService.hasValidationError$ | async">
    <vdms-hq-ui-info-bar
      [type]="InfoBarType.ERROR"
      [message]="'pages.cart.checkout.validation_error' | translate"
      [disableClose]="true"
      [clickAction]="InfoBarClickAction.EMIT"
      (action)="actions.emit(CartActions.REMOVE_FAILED_VALIDATE_DELIVERY_DESTINATIONS)"
    ></vdms-hq-ui-info-bar>
  </ng-container>
  <mat-divider></mat-divider>
  <span class="checkout-summary__disclaimer">
    <span>{{ 'pages.cart.checkout.disclaimer' | translate }}</span>
  </span>
  <vdms-hq-ui-button
    class="cancel"
    color="secondary"
    *ngIf="(cartState.checkoutStep$ | async) > 0"
    [size]="'full'"
    (click)="cancel()"
  >
    {{ 'pages.cart.checkout.cancel' | translate }}
  </vdms-hq-ui-button>
</div>
