import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class DestinationValidator {
  static isRequired(): ValidatorFn {
    const invalid: ValidationErrors = { ['deliveryDestinationRequired']: true };

    return (control: AbstractControl): ValidationErrors | null => {
      const configUuid = control.get('configUuid')?.value;
      const uuid = control.get('uuid')?.value;

      const isUuidEmpty = !uuid;
      const isConfigUuidEmpty = !configUuid;

      if (!isUuidEmpty) {
        // config required
        if (isConfigUuidEmpty) {
          return invalid;
        }
      }

      return null;
    };
  }
}
