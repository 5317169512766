import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListItemComponent } from './list-item/list-item.component';
import { MatIconModule } from '@angular/material/icon';
import { ListItemPlaceholderComponent } from './list-item-placeholder/list-item-placeholder.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [ListItemComponent, ListItemPlaceholderComponent],
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  exports: [ListItemComponent, ListItemPlaceholderComponent],
})
export class UIListModule {}
