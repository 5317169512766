import { Injectable } from '@angular/core';
import { ToastService } from '@vdms-hq/toast';

@Injectable({ providedIn: 'root' })
export class TranscriptionToasts {
  popToast = {
    TRIGGER_SUMMARY_SUCCESS: () =>
      this.toastService.success({
        id: 'summary',
        message: 'New summary triggered',
      }),
    TRIGGER_SUMMARY_ERROR: () =>
      this.toastService.error({
        id: 'summary',
        message: 'New summary failed',
      }),
    TRIGGER_TRANSCRIPTION_SUCCESS: () =>
      this.toastService.success({
        id: 'transcription',
        message: 'New transcription generator triggered',
      }),
    TRIGGER_TRANSCRIPTION_FAILED: () =>
      this.toastService.error({
        id: 'transcription',
        message: 'Transcription generator failed',
      }),
  };

  constructor(public toastService: ToastService) {}
}
