import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RuntimeErrorComponent } from '../../ui-data-presentation/components/runtime-error/runtime-error.component';
import { TranslateModule } from '@ngx-translate/core';
import { MetadataHiddenPipe } from '../../ui-data-presentation/pipes/metadata-hidden.pipe';
import { ValueRendererComponent } from '../../ui-value-renderer';
import { UIPipesModule } from '../../ui-pipes/ui-pipes.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BehaviorSubject } from 'rxjs';
import { ValueDisplayFormatConfig } from '@vdms-hq/shared';
import { DisableIfRowPipe } from '../../ui-data-presentation';

export type TileSelectableConfig<T> = {
  label: string;
  key: string;
  disabledIf?: {
    label: string;
    icon?: string;
    condition: (item: T) => boolean;
  };
  columns?: '2-columns' | '3-columns' | 'dynamic';
  metadata: {
    label?: string;
    valuePath: string;
    viewFormat?: ValueDisplayFormatConfig;
    singleLine?: boolean;
    fallback?: string;
    hiddenIf?: (item: T) => boolean;
  }[];
};

@Component({
  selector: 'vdms-hq-ui-add-dialog-selectable-tiles',
  templateUrl: './ui-add-dialog-selectable-tiles.component.html',
  styleUrls: ['./ui-add-dialog-selectable-tiles.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RuntimeErrorComponent,
    UIPipesModule,
    TranslateModule,
    MetadataHiddenPipe,
    ValueRendererComponent,
    MatTooltipModule,
    DisableIfRowPipe,
  ],
})
export class UiAddDialogSelectableTilesComponent<T> {
  @Input() config!: TileSelectableConfig<T>;
  @Input() items!: T[];
  @Input() selected: string[] = [];
  @Input() loading$ = new BehaviorSubject<boolean>(false);
  @Output() selectedChange = new EventEmitter<T>();
}
