import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormSortingOption } from '../../../../index';
import { FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { FormatBitratePipe, FormatBytesPipe, SelectOption } from '@vdms-hq/shared';
import { Framerate, Timecode } from '@vdms-hq/timecode';

@Component({
  selector: 'vdms-hq-ui-filters-usage',
  templateUrl: './filters-usage.component.html',
  styleUrls: ['./filters-usage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersUsageComponent {
  customErrorMessages = {
    required: 'This value is mandatory',
    alreadyExist: 'Key already exist (async validator)',
  };
  framerate60fps = Framerate.fromValue(60);
  framerate25fps = Framerate.fromValue(25);
  framerate30fps = Framerate.fromValue(30);
  maxDurationForPair = Timecode.fromSeconds(40);

  form = new FormGroup({
    checkboxList: new FormControl(),
    text: new FormControl(),
    bitrateRange: new UntypedFormControl(),
    bytesRange: new UntypedFormControl({
      from: 1024,
      to: 40000,
    }),
    timecodeRange: new UntypedFormControl({
      from: Timecode.fromTimecode('00:00:00:00', this.framerate30fps),
      to: Timecode.fromTimecode('00:01:00:00', this.framerate30fps),
    }),
  });

  get checkboxList() {
    return this.form.get('checkboxList') as UntypedFormControl;
  }

  get text() {
    return this.form.get('text') as UntypedFormControl;
  }

  get bitrateRange() {
    return this.form.get('bitrateRange') as UntypedFormControl;
  }

  get timecodeRange() {
    return this.form.get('timecodeRange') as UntypedFormControl;
  }

  get bytesRange() {
    return this.form.get('bytesRange') as UntypedFormControl;
  }

  filteringSortingOptions: FormSortingOption[] = [
    {
      direction: 'asc',
      key: 'label',
      label: 'Alphabetical order (asc)',
    },
    {
      direction: 'desc',
      key: 'label',
      label: 'Alphabetical order (desc)',
    },
  ];

  selectOptions: SelectOption[] = [
    { key: 'example1', label: 'Example value #1' },
    { key: 'example2', label: 'Example value #2' },
    { key: 'example3', label: 'Example value #3' },
    { key: 'example4', label: 'Example value #4' },
    { key: 'example5', label: 'Example value #5' },
    { key: 'example6', label: 'Example value #6' },
  ];
  selectOptionsLong: SelectOption[] = [
    { key: 'example0', label: 'Example value #0' },
    { key: 'example1', label: 'Example value #1' },
    { key: 'example2', label: 'Example value #2' },
    { key: 'example3', label: 'Example value #3' },
    { key: 'example4', label: 'Example value #4' },
    { key: 'example5', label: 'Example value #5' },
    { key: 'example6', label: 'Example value #6' },
    { key: 'example7', label: 'Example value #7' },
    { key: 'example8', label: 'Example value #8' },
    { key: 'example9', label: 'Example value #9' },
    { key: 'example10', label: 'Example value #10' },
    { key: 'example11', label: 'Example value #11' },
    { key: 'example12', label: 'Example value #12' },
    { key: 'example13', label: 'Example value #13' },
    { key: 'example14', label: 'Example value #14' },
    { key: 'example15', label: 'Example value #15' },
    { key: 'example16', label: 'Example value #16' },
    { key: 'example17', label: 'Example value #17' },
    { key: 'example18', label: 'Example value #18' },
    { key: 'example19', label: 'Example value #19' },
    { key: 'example20', label: 'Example value #20' },
    { key: 'example21', label: 'Example value #21' },
    { key: 'example22', label: 'Example value #22' },
    { key: 'example23', label: 'Example value #23' },
    { key: 'example24', label: 'Example value #24' },
    { key: 'example25', label: 'Example value #25' },
    { key: 'example26', label: 'Example value #26' },
  ];

  alwaysEnabled = ['example5', 'example1'];

  readonly simpleSelectOptions = ['example1', 'example2', 'example3', 'example4', 'example5'];
  readonly emailOptions = [
    { key: 1, label: 'example1@mail.com' },
    { key: 2, label: 'example2@mail.com' },
    {
      key: 3,
      label: 'example3@mail.com',
    },
  ];

  withFooter = true;
  hint = 'Example hint';
  placeholder = 'Example placeholder';
  label = 'Example label';

  filesizeRangeMinMax = {
    min: 0,
    max: 6000000,
  };

  bitrateRangeMinMax = {
    min: 0,
    max: 130000000,
  };

  timecodeRangeMinMax = {
    min: Timecode.fromSeconds(120),
    max: Timecode.fromSeconds(1000),
  };
  facetsBitrate: SelectOption[] = [
    {
      key: { from: 0, to: 13000000 },
      label: `N/A - ${FormatBitratePipe.transformWithUnit(13000000)}`,
      suffix: '50 results',
    },
    {
      key: { from: 13000000, to: 25000000 },
      label: `${FormatBitratePipe.transformWithUnit(13000000)} - ${FormatBitratePipe.transformWithUnit(25000000)}`,
      suffix: '72 results',
    },
    {
      key: { from: 25000000, to: 50000000 },
      label: `${FormatBitratePipe.transformWithUnit(25000000)} - ${FormatBitratePipe.transformWithUnit(50000000)}`,
      suffix: '2 results',
    },
    {
      key: { from: 50000000, to: 100000000 },
      label: `${FormatBitratePipe.transformWithUnit(50000000)} - ${FormatBitratePipe.transformWithUnit(100000000)}`,
      suffix: '19 results',
    },
    {
      key: { from: 100000000, to: 130000000 },
      label: `${FormatBitratePipe.transformWithUnit(100000000, 'kbps')} - N/A`,
      suffix: '5 results',
    },
  ];

  facetsTimecode: SelectOption[] = [
    {
      key: { to: Timecode.fromSeconds(120) },
      label: `N/A - ${Timecode.fromSeconds(120)?.toString()}`,
      suffix: '10 results',
    },
    {
      key: { from: Timecode.fromSeconds(120), to: Timecode.fromSeconds(200000) },
      label: `${Timecode.fromSeconds(120)?.toString()} - ${Timecode.fromSeconds(450)?.toString()}`,
      suffix: '20 results',
    },
    {
      key: { from: 200000 },
      label: `${Timecode.fromSeconds(450)?.toString()} - N/A`,
      suffix: '30 results',
    },
  ];

  facetsFileSize: SelectOption[] = [
    {
      key: { to: 10000 },
      label: `N/A - ${FormatBytesPipe.transformWithUnit(10000)}`,
      suffix: '12 results',
    },
    {
      key: { from: 10000, to: 1200000 },
      label: `${FormatBytesPipe.transformWithUnit(10000)} - ${FormatBytesPipe.transformWithUnit(1200000)}`,
      suffix: '54 results',
    },
    {
      key: { from: 1200000, to: 4200000 },
      label: `${FormatBytesPipe.transformWithUnit(1200000)} - ${FormatBytesPipe.transformWithUnit(4200000)}`,
      suffix: '42 result',
    },
    {
      key: { from: 4200000 },
      label: `${FormatBytesPipe.transformWithUnit(4200000)} - N/A`,
      suffix: '1 result',
    },
  ];
}
