<ng-container *ngIf="(viewModel$ | async) as clientOptions">
  <vdms-hq-ui-form-input-select
    class="client-switcher"
    *ngIf="clientOptions.clientsSelectOptions.length > 1"
    [selectOptions]="clientOptions.clientsSelectOptions"
    [ngModel]="clientOptions.selectedClientId"
    (ngModelChange)="clientChanged.emit($event)"
    [label]="'Client name'"
    [withFooter]="false"
    data-testid="ClientSwitchView:form-select"
  ></vdms-hq-ui-form-input-select>
</ng-container>
