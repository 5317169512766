import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormSectionComponent, UIButtonModule, UIDialogWrapperModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DestinationApiService, DestinationStatus } from '@vdms-hq/api-contract';
import { BehaviorSubject, catchError, throwError, withLatestFrom } from 'rxjs';
import { ToastService } from '@vdms-hq/toast';
import { v4 as uuidv4 } from 'uuid';
import { Router } from '@angular/router';
import { DELIVERY_DESTINATIONS_ROUTING } from '../../delivery-destinations-routing';
import { Permission } from '@vdms-hq/firebase-contract';
import { emailsArrayValidator, enumToSelectOptions } from '@vdms-hq/shared';
import { PermissionService } from '@vdms-hq/activated-client';

@Component({
  selector: 'vdms-hq-destination-add-dialog',
  templateUrl: './destination-add-dialog.component.html',
  imports: [
    CommonModule,
    MatDialogModule,
    UIDialogWrapperModule,
    FormSectionComponent,
    UIFormModule,
    UIButtonModule,
    TranslateModule,
    UILayoutModule,
  ],
  standalone: true,
})
export class DestinationAddDialogComponent {
  #popToast = {
    CREATE_SUCCESS: () =>
      this.toastService.success({
        id: 'create_field_success',
        message: 'common.delivery_destinations.create.success',
      }),
    CREATE_FAILURE: (reason: string = 'Not specified') =>
      this.toastService.error({
        id: 'create_field_failure',
        message: 'common.delivery_destinations.create.failure',
        interpolatedParams: { reason },
      }),
  };

  loading$ = new BehaviorSubject(false);
  statusOptions = enumToSelectOptions(DestinationStatus, 'common.delivery_destinations.status.', true);

  constructor(
    public dialogRef: MatDialogRef<DestinationAddDialogComponent>,
    private deliverService: DestinationApiService,
    private toastService: ToastService,
    private router: Router,
    private permissionService: PermissionService,
  ) {}

  form = new FormGroup({
    name: new FormControl<string>('', {
      validators: Validators.required,
      nonNullable: true,
    }),
    method: new FormControl<string>({ disabled: true, value: 'vida' }),
    type: new FormControl<string>({ disabled: true, value: 'file' }),
    status: new FormControl<DestinationStatus>(
      { disabled: true, value: DestinationStatus.DRAFT },
      {
        validators: Validators.required,
        nonNullable: true,
      },
    ),
    emailDelivery: new FormControl<string[]>([], {
      validators: [Validators.required, emailsArrayValidator(true)],
      nonNullable: true,
    }),
    deliveryEmails: new FormControl<string[]>([], {
      validators: [emailsArrayValidator(true)],
      nonNullable: true,
    }),
    defaultConfig: new FormControl<string>('Config 1', {
      validators: Validators.required,
      nonNullable: true,
    }),
  });

  abort() {
    this.dialogRef.close();
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const uuid = uuidv4();
    const uuidConfig = uuidv4();

    this.loading$.next(true);

    this.deliverService
      .create({
        uuid,
        status: DestinationStatus.DRAFT,
        name: this.form.controls.name.value,
        type: 'file',
        method: 'vida',
        email: {
          notification: this.form.controls.deliveryEmails.value,
          delivery: this.form.controls.emailDelivery.value,
        },
        configs: [
          {
            uuid: uuidConfig,
            name: this.form.controls.defaultConfig.value,
            rules: [],
          },
        ],
      })
      .pipe(
        withLatestFrom(this.permissionService.verifyWithOwnedPermissions$([Permission.EDIT_DELIVERY_DESTINATIONS])),
        catchError((err) => {
          this.#popToast.CREATE_FAILURE();

          this.loading$.next(false);
          return throwError(err);
        }),
      )
      .subscribe(([_, canEditPermission]) => {
        this.loading$.next(true);
        this.#popToast.CREATE_SUCCESS();
        canEditPermission && this.router.navigate(['/', DELIVERY_DESTINATIONS_ROUTING.ROOT, uuid]);
        this.dialogRef.close();
      });
  }
}
