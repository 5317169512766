import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, of } from 'rxjs';
import { Timecode } from '@vdms-hq/timecode';

import { OmakasePlayer } from '@vdms-hq/omakase-player';
import { ADVANCED_PLAYER_REQUIRED_COOKIES, PLAYBACK_RATES, PLAYER_STYLES } from './advanced-player';
import { MediaFetcherService } from '@vdms-hq/api-contract';
import { PlayerInterface, Subtitles } from './player.interface';
import { SharedPlayerService } from './shared-player.service';
import { map, take } from 'rxjs/operators';
import { TimecodesService } from './timecodes.service';
import { Events, Level, LoadPolicy } from 'hls.js';
import { SelectOption } from '@vdms-hq/shared';
import { ToQualityLevelLabel } from './player.helpers';

export type LoadedSubtitle = Subtitles & { default: boolean; blobDataSrc: string };

@Injectable({
  providedIn: 'root',
})
/**
 * @internal for external usage use player.service.ts
 */
export class AdvancedPlayerService extends SharedPlayerService implements PlayerInterface {
  private readonly VIDEO_DOM_ID = 'advanced-player-window';
  player?: OmakasePlayer;
  #videoOnly = false;
  #latestVolume?: number;
  onPlayPause$ = new BehaviorSubject<'playing' | 'paused'>('paused');
  audioTracks$ = new BehaviorSubject<Array<{ id: number; name: string }>>([]);
  currentAudioTrack$ = new BehaviorSubject<number>(0);
  currentSubtitle$ = new BehaviorSubject<Subtitles | null>(null);
  currentQuality$ = new BehaviorSubject<number>(-1);
  loadedSubtitles$ = new BehaviorSubject<LoadedSubtitle[]>([]);
  qualityOptions$ = new BehaviorSubject<SelectOption[]>([]);

  constructor(private mediaFetcher: MediaFetcherService, private timecodes: TimecodesService) {
    super();
  }

  load = () => {
    if (!this.config) {
      this.setError('Config not passed to advanced-player.service.ts');
      throw new Error('Player must by configured first');
    }
    this.stateSubject$.next({ state: 'loading' });

    try {
      const hls = <
        {
          debug: boolean;
          fragLoadPolicy: LoadPolicy;
          autoLevelEnabled: boolean;
          startLevel: number;
          xhrSetup?: (xhr: {
            withCredentials: boolean;
            setRequestHeader: (arg0: string, arg1: string) => void;
          }) => void;
        }
      >{
        debug: false,
        fragLoadPolicy: {
          default: {
            maxTimeToFirstByteMs: 30000,
            maxLoadTimeMs: 60000,
          },
        },
        startLevel: -1,
        autoLevelEnabled: true,
        xhrSetup: this.config.withCredentials
          ? (xhr) => {
              xhr.withCredentials = true;
              ADVANCED_PLAYER_REQUIRED_COOKIES.forEach((header) => xhr.setRequestHeader(header.name, header.value)); // do send cookie
            }
          : undefined,
      };

      const videoPlayer = new OmakasePlayer({
        playerHTMLElementId: this.VIDEO_DOM_ID,
        style: PLAYER_STYLES,
        crossorigin: this.config.withCredentials ? 'use-credentials' : 'anonymous',
        hls,
      });

      videoPlayer?.loadVideo(this.config.file.url || '', this.framerateSubject.value.value).subscribe(() => {
        this.player = videoPlayer;

        this.#initSubtitles();
        this.#extractAudioTracks();
        this.#setInitialAudioTrack();
        this.#setVideoQualities();
        this.duration$.next(Timecode.fromSeconds(videoPlayer.video.getDuration(), this.config?.framerate));
        this.stateSubject$.next({ state: 'ready' });
      });
      videoPlayer.on(videoPlayer.EVENTS.OMAKASE_VIDEO_PAUSE, () => {
        this.onPlayPause$.next('paused');
      });

      videoPlayer.on(videoPlayer.EVENTS.OMAKASE_VIDEO_PLAY, () => {
        this.onPlayPause$.next('playing');
      });

      videoPlayer.video.onVideoTimeChange$.subscribe((event) => {
        this.currentTimecode$.next(Timecode.fromSeconds(event.currentTime, this.framerateSubject.value));
      });

      videoPlayer.video.onVideoError$.subscribe((error) => {
        this.setError(error?.message ?? 'Unable to load video');
        videoPlayer.destroy();
      });
    } catch (e: unknown | Error) {
      if (!(e instanceof Error)) {
        this.setError('Unable to initialize player');
        return;
      }

      switch (true) {
        case e.message?.includes('Cannot read properties of null'):
          this.setError('Unable to initialize player, did you forget to initialize PlayerComponent BEFORE load()?');
          return;
      }

      this.setError('Unable to initialize player');
    }
  };

  async loadSubtitles(subtitles: Subtitles[]): Promise<void> {
    for (const subtitle of subtitles) {
      await this.#loadSubtitle(subtitle);
    }
  }

  unload = () => {
    this.unloadShared();
    this.timecodes.reset();
    this.currentQuality$.next(-1);
    this.loadedSubtitles$.next([]);
    this.audioTracks$.next([]);
  };

  protected changeAudioTrack(audioTrackId: number): void {
    this.player?.video?.setAudioTrack(audioTrackId);
    this.currentAudioTrack$.next(audioTrackId);
  }

  protected changeQualityLevel(level: number) {
    if (!this.player) {
      return;
    }

    this.player.video.getHls().currentLevel = level;
    this.currentQuality$.next(level);
  }

  async #initSubtitles() {
    if (!this.config?.subtitles) {
      return;
    }

    let i = 0;
    for (const subtitle of this.config.subtitles) {
      await this.#loadSubtitle(subtitle, i === 0);
      i = ++i;
    }
  }

  async #loadSubtitle(subtitle: Subtitles, isDefault = false) {
    const alreadyLoaded = this.loadedSubtitles$.value.find((loadedSubtitle) => loadedSubtitle.path === subtitle.path);
    if (alreadyLoaded) {
      return;
    }

    const blobData = await firstValueFrom(this.mediaFetcher.getMedia(subtitle.path));
    if (!blobData) {
      return of(false);
    }

    const blobDataSrc = URL.createObjectURL(blobData);
    if (!this.player) {
      return of(false);
    }

    const result = this.player.subtitles
      .createVttTrack({
        id: subtitle.path,
        src: blobDataSrc,
        label: subtitle.language,
        language: subtitle.language,
        default: isDefault,
        kind: '',
      })
      .toPromise();

    if (!result) {
      return;
    }

    this.loadedSubtitles$.next([
      ...this.loadedSubtitles$.value,
      { ...subtitle, default: isDefault, blobDataSrc: blobDataSrc },
    ]);

    if (isDefault) {
      this.changeSubtitles(subtitle);
    }

    return;
  }

  protected changeSubtitles(givenSubtitle?: Subtitles): void {
    if (!this.player) {
      return;
    }

    if (!givenSubtitle) {
      this.currentSubtitle$.next(null);
      this.player?.subtitles?.hideTrack();
      return;
    }

    const subtitles = this.loadedSubtitles$.value;
    const nextIndex = subtitles.findIndex((sub) => sub.path === givenSubtitle?.path);

    if (nextIndex === -1) {
      return;
    }
    this.currentSubtitle$.next(givenSubtitle);
    this.player?.subtitles?.showTrack(givenSubtitle.path);
  }

  protected increasePlaybackRate() {
    const currentPlaybackValue = this.currentPlaybackRate$.value;

    const index = PLAYBACK_RATES.indexOf(currentPlaybackValue) || 0;

    const nextIndex = index === PLAYBACK_RATES.length ? PLAYBACK_RATES[0] : PLAYBACK_RATES[index + 1];

    this.player?.video?.setPlaybackRate(nextIndex);
    this.currentPlaybackRate$.next(nextIndex);
  }

  protected decreasePlaybackRate() {
    const currentPlaybackValue = this.currentPlaybackRate$.value;

    const index = PLAYBACK_RATES.indexOf(currentPlaybackValue) || 0;

    const nextIndex = index === 0 ? PLAYBACK_RATES[PLAYBACK_RATES.length - 1] : PLAYBACK_RATES[index - 1];

    this.player?.video?.setPlaybackRate(nextIndex);
    this.currentPlaybackRate$.next(nextIndex);
  }

  protected resetPlaybackRate() {
    this.player?.video?.setPlaybackRate(1);
    this.currentPlaybackRate$.next(1);
  }

  protected pause(): void {
    this.player?.video.pause();
  }

  protected play(): void {
    this.player?.video.play();
  }

  protected seekFrames(frames: number): void {
    const video = this.player?.video;
    video?.seekFromCurrentFrame(frames)?.subscribe();
  }

  protected seekSeconds(value: number): void {
    this.seekFrames(Number((value as number) * (this.config?.framerate?.value ?? 0)));
  }

  protected toggleFullScreen(): void {
    this.player?.video?.toggleFullscreen();
  }

  protected toggleMute(): void {
    if (this.#videoOnly || !this.player) {
      // todo 5014 handle in audio-component
      return;
    }

    const current = this.player.video.getVolume();

    if (current && current > 0) {
      this.mute();
    } else {
      this.unmute();
    }
  }

  protected mute() {
    if (!this.player) {
      return;
    }

    this.#latestVolume = this.player.video.getVolume();
    this.player.video.setVolume(0);
  }

  protected unmute() {
    if (this.#videoOnly || !this.player) {
      return;
    }

    this.player.video.setVolume(this.#latestVolume ?? 1);
  }

  protected togglePlayPause(): void {
    this.player?.video?.togglePlayPause();
  }

  protected updateVolumeUp(change: number): void {
    if (!this.player) {
      return;
    }
    const current = this.player.video.getVolume();
    const next = Math.max(0, Math.min(1, current + change));

    this.player.video.setVolume(next);
  }

  protected updateVolume(change: number): void {
    if (!this.player) {
      return;
    }

    this.player.video.setVolume(change);
  }

  #extractAudioTracks() {
    if (!this.player) {
      return;
    }
    const audio = this.player?.video;
    const audioTracks = audio?.getAudioTracks()?.map(({ id, name }) => ({ id, name })) || [];
    this.audioTracks$.next(audioTracks);
  }

  goToTime(number: number) {
    const video = this.player?.video;
    video?.pause();

    video?.seekToTimestamp(number)?.subscribe();
  }

  protected inToPlayhead(): void {
    const currentTime = this.player?.video.getCurrentTime();
    currentTime && this.timecodes.setTcIn(Timecode.fromSeconds(currentTime) as Timecode);
  }

  protected outToPlayhead(): void {
    const currentTime = this.player?.video.getCurrentTime();
    currentTime && this.timecodes.setTcIn(Timecode.fromSeconds(currentTime) as Timecode);
  }

  protected playToIn(): void {
    this.timecodes.timecodes$
      .pipe(
        take(1),
        map(([tcIn]) => tcIn?.countSeconds()),
      )
      .subscribe((tcIn) => tcIn && this.goToTime(tcIn));
  }

  protected playToOut(): void {
    this.timecodes.timecodes$
      .pipe(
        take(1),
        map(([, tcOut]) => tcOut?.countSeconds()),
      )
      .subscribe((tcOut) => tcOut && this.goToTime(tcOut));
  }

  setVideoOnlyMode(videoOnly: boolean) {
    this.#videoOnly = videoOnly;
    if (videoOnly) {
      this.mute();
    } else {
      this.unmute();
    }
  }

  #setInitialAudioTrack() {
    const preferredAudioIndex = this.config?.preferredAudioIndex;

    if (typeof preferredAudioIndex !== 'number') {
      return;
    }

    const selectedAudioTrack = this.player?.video.getAudioTracks()[preferredAudioIndex];

    if (selectedAudioTrack) {
      this.changeAudioTrack(selectedAudioTrack.id);
    }
  }

  #setVideoQualities() {
    const options = ((this.player?.video?.getHls().levels as unknown as Level[]) ?? []).map(({ url, height }, key) => {
      const label = ToQualityLevelLabel(url[0], height) + 'p';
      return {
        key,
        label,
      };
    });
    this.qualityOptions$.next(options);
  }
}
