import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiContractModule, AssetSearchService } from '@vdms-hq/api-contract';
import { AsperaUploadService } from './logic/aspera/aspera-upload.service';
import { MatButtonModule } from '@angular/material/button';
import { ObjectPipesModule } from 'ng-pipes';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule, UILoaderModule, UIStatusModule } from '@vdms-hq/ui';
import { TransferableBatchComponent } from './view/transferable-batch/transferable-batch.component';
import { TranslateModule } from '@ngx-translate/core';
import { UploadContainerComponent } from './view/upload-container/upload-container.component';
import { AssetDownloadService } from './logic/services/asset-download.service';
import { EmbargoSelectorDialogComponent } from './view/embargo-selector-dialog/embargo-selector-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectorsModule } from '@vdms-hq/selectors';

@NgModule({
  declarations: [UploadContainerComponent, EmbargoSelectorDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    ObjectPipesModule,
    ApiContractModule,
    UILoaderModule,
    UIButtonModule,
    TransferableBatchComponent,
    UIStatusModule,
    TranslateModule,
    UIDialogWrapperModule,
    ReactiveFormsModule,
    UIFormModule,
    SelectorsModule,
  ],
  providers: [AsperaUploadService, AssetDownloadService, AssetSearchService],
  exports: [UploadContainerComponent],
})
export class StorageAssetsModule {}
