import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DialogResponse, pairWithDefinition, SelectOption, SelectOptionKey } from '@vdms-hq/shared';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { ColumnSettingsScope } from '@vdms-hq/firebase-contract';
import { FieldConfigId } from '@vdms-hq/shared';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { ColumnsConfigService } from '../../logic/columns-config.service';
import { ColumnsFetcherService } from '@vdms-hq/asset-results';
import {
  availableOrdersColumns,
  defaultOrdersColumns,
  defaultSalesForceOrdersColumns,
} from '../../../details/logic/view-configurations';
import { ActivatedClientService } from '@vdms-hq/activated-client';

@Component({
  selector: 'vdms-hq-columns-config-dialog',
  templateUrl: './columns-config-dialog.component.html',
  styleUrls: ['./columns-config-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, UIFormModule, UIDialogWrapperModule, UIButtonModule],
})
export class ColumnsConfigDialogComponent implements OnInit, OnDestroy {
  formGroup = new UntypedFormGroup({
    listSortableCheckboxValue: new UntypedFormControl([], Validators.required),
  });
  availableListKey: SelectOptionKey[] = [];
  defaultListKey: SelectOptionKey[] = [];
  availableList: SelectOption[] = [];
  alwaysEnabledList = [];
  #destroyed = new Subject<void>();

  constructor(
    private columnsConfigService: ColumnsConfigService,
    private columnsFetcherService: ColumnsFetcherService,
    private columnsConfigDialogRef: MatDialogRef<ColumnsConfigDialogComponent>,
    private activatedClient: ActivatedClientService,
    @Inject(MAT_DIALOG_DATA) public data: { scopeName: ColumnSettingsScope; hiddenColumns: string[] },
  ) {}

  get listSortableCheckboxValues(): AbstractControl | null {
    return this.formGroup.get('listSortableCheckboxValue');
  }

  ngOnInit() {
    this.#initialize();
  }

  ngOnDestroy(): void {
    this.#destroyed.next();
    this.#destroyed.complete();
  }

  #initialize = () => {
    combineLatest([
      this.columnsConfigService.columnDefinitionsForOrderSettings$,
      this.columnsFetcherService.userConfig$(this.data.scopeName),
      this.activatedClient.clientDefinite$,
    ])
      .pipe(take(1))
      .subscribe(([all, userConfig, client]) => {
        const defaultColumns = client.integrations.salesforce ? defaultSalesForceOrdersColumns : defaultOrdersColumns;

        this.defaultListKey = defaultColumns ?? [];
        const available = availableOrdersColumns ?? [];
        userConfig = userConfig.filter((column) => {
          return !this.data.hiddenColumns.includes(column);
        });
        all = all.filter((column) => {
          return !this.data.hiddenColumns.some((hiddenCol) => {
            return column.key == hiddenCol;
          });
        });

        this.availableListKey = [
          ...userConfig,
          ...available.filter((item: FieldConfigId) => !userConfig.includes(item)),
        ];

        this.availableList = pairWithDefinition(
          this.availableListKey,
          all.filter((item) => available.includes(item.key as string)),
        );
        this.listSortableCheckboxValues?.patchValue(userConfig.length > 0 ? userConfig : defaultColumns);
      });
  };

  cancel(): void {
    this.columnsConfigDialogRef.close({ status: DialogResponse.ABORT });
  }

  onSubmit() {
    this.columnsConfigDialogRef.close({
      status: DialogResponse.OK,
      columns: this.listSortableCheckboxValues?.value,
    });
  }

  selectAll() {
    this.listSortableCheckboxValues?.patchValue(this.availableListKey);
  }

  deselectAll() {
    this.listSortableCheckboxValues?.patchValue([]);
  }

  revertDefaults() {
    this.listSortableCheckboxValues?.patchValue(this.defaultListKey);
  }
}
