<div class="multi-data-container">
  <vdms-hq-ui-data-presentation-header
    [actions]="headerActions$ | async"
    (action)="handleAction($event)"
    [title]="'pages.home.menu.clients'"
  >
  </vdms-hq-ui-data-presentation-header>

  <vdms-hq-ui-multiple-data-presentation
    *ngIf="viewConfiguration$ | async; let config"
    [configuration]="config"
    [dataSource]="clientsDataSource"
    (action)="handleAction($event)"
  >
    <vdms-hq-dynamic-form-group-renderer
      filters
      [form]="filters"
      [fieldsConfig]="filtersConfig"
      [hasLoader]="true"
    ></vdms-hq-dynamic-form-group-renderer>
  </vdms-hq-ui-multiple-data-presentation>
</div>
