<mat-label class="material-custom-field-label">
  {{ 'common.asset.audio_table' | translate }}
  <span class="required-indicator" *ngIf="isRequired">*</span>
</mat-label>

<ng-template #templatePortalContent let-column="column">
  <ng-container *ngIf="!readonly" [formGroup]="modifyForm">
    <ng-container [ngSwitch]="column.id">
      <vdms-hq-selector
        *ngSwitchCase="'layout'"
        [formControlName]="column.id"
        [sourceType]="selectorType.LAYOUT"
        [label]="column.label"
        [multiple]="false"
        class="material-custom-form"
      ></vdms-hq-selector>
      <vdms-hq-ui-form-input-select
        *ngSwitchCase="'channels'"
        [formControlName]="column.id"
        [label]="column.label"
        [selectOptions]="(channelsSelectOptions$ | async) ?? []"
        [multiple]="false"
        class="material-custom-form"
      ></vdms-hq-ui-form-input-select>
      <vdms-hq-selector
        *ngSwitchCase="'language'"
        [formControlName]="column.id"
        [sourceType]="selectorType.LANGUAGE"
        [label]="column.label"
        [multiple]="false"
        class="material-custom-form"
      ></vdms-hq-selector>
      <vdms-hq-selector
        *ngSwitchCase="'class'"
        [formControlName]="column.id"
        [sourceType]="selectorType.CONTENT_CLASS"
        [label]="column.label"
        [multiple]="false"
        class="material-custom-form"
      ></vdms-hq-selector>
      <vdms-hq-selector
        *ngSwitchCase="'loudness'"
        [formControlName]="column.id"
        [sourceType]="selectorType.LOUDNESS_STANDARD"
        [label]="column.label"
        [multiple]="false"
        class="material-custom-form"
      ></vdms-hq-selector>
      <vdms-hq-selector
        *ngSwitchCase="'qc_status'"
        [formControlName]="column.id"
        [sourceType]="selectorType.QC_STATUS"
        [label]="column.label"
        [multiple]="false"
        class="material-custom-form"
      ></vdms-hq-selector>
    </ng-container>
  </ng-container>
</ng-template>

<vdms-hq-ui-multiple-data-presentation
  #uiTable
  [dataSource]="audioTrackDataSource"
  [configuration]="configuration"
  (action)="handleAction($event)"
>
</vdms-hq-ui-multiple-data-presentation>

<ng-container *ngIf="!readonly && !disabled">
  <div class="mt-large">
    <mat-error *ngIf="audioTrackFormService.getErrors() as error" class="error-wrapper">
      <ng-container *ngIf="error?.audioLayoutValuesError">
        {{ 'common.validators.audio_track_empty_layout_error' | translate }}
      </ng-container>
      <ng-container *ngIf="error?.audioCountMatchError">
        {{ 'common.validators.audio_track_count_error' | translate : { max: trackChannelsValue } }}
      </ng-container>
    </mat-error>
  </div>
  <div class="mt-large">
    <vdms-hq-ui-button (click)="addTrack()" color="primary">
      {{ 'common.asset.add_track' | translate }}
    </vdms-hq-ui-button>
  </div>
</ng-container>
