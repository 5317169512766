import { ValidationErrors } from '@angular/forms';
import { InjectionToken } from '@angular/core';
import { ActivatedClientConfig } from '@vdms-hq/activated-client';

export class FilenameConventionsValidator {
  static separatorVerifier(): ValidationErrors | null {
    // todo
    return null;
  }
}

export const METADATA_MINIMUM_PERCENT_ACCURACY: InjectionToken<ActivatedClientConfig> = new InjectionToken(
  'MINIMUM_PERCENT_ACCURACY',
);
