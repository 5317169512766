import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BreadCrumb } from '../../models/breadcrumb.model';

@Component({
  selector: 'vdms-hq-ui-inner-header',
  templateUrl: './inner-header.component.html',
  styleUrls: ['./inner-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InnerHeaderComponent {
  @Input() breadcrumbs: BreadCrumb[] = [];
  @Input() header = '';
  @Input() subheader = '';
  @Input() status?: { label: string; color: 'success' | 'default' | 'alert' };
  @Input() action?: { label: string; icon: string };
  @Output() clickAction = new EventEmitter<void>();
}
