import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudioTracksLegacyComponent } from './smart/audio-tracks-legacy/audio-tracks-legacy.component';
import { AudioTracksTableComponent } from './smart/audio-tracks-table/audio-tracks-table.component';
import { SelectorsModule } from '@vdms-hq/selectors';
import { MultipleDataPresentationComponent, UIButtonModule, UIFormModule } from '@vdms-hq/ui';
import { AudioTrackDataSource } from './logic/services/audio-track-data-source';
import { AudioTrackFormService } from './logic/services/audio-track-form.service';
import { PortalModule } from '@angular/cdk/portal';
import { SharedModule } from '@vdms-hq/shared';

const newDeps = [SelectorsModule, UIButtonModule, UIFormModule];

@NgModule({
  declarations: [AudioTracksTableComponent, AudioTracksLegacyComponent],
  providers: [AudioTrackDataSource, AudioTrackFormService],
  imports: [CommonModule, ...newDeps, PortalModule, SharedModule, MultipleDataPresentationComponent],
  exports: [AudioTracksLegacyComponent, AudioTracksTableComponent],
})
export class AudioTracksModule {}
