import { AfterViewInit, Component } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { AnyFileComponent } from '../any-file.component';

@Component({
  selector: 'vdms-hq-ui-pdf-support',
  templateUrl: './pdf-support.component.html',
  styleUrls: ['./pdf-support.component.scss', './../any-file.component.scss'],
})
export class PdfSupportComponent extends AnyFileComponent implements AfterViewInit {
  static supportsExtensions = ['pdf'] as const;

  trustedFilePath!: SafeUrl;

  async ngAfterViewInit(): Promise<void> {
    this.trustedFilePath = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${this.settings.file.url}#toolbar=${this.settings.pdfWithToolbar ? 1 : 0}&navpanes=${
        this.settings.pdfWithNavPanes ? 1 : 0
      }&scrollbar=0`,
    );

    this.loaded();
    this.cdr.detectChanges();
  }
}
