import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isEmptyInputValue } from './email-array.validator';

export function forbiddenValueValidator(
  value: string[],
  type: 'value' | 'mail' | 'ownMail' | 'usedMail' = 'value',
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (isEmptyInputValue(control.value)) return null;
    if (Array.isArray(control.value)) {
      if (control.value.some((el) => value.includes(el))) {
        switch (type) {
          case 'mail':
            return { forbiddenMail: true };
          case 'ownMail':
            return { forbiddenOwnMail: true };
          case 'usedMail':
            return { alreadyUsedMail: true };
          default:
            return { forbiddenValue: true };
        }
      } else {
        return null;
      }
    } else {
      if (value.includes(control.value)) {
        switch (type) {
          case 'mail':
            return { forbiddenMail: true };
          case 'ownMail':
            return { forbiddenOwnMail: true };
          case 'usedMail':
            return { alreadyUsedMail: true };
          default:
            return { forbiddenValue: true };
        }
      } else {
        return null;
      }
    }
  };
}
