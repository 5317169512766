import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService, isMissingUserDataException } from '@vdms-hq/auth';
import { ToastService } from '@vdms-hq/toast';
import { map, Subject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { filterEmpty } from '@vdms-hq/shared';
import { UserApiService } from '@vdms-hq/api-contract';

@Component({
  selector: 'vdms-hq-token-form',
  templateUrl: './token-form.component.html',
  styleUrls: ['../login-form/login-form.component.scss', './token-form.component.scss'],
})
export class TokenFormComponent implements OnInit, OnDestroy {
  fallbackLogoUrl = '/assets/common/logos/vida_login.svg';

  @Input() loginButtonTranslateKey = 'common.auth.sendEmail';
  loading = false;

  form = new UntypedFormGroup({
    token: new UntypedFormControl('', [Validators.required]),
  });
  #destroyed = new Subject<void>();

  constructor(
    private authService: AuthService,
    private toastService: ToastService,
    private userApiService: UserApiService,
  ) {}

  get token(): UntypedFormControl {
    return this.form?.get('token') as UntypedFormControl;
  }

  async login() {
    if (this.form?.invalid) {
      return;
    }

    this.#getToken(this.token.value).subscribe({
      next: async (token) => await this.authService.loginWithCustomToken(token),
      error: () => {
        this.loading = false;
        this.toastService.warning({
          id: 'auth-error',
          message: 'common.auth.badToken',
        });
      },
    });

    this.loading = false;
  }

  ngOnInit(): void {
    this.authService.error$
      .pipe(
        filterEmpty(),
        switchMap((error) => {
          if (isMissingUserDataException(error) && error.email) {
            this.toastService.warning({
              id: 'auth-error',
              message: 'common.auth.userNotConfigured',
              interpolatedParams: {
                email: error.email,
              },
            });
          } else {
            this.toastService.warning({
              id: 'auth-error',
              message: 'common.auth.userUnknownFail',
            });
          }

          return this.authService.logout();
        }),
      )
      .subscribe();
  }

  #getToken(staticToken: string) {
    return this.userApiService.getCustomToken(staticToken).pipe(
      take(1),
      map(({ token }) => token),
    );
  }

  ngOnDestroy(): void {
    this.#destroyed.next();
    this.#destroyed.complete();
  }
}
