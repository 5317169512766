import { Pipe, PipeTransform } from '@angular/core';
import { Column, SettingsColumn } from '../logic/column-config';

@Pipe({
  name: 'isSettingsColumn',
  standalone: true,
})
export class IsColumnSettingsPipe implements PipeTransform {
  static isSettingsColumn(column: Column): false | SettingsColumn {
    return 'type' in column && column.type === 'settings' ? (column as SettingsColumn) : false;
  }

  transform(column: Column) {
    return IsColumnSettingsPipe.isSettingsColumn(column);
  }
}
