export enum Theme {
  dark = 'theme-dark',
  white = 'theme-light',
}

export interface ThemeInput {
  theme?: Theme;
  cssVars?: {
    [key: string]: string | undefined;
  };
}
