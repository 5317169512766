import { Component, forwardRef, Input } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';
import { Timecode } from '@vdms-hq/timecode';
import { percentageToValue, valueToPercentage } from '../../helpers/ranges';
import { ChangeContext, Options } from 'ngx-slider-v2';
import { SelectOption } from '@vdms-hq/shared';

type OutsideValue = Timecode | null;
type OutsideValuePair = {
  from: OutsideValue;
  to: OutsideValue;
};
type InsideValue = Timecode | null;
type InsideValuePair = {
  from: InsideValue;
  to: InsideValue;
};

type TimecodeForm = {
  from: FormControl<Timecode | null>;
  to: FormControl<Timecode | null>;
};

@Component({
  selector: 'vdms-hq-form-input-timecode-range',
  templateUrl: './form-input-timecode-range.component.html',
  styleUrls: ['./../../styles/ranges.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputTimecodeRangeComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormInputTimecodeRangeComponent),
    },
  ],
})
export class FormInputTimecodeRangeComponent extends FormControlValueAccessorComponent<
  OutsideValuePair,
  InsideValuePair
> {
  #precise = 100;

  @Input() set range(data: { min?: Timecode | null; max?: Timecode | null }) {
    if (data.min !== undefined && data.min !== null) {
      this.min = data.min;
    }
    if (data.max !== undefined && data.max !== null) {
      this.max = data.max;
    }
  }

  min = Timecode.fromSeconds(0);
  max = Timecode.fromSeconds(7200); //2h

  get #minValue() {
    return this.min?.countFrames() ?? 0;
  }

  get #maxValue() {
    return this.max?.countFrames() ?? 0;
  }

  @Input() predefinedRangesOptions: SelectOption[] = [];

  innerFormControl = new FormGroup<TimecodeForm>({
    from: new UntypedFormControl(null),
    to: new UntypedFormControl(null),
  });

  from = this.innerFormControl.get('from');
  to = this.innerFormControl.get('to');
  currentSliderFrom = 0;
  currentSliderTo = this.#precise;
  isVisible = true;

  options: Options = {
    floor: 0,
    ceil: this.#precise,
    translate: (value: number): string => this.formatLabel(value),
  };

  customValues = false;

  formatLabel = (givenValue: number) => {
    return (
      Timecode.fromFrames(
        percentageToValue(givenValue, this.#minValue, this.#maxValue, this.#precise),
        undefined,
        false,
      )?.toString() ?? '00:00:00:00'
    );
  };

  override transformInnerToOuter(value: InsideValuePair): OutsideValuePair {
    const left = value?.from;
    const right = value?.to;

    return {
      from: left ?? null,
      to: right ?? null,
    };
  }

  override writeValue(value: OutsideValuePair): void {
    super.writeValue(value);
    const inner = this.innerFormControl.value;

    const { from, to } = inner;

    this.currentSliderFrom = from?.countFrames()
      ? valueToPercentage(from?.countFrames(), this.#minValue, this.#maxValue, this.#precise)
      : 0;

    this.currentSliderTo = to?.countFrames()
      ? valueToPercentage(to?.countFrames(), this.#minValue, this.#maxValue, this.#precise)
      : this.#precise;

    this.#reRenderMatSlider();
  }

  override transformOuterToInner(value: OutsideValuePair): InsideValuePair {
    return {
      from: value?.from ?? null,
      to: value?.to ?? null,
    };
  }

  updateFromSlider(event: ChangeContext) {
    let from = null;
    let to = null;

    if (event.value) {
      from = Timecode.fromFrames(
        percentageToValue(event.value, this.#minValue, this.#maxValue, this.#precise),
        undefined,
        false,
      );
    }

    const highValue = event?.highValue;

    if (highValue && highValue !== this.#precise) {
      to = Timecode.fromFrames(
        percentageToValue(highValue, this.#minValue, this.#maxValue, this.#precise),
        undefined,
        false,
      );
    }

    this.innerFormControl.setValue({
      from,
      to,
    });
  }

  #reRenderMatSlider(): void {
    this.isVisible = false;
    setTimeout(() => (this.isVisible = true), 0);
  }

  updateFromPredefined(value: OutsideValuePair) {
    if (!value) {
      return;
    }
    this.innerFormControl.setValue({
      from: value?.from ?? null,
      to: value?.to ?? null,
    });
  }
}
