export class FileDestination {
  constructor(public url: string, public filename: string, public extension: string) {}

  static fromUrl(url: string, throwIfFail = false): FileDestination | null {
    try {
      const urlObject = new URL(url);

      const filename = urlObject.pathname;
      let extension;
      if (urlObject.pathname.indexOf('.') !== -1) {
        extension = urlObject.pathname.split('.').pop()?.trim()?.toLowerCase();
      }

      if (!extension) {
        throw 'Invalid extension';
      }

      return new FileDestination(url, filename, extension);
    } catch (e) {
      if (throwIfFail) {
        throw e;
      }
    }

    return null;
  }
  get mimeBasedOnExtension() {
    switch (this.extension) {
      case 'mp4':
        return 'video/mp4';
      case 'mov':
        return 'video/quicktime';
      case 'webm':
        return 'video/webm';
      case 'ogv':
        return 'video/ogv';
    }
    return null;
  }
}
