import { inject, Injectable } from '@angular/core';
import { FieldsOptionsService } from '@vdms-hq/api-contract';
import { useAsFilenameSelectOptions } from '@vdms-hq/config';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ClientsFieldsOptionsService {
  private fieldsOptionsService = inject(FieldsOptionsService);

  proxyFilenameOptions$ = this.fieldsOptionsService.getAllFieldTypesSettings().pipe(
    map((fields: string[]) => useAsFilenameSelectOptions(fields)),
    map((options) => [{ key: '', label: 'Default (Original File Name)' }, ...options]),
  );
}
