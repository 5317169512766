import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FilteredResultsService } from './filtered-results.service';
import { CdkDragMove } from '@angular/cdk/drag-drop';

@Component({
  selector: 'vdms-hq-ui-filtered-results',
  templateUrl: './filtered-results.component.html',
  styleUrls: ['./filtered-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilteredResultsComponent implements OnInit {
  @Input() defaultVisible = true;
  @Input() hiddenSwitcher = false;

  #LOCAL_WIDTH_KEY = 'filters_sidebar_width';
  #CSS_TRANSITION_VALUE = '200ms grid-template-columns ease-in-out';
  leftWidth = Number(localStorage.getItem(this.#LOCAL_WIDTH_KEY) ?? 325);
  maxLeftWidth = 1000;
  minLeftWidth = 325;

  @ViewChild('filters') filtersElement!: ElementRef<HTMLDivElement>;

  constructor(public filteredResultsService: FilteredResultsService) {}

  ngOnInit(): void {
    this.filteredResultsService.sidebarOpened$ = new BehaviorSubject(this.defaultVisible);
  }

  onDragMove($event: CdkDragMove) {
    this.#setCssTransition('none');
    const mouseEvent = $event.event as MouseEvent;
    const gap = 10;
    const leftWidth = mouseEvent.clientX - this.filtersElement.nativeElement.offsetLeft - gap;
    if (leftWidth >= this.minLeftWidth && leftWidth <= this.maxLeftWidth) {
      this.leftWidth = leftWidth;
      localStorage.setItem(this.#LOCAL_WIDTH_KEY, String(this.leftWidth));
    }
    this.#setCssTransition(this.#CSS_TRANSITION_VALUE);
  }

  #setCssTransition(value: string) {
    if (!this.filtersElement.nativeElement.parentElement) {
      return;
    }

    this.filtersElement.nativeElement.parentElement.style.transition = value;
  }
}
