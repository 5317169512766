import { Injectable } from '@angular/core';
import { ConnectableDataSource, LoadableDataSource } from '@vdms-hq/shared';
import { BehaviorSubject, combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { QualityControlService } from '@vdms-hq/api-contract';
import { QualityControlAnalyseRow } from './quality-control-view.model';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class QualityControlDatasource implements ConnectableDataSource<QualityControlAnalyseRow>, LoadableDataSource {
  refresh$ = new BehaviorSubject<number>(Date.now());
  assetUuid$ = new BehaviorSubject<string | null>(null);
  emptyResults$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  connection$: Observable<QualityControlAnalyseRow[]> = combineLatest([this.assetUuid$, this.refresh$]).pipe(
    tap(() => this.isLoading$.next(true)),
    switchMap(([assetUuid]) => {
      if (!assetUuid) {
        return of([]);
      }

      return this.qualityControlService.getResults(assetUuid);
    }),
    map((data) => {
      this.isLoading$.next(false);
      this.emptyResults$.next(false);

      if (data.length === 0) {
        this.emptyResults$.next(true);
      }

      return data.map((row) => QualityControlAnalyseRow.fromResponse(row));
    }),
  );

  allData$ = this.connection$;

  constructor(private qualityControlService: QualityControlService) {}

  refresh() {
    this.refresh$.next(Date.now());
  }
}
