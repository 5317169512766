export enum ReindexAutomatizationEndpoints {
  ROOT = 'group/reindex',
}

export type ReindexAutomatizationJobs = {
  index_name: string;
  group_uuid: string;
};

export type ReindexAutomatizationRequestModel = {
  jobs: Partial<ReindexAutomatizationJobs>[];
  is_partial: boolean;
  requested_at?: string;
};

export type ReindexAutomatizationModel = {
  key: string;
  label: string;
  done_at: string;
  processed: number;
  requested_at: string;
  requested_by: string;
  total_todo: number;
  total_assets: number;
  index_size: number;
  status?: string;
  seconds_left?: number;
} & ReindexAutomatizationJobs;
