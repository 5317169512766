import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@vdms-hq/auth';

@Component({
  selector: 'vdms-hq-license-agreement-dialog',
  templateUrl: './license-agreement-dialog.component.html',
  styleUrls: ['./license-agreement-dialog.component.scss'],
})
export class LicenseAgreementDialogComponent {
  accepted = false;
  additionalInfo = false;

  constructor(
    private allDialogs: MatDialog,
    private dialogRef: MatDialogRef<LicenseAgreementDialogComponent>,
    private authService: AuthService,
  ) {}

  public decline(): void {
    this.authService.logout().then();
    this.allDialogs.closeAll();
  }

  public onSubmit(): void {
    this.dialogRef.close({
      acceptLicense: true,
    });
  }

  showAdditional() {
    this.additionalInfo = true;
    this.accepted = true;
  }
}
