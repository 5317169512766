import { Store } from '@ngrx/store';
import { filter, first } from 'rxjs/operators';
import { clientChange, login, logout } from './state/autoload.action';
import { AuthService } from '@vdms-hq/auth';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { firstValueFrom } from 'rxjs';

async function initUserListener(store: Store, auth: AuthService): Promise<boolean> {
  const subject$ = auth.auth$.pipe(filter((auth) => auth !== undefined));
  subject$.subscribe((auth) => {
    if (!auth) {
      store.dispatch(logout());
    } else {
      store.dispatch(login({ id: auth.id }));
    }
  });

  await firstValueFrom(subject$.pipe(first()));

  return true;
}

async function initClientListener(store: Store, activatedClientService: ActivatedClientService): Promise<boolean> {
  const subject$ = activatedClientService.clientId$;

  subject$.subscribe((id) => {
    store.dispatch(clientChange({ id }));
  });

  return true;
}

export const initCoreState =
  (store: Store, auth: AuthService, activatedClientService: ActivatedClientService) => async () => {
    await initUserListener(store, auth);
    await initClientListener(store, activatedClientService);
  };
