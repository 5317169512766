import { inject, Injectable } from '@angular/core';
import { ActivatedClientService, Permission, PermissionService } from '@vdms-hq/activated-client';
import { QuarantineApiService, QuarantineConfig, QuarantineConfigRules } from '@vdms-hq/api-contract';
import { ActiveAssetService } from '@vdms-hq/asset-details';
import { filter, of, switchMap, take, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class QuarantineService {
  private activeAssetService = inject(ActiveAssetService);
  private activatedClientService = inject(ActivatedClientService);
  private permissionService = inject(PermissionService);
  private quarantineApiService = inject(QuarantineApiService);

  #clientQuarantineConfig: QuarantineConfig | undefined | null = null;

  public canUseQuarantinedAssets$ = this.permissionService.verifyWithOwnedPermissions$([
    Permission.USE_QUARANTINED_ASSETS,
  ]);
  public canEditQuarantinedAssets$ = this.permissionService.verifyWithOwnedPermissions$([
    Permission.EDIT_QUARANTINED_ASSETS,
  ]);
  public canManageQuarantineConfigs$ = this.permissionService.verifyWithOwnedPermissions$([
    Permission.MANAGE_QUARANTINE_CONFIGS,
  ]);
  public isActiveAssetQuarantined$ = this.activeAssetService.currentAssetView$.pipe(
    switchMap((asset) => {
      return of(asset && asset.general.is_quarantined);
    }),
  );

  clientQuarantineConfig$ = this.activatedClientService.clientId$.pipe(
    switchMap((clientId) => {
      if (this.#clientQuarantineConfig) {
        return of(this.#clientQuarantineConfig);
      }

      return this.quarantineApiService.getClientConfig(clientId);
    }),
    tap((config) => {
      if (!this.#clientQuarantineConfig && config) {
        this.#clientQuarantineConfig = config;
      }
    }),
  );

  existingClientSupplierQuarantineConfig$(supplierUuid: string | null | undefined) {
    return this.activatedClientService.clientId$.pipe(
      take(1),
      filter((clientUuid) => !!clientUuid),
      switchMap((clientUuid) => {
        if (!clientUuid) {
          throw new Error('Client UUID is missing');
        }

        return this.quarantineApiService.getClientSupplierConfig(clientUuid, supplierUuid);
      }),
    );
  }

  saveQuarantineConfig(rules: QuarantineConfigRules) {
    return this.clientQuarantineConfig$.pipe(
      take(1),
      switchMap((config) => {
        if (config) {
          return this.updateConfig(config, rules);
        }
        return this.createConfig(rules);
      }),
    );
  }

  saveSupplierQuarantineConfig(supplierUuid: string | undefined, rules: QuarantineConfigRules) {
    if (!supplierUuid) {
      throw new Error('Supplier UUID is missing');
    }

    return this.existingClientSupplierQuarantineConfig$(supplierUuid).pipe(
      switchMap((config) => {
        if (config) {
          return this.updateConfig(config, rules);
        }
        return this.createConfig(rules, supplierUuid);
      }),
    );
  }

  updateConfig(config: QuarantineConfig, rules: QuarantineConfigRules) {
    return this.quarantineApiService.updateConfig(config, rules);
  }

  createConfig(rules: QuarantineConfigRules, supplierUuid?: string) {
    return this.activatedClientService.clientId$.pipe(
      take(1),
      filter((uuid) => !!uuid),
      switchMap((uuid) => {
        if (!uuid) {
          throw new Error('Client UUID is missing');
        }

        return this.quarantineApiService.createConfig(uuid, rules, supplierUuid);
      }),
    );
  }
}
