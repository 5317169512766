import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResultDefinitionModel } from '@vdms-hq/shared';
import { Pagination, PaginationProps } from '@vdms-hq/ui';
import { Observable } from 'rxjs';
import { GetResponseData, GetResponsePaginationData } from '../../operators/get-response-data.operator';
import { ApiChildResourcePaginatedResponse, ApiEmptyResponse, ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { AwsV4Signature } from '../upload/upload';
import {
  CollectionAccessTypes,
  CollectionAssetFilters,
  CollectionCreateUploadRequest,
  CollectionCreateUploadResponse,
  CollectionExportMetadataPost,
  CollectionItemGet,
  CollectionItemsPatch,
  CollectionModelFlat,
  CollectionModelGet,
  CollectionModelPatch,
  CollectionModelPost,
  CollectionsFilters,
  CollectionUploadConfirmationRequest,
  CollectionUploadConfirmationResponse,
  FolderCollectionUploadRequest,
  IdData,
} from './models/collection.model';
import { COLLECTIONS_ENDPOINTS } from './models/collections-enpoints.model';

@Injectable({ providedIn: 'root' })
export class CollectionsService {
  constructor(private readonly apiService: ApiService) {}

  getList = (filters: Omit<CollectionsFilters, 'access_type'>, pagination?: Pagination, collectionType = 'all') => {
    let headers = new HttpHeaders();

    headers = pagination ? pagination?.applyToHeaders(headers) : headers;

    Object.entries(filters || {})
      .filter(([, value]) => value !== null && value !== undefined)
      .forEach(([key, value]) => (headers = headers.append(key, value)));

    let url: string;

    switch (collectionType) {
      case 'owned':
        url = `${COLLECTIONS_ENDPOINTS.OWNED}`;
        break;
      case 'shared':
        url = `${COLLECTIONS_ENDPOINTS.SHARED}`;
        break;
      default:
        url = `${COLLECTIONS_ENDPOINTS.COLLECTIONS}`;
    }

    return this.apiService
      .get<ApiChildResourcePaginatedResponse<CollectionModelFlat>>(url, { headers })
      .pipe(GetResponsePaginationData);
  };

  getCollectionData(id: string) {
    let headers = new HttpHeaders();
    headers = headers.append('Cache-Control', 'max-age=0');
    return this.apiService
      .get<ApiResponse<CollectionModelGet>>(`${COLLECTIONS_ENDPOINTS.COLLECTION}/${id}`, {
        headers,
      })
      .pipe(GetResponseData);
  }

  getCollectionAccessType(uuids: CollectionModelFlat['uuid'][]): Observable<CollectionAccessTypes[]> {
    return this.apiService
      .post<
        { collection_uuids: CollectionModelFlat['uuid'][] },
        ApiResponse<{ uuid: CollectionModelFlat['uuid']; access_type: CollectionModelFlat['access_type'] }[]>
      >(`${COLLECTIONS_ENDPOINTS.COLLECTIONS}/get-access-types`, { collection_uuids: uuids })
      .pipe(GetResponseData);
  }

  getAssets = (uuid: string, pagination: Pagination, filters?: Partial<CollectionAssetFilters>) => {
    let headers = new HttpHeaders();
    headers = headers.append('Cache-Control', 'max-age=0');
    headers = pagination ? pagination.applyToHeaders(headers) : headers;
    Object.entries(filters || {})
      .filter(([, value]) => value !== null && value !== undefined)
      .forEach(([key, value]) => (headers = headers.append(key, value)));
    return this.apiService
      .get<ApiChildResourcePaginatedResponse<CollectionItemGet>>(`${COLLECTIONS_ENDPOINTS.COLLECTION}/${uuid}/assets`, {
        headers,
      })
      .pipe(GetResponsePaginationData);
  };

  create = (json: CollectionModelPost) =>
    this.apiService.post<CollectionModelPost, ApiResponse<IdData>>(`${COLLECTIONS_ENDPOINTS.COLLECTION}`, json);

  update = (uuid: string, json: CollectionModelPatch) =>
    this.apiService.patch<CollectionModelPatch, ApiResponse<IdData>>(
      `${COLLECTIONS_ENDPOINTS.COLLECTION}/${uuid}`,
      json,
    );

  updateAndGetSignature = (uuid: string, json: CollectionModelPatch) =>
    this.apiService.patch<CollectionModelPatch, ApiResponse<AwsV4Signature>>(
      `${COLLECTIONS_ENDPOINTS.COLLECTION}/${uuid}`,
      json,
    );

  delete = (uuid: string) => this.apiService.delete(`${COLLECTIONS_ENDPOINTS.COLLECTION}/${uuid}`);

  export = (listUuid: string, fields?: ResultDefinitionModel[], pagination?: PaginationProps) => {
    const headers = Pagination.create(pagination).applyToHeaders(
      new HttpHeaders({ accept: 'text/csv', 'content-type': 'text/csv' }),
    );

    const payload = {
      fields: fields?.map((item) => item.results2.objectPath.replace('props.', '')) ?? [],
    };
    return this.apiService
      .post<CollectionExportMetadataPost, ApiResponse<string>>(
        `${COLLECTIONS_ENDPOINTS.COLLECTION}/${listUuid}/${COLLECTIONS_ENDPOINTS.EXPORT}`,
        payload,
        headers,
      )
      .pipe(GetResponseData);
  };

  addItems(uuid: string, items: { items: { asset_uuid?: string; item_uuid?: string; type?: string }[] }) {
    return this.apiService.patch<CollectionItemsPatch, ApiEmptyResponse>(
      `${COLLECTIONS_ENDPOINTS.COLLECTION}/${uuid}/${COLLECTIONS_ENDPOINTS.ADD_ITEMS}`,
      items,
    );
  }

  addItemsFromCart(uuid: string) {
    return this.apiService.patchNoBody<ApiEmptyResponse>(
      `${COLLECTIONS_ENDPOINTS.COLLECTION}/${uuid}/${COLLECTIONS_ENDPOINTS.ADD_ITEMS_FROM_CART}`,
    );
  }

  removeItems = (uuid: string, items: string[]) =>
    this.apiService.delete(`${COLLECTIONS_ENDPOINTS.COLLECTION}/${uuid}/${COLLECTIONS_ENDPOINTS.REMOVE_ITEMS}`, {
      items,
    });

  prepareUpload = (data: CollectionCreateUploadRequest): Observable<CollectionCreateUploadResponse> =>
    this.apiService.post<CollectionCreateUploadRequest, CollectionCreateUploadResponse>(
      COLLECTIONS_ENDPOINTS.UPLOAD,
      data,
    );

  confirmUpload = (data: CollectionUploadConfirmationRequest) =>
    this.apiService.post<CollectionUploadConfirmationRequest, CollectionUploadConfirmationResponse>(
      COLLECTIONS_ENDPOINTS.UPLOAD_CONFIRMATION,
      data,
    );

  addItemsFromFolder = (data: FolderCollectionUploadRequest) => {
    return this.apiService.put<FolderCollectionUploadRequest, CollectionUploadConfirmationResponse>(
      `${COLLECTIONS_ENDPOINTS.ADD_ITEMS_FROM_FOLDER}`,
      data,
    );
  };

  addAssetsFromOrder(order_uuid: string, selectedCollectionsId: string) {
    return this.apiService.patch(`${COLLECTIONS_ENDPOINTS.COLLECTION}/${selectedCollectionsId}/add-order`, {
      order_uuid,
    });
  }
  addAssetsFromCart(selectedCollectionsId: string) {
    return this.apiService.patch<void, void>(
      `${COLLECTIONS_ENDPOINTS.COLLECTION}/${selectedCollectionsId}/add-cart`,
      undefined,
    );
  }
}
