import { Pipe, PipeTransform } from '@angular/core';
import { FieldDefinitionModel } from '@vdms-hq/shared';
import { FormBuilderService } from '../logic/form-builder.service';
import { FieldConfig, isFormField } from '@vdms-hq/firebase-contract';

@Pipe({
  name: 'fieldDefinition',
})
export class FieldDefinitionPipe implements PipeTransform {
  constructor(private formBuilder: FormBuilderService) {}

  transform(element: FieldConfig): FieldDefinitionModel | undefined {
    const formField = isFormField(element);
    if (!formField) {
      console.warn('Runtime error, given element is not a fieldConfig type', element);
      return;
    }

    const definition = this.formBuilder.getConfig(formField.definition);

    if (!definition) {
      console.warn('Runtime error, missing definition');
      return;
    }

    return definition;
  }
}
