import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BreadCrumb } from '../../models/breadcrumb.model';

@Component({
  selector: 'vdms-hq-ui-layout-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
  readonly MAX_BREADCRUMBS_SIZE = 3;
  @Input() breadcrumbs: BreadCrumb[] = [];
  @Output() selectedBreadCrumb = new EventEmitter<{ crumb: BreadCrumb; index: number }>();
  @Input() loading = false;
  @Input() size: 'small' | 'medium' = 'small';
  @Input() additionalInfo = '';
}
