<div
  class="preview-container"
  [class.preview-container--overflow-hidden]="overflowHidden || loading"
  [class.preview-container--max-height]="limitHeight"
  [class.preview-container--max-width]="limitWidth"
>
  <vdms-hq-ui-preview-error *ngIf="error; else content" [header]="error.header" [reason]="error.message">
  </vdms-hq-ui-preview-error>

  <vdms-hq-ui-loader [center]="true" [backdrop]="true" mode="over-parent" *ngIf="loading"></vdms-hq-ui-loader>

  <ng-template #content>
    <ng-content></ng-content>
  </ng-template>
</div>
