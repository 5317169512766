import { Injectable } from '@angular/core';
import { CartApiService } from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import { combineLatest, of, shareReplay } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { CartStateService } from '@vdms-hq/cart-core';

@Injectable({ providedIn: 'root' })
export class CartTotalDataSource {
  #popToast = {
    CART_TOTAL_FAILURE: () =>
      this.toastService.error({
        id: 'cart_total_items',
        message: 'pages.cart.notifications.total',
      }),
  };

  total$ = combineLatest([this.activatedClientService.clientDefinite$, this.cartStateService.refresh$]).pipe(
    switchMap(() =>
      this.cartApiService.getTotal().pipe(
        startWith(-2),
        map((response) => response ?? 0),
        catchError(() => {
          this.#popToast.CART_TOTAL_FAILURE();

          return of(-1);
        }),
      ),
    ),
    shareReplay(1),
  );

  totalIsLoading$ = this.total$.pipe(map((value) => value === -2));

  constructor(
    private cartApiService: CartApiService,
    private activatedClientService: ActivatedClientService,
    private cartStateService: CartStateService,
    private toastService: ToastService,
  ) {}
}
