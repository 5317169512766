import { Injectable } from '@angular/core';
import {
  AwsV4Signature,
  GroupLogoStatusEnum,
  FileUploadState,
  UploadState,
  ClientsService,
  Client,
  ClientLogo,
  PolicySelectOption,
  PolicyService,
  Policy,
} from '@vdms-hq/api-contract';
import { catchError, firstValueFrom, of, map, take, BehaviorSubject, shareReplay } from 'rxjs';
import { S3SignatureUploadService } from '@vdms-hq/storage';

@Injectable({ providedIn: 'root' })
export class ClientDataService {
  clientData$ = new BehaviorSubject<Client | null>(null);
  clientLogo$ = new BehaviorSubject<ClientLogo | null>(null);
  policyOptions$ = new BehaviorSubject<PolicySelectOption[] | null>(null);

  constructor(
    private s3UploadService: S3SignatureUploadService,
    private clientsService: ClientsService,
    private policyService: PolicyService,
  ) {}

  getClientData(clientId: string) {
    this.getPolicies(clientId);
    this.clientsService
      .getClient(clientId)
      .pipe(shareReplay(1))
      .subscribe((client) => {
        this.clientData$.next(client);
        this.clientLogo$.next(client.logo);
      });
  }

  getPolicies(clientUuid: string) {
    this.policyService
      .getPolicies()
      .pipe(
        shareReplay(1),
        map((policies) => {
          return policies
            .map((element) => {
              return {
                ...element,
                key: element.uuid,
                label: element.name,
              };
            })
            .filter((policy: Policy) => {
              return policy.groups?.some((group) => {
                return group.uuid == clientUuid;
              });
            });
        }),
        map((policies) => [{ key: null, label: 'None' }, ...policies]),
      )
      .subscribe((policies) => {
        this.policyOptions$.next(policies);
      });
  }

  async uploadLogoToS3(uploadAccess: AwsV4Signature, file: File): Promise<{ status: GroupLogoStatusEnum }> {
    return await firstValueFrom(
      this.s3UploadService.uploadSmallFileWithSignature(file, uploadAccess).pipe(
        take(1),
        map(() => ({
          status: GroupLogoStatusEnum.UPLOADED,
        })),
        catchError(() =>
          of({
            status: GroupLogoStatusEnum.ERROR,
          }),
        ),
      ),
    );
  }

  async updateLogoUploadStatus(
    logo_uuid: string,
    group_uuid: string,
    status: GroupLogoStatusEnum.UPLOADED | GroupLogoStatusEnum.ERROR,
  ): Promise<FileUploadState<GroupLogoStatusEnum>> {
    return await firstValueFrom(
      this.clientsService.patchClientLogo(logo_uuid, group_uuid, status).pipe(
        take(1),
        map((response) => {
          this.clientLogo$.next(response);
          return {
            status: response.status,
            state: UploadState.COMPLETED,
            file: {
              uploaded: true,
              progress: 100,
            },
          };
        }),
        catchError(() =>
          of({
            status: GroupLogoStatusEnum.ERROR,
            state: UploadState.ERROR,
            file: {
              uploaded: false,
              progress: 0,
            },
          }),
        ),
      ),
    );
  }

  initStatus(): FileUploadState<GroupLogoStatusEnum> {
    return {
      status: GroupLogoStatusEnum.PENDING,
      state: UploadState.IN_PROGRESS,
      file: {
        uploaded: false,
        progress: 0,
      },
    };
  }
}
