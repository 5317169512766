import { Injectable } from '@angular/core';
import { ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { VideoAnalysisEndpoints, VideoAnalysisOptionsEndpoints } from './video-analysis-endpoint.model';
import { VideoAnalysisModel } from './video-analysis.model';

@Injectable({ providedIn: 'root' })
export class VideoAnalysisApiService {
  constructor(private apiService: ApiService) {}

  trigger = (assetUuid: string) => {
    return this.apiService.post(
      `${VideoAnalysisEndpoints.ROOT}/${assetUuid}/${VideoAnalysisOptionsEndpoints.CONTENT_MODERATION}`,
      null,
    );
  };

  getResults = (assetUuid: string) => {
    return this.apiService
      .get<ApiResponse<VideoAnalysisModel>>(
        `${VideoAnalysisEndpoints.ROOT}/${assetUuid}/${VideoAnalysisOptionsEndpoints.CONTENT_MODERATION}`,
      )
      .pipe(GetResponseData);
  };
}
