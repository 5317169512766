import { Pipe, PipeTransform } from '@angular/core';
import { POSSIBLE_KEYS, TRANSLATED_POSSIBLE_KEYS } from '@vdms-hq/api-contract';

@Pipe({
  name: 'keyToTitle',
  standalone: true,
})
export class KeyToTitlePipe implements PipeTransform {
  transform(value: (typeof POSSIBLE_KEYS)[number]): string {
    const translatedKey = TRANSLATED_POSSIBLE_KEYS[value];
    return translatedKey ?? value;
  }
}
