import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  castTo,
  ConnectableDataSource,
  isSelectableDataSource,
  SelectableDataSource,
  SortableDataSource,
  ValueDisplayFormatConfig,
} from '@vdms-hq/shared';
import { CommonModule } from '@angular/common';
import { RuntimeErrorComponent } from '../components/runtime-error/runtime-error.component';
import { ConfigActions, ContextMenu, TranslatableName } from '../logic/common-config';
import { RouterLinkWithHref } from '@angular/router';
import { TileImageComponent } from '../components/tile-image/tile-image.component';
import { ValueRendererComponent } from '../../ui-value-renderer';
import { FloatingControlsV2Component } from '../components/floating-controls-v2/floating-controls-v2.component';
import { defaultTrackBy } from '../logic/common-functions';
import { TranslateModule } from '@ngx-translate/core';
import { SelectOneComponent } from '../components/select-one/select-one.component';
import { ContextMenuComponent } from '../components/context-menu/context-menu.component';
import { IsRowTooltipPipe } from '../pipes/is-row-tooltip.pipe';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { take } from 'rxjs';
import { MetadataHiddenPipe } from '../pipes/metadata-hidden.pipe';
import { ValueByPathPipe } from '../pipes/value-by-path.pipe';
import { DisableIfRowPipe } from '../pipes/disable-if-row.pipe';
import { RowViewConfig } from '../logic/models';

export type GridAdvancedDataSource<T> = ConnectableDataSource<T> &
  Partial<SortableDataSource> &
  Partial<SelectableDataSource<T>>;

export type GridAdvancedMetadata<T> = {
  label: TranslatableName;
  viewFormat?: ValueDisplayFormatConfig;
  valuePath: string;
  fallback?: string;
  fullLine?: boolean;
  hiddenIf?: (item: T) => boolean;
};

export type GridAdvancedAdditionalInfo = {
  label?: TranslatableName;
  valuePath: string;
  viewFormat?: ValueDisplayFormatConfig;
  fallback?: string;
};

export type GridAdvancedConfig<T> = ContextMenu<T> &
  ConfigActions<T> & {
    rows?: RowViewConfig<T>;
    content?: Partial<{
      iconPath: string;
      titlePath: string;
      additionalInfo?: GridAdvancedAdditionalInfo;
      metadata: GridAdvancedMetadata<T>[];
    }>;
    layout?: {
      columns?: 'up-to-2';
    };
    image?: Partial<{
      bgPath: string;
      bgHoverPath: string;
    }>;
  };

@Component({
  selector: 'vdms-hq-ui-grid-advanced',
  templateUrl: './grid-advanced.component.html',
  styleUrls: ['./grid-advanced.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterLinkWithHref,
    RuntimeErrorComponent,
    TileImageComponent,
    ValueByPathPipe,
    ValueRendererComponent,
    FloatingControlsV2Component,
    TranslateModule,
    SelectOneComponent,
    ContextMenuComponent,
    IsRowTooltipPipe,
    MatTableModule,
    MatTooltipModule,
    MatIconModule,
    MetadataHiddenPipe,
    DisableIfRowPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridAdvancedComponent<T> implements AfterViewInit, OnDestroy {
  private resizeObserver?: ResizeObserver;
  $castToString = castTo<string>();

  @Input() dataSource?: GridAdvancedDataSource<T>;
  @Input() configuration?: GridAdvancedConfig<T>;
  @Output() action = new EventEmitter<{ key: string; item?: T }>();

  defaultTrackBy = defaultTrackBy;
  isSelectable = isSelectableDataSource;

  @ViewChild(ContextMenuComponent) contextMenu?: ContextMenuComponent<T>;
  @ViewChild('gridAdvanced') gridAdvanced?: ElementRef;

  isRowDisabled(item: T) {
    return Boolean(this.configuration?.rows?.disabledIf?.condition(item));
  }

  ngAfterViewInit() {
    this.resizeObserver = new ResizeObserver((entries) => {
      const parentElement = entries[0].target as HTMLDivElement;
      const parentWidth = parentElement.clientWidth;
      const changeToOneColumnWidth = 1100;

      if (parentWidth < changeToOneColumnWidth) {
        parentElement.classList.replace('grid-advanced--up-to-2', 'grid-advanced--up-to-1');
      } else {
        parentElement.classList.replace('grid-advanced--up-to-1', 'grid-advanced--up-to-2');
      }
    });
    this.resizeObserver.observe(this.gridAdvanced?.nativeElement);
  }

  ngOnDestroy() {
    if (!this.gridAdvanced?.nativeElement) {
      return;
    }
    this.resizeObserver?.unobserve(this.gridAdvanced?.nativeElement);
  }

  showContextMenu($event: MouseEvent, item: T) {
    if (this.isRowDisabled(item)) {
      return;
    }
    if (!this.configuration?.contextMenu?.actions || this.configuration?.contextMenu?.actions?.length === 0) {
      return;
    }
    if (!this.contextMenu) {
      return;
    }
    $event.preventDefault();
    this.contextMenu.open($event, item);
  }

  handleDoubleClick($event: MouseEvent, item: T) {
    if ($event?.target instanceof Element && $event.target.closest('mat-checkbox')) {
      $event.stopImmediatePropagation();
      return;
    }

    if (this.isRowDisabled(item)) {
      return;
    }

    const existingAction = (this.configuration?.actions ?? []).find((action) => action.onDoubleClick);
    if (!existingAction) {
      return;
    }

    return this.action.emit({
      key: existingAction.key,
      item,
    });
  }

  selectItem(item: T) {
    if (!this.isSelectable(this.dataSource)) {
      return;
    }

    this.dataSource.selection
      .isSelected$(item)
      .pipe(take(1))
      .subscribe((selected) => {
        if (selected) this.dataSource?.selection?.changeOne(item, false, false);
        else this.dataSource?.selection?.changeOne(item, true, false);
      });
  }
}
