import { Injectable } from '@angular/core';
import { ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import {
  QualityControlAnalyseConfigs,
  QualityControlConfig,
  QualityControlConfigEndpoints,
} from './quality-control-config.model';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { ASSETS_ENDPOINTS } from '../asset';

@Injectable({ providedIn: 'root' })
export class QualityControlService {
  constructor(private readonly apiService: ApiService) {}
  getAllQAConfigs(): Observable<QualityControlConfig[]> {
    return this.apiService
      .get<ApiResponse<QualityControlConfig[]>>(`${QualityControlConfigEndpoints.MANY}`)
      .pipe(GetResponseData);
  }

  getQAConfig(uuid: string): Observable<QualityControlConfig> {
    return this.apiService
      .get<ApiResponse<QualityControlConfig>>(`${QualityControlConfigEndpoints.ONE}/${uuid}`)
      .pipe(GetResponseData);
  }

  postQAConfig(body: Partial<QualityControlConfig>) {
    return this.apiService.post<Partial<QualityControlConfig>, unknown>(`${QualityControlConfigEndpoints.ONE}`, body);
  }

  patchQAConfig(body: Partial<QualityControlConfig>, uuid: string) {
    return this.apiService.patch<Partial<QualityControlConfig>, unknown>(
      `${QualityControlConfigEndpoints.ONE}/${uuid}`,
      body,
    );
  }

  deleteQAConfig(uuid: string) {
    return this.apiService.delete<ApiResponse<QualityControlConfig>>(`${QualityControlConfigEndpoints.ONE}/${uuid}`);
  }

  getResults(assetUuid: string) {
    return this.apiService
      .get<ApiResponse<QualityControlAnalyseConfigs>>(`${ASSETS_ENDPOINTS.ONE}/${assetUuid}/qa`)
      .pipe(GetResponseData);
  }
}
