<vdms-hq-ui-layout-section orientation="horizontal">
  <div class="preview-outer">
    <div class="preview">
      <vdms-hq-ui-preview [settings]="settings" *ngIf="settings"></vdms-hq-ui-preview>
    </div>
  </div>
  <div>
    <h1>Preview samples</h1>
    <ul class="list">
      <li
        *ngFor="let file of files"
        (click)="select(file)"
        class="list__item"
        [class.list__item--selected]="file.bucketPath === selectedFile?.bucketPath"
      >
        {{ file.bucketPath }}
      </li>
    </ul>
    <h1>Supported extensions</h1>
    <div class="extensions-list">
      <span *ngFor="let ext of supportedExtensions">{{ ext }}</span>
    </div>
  </div>
</vdms-hq-ui-layout-section>
