import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoBarType, UIButtonModule, UILayoutModule } from '@vdms-hq/ui';
import { BehaviorSubject } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'vdms-hq-generate-user-api-token',
  templateUrl: './generate-user-api-token.component.html',
  styleUrls: ['./generate-user-api-token.component.scss'],
  standalone: true,
  imports: [CommonModule, UILayoutModule, UIButtonModule, TranslateModule],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenerateUserApiTokenComponent implements OnChanges {
  @Input() userApiToken: string | null = null;
  @Output() generate = new EventEmitter<void>();

  protected readonly InfoBarType = InfoBarType;
  message$ = new BehaviorSubject<string | null>(null);

  type$ = new BehaviorSubject<InfoBarType.WARNING | InfoBarType.SUCCESS>(InfoBarType.WARNING);

  ngOnChanges(): void {
    this.#init();
  }

  generateToken() {
    this.generate.emit();
  }

  #init() {
    if (!this.userApiToken) {
      return;
    }

    const message = this.#generateMessage(this.userApiToken);
    this.message$.next(message);
    this.type$.next(this.userApiToken?.length < 20 ? InfoBarType.WARNING : InfoBarType.SUCCESS);
  }

  #generateMessage(tokenChars: string) {
    const alreadyExistingToken = tokenChars?.length < 20;
    if (alreadyExistingToken) {
      return `This user has an active API token <span class="break-word">${tokenChars}*********************.</span> Generating new token will revoke all access for existing token.`;
    }

    return `This is your new API access token. We're not saving this anywhere so make sure to copy and save it: <span class="break-word">${tokenChars}</span>`;
  }
}
