export type SidebarItem = {
  name: string;
  additionalInfo?: string;
  routerLink?: string;
  superscript?: string | null;
};

export type Separator = 'separator';

export type SidebarNestedItem = {
  children: (SidebarItem | Separator)[];
} & SidebarItem;

export const isCompositeItem = (obj: any): obj is SidebarItem => {
  return 'children' in obj;
};

export const isItem = (obj: any): obj is SidebarItem => {
  return typeof obj === 'object' && 'name' in obj;
};

export const isSeparator = (obj: any): obj is Separator => {
  return obj === 'separator';
};
