<button
  mat-button
  type="button"
  class="trigger--button"
  (click)="displayOverlay()"
  [class.open]="overlayActive$ | async"
  [matTooltip]="'tooltip.notifications_center' | translate"
>
  <span class="button-loader">
    <mat-progress-spinner
      *ngIf="isLoading$ | async"
      mode="indeterminate"
      [strokeWidth]="2"
      [diameter]="19"
    ></mat-progress-spinner>
  </span>

  <div
    [matBadge]="(total$ | async) ?? 0 | replaceWhenExceeds : 1000 : '999+'"
    matBadgeSize="large"
    matBadgeColor="primary"
    [matBadgeHidden]="(isLoading$ | async) || !((total$ | async) ?? 0)"
    [matBadgeOverlap]="true"
    class="button-content"
  >
    <mat-icon>notifications</mat-icon>
  </div>
</button>
