import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QualityControlConfig } from '@vdms-hq/api-contract';
import { SelectOptionKey } from '@vdms-hq/shared';
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { SelectOption, UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { AssetMasterType } from '@vdms-hq/selectors';
import { TranslateModule } from '@ngx-translate/core';
import {
  QCAudioBitDepth,
  QCAudioCodec,
  QCAudioSampleRate,
  QCChromaSampling,
  QCFileExtensions,
  QCFramerates,
  QCInterlaceMode,
  QCVideoBitDepths,
  QCVideoCodecs,
} from '../../logic/quality-control-selectables';
import { InputMultipleComponent } from '@vdms-hq/ui';
import { Timecode } from '@vdms-hq/timecode';

@Component({
  selector: 'vdms-hq-add-edit-qc-config-dialog',
  templateUrl: './qc-config-add-edit-dialog.component.html',
  styleUrls: ['./qc-config-add-edit-dialog.component.scss'],
  standalone: true,
  imports: [UIFormModule, UIDialogWrapperModule, TranslateModule, UIButtonModule, InputMultipleComponent],
})
export class AddEditQCConfigDialogComponent implements OnInit {
  isEdit!: boolean;
  assetTypes: SelectOption[] = AssetMasterType;
  fileExtensions = QCFileExtensions;
  bitDepths = QCVideoBitDepths;
  chromeSampling = QCChromaSampling;
  framerate = QCFramerates;
  videoCodecs = QCVideoCodecs;
  interlaceMode = QCInterlaceMode;
  audioCodec = QCAudioCodec;
  audioBitDepth = QCAudioBitDepth;
  audioSampleRate = QCAudioSampleRate;
  form = new FormGroup({
    asset_type: new FormControl<string>('', [Validators.required]),
    file_extension: new FormControl<SelectOptionKey[]>([]),
    bit_depth: new FormControl<SelectOptionKey[]>([]),
    chroma_format: new FormControl<SelectOptionKey[]>([]),
    framerate: new FormControl<SelectOptionKey[]>([]),
    video_codec: new FormControl<SelectOptionKey[]>([]),
    interlace_mode: new FormControl<SelectOptionKey[]>([]),
    timecode_start: new UntypedFormControl([]),
    width: new FormControl<number[]>([]),
    height: new FormControl<number[]>([]),
    audio_codec: new FormControl<SelectOptionKey[]>([]),
    channel_count: new FormControl<number[]>([]),
    channels_per_track: new FormControl<number[]>([]),
    audio_bit_depth: new FormControl<SelectOptionKey[]>([]),
    audio_sample_rate: new FormControl<SelectOptionKey[]>([]),
    dropped_frames: new FormControl<boolean | null>(null),
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { config?: QualityControlConfig; isEdit?: boolean; isDuplicate?: boolean },
    private readonly dialogRef: MatDialogRef<AddEditQCConfigDialogComponent>,
  ) {}

  ngOnInit(): void {
    if ((this.data?.isEdit || this.data?.isDuplicate) && this.data.config) {
      const { id, uuid, ...editData } = this.data.config;
      this.form.setValue(editData);
      this.isEdit = !!this.data.isEdit;
    }
  }

  cancel() {
    this.dialogRef.close(null);
  }

  save() {
    if (this.form.valid)
      this.dialogRef.close({
        ...this.form.value,
        timecode_start: (this.form.value.timecode_start ?? []).map((t: Timecode) => t.toString()),
      });
  }
}
