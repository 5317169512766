import { Pipe, PipeTransform } from '@angular/core';
import { TemplateBinding } from '@angular/compiler';

@Pipe({
  name: 'nameTransformer',
  pure: true,
  standalone: true,
})
export class NameTransformerPipe implements PipeTransform {
  transform<T = TemplateBinding>(value: ((item: T) => string) | string, item: T): string {
    if (typeof value === 'string') {
      return value;
    }

    return value(item);
  }
}
