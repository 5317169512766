import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';
import { BridgedRequest } from './bridge';
import { ToolsEndpoints } from '../tools';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../api.model';

@Injectable({ providedIn: 'root' })
export class BridgeService {
  constructor(private readonly apiService: ApiService) {}
  public passThroughBridge<T>(bridgedRequest: BridgedRequest): Observable<ApiResponse<T>> {
    return this.apiService.post<BridgedRequest, ApiResponse<T>>(
      `${ToolsEndpoints.ROOT}/${ToolsEndpoints.BRIDGE}`,
      bridgedRequest,
    );
  }
}
