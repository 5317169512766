import { Injectable } from '@angular/core';
import { ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { RatesType } from './rate-card.model';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { Observable } from 'rxjs';

enum RATE_CARD_ENDPOINTS {
  ROOT = 'rates',
}

@Injectable({ providedIn: 'root' })
export class RateCardService {
  constructor(private readonly apiService: ApiService) {}

  getRates = (): Observable<RatesType> => {
    return this.apiService.get<ApiResponse<RatesType>>(`${RATE_CARD_ENDPOINTS.ROOT}`).pipe(GetResponseData);
  };

  updateRates = (rates: RatesType) => {
    return this.apiService
      .patch<RatesType, ApiResponse<RatesType>>(`${RATE_CARD_ENDPOINTS.ROOT}`, rates)
      .pipe(GetResponseData);
  };
}
