<div class="messages">
  <div *ngFor="let message of messages$ | async; trackBy: trackByFn"
       [@flyIn] class="message"
       (click)="close(message.id)">
    <div class="state-indicator">
      <mat-icon class="icon" *ngIf="!isProcessing(message)">{{getIcon(message)}}</mat-icon>
      <mat-spinner [diameter]="24" class="loader" *ngIf="isProcessing(message)"></mat-spinner>
    </div>
    <div class="message-content">
      {{message.message}}
    </div>
  </div>
</div>
