import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FilesizeUnit, FormatBytesPipe } from '@vdms-hq/shared';
import { Bytes, CalculatedValue, FormInputRangeComponent } from '../form-input-range.component';

@Component({
  selector: 'vdms-hq-form-input-size-range',
  templateUrl: './../form-input-range.component.html',
  styleUrls: ['./../../../styles/ranges.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputSizeRangeComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormInputSizeRangeComponent),
    },
  ],
})
export class FormInputSizeRangeComponent extends FormInputRangeComponent<FilesizeUnit> {
  selectedUnit: FilesizeUnit = 'MB';

  get calculatedFrom(): CalculatedValue {
    return FormatBytesPipe.transform(this.from.value, this.selectedUnit);
  }

  get calculatedTo(): CalculatedValue {
    return FormatBytesPipe.transform(this.to.value ?? this.max, this.selectedUnit);
  }

  formatCalculatedValueToBytes = (value: CalculatedValue) => {
    return FormatBytesPipe.transformToBytes(value, this.selectedUnit);
  };

  formatSliderThumbLabel = (realValue: Bytes) => {
    return FormatBytesPipe.transformWithUnit(realValue);
  };
}
