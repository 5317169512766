import { Injectable } from '@angular/core';
import { ConnectableDataSource, LoadableDataSource, SelectableDataSource, Selection } from '@vdms-hq/shared';
import { BehaviorSubject, map, Observable, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { QualityControlConfig, QualityControlService } from '@vdms-hq/api-contract';

@Injectable({ providedIn: 'root' })
export class QualityControlDatasource
  implements
    ConnectableDataSource<QualityControlConfig>,
    SelectableDataSource<QualityControlConfig>,
    LoadableDataSource
{
  refresh$ = new BehaviorSubject<number>(Date.now());
  total$ = new BehaviorSubject(0);
  emptyResults$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  selection!: Selection<QualityControlConfig>;
  allData$: Observable<QualityControlConfig[]> = this.refresh$.asObservable().pipe(
    switchMap(() => this.qualityControlService.getAllQAConfigs()),
    map((data) => {
      this.isLoading$.next(true);
      this.emptyResults$.next(!data?.length);
      this.total$.next(data?.length || 0);
      return data;
    }),
    tap(() => this.isLoading$.next(false)),
  );
  connection$ = this.allData$;

  constructor(private readonly qualityControlService: QualityControlService) {}

  refresh() {
    this.refresh$.next(Date.now());
  }
}
