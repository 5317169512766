<form [formGroup]="userForm" (ngSubmit)="onSubmit()">
  <vdms-hq-ui-dialog-wrapper footerAlign="space-between">
    <ng-container title>
      <h1 *ngIf="data && data.uuid">{{ 'pages.users.edit_user' | translate }}</h1>
      <h1 *ngIf="!data || data.name === ''">{{ 'pages.users.create_new_user' | translate }}</h1>
    </ng-container>
    <div content>
      <vdms-hq-ui-form-section [divider]="false" [layout]="'single'">
        <vdms-hq-ui-form-input-email
          formControlName="email"
          [placeholder]="'pages.settings.email_placeholder' | translate"
          [label]="'pages.settings.email' | translate"
        >
        </vdms-hq-ui-form-input-email>
      </vdms-hq-ui-form-section>

      <vdms-hq-ui-form-section [divider]="false" [layout]="'single'">
        <vdms-hq-ui-form-input-text
          formControlName="name"
          [placeholder]="'pages.settings.name_placeholder' | translate"
          [label]="'pages.settings.name' | translate"
        >
          <vdms-hq-ui-form-errors [control]="userForm.controls.name" errors></vdms-hq-ui-form-errors>
        </vdms-hq-ui-form-input-text>
      </vdms-hq-ui-form-section>

      <vdms-hq-ui-form-section [divider]="false" [layout]="'single'">
        <vdms-hq-ui-form-input-text
          formControlName="office_address"
          [placeholder]="'pages.settings.office_address_placeholder' | translate"
          [label]="'pages.settings.office_address' | translate"
        >
          <vdms-hq-ui-form-errors [control]="userForm.controls.office_address" errors></vdms-hq-ui-form-errors>
        </vdms-hq-ui-form-input-text>
      </vdms-hq-ui-form-section>

      <vdms-hq-ui-form-section [divider]="false" [layout]="'grid-2-columns'">
        <vdms-hq-ui-form-input-text
          formControlName="office_phone_number"
          [placeholder]="'pages.settings.office_phone_number_placeholder' | translate"
          [label]="'pages.settings.office_phone_number' | translate"
        >
          <vdms-hq-ui-form-errors [control]="userForm.controls.office_phone_number" errors></vdms-hq-ui-form-errors>
        </vdms-hq-ui-form-input-text>
        <vdms-hq-ui-form-input-text
          formControlName="mobile_number"
          [placeholder]="'pages.settings.mobile_number_placeholder' | translate"
          [label]="'pages.settings.mobile_number' | translate"
        >
          <vdms-hq-ui-form-errors [control]="userForm.controls.mobile_number" errors></vdms-hq-ui-form-errors>
        </vdms-hq-ui-form-input-text>
      </vdms-hq-ui-form-section>

      <vdms-hq-ui-form-section [divider]="false" [layout]="'grid-2-columns'">
        <vdms-hq-ui-form-input-select
          formControlName="timezone"
          [label]="'pages.settings.timezone' | translate"
          [selectOptions]="timezones"
        >
          <vdms-hq-ui-form-errors [control]="userForm.controls.timezone" errors></vdms-hq-ui-form-errors>
        </vdms-hq-ui-form-input-select>
        <vdms-hq-ui-form-input-select
          formControlName="date_format"
          [label]="'pages.settings.date_format' | translate"
          [selectOptions]="dateFormats"
        >
          <vdms-hq-ui-form-errors [control]="userForm.controls.date_format" errors></vdms-hq-ui-form-errors>
        </vdms-hq-ui-form-input-select>
        <vdms-hq-ui-form-input-select
          formControlName="time_format"
          [label]="'pages.settings.time_format' | translate"
          [selectOptions]="timeFormats"
        >
          <vdms-hq-ui-form-errors [control]="userForm.controls.time_format" errors></vdms-hq-ui-form-errors>
        </vdms-hq-ui-form-input-select>

        <vdms-hq-ui-form-input-select
          formControlName="supplier"
          [label]="'pages.settings.supplier' | translate"
          [selectOptions]="(suppliersSelectOptions$ | async) ?? []"
        >
        </vdms-hq-ui-form-input-select>
        <vdms-hq-ui-form-errors [control]="userForm.controls.supplier" errors></vdms-hq-ui-form-errors>
      </vdms-hq-ui-form-section>

      <ng-container *requiresPermissions="[Permission.CREATE_API_TOKENS]">
        <vdms-hq-generate-user-api-token
          [userApiToken]="userToken$ | async"
          (generate)="generateToken()"
        ></vdms-hq-generate-user-api-token>
      </ng-container>
    </div>

    <ng-container footer>
      <vdms-hq-ui-button (click)="abort()" color="secondary">{{
        'common.global.cancel' | translate
      }}</vdms-hq-ui-button>
      <vdms-hq-ui-button
        [isSubmit]="true"
        [loading]="!!(isUpdating$ | async)"
        [disabled]="userForm.invalid"
        color="primary"
        >{{ 'common.global.save' | translate }}</vdms-hq-ui-button
      >
    </ng-container>
  </vdms-hq-ui-dialog-wrapper>
</form>
