import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  distinctUntilChanged,
  firstValueFrom,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  throwError,
} from 'rxjs';
import { AuthenticatedUserModel, UserClient } from '../models/authenticated-user.model';
import { MissingUserDataException } from '../exceptions/missing-user-data.exception';
import {
  AuthUserModel,
  ClientRef,
  Permission,
  PersistenceEndpointsModel,
  PolicyModel,
  UserModel,
} from '@vdms-hq/firebase-contract';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { catchError, first, map, switchMap, take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { filterEmpty } from '@vdms-hq/shared';

@Injectable()
/**
 * @internal do not use outside auth.module.ts, instead use proxy class auth.service.ts
 */
export class AuthStateService {
  authToken$ = this.auth.user.pipe(
    switchMap((user) => (user ? user.getIdToken() : of(undefined))),
    catchError(() => throwError(new MissingUserDataException('token'))),
    take(1),
  );
  #authState = new BehaviorSubject<AuthenticatedUserModel | null | undefined>(undefined);
  authState$ = this.#authState.asObservable();
  authStateDefinite$ = this.authState$.pipe(filterEmpty());
  authId$ = this.#authState.pipe(
    map((auth) => auth?.id),
    distinctUntilChanged(),
  );

  email$ = this.#authState.pipe(
    map((auth) => auth?.email),
    distinctUntilChanged(),
  );

  emailDefinite$ = this.email$.pipe(filterEmpty());
  #error$ = new ReplaySubject<Error | null>(1);
  error$ = this.#error$.asObservable();

  clientsInfo$: Observable<{
    clients: UserClient[];
    selectedClientId?: ClientRef;
  }> = this.authState$.pipe(
    map((auth) => {
      const isClientIdApplicable = (clientId: ClientRef, clients: UserClient[]) =>
        clients.some((client: UserClient) => client.uuid === clientId);

      const clients = auth?.clients ?? [];
      let selectedClientId = auth?.selectedClientId;

      if (selectedClientId && !isClientIdApplicable(selectedClientId, clients)) {
        selectedClientId = clients[0].uuid;
      }

      return {
        clients,
        selectedClientId,
      };
    }),
    shareReplay(1),
  );

  constructor(private afs: AngularFirestore, private auth: AngularFireAuth) {}

  setFromFirebaseUser = async () => {
    try {
      this.#error$.next(null);
      await this.#setFromFirebaseAuthService();
    } catch (error) {
      this.#authState.next(null);
      this.#error$.next(error as Error);
    }
  };

  #setFromFirebaseAuthService = async () => {
    const user = (await firstValueFrom(this.auth.user.pipe(first()))) as AuthUserModel;

    if (!user) {
      this.#authState.next(null);
      return;
    }

    if (!user.email) {
      throw new MissingUserDataException('fireauth.email');
    }

    if (!user.uid) {
      throw new MissingUserDataException('fireauth.uid', user.email);
    }

    const userDataSnapshot = await this.afs
      .doc<UserModel>(`${PersistenceEndpointsModel.USERS_COLLECTION}/${user.email}`)
      .ref.get();
    const userData = userDataSnapshot.data();

    const policiesSnapshot = await this.afs
      .collection<PolicyModel>(PersistenceEndpointsModel.POLICIES_COLLECTION)
      .ref.get();

    const policies = policiesSnapshot.docs.map((ref) => {
      const policy = ref.data();

      return {
        ...policy,
        uuid: ref.id,
      };
    });

    if (!userData?.groups) {
      throw new MissingUserDataException('firebase.groups', user.email);
    }

    if (!userData?.policies) {
      throw new MissingUserDataException('firebase.policies', user.email);
    }

    const enabledClientsIds = userData.groups;
    const enabledPoliciesIds = userData.policies;

    const onlyFound = (item: PolicyModel | undefined): item is PolicyModel => !!item;

    const enabledPolicies = [...enabledPoliciesIds]
      .map((policyId) => policies.find((policy) => policy.uuid === policyId))
      .filter(onlyFound);

    const clients = [...enabledClientsIds].map((clientId) => {
      const enabledPoliciesForClient = [...enabledPolicies].filter((policy) => policy?.groups.includes(clientId));

      let permissions: Permission[] = [];
      enabledPoliciesForClient.forEach((policy) => permissions.push(...policy.permissions));
      permissions = this.#replaceLegacyPermissions(permissions);

      return {
        uuid: clientId,
        permissions,
      };
    });

    if (userData.selectedClientId && !enabledClientsIds.includes(userData.selectedClientId)) {
      userData.selectedClientId = enabledClientsIds[0];
      await this.afs.doc(`${PersistenceEndpointsModel.USERS_COLLECTION}/${user.email}`).update(userData);
    }

    if (!userData.selectedClientId || !enabledClientsIds.includes(userData.selectedClientId)) {
      userData.selectedClientId = enabledClientsIds[0];
      await this.afs.doc(`${PersistenceEndpointsModel.USERS_COLLECTION}/${user.email}`).update(userData);
    }

    const clientsPermissions: Set<string> = new Set([]);
    clients.forEach(({ permissions }) => permissions.forEach((p) => clientsPermissions.add(p)));

    this.#authState.next({
      id: user.uid,
      email: user.email,
      emailVerified: user.emailVerified,
      policies: userData.policies,
      groups: userData.groups,
      clients,
      selectedClientId: userData.selectedClientId,
      clientsPermissions: Array.from(clientsPermissions.values()),
      lastSignIn: user.metadata.lastSignInTime,
    });
  };

  #replaceLegacyPermissions(permissions: Permission[]) {
    return permissions.map((item) => {
      return item === Permission.ASPERA_UPLOAD ? Permission.ASSET_UPLOAD : item;
    });
  }
}
