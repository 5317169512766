import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UILoaderModule } from '../../../ui-loader/ui-loader.module';
import { Selection, SelectionVisibility } from '@vdms-hq/shared';
import { CommonModule } from '@angular/common';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { RuntimeErrorComponent } from '../runtime-error/runtime-error.component';

@Component({
  selector: 'vdms-hq-ui-select-one',
  imports: [UILoaderModule, CommonModule, MatCheckboxModule, RuntimeErrorComponent],
  template: `
    <ng-container *ngIf="selection && item; else missingSelection">
      <mat-checkbox
        *ngIf="selection.state$ | async; let state"
        [checked]="selection.isSelected$(item) | async"
        (change)="onSelectionChange($event)"
        [disabled]="(selection.visibility$ | async) === SelectionVisibility.DISABLED"
      >
      </mat-checkbox>
    </ng-container>

    <ng-template #missingSelection>
      <vdms-hq-ui-runtime-error message="Missing selection or item"></vdms-hq-ui-runtime-error>
    </ng-template>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOneComponent<T> {
  @Input() selection?: Selection<T>;
  @Input() item?: T;
  protected readonly SelectionVisibility = SelectionVisibility;

  onSelectionChange(value: MatCheckboxChange) {
    if (!this.item) {
      return;
    }

    if (!this.selection) {
      return;
    }

    const pointerEvent = event as PointerEvent;

    this.selection.changeOne(this.item, value.checked, pointerEvent?.shiftKey ?? false);
  }
}
