import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BitrateUnit, FormatBitratePipe } from '@vdms-hq/shared';
import { Bytes, FormInputRangeComponent } from '../form-input-range.component';

@Component({
  selector: 'vdms-hq-form-input-bitrate-range',
  templateUrl: './../form-input-range.component.html',
  styleUrls: ['./../../../styles/ranges.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputBitrateRangeComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormInputBitrateRangeComponent),
    },
  ],
})
export class FormInputBitrateRangeComponent extends FormInputRangeComponent<BitrateUnit> {
  selectedUnit: BitrateUnit = 'kbps';

  get calculatedFrom() {
    return FormatBitratePipe.transform(this.from.value, this.selectedUnit);
  }

  get calculatedTo() {
    return FormatBitratePipe.transform(this.to.value ?? this.max, this.selectedUnit);
  }

  formatCalculatedValueToBytes = (value: number) => {
    return FormatBitratePipe.transformToBytes(value, this.selectedUnit);
  };

  formatSliderThumbLabel = (realValue: Bytes) => {
    return FormatBitratePipe.transformWithUnit(realValue) ?? 'N/A';
  };
}
