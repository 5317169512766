import { Portal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import {
  ConnectableDataSource,
  isSelectableDataSource,
  isSortableDataSource,
  SelectableDataSource,
} from '@vdms-hq/shared';
import { EMPTY } from 'rxjs';
import { UIButtonModule } from '../../ui-button';
import { ValueRendererComponent } from '../../ui-value-renderer';
import { ContextMenuComponent } from '../components/context-menu/context-menu.component';
import { FloatingControlsV2Component } from '../components/floating-controls-v2/floating-controls-v2.component';
import { RuntimeErrorComponent } from '../components/runtime-error/runtime-error.component';
import { SelectAllOnPageComponent } from '../components/select-all-on-page/select-all-on-page.component';
import { SelectOneComponent } from '../components/select-one/select-one.component';
import { Column } from '../logic/column-config';
import { ActionContextLess, ActionIdentifier, ConfigActions, ContextMenu } from '../logic/common-config';
import { defaultTrackBy } from '../logic/common-functions';
import { RowViewConfig } from '../logic/models';
import { ActionStatePipe } from '../pipes/action-state.pipe';
import { DisableIfRowPipe } from '../pipes/disable-if-row.pipe';
import { IsColumnActionsPipe } from '../pipes/is-column-actions.pipe';
import { IsColumnSelectPipe } from '../pipes/is-column-select.pipe';
import { IsColumnSettingsPipe } from '../pipes/is-column-settings.pipe';
import { IsColumnValuePipe } from '../pipes/is-column-value.pipe';
import { IsRowTooltipPipe } from '../pipes/is-row-tooltip.pipe';
import { RowClassesPipe } from '../pipes/row-classes.pipe';
import { ValueByPathPipe } from '../pipes/value-by-path.pipe';
import { IfHiddenControlsPipe } from '../pipes/if-hidden-controls.pipe';

export type TableAdvancedDataSource<T> = ConnectableDataSource<T> & Partial<SelectableDataSource<T>>;
export type CustomColumnIdentifier = string;
export type TableAdvancedConfig<T> = ContextMenu<T> &
  ConfigActions<T> & {
    rows?: RowViewConfig<T>;
    layout?: {
      headers?: boolean;
      rowSpacing?: boolean;
      actionsSpace?: boolean;
    };
    columnsEnabled: string[];
    columns: Column[];
    footer?: { [key: string]: Portal<unknown> };
    settingsAction?: {
      id?: ActionIdentifier;
      label?: string;
      icon?: string;
      color?: 'secondary' | 'transparent';
    };
    selectAllVisible?: boolean;
    headerActions?: ActionContextLess[];
  };

@Component({
  selector: 'vdms-hq-ui-table-advanced',
  templateUrl: './table-advanced.component.html',
  styleUrls: ['./table-advanced.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RuntimeErrorComponent,
    MatSortModule,
    MatTableModule,
    MatCheckboxModule,
    SelectOneComponent,
    TranslateModule,
    MatTooltipModule,
    ValueRendererComponent,
    FloatingControlsV2Component,
    SelectAllOnPageComponent,
    IsRowTooltipPipe,
    MatMenuModule,
    ContextMenuComponent,
    UIButtonModule,
    ActionStatePipe,
    MatIconModule,
    MatRippleModule,
    IsColumnActionsPipe,
    IsColumnSelectPipe,
    IsColumnValuePipe,
    IsColumnSettingsPipe,
    RowClassesPipe,
    ValueByPathPipe,
    DisableIfRowPipe,
    PortalModule,
    IfHiddenControlsPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableAdvancedComponent<T> {
  #SETTINGS_COLUMN = 'actions';
  @Input() dataSource?: TableAdvancedDataSource<T>;
  #configuration?: TableAdvancedConfig<T>;

  columns?: {
    defined: Column[];
    enabled: string[];
  };

  hasSingleClick = true;

  get configuration() {
    return this.#configuration;
  }

  @Input() set configuration(configuration: TableAdvancedConfig<T> | undefined) {
    this.hasSingleClick = !!(configuration?.actions ?? []).find((action) => action.onSingleClick);
    if (!configuration) {
      return;
    }

    this.#configuration = configuration;

    this.#prepareColumns();
  }

  @Output() action = new EventEmitter<{ key: string; item?: T }>();
  @ViewChild(ContextMenuComponent) contextMenu?: ContextMenuComponent<T>;
  columnsUnfolded = new Set<CustomColumnIdentifier>();

  protected readonly isSortableDataSource = isSortableDataSource;
  protected readonly defaultTrackBy = defaultTrackBy;
  protected readonly EMPTY = EMPTY;
  protected readonly isSelectableDataSource = isSelectableDataSource;

  #prepareColumns() {
    const defined = this.configuration?.columns ?? [];
    const enabled = (this.configuration?.columnsEnabled ?? []).filter(
      (enabled) => defined.findIndex((def) => def.id === enabled) !== -1,
    );

    if (!this.configuration?.settingsAction) {
      this.columns = {
        enabled,
        defined,
      };
      return;
    }

    const hasVisibleSettings = enabled
      .map((id) => (this.configuration?.columns ?? []).find((item) => id === item.id) ?? null)
      .find(
        (column) =>
          column && (IsColumnActionsPipe.isColumnAction(column) || IsColumnSettingsPipe.isSettingsColumn(column)),
      );

    if (hasVisibleSettings) {
      this.columns = {
        enabled,
        defined,
      };
      return;
    }

    this.columns = {
      defined: [
        ...defined,
        {
          id: this.#SETTINGS_COLUMN,
          type: 'actions',
        },
      ],
      enabled: [...enabled, this.#SETTINGS_COLUMN],
    };
  }

  handleClick($event: MouseEvent, item: T) {
    if ($event?.target instanceof Element && $event.target.closest('mat-checkbox')) {
      $event.stopImmediatePropagation();
      return;
    }

    const existingAction = (this.configuration?.actions ?? []).find((action) => action.onSingleClick);
    if (!existingAction) {
      return;
    }

    this.action.emit({
      key: existingAction.key,
      item,
    });
  }

  handleDoubleClick($event: MouseEvent, item: T) {
    if (this.configuration?.rows?.disabledIf && this.configuration?.rows?.disabledIf?.condition(item)) {
      return;
    }

    if ($event?.target instanceof Element && $event.target.closest('mat-checkbox')) {
      $event.stopImmediatePropagation();
      return;
    }

    const existingAction = (this.configuration?.actions ?? []).find((action) => action.onDoubleClick);
    if (!existingAction) {
      return;
    }

    this.action.emit({
      key: existingAction.key,
      item,
    });
  }

  showContextMenu($event: MouseEvent, item: T) {
    if (this.configuration?.rows?.disabledIf && this.configuration?.rows?.disabledIf?.condition(item)) {
      return;
    }

    if (!this.configuration?.contextMenu?.actions || this.configuration?.contextMenu?.actions?.length === 0) {
      return;
    }
    if (!this.contextMenu) {
      return;
    }
    $event.preventDefault();
    this.contextMenu.open($event, item);
  }

  toggleFoldUnfold($event: MouseEvent, key: CustomColumnIdentifier) {
    $event.stopPropagation();
    $event.preventDefault();

    if (this.columnsUnfolded.has(key)) {
      this.columnsUnfolded.delete(key);
    } else {
      this.columnsUnfolded.add(key);
    }
  }
}
