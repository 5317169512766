import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { WebsocketNotificationInterface } from '../models/websocket.model';

type SourceType<T> = WebsocketNotificationInterface<T>;
export function FilterStreamMsgFactory<T>(
  streamActions: string[],
): (source$: Observable<SourceType<T>>) => Observable<SourceType<T>> {
  return (source$) =>
    source$.pipe(
      filter((msg) => {
        if (msg.data?.action) {
          return streamActions.includes(msg.data.action);
        }
        return false;
      }),
    ) as Observable<SourceType<T>>;
}
