import { Inject, Injectable } from '@angular/core';
import { STORAGE_CONFIG, StorageConfig } from './storage-config';

@Injectable({
  providedIn: 'root',
})
export class StorageUrlService {
  constructor(@Inject(STORAGE_CONFIG) private config: StorageConfig) {}

  updateCdn = (url: string | undefined) => {
    if (!url) {
      return null;
    }

    try {
      const actualURl = new URL(url);
      if (actualURl.hostname.includes('wasabi')) {
        return url;
      }

      const relative = actualURl.toString().substring(actualURl.origin.length);
      const newUrl = new URL(relative, this.config.cdn);

      return newUrl.toString();
    } catch (e) {
      return null;
    }
  };
}
