export function replaceEmptyStringWithNull<T>(obj: any) {
  const modifiedObject: { [key: string]: any } = JSON.parse(JSON.stringify(obj));

  const replace = (obj: Record<string, any>) => {
    for (const key in obj) {
      if (obj[key] === '') {
        obj[key] = null;
      } else if (typeof obj[key] === 'object') {
        replace(obj[key]);
      }
    }
  };

  replace(modifiedObject);

  return modifiedObject as T;
}
