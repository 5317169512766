import { CommonModule } from '@angular/common';
import { AuthModule, AuthService } from '@vdms-hq/auth';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { Store, StoreModule } from '@ngrx/store';
import { appReducers } from './logic/state/reducer-app';
import { initCoreState } from './logic/core-state.initializer';
import { ActivatedClientModule, ActivatedClientService } from '@vdms-hq/activated-client';

@NgModule({
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: initCoreState,
    deps: [Store, AuthService, ActivatedClientService],
    multi: true,
  }],
  imports: [CommonModule, AuthModule, ActivatedClientModule, StoreModule.forRoot(appReducers)],
})
export class StateRootModule {
}
