import { Component, Input } from '@angular/core';
import { BreadCrumb } from '../../models/breadcrumb.model';

@Component({
  selector: 'vdms-hq-ui-layout-section',
  templateUrl: './layout-section.component.html',
  styleUrls: ['./layout-section.component.scss'],
})
export class LayoutSectionComponent {
  @Input() margin = true;
  @Input() pagePadding = false;
  @Input() accent = false;
  @Input() orientation: 'horizontal' | 'vertical' = 'vertical';
  @Input() breadcrumbs?: BreadCrumb[] = [];
}
