import { ChangeDetectorRef, Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { FormControlValueAccessorComponent } from '@vdms-hq/ui';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DataProviderService } from '../../logic/data-provider.service';
import { map, startWith } from 'rxjs/operators';
import { Option, SelectOption } from '@vdms-hq/shared';
import { combineLatest, Observable, of } from 'rxjs';
import { SelectorSourceType } from '../../logic/selector-source.type';

@Component({
  selector: 'vdms-hq-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AutocompleteComponent),
    },
  ],
})
export class AutocompleteComponent extends FormControlValueAccessorComponent<string> implements OnInit {
  innerFormControl = new UntypedFormControl(null);
  #list$: Observable<SelectOption[]> = of([]);
  #controlValue$: Observable<string> = this.innerFormControl.valueChanges.pipe(startWith(''));

  loading$?: Observable<boolean>;
  filteredList$?: Observable<SelectOption[]>;

  @Input() sourceType!: SelectorSourceType;
  @Input() context: 'search' | 'forms' = 'search';
  @Input() autocompleteList: SelectOption[] = [];

  constructor(private provider: DataProviderService, injector: Injector, changeDetectorRef: ChangeDetectorRef) {
    super(injector, changeDetectorRef);
  }

  ngOnInit() {
    super.ngOnInit();

    if (!this.provider) {
      return;
    }

    this.loading$ = this.provider.isLoading(this.sourceType);
    this.#list$ = this.autocompleteList.length
      ? of(this.autocompleteList)
      : this.provider.listForAutocomplete(this.sourceType, this.context);

    this.filteredList$ = combineLatest([this.#list$, this.#controlValue$]).pipe(
      map(([selectOptions, controlValue]) => {
        if (!controlValue) {
          return selectOptions;
        }
        return (selectOptions as Option[]).filter((opt) =>
          opt.label.toLowerCase().includes(controlValue?.toLowerCase()),
        );
      }),
    );
  }
}
