import { Injectable } from '@angular/core';
import { UIConfirmationDialogService } from '@vdms-hq/ui';
import { switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
import { PersistenceSearchParams } from '@vdms-hq/api-contract';
import { Router } from '@angular/router';
import { ActivatedClientService, ClientKeyLabel } from '@vdms-hq/activated-client';

type clientId = { clientId: string };

@Injectable({ providedIn: 'root' })
export class ClientAccessChecker {
  constructor(
    private router: Router,
    private activatedClientService: ActivatedClientService,
    private confirmationDialog: UIConfirmationDialogService,
  ) {}

  checkAccess(persistenceQueryParams: clientId | PersistenceSearchParams): Observable<boolean> {
    return this.activatedClientService.authorizedClientsOptions$.pipe(
      withLatestFrom(this.activatedClientService.selectedClientId$),
      switchMap(([clients, selectedClient]) => {
        const isProvidedClientAvailable = clients.some(
          (ckl: ClientKeyLabel) => ckl.key === persistenceQueryParams['clientId'],
        );
        if (!isProvidedClientAvailable) {
          setTimeout(() => this.router.navigate(['/']));
          return EMPTY;
        }
        return of([persistenceQueryParams, selectedClient]);
      }),
      switchMap(([persistanceQueryParams, selectedClient]) => {
        if (persistenceQueryParams['clientId'] === selectedClient) {
          return of(null);
        }
        return of(persistanceQueryParams);
      }),
      switchMap((persistenceQueryParams) => {
        if (persistenceQueryParams === null) {
          return of(true);
        }
        return this.confirmationDialog
          .open(
            {
              title: 'Please confirm',
              message: 'The resource exists in a different client, would you like to switch there?',
            },
            { panelClass: 'client-checker' },
          )
          .pipe(
            take(1),
            tap((status) => {
              switch (status) {
                case false:
                  this.router.navigate(['/']);
                  break;
                case true:
                  this.activatedClientService.setActivatedClientId(
                    (persistenceQueryParams as PersistenceSearchParams)?.clientId?.toString() || '',
                  );
                  break;
              }
            }),
          );
      }),
    );
  }
}
