import { Injectable } from '@angular/core';
import { ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { FilenameAnalysisOutputData, FilenameAnalysisRequest } from './models/metadata-recognition.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MetadataRecognitionApiService {
  constructor(private readonly apiService: ApiService) {}

  analyzeFilenames(payload: FilenameAnalysisRequest): Observable<FilenameAnalysisOutputData[]> {
    return this.apiService
      .post<FilenameAnalysisRequest, ApiResponse<FilenameAnalysisOutputData[]>>('tools/analyze-filenames', payload)
      .pipe(GetResponseData);
  }
}
