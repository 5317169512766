import { InjectionToken } from "@angular/core";
import { FirebaseOptions } from 'firebase/app';

export interface AuthRouteConfig {
  forUnauthorized?: string;
  forAuthorized?: string;
}

export interface AuthConfig {
  firebase: FirebaseOptions,
  microsoftTenantId?: string,
  routing?: AuthRouteConfig
}

export const AUTH_CONFIG: InjectionToken<AuthConfig> = new InjectionToken('AUTH_TOKEN');
