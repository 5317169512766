import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandlingService } from './error-handling.service';
import { redirect404, redirectNotPermitted403, authenticate401 } from './error-handling.types';
import { ErrorHandlingRoutes, ErrorHandlingMessages } from './error-handling.enum';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(private readonly errorHandlingService: ErrorHandlingService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request.clone()).pipe(
      catchError((error) => {
        if (request.context.get(redirect404) && error.status === +ErrorHandlingRoutes.NOT_FOUND) {
          this.errorHandlingService.redirectToErrorPage();
        }
        if (request.context.get(redirectNotPermitted403) && error.status === +ErrorHandlingRoutes.NOT_PERMITTED) {
          this.errorHandlingService.redirectToErrorPage(ErrorHandlingRoutes.NOT_PERMITTED);
        }
        if (
          request.context.get(authenticate401) &&
          error.status === +ErrorHandlingRoutes.NOT_AUTHORIZED_STATUS &&
          error.error.data === ErrorHandlingMessages.REQUIRED_2FA
        ) {
          this.errorHandlingService.redirectToErrorPage(ErrorHandlingRoutes.NOT_AUTHORIZED_PAGE);
        }
        // todo:
        // if (!request.context.get(ignoreToast)) {
        //   this.errorHandlingService.triggerErrorToast();
        // }
        return throwError(error);
      }),
    );
  }
}
