<div class="login-or-separator">
  <span>or</span>
</div>

<div class="provider-buttons">
  <button
    mat-raised-button
    class="button provider-button"
    *ngIf="settings.google"
    [loading]="loadingGoogle"
    (click)="loginViaGoogle()"
  >
    <img class="vendor-logo" alt="Google logo" src="assets/login/google.svg" />
    {{ 'common.auth.provider.googleButtonLabel' | translate }}
  </button>
  <button
    mat-raised-button
    class="button provider-button"
    *ngIf="settings.microsoft"
    [loading]="loadingMicrosoft"
    (click)="loginViaMicrosoft()"
  >
    <img class="vendor-logo" alt="Microsoft logo" src="assets/login/microsoft.svg" />
    {{ 'common.auth.provider.microsoftButtonLabel' | translate }}
  </button>
</div>
