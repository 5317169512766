<div class="release__container" *ngIf="releaseNoteDS.releaseNote$ | async as release">
  <header class="release__header">
    <span class="release__title">
      {{ release.tag_name }}
    </span>
    <span *ngIf="release.prerelease" class="pill yellow">Pre-release</span>
  </header>
  <p class="release__date">{{ release.published_at | date : 'MMM d, y' }}</p>
  <article class="markdown-content" [innerHTML]="release.body | sanitize : SANITIZE_TYPE.HTML"></article>
</div>
<vdms-hq-ui-loader *ngIf="isFetching$ | async" [backdrop]="true" [mode]="'over-parent'"></vdms-hq-ui-loader>
