<div class="material-custom-field checkbox-outer" [class.with-touched-indicator]="withTouchedIndicator">
  <mat-checkbox [formControl]="innerFormControl" (blur)="propagateTouch()" [required]="isRequired">
    {{ label }}
  </mat-checkbox>
  <div class="footer" *ngIf="withFooter">
    <mat-hint *ngIf="withFooter && hint">{{ hint }}</mat-hint>
    <mat-error *ngIf="withFooter && innerFormControl.touched">
      <span *ngFor="let error of errors">{{ error.transLang | translate : error.contextParams }}</span>
    </mat-error>
  </div>
</div>
