import { InjectionToken } from '@angular/core';

export interface AsperaConfig {
  /** @deprecated this data comes from backend, we should't use this hardcoded value */
  remoteHost: string;
  /** @deprecated this data comes from backend, we should't use this hardcoded value */
  remoteUser: string;
  /** @deprecated this data comes from backend, we should't use this hardcoded value */
  remotePassword: string;
  /** @deprecated this data comes from backend, we should't use this hardcoded value */
  destinationRoot: string;
  sdkLocation: string;
  debug: boolean;
}

export const ASPERA_CONFIG: InjectionToken<AsperaConfig> = new InjectionToken('ASPERA_CONFIG_TOKEN');

export interface ViewConfig {
  draggablePlaceholderCss?: string;
  allowMultipleSelection: boolean;
  autoUpload: boolean;
  clearSelectedOnDone: boolean;
}
