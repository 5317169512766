import { Routes } from '@angular/router';
import { LicensePackagesResultsComponent } from './details/pages/license-packages-results/license-packages-results.component';
import { SingleLicensePackageComponent } from './details/pages/single-license-package/single-license-package.component';
import { SinglePackageResolverService } from './logic/single-license-package-resolver.service';

export enum LICENSE_PACKAGES_ROUTER_BASE {
  ROOT = 'licensed-packages',
  SINGLE_PACKAGE_TITLE = ':uuid',
}

export const licensePackagesRouting: Routes = [
  {
    path: '',
    component: LicensePackagesResultsComponent,
  },
  {
    path: LICENSE_PACKAGES_ROUTER_BASE.SINGLE_PACKAGE_TITLE,
    component: SingleLicensePackageComponent,
    resolve: {
      uuid: SinglePackageResolverService,
    },
    providers: [SinglePackageResolverService],
  },
  {
    path: '**',
    redirectTo: `/${LICENSE_PACKAGES_ROUTER_BASE.ROOT}`,
    pathMatch: 'full',
  },
];
