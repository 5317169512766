import { Injectable } from '@angular/core';
import { SidenavState, SidenavStatusEnum } from '../models/sidenav.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { getLocalStorageItem, setLocalStorageItem } from '@vdms-hq/shared';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  #setLocalStorageItem = setLocalStorageItem;
  #getLocalStorageItem = getLocalStorageItem;

  #sidenavStatus$ = new BehaviorSubject<SidenavState>(this.#getSidenavState() || SidenavStatusEnum.Expanded);

  get sidenavStatus$(): Observable<SidenavState> {
    return this.#sidenavStatus$.asObservable();
  }

  get sidenavExist() {
    return !!document.querySelector('vdms-hq-ui-sidenav');
  }

  setDefaultStatus(status: SidenavStatusEnum) {
    this.#sidenavStatus$.next(status);
  }

  openDrawer = (status = SidenavStatusEnum.Expanded) => {
    this.#sidenavStatus$.next(status);
    this.#storeSidenavStatus();
  };

  closeDrawer = () => {
    this.#sidenavStatus$.next(SidenavStatusEnum.Closed);
    this.#storeSidenavStatus();
  };

  minimizeDrawer = () => {
    this.#sidenavStatus$.next(SidenavStatusEnum.Collapsed);
    this.#storeSidenavStatus();
  };

  toggle(callback?: () => void) {
    switch (this.#sidenavStatus$.value) {
      case SidenavStatusEnum.Closed:
        this.openDrawer();
        break;
      case SidenavStatusEnum.Expanded:
        this.minimizeDrawer();
        break;
      case SidenavStatusEnum.Collapsed:
        this.closeDrawer();
        break;
    }

    if (callback) {
      callback();
    }
  }

  #getSidenavState(): SidenavState {
    return this.#getLocalStorageItem<SidenavState>('sidenavStatus') ?? SidenavStatusEnum.Expanded;
  }

  #storeSidenavStatus() {
    this.#setLocalStorageItem('sidenavStatus', this.#sidenavStatus$.value);
  }
}
