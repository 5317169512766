<section class="section" [class.padding-less]="paddingLess">
  <div class="header">
    <div class="inline-header" [class.fullwidth]="fullWidthHeader">
      <vdms-hq-ui-button *ngIf="withBackButton" (click)="back()" [iconOnly]="true" icon="chevron_right">
      </vdms-hq-ui-button>
      <ng-content select="[section-before-header]"></ng-content>
      <span *ngIf="header">
        {{ header }}
      </span>
    </div>
    <ng-content select="[section-actions]"></ng-content>
  </div>
  <div class="content">
    <ng-content></ng-content>
  </div>
</section>
