import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { LocalDataSource, Option, SelectOption } from '@vdms-hq/shared';
import { Framerate, Timecode } from '@vdms-hq/timecode';
import { combineLatest, Observable, of } from 'rxjs';
import { delay, map, startWith } from 'rxjs/operators';
import { FormFilteredListSelectOption, FormSortingOption, TableAdvancedDataSource } from '../../../../index';
import { samples } from '../samples';

class CustomDataSource
  extends LocalDataSource<FormFilteredListSelectOption>
  implements TableAdvancedDataSource<FormFilteredListSelectOption>
{
  isLoading$ = of(false);

  trackBy = (index: number, item: FormFilteredListSelectOption) => item.key;
}

export class CustomValidators {
  static exampleAsyncValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return of(null).pipe(
        delay(2000),
        map(() => {
          if (control.value === 'taken') {
            return { alreadyExist: true };
          } else {
            return null;
          }
        }),
      );
    };
  }
}

@Component({
  selector: 'vdms-hq-ui-form-usage',
  templateUrl: './form-usage.component.html',
  styleUrls: ['./form-usage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormUsageComponent {
  customErrorMessages = {
    required: 'This value is mandatory',
    alreadyExist: 'Key already exist (async validator)',
  };
  framerate60fps = Framerate.fromValue(60);
  framerate25fps = Framerate.fromValue(25);
  framerate30fps = Framerate.fromValue(30);
  maxDurationForPair = Timecode.fromSeconds(40);
  formGroup = new UntypedFormGroup({
    autocomplete: new UntypedFormControl(null, [Validators.required]),
    email: new UntypedFormControl(null, [Validators.required, Validators.email]),
    text: new UntypedFormControl(
      null,
      [Validators.required, Validators.minLength(3)],
      [CustomValidators.exampleAsyncValidator()],
    ),
    textCustomValue: new UntypedFormControl(),
    number: new UntypedFormControl(null, Validators.required),
    select: new UntypedFormControl(null, Validators.required),
    selectorChips: new UntypedFormControl(null, Validators.required),
    chips: new UntypedFormControl(null, Validators.required),
    textarea: new UntypedFormControl(null, Validators.required),
    listValue: new UntypedFormControl(null, Validators.required),
    date: new UntypedFormControl(null, Validators.required),
    dateCustomValue: new UntypedFormControl(),
    dateTime: new UntypedFormControl(null, Validators.required),
    dateTimeCustomValue: new UntypedFormControl(),
    time: new UntypedFormControl(null, Validators.required),
    timeTextCustomValue: new UntypedFormControl(),
    radio: new UntypedFormControl(null, Validators.required),
    radioHorizontal: new UntypedFormControl(null, Validators.required),
    checkbox: new UntypedFormControl(null, Validators.required),
    checkboxList: new UntypedFormControl(null, Validators.required),
    checkboxListHorizontal: new UntypedFormControl(null, Validators.required),
    slider: new UntypedFormControl(null, Validators.required),
    sliderCustomValue: new UntypedFormControl(null),
    simplifiedList: new UntypedFormControl(null, Validators.required),
    simplifiedListHorizontal: new UntypedFormControl(null, Validators.required),
    listSortableCheckboxValue: new UntypedFormControl(['example1', 'example3', 'example5'], Validators.required),
    timecode: new UntypedFormControl(Timecode.fromTimecode('00:00:00:00', this.framerate25fps)),
    timecode60fps: new UntypedFormControl(Timecode.fromTimecode('00:00:00:50', this.framerate60fps)),
    timecodePair: new UntypedFormControl([
      Timecode.fromTimecode('00:00:00:00', this.framerate30fps),
      Timecode.fromTimecode('00:01:00:00', this.framerate30fps),
    ]),
    timecodeRange: new UntypedFormControl([
      Timecode.fromTimecode('00:00:00:00', this.framerate30fps),
      Timecode.fromTimecode('00:01:00:00', this.framerate30fps),
    ]),
    bitrateRange: new UntypedFormControl([1200000, 6500000]),
    bytesRange: new UntypedFormControl([1024, 40000]),
    toggle: new UntypedFormControl(false),
  });

  get checkboxList() {
    return this.formGroup.get('checkboxList') as UntypedFormControl;
  }

  get text() {
    return this.formGroup.get('text') as UntypedFormControl;
  }

  get bitrateRange() {
    return this.formGroup.get('bitrateRange') as UntypedFormControl;
  }

  get timecodeRange() {
    return this.formGroup.get('timecodeRange') as UntypedFormControl;
  }

  get bytesRange() {
    return this.formGroup.get('bytesRange') as UntypedFormControl;
  }

  filteringSortingOptions: FormSortingOption[] = [
    {
      direction: 'asc',
      key: 'label',
      label: 'Alphabetical order (asc)',
    },
    {
      direction: 'desc',
      key: 'label',
      label: 'Alphabetical order (desc)',
    },
  ];

  filteringListDataSource = new CustomDataSource(
    of(
      samples.map((sample) => ({
        key: sample.id,
        filterableValue: sample.name,
        label: sample.name,
      })),
    ),
    {
      pageSizeOptions: [3, 6, 9],
      defaultPageSize: 3,
    },
  );

  selectOptions: SelectOption[] = [
    { key: 'example1', label: 'Example value #1' },
    { key: 'example2', label: 'Example value #2' },
    { key: 'example3', label: 'Example value #3' },
    { key: 'example4', label: 'Example value #4' },
    { key: 'example5', label: 'Example value #5' },
    { key: 'example6', label: 'Example value #6' },
  ];
  selectOptionsLong: SelectOption[] = [
    { key: 'example0', label: 'Example value #0' },
    { key: 'example1', label: 'Example value #1' },
    { key: 'example2', label: 'Example value #2' },
    { key: 'example3', label: 'Example value #3' },
    { key: 'example4', label: 'Example value #4' },
    { key: 'example5', label: 'Example value #5' },
    { key: 'example6', label: 'Example value #6' },
    { key: 'example7', label: 'Example value #7' },
    { key: 'example8', label: 'Example value #8' },
    { key: 'example9', label: 'Example value #9' },
    { key: 'example10', label: 'Example value #10' },
    { key: 'example11', label: 'Example value #11' },
    { key: 'example12', label: 'Example value #12' },
    { key: 'example13', label: 'Example value #13' },
    { key: 'example14', label: 'Example value #14' },
    { key: 'example15', label: 'Example value #15' },
    { key: 'example16', label: 'Example value #16' },
    { key: 'example17', label: 'Example value #17' },
    { key: 'example18', label: 'Example value #18' },
    { key: 'example19', label: 'Example value #19' },
    { key: 'example20', label: 'Example value #20' },
    { key: 'example21', label: 'Example value #21' },
    { key: 'example22', label: 'Example value #22' },
    { key: 'example23', label: 'Example value #23' },
    { key: 'example24', label: 'Example value #24' },
    { key: 'example25', label: 'Example value #25' },
    { key: 'example26', label: 'Example value #26' },
  ];

  filteredOptions$ = combineLatest([
    of(this.selectOptionsLong),
    this.formGroup.controls?.autocomplete.valueChanges.pipe(startWith('')),
  ]).pipe(
    map(([selectOptions, controlValue]) => {
      return (selectOptions as Option[]).filter((opt) => opt.label.toLowerCase().includes(controlValue?.toLowerCase()));
    }),
  );

  alwaysEnabled = ['example5', 'example1'];

  readonly simpleSelectOptions = ['example1', 'example2', 'example3', 'example4', 'example5'];
  readonly emailOptions = [
    { key: 1, label: 'example1@mail.com' },
    { key: 2, label: 'example2@mail.com' },
    {
      key: 3,
      label: 'example3@mail.com',
    },
  ];

  withFooter = true;
  debug = true;
  hint = 'Example hint';
  placeholder = 'Example placeholder';
  label = 'Example label';
  expanded = false;
  minTimecode = Timecode.fromTimecode('00:00:00:01');
  maxTimecode = Timecode.fromTimecode('00:10:00:01');

  getField = (key: string) => this.formGroup.get(key) as UntypedFormControl;

  toggleFooter() {
    this.withFooter = !this.withFooter;
  }

  toggleDebug() {
    this.debug = !this.debug;
  }

  updateValue(fieldKey: string, customValue: string) {
    this.getField(fieldKey).setValue(customValue);
  }
}
