export interface AwsV4Signature {
  bucket_name: string;
  file_key: string;
  s3_region_endpoint: string;
  x_amz_algorithm: string;
  x_amz_credential: string;
  x_amz_date: string;
  x_amz_expires: number;
  x_amz_signature: string;
  policy: string;
}

export interface FileUploadState<STATUSES> {
  state: UploadState;
  status: STATUSES;
  uploadParams?: CreateMultipartUploadOutput;
  signature?: AwsV4Signature;
  file?: FileUploadProgressState;
  error?: string;
}

export enum UploadState {
  IN_PROGRESS = 'in_progress',
  ERROR = 'error',
  COMPLETED = 'completed',
}

export interface FileUploadProgressState {
  uploaded: boolean;
  progress: number;
}

export interface CreateMultipartUploadOutput {
  Bucket?: string;
  Key?: string;
  UploadId?: string;
}
