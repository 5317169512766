<ng-container [formGroup]="arrayGroup">
  <div formArrayName="array" class="multiple-input-wrapper">
    <ng-container *ngFor="let control of controlsArray.controls; let i = index">
      <ng-container [ngSwitch]="this.inputType">
        <vdms-hq-ui-form-input-number
          *ngSwitchCase="'number'"
          [formControlName]="i"
          [min]="numberMin"
          [max]="numberMax"
          [label]="label ?? ''"
          [withFooter]="false"
        >
        </vdms-hq-ui-form-input-number>
        <vdms-hq-form-input-timecode *ngSwitchCase="'timecode'" [formControlName]="i" [label]="label ?? ''">
          >
        </vdms-hq-form-input-timecode>
      </ng-container>
    </ng-container>
    <div [class]="inputType === 'timecode' ? 'actions-timecode' : 'actions'">
      <vdms-hq-ui-button
        icon="remove"
        [iconOnly]="true"
        (click)="removeControl(controlsArray.controls.length - 1)"
        *ngIf="controlsArray.controls.length > 1"
      ></vdms-hq-ui-button>
      <vdms-hq-ui-button icon="add" [iconOnly]="true" (click)="addControl()"></vdms-hq-ui-button>
    </div>
  </div>
</ng-container>
