import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ColumnSettingsScope } from '@vdms-hq/firebase-contract';
import { DeepPartial, updateNestedProperties } from '@vdms-hq/shared';
import {
  ActionContextLess,
  DataAction,
  DataPresentationHeaderComponent,
  DefaultMultiViewType,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  TableAdvancedDataSource,
} from '@vdms-hq/ui';
import { combineLatest, map, Observable, Subject, takeUntil } from 'rxjs';
import { ColumnsSettingsService } from '../../logic/services/columns-settings.service';
import { AssetFlatView2Model } from '../../logic/transformation/asset-flat-view-2.model';
import { TABLE_TYPE, ViewSettingsService } from '@vdms-hq/view-settings';

@Component({
  selector: 'vdms-hq-asset-results-2',
  templateUrl: './asset-results-2.component.html',
  styleUrls: ['./asset-results-2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MultipleDataPresentationComponent, DataPresentationHeaderComponent, TranslateModule],
  standalone: true,
})
export class AssetResults2Component<T extends AssetFlatView2Model> implements OnInit, OnDestroy {
  viewSettingsService = inject(ViewSettingsService);
  columnsSettingsService = inject(ColumnsSettingsService);

  @Input() multiViewKey = TABLE_TYPE.ASSET;
  @Input() dataSource!: TableAdvancedDataSource<T>;
  @Input() scopeName!: ColumnSettingsScope;
  @Input() actions: DataAction<T>[] = [];
  @Input() overrideViewConfiguration?: DeepPartial<MultipleViewConfiguration<T>>;
  @Input() contextActions: DataAction<T>[] = [];
  @Input() tableHeaderActions: ActionContextLess[] = [];
  @Input() hiddenColumns: string[] = [];
  @Input() drawerVisible = false;
  @Input() hideSelectAll = false;
  @Input() viewVisible: ('gridTiles' | 'gridAdvanced' | 'tableAdvanced')[] = [
    'tableAdvanced',
    'gridAdvanced',
    'gridTiles',
  ];
  @Input() fitToVisibleArea = true;
  @Input() sortByAllColumns = false;
  @Output() action = new EventEmitter<{ key: string; item?: T }>();

  #destroyed$ = new Subject<void>();
  configuration$!: Observable<MultipleViewConfiguration<T>>;

  ngOnInit() {
    this.configuration$ = combineLatest([
      this.columnsSettingsService.getColumns$(this.scopeName, this.hiddenColumns),
      this.columnsSettingsService.getEnabledColumns$(this.scopeName),
      this.columnsSettingsService.getGridMetadata$(this.scopeName),
      this.viewSettingsService.defaultViewFor$(this.multiViewKey),
    ]).pipe(
      takeUntil(this.#destroyed$),
      map(([columns, columnsEnabled, metadata, viewType]) => {
        if (this.sortByAllColumns) {
          columns = columns.map((column) => ({ ...column, sortable: true }));
        }
        !columnsEnabled.includes('restore_status') && columnsEnabled.push('restore_status');
        const tableAdvanced = {
          tableAdvanced: {
            rows: {
              disabledIf: {
                icon: 'menu_open',
                label: 'Removed',
                condition: (item: AssetFlatView2Model) => item.isDeleted,
              },
            },
            actions: this.actions,
            headerActions: this.tableHeaderActions,
            contextMenu: {
              actions: this.contextActions,
            },
            layout: {
              headers: true,
            },
            settingsAction: {
              id: 'settings',
              label: 'common.global.edit_columns',
              icon: 'settings',
              color: 'transparent',
            },
            selectAllVisible: !this.hideSelectAll,
            columns,
            columnsEnabled,
          },
        };

        const gridAdvanced = {
          gridAdvanced: {
            rows: {
              disabledIf: {
                icon: 'menu_open',
                label: 'Removed',
                condition: (item: AssetFlatView2Model) => item.isDeleted,
              },
            },
            content: {
              titlePath: 'title',
              iconPath: 'icon',
              metadata,
            },
            layout: {
              columns: 'up-to-2',
            },
            image: {
              bgPath: 'background',
              bgHoverPath: 'backgroundHover',
            },
            actions: this.actions,
            contextMenu: {
              actions: this.contextActions,
            },
          },
        };

        const gridTiles = {
          gridTiles: {
            rows: {
              disabledIf: {
                icon: 'menu_open',
                label: 'Removed',
                condition: (item: AssetFlatView2Model) => item.isDeleted,
              },
            },
            content: {
              titlePath: 'title',
              iconPath: 'icon',
            },
            layout: {
              columns: 'up-to-6',
            },
            image: {
              bgPath: 'background',
              bgHoverPath: 'backgroundHover',
            },
            actions: this.actions,
            contextMenu: {
              actions: this.contextActions,
            },
          },
        };

        const configuration = {
          multiView: {
            defaultView: viewType,
            fitToVisibleArea: this.fitToVisibleArea,
            emptyResults: {
              message: 'Could not find any entities',
              navigateLink: '/browse',
              btnText: 'Browse assets',
            },
          },
          ...(this.viewVisible.includes('tableAdvanced') ? tableAdvanced : {}),
          ...(this.viewVisible.includes('gridTiles') ? gridTiles : {}),
          ...(this.viewVisible.includes('gridAdvanced') ? gridAdvanced : {}),
        };

        // todo - type casting won't be necessary after settingAction interface has been cleaned up
        this.overrideViewConfiguration &&
          updateNestedProperties(configuration as MultipleViewConfiguration<T>, this.overrideViewConfiguration);
        return configuration as MultipleViewConfiguration<T>;
      }),
    );
  }

  ngOnDestroy(): void {
    this.#destroyed$.next();
    this.#destroyed$.complete();
  }

  handleSettingsAction($event: { key: string; item?: T }) {
    if ($event.key !== 'settings') {
      return;
    }
    this.columnsSettingsService.openColumnsSettingsPopup(this.scopeName);
  }

  setPreferredTableView($event: DefaultMultiViewType) {
    this.viewSettingsService.saveDefaultViewFor(this.multiViewKey, $event);
  }
}
