<vdms-hq-ui-inner-header
  header="Client Admin Panel"
  [subheader]="(clientDefinite$ | async).name + ' | Version: ' + (version$ | async)"
>
  <div right class="actions">
    <vdms-hq-import-export-button></vdms-hq-import-export-button>
  </div>
</vdms-hq-ui-inner-header>

<vdms-hq-ui-inner-sidebar>
  <vdms-hq-ui-inner-sidebar-menu [navItems]="navItems" left></vdms-hq-ui-inner-sidebar-menu>
  <router-outlet></router-outlet>
</vdms-hq-ui-inner-sidebar>
