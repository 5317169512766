type Permission = unknown;

export interface ItemPermissions {
  ids: Permission[];
  comparison?: 'some' | 'every';
  required?: Permission[];
}

export interface PermissionAwareItem {
  [key: string]: any;
  permissions?: ItemPermissions;
}

export type MenuSectionWithPermissions = PermissionAwareItem & {
  [key: string]: any;
  menuItems: PermissionAwareItem[];
};

const isItemPermissions = (permissions: any): permissions is ItemPermissions => {
  return permissions && permissions.ids && Array.isArray(permissions.ids);
};

export const isPermissionAwareItem = (item: any): item is PermissionAwareItem => {
  return item && isItemPermissions(item.permissions);
};

export const isMenuSectionWithPermissions = (item: any): item is MenuSectionWithPermissions => {
  return item && Array.isArray(item.menuItems);
};
