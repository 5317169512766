import { ActivatedClientService } from '@vdms-hq/activated-client';
import { LicensedPackageAssetGet, AssetFlat } from '@vdms-hq/api-contract';
import { AssetFlatView2 } from '@vdms-hq/asset-results';
import { DataProviderService } from '@vdms-hq/selectors';
import { StorageUrlService } from '@vdms-hq/storage';

type Context = {
  uuid: string;
};

export class LicensePackageAssetViewModel extends AssetFlatView2 {
  constructor(
    props: AssetFlat,
    deps: {
      dataProvider: DataProviderService;
      storageUrlService: StorageUrlService;
      activatedClientService: ActivatedClientService;
    },
    public context: Context,
  ) {
    super(props, deps);
  }

  static fromLicensePackageItem(
    originalAsset: LicensedPackageAssetGet,
    deps: {
      dataProvider: DataProviderService;
      storageUrlService: StorageUrlService;
      activatedClientService: ActivatedClientService;
    },
  ) {
    return new LicensePackageAssetViewModel(originalAsset.asset, deps, {
      uuid: originalAsset.uuid,
    });
  }
}
