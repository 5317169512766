import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FieldConfigId, FieldDefinitionModel } from '@vdms-hq/shared';
import objectPath from 'object-path';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FormBuilderService<ENTITY extends object = Record<string, unknown>> {
  #destroyed$ = new Subject<void>();
  #lastValue!: Record<string, unknown>;

  formGroup: UntypedFormGroup | null = null;
  #fields: FieldDefinitionModel[] = [];
  value?: Partial<ENTITY>;
  modifiedValue?: Partial<ENTITY>;
  locked = true;

  unlock = () => {
    this.locked = false;
  };

  lock = () => {
    this.locked = true;
  };

  set = (fields: FieldDefinitionModel[], entity?: ENTITY) => {
    if (this.formGroup && entity) {
      this.#setValue(entity);
      return;
    }
    this.#fields = fields;
    const form = new UntypedFormGroup({});

    fields.forEach((field) => {
      form.setControl(field.id, new UntypedFormControl(undefined, field?.input?.validators ?? []));
    });

    this.formGroup = form;

    if (entity) {
      this.#setValue(entity);
    }

    this.reset();
    this.#propagateValue();

    this.formGroup?.valueChanges.pipe(takeUntil(this.#destroyed$)).subscribe(() => {
      this.#propagateValue();
    });
  };

  unset() {
    this.reset();
    this.formGroup = null;
    this.#lastValue = {};
    this.#fields = [];
    this.#destroyed$.next();
  }

  getControl(fieldId: FieldConfigId) {
    return this.formGroup?.get(fieldId) as UntypedFormControl | undefined;
  }

  getConfig(fieldId: FieldConfigId) {
    return this.#fields?.find((item) => item.id === fieldId) ?? null;
  }

  reset(entity?: Partial<ENTITY>, lock = true) {
    if (entity) {
      this.#setValue(entity);
    }
    this.formGroup?.reset(this.#lastValue);
    this.formGroup?.markAsUntouched();
    this.formGroup?.markAsPristine();
    lock && this.lock();
  }

  #propagateValue(): void {
    const value = {};
    const modifiedValue = {};

    for (const cfg of this.#fields) {
      const ctrl = this.getControl(cfg.id);

      if (!ctrl || ctrl.disabled) {
        continue;
      }

      const orgValue = ctrl.value;

      if (!cfg?.input?.objectPath) {
        continue;
      }

      objectPath.set(value, cfg?.input?.objectPath, orgValue);

      if (ctrl.dirty) {
        objectPath.set(modifiedValue, cfg?.input?.objectPath, orgValue);
      }
    }

    this.value = value as Partial<ENTITY>;
    this.modifiedValue = modifiedValue as Partial<ENTITY>;
  }

  #setValue(entity: Partial<ENTITY>) {
    this.#fields?.forEach((cfg) => {
      let value;
      if (cfg?.input?.objectPath) {
        value = objectPath.get(entity, cfg?.input.objectPath) ?? null;
      }

      const ctrl = this.getControl(cfg?.id);
      if (ctrl) {
        ctrl.patchValue(value);
      }
    });

    this.#lastValue = this.formGroup?.value;
  }
}
