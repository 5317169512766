import { inject, Injectable } from '@angular/core';
import { AssetApiService, AssetPatch } from '@vdms-hq/api-contract';
import { FormBuilderService } from '@vdms-hq/dynamic-form';
import { replaceEmptyStringWithNull } from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import { BehaviorSubject, switchMap } from 'rxjs';
import { take } from 'rxjs/operators';
import { ActiveAssetService } from './active-asset.service';
import { AssetViewModel } from './../models/asset-view.model';

@Injectable({ providedIn: 'root' })
export class ActiveAssetActionService {
  assetApiService = inject(AssetApiService);
  toastService = inject(ToastService);
  activeAsset = inject(ActiveAssetService);
  formBuilder = inject(FormBuilderService<AssetViewModel>);

  popToast = {
    UPDATE_ASSET_PROCESSING: () =>
      this.toastService.processing({
        id: 'update_asset',
        message: `common.notifications.save_asset.processing`,
      }),
    UPDATE_ASSET_SUCCESS: () =>
      this.toastService.success({
        id: 'update_asset',
        message: 'common.notifications.save_asset.done',
      }),
    UPDATE_ASSET_FAILURE: () =>
      this.toastService.error({
        id: 'update_asset',
        message: `common.notifications.save_asset.failed`,
      }),
  };

  id$ = this.activeAsset.currentIdDefinite$;
  isSaving$ = new BehaviorSubject<boolean>(false);

  save(params: { withFinish: boolean; withLock?: boolean }) {
    if (this.formBuilder.formGroup?.invalid) {
      this.formBuilder.formGroup?.markAllAsTouched();
      return;
    }
    const value = this.formBuilder.modifiedValue;
    let asset = value;

    if (params.withFinish) {
      asset = {
        ...value,
        general: {
          ...value?.general,
          stage: 1,
        },
      } as AssetViewModel;
    }

    const payload = replaceEmptyStringWithNull(asset) as AssetPatch;

    this.isSaving$.next(true);
    this.updateAsset(
      payload,
      () => {
        this.isSaving$.next(false);
      },
      () => {
        this.isSaving$.next(false);
      },
    );

    this.formBuilder.reset(this.formBuilder.value, params.withLock ?? true);
  }

  updateAsset(json: AssetPatch, callbackSuccess?: () => void, callbackFail?: () => void) {
    this.popToast.UPDATE_ASSET_PROCESSING();

    this.id$
      .pipe(
        take(1),
        switchMap((uuid) => this.assetApiService.updateAsset(json, uuid)),
      )
      .subscribe({
        next: () => {
          this.popToast.UPDATE_ASSET_SUCCESS();
          callbackSuccess && callbackSuccess();
        },
        error: (error) => {
          if (error.error.cause?.vida_error === 'ASSET_VALIDATION_ERROR') {
            this.toastService.error({
              id: 'update_asset',
              message: 'This asset has only ' + error.error.cause.data.availableChannels + ' channels available.',
            });
          } else {
            this.popToast.UPDATE_ASSET_FAILURE();
          }
          callbackFail && callbackFail();
        },
      });
  }
}
