import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageWrapperComponent } from './components/page-wrapper/page-wrapper.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ActionsFooterComponent } from './components/actions-footer/actions-footer.component';
import { InnerHeaderComponent } from './components/inner-header/inner-header.component';
import { InnerSidebarComponent } from './components/inner-sidebar/inner-sidebar.component';
import { InnerSidebarLinkComponent } from './components/inner-sidebar-link/inner-sidebar-link.component';
import { MatDividerModule } from '@angular/material/divider';
import { LayoutSectionComponent } from './components/layout-section/layout-section.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { InfoBarComponent } from './components/info-bar/info-bar.component';
import { MatMenuModule } from '@angular/material/menu';
import { UIButtonModule } from '../ui-button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FilteredResultsComponent } from './components/filtered-results/filtered-results.component';
import { InnerSidebarMenuComponent } from './components/inner-sidebar-menu/inner-sidebar-menu.component';
import { UILoaderModule } from '../ui-loader';
import { GridItemHeaderComponent } from './components/grid-item-header/grid-item-header.component';
import { GridStatusComponent } from './components/grid-status/grid-status.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

const exportedComponents = [
  PageWrapperComponent,
  ActionsFooterComponent,
  InnerSidebarComponent,
  InnerHeaderComponent,
  LayoutSectionComponent,
  BreadcrumbsComponent,
  InfoBarComponent,
  FilteredResultsComponent,
];

@NgModule({
  declarations: [exportedComponents, InnerSidebarLinkComponent, InnerSidebarMenuComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    RouterModule,
    MatIconModule,
    TranslateModule,
    MatDividerModule,
    MatMenuModule,
    UIButtonModule,
    MatTooltipModule,
    UILoaderModule,
    GridItemHeaderComponent,
    GridStatusComponent,
    DragDropModule,
  ],
  exports: [...exportedComponents, InnerSidebarMenuComponent],
})
export class UILayoutModule {}
