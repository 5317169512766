import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthModule } from '@vdms-hq/auth';
import { UIModule } from '@vdms-hq/ui';
import { LogoutComponent } from './components/logout/logout.component';

@NgModule({
  imports: [CommonModule, AuthModule, UIModule],
  declarations: [LogoutComponent],
  exports: [LogoutComponent],
})
export class LoginCoreModule {}
