import { Pagination, PaginationProps } from '@vdms-hq/ui';
import { Observable } from 'rxjs';
import {
  AbortMultipartUploadRequest,
  AbortMultipartUploadResponse,
  AssetAsperaPostRequest,
  AssetAsperaPostResponse,
  AssetLinkResponse,
  AssetMediaInfoDetailsResponse,
  AssetPartPostRequest,
  AssetPartPostResponse,
  AssetPatchRequest,
  AssetPatchResponse,
  AssetPostMultipartResponse,
  AssetPostRequest,
  AssetPostResponse,
  AssetResponse,
  AssetsDetailsResponse,
  AssetSearchRequest,
  AssetsHomepageResponse,
  AssetsListGetResponse,
  AssetsOnMapRequest,
  FinishMultipartUploadRequest,
  FinishMultipartUploadResponse,
  VirtualAssetPostRequest,
  VirtualAssetPostResponse,
} from '../../../models/lambda/asset';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class LambdaAssets {
  constructor(private http: HttpClient) {}

  /** @deprecated use @dashboard instead */
  getHomepageAssets(pagination?: PaginationProps): Observable<AssetsHomepageResponse> {
    const headers = Pagination.create(pagination).applyToHeaders(new HttpHeaders());
    return this.http.get<AssetsHomepageResponse>(`${environment.apiUrl}/assets/latest`, { headers });
  }

  /** @deprecated use @search instead */
  postSearchAssets(json: AssetSearchRequest, pagination?: PaginationProps): Observable<AssetResponse> {
    const request: AssetSearchRequest = JSON.parse(JSON.stringify(json));
    const startOfDay = (date: string) =>
      moment.utc(moment(date).toISOString(true).substr(0, 10)).startOf('day').toISOString();
    const endOfDay = (date: string) =>
      moment.utc(moment(date).toISOString(true).substr(0, 10)).endOf('day').toISOString();

    const {
      filters: { ingest_date_from, ingest_date_to, tx_date_from, tx_date_to },
    } = json;

    request.filters.ingest_date_from = ingest_date_from ? startOfDay(ingest_date_from) : ingest_date_from;
    request.filters.ingest_date_to = ingest_date_to ? endOfDay(ingest_date_to) : ingest_date_to;
    request.filters.tx_date_from = tx_date_from ? startOfDay(tx_date_from) : tx_date_from;
    request.filters.tx_date_to = tx_date_to ? endOfDay(tx_date_to) : tx_date_to;

    const headers = Pagination.create(pagination).applyToHeaders(new HttpHeaders());

    return this.http.post<AssetResponse>(`${environment.apiUrl}/search`, request, { headers });
  }

  getAssetsDetails(uuid: string[]): Observable<AssetsDetailsResponse> {
    return this.http.get<AssetsDetailsResponse>(`${environment.apiUrl}/assets`, { params: { uuid } });
  }

  /** @deprecated use api-contract instead */
  getAssetDetails(uuid: string): Observable<AssetMediaInfoDetailsResponse> {
    return this.http.get<AssetMediaInfoDetailsResponse>(`${environment.apiUrl}/asset/` + uuid);
  }

  /** @deprecated use api-contract instead */
  getAssetDownloadLink(uuid: string): Observable<AssetLinkResponse> {
    return this.http.get<AssetLinkResponse>(`${environment.apiUrl}/asset/` + uuid + `/download`);
  }
  /** @deprecated use api-contract instead */
  getAssetDownloadProxyLink(uuid: string): Observable<AssetLinkResponse> {
    return this.http.get<AssetLinkResponse>(`${environment.apiUrl}/asset/` + uuid + `/download-proxy`);
  }

  /** @deprecated use postAssetMultipart instead - this method does not support files over 5GB */
  postAsset(json: AssetPostRequest): Observable<AssetPostResponse> {
    return this.http.post<AssetPostResponse>(`${environment.apiUrl}/asset`, json);
  }

  postAssetMultipart(json: AssetPostRequest): Observable<AssetPostMultipartResponse> {
    return this.http.post<AssetPostMultipartResponse>(`${environment.apiUrl}/asset`, json);
  }

  postAssetPart(json: AssetPartPostRequest, assetUuid: string): Observable<AssetPartPostResponse> {
    return this.http.post<AssetPartPostResponse>(`${environment.apiUrl}/asset/${assetUuid}/upload-part`, json);
  }

  postAssetComplete(json: FinishMultipartUploadRequest, assetUuid: string): Observable<FinishMultipartUploadResponse> {
    return this.http.post<FinishMultipartUploadResponse>(
      `${environment.apiUrl}/asset/${assetUuid}/upload-complete`,
      json,
    );
  }

  postAssetAbort(json: AbortMultipartUploadRequest, assetUuid: string): Observable<AbortMultipartUploadResponse> {
    return this.http.post<AbortMultipartUploadResponse>(`${environment.apiUrl}/asset/${assetUuid}/upload-abort`, json);
  }

  /** @deprecated use api-contract instead */
  postAsperaAsset(json: AssetAsperaPostRequest): Observable<AssetAsperaPostResponse> {
    return this.http.post<AssetAsperaPostResponse>(`${environment.apiUrl}/asset/aspera`, json);
  }

  /** @deprecated use api-contract instead */
  postVirtualAsset(json: VirtualAssetPostRequest, assetUuid: string): Observable<VirtualAssetPostResponse> {
    return this.http.post<VirtualAssetPostResponse>(`${environment.apiUrl}/asset/${assetUuid}/virtual`, json);
  }

  patchAssets(filename: string): Observable<AssetPostResponse> {
    return this.http.patch<AssetPostResponse>(`${environment.apiUrl}/assets`, { filename });
  }

  /** @deprecated use api-contract instead */
  patchAsset(json: AssetPatchRequest, assetUuid: string): Observable<AssetPatchResponse> {
    const request: AssetPatchRequest = JSON.parse(JSON.stringify(json));
    const startOfDay = (date: string) =>
      moment.utc(moment(date).toISOString(true).substr(0, 10)).startOf('day').toISOString();

    if ('ext_title_info' in json) {
      const {
        ext_title_info: { tx_date },
      } = json;

      request.ext_title_info.tx_date = tx_date ? startOfDay(tx_date) : tx_date;
    }

    return this.http.patch<AssetPatchResponse>(`${environment.apiUrl}/asset/${assetUuid}`, request);
  }

  getAssetsListByStage(
    stage: string,
    pagination: PaginationProps,
    clearCache = false,
  ): Observable<AssetsListGetResponse> {
    const headers = Pagination.create(pagination).applyToHeaders(
      clearCache ? new HttpHeaders({ 'Cache-Control': 'max-age=0' }) : new HttpHeaders(),
    );

    return this.http.get<AssetsListGetResponse>(`${environment.apiUrl}/assets/${stage}`, { headers });
  }

  deleteAsset(assetUuid: string): Observable<AssetsDetailsResponse> {
    return this.http.delete<AssetsDetailsResponse>(`${environment.apiUrl}/asset/${assetUuid}`);
  }

  deleteAssets(
    uuid: string[],
    body: {
      deleteReason: string;
      deletionBillable: boolean;
    },
  ): Observable<AssetsDetailsResponse> {
    return this.http.delete<AssetsDetailsResponse>(`${environment.apiUrl}/assets`, { params: { uuid }, body });
  }

  /** @deprecated use api-contract instead */
  getAssetsByCoords(json: AssetsOnMapRequest): Observable<AssetsHomepageResponse> {
    return this.http.post<AssetsHomepageResponse>(`${environment.apiUrl}/assets/map`, json);
  }
}
