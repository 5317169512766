import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as assetActions from '../actions/assets.action';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { LambdaAssets } from '../services/lambda/http/lambda.assets';
import * as toast from '../../toast/public-api';
@Injectable()
export class AssetsEffect {
  deleteAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(assetActions.deleteAssets),
      mergeMap(({ payload }) =>
        this.lambdaAssets
          .deleteAssets(payload.assetIds, {
            deleteReason: payload.reason,
            deletionBillable: payload.billable,
          })
          .pipe(
            map(() => assetActions.deleteAssetsSuccess()),
            catchError((reason) => of(assetActions.deleteAssetsFail({ error: reason }))),
          ),
      ),
    ),
  );

  deleteAssetsProcessing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(assetActions.deleteAssets),
      mergeMap(() => [
        toast.processing({
          id: 'asset_delete',
          message: 'notifications.delete.processing',
        }),
      ]),
    ),
  );

  deleteAssetsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(assetActions.deleteAssetsSuccess),
      mergeMap(() => [
        toast.success({
          id: 'asset_delete',
          message: 'notifications.delete.done',
        }),
      ]),
    ),
  );

  deleteAssetsFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(assetActions.deleteAssetsFail),
      mergeMap(() => [
        toast.success({
          id: 'asset_delete',
          message: 'notifications.delete.fail',
        }),
      ]),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    public dialog: MatDialog,
    private lambdaAssets: LambdaAssets,
  ) {}
}
