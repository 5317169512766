import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiTimecodeListComponent } from './ui-timecode-list/ui-timecode-list.component';
import { UIModule } from '../ui.module';
import { UIFormModule } from '../ui-form';
import {TimecodeModule} from '@vdms-hq/timecode';
import {UIEmptyResultsModule} from '../ui-empty-results';

@NgModule({
  declarations: [UiTimecodeListComponent],
  imports: [CommonModule, UIModule, UIFormModule, TimecodeModule, UIEmptyResultsModule],
  exports: [UiTimecodeListComponent],
})
export class UiTimecodeListModule {}
