<form *ngIf="formGroup; else formGroupNotInitialized">
  <div class="fields fields__columns-{{ columns }}" [class.fields--locked]="isLocked">
    <ng-container *ngFor="let element of elements">
      <ng-container *ngIf="element">
        <ng-container *ngIf="isFormField(element); let formField; else: custom">
          <vdms-hq-control
            [formGroup]="formGroup"
            [definition]="definition"
            [withFooter]="withFooters"
            [content]="content"
            [withStateToggle]="withStateToggle"
            [framerate]="framerate"
            *ngIf="formField | fieldDefinition; let definition; else: unknownDefinition"
          >
          </vdms-hq-control>

          <ng-template #unknownDefinition>
            <vdms-hq-ui-empty-results
              icon="warning"
              message="Unknown form field definition - {{ formField.definition }}"
            ></vdms-hq-ui-empty-results>
          </ng-template>
        </ng-container>

        <ng-template #custom>
          <ng-container
            [ngTemplateOutlet]="customElement.templateRef"
            *ngIf="element.type | customElement : customElements; let customElement; else: unknownCustomElement"
          ></ng-container>
        </ng-template>

        <ng-template #unknownCustomElement>
          <vdms-hq-ui-empty-results
            icon="warning"
            message="Runtime error: Unknown custom element {{ element.type }}, did you forget to register it?"
          ></vdms-hq-ui-empty-results>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
</form>

<ng-template #formGroupNotInitialized>
  <vdms-hq-ui-empty-results
    icon="warning"
    message="Runtime error: Form group not initialized, did you forget to load FormService?"
  ></vdms-hq-ui-empty-results>
</ng-template>
