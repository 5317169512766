import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  DialogResponse,
  UIButtonModule,
  UIDataSelectorComponent,
  UIDialogWrapperModule,
  UILoaderModule,
} from '@vdms-hq/ui';
import { ActivatedClientModule, ActivatedClientService, WithPermissions } from '@vdms-hq/activated-client';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { transformUserToSelectOptionMail, UsersSelectorComponent } from '@vdms-hq/users';
import { SelectionIdentifier, SelectOption } from '@vdms-hq/shared';
import { CollectionsService } from '@vdms-hq/api-contract';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AuthService } from '@vdms-hq/auth';

@Component({
  selector: 'vdms-hq-collection-share-dialog',
  templateUrl: './collection-share-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    UIDialogWrapperModule,
    ActivatedClientModule,
    UIButtonModule,
    UIDataSelectorComponent,
    UsersSelectorComponent,
    ReactiveFormsModule,
    UILoaderModule,
  ],
})
export class CollectionShareDialogComponent extends WithPermissions() implements OnInit, OnDestroy {
  #destroy$ = new Subject<void>();
  users = new FormControl<SelectOption<SelectionIdentifier>[]>([], { nonNullable: true });
  viewInitialized = false;
  selectedIds: string[] = [];
  userListHidden$ = this.clientService.userListHidden$;
  originalUsers: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<CollectionShareDialogComponent>,
    private clientService: ActivatedClientService,
    private collectionsService: CollectionsService,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: { collectionUuid: string },
  ) {
    super();
  }

  ngOnInit(): void {
    this.#setSharedUsers();
  }

  ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.dialogRef.close({
      status: DialogResponse.OK,
      userIds: this.selectedIds,
    });
  }

  #setSharedUsers(): void {
    this.collectionsService
      .getCollectionData(this.data.collectionUuid)
      .pipe(
        takeUntil(this.#destroy$),
        filter((collection) => !!collection),
        withLatestFrom(this.auth.authDefinite$),
      )
      .subscribe(([collection, currentUser]) => {
        collection.users = collection.users?.filter((user) => user.uuid !== currentUser.id);
        this.users.setValue((collection.users || []).map(transformUserToSelectOptionMail));
        this.originalUsers = (collection.users || []).map((user) => user.uuid);
        this.viewInitialized = true;
      });
  }

  updateSelection($event: string[]) {
    this.selectedIds = $event;
  }

  noChanges(): boolean {
    return this.originalUsers.slice().sort().join(',') === this.selectedIds.slice().sort().join(',');
  }
}
