import { Pipe, PipeTransform } from '@angular/core';
import { GridAdvancedMetadata } from '../grid-advanced/grid-advanced.component';

@Pipe({
  name: 'metadataHidden',
  standalone: true,
})
export class MetadataHiddenPipe<T> implements PipeTransform {
  transform(metadata: GridAdvancedMetadata<T>, item: T): boolean {
    if (!metadata?.hiddenIf) {
      return false;
    }

    return metadata.hiddenIf(item);
  }
}
