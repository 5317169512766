import { GetDiscountCodeInterface } from '@vdms-hq/api-contract';

export class DiscountsViewModel {
  constructor(private discount: GetDiscountCodeInterface) {}

  static fromResponse(resp: GetDiscountCodeInterface): DiscountsViewModel {
    return new DiscountsViewModel(resp);
  }

  get uuid() {
    return this.discount.uuid;
  }

  get name() {
    return this.discount.name;
  }

  get code() {
    return this.discount.code;
  }

  get isActive() {
    return this.discount.is_active;
  }

  get egressPricePerGb() {
    return this.discount.multiplier.egress_price_per_gb;
  }

  get restorationPricePerGb() {
    return this.discount.multiplier.restoration_price_per_gb;
  }

  get assetProcessingFeePricePerAsset() {
    return this.discount.multiplier.asset_processing_fee_price_per_asset;
  }

  get transcodePrices() {
    return this.discount.multiplier.transcode;
  }
}
