<vdms-hq-ui-dialog-wrapper footerAlign="space-between" size="wide">
  <ng-container title>
    {{ 'common.delivery_destinations.edit.details' | translate }}
  </ng-container>

  <form [formGroup]="form" content>
    <vdms-hq-ui-form-section layout="single" [divider]="false">
      <vdms-hq-ui-form-input-text
        formControlName="name"
        [label]="'common.delivery_destinations.fields.name' | translate"
      ></vdms-hq-ui-form-input-text>
    </vdms-hq-ui-form-section>
    <vdms-hq-ui-form-section layout="grid-2-columns" [divider]="false">
      <vdms-hq-ui-form-input-chips
        formControlName="emailDelivery"
        [label]="'common.delivery_destinations.fields.email_delivery' | translate"
      ></vdms-hq-ui-form-input-chips>
      <vdms-hq-ui-form-input-chips
        formControlName="deliveryEmails"
        [label]="'common.delivery_destinations.fields.delivery_emails' | translate"
      ></vdms-hq-ui-form-input-chips>
      <vdms-hq-ui-form-input-select
        formControlName="status"
        [selectOptions]="statusOptions"
        [label]="'common.delivery_destinations.fields.status' | translate"
      ></vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-input-text
        formControlName="method"
        [label]="'common.delivery_destinations.fields.method' | translate"
      ></vdms-hq-ui-form-input-text>
    </vdms-hq-ui-form-section>
  </form>

  <ng-container footer>
    <vdms-hq-ui-button (click)="close()" color="secondary">{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button>
    <vdms-hq-ui-button (click)="save()" [disabled]="form.invalid" color="primary">{{
      'common.global.edit' | translate
    }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
