import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { isPageableDataSource, isWithEmptyResults, LoadableDataSource, PageableDataSource } from '@vdms-hq/shared';
import { UIButtonModule } from '../../ui-button';
import { UIEmptyResultsModule } from '../../ui-empty-results';
import { UIFormModule } from '../../ui-form';
import { UILayoutModule } from '../../ui-layout';
import { PaginatorComponent } from '../../ui-pagination';
import { DrawerContainerComponent } from '../components/drawer-container/drawer-container.component';
import { LoaderDsComponent } from '../components/loader-ds/loader-ds.component';
import { RuntimeErrorComponent } from '../components/runtime-error/runtime-error.component';
import { MaxHeightAsDirective } from '../directives/max-height-as.directive';
import { VisibleAreaDirective } from '../directives/visible-area/visible-area.directive';
import {
  GridAdvancedComponent,
  GridAdvancedConfig,
  GridAdvancedDataSource,
} from '../grid-advanced/grid-advanced.component';
import { GridTilesComponent, GridTilesConfig, GridTilesDataSource } from '../grid-tiles/grid-tiles.component';
import {
  TableAdvancedComponent,
  TableAdvancedConfig,
  TableAdvancedDataSource,
} from '../table-advanced/table-advanced.component';
export type DefaultMultiViewType = 'tableAdvanced' | 'gridAdvanced' | 'gridTiles';

export type MultipleViewDataSource<T> = GridTilesDataSource<T> &
  GridAdvancedDataSource<T> &
  TableAdvancedDataSource<T> &
  Partial<PageableDataSource> &
  Partial<LoadableDataSource>;

export type MultipleViewConfiguration<T> = {
  gridTiles?: GridTilesConfig<T>;
  gridAdvanced?: GridAdvancedConfig<T>;
  tableAdvanced?: TableAdvancedConfig<T>;

  multiView?: {
    /** @description default view to show, if not provided, we will try to select first available view */
    defaultView?: DefaultMultiViewType;
    /** @description disable or enable pagination in footer, enabled by default */
    pagination?: boolean;
    /** @description calculate height & fit to visible area on page, enabled by default */
    fitToVisibleArea?: boolean;

    /** @description in case if datasource returns empty array, we display empty results fallback */
    emptyResults?: Partial<{
      message: string;
      showButton: boolean;
      btnText: string;
      navigateLink: string;
      icon: string;
    }>;
  };
};

@Component({
  selector: 'vdms-hq-ui-multiple-data-presentation',
  templateUrl: './multiple-data-presentation.component.html',
  styleUrls: ['./multiple-data-presentation.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    UIButtonModule,
    UILayoutModule,
    TranslateModule,
    GridTilesComponent,
    GridAdvancedComponent,
    TableAdvancedComponent,
    PaginatorComponent,
    RuntimeErrorComponent,
    VisibleAreaDirective,
    UIEmptyResultsModule,
    UIFormModule,
    LoaderDsComponent,
    MaxHeightAsDirective,
    DrawerContainerComponent,
  ],
  animations: [
    trigger('enterAnimation', [
      transition('close => enter', [
        style({ marginRight: '-300px' }),
        animate('60ms ease-in', style({ marginRight: '0' })),
      ]),
      transition('enter => close', [animate('140ms ease-in', style({ marginRight: '-300px' }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultipleDataPresentationComponent<T> implements OnInit {
  view?: DefaultMultiViewType;

  /**
   * @deprecated, use drawer-dialog.service.ts instead, todo refactor
   */
  @Input() drawerVisible = false;
  @Input() showDrawerClose = true;
  @Input() dataSource?: MultipleViewDataSource<T>;
  @Input() configuration: MultipleViewConfiguration<T> = {};
  @Output() changeDefaultView: EventEmitter<DefaultMultiViewType> = new EventEmitter<DefaultMultiViewType>();

  @Output() action = new EventEmitter<{ key: string; item?: T }>();
  height?: string;
  protected readonly isPageableDataSource = isPageableDataSource;
  protected readonly isWithEmptyResults = isWithEmptyResults;

  get enabledViews() {
    return Object.keys(this.configuration).filter((item) =>
      ['gridTiles', 'gridAdvanced', 'tableAdvanced'].includes(item),
    );
  }

  ngOnInit(): void {
    this.#setUpDefaultView();
  }

  #setUpDefaultView() {
    const enabledViews = Object.keys(this.configuration);

    const providedView = this.configuration?.multiView?.defaultView;
    if (providedView && enabledViews.includes(providedView)) {
      this.view = providedView;
      return;
    }

    const defaultViewSelectionOrder = <DefaultMultiViewType[]>['tableAdvanced', 'gridAdvanced', 'gridTiles'];

    for (const view of defaultViewSelectionOrder) {
      if (enabledViews.includes(view)) {
        this.view = view;
        return;
      }
    }
  }

  changeView(view: DefaultMultiViewType) {
    this.view = view;
    this.changeDefaultView.emit(view);
  }
}
