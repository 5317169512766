import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MultiFactorError } from '@firebase/auth';
import { AuthMfaService, AuthService, MfaFactor } from '@vdms-hq/auth';
import { FormControl, Validators } from '@angular/forms';
import { SelectOption } from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import { BehaviorSubject, catchError, filter, of, switchMap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

export interface MfaCodeDialogInput {
  error: MultiFactorError;
}

enum AuthenticationSteps {
  SELECTION = 'selection',
  AUTHENTICATOR = 'authenticator',
  SMS = 'sms',
}

@Component({
  selector: 'vdms-hq-mfa-code-dialog',
  templateUrl: './mfa-code-dialog.component.html',
  styleUrls: ['./mfa-code-dialog.component.scss'],
})
export class MfaCodeDialogComponent implements OnInit {
  step$ = new BehaviorSubject<AuthenticationSteps>(AuthenticationSteps.SELECTION);
  selectedFactor = new FormControl<MfaFactor | null>(null, Validators.required);
  code = new FormControl<number | null>(null, Validators.required);
  otpFromAuthenticator = new FormControl<number | null>(null, Validators.required);
  selectOptions: SelectOption[] = [];
  hints: MfaFactor[] = [];
  processing = false;
  verificationId?: string;
  protected readonly AuthenticationSteps = AuthenticationSteps;

  constructor(
    public dialogRef: MatDialogRef<MfaCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: MfaCodeDialogInput,
    private mfaService: AuthMfaService,
    private toastService: ToastService,
    private authService: AuthService,
    private router: Router,
  ) {}

  @HostListener('document:keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (event.key !== 'Enter') {
      return;
    }

    (this.otpFromAuthenticator.valid || this.code.valid) && this.confirm();
  }

  ngOnInit(): void {
    this.hints = this.mfaService.multiFactorResolver(this.data.error);
    this.selectOptions =
      this.hints.map((hint) => ({
        key: hint,
        label: `${hint.displayName} (${
          hint.phoneNumber ?? (hint.factorId === 'totp' ? 'Authenticator' : hint.factorId)
        })`,
      })) ?? [];

    const hasAuthenticator = this.hints.find((hint) => hint.factorId === 'totp');
    this.selectedFactor.setValue(hasAuthenticator ?? this.hints[0]);
    this.step$.next(hasAuthenticator ? AuthenticationSteps.AUTHENTICATOR : AuthenticationSteps.SMS);
    !hasAuthenticator && this.sendCode();
  }

  sendCode() {
    this.#phone(this.selectedFactor.value?.uid as string);
  }

  cancel() {
    this.dialogRef.close();
  }

  confirmMethod() {
    const step =
      this.selectedFactor.value?.factorId === 'totp' ? AuthenticationSteps.AUTHENTICATOR : AuthenticationSteps.SMS;
    this.code.reset();
    this.otpFromAuthenticator.reset();
    step && this.step$.next(step);
    step === AuthenticationSteps.SMS && this.sendCode();
  }

  confirm() {
    switch (this.selectedFactor.value?.factorId) {
      case 'totp':
        this.#confirmTotp(this.selectedFactor.value?.uid);
        break;
      case 'phone':
        this.#confirmPhone();
        break;
      default:
        break;
    }
  }

  #phone(selectedFactor: string) {
    this.processing = true;

    this.mfaService
      .proofOwnership(this.data.error, selectedFactor)
      .pipe(take(1))
      .subscribe(({ verificationId }) => {
        this.processing = false;
        if (!verificationId) {
          return;
        }

        this.toastService.success({
          id: 'login_mfa',
          message: 'common.mfa.code_sent',
        });

        this.verificationId = verificationId;
      });
  }

  #confirmTotp(uid: string) {
    const otpFromAuthenticator = this.otpFromAuthenticator.value;
    this.mfaService
      .signInWithTotp(this.data.error, String(otpFromAuthenticator), uid)
      .pipe(
        take(1),
        filter(({ operationType }) => operationType === 'signIn'),
        switchMap(() => {
          this.dialogRef.close();
          return this.authService.mfaVerify();
        }),
        catchError(async (err) => {
          if (err.message.includes('auth/invalid-verification-code')) {
            this.toastService.error({ id: 'invalid_code', message: 'Verification code is not valid.' });
          }

          if (err.message.includes('auth/totp-challenge-timeout')) {
            await this.#totpChallengeTimeout();
          }

          this.otpFromAuthenticator.reset();
          return throwError(err);
        }),
      )
      .subscribe();
  }

  #confirmPhone() {
    const code = this.code.value;
    if (!code || !this.verificationId) {
      return;
    }

    this.processing = true;

    this.mfaService
      .proofOwnershipCode(this.data.error, this.verificationId, String(code))
      .pipe(
        switchMap((verified) => {
          this.processing = false;

          if (!verified) {
            this.code.setValue(null);
            return of(verified);
          }

          this.dialogRef.close();
          return this.authService.mfaVerify();
        }),
        catchError(async (err) => {
          if (err.message.includes('auth/invalid-verification-code')) {
            this.toastService.error({ id: 'invalid_code', message: 'Verification code is not valid.' });
          }

          if (err.message.includes('auth/totp-challenge-timeout')) {
            await this.#totpChallengeTimeout();
          }

          this.code.reset();
          return throwError(err);
        }),
      )
      .subscribe();
  }

  async #totpChallengeTimeout() {
    this.toastService.error({ id: 'timeout', message: 'Timeout, please try logging in again.' });
    this.dialogRef.close();
    await this.authService.logout();
  }
}
