import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedClientModule, WithPermissions } from '@vdms-hq/activated-client';
import { ResultsActions } from '@vdms-hq/asset-results';
import { CartStateService } from '@vdms-hq/cart-core';
import { Selection } from '@vdms-hq/shared';
import { UIButtonModule, UILayoutModule } from '@vdms-hq/ui';
import { CartDataSource } from '../../logic/data-sources/cart-data-source';

@Component({
  selector: 'vdms-hq-cart-results-actions',
  templateUrl: './cart-results-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UILayoutModule, NgIf, UIButtonModule, AsyncPipe, TranslateModule, ActivatedClientModule],
})
export class CartResultsActionsComponent<T> extends WithPermissions() {
  ResultsActions = ResultsActions;
  @Input() selectionManager?: Selection<T>;
  @Input() cartButtonDisabled = false;
  @Output() actions: EventEmitter<ResultsActions> = new EventEmitter();

  constructor(public cartDataSource: CartDataSource, public cartState: CartStateService) {
    super();
  }
}
