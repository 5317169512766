<vdms-hq-ui-form-input-select-group
  *ngIf="list$ | async as list"
  [selectOptions]="list ?? []"
  [label]="'Ratings'"
  [multiple]="true"
  [onePerGroup]="true"
  [loading]="loading$ | async"
  [formControl]="innerFormControl"
  [withTouchedIndicator]="withTouchedIndicator"
></vdms-hq-ui-form-input-select-group>
