import { SelectOption } from '@vdms-hq/shared';

export const WasabiRegionsOptions: SelectOption[] = [
  { key: 'ap-northeast-1', label: 'Tokyo (ap-northeast-1)' },
  { key: 'ap-northeast-2', label: 'Osaka (ap-northeast-2)' },
  { key: 'ap-southeast-1', label: 'Singapore (ap-southeast-1)' },
  { key: 'ap-southeast-2', label: 'Sydney (ap-southeast-2)' },
  { key: 'ca-central-1', label: 'Toronto (ca-central-1)' },
  { key: 'eu-central-1', label: 'Amsterdam (eu-central-1)' },
  { key: 'eu-central-2', label: 'Frankfurt (eu-central-2)' },
  { key: 'eu-west-1', label: 'London (eu-west-1)' },
  { key: 'eu-west-2', label: 'Paris (eu-west-2)' },
  { key: 'us-west-1', label: 'Oregon (us-west-1)' },
  { key: 'us-central-1', label: 'Texas (us-east-1)' },
  { key: 'us-east-1', label: 'N. Virginia (us-east-1)' },
  { key: 'us-east-2', label: 'N. Virginia (us-east-2)' },
];
