<div class="search-input-outer" [bdcWalkTriggerFor]="searchBar">
  <vdms-hq-ui-form-input-text
    #input
    [alternativeAppearance]="alternativeAppearance"
    [(ngModel)]="currentQuery"
    maxlength="150"
    (keyup)="keyUp($event)"
    (clickSuffix)="updateQuery()"
    (clickAutocompleteItem)="updateQuery()"
    (clickAutocompleteRemoveItem)="removeAutocompleteItem($event)"
    [placeholder]="placeholder | translate"
    fallbackSuffixIcon="search"
    [suffixConfig]="suffixConfig$ | async"
    [withFooter]="false"
    class="search-input"
    [autocompleteList]="latestQueries$ | async"
    [autocompleteListRemoveItemButton]="true"
  >
  </vdms-hq-ui-form-input-text>
</div>

<vdms-hq-tour-item #searchBar configName="searchBar"></vdms-hq-tour-item>
