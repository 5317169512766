import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from './components/error/error.component';
import { RouterModule } from '@angular/router';

const exportedComponents = [ErrorComponent];

@NgModule({
  declarations: exportedComponents,
  imports: [CommonModule, RouterModule],
  exports: [...exportedComponents],
})
export class UIErrorsModule {}
