import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromToasts from './logic/reducers/index.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MessageEffect } from './logic/effects/message.effect';
import { MessagesComponent } from './smart-components/messages/messages.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [MessagesComponent],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromToasts.featureKey, fromToasts.reducers),
    EffectsModule.forFeature([MessageEffect]),
    MatIconModule,
    MatProgressSpinnerModule,
    TranslateModule.forChild(),
  ],
  exports: [MessagesComponent],
})
export class ToastNotificationModule {}
