import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { VoiceAiService } from '../../logic/voice-ai-service';
import { catchError, map, take, tap } from 'rxjs/operators';
import { ToastService } from '@vdms-hq/toast';
import { EMPTY, Observable, of } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'vdms-hq-voice-reader',
  templateUrl: './voice-reader.component.html',
  styleUrls: ['./voice-reader.component.scss'],
})
export class VoiceReaderComponent implements OnInit {
  @Input() text?: string;
  @ViewChild('audio', { read: ElementRef }) audioRef?: ElementRef<HTMLAudioElement>;

  isLoading = false;
  audioSrc$: Observable<SafeResourceUrl | null> = EMPTY;

  constructor(
    private voiceAiService: VoiceAiService,
    private toastService: ToastService,
    private readonly _sanitizer: DomSanitizer,
  ) {}

  get #audioPlayer() {
    return this.audioRef?.nativeElement;
  }

  ngOnInit() {
    this.play();
  }

  play() {
    this.isLoading = true;
    if (!this.text) {
      return;
    }

    this.audioSrc$ = this.voiceAiService.generateTextToSpeech(this.text).pipe(
      take(1),
      map((response) => this._sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(response))),
      tap(() => (this.isLoading = false)),
      tap(() => {
        if (this.#audioPlayer) {
          this.#audioPlayer.play();
        }
      }),
      catchError((err) => {
        this.isLoading = false;
        this.toastService.error({ id: 'voiceAiError', message: err.message });
        console.error(err);
        return of(null);
      }),
    );
  }
}
