import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AUTH_CONFIG, AuthConfig } from '../config-token';
import { AuthService } from '../services/auth.service';

@Injectable()
export class GuestOnlyGuard implements CanActivate {
  constructor(private state: AuthService, private router: Router, @Inject(AUTH_CONFIG) private env: AuthConfig) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.state.auth$.pipe(
      map((auth) => {
        if (!auth) {
          return true;
        }

        if (route.data?.redirectIfForbidden) {
          this.router.navigate([route.data?.redirectIfForbidden]);
        }

        if (this.env?.routing?.forAuthorized) {
          this.router.navigate([this.env?.routing?.forAuthorized]);
        }

        return false;
      }),
    );
  }
}
