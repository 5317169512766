<vdms-hq-ui-dialog-wrapper footerAlign="space-between" [size]="'narrow'">
  <div title>
    <span class="title">Decode transcription from asset</span>
  </div>

  <div content>
    <div class="form">
      <vdms-hq-ui-form-radio-list
        [selectOptions]="(modelsSelectOptions$ | async) ?? []"
        orientation="vertical"
        label="Select model"
        [required]="true"
        [(ngModel)]="selectedModel"
      >
      </vdms-hq-ui-form-radio-list>
    </div>
  </div>

  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="cancel()">{{ 'common.global.close' | translate }}</vdms-hq-ui-button>
    <vdms-hq-ui-button color="primary" [disabled]="!selectedModel" (click)="run()" [loading]="loading">{{
      'common.global.generate' | translate
    }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
