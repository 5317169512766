import { Injectable } from '@angular/core';
import { CredentialsApiService, CredentialTypeEnum, GetCredentialInterface } from '@vdms-hq/api-contract';
import { shareReplay, take } from 'rxjs/operators';
import { map } from 'rxjs';

const credentialsSelectOption = (item: GetCredentialInterface<CredentialTypeEnum>[]) =>
  item.map((el) => ({
    key: el.uuid,
    label: el.name,
  }));

@Injectable()
export class CredentialsFetcher {
  asperaCredentials$ = this.credentialsService.getByType(CredentialTypeEnum.ASPERA).pipe(
    shareReplay(1),
    take(1),
    map((item) => credentialsSelectOption(item)),
  );
  externalBucketCredentials$ = this.credentialsService.getByType(CredentialTypeEnum.WASABI).pipe(
    shareReplay(1),
    take(1),
    map((item) => credentialsSelectOption(item)),
  );

  constructor(private credentialsService: CredentialsApiService) {}
}
