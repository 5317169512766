<mat-form-field
  appearance="outline"
  class="material-custom-field"
  [class.hide-footer]="!withFooter"
  [class.with-touched-indicator]="withTouchedIndicator"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    type="email"
    matInput
    [readonly]="readonly"
    [formControl]="innerFormControl"
    [placeholder]="placeholder"
    autocomplete="off"
    class="mat-form-field"
    [required]="isRequired"
  />
  <mat-hint *ngIf="withFooter && hint">{{ hint }}</mat-hint>
  <mat-error *ngIf="withFooter">
    <span *ngFor="let error of errors">{{ error.transLang | translate : error.contextParams }}</span>
  </mat-error>
  <vdms-hq-ui-loader matSuffix [size]="20" *ngIf="innerFormControl.pending"></vdms-hq-ui-loader>
</mat-form-field>
