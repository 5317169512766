export enum MediaInsightsEndpoints {
  ROOT_OLD = 'media-insights',
  ROOT = 'video-analysis',
}

export enum MediaInsightsOptionEndpoints {
  EXECUTE_WORKFLOW = 'execute-workflow',
  METADATA = 'metadata',
  OPERATORS = 'operators',
  TRIGGER = 'trigger',
  JOBS = 'jobs',
  RESULTS = 'results',
}
