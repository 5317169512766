import { AssetType } from '@vdms-hq/api-contract';
import { SelectOption } from '@vdms-hq/shared';

const assetTypesConfig: {
  icon: string;
  assetType: AssetType;
  label: string;
  tileBackground: string;
}[] = [
  {
    icon: 'movie',
    assetType: 'video',
    label: 'Video',
    tileBackground: 'assets/common/asset_fallbacks/video.png',
  },
  {
    icon: 'audiotrack',
    assetType: 'audio',
    label: 'Audio',
    tileBackground: 'assets/common/asset_fallbacks/audio.png',
  },
  {
    icon: 'image',
    assetType: 'image',
    label: 'Images',
    tileBackground: 'assets/common/asset_fallbacks/image.png',
  },
  {
    icon: 'subtitles',
    assetType: 'subtitles',
    label: 'Subtitle',
    tileBackground: 'assets/common/asset_fallbacks/subtitles.png',
  },
  {
    icon: 'inventory_2',
    assetType: 'archive',
    label: 'Archives',
    tileBackground: 'assets/common/asset_fallbacks/archive.png',
  },
  {
    icon: 'description',
    assetType: 'document',
    label: 'Documents',
    tileBackground: 'assets/common/asset_fallbacks/doc.png',
  },
  {
    icon: 'insert_drive_file',
    assetType: 'other',
    label: 'Other',
    tileBackground: 'assets/common/asset_fallbacks/unknown.png',
  },
];

export const iconsSelectOptions: SelectOption[] = assetTypesConfig.map(({ icon, label }) => ({ key: icon, label }));

export const assetTypesSelectOptions: SelectOption[] = assetTypesConfig.map((item) => ({
  key: item.assetType,
  label: item.label,
}));

export const getIcon = (type: AssetType) =>
  assetTypesConfig.find((item) => item.assetType === type)?.icon ?? 'insert_drive_file';

export const getFallbackBackground = (type: AssetType) => {
  return assetTypesConfig.find((item) => item.assetType == type)?.tileBackground;
};
