import { AbstractControl, ValidationErrors } from '@angular/forms';

export function emailsArrayValidator(every = false): (control: AbstractControl) => ValidationErrors | null {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isEmptyInputValue(control.value)) {
      return null;
    }
    const valid = [...control.value][every ? 'every' : 'some']((el) => {
      return EMAIL_REGEXP.test(el);
    });
    return valid ? null : { emails: false };
  };
}

const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isEmptyInputValue(value: any): boolean {
  return value == null || (Array.isArray(value) && value.length === 0);
}
