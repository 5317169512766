<form [formGroup]="orderForm" (ngSubmit)="onSubmit()" *ngIf="orderForm">
  <vdms-hq-ui-dialog-wrapper>
    <div title>
      <span class="title">{{ 'pages.order.order' | translate }}</span>
    </div>
    <div content>
      <div class="dialog-content metadata-wrapper">
        <div class="field-wrapper" *ngIf="deliveryTimeSelectOptions$ | async; let deliveryTimeOptions">
          <h3>Delivery time</h3>
          <vdms-hq-ui-form-radio-list
            [selectOptions]="deliveryTimeOptions"
            [orientation]="'horizontal'"
            formControlName="deliveryDelay"
          >
          </vdms-hq-ui-form-radio-list>
        </div>
        <div [ngStyle]="orderForm.get('deliveryDelay').value === 'immediate' ? { display: 'none' } : null">
          <vdms-hq-ui-form-input-date-time
            [minDate]="deliveryMinDate"
            [formControl]="deliveryDate"
            dateLabel="{{ 'pages.order.deliveryDate' | translate }}"
            timeLabel="{{ 'pages.order.deliveryTime' | translate }}"
            [timeAutocompleteList]="deliveryTimeOptions$ | async"
            [autocompleteIncludeNull]="(deliveryTimeOptions$ | async).length > 0"
          >
          </vdms-hq-ui-form-input-date-time>
        </div>

        <hr class="spacer" />

        <div class="field-wrapper">
          <vdms-hq-ui-form-radio-list
            [label]="'pages.order.expiry_time' | translate"
            [selectOptions]="expiryTimeOptions"
            [orientation]="'horizontal'"
            formControlName="expiry"
          ></vdms-hq-ui-form-radio-list>
        </div>
        <div [ngStyle]="orderForm.get('expiry').value !== 'custom' ? { display: 'none' } : null">
          <vdms-hq-ui-form-input-date-time
            [minDate]="expiryMinDate"
            [maxDate]="expiryMaxDate"
            formControlName="expiryDate"
            dateLabel="{{ 'pages.order.expiry_date' | translate }}"
            timeLabel="{{ 'pages.order.expiry_time' | translate }}"
            [timeAutocompleteList]="expiryTimeOptions$ | async"
            [autocompleteIncludeNull]="(expiryTimeOptions$ | async).length > 0"
          ></vdms-hq-ui-form-input-date-time>
        </div>

        <hr class="spacer" />

        <h3>Order details</h3>
        <div class="grid grid-upto2 grid-gap-x">
          <vdms-hq-ui-form-input-text
            formControlName="packageTitle"
            label="{{ 'pages.order.packageTitle' | translate }}"
          >
            <mat-error
              *ngIf="
                this.submitted &&
                this.orderForm.controls.packageTitle.errors &&
                this.orderForm.controls.packageTitle.errors.required
              "
            >
              {{ 'pages.order.packageTitle_required' | translate }}
            </mat-error>
          </vdms-hq-ui-form-input-text>
          <vdms-hq-ui-form-input-text
            formControlName="purchaseOrder"
            label="{{ 'pages.order.purchaseOrder' | translate }}"
          >
            <mat-error
              *ngIf="
                this.submitted &&
                this.orderForm.controls.purchaseOrder.errors &&
                this.orderForm.controls.purchaseOrder.errors.required
              "
            >
              {{ 'pages.order.purchaseOrder_required' | translate }}
            </mat-error>
          </vdms-hq-ui-form-input-text>
        </div>

        <div>
          <vdms-hq-ui-form-input-text formControlName="subject" label="{{ 'pages.order.subject' | translate }}">
            <mat-error
              *ngIf="
                this.submitted &&
                this.orderForm.controls.subject.errors &&
                this.orderForm.controls.subject.errors.required
              "
            >
              {{ 'pages.order.subject_required' | translate }}
            </mat-error>
          </vdms-hq-ui-form-input-text>

          <vdms-hq-ui-form-input-text-area
            [minRows]="1"
            formControlName="comment"
            label="{{ 'pages.order.comment' | translate }}"
          >
          </vdms-hq-ui-form-input-text-area>
        </div>

        <hr class="spacer" />

        <div class="field-wrapper">
          <h3>{{ 'pages.order.delivery_method' | translate }}</h3>
          <vdms-hq-ui-form-input-select formControlName="deliveryMethod" [selectOptions]="deliveryMethodOptions">
          </vdms-hq-ui-form-input-select>
        </div>

        <div class="fit-inputs fieldset" [ngStyle]="deliveryMethod?.value === 'manual' ? { display: 'none' } : {}">
          <div formArrayName="destinations" class="group">
            <div class="group-item" *ngFor="let destination of destinationsControls; let index = index">
              <vdms-hq-destination-selector
                [destinationGroup]="destination"
                [submitted]="submitted"
                [unavailableOptions]="unavailableOptions"
                [label]="('pages.order.destination' | translate) + ' ' + (index + 1)"
                (selectedDestination)="completeEmailControls()"
              >
              </vdms-hq-destination-selector>
              <vdms-hq-ui-button
                (click)="removeDestination(index)"
                [iconOnly]="true"
                [size]="'small'"
                icon="remove"
                color="warn"
              ></vdms-hq-ui-button>
            </div>
          </div>
          <div class="add-profile">
            <vdms-hq-ui-button
              (click)="addDestination()"
              [iconOnly]="true"
              icon="add"
              color="primary"
            ></vdms-hq-ui-button>
          </div>
        </div>

        <div *ngIf="!loading" class="fit-inputs fieldset mb-huge">
          <ng-container *ngIf="(cartNonAssetCount$ | async) === 0">
            <vdms-hq-ui-form-input-chips
              formControlName="deliveryEmails"
              [autocompleteOptions]="emailOptions"
              [placeholder]="emailsPlaceholder"
              matTooltip="Press enter to add"
              matTooltipPosition="above"
              [label]="
                (deliveryMethod?.value === 'manual'
                  ? 'pages.delivery_destinations.form.email'
                  : 'pages.delivery_destinations.form.deliver_email'
                ) | translate
              "
            ></vdms-hq-ui-form-input-chips>

            <vdms-hq-ui-form-input-chips
              formControlName="notificationEmails"
              [autocompleteOptions]="emailOptions"
              [placeholder]="emailsPlaceholder"
              matTooltip="Press enter to add"
              matTooltipPosition="above"
              [label]="'pages.delivery_destinations.form.notification_email' | translate"
              [withFooter]="true"
              [hint]="'pages.delivery_destinations.form.notification_email_hint' | translate"
            ></vdms-hq-ui-form-input-chips>
          </ng-container>

          <div *ngIf="(cartNonAssetCount$ | async) > 0" class="alert-badge">
            <mat-icon class="icon">warning</mat-icon>
            <p [innerHTML]="'pages.order.select_delivery_config' | translate"></p>
          </div>
        </div>
      </div>
      <div #errorList>
        <vdms-hq-ui-info-bar
          *ngIf="(validationErrorAssetUuids$ | async).length > 0"
          [type]="InfoBarType.ERROR"
          [message]="'pages.cart.checkout.validation_error' | translate"
          [disableClose]="true"
          [clickAction]="InfoBarClickAction.EMIT"
          (action)="openFailedDeliveryDestinationValidationDialog()"
        ></vdms-hq-ui-info-bar>
      </div>
    </div>
    <div footer class="footer buttons-group" *ngIf="!loading" [align]="'end'">
      <vdms-hq-ui-button (click)="onNoClick()" color="secondary"
        >{{ 'common.global.cancel' | translate }}
      </vdms-hq-ui-button>
      <ng-container *ngIf="(isValidated$ | async) || deliveryMethod.value === 'manual'; else validateButton">
        <vdms-hq-ui-button
          (click)="onSubmit()"
          [loading]="loading$ | async"
          [disabled]="orderForm.invalid || validationErrorAssetUuids$?.value?.length > 0"
          [isSubmit]="true"
          color="primary"
          >{{ 'common.global.confirm' | translate }}
        </vdms-hq-ui-button>
      </ng-container>
      <ng-template #validateButton>
        <vdms-hq-ui-button
          color="primary"
          [loading]="loading$ | async"
          [disabled]="orderForm.invalid"
          (click)="validate()"
          >{{ 'Validate destination deliveries' }}</vdms-hq-ui-button
        >
      </ng-template>
    </div>
  </vdms-hq-ui-dialog-wrapper>
</form>
