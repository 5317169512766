import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';
import { ApiResponse } from '../../api.model';
import { TRAILER_ENDPOINTS } from './endpoints';

@Injectable({ providedIn: 'root' })
export class TrailerApiService {
  constructor(private apiService: ApiService) {}

  createAssetTrailer(uuid: string) {
    return this.apiService.post<undefined, ApiResponse<string>>(
      `${TRAILER_ENDPOINTS.ASSET}/${uuid}/${TRAILER_ENDPOINTS.TRAILER}`,
      undefined,
    );
  }
}
