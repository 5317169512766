import { Observable } from 'rxjs';
import { Framerate, Timecode } from '@vdms-hq/timecode';
import { FileDestination } from '@vdms-hq/shared';

export const supportedExtensions = ['mp4', 'ogv', 'webm', 'm3u8', 'mp3', 'wav'] as const;

export const isSupportedExtension = (extension: string) =>
  extension && (supportedExtensions as ReadonlyArray<string>).includes(extension);

export interface CanvasShape {
  height: number;
  left: number;
  top: number;
  width: number;
}

export interface CanvasDrawing {
  timecode: Timecode;
  shapes: CanvasShape[];
}

export interface SpritePreviewSettings {
  thumbnailWidth: number;
  thumbnailHeight: number;
  second: number;
  spriteUrl: string;
}
export interface Subtitles {
  language: string;
  path: string;
}

export type PlayerAction =
  | 'pause'
  | 'play'
  | 'togglePlayPause'
  | 'secondForward'
  | 'frameForward'
  | 'increasePlaybackRate'
  | 'decreasePlaybackRate'
  | 'resetPlaybackRate'
  | 'toggleMute'
  | 'changeSubtitles'
  | 'toggleFullScreen'
  | 'playToIn'
  | 'playToOut'
  | 'inToPlayhead'
  | 'outToPlayhead'
  | 'changeAudioTrack'
  | 'updateVolumeUp'
  | 'updateVolume'
  | 'changeQualityLevel';

export interface PlayerConfiguration {
  playerType: 'advanced' | 'simple' | 'audio';
  file: FileDestination;
  offset: Timecode;
  askToFillOffset: boolean;
  framerate?: Framerate;
  includeCookiesInRequest: boolean;
  clip?: {
    startAt: Timecode;
    endAt: Timecode;
  };
  withCredentials: boolean;
  subtitles?: Subtitles[];

  waveformsVtt?: { url: string; name: string }[];
  thumbnailsVttUrl?: string;
  thumbnailsSprite?: SpritePreviewSettings;
  preferredAudioIndex: number | null;
}

export type PlayerState = {
  state: 'not_initialized' | 'loading' | 'ready' | 'error';
  message?: string;
};

export interface PlayerInterface {
  framerate$: Observable<Framerate>;

  currentPlaybackRate$: Observable<number>;

  currentVolumeLevel$: Observable<number>;

  readonly config?: PlayerConfiguration;

  configure: (config: PlayerConfiguration) => void;

  load: () => void;

  unload: () => void;

  goToTimecode: (timecode: Timecode, withOffset: boolean) => void;

  offset$: Observable<Timecode | null>;

  duration$: Observable<Timecode | null>;

  currentTimecode$: Observable<Timecode | null>;

  loading$: Observable<boolean>;

  state$: Observable<PlayerState>;

  handleAction: (action: PlayerAction, value?: number | string) => void;

  updateOffset(offset: Timecode): void;

  loadSubtitles(subtitles: Subtitles[]): Promise<void>;
}
