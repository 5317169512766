<vdms-hq-ui-layout-page-wrapper [breadcrumbs]="[{ name: 'Filename Conventions' }]">
  <ng-container actions>
    <vdms-hq-ui-button (click)="add()" color="secondary">Add new</vdms-hq-ui-button>
    <vdms-hq-ui-button [disabled]="form.invalid || form.pristine" (click)="save()" color="primary"
      >Save</vdms-hq-ui-button
    >
  </ng-container>
  <div class="page" [formGroup]="form">
    <ng-container formArrayName="conventions">
      <div
        class="row"
        *ngFor="let convention of conventionsFormArray.controls; let index = index"
        [formGroupName]="index"
      >
        <vdms-hq-ui-form-input-text formControlName="key" [label]="'Convention'"></vdms-hq-ui-form-input-text>
        <vdms-hq-ui-form-input-select
          formControlName="separator"
          [selectOptions]="separatorSelectOptions"
          [label]="'Separator'"
        ></vdms-hq-ui-form-input-select>
      </div>
    </ng-container>
  </div>
</vdms-hq-ui-layout-page-wrapper>
