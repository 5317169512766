import {
  FormSectionComponent,
  UIButtonModule,
  UIFormModule,
  UILayoutModule,
  UIResultsWrapperModule,
} from '@vdms-hq/ui';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  camelToSnakeCase,
  emailPatternValidator,
  enumToSelectOptions,
  forbiddenValueValidator,
  SelectionIdentifier,
  SelectOption,
  snakeCaseToCamel,
} from '@vdms-hq/shared';
import {
  CredentialsApiService,
  CredentialTypeEnum,
  MetadataSnapshotDeliveryDestination,
  MetadataSnapshotDeliveryType,
  MetadataSnapshotsService,
} from '@vdms-hq/api-contract';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { FieldsConfigService } from '@vdms-hq/config';
import { map, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { ToastService } from '@vdms-hq/toast';
import { shareReplay } from 'rxjs/operators';
import { AwsRegionsOptions } from '@vdms-hq/delivery-destinations';

function validateRequiredEmail(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      control.get('delivery_destination')?.value == MetadataSnapshotDeliveryDestination.EMAIL &&
      (control.get('emails')?.value ?? []).length <= 0
    ) {
      return { emailRequired: true };
    }
    return null;
  };
}

@Component({
  selector: 'vdms-hq-metadata-snapshots-page',
  standalone: true,
  imports: [
    CommonModule,
    UIResultsWrapperModule,
    UILayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    UIFormModule,
    FormSectionComponent,
    UIButtonModule,
  ],
  templateUrl: './metadata-snapshots-page.component.html',
  styleUrls: ['./metadata-snapshots-page.component.scss'],
})
export class MetadataSnapshotsPageComponent implements OnInit, OnDestroy {
  #destroy = new Subject<void>();
  isEdit = false;
  deliveryDestinationOptions = enumToSelectOptions(
    MetadataSnapshotDeliveryDestination,
    'common.metadata_snapshots.delivery_destination.',
    true,
  );
  deliveryOptions = enumToSelectOptions(MetadataSnapshotDeliveryType, 'common.metadata_snapshots.delivery_type.', true);
  months: SelectOption<number>[] = [
    { key: 1, label: 'January' },
    { key: 2, label: 'February' },
    { key: 3, label: 'March' },
    { key: 4, label: 'April' },
    { key: 5, label: 'May' },
    { key: 6, label: 'June' },
    { key: 7, label: 'July' },
    { key: 8, label: 'August' },
    { key: 9, label: 'September' },
    { key: 10, label: 'October' },
    { key: 11, label: 'November' },
    { key: 12, label: 'December' },
  ];

  weekdays: SelectOption<number>[] = [
    { key: 1, label: 'Monday' },
    { key: 2, label: 'Tuesday' },
    { key: 3, label: 'Wednesday' },
    { key: 4, label: 'Thursday' },
    { key: 5, label: 'Friday' },
    { key: 6, label: 'Saturday' },
    { key: 7, label: 'Sunday' },
  ];

  MetadataSnapshotDeliveryType = MetadataSnapshotDeliveryType;
  MetadataSnapshotDeliveryDestination = MetadataSnapshotDeliveryDestination;
  credentialsSelectorSourceAWS: { label: string; key: string }[] = [];
  protected readonly AwsRegionsOptions = AwsRegionsOptions;

  configNotSet = false;

  form = new FormGroup(
    {
      delivery_type: new UntypedFormControl(MetadataSnapshotDeliveryType.DAILY, {
        validators: Validators.required,
        nonNullable: true,
      }),
      delivery_destination: new FormControl<MetadataSnapshotDeliveryDestination>(
        MetadataSnapshotDeliveryDestination.EMAIL,
        {
          validators: Validators.required,
          nonNullable: true,
        },
      ),
      s3Region: new FormControl<string | null>(null),
      s3BucketName: new FormControl<string | null>(null),
      s3Prefix: new FormControl<string | null>(null),
      s3CredentialUuid: new FormControl<string | null>(null),
      deliveryDayMonth: new FormControl<number>(1),
      deliveryDayWeek: new FormControl<number>(1),
      deliveryMonth: new FormControl<number>(1),
      columns: new UntypedFormControl([], { nonNullable: true }),
      emails: new FormControl<SelectOption<SelectionIdentifier>[]>([], {
        nonNullable: true,
      }),
      storage_days: new FormControl<number>(0, [Validators.required]),
    },
    { validators: [validateRequiredEmail()] },
  );
  addUser = new FormControl<string>('', {
    nonNullable: true,
    validators: [emailPatternValidator()],
  });
  columns$ = this.fieldsConfigService.columnDefinitions$;

  constructor(
    private metadataSnapshotService: MetadataSnapshotsService,
    private fieldsConfigService: FieldsConfigService,
    private toastService: ToastService,
    private credentialsService: CredentialsApiService,
  ) {}

  ngOnInit(): void {
    this.#initialize();
  }

  ngOnDestroy() {
    this.#destroy.next();
    this.#destroy.complete();
  }

  showDayMonth() {
    return this.form.value.delivery_type
      ? [MetadataSnapshotDeliveryType.ANNUALLY, MetadataSnapshotDeliveryType.MONTHLY].includes(
          this.form.value.delivery_type,
        )
      : false;
  }

  getDeliveryLayout() {
    return this.form.value.delivery_type == MetadataSnapshotDeliveryType.ANNUALLY ? 'grid-2-columns' : 'single';
  }

  attachUser(email: SelectionIdentifier) {
    const user: SelectOption<SelectionIdentifier>[] = [{ key: email, label: email as string }];
    const controlArray = this.form.controls.emails.value;
    if (!controlArray.some((item) => item.key === email)) {
      // add user to the beginning of the control array
      this.form.controls.emails.setValue(user.concat(controlArray));
      this.setValidators();
    }
    this.addUser.setValue('');
  }

  get user_emails_as_string_array() {
    return this.form.controls.emails.value.map((user) => user.key as string);
  }

  setValidators() {
    this.addUser.setValidators([
      emailPatternValidator(),
      forbiddenValueValidator(this.user_emails_as_string_array, 'usedMail'),
    ]);
  }

  detachUser(email: string | SelectionIdentifier) {
    if (this.form.controls.emails.value) {
      this.form.controls.emails.setValue(this.form.controls.emails.value.filter((item) => item.key !== email));
      this.setValidators();
    }
  }

  #initialize() {
    this.credentialsService
      .getByType(CredentialTypeEnum.AWS)
      .pipe(shareReplay(1), takeUntil(this.#destroy))
      .subscribe((credentials) => {
        this.credentialsSelectorSourceAWS = credentials.map((credential) => ({
          key: credential.uuid,
          label: credential.name,
        }));
        return;
      });
    this.form.controls.delivery_type.valueChanges
      .pipe(
        takeUntil(this.#destroy),
        tap((type) => {
          this.form.controls.deliveryDayMonth.clearValidators();
          this.form.controls.deliveryMonth.clearValidators();
          this.form.controls.deliveryDayWeek.clearValidators();
          this.form.controls.deliveryDayMonth.setErrors(null);
          this.form.controls.deliveryMonth.setErrors(null);
          this.form.controls.deliveryDayWeek.setErrors(null);

          switch (type) {
            case MetadataSnapshotDeliveryType.WEEKLY:
              this.form.controls.deliveryDayWeek.setValidators([Validators.required]);
              break;
            case MetadataSnapshotDeliveryType.MONTHLY:
              this.form.controls.deliveryDayMonth.setValidators([Validators.required]);
              break;
            case MetadataSnapshotDeliveryType.ANNUALLY:
              this.form.controls.deliveryDayMonth.setValidators([Validators.required]);
              this.form.controls.deliveryMonth.setValidators([Validators.required]);
              break;
          }

          this.form.updateValueAndValidity();
        }),
      )
      .subscribe();

    this.form.controls.delivery_destination.valueChanges
      .pipe(
        takeUntil(this.#destroy),
        tap((destination) => {
          this.form.controls.s3Region.clearValidators();
          this.form.controls.s3Prefix.clearValidators();
          this.form.controls.s3CredentialUuid.clearValidators();
          this.form.controls.s3BucketName.clearValidators();
          this.form.controls.s3Region.setErrors(null);
          this.form.controls.s3Prefix.setErrors(null);
          this.form.controls.s3CredentialUuid.setErrors(null);
          this.form.controls.s3BucketName.setErrors(null);

          if (destination == MetadataSnapshotDeliveryDestination.S3) {
            this.form.controls.s3Region.setValidators([Validators.required]);
            this.form.controls.s3Prefix.setValidators([Validators.required]);
            this.form.controls.s3CredentialUuid.setValidators([Validators.required]);
            this.form.controls.s3BucketName.setValidators([Validators.required]);
          }

          this.form.updateValueAndValidity();
        }),
      )
      .subscribe();

    this.metadataSnapshotService.getSnapshotConfig().subscribe({
      error: () => {
        this.configNotSet = true;
      },
      next: (config) => {
        this.isEdit = true;
        this.form.setValue({
          columns: config.labels.map((c) => snakeCaseToCamel(c.value)),
          delivery_type: config.delivery_type,
          delivery_destination: config.delivery_destination as MetadataSnapshotDeliveryDestination,
          deliveryDayMonth: config.schedule_config?.deliveryDayMonth ?? null,
          deliveryDayWeek: config.schedule_config?.deliveryDayWeek ?? null,
          deliveryMonth: config.schedule_config?.deliveryMonth ?? null,
          emails: [],
          s3BucketName: config.delivery_config?.bucketName ?? '',
          s3CredentialUuid: config.delivery_config?.credentialUuid ?? '',
          s3Prefix: config.delivery_config?.prefix ?? '',
          s3Region: config.delivery_config?.region ?? '',
          storage_days: config.storage_days ?? 0,
        });
        config.delivery_config?.emails?.forEach((e) => {
          this.attachUser(e);
        });
      },
    });
  }

  save() {
    if (this.form.valid) {
      this.fieldsConfigService.columnDefinitions$
        .pipe(
          map((fields) => {
            let delivery_config = {};
            if (this.form.value.delivery_destination == MetadataSnapshotDeliveryDestination.EMAIL) {
              delivery_config = {
                emails: this.form.value.emails?.map((e) => e.label) ?? [],
              };
            } else if (this.form.value.delivery_destination == MetadataSnapshotDeliveryDestination.S3) {
              delivery_config = {
                region: this.form.value.s3Region,
                bucketName: this.form.value.s3BucketName,
                prefix: this.form.value.s3Prefix,
                credentialUuid: this.form.value.s3CredentialUuid,
              };
            }

            let schedule_config = {};
            switch (this.form.value.delivery_type) {
              case MetadataSnapshotDeliveryType.WEEKLY:
                schedule_config = {
                  deliveryDayWeek: this.form.value.deliveryDayWeek as number,
                };
                break;
              case MetadataSnapshotDeliveryType.MONTHLY:
                schedule_config = {
                  deliveryDayMonth: this.form.value.deliveryDayMonth as number,
                };
                break;
              case MetadataSnapshotDeliveryType.ANNUALLY:
                schedule_config = {
                  deliveryMonth: this.form.value.deliveryMonth as number,
                  deliveryDayMonth: this.form.value.deliveryDayMonth as number,
                };
                break;
            }

            return {
              uuid: uuidv4(),
              delivery_type: this.form.value.delivery_type,
              delivery_destination: this.form.value.delivery_destination,
              storage_days: this.form.value.storage_days as number,
              schedule_config: schedule_config,
              delivery_config: delivery_config,
              labels: fields
                .filter((f) => {
                  return this.form.value.columns?.includes(f.key as string);
                })
                .map((f) => {
                  return { value: camelToSnakeCase(f.key as string), label: f.label };
                }),
            };
          }),
          switchMap((payload) => {
            if (this.isEdit) {
              return this.metadataSnapshotService.updateSnapshotConfig(payload);
            } else {
              return this.metadataSnapshotService.postSnapshotConfig(payload);
            }
          }),
        )
        .subscribe(() => {
          this.toastService.success({
            id: 'save',
            message: 'Saved metadata snapshot config.',
          });
        });
    }
  }

  delete() {
    this.metadataSnapshotService.deleteSnapshotConfig().subscribe({
      next: () => {
        this.toastService.success({
          id: 'delete',
          message: 'Deleted metadata snapshot config',
        });
        this.form.reset();
      },
      error: () => {
        this.toastService.error({
          id: 'errorDelete',
          message: 'Error deleting config.',
        });
      },
    });
  }

  runSnapshot() {
    this.metadataSnapshotService.runSnapshot().subscribe(() => {
      this.toastService.info({
        id: 'run',
        message: 'Generating a new metadata snapshot.',
      });
    });
  }
}
