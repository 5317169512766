import { Injectable } from '@angular/core';
import { ConnectableDataSource, LoadableDataSource, PageableDataSource, SelectOption } from '@vdms-hq/shared';
import { LICENSED_PACKAGE_STATUS, LicensedPackageFiltersView, LicensePackagesService } from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import { AuthService } from '@vdms-hq/auth';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  EMPTY,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { Pagination } from '@vdms-hq/ui';
import { catchError } from 'rxjs/operators';
import { ParamsPagination } from '@vdms-hq/view-settings';

export interface LicensePackagesAddData {
  key: string;
  label: string;
  number_of_assets: number;
  status: LICENSED_PACKAGE_STATUS;
}

@Injectable({ providedIn: 'root' })
export class RightsContractsLicensePackagesDsService
  extends ParamsPagination
  implements LoadableDataSource, ConnectableDataSource<SelectOption>, PageableDataSource
{
  static readonly defaultPerPage = 24;
  static readonly defaultPage = 0;
  #userId$ = this.authService.id$;

  refresh$ = new BehaviorSubject<boolean>(true);

  isLoading$ = new BehaviorSubject<boolean>(true);
  total$ = new BehaviorSubject<number>(RightsContractsLicensePackagesDsService.defaultPerPage);
  emptyResults$ = new BehaviorSubject(false);

  filters = new FormGroup({
    keyword: new FormControl<string>(''),
    sort: new FormControl<string | null>('licensedPackage.createdAt_desc'),
  });

  values$: Observable<LicensedPackageFiltersView> = this.filters.valueChanges.pipe(
    startWith(this.filters.value),
    debounceTime(400),
    switchMap(() => {
      const formValue = this.filters.value;
      const filters = <LicensedPackageFiltersView>{};

      if (formValue?.keyword) {
        filters.keyword = formValue.keyword;
      }

      if (formValue?.sort) {
        filters.sort = formValue.sort.split('_')[0];
        filters.direction = formValue.sort.split('_')[1];
      }

      this.changePageIndex$.next(0);
      return of(filters);
    }),
  );

  allData$: Observable<LicensePackagesAddData[]> = combineLatest([
    this.values$,
    this.pageIndex$,
    this.pageSize$,
    this.#userId$,
    this.refresh$,
  ]).pipe(
    debounceTime(500),
    tap(() => this.isLoading$.next(true)),
    switchMap(([filters, index, size]) => {
      const headers = Pagination.create({
        page: index,
        perPage: size,
        orderBy: filters.sort,
        orderDir: filters.direction,
      });

      return this.licensePackagesService.getMany(headers, filters).pipe(
        catchError((err) => {
          this.#errorHandler();
          throw err;
        }),
        tap((response) => {
          this.total$.next(response.total);
          this.isLoading$.next(false);
          this.emptyResults$.next(response.total === 0);
        }),
        map((response) =>
          response.data.map((item) => ({
            key: item.uuid,
            label: item.name,
            status: item.status,
            number_of_assets: item.number_of_assets,
          })),
        ),
      );
    }),
    shareReplay(1),
  );

  connection$ = this.allData$;

  constructor(
    private toastService: ToastService,
    private licensePackagesService: LicensePackagesService,
    private authService: AuthService,
  ) {
    super();
  }

  #errorHandler() {
    this.toastService.error({ id: 'titlePackages', message: 'Error fetching title packages' });
    this.isLoading$.next(false);
    return EMPTY;
  }
}
