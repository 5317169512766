import { filterIsArray, filterIsObject } from './filters-type.utils';

export const filterArrayModified = <T>(value: { [k: string]: T } | T[] | T, valueToRemove: T): T[] | null => {
  if (!filterIsArray(value)) {
    return null;
  }
  const modifiedValue = (value as T[]).filter((v) => v !== valueToRemove);
  return modifiedValue.length <= 0 ? null : modifiedValue;
};

export const filterObjectModified = <T>(
  value: { [k: string]: T } | T[] | T,
  index: number,
): null | { [k: string]: T } => {
  if (!filterIsObject(value)) {
    return null;
  }
  return Object.entries(value as { [k: string]: T })
    .filter(([k, v]) => !!v)
    .map(([k, v], idx) => (idx === index ? [k, null] : [k, v]))
    .reduce((accum, [k, v]) => {
      accum[k as string] = v as T;
      return accum;
    }, {} as { [k: string]: T });
};
