import { Injectable } from '@angular/core';
import {
  ApiPaginatedResponse,
  Notification,
  NOTIFICATIONS_CATEGORIES,
  NotificationSubscriptionsService,
  NotificationTypes,
  SortOptions,
} from '@vdms-hq/api-contract';
import { BehaviorSubject, combineLatest, debounceTime, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { NotificationMessageViewModel } from './notification-message-view-model';
import { PaginationProps } from '@vdms-hq/ui';
import moment from 'moment';
import { ActivatedClientService } from '@vdms-hq/activated-client';

@Injectable({ providedIn: 'root' })
export class NotificationsDatasource {
  perPage$ = new BehaviorSubject<number>(12);
  page$ = new BehaviorSubject<number>(0);

  unreadRefresh$ = new BehaviorSubject<string>(new Date().toString());
  unreadNotifications$: Observable<ApiPaginatedResponse<NotificationMessageViewModel>> = this.unreadRefresh$.pipe(
    tap(() => this.isLoading$.next(true)),
    switchMap(() => this.#getNotifications({ read: false }, { perPage: 4, page: 0, orderDir: 'desc' })),
    map((response) => ({
      ...response,
      data: response.data.map((item) => new NotificationMessageViewModel(item)),
    })),
    tap(() => this.isLoading$.next(false)),
    shareReplay(1),
  );
  category$ = new BehaviorSubject<NOTIFICATIONS_CATEGORIES | undefined>(undefined);
  sortDir$ = new BehaviorSubject<SortOptions['direction']>('desc');
  eventType$ = new BehaviorSubject<string>('');

  allNotifications$ = new BehaviorSubject<NotificationMessageViewModel[]>([]);
  totalNotifications$ = new BehaviorSubject<number>(0);
  notificationsRefresh$ = new BehaviorSubject<string>(new Date(moment().add(1, 'day').toISOString()).toString());
  notifications$ = combineLatest([
    this.notificationsRefresh$,
    this.perPage$,
    this.page$,
    this.category$,
    this.sortDir$,
    this.eventType$,
  ]).pipe(
    tap(() => {
      this.isLoading$.next(true);
    }),
    debounceTime(200),
    switchMap(([, perPage, page, category, orderDir, types]) => {
      if (this.isNewRefresh) {
        return combineLatest([
          this.#getNotifications({ types, categories: category }, { perPage, page, orderDir }),
          this.#getFirstUnreadPage(),
        ]);
      }
      return combineLatest([
        this.#getNotifications({ types, categories: category }, { perPage, page, orderDir }),
        of([] as Notification[]),
      ]);
    }),
    tap(([{ data, total }, unreadItems]) => {
      this.totalNotifications$.next(total);
      const prevValue = this.allNotifications$.value;
      const currentValue = data.map((item) => new NotificationMessageViewModel(item));
      const newValue = [...prevValue, ...currentValue];
      const unreadValue = unreadItems
        .filter((item: Notification) => !newValue.some((prev) => prev.props.uuid === item.uuid))
        .map((item) => new NotificationMessageViewModel(item));

      this.allNotifications$.next([...unreadValue, ...newValue]);
      this.isLoading$.next(false);
    }),
    switchMap(() => this.allNotifications$.asObservable()),
    shareReplay(1),
  );

  isLoading$ = new BehaviorSubject(false);

  constructor(private readonly service: NotificationSubscriptionsService, private client: ActivatedClientService) {
    client.clientId$.subscribe(() => {
      this.#init();
    });
  }

  get isNewRefresh() {
    return moment(this.notificationsRefresh$.value).diff(moment(), 'minutes') < 1;
  }

  trackBy(index: number, item: NotificationMessageViewModel) {
    return `${item.props.id}_${item.props.read_at}`;
  }

  loadMore() {
    const nextPage = this.page$.value + 1;
    this.page$.next(nextPage);
  }

  reset() {
    this.page$.next(0);
    this.allNotifications$.next([]);
  }

  #getNotifications(
    params: { categories?: NOTIFICATIONS_CATEGORIES; types?: string; read?: boolean },
    pagination: PaginationProps,
  ) {
    return this.service.getNotifications(params, pagination);
  }

  #getFirstUnreadPage() {
    return this.#getNotifications({ read: false }, { perPage: 12, page: 0, orderDir: 'desc' }).pipe(
      map(({ data }) => data),
    );
  }

  #init() {
    this.allNotifications$.next([]);
    this.totalNotifications$.next(0);
    this.page$.next(0);

    this.unreadRefresh$.next(new Date().toString());
  }
}
