import { BehaviorSubject, combineLatest, Observable, of, share, startWith, withLatestFrom } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  SelectableDataSource,
  SelectIdFunction,
  Selection,
  SelectionIdentifier,
  SelectionState,
  SelectionVisibility,
} from '../contracts/selectable.ds';
import { ConnectableDataSource } from '../contracts/connectable.ds';

export type TableViewDataSource<T> = ConnectableDataSource<T> & Partial<SelectableDataSource<T>>;

export class SelectionManager<T> implements Selection<T> {
  #rangeSelectionStart: SelectionIdentifier | null = null;
  identifiersSnapshot: SelectionIdentifier[] = [];
  visibility$ = new BehaviorSubject<SelectionVisibility>(SelectionVisibility.ENABLED);
  identifiers$ = new BehaviorSubject<SelectionIdentifier[]>([]);
  total$ = this.identifiers$.pipe(map((items) => items.length)) ?? of(0);
  selected$ = this.identifiers$.pipe(map((items) => items.length > 0)) ?? of(false);
  state$: Observable<SelectionState> = combineLatest([this.identifiers$, this.dataSource.connection$]).pipe(
    map(([allSelections, currentPageItems]) => {
      if (allSelections.length === 0) {
        return SelectionState.NONE;
      }

      const currentItemsIds = currentPageItems.map(this.selectIdFn) ?? [];
      const selectedOnPage = allSelections.filter((item) => currentItemsIds.includes(item));
      const selectedAllOnPage = selectedOnPage.length === currentItemsIds.length;

      if (selectedAllOnPage) {
        return SelectionState.ALL_ON_PAGE;
      }

      if (!selectedAllOnPage && (allSelections.length || selectedOnPage.length)) {
        return SelectionState.SOME;
      }

      return SelectionState.NONE;
    }),
    share(),
    startWith(SelectionState.NONE),
  );

  entities$ = new BehaviorSubject<T[]>([]);

  isSelected$ = (value: T) => this.identifiers$.pipe(map((ids) => ids.includes(this.selectIdFn(value))));

  toggleAllVisible = (checked: boolean) => {
    this.dataSource.connection$.pipe(take(1)).subscribe((items) => {
      const ids = items.map(this.selectIdFn);
      let selections;

      if (checked) {
        selections = new Set([...this.identifiersSnapshot, ...ids]);
      } else {
        selections = new Set([...this.identifiersSnapshot.filter((item) => !ids.includes(item))]);
      }
      this.#setSelected(selections);

      this.#updateEntities();
    });
  };

  toggleAll(checked: boolean): void {
    if (!checked) {
      this.clear();
    }

    this.dataSource.connection$
      .pipe(
        take(1),
        map((e) => e.map(this.selectIdFn)),
      )
      .subscribe((allIdentifiers) => {
        this.#setSelected(new Set(allIdentifiers));

        this.#updateEntities();
      });
  }

  clear = () => {
    this.#setSelected(new Set());
    this.entities$.next([]);
  };

  changeVisibility = (value: SelectionVisibility) => this.visibility$.next(value);

  updateSelected(value: SelectionIdentifier[]): void {
    this.#setSelected(new Set(value));
  }

  constructor(private dataSource: TableViewDataSource<T>, private selectIdFn: SelectIdFunction<T>) {}

  #setSelected(ids: Set<SelectionIdentifier>) {
    const arrayed = Array.from(ids);
    this.identifiers$.next(arrayed);
    this.identifiersSnapshot = arrayed;

    this.#updateEntities();
  }

  changeOne(item: T, value: boolean, continuePreviousSelection: boolean) {
    const currentId = this.selectIdFn(item);
    const previousId = this.#rangeSelectionStart;

    if (continuePreviousSelection && previousId) {
      this.dataSource.connection$.pipe(take(1)).subscribe((items) => {
        const allIds = items.map(this.selectIdFn);

        const indexA = allIds.indexOf(currentId);
        const indexB = allIds.indexOf(previousId);

        const fromRange = Math.min(indexA, indexB);
        const toRange = Math.max(indexB, indexA);

        const rangeIds = allIds.filter((value, index) => {
          return fromRange <= index && toRange >= index;
        });

        if (value) {
          this.#setSelected(new Set([...this.identifiersSnapshot, ...rangeIds]));
        } else {
          this.#setSelected(new Set([...this.identifiersSnapshot.filter((item) => !rangeIds.includes(item))]));
        }

        this.#rangeSelectionStart = currentId;
      });
      return;
    }

    if (value) {
      this.#setSelected(new Set([...this.identifiersSnapshot, currentId]));
    } else {
      this.#setSelected(new Set([...this.identifiersSnapshot.filter((item) => item !== currentId)]));
    }

    this.#rangeSelectionStart = currentId;

    this.#updateEntities();
  }

  #updateEntities() {
    this.identifiers$
      .pipe(take(1), withLatestFrom(this.dataSource.connection$, this.entities$))
      .subscribe(([identifiers, onPageEntities, currentEntities]: [SelectionIdentifier[], T[], T[]]) => {
        const currentEntitiesIds = currentEntities.map((entity) => this.selectIdFn(entity));

        let newEntities = [...currentEntities];

        onPageEntities.forEach((entity) => {
          const nextId = this.selectIdFn(entity);

          const isSelected = identifiers.includes(nextId);
          const isInEntities = currentEntitiesIds.includes(nextId);

          if (isSelected && isInEntities) {
            return;
          }

          if (isSelected && !isInEntities) {
            newEntities.push(entity);
            return;
          }

          if (!isSelected && isInEntities) {
            newEntities = newEntities.filter((entity) => this.selectIdFn(entity) !== nextId);
          }
        });

        this.entities$.next(newEntities);
      });
  }
}
