import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pagination } from '@vdms-hq/ui';
import { map } from 'rxjs/operators';
import { ApiPaginatedResponse, ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { MetadataValue, MetadataValueBag } from '../metadata.model';
import { SearchParams } from '../parameters/search-params';
import { DeliveryUploadJobStatus } from '../upload-job';
import { DeliveryPack, DeliveryPackGetFlat, DeliveryPackPatch, EditMandatoryFieldsType } from './delivery-pack.model';
import { FieldConfigId } from '@vdms-hq/shared';

const DELIVERY_PACK_URL = 'delivery-pack';
const MANDATORY_FIELDS_URL = 'mandatory-fields';
const CONTENT_TYPE_URL = 'content-type';
type MandatoryFieldType = Record<FieldConfigId, string[]>;

@Injectable({
  providedIn: 'root',
})
export class DeliveryPackApiService {
  constructor(private apiService: ApiService) {}

  list = (statuses: DeliveryUploadJobStatus[], fullTextSearch = '', pagination?: Pagination, owner?: string | null) => {
    let headers = new HttpHeaders();
    let params = {};

    headers = pagination ? pagination?.applyToHeaders(headers) : headers;

    if (statuses.length) {
      params = {
        status: statuses.join('|'),
        keyword: fullTextSearch,
      };
    }
    if (owner) {
      params = { ...params, owner };
    }

    const queryParams = new SearchParams(params).toQueryParams();

    return this.apiService.get<ApiPaginatedResponse<DeliveryPackGetFlat>>('delivery-packs', {
      headers,
      params: queryParams,
    });
  };

  get = (id: string) =>
    this.apiService.get<ApiResponse<DeliveryPack>>(`${DELIVERY_PACK_URL}/${id}`).pipe(map(({ data }) => data));

  create = (data: MetadataValueBag<MetadataValue>) =>
    this.apiService
      .post<MetadataValueBag<MetadataValue>, ApiResponse<DeliveryPack>>(DELIVERY_PACK_URL, data)
      .pipe(map(({ data }) => data));

  update = (id: string, data: DeliveryPackPatch, status?: string) => {
    return this.apiService
      .patch<DeliveryPackPatch, ApiResponse<DeliveryPack>>(
        `${DELIVERY_PACK_URL}/${id}${status ? '/' + status : ''}`,
        data,
      )
      .pipe(map(({ data }) => data));
  };

  delete = (id: string) => this.apiService.delete(`${DELIVERY_PACK_URL}/${id}`);

  updateMandatoryFields = (data: EditMandatoryFieldsType) =>
    this.apiService.patch<EditMandatoryFieldsType, ApiResponse<unknown>>(
      `${DELIVERY_PACK_URL}/${MANDATORY_FIELDS_URL}`,
      data,
    );

  getMandatoryFields = () =>
    this.apiService
      .get<ApiResponse<MandatoryFieldType>>(`${DELIVERY_PACK_URL}/${MANDATORY_FIELDS_URL}`)
      .pipe(map(({ data }) => data));

  getMandatoryField = (contentType: string) =>
    this.apiService
      .get<ApiResponse<MandatoryFieldType>>(
        `${DELIVERY_PACK_URL}/${MANDATORY_FIELDS_URL}?${CONTENT_TYPE_URL}=${contentType}`,
      )
      .pipe(map(({ data }) => data));
}
