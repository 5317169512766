<div
  class="loader-wrapper"
  [class.center]="center"
  [class.center-padding]="centerPadding"
  [ngClass]="{
    over: mode === 'over',
    'over-parent': mode === 'over-parent'
  }"
  [class.backdrop]="backdrop"
>
  <mat-spinner *ngIf="showLoader" [diameter]="size" class="loader"></mat-spinner>
  <div *ngIf="text" class="loader-text-content">{{ text }}</div>
</div>
