import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';
import { SelectOption } from '@vdms-hq/shared';
import { percentageToValue, valueToPercentage } from '../../helpers/ranges';
import { ChangeContext, Options } from 'ngx-slider-v2';

export type Bytes = number;
export type CalculatedValue = number;
export type Percentage = number;

type OutsideValue = Bytes | null;
type OutsideValuePair = {
  from: OutsideValue;
  to: OutsideValue;
} | null;

type InsideValuePair = {
  from: Bytes | null;
  to: Bytes | null;
};

type BytesForm = {
  from: FormControl<Bytes | null>;
  to: FormControl<Bytes | null>;
};

type RangeBoundaries = {
  min: number;
  max: number;
};

const valueOrDefault = <T>(value: T | null | undefined, defaultValue: T) =>
  value !== undefined && value !== null ? value : defaultValue;

@Component({
  template: '',
})
export abstract class FormInputRangeComponent<Unit> extends FormControlValueAccessorComponent<
  OutsideValuePair,
  InsideValuePair
> {
  #precise = 100;

  customValues = false;
  #defaultBoundaries: RangeBoundaries = {
    min: 0,
    max: 100000000000,
  };

  abstract selectedUnit: Unit;
  abstract formatSliderThumbLabel: (value: Bytes) => string;
  abstract formatCalculatedValueToBytes: (value: CalculatedValue) => Bytes;
  toInputValue?: CalculatedValue;
  fromInputValue?: CalculatedValue;

  @Input() range?: Partial<RangeBoundaries>;
  @Input() predefinedRangesOptions: SelectOption[] = [];

  @Input() set enabledUnits(units: Unit[]) {
    this.unitSelectOptions = units.map((unit) => ({
      key: unit,
      label: String(unit),
    }));
  }

  innerFormControl = new FormGroup<BytesForm | null>({
    from: new FormControl(null),
    to: new FormControl(null),
  });

  unitSelectOptions: SelectOption<Unit>[] = [];

  options: Options = {
    floor: 0,
    ceil: this.#precise,
    translate: (value: number): string => this.formatLabel(value),
  };

  get sliderValueFrom() {
    return valueToPercentage(this.from.value ?? this.min, this.min, this.max, this.#precise);
  }

  get sliderValueTo() {
    return valueToPercentage(this.to.value ?? this.max, this.min, this.max, this.#precise);
  }

  get from() {
    return this.innerFormControl.get('from') as FormControl<Bytes | null>;
  }

  get to() {
    return this.innerFormControl.get('to') as FormControl<Bytes | null>;
  }

  get min(): number {
    return valueOrDefault<number>(this.range?.min, this.#defaultBoundaries.min);
  }

  get max() {
    return valueOrDefault<number>(this.range?.max, this.#defaultBoundaries.max);
  }

  override transformInnerToOuter(value: InsideValuePair): OutsideValuePair {
    if (!value.from && !value.to) {
      return null;
    }
    return value;
  }

  override transformOuterToInner(value: OutsideValuePair): InsideValuePair {
    return {
      from: value?.from ?? null,
      to: value?.to ?? null,
    };
  }

  updateFromPredefined(value: OutsideValuePair) {
    if (!value) {
      return;
    }
    this.innerFormControl.setValue({
      from: value?.from ?? null,
      to: value?.to ?? null,
    });
  }

  updateFromSlider(event: ChangeContext) {
    let from: number | null = percentageToValue(event.value, this.min, this.max, this.#precise);
    if (from === 0) {
      from = null;
    }
    let to: number | null = percentageToValue(event.highValue ?? this.max, this.min, this.max, this.#precise);
    if (to === this.max) {
      to = null;
    }
    this.innerFormControl.setValue({
      from,
      to,
    });
  }

  updateFromInput(control: 'from' | 'to', value?: CalculatedValue) {
    if (value === undefined) {
      return;
    }

    const nextValue = this.formatCalculatedValueToBytes(value < 0 ? 0 : value);

    switch (control) {
      case 'from':
        this.from.setValue(nextValue !== this.min ? nextValue : null);
        break;
      case 'to':
        this.to.setValue(nextValue !== this.min ? nextValue : null);
        break;
    }
  }

  formatLabel = (percentage: Bytes) => {
    const value = percentageToValue(percentage, this.min, this.max, this.#precise);

    return this.formatSliderThumbLabel(value);
  };
}
