export enum COLLECTIONS_ENDPOINTS {
  COLLECTION = 'collection',
  COLLECTIONS = 'collections',
  SHARED = 'collections/shared',
  OWNED = 'collections/my',
  EXPORT = 'export',
  ADD_ITEMS = 'add-items',
  ADD_ITEMS_FROM_CART = 'add-cart',
  REMOVE_ITEMS = 'remove-items',
  UPLOAD = 'collection/upload',
  UPLOAD_CONFIRMATION = 'collection/upload/confirm',
  ADD_ITEMS_FROM_FOLDER = 'collections/add-search',
}
