import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TranslateModule } from '@ngx-translate/core';
import { AssetSearchFilters } from '@vdms-hq/api-contract';
import { DestroyComponent, forbiddenValueValidator } from '@vdms-hq/shared';
import {
  BreadCrumb,
  DialogResponse,
  FormSectionComponent,
  GridItemHeaderComponent,
  SimpleDetailsList,
  UIButtonModule,
  UIDialogWrapperModule,
  UIFormModule,
  UILoaderModule,
  UISimpleDetailsListModule,
  GridTitleComponent,
} from '@vdms-hq/ui';
import { distinctUntilChanged } from 'rxjs';

export interface CreateShareFolderInput {
  folderQuery: AssetSearchFilters;
  breadcrumbs: BreadCrumb[];
  userEmail: string;
  numberOfAssets: number;
}

export interface CreateShareFolderResponse {
  status: DialogResponse;
  users: string[];
  collection_name: string;
}

@Component({
  selector: 'vdms-hq-collection-create-share-folder-dialog',
  templateUrl: './collection-create-share-folder.component.html',
  styleUrls: ['./collection-create-share-folder.component.scss'],
  styles: ['.form-content {} .title-container {} .relative {} .content-container {}'],
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    TranslateModule,
    UIButtonModule,
    UIDialogWrapperModule,
    UIFormModule,
    UILoaderModule,
    UISimpleDetailsListModule,
    FormSectionComponent,
    GridItemHeaderComponent,
    GridTitleComponent,
  ],
})
export class CollectionCreateShareFolderDialogComponent extends DestroyComponent {
  form = new FormGroup({
    name: new FormControl<string>(''),
    users: new FormControl<string[]>([], {
      validators: forbiddenValueValidator([this.data.userEmail], 'ownMail'),
      nonNullable: true,
    }),
  });

  usersControl = this.form.get('users') as FormControl<string[]>;
  nameControl = this.form.get('name') as FormControl<string>;

  shareConfig: SimpleDetailsList[] = [];

  constructor(
    public dialogRef: MatDialogRef<CollectionCreateShareFolderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateShareFolderInput,
  ) {
    super();
    this.form.controls.users.valueChanges.pipe(this.takeUntilDestroyed(), distinctUntilChanged()).subscribe(() => {
      this.usersControl.markAllAsTouched();
    });
    this.shareConfig = [
      {
        title: 'Shared by',
        value: data.userEmail,
      },
      {
        title: 'Number of assets',
        value: data.numberOfAssets,
      },
      {
        title: 'Folders',
        value: data.breadcrumbs.map((b) => b.name.replace(' — ', ': ')).join('  >  '),
        size: 'wide',
      },
    ];
    this.nameControl.setValue('Shared Folder Collection' + ' | ' + new Date().toLocaleDateString());
  }

  close() {
    this.dialogRef.close({ status: DialogResponse.ABORT });
  }

  submit() {
    this.dialogRef.close({
      status: DialogResponse.OK,
      users: this.usersControl.value,
      collection_name: this.nameControl.value,
    });
  }
}
