import { InjectionToken } from '@angular/core';

export type ActivatedClientConfig = {
  routing: ActivatedClientConfigRouting;
  allowChangeClientForHostname: boolean;
};

export type ActivatedClientConfigRouting = {
  clientSwitchRoute?: string;
  insufficientPermissionsRoute: string;
};

export const ACTIVATED_CLIENT_CONFIG_TOKEN: InjectionToken<ActivatedClientConfig> = new InjectionToken(
  'ActivatedClientConfig',
);
