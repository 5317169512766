<div class="drawer-wrapper">
  <div class="drawer-wrapper__title">
    <ng-template [cdkPortalOutlet]="portals?.header"></ng-template>
  </div>
  <div class="drawer-wrapper__content">
    <ng-template [cdkPortalOutlet]="portals?.content"></ng-template>
  </div>
  <div class="drawer-wrapper__footer">
    <ng-template [cdkPortalOutlet]="portals?.footer"></ng-template>
  </div>
</div>
