import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssetAsperaPost } from '@vdms-hq/api-contract';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectorSourceType } from '@vdms-hq/selectors';

@Component({
  selector: 'vdms-hq-storage-embargo-selector-dialog',
  templateUrl: './embargo-selector-dialog.component.html',
  styleUrls: ['./embargo-selector-dialog.component.scss'],
})
export class EmbargoSelectorDialogComponent implements OnInit {
  form = new FormGroup({
    files: new FormArray([]),
  });
  readonly SelectorSourceType = SelectorSourceType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { files: AssetAsperaPost },
    private dialogRef: MatDialogRef<EmbargoSelectorDialogComponent>,
  ) {}

  get files(): FormArray<FormGroup> {
    return this.form.controls['files'] as unknown as FormArray<FormGroup>;
  }

  ngOnInit(): void {
    this.data?.files?.files.forEach((file) =>
      this.files.push(
        new FormGroup({
          originalFilename: new FormControl(file.originalFilename),
          embargoed_to: new FormControl(null, Validators.required),
        }),
      ),
    );
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  save(): void {
    const payload = this.data.files.files;
    this.files.controls.forEach((control) => {
      const index = payload.findIndex((file) => file.originalFilename === control.value['originalFilename']);
      payload[index].embargoed_to = control.value['embargoed_to'];
    });
    this.dialogRef.close(payload);
  }
}
