import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FilterableDataSource,
  LoadableDataSource,
  MissingDatasourceError,
  isLoadableDataSource,
  DiacriticsConverter,
  PageableDataSource,
} from '@vdms-hq/shared';
import { BreadCrumb } from './../../ui-layout/models/breadcrumb.model';
import { ActionIdentifier, ResultsWrapperViewConfiguration } from './../../ui-results-wrapper/logic/wrapper.model';

export type ResultsWrapperDataSource = Partial<LoadableDataSource> &
  Partial<FilterableDataSource> &
  Partial<PageableDataSource>;

@Component({
  selector: 'vdms-hq-ui-results-wrapper',
  templateUrl: './results-wrapper.component.html',
  styleUrls: ['./results-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultsWrapperComponent implements OnInit {
  @Input() dataSource!: ResultsWrapperDataSource;
  @Input() breadcrumbs: BreadCrumb[] = [];
  @Input() additionalInfo = '';
  @Input() configuration?: ResultsWrapperViewConfiguration;
  @Output() headerAction = new EventEmitter();
  @Input() hasBackendFilter = false;

  isLoadableDataSource = isLoadableDataSource;

  applyFilter($event: KeyboardEvent) {
    if (!this.dataSource?.applyFilter) {
      return;
    }

    let value = ($event.target as HTMLFormElement)['value'];

    if (this.hasBackendFilter) {
      value = DiacriticsConverter(($event.target as HTMLFormElement)?.value);
      const pageSize = <string>this.dataSource.pagination$?.value?.perPage;
      if (this.dataSource.pageChange) {
        this.dataSource.pageChange({ pageIndex: 0, pageSize: Number(pageSize), length: 0 });
      }

      this.dataSource.pagination$?.next({ page: '0', perPage: pageSize });
    }
    this.dataSource.applyFilter(value);
  }

  emitHeaderAction($event: MouseEvent, key: ActionIdentifier) {
    $event.stopPropagation();
    this.headerAction.emit({ key });
  }

  ngOnInit(): void {
    if (!this.dataSource) {
      throw new MissingDatasourceError();
    }
  }
}
