import { StatsStaticViewModel } from './stats-static.model';
import { StatsChartViewModel } from './stats-chart-view.model';
import { StatsType } from './stats-type.enum';

export class StatsConfig {
  type: StatsType = StatsType.STATIC;
  title = '';
  icon = '';
  viewModel: StatsStaticViewModel | StatsChartViewModel = {} as StatsStaticViewModel | StatsChartViewModel;

  constructor(type: StatsType, title: string, icon: string, viewModel: StatsStaticViewModel | StatsChartViewModel) {
    this.type = type;
    this.title = title;
    this.icon = icon;
    this.viewModel = viewModel;
  }
}
