import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceWhenExceeds',
  pure: true,
})
export class ReplaceWhenExceedsPipe implements PipeTransform {
  transform(value: number, enoughToTrigger: number, replaceWith: string): string {
    if (value >= enoughToTrigger) {
      return replaceWith;
    }
    return value?.toString();
  }
}
