import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MathPipesModule } from 'ng-pipes';
import { UIButtonModule, UIEmptyResultsModule, UIPreviewModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { PlayerService } from '../../logic/player.service';
import { SimplePlayerComponent } from '../simple-player/simple-player.component';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Timecode } from '@vdms-hq/timecode';
import { AdvancedPlayerComponent } from '../advanced-player/advanced-player.component';
import { PlayerConfiguration } from '../../logic/player.interface';
import { AudioPlayerComponent } from '../audio-player/audio-player.component';

@Component({
  selector: 'vdms-hq-player[config]',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    UIEmptyResultsModule,
    SimplePlayerComponent,
    UIButtonModule,
    UIPreviewModule,
    TranslateModule,
    MathPipesModule,
    AdvancedPlayerComponent,
    AudioPlayerComponent,
  ],
})
export class PlayerComponent implements OnInit, OnDestroy {
  dialog: 'gototimecode' | 'offset' | null = null;
  isCurrentTimecodeVisible = true;
  isDevStatsPaneVisible = false;
  #destroyed = new Subject<void>();
  @Output() changeOffset = new EventEmitter<Timecode>();
  @Input() config!: PlayerConfiguration;

  constructor(public playerService: PlayerService) {}

  ngOnInit(): void {
    this.playerService.configure(this.config);

    this.playerService.activePlayer.state$
      .pipe(takeUntil(this.#destroyed), withLatestFrom(this.playerService.offset$))
      .subscribe(([state, offset]) => {
        const playerReady = state.state === 'ready';
        if (this.playerService.activePlayer.config?.askToFillOffset && !offset && !playerReady) {
          this.dialog = 'offset';
        }
      });
  }
  ngOnDestroy(): void {
    this.#destroyed.next();
  }
  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    const element = event.target as HTMLElement;
    const isInput = element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement;

    if (!isInput) {
      if (event.code === 'Space') {
        event.preventDefault();
        this.playerService.handleAction('togglePlayPause');
      }

      if (event.code === 'ArrowLeft') {
        event.preventDefault();
        this.playerService.handleAction('frameForward', -1);
      }

      if (event.code === 'ArrowRight') {
        event.preventDefault();
        this.playerService.handleAction('frameForward', 1);
      }
      if (event.code === 'KeyJ') {
        event.preventDefault();
        this.playerService.handleAction('frameForward', -3);
      }

      if (event.code === 'KeyK') {
        event.preventDefault();
        this.playerService.handleAction('frameForward', 3);
      }

      if (event.code === 'ArrowUp') {
        event.preventDefault();
        this.playerService.handleAction('updateVolumeUp', 0.05);
      }

      if (event.code === 'ArrowDown') {
        event.preventDefault();
        this.playerService.handleAction('updateVolumeUp', -0.05);
      }

      if (event.code === 'KeyG') {
        event.preventDefault();
        this.dialog = 'gototimecode';
      }

      if (event.code === 'KeyH') {
        event.preventDefault();
        this.dialog = 'offset';
      }

      if (event.code === 'KeyT') {
        event.preventDefault();
        this.isCurrentTimecodeVisible = !this.isCurrentTimecodeVisible;
      }

      if (event.code === 'KeyL') {
        event.preventDefault();
        this.playerService.handleAction('resetPlaybackRate');
      }

      if (event.code === 'Comma') {
        event.preventDefault();
        this.playerService.handleAction('decreasePlaybackRate');
      }

      if (event.code === 'Period') {
        event.preventDefault();
        this.playerService.handleAction('increasePlaybackRate');
      }

      if (event.code === 'KeyD') {
        event.preventDefault();
        this.isDevStatsPaneVisible = !this.isDevStatsPaneVisible;
      }

      const isSlashCode = event.code === 'Slash';
      const macOsQuestionMark = isSlashCode && event.shiftKey;

      if (macOsQuestionMark || isSlashCode) {
        event.preventDefault();
        this.playerService.toggleHelp();
      }
    }

    if (event.code === 'Escape') {
      this.dialog = null;
      this.playerService.hideAllDialogs();
    }
  }
}
