<vdms-hq-ui-dialog-wrapper size="narrow">
  <h1 title class="mb-0">{{ data.title || default.title | translate }}</h1>

  <div
    content
    class="dialog-content dialog-content-small"
    [innerHTML]="
      data.message ? (data.message | translate | sanitize : SANITIZE_TYPE.HTML) : (default.message | translate)
    "
  ></div>
  <div footer class="confirmation buttons-group">
    <vdms-hq-ui-button
      *ngIf="data?.abortAction !== null"
      (click)="abort()"
      [color]="data.abortAction?.color || defaultAbort.color"
      >{{ data.abortAction?.label || defaultAbort.label | translate }}</vdms-hq-ui-button
    >
    <vdms-hq-ui-button
      *ngIf="data?.okAction !== null"
      (click)="ok()"
      [color]="data.okAction?.color || defaultOk.color"
      >{{ data.okAction?.label || defaultOk.label | translate }}</vdms-hq-ui-button
    >
  </div>
</vdms-hq-ui-dialog-wrapper>
