import { Pipe, PipeTransform } from '@angular/core';
import { ActionsColumn, Column } from '../logic/column-config';

@Pipe({
  name: 'isColumnAction',
  standalone: true,
})
export class IsColumnActionsPipe implements PipeTransform {
  static isColumnAction(column: Column): false | ActionsColumn {
    return 'type' in column && column.type === 'actions' ? (column as ActionsColumn) : false;
  }

  transform(column: Column) {
    return IsColumnActionsPipe.isColumnAction(column);
  }
}
