import { Injectable } from '@angular/core';
import { ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { GetResponseData } from '../../operators/get-response-data.operator';
import {
  MediaInsightsWorkflowParamsRequest,
  MediaInsightsWorkflowParamsResponse,
  MediaInsightsWorkflowStatus,
  VideoAnalysisJob,
  VideoAnalysisJobType,
  VideoAnalysisPayload,
  VideoAnalysisResultsRequest,
  VideoAnalysisResultsResponse,
} from './media-insights.model';
import { Observable } from 'rxjs';
import { MediaInsightsEndpoints, MediaInsightsOptionEndpoints } from './media-insights-endpoint.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class MediaInsightsApiService {
  constructor(private apiService: ApiService) {}

  getOperators = (): Observable<VideoAnalysisJobType[]> =>
    this.apiService
      .get<ApiResponse<VideoAnalysisJobType[]>>(
        `${MediaInsightsEndpoints.ROOT}/${MediaInsightsOptionEndpoints.OPERATORS}`,
      )
      .pipe(GetResponseData);

  runVideoAnalysis = (
    assetUuid: string,
    operator: VideoAnalysisJobType,
    payload: VideoAnalysisPayload,
  ): Observable<any> =>
    this.apiService.post<VideoAnalysisPayload, any>(
      `${MediaInsightsEndpoints.ROOT}/${MediaInsightsOptionEndpoints.TRIGGER}/${assetUuid}/${operator}`,
      payload,
    );

  getJobsStatus = (assetUuid: string): Observable<VideoAnalysisJob[]> =>
    this.apiService
      .get<ApiResponse<VideoAnalysisJob[]>>(
        `${MediaInsightsEndpoints.ROOT}/${assetUuid}/${MediaInsightsOptionEndpoints.JOBS}`,
      )
      .pipe(GetResponseData);

  getResults = (
    assetUuid: string,
    options: { perPage: number; filters?: string },
    payload: VideoAnalysisResultsRequest,
  ): Observable<VideoAnalysisResultsResponse[]> => {
    const headers = new HttpHeaders({ 'per-page': options.perPage.toString() });
    if (options.filters) {
      headers.append('filters', options.filters);
    }
    return this.apiService
      .post<VideoAnalysisResultsRequest, ApiResponse<VideoAnalysisResultsResponse[]>>(
        `${MediaInsightsEndpoints.ROOT}/${assetUuid}/${MediaInsightsOptionEndpoints.RESULTS}`,
        payload,
        headers,
      )
      .pipe(GetResponseData);
  };

  // Old API
  runWorkflow = (
    json: MediaInsightsWorkflowParamsRequest,
    assetUuid: string,
  ): Observable<MediaInsightsWorkflowParamsResponse> =>
    this.apiService.post<MediaInsightsWorkflowParamsRequest, MediaInsightsWorkflowParamsResponse>(
      `${MediaInsightsEndpoints.ROOT_OLD}/${MediaInsightsOptionEndpoints.EXECUTE_WORKFLOW}/${assetUuid}`,
      json,
    );

  getStatusWorkflow = (workflowUuid: string, operator: string): Observable<MediaInsightsWorkflowStatus> =>
    this.apiService
      .get<ApiResponse<MediaInsightsWorkflowStatus>>(
        `${MediaInsightsEndpoints.ROOT_OLD}/${MediaInsightsOptionEndpoints.METADATA}/${operator}/${workflowUuid}`,
      )
      .pipe(GetResponseData);
}
