<vdms-hq-ui-results-wrapper
  [dataSource]="dataSource"
  [breadcrumbs]="[{ name: ('common.mandatory-fields.title' | translate) }]"
  [configuration]="headerConfig"
  (headerAction)="handleAction($event)"
>
  <vdms-hq-ui-loader *ngIf="dataSource.isLoading$ | async" [backdrop]="true" mode="over-parent"></vdms-hq-ui-loader>
  <vdms-hq-ui-multiple-data-presentation
    [dataSource]="dataSource"
    [configuration]="viewConfig"
    (action)="handleAction($event)"
  >
  </vdms-hq-ui-multiple-data-presentation>
</vdms-hq-ui-results-wrapper>
