import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { AudioTrack } from '@vdms-hq/api-contract';
import { map, startWith } from 'rxjs/operators';

@Injectable()
export class AudioTrackFormService {
  form = new FormGroup({
    audioTracks: new FormArray<
      FormGroup<{
        track: FormControl<number>;
        layout: FormControl<string | null>;
        channels: FormControl<string[]>;
        language: FormControl<string | null>;
        audio_description: FormControl<string | null>;
        loudness: FormControl<string | null>;
        class: FormControl<string | null>;
        codec: FormControl<string | null>;
        sample_rate: FormControl<string | null>;
        bit_depth: FormControl<string | null>;
        qc_status: FormControl<string | null>;
      }>
    >([]),
  });

  get audioTracks() {
    return this.form.controls.audioTracks;
  }

  value$ = this.audioTracks.valueChanges.pipe(
    startWith(this.audioTracks.value),
    map(() => this.audioTracks.value as AudioTrack[]),
  );

  resetForm() {
    this.audioTracks.clear();
  }

  getErrors() {
    return this.form.errors;
  }

  buildTracks(value: AudioTrack[] | null | undefined) {
    if (value) {
      this.audioTracks.clear();
      value.map((item: AudioTrack, index: number) => {
        const audioTrack = this.#audioTrackFormGroup(index + 1, item);

        this.audioTracks.push(audioTrack);
      });
    }
  }

  removeTrack(value: AudioTrack) {
    const index = this.audioTracks.value.findIndex((item) => item.track === value.track);
    this.audioTracks.removeAt(index);
  }

  addTrack(value?: AudioTrack) {
    const nextIndex = this.audioTracks.length + 1;
    const audioTrack = this.#audioTrackFormGroup(nextIndex, value);

    this.audioTracks.push(audioTrack);
  }

  #audioTrackFormGroup(index: number, item?: AudioTrack) {
    return new FormGroup({
      track: new FormControl(index, { nonNullable: true }),
      layout: new FormControl(item?.layout ?? null),
      channels: new FormControl(item?.channels ?? [], { nonNullable: true }),
      language: new FormControl(item?.language ?? null),
      audio_description: new FormControl(item?.audio_description ?? null),
      loudness: new FormControl(item?.loudness ?? null),
      class: new FormControl(item?.class ?? null),
      codec: new FormControl(item?.codec ?? null),
      sample_rate: new FormControl(item?.sample_rate ?? null),
      bit_depth: new FormControl(item?.bit_depth ?? null),
      qc_status: new FormControl(item?.qc_status ?? null),
    });
  }

  updateTrack(index: number, key: string, value: string) {
    const track = this.audioTracks.controls[index];
    if (!track) {
      return;
    }

    track.get(key)?.patchValue(value);
  }
}
