import { SupplierAsperaKeysResponse } from '@vdms-hq/api-contract';

type Additional = {
  supplierName: string;
};

export class AsperaKeysViewModel {
  constructor(private supplierAsperaKeysResponse: SupplierAsperaKeysResponse, public additional: Additional) {}

  static fromResponse(resp: SupplierAsperaKeysResponse, additional: Additional): AsperaKeysViewModel {
    return new AsperaKeysViewModel(resp, additional);
  }

  get uuid() {
    return this.supplierAsperaKeysResponse.uuid;
  }

  get name() {
    return this.supplierAsperaKeysResponse.name;
  }

  get keyId() {
    return this.supplierAsperaKeysResponse.key_id;
  }

  get supplierUuid() {
    return this.supplierAsperaKeysResponse.supplier_uuid;
  }

  get supplierName() {
    return this.additional.supplierName;
  }

  get groupUuid() {
    return this.supplierAsperaKeysResponse.group_uuid;
  }

  get createdBy() {
    return this.supplierAsperaKeysResponse.created_by;
  }

  get createdAt() {
    return this.supplierAsperaKeysResponse.created_at;
  }

  get facilityOfOriginName() {
    return this.supplierAsperaKeysResponse.facility_of_origin_name;
  }
}
