import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AssetViewConfig, Container } from '@vdms-hq/firebase-contract';

@Component({
  selector: 'vdms-hq-batch-action-form[batchConfig]',
  templateUrl: './batch-action-form.component.html',
  styleUrls: ['./batch-action-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatchActionFormComponent {
  @Input() batchConfig!: AssetViewConfig;
  @Input() withStateToggle = false;
  @Input() containers: Container[] = [];
  @Input() columns = 2;

  getFieldRefsFor(placeId: Container['id']) {
    if (!this.batchConfig) {
      return [];
    }
    return (this.batchConfig?.elements ?? []).filter((element) => element.place === placeId);
  }
}
