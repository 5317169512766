import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';

import { FormControlValueAccessorComponent } from '../../models/form/inputs/form-control-value-accessor.component';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { calculateColumn } from './column-calculator';
import { SelectOption, SelectOptionKey } from '@vdms-hq/shared';

type OuterValue = SelectOptionKey[] | null | undefined;
type InnerValue = SelectOptionKey[] | null;

@Component({
  selector: 'vdms-hq-ui-form-sortable-checkbox-list',
  templateUrl: './form-sortable-checkbox-list.component.html',
  styleUrls: ['./form-sortable-checkbox-list.component.scss', '../../styles/columns.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormSortableCheckboxListComponent),
    },
  ],
})
export class FormSortableCheckboxListComponent
  extends FormControlValueAccessorComponent<OuterValue, InnerValue>
  implements OnInit
{
  innerFormControl = new UntypedFormControl([]);
  @Input() set available(available: SelectOption[]) {
    this.#available = available;
    this.#recalculateLists();
  }

  @Input() columns: 1 | 3 | 4 = 3;
  @Input() alwaysEnabled: SelectOptionKey[] = [];

  #available: SelectOption[] = [];
  lists: { [key: number]: SelectOption[] } = {};

  get value() {
    return this.innerFormControl.value ?? [];
  }

  get listsArray() {
    return new Array(this.columns);
  }

  drop($event: CdkDragDrop<SelectOption[], SelectOption[], SelectOption>, nextColumnIndex: number) {
    const movedItem = $event.item.data;
    const prevPosition = this.#available.findIndex((items) => items.key === movedItem.key);
    const nextPosition = nextColumnIndex * Math.ceil(this.#available.length / this.columns) + $event.currentIndex;

    moveItemInArray(this.#available, prevPosition, nextPosition);

    this.innerFormControl.setValue(this.#attachAlwaysEnabled(this.#syncSorting(this.value)));

    this.#recalculateLists();
  }

  change($event: MatCheckboxChange, item: SelectOptionKey) {
    const value = this.value as SelectOptionKey[];

    let nextValue: SelectOptionKey[];
    if ($event.checked) {
      nextValue = [...value, item];
    } else {
      nextValue = value.filter((other) => other !== item);
    }

    this.innerFormControl.setValue(this.#attachAlwaysEnabled(this.#syncSorting(nextValue)));
  }

  override transformOuterToInner(value: OuterValue): InnerValue {
    return this.#attachAlwaysEnabled(value ?? []);
  }

  trackBy = (index: number, value: SelectOption) => value.key;

  #syncSorting = (values: SelectOptionKey[]) => {
    return this.#available
      .filter((available) => values.find((value) => value === available.key))
      .map((item) => item.key);
  };

  #attachAlwaysEnabled(strings: SelectOptionKey[]) {
    const next = new Set([...(strings ?? []), ...this.alwaysEnabled]);

    return Array.from(next);
  }

  #recalculateLists() {
    for (let listIndex = 0; listIndex < this.columns; listIndex++) {
      this.lists[listIndex] = this.#available.filter(
        (item) =>
          calculateColumn(
            item.key,
            this.#available.map((available) => available.key),
            this.columns,
          ) === listIndex,
      );
    }
  }

  getListItems(listIndex: number) {
    return this.lists[listIndex] as SelectOption[];
  }
}
