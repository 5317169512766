import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { SidenavService } from '../../ui-sidenav';
import { map } from 'rxjs/operators';

@Component({
  selector: 'vdms-hq-ui-site-navigation',
  templateUrl: './site-navigation.component.html',
  styleUrls: ['./site-navigation.component.scss'],
})
export class SiteNavigationComponent implements AfterViewInit {
  state$ = this.sidenavService.sidenavStatus$.pipe(map((state) => (this.sidenavService.sidenavExist ? state : '')));
  constructor(private sidenavService: SidenavService, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
}
