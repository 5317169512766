import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecondsAsTimeCodePipe } from './pipes/seconds-as-timecode.pipe';
import { FramesAsTimecodePipe } from './pipes/frames-as-timecode.pipe';

@NgModule({
  declarations: [SecondsAsTimeCodePipe, FramesAsTimecodePipe],
  imports: [CommonModule],
  exports: [SecondsAsTimeCodePipe, FramesAsTimecodePipe],
})
export class TimecodeModule {}
