<vdms-hq-ui-dialog-wrapper>
  <div title>
    <vdms-hq-ui-layout-section
      [margin]="false"
      [breadcrumbs]="[
        {
          name: 'common.delivery_destinations.fields.delivery_configs' | translate
        },
        {
          name: 'common.delivery_destinations.fields.new_delivery_config' | translate
        }
      ]"
    >
    </vdms-hq-ui-layout-section>
  </div>
  <div content>
    <vdms-hq-ui-form-input-text
      [formControl]="name"
      [label]="'common.delivery_destinations.fields.config_name' | translate"
    ></vdms-hq-ui-form-input-text>
  </div>
  <div footer>
    <vdms-hq-ui-button color="secondary" (click)="close()">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button color="primary" [loading]="loading$ | async" (click)="save()" [disabled]="name.invalid">
      {{ 'common.delivery_destinations.fields.add' | translate }}
    </vdms-hq-ui-button>
  </div>
</vdms-hq-ui-dialog-wrapper>
