import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Typed } from './typed';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[typingAnimation]',
})
export class TypingLettersDirective implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() typeSpeed = 0;
  @Input() startDelay = 0;
  @Input() hideCursorOnComplete = false;
  @Input() typingDisabled = false;
  @Input() text = '';
  @Output() completeTyping = new EventEmitter<void>();
  typed?: Typed;

  typingLock = false;
  contentSubject = new ReplaySubject<string>(1);
  contentObservable = this.contentSubject.asObservable();
  destroy = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy.next();
  }

  constructor(private elRef: ElementRef) {}

  ngOnInit() {
    if (!this.checkContent()) {
      return;
    }
    this.createTyped();
  }

  ngAfterViewInit() {
    if (this.typed) {
      return;
    }

    if (!this.checkContent()) {
      this.contentSubject.next(this.text);

      this.createTyped();

      this.contentObservable.pipe(takeUntil(this.destroy)).subscribe(() => {
        this.createTyped();
      });

      return;
    }

    this.createTyped();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('condition' in changes && this.typed) {
      if (this.typingLock) {
        return;
      }
      if (!this.typingDisabled) {
        this.typed.begin();
        this.typingLock = true;
      }
    }

    if ('text' in changes && this.typed) {
      if (this.typingLock) {
        return;
      }
      if (!this.typingDisabled) {
        this.typed.textContent = this.text;
        this.typed.begin();
        this.typingLock = true;
      }
    }
  }

  private checkContent() {
    return this.text;
  }

  private createTyped() {
    this.typed = new Typed(
      this.elRef.nativeElement,
      {
        showCursor: true,
        typeSpeed: this.typeSpeed,
        startDelay: this.startDelay,
        hideCursorOnComplete: this.hideCursorOnComplete,
        onComplete: () => {
          this.completeTyping.emit();
          this.typingLock = false;
        },
      },
      this.text,
    );

    if (!this.typingDisabled) {
      this.typed.begin();
      this.typingLock = true;
    }
  }
}
